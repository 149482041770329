import React from 'react';
import { useLocation } from 'react-router-dom';

const useLocationQuery = (name: string) => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search).get(name), [search]);
};

export default useLocationQuery;
