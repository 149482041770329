import React from 'react';
import { TableFilterInterface } from '../TableFilterInterface';
import EmployeeInput from '../../Form/EmployeeInput/EmployeeInput';

export type EmployeeFilterOptions = {
  group: 'team' | 'organisation';
  all?: boolean;
  context?: string;
  isMulti?: boolean;
};

const EmployeeFilter: TableFilterInterface<EmployeeFilterOptions> = ({ name, filter, onChange, defaultValue, options }) => {
  return (
    <EmployeeInput
      all={options?.all ?? false}
      group={options?.group ?? 'team'}
      context={options?.context}
      name={name}
      onChange={onChange}
      defaultValue={defaultValue}
      label={filter.label ?? name}
      placeholder={filter.placeholder}
      isFilter={true}
      isMulti={options?.isMulti}
    />
  );
};

export default EmployeeFilter;
