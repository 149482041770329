import React, { FunctionComponent, useMemo } from 'react';
import Form from '../Form/Form';
import { Icon } from '../../atoms/Icon/Icon';
import NarwhalSunny from '../../../Icons/Narwhal-sunny';
import { color } from '../../../styles/Variables';
import { SwitchInput } from '../Form/SwitchInput/SwitchInput';
import NarwhalNight from '../../../Icons/Narwhal-night';

type Props = {};

const DarkModeSwitch: FunctionComponent<Props> = (props) => {
  function saveDarkMode(value: string) {
    localStorage.setItem('darkMode', value);
  }

  const darkModeEnabled = useMemo(() => {
    return localStorage.getItem('darkMode') === 'true';
  }, []);

  return (
    <div>
      <Form onSubmit={() => {}}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1.2rem' }}>
          <Icon size={4}>
            <NarwhalSunny
              sunColor={darkModeEnabled ? color.neutral[100] : color.semantic.warning[100]}
              narwhalColor={darkModeEnabled ? color.neutral[100] : color.primary[60]}
            />
          </Icon>
          <SwitchInput
            name={'darkMode'}
            onChange={(value) => {
              saveDarkMode(value);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }}
            defaultValue={darkModeEnabled}
          />
          <Icon size={4}>
            <NarwhalNight
              moonColor={!darkModeEnabled ? color.neutral[40] : color.primary[30]}
              narwhalColor={!darkModeEnabled ? color.neutral[40] : color.primary[60]}
            />
          </Icon>
        </div>
      </Form>
    </div>
  );
};

export default DarkModeSwitch;
