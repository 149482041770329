import React, { FunctionComponent } from 'react';

type Props = {};

const GridIcon: FunctionComponent<Props> = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.91669 9.16667V2.91669H9.16667V9.16667H2.91669ZM2.91669 17.0833V10.8333H9.16667V17.0833H2.91669ZM10.8333 9.16667V2.91669H17.0833V9.16667H10.8333ZM10.8333 17.0833V10.8333H17.0833V17.0833H10.8333ZM4.16667 7.91669H7.91669V4.16667H4.16667V7.91669ZM12.0833 7.91669H15.8333V4.16667H12.0833V7.91669ZM12.0833 15.8333H15.8333V12.0833H12.0833V15.8333ZM4.16667 15.8333H7.91669V12.0833H4.16667V15.8333Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default GridIcon;
