import React, { FunctionComponent, useEffect } from 'react';
import Container from '../../../atoms/Container/Container';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import NavigationTree from '../../../molecules/NavigationTree/NavigationTree';
import WikiContent from './components/WikiContent/WikiContent';
import { Loader } from '../../../../legacy/CapeMorris/components';
import useWikiTree from '../../../../hooks/Projects/Wiki/useWikiTree';
import WikiSearch from '../../../molecules/Projects/Wiki/WikiSearch/WikiSearch';
import { useNavigate } from 'react-router-dom';
import api from '../../../../services/api';

type Props = {
  slugs: string[];
  tags: string[];
  onTagsChange: (tags: string[]) => void;
};

const WikiLayout: FunctionComponent<Props> = ({ slugs, tags, onTagsChange }) => {
  const { tree, reload } = useWikiTree(null, tags);
  let navigate = useNavigate();

  useEffect(() => {
    const id = api.interceptors.response.use((r) => {
      if (!r.config.url?.endsWith('/favorite')) {
        return r;
      }
      reload();
      return r;
    });
    return () => {
      api.interceptors.response.eject(id);
    };
  }, []);

  return (
    <>
      {tree && (
        <Container $narrow style={{ marginTop: '2rem' }} id={'#wiki'}>
          <Grid $justifyContent={'space-between'}>
            <GridItem $desktop={3}>
              {
                <>
                  <WikiSearch onArticleChange={(fullPath) => navigate(fullPath)} onTagsChange={onTagsChange} />
                  <NavigationTree tree={tree} slugs={slugs} tags={tags} />
                </>
              }
            </GridItem>
            <GridItem $desktop={8}>{<WikiContent slugs={slugs} tree={tree} treeReload={reload} />}</GridItem>
          </Grid>
        </Container>
      )}
      {!tree && <Loader />}
    </>
  );
};

export default WikiLayout;
