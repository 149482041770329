import React, { FunctionComponent } from 'react';
import { Route, Routes } from 'react-router-dom';
import ProjectsHome from './submodules/Home/ProjectsHome';
import ProjectsMyWork from './submodules/MyWork/ProjectsMyWork';
import TasksSubmodule from './submodules/Tasks/TasksSubmodule';
import ProjectsTimesheets from './submodules/Timesheet/ProjectsTimesheets';
import ProjectsWiki from './submodules/Wiki/ProjectsWiki';
import ProjectDetailsView from './submodules/ProjectDetails/ProjectDetailsView';
import ProjectsList from './submodules/ProjectsList/ProjectsList';
import AbsencesSubmodule from './submodules/Absences/AbsencesSubmodule';
import WorkloadView from './submodules/Workload/WorkloadView';
import WikiRedirect from './submodules/Wiki/WikiRedirect';
import Feed from '../../components/templates/ProjectsHome/Feed/Feed';
import Dashboard from '../../components/templates/ProjectsHome/Dashboard/Dashboard';

type Props = {};

const ProjectsModule: FunctionComponent<Props> = () => {
  return (
    <Routes>
      <Route path="" element={<ProjectsHome />}>
        <Route path="feed" element={<Feed />}></Route>
        <Route path="dashboard" element={<Dashboard />}></Route>
      </Route>

      <Route path="my-work/*" element={<ProjectsMyWork />}></Route>
      <Route path="timesheets" element={<ProjectsTimesheets />}></Route>
      <Route path="list/*" element={<ProjectsList />}></Route>
      <Route path={`projects/:id/*`} element={<ProjectDetailsView />}></Route>
      <Route path={`tasks`} element={<TasksSubmodule />}></Route>
      <Route path={`absences/*`} element={<AbsencesSubmodule />}></Route>
      <Route path="wiki-ids/:type/:id" element={<WikiRedirect />}></Route>
      <Route path="wiki/*" element={<ProjectsWiki />}></Route>
      <Route path="workload/*" element={<WorkloadView />} />
    </Routes>
  );
};

export default ProjectsModule;
