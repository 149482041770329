import styled, { css } from 'styled-components';
import { color } from '../../../../styles/Variables';

type DropdownMenuItemProps = {
  $hasSubmenu: boolean;
  $isBorderTop?: boolean;
  onClick?: () => void;
};

export const DropdownMenuItem = styled.div<DropdownMenuItemProps>`
  display: grid;
  grid-template-columns: 0.5fr 2fr 0.5fr;
  gap: 1rem;
  padding: 1.2rem;
  cursor: pointer;
  min-width: 20rem;
  align-items: center;
  justify-content: center;
  position: relative;

  &:hover {
    background-color: ${color.neutral['20']};

    ${(props: DropdownMenuItemProps) =>
      !props.onClick &&
      css`
        background-color: unset;
      `}
  }

  ${(props: DropdownMenuItemProps) =>
    props.$hasSubmenu &&
    css`
      grid-template-columns: 20% 60% 20%;
      gap: 0.2rem;
    `}

  ${(props: DropdownMenuItemProps) =>
    !props.onClick &&
    css`
      cursor: default;
    `}

  ${(props: DropdownMenuItemProps) =>
    props.$isBorderTop &&
    css`
      border-top: 1px solid ${color.neutral['50']};
    `}
`;

export const SubmenuWrapper = styled.div<{ $active: boolean; $left?: boolean }>`
  visibility: hidden;
  position: absolute;
  ${(props) =>
    props.$active &&
    css`
      visibility: visible;
    `}
  ${(props) =>
    props.$left
      ? css`
          right: 100%;
        `
      : css`
          left: 100%;
        `}
`;
