import React, { FunctionComponent } from 'react';
import * as Styled from './ListColumn.styled';
import ListItem, { ItemType, ListItemType } from 'components/molecules/Projects/ListItem/ListItem';
import { Loader } from 'legacy/CapeMorris/components';
import Text from '../../../atoms/Text';

export type ListColumnType = {
  name: string;
  items: ListItemType[];
  itemType: ItemType;
  onSelect: (item: ListItemType) => void;
  isLoading: boolean;
  button?: JSX.Element;
  clientId?: number | null;
};

const ListColumn: FunctionComponent<ListColumnType> = ({ items, name, isLoading, onSelect, button, itemType, clientId }) => {
  const [selectedItem, setSelectedItem] = React.useState<ListItemType | undefined>();

  const handleSelect = React.useCallback((item: ListItemType) => {
    onSelect(item);
    setSelectedItem(item);
  }, []);

  return (
    <Styled.Column>
      <Styled.HeaderWrapper>
        <Text size={'xl'} bold={true}>
          {name}
        </Text>
        {button}
      </Styled.HeaderWrapper>
      <Styled.ColumnBody>
        {isLoading && <Loader />}
        {items &&
          items.map((item) => (
            <ListItem
              key={item.id}
              item={item}
              onSelect={handleSelect}
              isSelected={!!selectedItem && selectedItem.id === item.id}
              itemType={itemType}
              clientId={clientId}
            />
          ))}
      </Styled.ColumnBody>
    </Styled.Column>
  );
};

export default ListColumn;
