import React, { FunctionComponent, useCallback, useState } from 'react';
import { RequestType } from '../../../@types/Request/RequestType';
import { Grid, GridItem } from '../../atoms/Grid/Grid';
import RequestSign from './RequestSign';
import TextareaInput from '../../molecules/Task/TaskForm/TextareaInput/TextareaInput';
import Form from '../../molecules/Form/Form';
import api from '../../../services/api';
import { useEntityContext } from '../../../Context/EntityContext/EntityContext';
import Button from '../../atoms/Button/Button';
import Person from '../../molecules/Person/Person';
import Text from '../../atoms/Text';
import Heading from '../../atoms/Heading';
import { parseDate } from '../../../helpers/date';

type Props = {
  request: RequestType;
  current?: boolean;
};

const RequestCard: FunctionComponent<Props> = ({ request, current }) => {
  const ctx = useEntityContext();
  const [isAcceptBtnLoading, setIsAcceptBtnLoading] = useState(false);
  const [isRejectBtnLoading, setIsRejectBtnLoading] = useState(false);

  const onSubmit = useCallback(
    (data: any, type: 'accept' | 'reject') => {
      if (type === 'accept') {
        setIsAcceptBtnLoading(true);
        api.post(`/api/requests/${request.id}/sign`, data).then(() => {
          ctx?.reload();
          setIsAcceptBtnLoading(false);
        });
      } else {
        setIsRejectBtnLoading(true);
        api.post(`/api/requests/${request.id}/reject`, data).then(() => {
          ctx?.reload();
          setIsRejectBtnLoading(false);
        });
      }
    },
    [request, ctx],
  );

  return (
    <div style={{ width: '100%' }}>
      <Heading size={'s'}>
        <span style={{ display: 'flex', alignItems: 'center' }}>Request</span>
      </Heading>
      <Text>{request.comment}</Text>
      <div style={{ marginTop: '2.6rem', width: '100%' }}>
        <Grid $align={'center'} $justifyContent={'space-between'}>
          <GridItem>
            <Grid spacing={1} $align={'center'}>
              <GridItem>
                <Person person={request.applicant} size={3.5} hideName />
              </GridItem>
              <GridItem>
                <Text bold>{request.applicant.name} asked for approval of this document.</Text>
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem>
            <Text color={'grey'} size={'s'}>
              {parseDate(request.createdAt, true)}
            </Text>
          </GridItem>
        </Grid>
      </div>
      <div style={{ marginTop: '2.6rem' }}>
        <Grid spacing={2.6}>
          {request.signs.map((sign, idx) => (
            <GridItem $desktop={12} key={idx}>
              <RequestSign sign={sign} />
            </GridItem>
          ))}
        </Grid>
      </div>
      <Grid style={{ marginTop: '2.6rem' }}>
        <GridItem $desktop={12}>
          {request.canBeAccepted && current && (
            <Form onSubmit={() => {}}>
              {({ handleSubmit }) => (
                <Grid $simple spacing={2}>
                  <TextareaInput name={'signComment'} placeholder={'Comment'} required />
                  <GridItem $desktop={'max'} />
                  <Button
                    kind={'positive'}
                    type={'submit'}
                    waiting={isAcceptBtnLoading}
                    $text={'Accept'}
                    onClick={handleSubmit((data: any) => onSubmit(data, 'accept'))}
                  />
                  <Button kind={'negative'} $text={'Reject'} waiting={isRejectBtnLoading} onClick={handleSubmit((data: any) => onSubmit(data, 'reject'))} />
                </Grid>
              )}
            </Form>
          )}
        </GridItem>
      </Grid>
    </div>
  );
};

export default RequestCard;
