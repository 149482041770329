import styled, { css } from 'styled-components';

type ItemsColumnWrapperProps = {
  $scrollable?: boolean;
  $isProjectsList?: boolean;
  $heightLocked?: boolean;
};

export const ItemsColumnWrapper = styled.div<ItemsColumnWrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  min-height: 30rem;
  flex-wrap: wrap;
  overflow-x: auto;

  ${(props: ItemsColumnWrapperProps) =>
    props.$heightLocked &&
    css`
      max-height: 50rem;
    `}

  ${(props: ItemsColumnWrapperProps) =>
    props.$scrollable &&
    css`
      flex-wrap: nowrap;
      overflow-x: hidden;
      overflow-y: auto;
    `}
  ${(props: ItemsColumnWrapperProps) =>
    props.$isProjectsList &&
    css`
      max-height: unset;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: min-content;
      overflow-y: auto;
    `}
`;
