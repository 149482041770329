import { color } from '../../../styles/Variables';
import { CSSObjectWithLabel } from 'react-select';

export const TeamSelectorStyles = {
  container: (styles: CSSObjectWithLabel) => ({
    ...styles,
    minWidth: 250,
  }),
  indicatorSeparator: (styles: CSSObjectWithLabel) => ({
    ...styles,
    display: 'none',
  }),
  dropdownIndicator: (styles: CSSObjectWithLabel) => ({
    ...styles,
  }),
  singleValue: (styles: CSSObjectWithLabel) => ({
    ...styles,
    color: color.primary[60],
  }),
  control: (styles: CSSObjectWithLabel) => ({
    ...styles,
    backgroundColor: 'transparent',
    border: 'none',
    paddingLeft: 0,
    cursor: 'pointer',
    boxShadow: 'none',
  }),
  option: (styles: CSSObjectWithLabel, state: { isSelected: boolean }) => ({
    ...styles,
    cursor: 'pointer',
    fontSize: 12,
    color: state.isSelected ? color.light : color.primary[60],
  }),
  menu: (styles: CSSObjectWithLabel) => ({
    ...styles,
    zIndex: 10,
  }),
};
