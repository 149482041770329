import React, { FunctionComponent } from 'react';
import CloseIcon from '../../../Icons/CloseIcon';
import { Icon } from '../Icon/Icon';
import { color } from '../../../styles/Variables';

type Props = {
  onClick: () => void;
  size?: number;
};

const CloseButton: FunctionComponent<Props> = ({ onClick, size = 1.5 }) => {
  return (
    <Icon onClick={onClick} size={size} color={color.primary[60]} reversedHover>
      <CloseIcon />
    </Icon>
  );
};

export default CloseButton;
