import styled from 'styled-components';
import { BodyText } from '../../../../styles/Typography';
import { color } from '../../../../styles/Variables';

export const CountPerPageSelectWrapper = styled.div`
  display: flex;
  padding: 0.6rem 1rem;
  border-radius: 8px;
  gap: 4px;
  background-color: ${color.neutral['30']};
  color: ${color.neutral['60']};
  cursor: pointer;
`;

export const CountPerPageSelect = styled.select`
  ${BodyText};
  border: none;
  background-color: ${color.neutral['30']};
  color: ${color.neutral['60']};
  cursor: pointer;
`;
