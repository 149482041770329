import styled from 'styled-components';

export const HistoryItem = styled.div`
  width: 100%;
  display: grid !important;
  grid-template-columns: 70% 20%;
  margin-bottom: 1.4rem;
`;

export const ActionWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
