import React, { FunctionComponent } from 'react';
import * as Styled from './Indicator.Styled';

type Props = {
  onClick: () => void;
};

const Indicator: FunctionComponent<Props> = (props) => {
  const handleClick = React.useCallback(() => {
    props.onClick();
  }, [props.onClick]);

  return (
    <Styled.Icon onClick={handleClick}>
      <svg viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.4173 20.8H3.07062C1.93164 20.8 1 19.8339 1 18.6529V11.7485C1 7.04646 4.70943 3.2 9.24397 3.2C13.7785 3.2 17.4879 7.04646 17.4879 11.7485V18.6529C17.4879 19.8339 16.5563 20.8 15.4173 20.8Z"
          strokeWidth="0.9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.8933 20.8V21.3214C10.8933 22.2448 10.151 23 9.2445 23C8.33798 23 7.5957 22.2448 7.5957 21.3214V20.8"
          stroke="#E5E5E5"
          strokeWidth="0.9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M9.79366 3.2V1" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </Styled.Icon>
  );
};

export default Indicator;
