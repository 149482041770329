import { useMemo } from 'react';

export const useTextShade = (color?: string) =>
  useMemo(() => {
    if (!color) {
      return;
    }
    const hexcolor = color.slice(1);
    const r = parseInt(hexcolor.slice(0, 2), 16);
    const g = parseInt(hexcolor.slice(2, 4), 16);
    const b = parseInt(hexcolor.slice(4, 6), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 154 ? 'dark' : 'light';
  }, [color]);
