import React, { FunctionComponent, useCallback, useContext, useState } from 'react';
import * as Styled from './TaskShortForm.styled';
import Form from '../../../../molecules/Form/Form';
import Button from '../../../../atoms/Button/Button';
import TaskNameInput from '../../../../molecules/Task/TaskForm/TaskNameInput/TaskNameInput';
import InlineInput from '../../../../molecules/Task/TaskForm/InlineInput/InlineInput';
import PersonIcon from '../../../../../Icons/Project/Person.icon';
import TaskEmployeeDropdown from '../../../../molecules/Task/TaskForm/TaskEmployeeDropdown/TaskEmployeeDropdown';
import TaskProjectDropdown from '../../../../molecules/Task/TaskForm/TaskProjectDropdown/TaskProjectDropdown';
import { ProjectType } from '../../../../../@types/Project/Project';
import { DictValue } from '../../../../../@types/Dictionary/DictValue';
import PriorityIcon from '../../../../../Icons/Project/Priority.icon';
import TaskPriorityDropdown from '../../../../molecules/Task/TaskForm/TaskPriorityDropdown/TaskPriorityDropdown';
import { TaskPriorityType } from '../../../../../@types/Task/TaskType';
import CalendarIcon from '../../../../../Icons/Calendar.icon';
import TaskDeadlineDropdown from '../../../../molecules/Task/TaskForm/TaskDeadlineDropdown/TaskDeadlineDropdown';
import TaskEstimationDropdown from '../../../../molecules/Task/TaskForm/TaskEstimationDropdown/TaskEstimationDropdown';
import EstimationIcon from '../../../../../Icons/Project/Estimation.icon';
import TaskFormContext from '../../../../../Context/TaskFormContext/TaskFormContext';
import CloseButton from '../../../../atoms/CloseButton/CloseButton';
import useApi from '../../../../../hooks/useApi';
import PriorityTag from 'components/molecules/PriorityTag/PriorityTag';
import Text from '../../../../atoms/Text';
import AcceptIcon from '../../../../../Icons/Accept.icon';
import { CloseButtonContainer, Column, InnerContainer, Line, Row } from '../CommonStyles';
import Tooltip from '../../../../molecules/Tooltip/Tooltip';
import RichTextInput from '../../../../molecules/Form/RichTextInput/RichTextInput';

type Props = {};

type TaskDataType = {
  name: string;
  description: string;
  assignee: string;
  project: string;
};

const TaskShortForm: FunctionComponent<Props> = (props) => {
  const { closeForm } = useContext(TaskFormContext);
  const { post } = useApi();
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback((data: TaskDataType) => {
    setLoading(true);
    post('/api/tasks', data)
      .then((response) => {
        closeForm();
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  return (
    <Styled.ShortFormContainer>
      <Form onSubmit={handleSubmit}>
        <InnerContainer>
          <TaskNameInput />
          <Row>
            <Column>
              <Text bold color={'grey'}>
                For
              </Text>
              <InlineInput name={'assignee'} icon={<PersonIcon />} dropdown={TaskEmployeeDropdown} dropdownOnTop />
            </Column>
            <Column>
              <Text bold color={'grey'}>
                in project
              </Text>
              <InlineInput
                name={'project'}
                shape={'square'}
                dropdown={TaskProjectDropdown}
                parseValue={(v: DictValue<ProjectType>) => (
                  <Line>
                    <Text
                      color={'primary'}
                      style={{
                        display: 'inline',
                        textWrap: 'nowrap',
                        marginRight: '0.35rem',
                      }}
                      bold
                    >
                      #{v.meta?.id}
                    </Text>
                    <Text color={'primary'} bold style={{ display: 'inline', textWrap: 'nowrap' }}>
                      {v.label}
                    </Text>
                  </Line>
                )}
              />
            </Column>
          </Row>
          <RichTextInput name={'description'} label={'Description'} placeholder={'Write some awesome description'} />
          <Row>
            <InlineInput name={'reviewer'} icon={<PersonIcon />} dropdown={TaskEmployeeDropdown} dropdownOnTop />
            <InlineInput
              name={'priority'}
              icon={<PriorityIcon />}
              dropdown={TaskPriorityDropdown}
              parseValue={(value: DictValue<TaskPriorityType>) => <PriorityTag priority={value.value} />}
              dropdownOnTop
            />
            <InlineInput name={'deadline'} icon={<CalendarIcon />} dropdown={TaskDeadlineDropdown} dropdownOnTop />
            <InlineInput name={'estimate'} icon={<EstimationIcon />} dropdown={TaskEstimationDropdown} dropdownOnTop preventDefault />
          </Row>
          <Row style={{ justifyContent: 'space-between' }}>
            <Button $text={'Add task'} type={'submit'} waiting={loading} $icon={<AcceptIcon />} />
            <Button $text={'Cancel'} kind={'link'} onClick={closeForm} />
          </Row>
        </InnerContainer>
      </Form>
      <CloseButtonContainer>
        <CloseButton onClick={closeForm} size={1.7} />
      </CloseButtonContainer>
    </Styled.ShortFormContainer>
  );
};

export default TaskShortForm;
