import React, { FunctionComponent } from 'react';
import Collapse from '../../../../../components/atoms/Collapse/Collapse';
import { WorkloadType } from '../../../../../@types/Workload/WorkloadType';
import EmployeeWorkloadCollapseBody from './EmployeeWorkloadCollapseBody';
import EmployeeWorkloadCollapseHeader from './EmployeeWorkloadCollapseHeader';
import Text from '../../../../../components/atoms/Text';

type Props = {
  workloadItem: WorkloadType;
};

const EmployeeWorkloadCollapse: FunctionComponent<Props> = ({ workloadItem }) => {
  return (
    <>
      <Collapse
        header={<EmployeeWorkloadCollapseHeader workloadItem={workloadItem} />}
        hidden={
          workloadItem.tasks.length > 0 || workloadItem.meetings.length > 0 ? (
            <EmployeeWorkloadCollapseBody workloadTasks={workloadItem.tasks} workloadMeetings={workloadItem.meetings} key={workloadItem.id} />
          ) : (
            <Text bold color={'grey'} style={{ marginTop: '1.6rem' }}>
              No tasks or meetings assigned
            </Text>
          )
        }
        style={{ marginBottom: 'unset' }}
      />
    </>
  );
};

export default EmployeeWorkloadCollapse;
