import React, { FunctionComponent } from 'react';
import TablePage from '../../../../../../components/organisms/TablePage/TablePage';
import protocolsTableFilters from './ProtocolsTable.filters';
import protocolsTableProperties from './ProtocolsTable.properties';
import InventoryProtocolItemExtendedRow from './InventoryProtocolItemExtendedRow/InventoryProtocolItemExtendedRow';

type Props = {};

const ProtocolsScreen: FunctionComponent<Props> = () => {
  return (
    <TablePage
      url={'/api/inventory_allocations'}
      context={'/api/inventory_allocations'}
      filters={protocolsTableFilters}
      properties={protocolsTableProperties}
      heading={'Protocols'}
      extendedRow={InventoryProtocolItemExtendedRow}
    />
  );
};

export default ProtocolsScreen;
