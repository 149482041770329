import React, { FunctionComponent } from 'react';
import Text from '../../../atoms/Text/Text';
import { GridItem, GridItemProps } from '../../../atoms/Grid/Grid';
import IdText from '../../IdText/IdText';
import * as Styled from '../Common.styled';
import { Link } from 'react-router-dom';
import { shorten } from '../../../../helpers/shorten';
import { TextSizeType } from '../../../../@types/Text/TextSizeType';
import { ColorType } from '../../../../@types/Color/ColorType';

type Props = GridItemProps & {
  detailTitle?: string;
  name: string;
  id: number;
  hideTitle?: boolean;
  size?: TextSizeType;
  bold?: boolean;
  color?: ColorType;
};

const ProjectSingleDetail: FunctionComponent<Props> = ({ name, id, detailTitle, $desktop = 'auto', hideTitle, size, bold, color, ...props }) => {
  return (
    <GridItem style={{ display: 'flex', flexDirection: 'column' }} {...props} $desktop={$desktop}>
      {!hideTitle && (
        <Text color={'grey'} size={'s'} bold>
          {detailTitle ? detailTitle : 'Project'}
        </Text>
      )}
      <Styled.ClickableSingleDetail>
        <Link to={`/projects/projects/${id}/overview`} target={'_blank'}>
          <GridItem style={{ display: 'flex', gap: '0.3rem', alignItems: 'center' }}>
            <IdText withHash={true} value={id} size={size ?? 'l'} bold={bold} />
            <Text size={size ?? 'l'} bold={bold} color={color ?? 'darkGrey'}>
              {shorten(name, 30, 30)}
            </Text>
          </GridItem>
        </Link>
      </Styled.ClickableSingleDetail>
    </GridItem>
  );
};

export default ProjectSingleDetail;
