import React, { FunctionComponent } from 'react';
import Heading from '../../atoms/Heading';
import * as Styled from './CardHeading.styled';
import { Icon } from '../../atoms/Icon/Icon';
import { Grid, GridItem } from '../../atoms/Grid/Grid';

type Props = {
  heading: string;
  icon?: JSX.Element;
  action?: JSX.Element;
  $noMargin?: boolean;
  rightElement?: JSX.Element;
};

const CardHeading: FunctionComponent<Props> = ({ heading, action, icon, $noMargin = false, rightElement }) => {
  return (
    <Styled.CardHeadingWrapper $noMargin={$noMargin}>
      <Grid>
        <GridItem $desktop={'max'}>
          <Styled.CardHeading>
            <Styled.CardHeading>
              {icon && <Icon size={1.5}>{icon}</Icon>}
              <Heading size={'m'} noPadding={true}>
                {heading}
              </Heading>
            </Styled.CardHeading>
            {rightElement}
          </Styled.CardHeading>
        </GridItem>
        <GridItem>{action}</GridItem>
      </Grid>
    </Styled.CardHeadingWrapper>
  );
};

export default CardHeading;
