import React, { FunctionComponent } from 'react';

type PropType = {
  size?: number;
  color?: string;
};

const SandglassInCircle: FunctionComponent<PropType> = ({ size = 1, color }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M10.05 15.9H13.95V14.4C13.95 13.8583 13.7604 13.3979 13.3812 13.0187C13.0021 12.6395 12.5417 12.45 12 12.45C11.4583 12.45 10.9979 12.6395 10.6187 13.0187C10.2396 13.3979 10.05 13.8583 10.05 14.4V15.9ZM12 11.55C12.5417 11.55 13.0021 11.3604 13.3812 10.9812C13.7604 10.602 13.95 10.1416 13.95 9.59995V8.09995H10.05V9.59995C10.05 10.1416 10.2396 10.602 10.6187 10.9812C10.9979 11.3604 11.4583 11.55 12 11.55ZM8.39999 16.8V15.9H9.14999V14.4C9.14999 13.9083 9.26666 13.452 9.49999 13.0312C9.73333 12.6104 10.0583 12.2666 10.475 12C10.0667 11.7333 9.74374 11.3895 9.50624 10.9687C9.26874 10.5479 9.14999 10.0916 9.14999 9.59995V8.09995H8.39999V7.19995H15.6V8.09995H14.85V9.59995C14.85 10.0916 14.7312 10.5479 14.4937 10.9687C14.2562 11.3895 13.9333 11.7333 13.525 12C13.9417 12.2666 14.2667 12.6104 14.5 13.0312C14.7333 13.452 14.85 13.9083 14.85 14.4V15.9H15.6V16.8H8.39999Z"
          fill="currentColor"
        />
      </g>
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="currentColor" />
    </svg>
  );
};

export default SandglassInCircle;
