import React from 'react';

export const ErrorIcon: React.FC = () => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 0C5.36756 0 0 5.36705 0 12C0 18.6325 5.36705 24 12 24C18.6324 24 24 18.633 24 12C24 5.36752 18.633 0 12 0ZM12 22.125C6.40387 22.125 1.875 17.5965 1.875 12C1.875 6.40383 6.40345 1.875 12 1.875C17.5961 1.875 22.125 6.40345 22.125 12C22.125 17.5962 17.5965 22.125 12 22.125Z"
        fill="#EA0C0C"
        strokeWidth={0.5}
      />
      <path
        d="M16.1056 14.7798L13.3258 12L16.1056 9.22023C16.4717 8.85414 16.4717 8.26056 16.1056 7.89442C15.7395 7.52828 15.1459 7.52832 14.7798 7.89442L12 10.6742L9.22018 7.89442C8.85413 7.52828 8.26046 7.52828 7.89436 7.89442C7.52827 8.26056 7.52827 8.85414 7.89441 9.22023L10.6742 12L7.89441 14.7798C7.52827 15.1459 7.52822 15.7395 7.89436 16.1056C8.2606 16.4718 8.85418 16.4716 9.22018 16.1056L12 13.3258L14.7798 16.1056C15.1458 16.4717 15.7395 16.4717 16.1056 16.1056C16.4718 15.7395 16.4717 15.1459 16.1056 14.7798Z"
        fill="#EA0C0C"
        strokeWidth={0.5}
      />
    </svg>
  );
};

export default ErrorIcon;
