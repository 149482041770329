import { color, fontSize, fontWeight } from '../../../../styles/Variables';
import { CSSObjectWithLabel, OptionProps } from 'react-select';

export const selectStyles = (hasError: boolean | undefined, required?: boolean, disabled?: boolean) => ({
  container: (styles: CSSObjectWithLabel) => ({
    ...styles,
    margin: 0,
  }),
  control: (styles: CSSObjectWithLabel) => ({
    ...styles,
    fontSize: fontSize.body.m,
    fontWeight: fontWeight.bold,
    color: hasError ? color.semantic.error[100] : color.neutral[60],
    borderRadius: 0,
    cursor: 'pointer',
    borderWidth: 0,
    padding: 0,
    margin: 0,
    boxShadow: 'none',
    height: 0,
    background: 'transparent',
    minHeight: 32,
  }),
  indicatorsContainer: (styles: CSSObjectWithLabel) => ({
    ...styles,
  }),
  valueContainer: (styles: CSSObjectWithLabel) => ({
    ...styles,
    paddingLeft: 0,
  }),
  singleValue: (styles: CSSObjectWithLabel) => ({
    ...styles,
    color: disabled ? color.neutral[50] : color.neutral[80],
    paddingLeft: 0,
    marginLeft: 0,
    paddingBottom: 8,
  }),
  input: (styles: CSSObjectWithLabel) => ({
    ...styles,
    fontSize: fontSize.body.m,
    padding: 0,
    margin: 0,
    color: hasError ? color.semantic.error[100] : color.neutral[80],
    fontWeight: fontWeight.bold,
    paddingBottom: 8,
  }),
  placeholder: (styles: CSSObjectWithLabel) => ({
    ...styles,
    fontSize: fontSize.body.m,
    fontWeight: fontWeight.bold,
    color: hasError ? color.semantic.error[100] : color.neutral[50],
    padding: 0,
    margin: 0,
    paddingBottom: 8,
  }),
  menu: (styles: CSSObjectWithLabel) => ({
    ...styles,
    minHeight: 48,
    padding: 0,
    borderRadius: 0,
  }),
  menuList: (styles: CSSObjectWithLabel) => ({
    ...styles,
    backgroundColor: color.light,
    borderRadius: 0,
    boxShadow: 'box-shadow: 0px 2px 4px rgba(0, 0, 0, .2)',
    padding: 0,
  }),
  option: (styles: CSSObjectWithLabel, state: OptionProps<any, any>) => ({
    ...styles,
    transition: 'all .3s ease-in-out',
    transitionProperty: 'color, background-color',
    fontSize: fontSize.body.s,
    cursor: 'pointer',
    backgroundColor: state.isSelected || state.isFocused ? color.primary[20] : color.light,
    color: color.neutral[80],
    '&:hover': {
      color: color.primary[60],
      backgroundColor: state.isDisabled ? 'white' : color.primary[10],
      cursor: state.isDisabled ? 'unset' : 'pointer',
    },
    '&:active': {
      backgroundColor: color.primary[10],
    },
  }),
});
