import React, { FunctionComponent, useCallback, useState } from 'react';
import Form from '../Form/Form';
import SearchOption from './SearchOption';
import api from '../../../services/api';
import useModal from '../../../hooks/useModal';
import { ModalFooter } from './Modal';
import Button from '../../atoms/Button/Button';
import Select from '../Form/SelectInput/SelectInput';
type Props = {
  projectId?: string | number;
  dataLoad: () => void;
};

// TODO: zrobić generyczny
const AddToProjectModal: FunctionComponent<Props> = ({ projectId, dataLoad }) => {
  const { hideModal } = useModal();
  const [waiting, setWaiting] = useState(false);

  const handleSubmit = useCallback((data: { checklists: string }) => {
    setWaiting(true);
    api
      .post(`${data.checklists}/assign`, {
        project: `/api/projects/${projectId}`,
      })
      .then(() => {
        dataLoad();
        hideModal();
        setWaiting(false);
      });
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <Select name={'checklists'} label={'Select checklist'} baseUrl={'/wiki/checklist_templates'} optionComponent={SearchOption} />
      <div style={{ height: '20rem' }} />
      <ModalFooter>
        <Button $text={'Add to Project'} type="submit" waiting={waiting} />
      </ModalFooter>
    </Form>
  );
};

export default AddToProjectModal;
