import styled from 'styled-components';
import { color } from '../../../../../styles/Variables';

export const ExportBottomContainer = styled.div<{ $show: boolean }>`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  height: 6rem;
  background-color: ${color.primary[60]};
  color: ${color.light};
  display: ${({ $show }) => ($show ? 'flex' : 'none')};
  gap: 3rem;
  padding: 2rem;
  justify-content: flex-end;
  align-items: center;
  z-index: 50;
`;
