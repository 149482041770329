import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import ModuleContext from '../Context/ModuleContext/ModuleContext';
import { FloatingButtonsContainer } from '../components/atoms/FloatingButton/FloatingButton';

type Props = {
  module: any;
  buttons?: ReactElement[] | ReactElement;
};

const ModuleContainer: FunctionComponent<Props> = ({ module, buttons }) => {
  const { setModule } = React.useContext(ModuleContext);
  useEffect(() => {
    setModule(module);
  }, [module]);
  return (
    <>
      <Outlet />
      <FloatingButtonsContainer $right>{buttons}</FloatingButtonsContainer>
    </>
  );
};

export default ModuleContainer;
