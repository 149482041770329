import React, { FunctionComponent } from 'react';
import * as Components from './FinancialCompanySelector.styled';
import { searchSelectStyles } from '../Search/SearchSelectStyles';
import api from '../../../../services/api';
import { AxiosResponse } from 'axios';
import { FinancialCompanyContext } from '../../../../Context/BranchContext/FinancialCompanyContext';
import Select, { OnChangeValue } from 'react-select';
import FinancialCompanySelectorControl from './FinancialCompanySelectorControl';
import { FinancialCompanyType } from '../../../../@types/FinancialCompany/FinancialCompanyType';

type ApiResponse = AxiosResponse<{
  'hydra:member': FinancialCompanyType[];
}>;

const FinancialCompanySelector: FunctionComponent = () => {
  const { setFinancialCompany } = React.useContext(FinancialCompanyContext);
  const [options, setOptions] = React.useState<{ value: string; label: string; meta: FinancialCompanyType }[] | undefined>();
  const [internalValue, setInternalValue] = React.useState<{ value: string; label: string; meta: FinancialCompanyType } | null | undefined>();
  const loadOptions = React.useCallback(() => {
    void api.get('/exchange/companies').then((response: ApiResponse) => {
      const options = response.data['hydra:member'].map((branch) => ({
        label: branch.name,
        value: branch['@id'],
        meta: branch,
      }));
      setOptions(options);
      setInternalValue(options[0]);
    });
  }, []);

  React.useEffect(() => {
    loadOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (internalValue) {
      setFinancialCompany(internalValue.meta);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalValue]);

  const handleChange = React.useCallback(
    (value: OnChangeValue<{ value: string; label: string; meta: FinancialCompanyType }, false>) => {
      setInternalValue(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setFinancialCompany],
  );

  return (
    <Components.Container>
      <Select
        placeholder="Select branch"
        onChange={handleChange}
        options={options}
        value={internalValue}
        styles={searchSelectStyles}
        components={{
          Control: FinancialCompanySelectorControl,
          IndicatorSeparator: null,
        }}
      />
    </Components.Container>
  );
};

export default FinancialCompanySelector;
