import React, { FunctionComponent } from 'react';

type Props = {
  size?: number;
};

const UnpublishedIcon: FunctionComponent<Props> = ({ size = 1 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={`${size}em`} height={`${size}em`} viewBox="0 0 16 16" fill="none">
      <g>
        <path
          d="M6.26671 9.33334L8.00004 7.60001L9.73337 9.33334L10.6667 8.40001L8.93337 6.66668L10.6667 4.93334L9.73337 4.00001L8.00004 5.73334L6.26671 4.00001L5.33337 4.93334L7.06671 6.66668L5.33337 8.40001L6.26671 9.33334ZM1.33337 14.6667V2.66668C1.33337 2.30001 1.46393 1.98612 1.72504 1.72501C1.98615 1.4639 2.30004 1.33334 2.66671 1.33334H13.3334C13.7 1.33334 14.0139 1.4639 14.275 1.72501C14.5362 1.98612 14.6667 2.30001 14.6667 2.66668V10.6667C14.6667 11.0333 14.5362 11.3472 14.275 11.6083C14.0139 11.8695 13.7 12 13.3334 12H4.00004L1.33337 14.6667ZM3.43337 10.6667H13.3334V2.66668H2.66671V11.4167L3.43337 10.6667Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default UnpublishedIcon;
