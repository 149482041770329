import React, { FunctionComponent } from 'react';
import { Route, Routes } from 'react-router-dom';
import WikiMainPage from './pages/WikiMainPage';
import WikiCategoryForm from '../../../../components/templates/Projects/Wiki/WikiCategoryForm';
import WikiArticleForm from '../../../../components/templates/Projects/Wiki/WikiArticleForm';
import ChecklistTemplateForm from '../../../../components/organisms/Projects/ChecklistTemplate/ChecklistTemplateForm';
import { Helmet } from 'react-helmet';

type Props = {};

const ProjectsWiki: FunctionComponent<Props> = (props) => {
  return (
    <>
      <Helmet>
        <title>Wiki | F.CAPE</title>
      </Helmet>
      <Routes>
        <Route path={`categories/create`} element={<WikiCategoryForm />}></Route>
        <Route path={`categories/:id/edit`} element={<WikiCategoryForm />}></Route>
        <Route path={`articles/create`} element={<WikiArticleForm />}></Route>
        <Route path={`articles/:id/edit`} element={<WikiArticleForm />}></Route>
        <Route path={`articles/:articleId/create-checklist`} element={<ChecklistTemplateForm />}></Route>
        <Route path={`articles/:articleId/checklists/:id/edit`} element={<ChecklistTemplateForm />}></Route>
        <Route path="" element={<WikiMainPage />}></Route>
        <Route path="*" element={<WikiMainPage />}></Route>
      </Routes>
    </>
  );
};

export default ProjectsWiki;
