import React, { memo, useContext, useMemo } from 'react';
import ExtendedRowComponent from '../../../atoms/Table/ExtendedRowComponent';
import { AbsenceType } from '../../../../@types/Absences/AbsenceType';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import { parseDate } from '../../../../helpers/date';
import * as Styled from './AbsenceItemExtendedRow.styled';
import CancelButton from './Buttons/CancelButton';
import { AuthContext } from '../../../../Context/auth-context/auth-context';
import AcceptButton from './Buttons/AcceptButton';
import RejectButton from './Buttons/RejectButton';
import EditButton from './Buttons/EditButton';
import SingleDetail from '../../../molecules/SingleDetail/SingleDetail';
import TableCell from '../../../atoms/Table/TableCell';

const AbsencesItemExtendedRow: ExtendedRowComponent<AbsenceType> = ({ entity }) => {
  const {
    can,
    state: { user },
  } = useContext(AuthContext);

  const canBeCancelled = useMemo(() => {
    if (entity.absenceType === 'sick_leave' && can('ROLE_SUPER_ADMIN')) {
      return true;
    } else if (entity.absenceType === 'sick_leave') {
      return false;
    }

    if (entity.status !== 'pending' && entity.status !== 'accepted') {
      return false;
    }

    if (entity.status === 'accepted' && can('ROLE_SUPER_ADMIN')) {
      return true;
    }

    let isOverdue = new Date(entity.dateFrom) <= new Date();

    if (isOverdue) {
      return can('ROLE_SUPER_ADMIN');
    } else {
      if (user && entity.person.id === user.employeeId) {
        return true;
      }
    }
    return false;
  }, [entity.absenceType, entity.status]);

  const canBeAcceptedOrRejected = useMemo(() => {
    if (entity.status !== 'pending') {
      return false;
    }
    return entity.canBeAccepted;
  }, [entity.status, entity.canBeAccepted]);

  const canBeEdited = useMemo(() => {
    if (entity.absenceType === 'sick_leave' && can('ROLE_SUPER_ADMIN')) {
      return false;
    } else if (entity.absenceType === 'sick_leave') {
      return false;
    }
    if (entity.status !== 'pending') {
      return false;
    }
    return !!(user && entity.person.id == user.employeeId);
  }, [entity.absenceType, entity.status]);

  return (
    <Styled.AbsenceItemExtendedRow>
      <TableCell colSpan={7}>
        <Grid spacing={2}>
          <GridItem $desktop={1}>
            <SingleDetail title={'Creation date'}>{parseDate(entity.createdAt)}</SingleDetail>
          </GridItem>
          <GridItem $desktop={2}>
            <SingleDetail title={'Comment'}>{entity.comment}</SingleDetail>
          </GridItem>
          <GridItem $desktop={'max'} />
          <GridItem>
            <Grid spacing={1}>
              <GridItem> {canBeAcceptedOrRejected && <AcceptButton absence={entity} />}</GridItem>
              <GridItem> {canBeCancelled && <CancelButton absence={entity} />}</GridItem>
              <GridItem> {canBeAcceptedOrRejected && <RejectButton absence={entity} />}</GridItem>
              <GridItem> {canBeEdited && <EditButton absence={entity} />}</GridItem>
            </Grid>
          </GridItem>
        </Grid>
      </TableCell>
      <TableCell></TableCell>
    </Styled.AbsenceItemExtendedRow>
  );
};

export default memo(AbsencesItemExtendedRow);
