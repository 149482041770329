import React, { FunctionComponent } from 'react';
import Container from '../../../../components/atoms/Container/Container';
import { Route, Routes } from 'react-router-dom';
import ContractsList from '../../../../components/templates/Contracts/ContractsList/ContractsList';
import ContractShowView from '../../../../components/templates/Contracts/ContractShow/ContractShowView';
import ContractFormEditView from '../../../../components/templates/Contracts/ContractForm/ContractFormEditView';
import ContractForm from '../../../../components/templates/Contracts/ContractForm/ContractForm';
import { AuthContext } from '../../../../Context/auth-context/auth-context';
import { Helmet } from 'react-helmet';

const ContractsSubmodule: FunctionComponent = () => {
  const { can } = React.useContext(AuthContext);
  return (
    <Container>
      <Helmet>
        <title>Contracts | F.CAPE</title>
      </Helmet>
      <Routes>
        <Route path="" element={<ContractsList />}></Route>
        <Route path=":id/show" element={<ContractShowView />}></Route>
        {can('FINANCES.CONTRACTS.CAN_MANAGE') && <Route path=":id/edit" element={<ContractFormEditView />}></Route>}
        {can('FINANCES.CONTRACTS.CAN_MANAGE') && <Route path="create" element={<ContractForm />}></Route>}
      </Routes>
    </Container>
  );
};

export default ContractsSubmodule;
