import React, { FunctionComponent, useCallback, useState } from 'react';
import { ContractorType } from '../../../../@types/Finances/Contractor/ContractorType';
import api from '../../../../services/api';
import { AxiosError, AxiosResponse } from 'axios';
import SnackContainer from '../../../../legacy/CapeMorris/components/snack-bar/use-snack-container';
import Form from '../../../molecules/Form/Form';
import useModal from '../../../../hooks/useModal';
import { ModalFooter } from '../../../molecules/Modal/Modal';
import Button from '../../../atoms/Button/Button';
import { TextInput } from '../../../molecules/Form/TextInput/TextInput';
import Select from '../../../molecules/Form/SelectInput/SelectInput';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';

type Props = {
  entity: ContractorType;
  onSuccess: (entity: ContractorType) => void;
};

type ResponseType = AxiosResponse<ContractorType>;
type ResponseErrorType = AxiosError<{
  'hydra:description': string;
}>;

const EditBankModal: FunctionComponent<Props> = ({ entity, ...props }) => {
  const { hideModal } = useModal();
  const [waiting, setWaiting] = useState(false);
  const { showSnack } = SnackContainer.useContainer();
  const handleSubmit = useCallback(
    (data: ContractorType) => {
      setWaiting(true);
      api
        .put(`${entity['@id'].substr(4)}/change-bank`, data)
        .then((response: ResponseType) => {
          setWaiting(false);
          props.onSuccess(response.data);
          showSnack({
            title: 'Success',
            message: 'Bank account details changed successfully',
          });
          hideModal();
        })
        .catch((error: ResponseErrorType) => {
          setWaiting(false);
          const response = error.response;
          showSnack({
            type: 'error',
            title: 'Error',
            message: response?.data['hydra:description'] ?? 'Unexpected error occurred',
          });
          hideModal();
        });
    },
    [entity],
  );
  return (
    <Form onSubmit={handleSubmit} defaultValues={entity}>
      <Grid spacing={2} $align={'center'}>
        <GridItem $desktop={6}>
          <TextInput
            name={'bank.number'}
            label={'IBAN'}
            required
            placeholder={'PL17958411065867915407528914'}
            pattern={{
              value: /^[A-Z]{2}\d{2}(?:[ ]?[A-Z0-9]){10,30}$/,
              message: 'Invalid IBAN number. Remember it should starts with two letters',
            }}
          />
        </GridItem>
        <GridItem $desktop={6}>
          <Select
            name={'bank.currency'}
            label={'Currency'}
            required
            options={[
              { value: 'PLN', label: 'PLN' },
              { value: 'EUR', label: 'EUR' },
              { value: 'GBP', label: 'GBP' },
              { value: 'USD ', label: 'USD' },
            ]}
          />
        </GridItem>
        <GridItem $desktop={6}>
          <TextInput name={'bank.name'} label={'Name'} required placeholder={'Central Bank'} />
        </GridItem>
        <GridItem $desktop={6}>
          <TextInput name={'bank.swift'} label={'SWIFT'} required placeholder={'PL000000000'} />
        </GridItem>
      </Grid>
      <ModalFooter>
        <GridItem $desktop={'auto'}>
          <Button $text="Save" kind={'primary'} waiting={waiting} type={'submit'} />
        </GridItem>
      </ModalFooter>
    </Form>
  );
};

export default EditBankModal;
