import React, { FunctionComponent } from 'react';
import { NotificationType } from '../../../../@types/Notification/NotificationType';
import * as Styled from '../Notification.styled';
import { Icon } from '../../../atoms/Icon/Icon';
import { color } from '../../../../styles/Variables';
import AcceptIcon from '../../../../Icons/Accept.icon';
import Text from '../../../atoms/Text';
import Chip from '../../../atoms/Chips/Chip';
import ArrowLongIcon from '../../../../Icons/ArrowLong.icon';
import WarningIcon from '../../../../Icons/warning.icon';

type Props = {
  notification: NotificationType;
  timeComponent: JSX.Element;
  indicatorComponent: JSX.Element;
};

const InventoryNotification: FunctionComponent<Props> = ({ notification, timeComponent, indicatorComponent }) => {
  return (
    <>
      <Styled.TopWrapper>
        {indicatorComponent}
        {timeComponent}
        {notification.notificationType === 'inventory_release' && (
          <Chip $size={'s'} $bgColor={color.semantic.success[40]}>
            <Icon>
              <ArrowLongIcon arrowColor={color.semantic.success[80]} />
            </Icon>
            <Text size={'xs'} color={'success'}>
              Release
            </Text>
          </Chip>
        )}
        {notification.notificationType === 'inventory_return' && (
          <Chip $size={'s'} $bgColor={color.semantic.warning[40]}>
            <Icon>
              <ArrowLongIcon arrowColor={color.semantic.warning[80]} rotate={180} />
            </Icon>
            <Text size={'xs'} color={'warning'}>
              Return
            </Text>
          </Chip>
        )}
        {notification.notificationType === 'inventory_warranty' && (
          <Chip $size={'s'} $bgColor={color.semantic.error[40]}>
            <Icon>
              <WarningIcon iconColor={color.semantic.error[100]} />
            </Icon>
            <Text size={'xs'} color={'error'}>
              Warranty
            </Text>
          </Chip>
        )}
        {notification.notificationType === 'inventory_confirmed' && (
          <Chip $size={'s'} $bgColor={color.primary[40]}>
            <Icon color={color.primary[80]}>
              <AcceptIcon />
            </Icon>
            <Text size={'xs'} color={'primary'}>
              Confirmed
            </Text>
          </Chip>
        )}
      </Styled.TopWrapper>
      <Styled.ContentTitle size={'l'} bold={true}>
        {notification.subject}
      </Styled.ContentTitle>
      <Text size={'m'} color={'semiGrey'}>
        {notification.subtext}
      </Text>
    </>
  );
};

export default InventoryNotification;
