import { MoneyType } from '../@types/Money/MoneyType';

const moneyAsString = (money?: MoneyType): string => {
  if (!money) {
    return '';
  }
  if (!money?.currency) {
    return '';
  }
  const intlValue = new Intl.NumberFormat('pl', {
    style: 'currency',
    currency: money.currency,
    currencyDisplay: 'code',
  }).format(money.value / 100);

  return intlValue.replace(/(\d)(\d{3})/i, '$1 $2');
};

export default moneyAsString;
