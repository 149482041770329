import { CellTemplate } from '../../CellTemplate';
import { MoneyType } from '../../../../../@types/Money/MoneyType';
import React from 'react';
import moneyAsString from '../../../../../helpers/moneyAsString';
import Text from '../../../../atoms/Text/Text';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import { ColorType } from '../../../../../@types/Color/ColorType';
import { DictValue } from '../../../../../@types/Dictionary/DictValue';
import { TextSizeType } from '../../../../../@types/Text/TextSizeType';

const MoneyTemplate: CellTemplate<MoneyType, { textProperties?: { bold?: boolean; size?: TextSizeType; color?: ColorType } }> = ({ value, options = {} }) => {
  const computedColor: ColorType = React.useMemo<ColorType>(() => {
    if (options?.textProperties?.color) {
      return options.textProperties.color;
    }
    if (value?.value === 0 && options.textProperties?.color) {
      return options.textProperties.color;
    }

    if (!options.textProperties?.color) {
      return 'darkGrey';
    }

    if (value?.value > 0) {
      return 'success';
    } else {
      return 'error';
    }
  }, [value, options]);

  function extractCurrency(str?: string) {
    if (!str) return null;
    const regex = /\b[A-Z]{3}\b/;
    const match = str.match(regex);
    return match ? match[0] : null;
  }

  return (
    <Grid style={{ padding: '0 0 0 2rem' }}>
      <GridItem>
        <Text
          color={computedColor}
          size={options?.textProperties?.size ?? 'l'}
          bold={true}
          style={{ whiteSpace: 'nowrap', wordWrap: 'break-word', overflow: 'auto' }}
        >
          {moneyAsString(value)}
        </Text>
      </GridItem>
    </Grid>
  );
};

export default MoneyTemplate;
