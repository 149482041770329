import React, { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputProps } from '../../../../@types/Form/InputProps';
import { ErrorMessages } from '../../../atoms/Form/FormMessages/ErrorMessages';
import { FormHtmlInput, FormInputContainer, FormLabel } from '../../../atoms/Form/InputParts/InputParts';
import FormError from '../../../atoms/Form/FormError/FormError';
import useFormError from '../../../../hooks/Form/useFormError';

type TextInputProps = InputProps & {
  password?: boolean;
  style?: React.CSSProperties;
  tooltip?: ReactNode;
  pattern?: {
    value: RegExp;
    message: string;
  };
};
export const TextInput: React.FC<TextInputProps> = ({
  name,
  inputRef,
  password = false,
  label,
  placeholder,
  disabled = false,
  style,
  defaultValue,
  required = false,
  onChange,
  tooltip,
  pattern,
}) => {
  const { register, getValues } = useFormContext();
  const error = useFormError(name);

  return (
    <>
      <FormInputContainer style={style} status={error && 'error'}>
        <FormLabel status={error && 'error'} required={required}>
          {label ? label : name} {tooltip}
        </FormLabel>
        <FormHtmlInput
          status={error && 'error'}
          type={password ? 'password' : 'text'}
          id={name}
          onInput={() => (onChange ? onChange(getValues(name)) : null)}
          {...register(name, { required: required && ErrorMessages.required, pattern: pattern, onChange: onChange })}
          placeholder={placeholder ?? label ?? name}
          disabled={disabled}
          defaultValue={defaultValue}
        />
      </FormInputContainer>
      <FormError name={name} />
    </>
  );
};
