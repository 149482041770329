import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Grid, GridItem } from '../../../../components/atoms/Grid/Grid';
import SwitchButton from '../../../../components/molecules/SwitchButton/SwitchButton';
import * as Styled from './ProjectsHome.styled';
import ProjectsHomeHeader from './components/ProjectsHomeHeader/ProjectsHomeHeader';
import api from '../../../../services/api';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { DashboardData } from '../../../../@types/Dashboard/DashboardDataType';

type Props = {};

const ProjectsHome: FunctionComponent<Props> = () => {
  const [data, setData] = useState<DashboardData>();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const loadDashboardData = useCallback(() => {
    api.get<DashboardData>('/dashboard').then((r) => {
      setData(r.data);
    });
  }, []);

  useEffect(() => {
    loadDashboardData();
  }, []);

  return (
    <Grid $align={'center'} $justifyContent={'center'} style={{ margin: '4rem 0' }}>
      <GridItem $desktop={'max'}>
        <Styled.HeaderWrapper>
          <ProjectsHomeHeader data={data} />
          <SwitchButton
            leftButton={{
              onClick: () => navigate('../feed'),
              name: 'Feed',
              isActive: pathname === '/projects/feed',
              label: data?.unseenFeedEntries || 0,
            }}
            rightButton={{
              onClick: () => navigate('../dashboard'),
              name: 'Dashboard',
              isActive: pathname === '/projects/dashboard',
            }}
          />
        </Styled.HeaderWrapper>
        <Outlet context={[data]} />
      </GridItem>
    </Grid>
  );
};

export default ProjectsHome;
