import styled from 'styled-components';
import { WEIGHTS } from '../../../../../assets/fonts';
import { color } from '../../../../../styles/Variables';

export const Container = styled.div`
  background-color: ${color.light};
  font-size: 13px;
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  align-content: center;
  align-items: center;
  height: 60px;
  margin: auto 50px;
  border-bottom: 1px solid ${color.neutral['40']};

  @media (max-width: 1440px) {
    margin: auto 30px;
  }
`;

export const Burger = styled.div`
  margin-right: 18px;
  cursor: pointer;
  transition: transform 0.2s ease;

  :active {
    transform: scale(0.9);
  }
`;

export const ModuleName = styled.span`
  font-weight: ${WEIGHTS.BOLD};
  color: ${color.primary[60]};

  @media (max-width: 1000px) {
    font-size: 10px;
  }
`;

export const UserContainer = styled.div`
  justify-self: flex-end;
`;
