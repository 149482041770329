import { GridItem } from 'components/atoms/Grid/Grid';
import styled, { css } from 'styled-components';
import { BodyText, UiText } from '../../../../styles/Typography';
import { color, fontSize, fontWeight } from '../../../../styles/Variables';

export const PaginationContainer = styled.div`
  padding-top: 1.4rem;
  margin-bottom: 6rem;
`;

export const Pages = styled(GridItem)`
  display: flex;
`;

export const Page = styled(GridItem)<{
  $active?: boolean;
  $disabled?: boolean;
}>`
  ${UiText};
  font-size: ${fontSize.body.m};
  font-weight: ${fontWeight.bold};
  color: ${color.neutral[60]};
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.2rem;

  transition: background-color 0.2s ease-in-out;

  &:hover {
    ${(props) =>
      !props.$disabled &&
      !props.$active &&
      css`
        cursor: pointer;
        background-color: ${color.neutral[40]};
      `}
  }

  ${(props) =>
    props.$active &&
    css`
      background-color: ${color.primary[60]};
      color: ${color.light};
    `}
`;

export const PaginationSelectWrapper = styled.div`
  ${BodyText};
  display: flex;
  gap: 6px;
  align-items: center;
`;
