import React, { FunctionComponent, ReactNode, useState } from 'react';
import * as Styled from '../../atoms/Collapse/Collapse.styled';
import Arrow from '../Arrow/Arrow';

export type CollapseDisplayType = 'card' | 'section';

type CollapseType = {
  header: ReactNode;
  hidden: ReactNode;
  expandable?: boolean;
  initialExpanded?: boolean;
  displayType?: CollapseDisplayType;
  style?: React.CSSProperties;
  arrowPosition?: 'left' | 'right';
  arrowColor?: 'primary' | 'neutral' | string;
};

const Collapse: FunctionComponent<CollapseType> = ({
  header,
  hidden,
  initialExpanded = false,
  expandable = true,
  displayType,
  style,
  arrowPosition = 'left',
  arrowColor,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(initialExpanded);

  return (
    <Styled.CollapseContainer $displayType={displayType} style={style}>
      <Styled.CollapseHeader $displayType={displayType} onClick={expandable ? () => setIsExpanded(!isExpanded) : () => {}} className={'collapse-header'}>
        {expandable && arrowPosition === 'left' && <Arrow direction={isExpanded ? 'down' : 'right'} color={arrowColor} />}
        {header}
        {expandable && arrowPosition === 'right' && <Arrow direction={isExpanded ? 'down' : 'right'} color={arrowColor} />}
      </Styled.CollapseHeader>
      <Styled.CollapseHidden $isExpanded={isExpanded} $displayType={displayType}>
        {hidden}
      </Styled.CollapseHidden>
    </Styled.CollapseContainer>
  );
};
export default Collapse;
