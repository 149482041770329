import React, { FunctionComponent, useMemo } from 'react';
import MyProjects from '../Components/MyProjects';
import ProjectsColumnsView from './ProjectsColumnsView';
import TabsNavigatorRouter from '../../../../components/molecules/TabsNavigatorRouter/TabsNavigatorRouter';
import { AuthContext } from '../../../../Context/auth-context/auth-context';
import { ProjectType } from '../../../../@types/Project/Project';
import EntityProvider from 'Context/EntityContext/EntityProvider';
import { Helmet } from 'react-helmet';

type Props = {
  basePath?: string;
};

const ProjectsList: FunctionComponent<Props> = ({ basePath }) => {
  const { can } = React.useContext(AuthContext);

  const isProjectManager = React.useMemo(() => {
    return can('ROLE_PROJECT_MANAGER') || can('PROJECT.CAN_SEE_ALL_FINANCES');
  }, [can]);

  const pages = useMemo(() => {
    if (isProjectManager) {
      return [
        { to: '/my', name: 'My projects', screen: <MyProjects /> },
        { to: '/all', name: 'All projects', screen: <ProjectsColumnsView /> },
      ];
    } else {
      return [{ to: '/all', name: 'All projects', screen: <ProjectsColumnsView /> }];
    }
  }, [isProjectManager]);

  return (
    <>
      <Helmet>
        <title>Projects | F.CAPE</title>
      </Helmet>
      <TabsNavigatorRouter pages={pages} basePath={basePath} />
    </>
  );
};

export default ProjectsList;
