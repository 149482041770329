import React, { FunctionComponent } from 'react';
import * as Styled from './TasksView.styled';
import Sections from './Sections/Sections';
import Button from '../../../../atoms/Button/Button';
import PlusIcon from '../../../../../Icons/Plus.icon';
import useTaskForm from '../../../../../hooks/useTaskForm';
import { useEntityContext } from '../../../../../Context/EntityContext/EntityContext';
import { ProjectType } from '../../../../../@types/Project/Project';

type Props = {};

const TasksView: FunctionComponent<Props> = (props) => {
  const { openForm } = useTaskForm();
  const { entity: project } = useEntityContext<ProjectType>();

  return (
    <Styled.Container>
      <Styled.Buttons>
        {project.status === 'active' && !project.archived && (
          <Button
            id={'open-task-form'}
            kind={'primary'}
            $text={'Add task'}
            $icon={<PlusIcon />}
            onClick={() =>
              openForm('long', {
                project: project,
              })
            }
            iconSize={1.3}
          />
        )}
      </Styled.Buttons>
      <Styled.Head>
        <div>Name</div>
        <div>Status</div>
        <div>Assignee</div>
        <div>Time</div>
        <div>Due date</div>
        <div>Priority</div>
        <div>Tags</div>
        <div>Planned</div>
      </Styled.Head>
      <Sections />
    </Styled.Container>
  );
};

export default TasksView;
