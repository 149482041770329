import styled, { css } from 'styled-components';
import { color } from '../../../../styles/Variables';

type RadioProps = {
  $checked: boolean;
  $color: string;
};

export const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 3.45rem);
  flex-wrap: wrap;
  gap: 0.8rem;
  margin-top: 1.6rem;
  input {
    position: absolute;
    opacity: 0;
    cursor: none;
    height: 0;
    width: 0;

    &:active + div {
      transform: scale(0.9);
    }
  }
`;

export const HiddenRadio = styled.input.attrs({ type: 'radio' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
  opacity: 0;
  position: absolute;
  white-space: nowrap;
  width: 0;
`;

export const Radio = styled.div<RadioProps>`
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: ${color.primary['60']};

  ${(props) =>
    props.$color &&
    css`
      background-color: ${props.$color};
    `}
`;
export const Inner = styled.div<RadioProps>``;

export const Label = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
`;
