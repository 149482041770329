import React, { FunctionComponent } from 'react';
import { RouterPageType } from '../../../../@types/RouterPage/RouterPageType';
import Workload from '../../../../components/templates/Workload/Workload';
import TabsNavigatorRouter from '../../../../components/molecules/TabsNavigatorRouter/TabsNavigatorRouter';
import WorkloadSchedule from '../../../../components/templates/WorkloadSchedule/WorkloadSchedule';
import { Helmet } from 'react-helmet';

type Props = {};

const WorkloadView: FunctionComponent<Props> = (props) => {
  const pages: RouterPageType[] = [
    { to: '/workload', name: 'Workload', screen: <Workload /> },
    { to: '/my-tasks', name: 'Schedule', screen: <WorkloadSchedule /> },
  ];

  return (
    <>
      <Helmet>
        <title>Workload | F.CAPE</title>
      </Helmet>
      <TabsNavigatorRouter pages={pages} />
    </>
  );
};

export default WorkloadView;
