import React from 'react';
import { MenuLink } from './MenuItem.styled';
import { ModuleType } from '../../../../../@types/Module/Module';

export type MenuItemProps = {
  icon?: React.ReactElement;
  submenu?: React.ReactElement;
  module: ModuleType;
};

export const MenuItem: React.FC<MenuItemProps> = ({ module }) => {
  return <MenuLink title={module.name}>{module.name}</MenuLink>;
};
