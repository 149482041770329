import React, { FunctionComponent, useCallback } from 'react';
import { Grid, GridItem } from '../../atoms/Grid/Grid';
import { Card } from '../../atoms/Card/Card';
import CardHeading from '../../molecules/CardHeading/CardHeading';
import { useFieldArray, useFormContext } from 'react-hook-form';
import Button from '../../atoms/Button/Button';
import { PositionType } from '../../../@types/Finances/PositionType';
import Position from './Position';
import Text from '../../atoms/Text';

type Props = {
  name?: string;
};

const PositionsInput: FunctionComponent<Props> = ({ name = 'positions' }) => {
  const { control } = useFormContext();
  const {
    fields: positions,
    append,
    remove,
  } = useFieldArray({
    control,
    name,
    keyName: '_id',
  });

  const handleCreate = useCallback(() => {
    append({
      name: '',
      vat: 0,
    });
  }, [append]);

  return (
    <Grid spacing={2}>
      <GridItem $desktop={12}>
        <Card>
          <CardHeading heading={'Positions'} $noMargin />
          <Text>Add positions to income. These will be printed on the invoice.</Text>
        </Card>
      </GridItem>
      {/* todo: upgraded react-hook-form is more typing strict */}
      {positions.map((position: any, idx) => (
        <Position index={idx} name={`${name}.${idx}`} key={position._id} position={position as PositionType} onDelete={() => remove(idx)} />
      ))}
      <GridItem $desktop={12}>
        <Button full $text={'Add position'} onClick={handleCreate} />
      </GridItem>
    </Grid>
  );
};

export default PositionsInput;
