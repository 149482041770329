import React, { ReactNode } from 'react';

type SidebarContextType = {
  openSidebar: (body: ReactNode | string) => void;
  closeSidebar: () => void;
  isOpen: boolean;
  body: ReactNode | string | undefined;
  isPdfContext?: boolean;
  setPdfContext?: (value: boolean) => void;
};

export const SidebarContext = React.createContext<SidebarContextType>({
  body: undefined,
  openSidebar: () => {},
  closeSidebar: () => {},
  isOpen: false,
  isPdfContext: false,
  setPdfContext: () => {},
});
