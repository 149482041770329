import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { ChecklistItemType, ChecklistType } from '../../../../../../@types/Checklist/ChecklistType';
import { GridItem } from '../../../../../atoms/Grid/Grid';
import Collapse from '../../../../../atoms/Collapse/Collapse';
import api from '../../../../../../services/api';
import SingleChecklistHeader from './SingleChecklistHeader/SingleChecklistHeader';
import SingleChecklistBody from './SingleChecklistBody/SingleChecklistBody';

type Props = {
  checklist: ChecklistType;
  onComplete: () => void;
  onDelete: () => void;
};

const SingleChecklist: FunctionComponent<Props> = ({ checklist, onComplete, onDelete }) => {
  const [internalChecklist, setInternalChecklist] = useState<ChecklistType>(checklist);
  const handleComplete = useCallback(
    (item: ChecklistItemType) => {
      const suffix = !!item.completedBy ? '/uncomplete' : '/complete';

      api.post(`${item['@id']}${suffix}`, {}).then(() => {
        api.get<ChecklistType>(checklist['@id']).then((response) => {
          setInternalChecklist(response.data);
        });
      });
    },
    [internalChecklist],
  );

  useEffect(() => {
    setInternalChecklist(checklist);
  }, [checklist]);

  return (
    <GridItem $desktop={12}>
      <Collapse
        header={<SingleChecklistHeader checklist={internalChecklist} onDelete={onDelete} />}
        hidden={<SingleChecklistBody items={internalChecklist.items} handleComplete={handleComplete} />}
        displayType={'card'}
      />
    </GridItem>
  );
};

export default SingleChecklist;
