import React, { useEffect } from 'react';
import { ContentContainer, HeaderContainer, LayoutContainer, LayoutInline, MenuContainer } from './layout.styled';
import { Header } from './header/header';
import { Menu } from './menu/menu';
import { useLocation } from 'react-router-dom';
import useUser from '../../../../hooks/useUser';
import { SidebarContext } from '../../../../Context/Sidebar/SidebarContext';
import useLocationQuery from '../../../../hooks/useLocationQuery';

type LayoutProps = {
  menuItems: React.ReactElement[];
  children?: React.ReactElement[];
};

export const Layout: React.FC<LayoutProps> = ({ menuItems, children }) => {
  const [menuClosed, setMenuClosed] = React.useState(true);
  const location = useLocation();
  const user = useUser();
  const { closeSidebar } = React.useContext(SidebarContext);
  const taskId = useLocationQuery('task');

  useEffect(() => {
    if (taskId) {
      return;
    }
    closeSidebar();
  }, [location]);

  useEffect(() => {
    if (!location || !window || !document) return;
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      event: 'page_view',
      pagePathname: location.pathname,
      pageHash: location.hash,
      pageTitle: document.title,
      userDepartment: user?.department?.name,
    });
  }, [window, document, location]);

  const toggleMenu = React.useCallback(() => {
    setMenuClosed(!menuClosed);
  }, [menuClosed]);

  return (
    <LayoutContainer>
      {/*<AppNotificationModal />*/}
      <HeaderContainer>
        <Header closed={menuClosed} onToggleMenu={toggleMenu} />
      </HeaderContainer>
      <LayoutInline>
        <MenuContainer onClick={toggleMenu} $closed={menuClosed}>
          <Menu items={menuItems} linkOnMouseEnter={() => {}} linkOnClick={() => {}} />
        </MenuContainer>
        <ContentContainer>{children}</ContentContainer>
      </LayoutInline>
    </LayoutContainer>
  );
};
