import { TableProperties } from '../../../../@types/Table/TableProperty';
import { IncomeType } from '../../../../@types/Finances/Income/IncomeType';
import FinanceItemInfoTemplate from '../../../molecules/CellTemplates/Finances/FinanceItemInfoTemplate/FinanceItemInfoTemplate';
import { getFinancialCompanyProductionNameLabel } from '../../../../@types/FinancialCompany/FinancialCompanyType';
import { PiInfoBold } from 'react-icons/pi';
import ContractorValueTaxTemplate from '../../../molecules/CellTemplates/Finances/ContractorValueTaxTemplate/ContractorValueTaxTemplate';
import DateTemplate from '../../../molecules/CellTemplates/common/DateTemplate/DateTemplate';
import MoneyTemplate from '../../../molecules/CellTemplates/common/MoneyTemplate/MoneyTemplate';
import TagsTemplate from '../../../molecules/CellTemplates/common/TagsTemplate/TagsTemplate';
import PersonTemplate from '../../../molecules/CellTemplates/common/PersonTemplate/PersonTemplate';

export const incomeTableProperties: TableProperties<IncomeType> = [
  {
    name: 'number',
    visibleName: 'Number',
    template: FinanceItemInfoTemplate,
    sortable: 'sortableNumber',
    templateOptions: {
      chips: [
        {
          display: (item: IncomeType) => `#${item.id}`,
          condition: () => true,
        },
        {
          display: () => 'Internal',
          condition: (item: IncomeType) => item.internal,
        },
        {
          display: (item: IncomeType) => getFinancialCompanyProductionNameLabel(item.financialCompany.name),
          condition: () => true,
          icon: () => PiInfoBold,
        },
      ],
      textProperties: {
        size: 'l',
        bold: true,
      },
      itemsSpacing: 0.6,
      maxWidth: '10rem',
    },
  },
  {
    name: 'kind',
    visibleName: 'Kind',
    template: TagsTemplate,
    templateOptions: {
      color: 'success',
    },
  },
  {
    name: 'contractor',
    visibleName: 'Vendor',
    template: ContractorValueTaxTemplate,
    sortable: true,
    templateOptions: {
      textProperties: {
        maxWidth: '15rem',
      },
    },
  },
  {
    name: 'owner',
    visibleName: 'Owner',
    template: PersonTemplate,
    templateOptions: {
      areInitials: true,
    },
  },
  {
    name: 'postedAt',
    visibleName: 'Service end date',
    template: DateTemplate,
    sortable: true,
  },
  {
    name: 'paymentDue',
    visibleName: 'Payment date',
    template: DateTemplate,
    sortable: true,
  },
  {
    name: 'amountNet',
    sumAsMoney: true,
    sumBy: 'amountNet',
    visibleName: 'Net',
    template: MoneyTemplate,
    sortable: 'amountNet.value',
    justifyContent: 'flex-end',
    templateOptions: {
      textProperties: {
        bold: true,
      },
    },
  },
  {
    name: 'amountGross',
    visibleName: 'Gross',
    sumAsMoney: true,
    sumBy: 'amountGross',
    template: MoneyTemplate,
    sortable: 'amountGross.value',
    justifyContent: 'flex-end',
    templateOptions: {
      textProperties: {
        bold: true,
      },
    },
  },
  {
    name: 'liabilitiesPln',
    visibleName: 'Liabilities',
    template: MoneyTemplate,
    sumAsMoney: true,
    sumBy: 'liabilitiesPln',
    sortable: 'liabilitiesPln.value',
    justifyContent: 'flex-end',
    templateOptions: {
      colored: true,
      textProperties: {
        bold: true,
        color: 'primary',
      },
    },
  },
];
