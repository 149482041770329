import React, { FunctionComponent } from 'react';
import * as Styled from '../Items.styled';
import Button from '../../../../../atoms/Button/Button';
import PlusIcon from '../../../../../../Icons/Plus.icon';
import { color, fontSize, fontWeight } from '../../../../../../styles/Variables';
import useProjectForm from '../../../../../../hooks/useProjectForm';
import { Icon } from '../../../../../atoms/Icon/Icon';

type Props = {};

const AddNewProjectButton: FunctionComponent<Props> = () => {
  const { show } = useProjectForm();

  return (
    <Styled.CardItemWrapper
      id={'open-project-form'}
      onClick={() => {
        show();
      }}
    >
      <Styled.ProjectButtonWrapper>
        <Styled.ProjectIcon $dashed={true}>
          <Icon color={color.neutral[60]}>
            <PlusIcon />
          </Icon>
        </Styled.ProjectIcon>
        <Button
          $text={'Add new project'}
          kind={'link'}
          iconSize={3.2}
          style={{
            padding: '0',
            fontWeight: fontWeight.bold,
            fontSize: fontSize.body.l,
          }}
          iconColor={color.primary['80']}
          onClick={show}
        />
      </Styled.ProjectButtonWrapper>
    </Styled.CardItemWrapper>
  );
};

export default AddNewProjectButton;
