import React, { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';

import styled, { css } from 'styled-components';
import { UiText } from '../../../../../styles/Typography';
import { color, fontSize } from '../../../../../styles/Variables';
import useFormError from '../../../../../hooks/Form/useFormError';

type Props = {
  name: string;
  placeholder: string;
  defaultValue?: string;
  long?: boolean;
  medium?: boolean;
  required?: boolean;
};

const Textarea = styled.textarea<{ long: boolean; medium: boolean; error?: boolean }>`
  ${UiText};
  font-size: ${fontSize.body.s};
  border: 1px solid ${color.neutral[50]};
  border-radius: 8px;
  resize: none;
  padding: 1.2rem;
  height: ${({ long, medium }) => (long ? '18rem' : medium ? '10rem' : '6rem')};
  width: 100%;
  transition: border 0.15s ease-in-out;
  background-color: ${color.neutral[20]};

  &:hover {
    border: 1px solid ${color.neutral[70]};
  }

  ${(p) =>
    p.error &&
    css`
      border: 1px solid ${color.semantic.error[60]};
      &::placeholder {
        color: ${color.semantic.error[100]};
      }
    `}
`;

const TextareaInput: FunctionComponent<Props> = ({ name, placeholder, long = false, medium = false, defaultValue, required }) => {
  const { register } = useFormContext();
  const error = useFormError(name);
  return (
    <Textarea
      {...register(name, { required: required && 'Error' })}
      placeholder={placeholder}
      long={long}
      medium={medium}
      defaultValue={defaultValue}
      error={!!error}
    />
  );
};
export default TextareaInput;
