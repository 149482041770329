import React, { FunctionComponent } from 'react';
import { Icon } from '../Icon/Icon';
import BellIcon from 'Icons/Bell.icon';
import { color } from 'styles/Variables';

type PropTypes = {
  active?: boolean;
  size?: number;
  onClick?: () => void;
  filled: boolean;
};
const NotificationToggle: FunctionComponent<PropTypes> = ({ active = true, size = 1, onClick, filled = false }) => {
  return (
    <Icon
      size={size}
      onClick={
        active
          ? () => {
              active && onClick && onClick();
            }
          : undefined
      }
      color={filled ? color.primary[60] : 'inherit'}
    >
      <BellIcon filled={filled} />
    </Icon>
  );
};

export default NotificationToggle;
