import React, { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import useFormError from 'hooks/Form/useFormError';
import * as Styled from './TaskNameInput.styled';

type Props = {
  name: string;
  placeholder?: string;
  onClick?: () => void;
  fontSize?: string;
};

const TaskNameInput: FunctionComponent<Props> = ({ name, placeholder, onClick, fontSize }) => {
  const { register } = useFormContext();
  const error = useFormError(name);

  return <Styled.Input $error={!!error} placeholder={placeholder} onClick={onClick} {...register(name, { required: true })} $fontSize={fontSize ?? '1.2rem'} />;
};

export default TaskNameInput;
