import React, { FunctionComponent, ReactNode, useEffect, useMemo, useState } from 'react';
import Text from '../../atoms/Text/Text';
import { Grid, GridItem, GridItemProps } from '../../atoms/Grid/Grid';
import { DictValue } from '../../../@types/Dictionary/DictValue';
import { ColorType } from '../../../@types/Color/ColorType';
import { Icon } from '../../atoms/Icon/Icon';
import PaidIcon from '../../../Icons/Paid.icon';
import CalendarIcon from '../../../Icons/Calendar.icon';
import { color as Color } from '../../../styles/Variables';
import translateDictionary from '../../../helpers/translateDictionary';

type Props = GridItemProps & {
  title: string | ReactNode;
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | undefined;
  children: ReactNode | string;
  bold?: boolean;
  dictionary?: DictValue[];
  color?: ColorType;
  type?: 'date' | 'money';
  labelColor?: ColorType;
};

const SingleDetail: FunctionComponent<Props> = ({ title, size = 'l', bold, $desktop = 'auto', dictionary, color, type, labelColor, ...props }) => {
  const [titleSize, setTitleSize] = useState<'xs' | 's' | 'm' | 'l' | 'xl' | undefined>('xs');

  useEffect(() => {
    if (size === 'xl') {
      setTitleSize('m');
    } else if (size === 'm' || size === 'l') {
      setTitleSize('s');
    } else {
      setTitleSize('xs');
    }
  }, []);

  const translated = useMemo(() => {
    if (!props.children) {
      return 'N/A';
    }
    if (!dictionary) {
      return props.children;
    }
    if (typeof props.children === 'string') {
      return translateDictionary(dictionary, props.children);
    } else {
      throw new Error('Dictionary is not supported for non-string children');
    }
  }, [dictionary, props.children]);

  return (
    <GridItem {...props} style={{ display: 'flex', flexDirection: 'column' }} $desktop={$desktop}>
      <Grid spacing={0.3} $align={'center'}>
        {type && (
          <GridItem>
            <Icon color={Color.neutral[90]} size={1.1}>
              {type === 'money' && <PaidIcon />}
              {type === 'date' && <CalendarIcon />}
            </Icon>
          </GridItem>
        )}
        <GridItem>
          <Text size={titleSize} color={labelColor ?? 'grey'} bold>
            {title}
          </Text>
        </GridItem>
      </Grid>
      <Text size={size} bold={bold} color={color}>
        {translated ?? 'N/A'}
      </Text>
    </GridItem>
  );
};

export default SingleDetail;
