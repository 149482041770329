import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { Card } from '../../../../atoms/Card/Card';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import CardHeading from '../../../../molecules/CardHeading/CardHeading';
import Text from '../../../../atoms/Text';
import Heading from '../../../../atoms/Heading';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { MoneyType, sum } from '../../../../../@types/Money/MoneyType';
import { SubcostType } from '../../../../../@types/Finances/Cost/SubcostType';
import Button from '../../../../atoms/Button/Button';
import SubincomeInput from './SubincomeInput';
import { SubincomeType } from '../../../../../@types/Finances/Income/SubincomeType';
import moneyAsString from '../../../../../helpers/moneyAsString';
import { PositionType } from '../../../../../@types/Finances/PositionType';
import { ClientType } from '../../../../../@types/Project/Client/ClientType';

type Props = {
  name?: string;
  client: ClientType | undefined;
};

const SubincomesInput: FunctionComponent<Props> = ({ name = 'subincomes', ...props }) => {
  const { control, watch } = useFormContext();
  const positions = watch('positions');
  const currency = watch('currency');

  const {
    fields: subincomes,
    append,
    remove,
  } = useFieldArray({
    control,
    name,
    keyName: '_id',
  });
  const watchedSubincomes = watch('subincomes', []);

  const remainNet = useMemo(() => {
    const originalAmountNet = positions
      .map((p: PositionType) => p.originalAmountNet)
      .reduce((acc: MoneyType, position: MoneyType) => sum(acc, position), {
        value: 0,
        currency: currency,
      });

    const total = watchedSubincomes.reduce((acc: MoneyType, subcost: SubcostType) => sum(acc, subcost.originalAmountNet), {
      value: 0,
      currency: currency,
    });
    return {
      ...total,
      value: originalAmountNet.value - total.value,
    };
  }, [watchedSubincomes, currency, positions]);

  const handleCreate = useCallback(() => {
    append({
      project: null,
      originalAmountNet: { value: 0, currency },
    });
  }, []);

  const handleRemove = useCallback(
    (idx: number) => {
      remove(idx);
    },
    [remove],
  );

  return (
    <Grid spacing={2}>
      <GridItem $desktop={12}>
        <Card>
          <CardHeading heading={'Assignments'} $noMargin />
          <Text>Add assignments. For internal reporting purposes only. Will not be included on invoice.</Text>
          <div style={{ marginTop: '1.5rem' }}>
            <Heading level={3} size={'s'}>
              Remain to assign: {moneyAsString(remainNet)}
            </Heading>
          </div>
        </Card>
      </GridItem>
      {/* todo: upgraded react-hook-form is more typing strict */}
      {subincomes.map((subincome: any, idx) => (
        <GridItem $desktop={12} key={subincome._id}>
          <SubincomeInput
            currency={currency}
            name={`${name}.${idx}`}
            index={idx}
            subincome={subincome as SubincomeType}
            onRemove={() => handleRemove(idx)}
            client={props.client}
          />
        </GridItem>
      ))}
      <GridItem $desktop={12}>
        <Button full $text={'Add assignment'} onClick={handleCreate} />
      </GridItem>
    </Grid>
  );
};

export default SubincomesInput;
