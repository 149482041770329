import React, { FunctionComponent, useCallback } from 'react';
import Button from '../../../../../atoms/Button/Button';
import AddWorkIcon from '../../../../../../Icons/AddWork.icon';
import AddToTimesheetModal from '../../../../AddToTimesheetModal/AddToTimesheetModal';
import { TaskType } from '../../../../../../@types/Task/TaskType';
import useModal from '../../../../../../hooks/useModal';
import { useEntityContext } from '../../../../../../Context/EntityContext/EntityContext';

type Props = {
  task: TaskType;
  disabled?: boolean;
};

const TaskTopBarAddToTimesheetButton: FunctionComponent<Props> = ({ task, disabled }) => {
  const { showModal } = useModal();
  const { reload } = useEntityContext();

  const onSave = () => {
    reload();
  };

  const handleAddToTimesheetModal = useCallback(() => {
    showModal({
      title: 'Add to timesheet',
      body: <AddToTimesheetModal task={task} onSave={onSave} />,
      width: 45,
    });
  }, [task]);
  return (
    <>
      <Button $text={'Add to timesheet'} $icon={<AddWorkIcon />} kind={'ghost'} onClick={handleAddToTimesheetModal} disabled={disabled} />
    </>
  );
};

export default TaskTopBarAddToTimesheetButton;
