import React from 'react';

export const FilterIcon: React.FC = () => {
  return (
    <svg viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9517 6.75941H10.9574V0.462349C10.9574 0.20704 10.7576 -0.000167847 10.5114 -0.000167847C10.2652 -0.000167847 10.0654 0.20704 10.0654 0.462349V6.75941H8.8542C8.60801 6.75941 8.4082 6.96662 8.4082 7.22193C8.4082 7.47724 8.60801 7.68445 8.8542 7.68445H11.9517C12.1979 7.68445 12.3977 7.47724 12.3977 7.22193C12.3977 6.96662 12.1979 6.75941 11.9517 6.75941ZM7.68018 2.86418H6.68582V0.4627C6.68582 0.206928 6.48601 0.000183105 6.23982 0.000183105C5.99363 0.000183105 5.79382 0.206928 5.79382 0.4627V2.86418H4.58272C4.33653 2.86418 4.13672 3.07139 4.13672 3.3267C4.13672 3.58201 4.33653 3.78922 4.58272 3.78922H7.68018C7.92637 3.78922 8.12617 3.58201 8.12617 3.3267C8.12617 3.07139 7.92637 2.86418 7.68018 2.86418ZM1.52185 8.29988H0.445876C0.199685 8.29988 -0.00012207 8.50709 -0.00012207 8.7624C-0.00012207 9.01771 0.199685 9.22491 0.445876 9.22491H3.54333C3.78953 9.22491 3.98933 9.01771 3.98933 8.7624C3.98933 8.50709 3.78953 8.29988 3.54333 8.29988H2.41385V0.462791C2.41385 0.20702 2.21404 0.000274658 1.96785 0.000274658C1.72166 0.000274658 1.52185 0.20702 1.52185 0.462791V8.29988ZM1.96785 12C1.72166 12 1.52185 11.7928 1.52185 11.5375V10.0861C1.52185 9.8308 1.72166 9.6236 1.96785 9.6236C2.21404 9.6236 2.41385 9.8308 2.41385 10.0861V11.5375C2.41385 11.7928 2.21404 12 1.96785 12ZM5.79382 11.5375C5.79382 11.7928 5.99363 12 6.23982 12C6.48601 12 6.68582 11.7928 6.68582 11.5375V4.58677C6.68582 4.33146 6.48601 4.12425 6.23982 4.12425C5.99363 4.12425 5.79382 4.33146 5.79382 4.58677V11.5375ZM10.0654 11.5375C10.0654 11.7928 10.2652 12 10.5114 12C10.7576 12 10.9574 11.7928 10.9574 11.5375V8.42521C10.9574 8.16944 10.7576 7.96269 10.5114 7.96269C10.2652 7.96269 10.0654 8.16944 10.0654 8.42521V11.5375Z"
        fill={'currentColor'}
      />
    </svg>
  );
};
