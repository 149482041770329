import styled from 'styled-components';

type PropType = {
  onClick?: () => void;
};

export const ReactionWrapper = styled.div<PropType>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.8rem;
  cursor: ${(props) => (!!props.onClick ? 'pointer' : 'default')};
`;
