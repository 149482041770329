import styled from 'styled-components';
import { BodyText } from '../../../../styles/Typography';

export const Info = styled.div`
  ${BodyText};
  gap: 0.6rem;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.6rem;
  div {
  }
`;

export const InfoInline = styled(Info)`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  margin-bottom: 2.6rem;
  & > div {
  }
`;

export const ContentWrapper = styled.div`
  margin-bottom: 2.4rem;
  margin-top: 1.5rem;
`;
