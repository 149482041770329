import React, { FunctionComponent } from 'react';
import api from '../../../services/api';
import SnackContainer from '../../../legacy/CapeMorris/components/snack-bar/use-snack-container';
import Form from '../../../components/molecules/Form/Form';
import Button from '../../../components/atoms/Button/Button';
import { TextInput } from '../../../components/molecules/Form/TextInput/TextInput';
import * as Styled from '../LoginScreen.styled';
import Text from '../../../components/atoms/Text';

type Props = {
  toLogIn: () => void;
  toChangePassword: () => void;
};

const ForgotPasswordForm: FunctionComponent<Props> = ({ toLogIn, toChangePassword }) => {
  const [state, setState] = React.useState<'idle' | 'working'>('idle');
  const { showSnack } = SnackContainer.useContainer();

  const onSubmit = async (data: any) => {
    setState('working');
    await api.post('/reset-password', { ...data }).then(() => {
      setState('idle');
      showSnack({
        title: 'Check mailbox',
        message: 'An email with further information has been sent to you',
      });
      toChangePassword();
    });
  };
  return (
    <Form onSubmit={onSubmit}>
      <Styled.HeaderWrapper>
        <Styled.Header $size={'l'}>Forgot password?</Styled.Header>
        <Text size={'l'} color={'grey'}>
          Enter your username or e-mail. You will receive an e-mail with further instructions.
        </Text>
      </Styled.HeaderWrapper>
      <Styled.InputWrapper>
        <TextInput name="username" label={'Username'} placeholder={'j.snow'} required />
      </Styled.InputWrapper>
      <Styled.ButtonsWrapper>
        <Button type="submit" full={true} $text={'Send e-mail'} waiting={state === 'working'} />
        <Button full={true} onClick={toLogIn} $text={'Log in'} />
        <Button full={true} onClick={toChangePassword} $text={'I have a token'} />
      </Styled.ButtonsWrapper>
    </Form>
  );
};

export default ForgotPasswordForm;
