import { TableFilterType } from '../../../../@types/Table/TableFilterType';
import FrequentFilters from '../../../../@types/Table/FrequentFilters';
import DateSelectFilter from '../../../molecules/TableFilters/DateSelectFilter/DateSelectFilter';

export const filters: TableFilterType[] = [
  {
    ...FrequentFilters._search,
    placeholder: 'Search by id, document number, contractor name',
    gridProps: {
      $desktop: 3,
    },
  },
  {
    type: 'text',
    name: 'income.number',
    label: 'Number',
    gridProps: {
      $desktop: 2,
    },
  },
  {
    ...FrequentFilters.contractor,
    name: 'income.contractor.id',
  },
  {
    ...FrequentFilters.partner,
    name: 'project.client.id',
  },
  {
    ...FrequentFilters.project,
  },
  {
    type: 'custom',
    name: 'income.postedAt',
    label: 'Posting date',
    options: {
      component: DateSelectFilter,
    },
    gridProps: {
      $desktop: 5,
    },
  },
  {
    type: 'money',
    name: 'amountNet.value[gte]',
    label: 'Net from',
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'money',
    name: 'amountNet.value[lte]',
    label: 'Net to',
    gridProps: {
      $desktop: 2,
    },
  },
];
