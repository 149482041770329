import React, { FunctionComponent } from 'react';
import { NotificationType } from '../../../../@types/Notification/NotificationType';
import * as Styled from '../Notification.styled';
import Text from '../../../atoms/Text';
import Chip from '../../../atoms/Chips/Chip';
import { color } from '../../../../styles/Variables';
import { Icon } from '../../../atoms/Icon/Icon';
import PaidIcon from '../../../../Icons/Paid.icon';

type Props = {
  notification: NotificationType;
  timeComponent: JSX.Element;
  indicatorComponent: JSX.Element;
  title?: string;
};

const FinanceNotification: FunctionComponent<Props> = ({ notification, timeComponent, indicatorComponent, title }) => {
  return (
    <>
      <Styled.TopWrapper>
        {indicatorComponent}
        {timeComponent}
        <Chip $size={'s'} $bgColor={color.primary[20]}>
          <Icon color={color.primary[60]}>
            <PaidIcon />
          </Icon>
          <Text size={'xs'} color={'primary'}>
            {title}
          </Text>
        </Chip>
      </Styled.TopWrapper>
      <Styled.ContentTitle size={'l'} bold={true}>
        {notification.subject}
      </Styled.ContentTitle>
      <Text size={'m'} color={'semiGrey'}>
        {notification.subtext}
      </Text>
    </>
  );
};

export default FinanceNotification;
