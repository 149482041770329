import { EntityType } from '../../Entity/EntityType';
import { ContractorType } from '../Contractor/ContractorType';
import { BranchType } from '../../Branch/BranchType';
import { BasicEmployeeType } from '../../Employee/EmployeeType';
import { ClientType } from '../../Project/Client/ClientType';
import { ProjectType } from '../../Project/Project';
import { PurchaseOrderType } from '../PurchaseOrder/PurchaseOrderType';
import { RequestType } from '../../Request/RequestType';
import { FinanceStatusType } from '../FinanceStatusType';
import { FinancialCompanyType } from '../../FinancialCompany/FinancialCompanyType';

type ContractTypeType = 'framework_agreement' | 'annex_to_the_contract' | 'nda' | 'administration' | 'orders' | 'others';
type ContractAppliesType = 'general' | 'partner' | 'project' | 'purchase_order' | 'employee';

export const ContractSubject = 'Finances::Contract';

export type ContractType = EntityType & {
  contractor: ContractorType;
  status: FinanceStatusType;
  confidential: boolean;
  branch: BranchType;
  title: string;
  description?: string;
  owner: BasicEmployeeType;
  number: string;
  parentContract?: ContractType;
  date: string;
  dateSigned?: string;
  binderReference: string;
  type: ContractTypeType;
  applies: ContractAppliesType;
  detailsPartner?: ClientType | null;
  detailsProject?: ProjectType | null;
  detailsPurchaseOrder?: PurchaseOrderType | null;
  detailsEmployee?: BasicEmployeeType | null;
  createdAt: string;
  createdBy: BasicEmployeeType;
  subjectId: string;
  request?: RequestType;
  uploadedResources?: (number | string)[];
  company: FinancialCompanyType;
};
