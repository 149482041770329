import { createGlobalStyle } from 'styled-components';
import { color, fontWeight } from './Variables';

const GlobalStyle = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li, g,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    text-align: left;
    letter-spacing: initial;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: inherit;
  }

  strong, b {
    font-weight: 600;
  }

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }

  *:focus {
    outline: none;
  }

  html {
    scroll-behavior: smooth;
    font-size: 10px;
    overflow: hidden;
    //font-size: calc(9px + 1 * ((100vw - 768px) / (1440 - 768)));
  }

  body {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    margin: 0;
    font-family: 'Plus Jakarta Sans', serif;
    background-color: ${color.light};
    color: ${color.neutral[80]};
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.5;
  }

  button {
    font-family: inherit;
    letter-spacing: initial;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  select {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
  }

  input, textarea {
    font-family: 'Plus Jakarta Sans', sans-serif;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    padding: 0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${color.light} inset !important;
    -webkit-text-fill-color: ${color.dark} !important;
    animation: onAutoFillStart 5s ease-in-out;
  }

  @keyframes onAutoFillStart {
    from {
      background-color: ${color.light};
    }
    to {
      background-color: ${color.light};
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }

  iframe {
    border: 0;
    width: 100%;
  }

  #data-picker-portal {
    position: absolute;
    z-index: 2000;
    width: 200%;

  }

  .react-datepicker {
    font-family: 'Plus Jakarta Sans', serif;
    transform: scale(1.2) translateY(14px);
    width: max-content;

    .react-datepicker__month-text {
      text-align: center;
      padding: 0.2rem;
    }

    .react-datepicker__month-text--keyboard-selected {
      background-color: ${color.primary[60]};
      color: ${color.light};
      font-weight: ${fontWeight.bold};


    }

    .react-datepicker__triangle {
      margin-left: -10px;
      left: -6px !important;
    }

    .react-datepicker__current-month {
      font-size: 12px;
    }

    .react-datepicker__navigation-icon::before {
      top: 8px;
      border-width: 2px 2px 0 0;
      width: 7px;
      height: 7px;
      border-color: ${color.primary[60]};
    }

    .react-datepicker__navigation-icon--previous::before {
      right: -3px;
    }

    .react-datepicker__navigation-icon--next::before {
      left: -3px;
    }

    .react-datepicker__year-read-view {
      margin: 2px 0;
    }

    .react-datepicker__year-read-view--down-arrow {
      border-width: 1px 1px 0 0;
      border-color: ${color.primary[60]};
      right: -12px;
      top: 3px;
      width: 6px;
      height: 6px;
    }

    .react-datepicker__year-read-view--selected-year {
      font-size: 9px;
    }

    .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected {
      background-color: ${color.primary[60]};
      color: white;
    }

    .react-datepicker__day--in-range {
      background-color: ${color.primary[50]};
    }

    .react-datepicker__month--selected, .react-datepicker__month-text--keyboard-selected {
      background-color: ${color.primary[60]};
    }

    .react-datepicker__year-dropdown {
      max-height: 200%;
      overflow: scroll;
    }

    .react-datepicker__navigation--years {
      display: none;
    }
  }

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${color.neutral[30]};
    border-radius: 8px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: ${color.neutral[50]};
    border-radius: 8px;
    transition: all 0.15s ease-in-out;

  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: ${color.neutral[60]};
  }

`;
export default GlobalStyle;
