import styled from 'styled-components';
import { GridItem } from '../../../atoms/Grid/Grid';

export const ItemWrapper = styled(GridItem)`
  margin-bottom: 2.4rem;
`;

export const AgreementWrapper = styled.div`
  margin-top: 2rem;
`;
