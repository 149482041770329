import React, { FunctionComponent } from 'react';
import ModalForm from '../../../../../Form/ModalForm/ModalForm';
import { TextInput } from '../../../../../../molecules/Form/TextInput/TextInput';
import HiddenInput from '../../../../../../atoms/Form/HiddenInput/HiddenInput';
import { ProjectRoleType } from '../../../../../../../@types/Project/ProjectRoleType';
import EmployeeInput from '../../../../../../molecules/Form/EmployeeInput/EmployeeInput';
import { Grid, GridItem } from '../../../../../../atoms/Grid/Grid';
type Props = {
  projectId: string;
  role?: ProjectRoleType;
  onChange: () => void;
};

const RoleModal: FunctionComponent<Props> = ({ projectId, role, onChange }) => {
  const isEdit = !!role;

  return (
    <ModalForm
      basePath={isEdit ? `/api/project_roles/${role?.id}` : '/api/project_roles'}
      buttonText={isEdit ? 'Save role' : 'Add role'}
      defaults={role}
      onChange={onChange}
    >
      <Grid spacing={2.4}>
        <GridItem $desktop={12}>
          {!isEdit && <EmployeeInput group={'organisation'} name={'employee'} label={'Person'} placeholder={'Choose person'} required />}
        </GridItem>
        <GridItem $desktop={12}>
          <TextInput name={'name'} placeholder={'Type role name or leave blank for no role'} label={'Role name'} />
        </GridItem>
        <GridItem $desktop={12}>
          <HiddenInput name={'project'} value={projectId} />
        </GridItem>
      </Grid>
    </ModalForm>
  );
};

export default RoleModal;
