import React, { FunctionComponent } from 'react';
import { color } from '../styles/Variables';

type Props = {
  width?: string;
};

const Narwhal: FunctionComponent<Props> = ({ width }) => {
  return (
    <svg style={{ width: width }} className="narwhal" x="0px" y="0px" viewBox="0 0 511.998 511.998">
      <path
        fill={color.primary['60']}
        d="M495.663,335.662c-6.158-28.076-16.521-52.115-21.456-62.645c-0.375-43.996-12.864-86.8-36.195-123.921
                            c-4.035-6.412-8.611-12.396-13.625-17.858l33.202-120.7c1.067-3.879-0.791-7.965-4.415-9.712
                            c-3.624-1.747-7.978-0.652-10.347,2.598l-69.762,95.761c-25.54-7.582-53.238-6.248-78.689,3.926
                            c-4.271,1.708-6.349,6.554-4.642,10.824c1.301,3.257,4.43,5.239,7.735,5.239c1.028,0,2.075-0.192,3.089-0.597
                            c20.043-8.014,41.663-9.667,61.979-4.944l-4.282,5.877c-6.931,9.51-8.588,21.769-4.433,32.792
                            c4.126,10.944,13.379,18.997,24.655,21.518c0.335,0.08,0.67,0.157,1.004,0.222c2.253,0.46,4.505,0.682,6.73,0.682
                            c14.61,0,28.017-9.57,32.737-23.988c1.74,2.33,3.397,4.741,4.961,7.227c22.017,35.03,33.656,75.521,33.656,117.092
                            c0,9.804-0.641,19.634-1.903,29.221c-2.293,17.475-6.631,34.461-12.945,50.742c-2.583-5.303-6.271-10.903-11.534-16.272
                            c-3.219-3.284-8.492-3.337-11.777-0.118c-3.285,3.219-3.337,8.492-0.118,11.777c11.092,11.316,12.567,23.695,12.725,27.999
                            c-2.627,4.962-5.453,9.857-8.445,14.572c-40.545,64.098-110.186,102.365-186.293,102.365c-38.345,0-70.582-8.6-95.817-25.562
                            c-3.818-2.566-8.991-1.551-11.558,2.266c-2.566,3.818-1.551,8.992,2.266,11.557c28.031,18.84,63.395,28.395,105.109,28.395
                            c81.85,0,156.755-41.165,200.364-110.109c0.535-0.843,1.056-1.701,1.581-2.555c16.641,22.693,37.744,34.009,38.775,34.552
                            c1.208,0.636,2.542,0.961,3.882,0.961c0.847,0,1.696-0.129,2.518-0.39c2.124-0.674,3.889-2.17,4.901-4.154
                            C501.886,405.628,504.026,373.785,495.663,335.662z M382.789,157.719c-0.03-0.007-0.082-0.017-0.113-0.022
                            c-0.115-0.022-0.231-0.052-0.348-0.08c-0.036-0.009-0.079-0.019-0.115-0.027c-5.965-1.334-10.631-5.401-12.802-11.161
                            c-2.201-5.839-1.36-12.074,2.31-17.107l1.064-1.46c3.542,7.905,9.668,17.794,20.193,28.813
                            C389.837,158.006,386.316,158.444,382.789,157.719z M407.027,131.536c0,0.002-0.001,0.004-0.001,0.007l-3.349,12.187
                            c-14.357-15.566-17.929-27.651-18.807-32.459l15.31-21.016c2.066,8.105,5.562,17.13,11.467,24.486L407.027,131.536z
                             M417.964,91.782c-2.825-8.107-3.721-16.37-3.999-20.45l15.465-21.229L417.964,91.782z M478.148,414.28
                            c-7.938-5.619-20.477-16.069-29.79-31.187c11.555-22.603,19.336-46.76,23.148-71.894
                            C481.21,340.076,490.234,381.373,478.148,414.28z"
      />
      <path
        fill={color.primary['60']}
        d="M405.416,321.739c-15.959-6.706-36.043-10.106-59.695-10.106c-12.164,0-23.733,1.96-35.049,5.938
                        c0-0.043,0.002-0.085,0.002-0.129c0-4.599-3.729-8.328-8.328-8.328c-4.599,0-8.328,3.729-8.328,8.328
                        c0,3.801-0.265,7.664-0.791,11.488c-0.026,0.133-0.048,0.266-0.068,0.402c-5.378,37.569-35.318,65.334-50.486,77.207
                        c-1.86-6.801-3.232-14.031-4.099-21.605c-5.066-44.328,8.781-86.948,9.01-87.641c1.455-4.364-0.904-9.08-5.268-10.533
                        c-4.365-1.455-9.08,0.904-10.533,5.268c-0.606,1.818-14.341,43.736-10.225,90.164c-34.666,27.073-71.197,40.598-132.144,21.303
                        C76.549,372.462,70.01,333.75,70.01,288.307c0-3.317-1.968-6.317-5.011-7.639c-16.524-7.176-27.643-17.874-33.047-31.8
                        c-7.147-18.415-3.025-38.71,0.316-49.747c37.595,21.458,49.873,62.458,49.997,62.883c1.14,3.993,5.047,6.535,9.162,5.96
                        c4.113-0.576,7.173-4.094,7.173-8.247c0-31.807,29.288-58.708,44.698-70.658c5.203,23.213,1.908,45.059-9.844,65.073
                        c-10.358,17.639-23.755,27.28-23.872,27.363c-3.268,2.296-4.446,6.591-2.805,10.234c0.222,0.493,5.538,12.203,14.509,23.913
                        c3.241,4.231,6.535,7.926,9.876,11.083c-8.36-1.442-17.653-4.635-27.342-10.72c-3.894-2.447-9.035-1.271-11.482,2.623
                        c-2.446,3.895-1.273,9.035,2.623,11.482c17.545,11.02,34.118,14.299,47.634,14.299c16.479,0,28.403-4.874,32.008-6.545
                        c18.098-7.364,34.149-30.176,48.78-69.196c6.284-16.74,11.342-40.063,15.034-69.32c3.549-28.057,17.687-52.371,39.81-68.462
                        c3.72-2.706,4.542-7.914,1.837-11.634c-2.706-3.72-7.915-4.543-11.634-1.837c-25.878,18.823-42.405,47.178-46.538,79.844
                        c-3.529,27.974-8.274,50.029-14.103,65.558c-14.571,38.859-30.88,60.534-45.925,61.034c-15.38,0.519-30.05-20.372-37.06-33.299
                        c5.73-5.06,14.829-14.257,22.628-27.336c11.336-19.01,22.027-50.14,8.582-90.477c-0.807-2.422-2.681-4.339-5.082-5.203
                        c-2.402-0.865-5.067-0.578-7.232,0.774c-2.058,1.286-41.862,26.559-56.453,64.178c-9.891-17.387-27.384-39.688-56.207-52.661
                        c-4.189-1.887-9.114-0.023-11.006,4.163c-0.682,1.509-16.561,37.338-3.658,70.779c6.478,16.79,18.915,29.841,37.007,38.858
                        c0.52,47.141,7.983,87.482,22.203,119.972c8.49,19.409,19.513,36.232,32.764,50.002c1.636,1.699,3.816,2.554,6.002,2.554
                        c2.078,0,4.159-0.773,5.774-2.327c3.314-3.189,3.416-8.461,0.227-11.775c-7.947-8.26-15.017-17.799-21.136-28.476
                        c15.712,3.898,29.905,5.629,42.836,5.629c34.63,0,60.27-12.338,82.208-28.194c1.557,7.7,3.634,15.024,6.213,21.89
                        c0.877,2.336,2.755,4.155,5.117,4.958c0.873,0.297,1.778,0.443,2.678,0.443c1.538,0,3.065-0.426,4.399-1.257
                        c2.329-1.448,55.737-35.266,66.227-90.843c11.889-5.313,23.978-7.913,36.829-7.913c21.424,0,39.338,2.963,53.242,8.806
                        c4.238,1.781,9.121-0.211,10.904-4.452C411.649,328.402,409.657,323.521,405.416,321.739z"
      />
      <path
        fill={color.primary['60']}
        d="M425.627,189.759c-2.949-1.591-6.673-1.275-9.271,0.865c-5.904,4.859-2.38,14.763,5.28,14.763
                        c3.846,0,7.283-2.745,8.127-6.499C430.567,195.31,428.857,191.502,425.627,189.759z"
      />
      <path
        fill={color.primary['60']}
        d="M319.35,190.384c-2.96,2.199-4.123,6.234-2.789,9.671c1.23,3.169,4.353,5.331,7.758,5.331c3.911,0,7.41-2.849,8.173-6.696
                        C333.951,191.333,325.324,185.947,319.35,190.384z"
      />
      <path
        fill={color.primary['60']}
        d="M427.81,217.963c-4.599,0-8.328,3.729-8.328,8.328c0,9.131-7.429,16.561-16.561,16.561s-16.561-7.43-16.561-16.561
                        c0-4.599-3.729-8.328-8.328-8.328c-4.599,0-8.328,3.729-8.328,8.328c0,9.131-7.429,16.561-16.561,16.561
                        s-16.56-7.429-16.56-16.561c0-4.599-3.729-8.328-8.328-8.328s-8.328,3.729-8.328,8.328c0,18.315,14.901,33.217,33.216,33.217
                        c9.898,0,18.798-4.351,24.889-11.242c6.091,6.89,14.991,11.242,24.889,11.242c18.316,0,33.217-14.901,33.217-33.217
                        C436.138,221.692,432.409,217.963,427.81,217.963z"
      />
    </svg>
  );
};

export default Narwhal;
