import React, { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import { convertTimeStringToMinutes } from '../../../../helpers/convertTimeStringToMinutes';
import { TextInput } from '../TextInput/TextInput';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import HiddenInput from '../../../atoms/Form/HiddenInput/HiddenInput';
import TimeInputTooltip from './TimeInputTooltip';

type TimeInputProps = {
  name: string;
  required?: boolean;
};

const TimeInput: FunctionComponent<TimeInputProps> = ({ name, required = false }) => {
  const { watch } = useFormContext();
  const value = watch(`_.${name}`);

  //todo: delete grid and text with current value after implementing UX requirements for time input
  return (
    <Grid spacing={2}>
      <GridItem $desktop={12}>
        <TextInput name={`_.${name}`} placeholder={'90 min or 1h 30 m'} label={'Time tracked'} required={required} tooltip={<TimeInputTooltip />} />
        <HiddenInput name={name} value={convertTimeStringToMinutes(value ?? '0')} />
      </GridItem>
    </Grid>
  );
};

export default TimeInput;
