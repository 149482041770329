import styled from 'styled-components';
import { color, fontSize, fontWeight } from 'styles/Variables';
import { BodyText } from 'styles/Typography';

export const Attachments = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin: 2rem 0 0 4rem;
`;

export const Card = styled.div`
  position: relative;
  padding: 1rem;
  border: 1px solid ${color.neutral[50]};
  background-color: ${color.light};
  border-radius: 0.8rem;
  color: ${color.primary[60]};
  display: grid;
  column-gap: 1rem;
  align-items: center;
  grid-template-columns: 4rem 1fr 2rem;
  height: 6.4rem;
  max-width: 30rem;
  cursor: pointer;
`;

export const Content = styled.div`
  display: grid;
  gap: 0.3rem;
  &:hover {
    .title {
      color: ${color.primary[60]};
    }
  }
`;

export const Name = styled.div`
  ${BodyText};
  width: 100%;
  font-weight: ${fontWeight.bold};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.2s ease-in-out;
`;

export const Details = styled.div`
  ${BodyText};
  display: flex;
  gap: 0.5rem;
  width: 100%;
  font-size: ${fontSize.body.xs};
  color: ${color.neutral[60]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  a,
  p {
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
      color: ${color.primary[60]};
    }
  }
`;

export const PreviewContainer = styled.div`
  img {
    width: 100%;
    width: -webkit-fill-available;
  }
`;

export const Menu = styled.div`
  height: 2rem;
  width: 2rem;
`;
