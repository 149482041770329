import styled, { css } from 'styled-components';
import { color } from '../../../styles/Variables';
import { Button, buttonDynamicStyles } from '../../atoms/Button/Button.styled';

export const ArrowWrapper = styled(Button)`
  display: flex;
  position: absolute;
  height: 100%;
  padding: 0 0.8rem;
  cursor: pointer;
  border-left: 1px solid ${color.neutral['10']};
  transition:
    background-color 0.1s ease,
    transform 0.15s ease;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  top: 0;
  ${(props) => buttonDynamicStyles(props)}

  ${(props) =>
    props.$waiting &&
    css`
      pointer-events: none;
      background-color: ${color.neutral[40]};
      color: ${color.neutral[50]};
      div {
        color: ${color.neutral[50]};
      }
    `}

  ${(props) =>
    (props.$disabled || props.$waiting) &&
    (props.$kind === 'ghost' || props.$kind === 'link') &&
    css`
      pointer-events: none;
      border-color: ${color.neutral[50]};
      background-color: transparent;
      color: ${color.neutral[50]};
    `}

  ${(props) =>
    (props.$kind === 'ghost' || props.$kind === 'link') &&
    css`
      border-left: none;
      &:active {
        border-color: ${color.neutral['50']};
      }
    `}
`;
