import React, { FunctionComponent, useMemo } from 'react';
import Select from '../../../Form/SelectInput/SelectInput';
import { DictValue } from '../../../../../@types/Dictionary/DictValue';
import IriHelper from '../../../../../helpers/iri-helper';

type Props = {
  project: DictValue | string;
};

const TaskDependenciesSelect: FunctionComponent<Props> = ({ project }) => {
  const id: string | number = useMemo(() => {
    if (typeof project === 'string') {
      return IriHelper.iriToId(project) ?? '';
    }
    return IriHelper.iriToId(project?.value) ?? '';
  }, [project]);
  return (
    <Select
      name={'previousTasks'}
      baseUrl={'/api/tasks'}
      optionLabel={'title'}
      isMulti={true}
      label={'Blocking tasks'}
      placeholder={'Select blocking tasks'}
      baseUrlParams={{ 'project.id': id, status: ['in_progress', 'acceptance', 'todo', 'in_review'] }}
    />
  );
};

export default TaskDependenciesSelect;
