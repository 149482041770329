import React, { FunctionComponent, useMemo } from 'react';
import * as Styled from './TabsNavigatorRouter.styled';
import { TabNavigatorType } from '../../../@types/TabNavigator/TabNavigatorType';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import NavigatorRouterTab from '../../atoms/NavigatorRouterTab/NavigatorRouterTab';

type Props = TabNavigatorType;

const TabsNavigatorRouter: FunctionComponent<Props> = ({ pages, basePath, actions }) => {
  const location = useLocation();

  const url = useMemo(() => {
    return basePath ?? location.pathname;
  }, [location.pathname, basePath]);

  const target = (to: string) => {
    const parts = url.split('/');
    parts.pop();
    return parts.join('/') + to;
  };

  return (
    <Styled.Navigation>
      <Styled.Tabs>
        <Styled.TabWrapper>
          {pages.map((page) => (
            <NavigatorRouterTab to={target(page.to)} key={page.to} isExternal={!!page.isExternal}>
              {page.name}
            </NavigatorRouterTab>
          ))}
        </Styled.TabWrapper>
        {actions && <Styled.ActionsWrapper>{actions}</Styled.ActionsWrapper>}
      </Styled.Tabs>
      <Styled.ScreenContainer>
        <Routes>
          {pages.map((page) => (
            <Route key={page.to} path={`${page.to}/*`} element={page.screen} />
          ))}
          <Route
            path={''}
            element={
              <Navigate
                to={{
                  pathname: `${url}${pages[0].to}`,
                  search: location.search,
                }}
              />
            }
          />
        </Routes>
      </Styled.ScreenContainer>
    </Styled.Navigation>
  );
};

export default TabsNavigatorRouter;
