import React from 'react';
import { useFormContext } from 'react-hook-form';
import { TableFilterInterface } from '../../../../../components/molecules/TableFilters/TableFilterInterface';
import DateSelectFilter from '../../../../../components/molecules/TableFilters/DateSelectFilter/DateSelectFilter';

const CustomDateSelectFilter: TableFilterInterface = (props) => {
  const { watch } = useFormContext();
  const watcher = watch('filters.status');
  return <>{watcher === 'settled' && <DateSelectFilter {...props} />}</>;
};

export default CustomDateSelectFilter;
