import { useCallback, useContext } from 'react';
import TaskFormContext from '../Context/TaskFormContext/TaskFormContext';
import { TaskType } from '../@types/Task/TaskType';

function useTaskForm() {
  const ctx = useContext(TaskFormContext);

  const openForm = useCallback((type: 'short' | 'long' = 'short', defaults: Partial<TaskType> = {}, iri?: string) => {
    if (type === 'long') {
      ctx.openLongForm(defaults, iri);
      return;
    }
    ctx.openShortForm();
  }, []);

  return { openForm };
}

export default useTaskForm;
