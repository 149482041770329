import React, { useCallback, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import api from '../../../../../services/api';
import { ArrayResponseType } from '../../../../../@types/hydra/hydra';
import AsyncSelect from 'react-select/async';
import { InlineInputDropdownType } from '../InlineInput/InlineInputDropdownType';
import ProjectOption from '../../../../molecules/Projects/ProjectOption/ProjectOption';

const TaskProjectDropdown: InlineInputDropdownType = ({ onChange, name }) => {
  const { control, watch } = useFormContext();
  const value = watch(name);

  useEffect(() => {
    onChange(value);
  }, [value]);

  const loadOptions = useCallback((inputValue: string, callback: Function) => {
    api.get<ArrayResponseType>('/projects', { params: { _search: inputValue, status: 'active', archived: false } }).then((response) => {
      callback(
        response.data['hydra:member'].map((i) => {
          return { value: i['@id'], label: i['name'], meta: i };
        }),
      );
    });
  }, []);

  return (
    <div style={{ width: '22rem' }}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange } }) => (
          <AsyncSelect
            components={{ Option: ProjectOption }}
            autoFocus
            menuIsOpen={true}
            isClearable={true}
            loadOptions={loadOptions}
            defaultOptions={true}
            onChange={onChange}
            className={'dropdown'}
          />
        )}
      />
    </div>
  );
};

export default TaskProjectDropdown;
