import styled from 'styled-components';
import { Grid } from '../../../../atoms/Grid/Grid';

export const Container = styled(Grid)`
  display: flex;
  gap: 0.3rem;
  align-items: center;
  white-space: nowrap;

  @media (max-width: 1500px) {
    max-width: 25rem;
  }
`;
