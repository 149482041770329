import React, { FunctionComponent, useCallback, useState } from 'react';
import Form from '../../../../../molecules/Form/Form';
import Select from '../../../../../molecules/Form/SelectInput/SelectInput';
import { ModalFooter } from '../../../../../molecules/Modal/Modal';
import Button from '../../../../../atoms/Button/Button';
import { ProjectType } from '../../../../../../@types/Project/Project';
import { TaskType } from '../../../../../../@types/Task/TaskType';
import useApi from '../../../../../../hooks/useApi';
import useModal from '../../../../../../hooks/useModal';
import IriHelper from '../../../../../../helpers/iri-helper';
import ProjectOption from '../../../../../molecules/Projects/ProjectOption/ProjectOption';
import ProjectLabel from '../../../../../molecules/Projects/ProjectLabel/ProjectLabel';

type Props = {
  task: TaskType;
};

const TaskTopBarMoveModal: FunctionComponent<Props> = ({ task }) => {
  const { put } = useApi();
  const { showModal, hideModal } = useModal();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmitMove = useCallback(
    async (data: ProjectType) => {
      setLoading(true);
      await put(`${task['@id']}/move`, data).then((r) => {
        hideModal();
        setLoading(false);
      });
    },
    [showModal, put, task],
  );

  return (
    <Form onSubmit={handleSubmitMove} defaultValues={task}>
      {({ watch }) => {
        const project = watch('project');
        const id = typeof project === 'string' ? IriHelper.iriToId(project) : project?.id;
        return (
          <>
            <Select optionComponent={ProjectOption} singleValueComponent={ProjectLabel} name={'project'} label={'Project'} baseUrl={'/api/projects/'} />
            <div style={{ height: '2.5rem' }} />
            {project && (
              <Select
                key={project}
                name={'section'}
                label={'Section'}
                baseUrl={'/api/sections/'}
                baseUrlParams={{
                  'project.id': id,
                }}
              />
            )}

            <ModalFooter>
              <Button $text={loading ? 'Moving...' : 'Move task'} type="submit" disabled={loading} />
            </ModalFooter>
          </>
        );
      }}
    </Form>
  );
};

export default TaskTopBarMoveModal;
