import React, { FunctionComponent } from 'react';
import { AuthContext } from '../../../../Context/auth-context/auth-context';
import properties from '../AbsencesTable.properties';
import AbsencesItemExtendedRow from '../../../organisms/Absences/AbsencesItemExtendedRow/AbsencesItemExtendedRow';
import filters from '../AbsencesTable.filters';
import useTableReload from '../../../../hooks/Table/useTableReload';
import TablePage from '../../../organisms/TablePage/TablePage';

type Props = {};

const MyAbsencesView: FunctionComponent<Props> = (props) => {
  const {
    state: { user },
  } = React.useContext(AuthContext);
  const { key } = useTableReload();

  return (
    <TablePage
      url={'/api/absence_requests/'}
      context={'/api/absence_requests'}
      properties={properties}
      filters={[
        ...filters.filter((elem) => elem.name !== 'person.id').filter((elem) => elem.name !== 'year'),
        {
          type: 'text',
          name: 'year',
          label: 'Year',

          gridProps: {
            $desktop: 4,
          },
        },
      ]}
      globalFilters={{ 'person.id': user!.employeeId }}
      extendedRow={AbsencesItemExtendedRow}
      reloadKey={key}
      heading={'My absences'}
    />
  );
};

export default MyAbsencesView;
