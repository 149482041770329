import React, { FunctionComponent, useCallback } from 'react';
import { WorkloadDay, WorkloadType } from '../../../../../@types/Workload/WorkloadType';
import WorkloadTime from './WorkloadTime/WorkloadTime';
import { getDayName } from '../../../../../helpers/getDaysForWeekMethods';
import { convertMinutesToHours } from '../../../../../helpers/convertMinutesToHours';
import * as Styled from './EmployeeWorkloadCollapseHeader.styled';
import Text from '../../../../../components/atoms/Text';

type Props = {
  workloadItem: WorkloadType;
};

const EmployeeWorkloadCollapseHeader: FunctionComponent<Props> = ({ workloadItem }) => {
  const isFriday = (day: WorkloadDay) => {
    return getDayName(new Date(day.day)) === 'Friday';
  };

  const dayTime = useCallback(
    (day: WorkloadDay) => {
      return isFriday(day) ? 360 : 480;
    },
    [workloadItem],
  );

  return (
    <Styled.MainWrapper>
      <Text bold size={'xl'}>
        {workloadItem.name}
      </Text>
      <Styled.Wrapper>
        {workloadItem.days.map((day) => (
          <WorkloadTime plannedTime={day.plannedTime} predictedTime={day.predictedTime} dayTime={dayTime(day)} dayEvent={day.dayEvent} key={day.day} />
        ))}
        <Text style={{ width: '100px', height: '56px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} bold size={'xl'}>
          {convertMinutesToHours(workloadItem.totalMinutes) ? convertMinutesToHours(workloadItem.totalMinutes) : '0h'}
        </Text>
      </Styled.Wrapper>
    </Styled.MainWrapper>
  );
};

export default EmployeeWorkloadCollapseHeader;
