import React, { FunctionComponent } from 'react';
import { components, OptionProps } from 'react-select';
import { ProjectType } from '../../../../@types/Project/Project';
import { Colored } from '../layout/layout.styled';
import Text from '../../../../components/atoms/Text';

type Props = OptionProps<{ value: string; label: string; meta: ProjectType }, false>;

const SearchOption: FunctionComponent<Props> = (props) => {
  return (
    <components.Option {...props}>
      <Text>
        <Colored>#{props.data.meta.id}</Colored>
        {props.data.meta.name}
      </Text>
      <Text size={'xs'} color={'grey'}>
        {props.data.meta.category.name} &nbsp;&nbsp;&nbsp;
        <Colored>|</Colored> {props.data.meta.client.name}
      </Text>
    </components.Option>
  );
};

export default SearchOption;
