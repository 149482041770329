import Comment from 'components/molecules/Comment/Comment';
import React, { FunctionComponent, ReactElement, useCallback, useEffect, useState } from 'react';
import Text from 'components/atoms/Text';
import { CommentType } from '../../../@types/Comment/CommentType';
import api from '../../../services/api';
import { ArrayResponseType } from '../../../@types/hydra/hydra';
import AddComment from '../../molecules/Comment/AddComment';
import * as Styled from './Comments.styled';
import serverEvents from '../../../services/serverEvents';
import { Icon } from '../../atoms/Icon/Icon';
import SortIcon from '../../../Icons/sort.icon';
import { Loader } from '../../../legacy/CapeMorris/components';

type PropType = {
  subject: string;
  subjectId: string | number;
  border?: 'top' | 'bottom' | 'right' | 'left' | 'full';
  header?: ReactElement | false;
  hideInput?: boolean;
  localStorageKey: string;
  disabled?: boolean;
  finances?: boolean;
};

const Comments: FunctionComponent<PropType> = ({ subject, subjectId, border, header, hideInput = false, localStorageKey, disabled, finances }) => {
  const [comments, setComments] = useState<CommentType[]>([]);
  const [direction, setDirection] = useState<'asc' | 'desc' | undefined>('desc');
  const [loading, setLoading] = useState<boolean>(false);

  const load = useCallback(() => {
    setLoading(true);
    api
      .get<ArrayResponseType<CommentType>>('/new_comments', {
        params: {
          subject: subject,
          subjectId: subjectId,
          _archived: 0,
          createdAt: 'desc',
        },
      })
      .then((response) => {
        setComments(response.data['hydra:member']);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [subject, subjectId, direction]);

  const onSort = () => {
    if (direction === 'desc') {
      setDirection('asc');
    } else {
      setDirection('desc');
    }
  };

  useEffect(() => {
    load();
    //todo: remember to change when classname changes
    const subscription = serverEvents.listen('/api/new_comments').subscribe((event: Partial<CommentType>) => {
      if (!event.subject || (event.subject === subject && Number(event.subjectId) === Number(subjectId))) {
        load();
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <Styled.CommentsMainContainer>
      {loading && <Loader />}
      {header !== false && (
        <Styled.CommentsHeadingWrapper>
          <Text size={'l'} bold color={'semiGrey'}>
            {header ? header : 'Comments'}
          </Text>
          <Styled.CommentsSort type={'button'} onClick={() => onSort()}>
            <Icon>
              <SortIcon direction={direction} />
            </Icon>
          </Styled.CommentsSort>
        </Styled.CommentsHeadingWrapper>
      )}
      <Styled.CommentsWrapper $finances={finances}>
        {comments && comments.length ? (
          comments
            .sort((a, b) => {
              if (direction === 'asc') {
                // @ts-ignore
                return new Date(b.createdAt) - new Date(a.createdAt);
              } else {
                // @ts-ignore
                return new Date(a.createdAt) - new Date(b.createdAt);
              }
            })
            .map((comment: CommentType, idx) => {
              return <Comment comment={comment} key={idx} localStorageKey={localStorageKey} disabled={disabled} />;
            })
        ) : (
          <Text color={'grey'} size={'m'}>
            There are no items to display
          </Text>
        )}
      </Styled.CommentsWrapper>

      {!hideInput && <AddComment subject={subject} subjectId={subjectId} />}
    </Styled.CommentsMainContainer>
  );
};

export default Comments;
