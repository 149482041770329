import React, { FunctionComponent } from 'react';
import { TextInput } from '../../../../molecules/Form/TextInput/TextInput';
import { GridItem } from '../../../../atoms/Grid/Grid';
import { useFormContext } from 'react-hook-form';
import { SwitchInput } from '../../../../molecules/Form/SwitchInput/SwitchInput';
import DynamicSelectInput from '../../../../molecules/Form/SelectInput/DynamicSelectInput';

const CompanyBasicDataForm: FunctionComponent = () => {
  const methods = useFormContext();
  const isOutsideEu = methods.watch('outsideEuropeanUnion');

  return (
    <>
      <GridItem $desktop={6}>
        <TextInput name={'name'} label={'Company name'} placeholder={'ACME Corp.'} required />
      </GridItem>
      <GridItem $desktop={6}>
        <TextInput name={'representative'} label={'Representative'} placeholder={'Jan Kowalski'} />
      </GridItem>
      <GridItem $desktop={6} />
      <GridItem $desktop={6}>
        <SwitchInput name={'outsideEuropeanUnion'} label={'Outside EU'} />
      </GridItem>
      <GridItem $desktop={6}>{!isOutsideEu && <TextInput name={'taxNumber'} label={'Tax number'} required placeholder={'PL000000000'} />}</GridItem>
      <GridItem $desktop={6}>
        <DynamicSelectInput name={'client'} label="Partner" baseUrl={'/api/clients/'} />
      </GridItem>
      <GridItem $desktop={6}>
        <SwitchInput name={'isPrimary'} label={'Primary contractor for partner'} />
      </GridItem>
    </>
  );
};

export default CompanyBasicDataForm;
