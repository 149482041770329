import React, { FunctionComponent } from 'react';
import { ContractorType } from '../../../../@types/Finances/Contractor/ContractorType';
import SingleDetail from '../../../molecules/SingleDetail/SingleDetail';

type Props = {
  contractor: ContractorType;
};

const BankAccountShow: FunctionComponent<Props> = ({ contractor }) => {
  return (
    <>
      <SingleDetail $desktop={6} title={'Name'}>
        {contractor.bank.name}
      </SingleDetail>
      <SingleDetail $desktop={6} title={'Currency'}>
        {contractor.bank.currency}
      </SingleDetail>
      <SingleDetail $desktop={6} title={'IBAN'}>
        {contractor.bank.number}
      </SingleDetail>
      <SingleDetail $desktop={6} title={'SWIFT'}>
        {contractor.bank.swift}
      </SingleDetail>
    </>
  );
};

export default BankAccountShow;
