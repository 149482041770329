import styled from 'styled-components';
import { color } from '../../../../../styles/Variables';

export const SelectWrapper = styled.div`
  .css-tlfecz-indicatorContainer,
  .css-1gtu0rj-indicatorContainer {
    display: none;
  }
  .close-icon:hover {
    svg path {
      stroke: ${color.primary[80]};
    }
  }
`;

export const ClearButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  width: 4rem;
  height: 100%;
  cursor: pointer;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;
