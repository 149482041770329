import React, { FunctionComponent, useCallback } from 'react';
import TablePage from '../../../organisms/TablePage/TablePage';
import { contractsListProperties } from './ContractsList.properties';
import { useNavigate, useLocation } from 'react-router-dom';
import { contractListFilters } from './ContractsList.filters';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import Button from '../../../atoms/Button/Button';
import { ContractType } from '../../../../@types/Finances/Contracts/ContractType';
import { AuthContext } from '../../../../Context/auth-context/auth-context';
import PlusIcon from '../../../../Icons/Plus.icon';

type Props = {};

const ContractsList: FunctionComponent<Props> = () => {
  let location = useLocation();
  let navigate = useNavigate();
  const { can } = React.useContext(AuthContext);

  const handleCreate = useCallback(() => {
    navigate(`${location.pathname}/create`);
  }, [location, navigate]);

  const CreateAction = () => {
    return (
      <Grid spacing={2} $align={'center'}>
        <GridItem $desktop={'auto'}>
          <Button $text={'New contract'} kind={'primary'} onClick={() => handleCreate()} $icon={<PlusIcon />} />
        </GridItem>
      </Grid>
    );
  };

  return (
    <TablePage<ContractType>
      context={'/api/contracts'}
      heading={'Contracts'}
      headingRight={can('FINANCES.CONTRACTS.CAN_MANAGE') ? <CreateAction /> : <></>}
      itemLink={(item) => `${location.pathname}/${item.id}/show`}
      url={'/contracts'}
      properties={contractsListProperties}
      filters={contractListFilters}
      onCreate={handleCreate}
      globalFilters={{
        _archived: 0,
      }}
    />
  );
};

export default ContractsList;
