import styled, { css } from 'styled-components';
import { color } from '../../../styles/Variables';

export const ClickableSingleDetail = styled.div`
  display: flex;
  gap: 0.3rem;
  align-items: center;
  position: relative;
  cursor: pointer;
  width: fit-content;

  &:hover {
    p {
      color: ${color.primary['60']};
    }
  }
`;
