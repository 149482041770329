import React, { FunctionComponent } from 'react';

interface OwnProps {}

type Props = OwnProps;

const DownloadIcon: FunctionComponent<Props> = (props) => {
  return (
    <svg viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path stroke={'currentColor'} d="M0.625 10.625V19.375" strokeLinecap="round" />
      <path stroke={'currentColor'} d="M5.625 8.75L11.25 14.375" strokeLinecap="round" />
      <path stroke={'currentColor'} d="M17.1875 8.75L11.5625 14.375" strokeLinecap="round" />
      <path stroke={'currentColor'} d="M11.25 13.75V0.625" strokeLinecap="round" />
      <path stroke={'currentColor'} d="M21.875 10.625V19.375" strokeLinecap="round" />
      <path stroke={'currentColor'} d="M21.75 19.375H0.625" strokeLinecap="round" />
    </svg>
  );
};

export default DownloadIcon;
