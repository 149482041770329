import React, { FunctionComponent, useCallback, useContext } from 'react';
import { EmployeeType } from '../../../../@types/Employee/EmployeeType';
import PageTop from '../../../molecules/Page/PageTop';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import { Card } from '../../../atoms/Card/Card';
import CardHeading from '../../../molecules/CardHeading/CardHeading';
import { AuthContext } from '../../../../Context/auth-context/auth-context';
import SingleDetail from '../../../molecules/SingleDetail/SingleDetail';
import Heading from '../../../atoms/Heading';
import DateString from '../../../atoms/DateString/DateString';
import VacationDays from './Components/VacationDays';
import Button from '../../../atoms/Button/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import useModal from '../../../../hooks/useModal';
import EmployeeDaysModal from './Components/EmployeeDaysModal';
import EmployeeStatusModal from './Components/EmployeeStatusModal';
import Chip from '../../../atoms/Chips/Chip';
import { color } from '../../../../styles/Variables';
import moneyAsString from '../../../../helpers/moneyAsString';
import { Colored } from '../../../../legacy/CapeMorris/components/layout/layout.styled';

type Props = {
  employee: EmployeeType;
  onChange: () => void;
};

const EmployeeShow: FunctionComponent<Props> = ({ employee, onChange }) => {
  const { can } = useContext(AuthContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { showModal } = useModal();

  const handleAddDays = useCallback(() => {
    showModal({
      title: 'Change vacation days',
      width: 42,
      body: <EmployeeDaysModal entity={employee} onSuccess={() => {}} />,
    });
  }, [showModal, employee]);

  const handleChangeStatus = useCallback(() => {
    showModal({
      title: 'Are you sure?',
      width: 42,
      body: <EmployeeStatusModal employee={employee} onSuccess={onChange} />,
    });
  }, [employee]);

  return (
    <>
      <PageTop
        heading={
          <Grid $align={'center'} spacing={2}>
            <GridItem>
              <Heading>
                <Colored>#{employee.id}</Colored> {employee.name}
              </Heading>
            </GridItem>
            <GridItem>
              <Chip $bgColor={employee.status ? color.primary['60'] : color.neutral['60']}>{employee.status}</Chip>
            </GridItem>
          </Grid>
        }
        rightColumn={
          <Grid spacing={2}>
            {can('EMPLOYEE.CAN_DEACTIVATE') && (
              <GridItem>
                <Button $text={employee.active ? 'Deactivate' : 'Activate'} onClick={handleChangeStatus} kind={'ghost'} />
              </GridItem>
            )}
            {can('EMPLOYEE.CAN_MANAGE.EXTENDED') && (
              <GridItem>
                <Button $text="Add vacation days" onClick={handleAddDays} kind={'secondary'} />
              </GridItem>
            )}
            {can('EMPLOYEE.CAN_MANAGE') && (
              <GridItem>
                <Button $text="Edit" kind={'secondary'} onClick={() => navigate(pathname.replace('show', 'edit'))} />
              </GridItem>
            )}
          </Grid>
        }
      />
      <Grid spacing={2}>
        <GridItem $desktop={6}>
          <Card>
            <CardHeading heading={'Details'} />
            <Grid spacing={2}>
              <SingleDetail $desktop={4} title={'Name'}>
                {employee.name}
              </SingleDetail>
              <SingleDetail $desktop={4} title={'Username'}>
                {employee.username}
              </SingleDetail>
              <SingleDetail $desktop={4} title={'First day'}>
                <DateString input={employee.firstDay} />
              </SingleDetail>
              <SingleDetail $desktop={4} title={'Department'}>
                {employee.department.name}
              </SingleDetail>
              <SingleDetail $desktop={4} title={'Position'}>
                {employee.position.name}
              </SingleDetail>
              <SingleDetail $desktop={4} title={'Leader'}>
                {employee.teamLeader ? employee.teamLeader.name : null}
              </SingleDetail>
            </Grid>
          </Card>
        </GridItem>
        <GridItem $desktop={6}>
          <Card>
            <CardHeading heading={'Contact information'} />
            <Grid spacing={2}>
              <SingleDetail $desktop={6} title={'E-mail'}>
                {employee.email}
              </SingleDetail>
              <SingleDetail $desktop={4} title={'Phone number'}>
                {employee.phoneNumber}
              </SingleDetail>
            </Grid>
          </Card>
        </GridItem>
        {can('EMPLOYEE.CAN_MANAGE.EXTENDED') && (
          <GridItem $desktop={6}>
            <Card>
              <CardHeading heading={'Financial information'} />
              <Grid spacing={2}>
                <SingleDetail $desktop={4} title={'FTE'}>
                  {employee.fullTimeEquivalent}
                </SingleDetail>
                <SingleDetail $desktop={8} title={'Hourly rate'}>
                  {employee.hourlyRate ? employee.hourlyRate : 'Default'}
                </SingleDetail>
                <SingleDetail $desktop={4} title={'Supervision ratio'}>
                  {employee.supervisionRatio}
                </SingleDetail>
                <SingleDetail $desktop={8} title={'Timesheet disabled'}>
                  {employee.timesheetDisabled ? 'Yes' : 'No'}
                </SingleDetail>
                <SingleDetail $desktop={4} title={'Magic mail'}>
                  {employee.specialMail ? 'Yes' : 'No'}
                </SingleDetail>
                <SingleDetail $desktop={8} title={'Maximum PO Acceptance Value'}>
                  {moneyAsString(employee.maximumCostsAcceptanceValue)}
                </SingleDetail>
              </Grid>
            </Card>
          </GridItem>
        )}
        {can('EMPLOYEE.CAN_MANAGE.EXTENDED') && <VacationDays employeeIri={employee['@id']} availableDays={employee.availableDays} />}
      </Grid>
    </>
  );
};

export default EmployeeShow;
