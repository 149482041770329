import React, { FunctionComponent } from 'react';
import * as Styled from './SingleEntry.styled';
import { WorkTimeType } from '../../../../../../@types/Project/Project';
import Text from '../../../../../atoms/Text';
import { convertMinutesToHours } from '../../../../../../helpers/convertMinutesToHours';

type Props = {
  value: WorkTimeType;
  department: string;
};

const SingleEntry: FunctionComponent<Props> = ({ value, department }) => {
  return (
    <Styled.Wrapper>
      <Text color={'light'} size={'s'} bold={true}>
        {department}:
      </Text>
      <Styled.HoursWrapper>
        <Text size={'xs'} color={'light'} bold={true}>
          {value.total ? convertMinutesToHours(value.total) : '0'}
        </Text>
        <Text size={'xs'} color={'light'}>
          /
        </Text>
        <Text size={'xs'} color={'light'}>
          {value.estimated > 0 ? `${value.estimated}h` : '-'}
        </Text>
      </Styled.HoursWrapper>
    </Styled.Wrapper>
  );
};

export default SingleEntry;
