import React, { FunctionComponent } from 'react';

type PropType = {
  size?: number;
  color?: string;
};

const CloseIcon: FunctionComponent<PropType> = ({ size = 1, color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <g>
        <path
          d="M8.53401 24.8717L7.12891 23.4666L14.5956 15.9999L7.12891 8.53327L8.53401 7.12817L16.0007 14.5948L23.4673 7.12817L24.8724 8.53327L17.4058 15.9999L24.8724 23.4666L23.4673 24.8717L16.0007 17.405L8.53401 24.8717Z"
          fill={color ?? 'currentColor'}
        />
      </g>
    </svg>
  );
};

export default CloseIcon;
