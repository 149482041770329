import React, { FunctionComponent } from 'react';
import useModal from '../../../../../hooks/useModal';
import { EmployeeType } from '../../../../../@types/Employee/EmployeeType';
import { VacationDaysFormType, VacationDaysType } from '../../../../../@types/VacationDays/VacationDaysType';
import api from '../../../../../services/api';
import SnackContainer from '../../../../../legacy/CapeMorris/components/snack-bar/use-snack-container';
import Form from '../../../../molecules/Form/Form';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import NumberInput from '../../../../molecules/Form/NumberInput/NumberInput';
import { TextInput } from '../../../../molecules/Form/TextInput/TextInput';
import { ModalFooter } from '../../../../molecules/Modal/Modal';
import Button from '../../../../atoms/Button/Button';

type Props = {
  entity: EmployeeType;
  onSuccess: () => void;
};

const EmployeeDaysModal: FunctionComponent<Props> = ({ entity, ...props }) => {
  const { hideModal } = useModal();
  const [waiting, setWaiting] = React.useState(false);
  const { showSnack } = SnackContainer.useContainer();
  const handleSubmit = React.useCallback(
    (data: VacationDaysFormType) => {
      data.person = entity['@id'];
      setWaiting(true);
      api
        .post<VacationDaysType>('/vacation_days', data)
        .then(() => {
          setWaiting(false);
          props.onSuccess();
          showSnack({
            title: 'Success',
            message: 'Days added successfully!',
            type: 'success',
          });
          hideModal();
        })
        .catch((error) => {
          setWaiting(false);
          const response = error.response;
          showSnack({
            title: 'Error',
            message: response?.data['hydra:description'] ?? 'Unexpected error occured',
            type: 'error',
          });
          hideModal();
        });
    },

    [entity],
  );
  return (
    <Form onSubmit={handleSubmit} defaultValues={entity}>
      <Grid spacing={2}>
        <GridItem $desktop={12}>
          <NumberInput name={'count'} label={'Count'} required placeholder={'Days count to add or remove'} min={-1000} />
        </GridItem>
        <GridItem $desktop={12}>
          <TextInput name={'description'} label={'Description'} required placeholder={'Description'} />
        </GridItem>
      </Grid>
      <ModalFooter>
        <Button $text="Cancel" kind={'link'} onClick={() => hideModal()} />
        <Button $text="Save" kind={'secondary'} waiting={waiting} type={'submit'} />
      </ModalFooter>
    </Form>
  );
};

export default EmployeeDaysModal;
