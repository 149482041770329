import React, { FunctionComponent, useCallback, useContext } from 'react';
import { ChecklistTemplateType } from '../../../../@types/ChecklistTemplate/ChecklistTemplateType';
import InfoBox from '../../InfoBox/InfoBox';
import ChecklistTemplateItem from '../ChecklistTemplateItem/ChecklistTemplateItem';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import { useNavigate } from 'react-router-dom';
import { ArticleType } from '../../../../@types/Article/ArticleType';
import { ItemsWrapper } from './ChecklistTemplate.styled';
import AddToProjectModal from './AddToProjectModal';
import useModal from '../../../../hooks/useModal';
import Button from '../../../atoms/Button/Button';
import Text from '../../../atoms/Text/Text';
import useApi from '../../../../hooks/useApi';
import { AuthContext } from '../../../../Context/auth-context/auth-context';
import InfoIcon from '../../../../Icons/info.icon';
import { Icon } from '../../../atoms/Icon/Icon';

type Props = {
  checklist: ChecklistTemplateType;
  article: ArticleType;
  onChange?: (data: ArticleType) => void;
};

const ChecklistTemplate: FunctionComponent<Props> = ({ checklist, article, onChange }) => {
  const { showModal, hideModal } = useModal();
  const navigate = useNavigate();
  const { del } = useApi();
  const { can } = useContext(AuthContext);

  const handleEdit = useCallback(() => {
    navigate(`/projects/wiki/articles/${article.id}/checklists/${checklist.id}/edit`);
  }, [article, checklist]);

  const handleAddToProject = useCallback(() => {
    showModal({
      title: 'Add checklist to project',
      body: <AddToProjectModal checklist={checklist} />,
    });
  }, [article, checklist]);

  const handleDelete = useCallback(() => {
    del(`/wiki/checklist_templates/${checklist.id}`).then((r) => {
      onChange && onChange(r.data);
      hideModal();
    });
  }, [article, checklist]);

  return (
    <InfoBox title={checklist.name}>
      {checklist.description && (
        <GridItem $desktop={12}>
          <Text size={'m'}>{checklist.description}</Text>
        </GridItem>
      )}
      <GridItem $desktop={12}>
        <ItemsWrapper style={checklist.description ? { marginTop: '1.6rem' } : { marginTop: 0 }}>
          <Grid spacing={1.8}>
            {checklist.items.map((item) => (
              <ChecklistTemplateItem item={item} key={item['@id']} />
            ))}
          </Grid>
        </ItemsWrapper>
      </GridItem>
      <GridItem $desktop={'max'}>
        <Grid spacing={2}>
          <GridItem $desktop={'auto'}>
            <Button $text={'Add to project'} kind={'secondary'} onClick={handleAddToProject} />
          </GridItem>
        </Grid>
      </GridItem>
      <GridItem $desktop={'auto'}>
        <Grid spacing={2}>
          <GridItem $desktop={'auto'}>
            <Button $text={'Edit'} kind={'ghost'} onClick={handleEdit} />
          </GridItem>
          {can('WIKI.CAN_MANAGE') && (
            <>
              <GridItem>
                <Button
                  $text={'Delete'}
                  onClick={() => {
                    showModal({
                      title: 'Checklist deletion',
                      body: (
                        <>
                          <Text>Are you sure you want to delete this checklist?</Text>
                          <div style={{ height: '3rem' }} />
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '0.6rem',
                            }}
                          >
                            <Icon>
                              <InfoIcon />
                            </Icon>
                            <Text size={'s'} color={'grey'}>
                              Checklists already assigned to projects won't be affected
                            </Text>
                          </div>
                        </>
                      ),
                      footer: (
                        <Grid $justifyContent={'end'} spacing={3}>
                          <GridItem>
                            <Button $text={'Delete'} onClick={handleDelete} kind={'negative'} />
                          </GridItem>
                          <GridItem>
                            <Button $text={'Cancel'} onClick={() => hideModal()} kind={'ghost'} />
                          </GridItem>
                        </Grid>
                      ),
                      width: 60,
                    });
                  }}
                  kind={'negative'}
                />
              </GridItem>
            </>
          )}
        </Grid>
      </GridItem>
    </InfoBox>
  );
};

export default ChecklistTemplate;
