import React, { ReactNode, useEffect, useState } from 'react';
import { authReducer } from './auth-reducer';
import { AuthContext } from './auth-context';
import { UserType } from '../../@types/User/user';
import api from '../../services/api';

type Props = {
  children: ReactNode;
};

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = React.useReducer(authReducer, {
    user: null,
    state: 'LOADING',
    context: null,
  });
  const [lastCheck, setLastCheck] = useState<Date>();
  const loadUser = (from?: Partial<Location>, overrideCheck = false) => {
    if (lastCheck && new Date().getTime() - lastCheck.getTime() < 1000 * 60 * 60) {
      if (!overrideCheck) {
        return;
      }
    }
    void api
      .get('/current-user')
      .then(({ data }: { data: UserType }) => {
        dispatch({ type: 'LOGIN', user: data });
        if (from) {
          window.location.href = from.pathname + (from.search ? `?${new URLSearchParams(from.search).toString()}` : '');
        }
        setLastCheck(new Date());
      })
      .catch((error) => {
        dispatch({ type: 'LOGOUT' });
      });
  };

  React.useEffect(() => {
    loadUser();
  }, []);

  useEffect(() => {
    const onFocus = () => {
      if (state.state === 'LOGGED_IN') {
        loadUser();
      }
    };
    window.addEventListener('focus', onFocus);

    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, [state]);

  const authenticate = (username: string, password: string, from: Partial<Location> = { pathname: '/' }) => {
    dispatch({ type: 'LOADING' });
    const formData = new FormData();
    formData.append('_username', username);
    formData.append('_password', password);
    void api
      .post('/login_check', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        loadUser(from, true);
        return response;
      })
      .catch(() => {
        dispatch({ type: 'INVALID_CREDENTIALS' });
      });
  };

  const logout = () => {
    void api.post('/logout', {}).then(() => {
      dispatch({ type: 'LOGOUT' });
    });
  };

  const can = React.useCallback(
    (role: string) => {
      const user = state.user;
      if (!user) {
        return false;
      }
      if (user.roles.includes('ROLE_SUPER_ADMIN') || user.roles.includes('ROLE_ADMIN')) {
        return true;
      }
      for (const singleRole of user.roles) {
        if (singleRole.startsWith(role) || singleRole.startsWith(`ROLE_ACL.${role}`)) {
          return true;
        }
      }
      return user.roles.includes(role) || user.roles.includes(`ROLE_ACL.${role}`);
    },
    [state.user],
  );

  return (
    <AuthContext.Provider value={{ state, dispatch, authenticate, logout, can, loadUser, isImpersonating: state.user?.impersonated ?? false }}>
      {children}
    </AuthContext.Provider>
  );
};
