import { DropdownMenuItemType } from '../../../../organisms/DropdownMenu/DropdownMenuItem/DropdownMenuItem';
import api from '../../../../../services/api';
import React from 'react';
import SnackContainer from '../../../../../legacy/CapeMorris/components/snack-bar/use-snack-container';
import DatabaseIcon from '../../../../../Icons/Database.icon';
import { color } from '../../../../../styles/Variables';
import { Icon } from '../../../../atoms/Icon/Icon';
import { useEntityContext } from '../../../../../Context/EntityContext/EntityContext';
import { ProjectType } from '../../../../../@types/Project/Project';

export const RestoreButton = (id: number): DropdownMenuItemType => {
  const { showSnack } = SnackContainer.useContainer();
  const { replace } = useEntityContext();

  return {
    icon: (
      <Icon color={color.semantic.success['80']}>
        <DatabaseIcon />
      </Icon>
    ),
    text: 'Restore',
    onClick: () => {
      api
        .post<ProjectType>(`/api/projects/${id}/restore`, {})
        .then((response) => {
          replace(response.data);
          showSnack({
            title: 'Success',
            message: 'Project restored',
            type: 'success',
          });
        })
        .catch((error) => {
          showSnack({
            title: 'Error',
            message: error.response.data['hydra:description'],
            type: 'error',
          });
        });
    },
    isBorderTop: true,
  };
};
