import React, { FunctionComponent, useEffect, useRef } from 'react';
import { NavigationNodeType } from '../../../../../@types/NavigationTree/NavigationTreeType';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import Heading from '../../../../atoms/Heading';
import { ContentWrapper } from '../../../../atoms/Projects/Wiki/ListItem';
import { HorizontalLine } from '../../../../atoms/HorizontalLine/HorizontalLine';
import { ArticleType } from '../../../../../@types/Article/ArticleType';
import ArticleListItem from '../../../../molecules/Projects/Wiki/ArticleListItem/ArticleListItem';
import { AnimatedWrapper } from '../../../../atoms/Projects/Wiki/AnimatedWrapper';
import { useIsInViewport } from '../../../../../hooks/useIsInViewport';
import api from '../../../../../services/api';
import { ArrayResponseType } from '../../../../../@types/hydra/hydra';
import Text from '../../../../atoms/Text/Text';
import { Helmet } from 'react-helmet';

type Props = {
  node: NavigationNodeType;
};

const FavoritesCategory: FunctionComponent<Props> = ({ node }) => {
  const wrapperInViewport = useRef(null);
  let isInViewport = useIsInViewport(wrapperInViewport);
  const [articles, setArticles] = React.useState<ArticleType[]>([]);

  useEffect(() => {
    api.get<ArrayResponseType<ArticleType>>('/wiki/favorite-articles').then((response) => {
      setArticles(response.data['hydra:member']);
    });
  }, []);

  return (
    <AnimatedWrapper ref={wrapperInViewport} $isInViewport={isInViewport}>
      <Helmet>
        <title>Favorites | F.CAPE</title>
      </Helmet>
      <Grid $justifyContent={'space-between'} $align={'center'}>
        <GridItem $desktop={'auto'} style={{ marginBottom: '0.8rem' }}>
          <Heading level={1}>{node.name}</Heading>
        </GridItem>
      </Grid>
      <Grid spacing={0.35}>
        <GridItem>
          <Text color={'primary'}>{node.children?.length ?? 0} articles</Text>
        </GridItem>
        <GridItem>
          <Text>in this category</Text>
        </GridItem>
      </Grid>

      <HorizontalLine />
      <ContentWrapper>
        <ContentWrapper>
          <Grid $justifyContent={'space-between'}>
            <GridItem $desktop={'auto'}>
              <Heading level={2} size={'s'}>
                Articles
              </Heading>
            </GridItem>
          </Grid>
        </ContentWrapper>
        {articles.length > 0 && (
          <>
            {articles.map((a: ArticleType) => (
              <ArticleListItem article={a} key={a['@id']} />
            ))}
          </>
        )}
      </ContentWrapper>
    </AnimatedWrapper>
  );
};

export default FavoritesCategory;
