import styled from 'styled-components';
import { Cell } from '../../../../../atoms/Table/TableCell.styled';
import { Grid } from '../../../../../atoms/Grid/Grid';

export const Details = styled(Cell)`
  flex-direction: column;
  vertical-align: unset;
  padding-top: 3rem;
`;

export const DetailsWrapper = styled(Grid)`
  margin-top: 2.4rem;
  gap: 8rem;
`;
