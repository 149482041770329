import React, { FunctionComponent } from 'react';
import SnackContainer from '../../../legacy/CapeMorris/components/snack-bar/use-snack-container';
import api from '../../../services/api';
import Form from '../../../components/molecules/Form/Form';
import Button from '../../../components/atoms/Button/Button';
import { TextInput } from '../../../components/molecules/Form/TextInput/TextInput';
import * as Styled from '../LoginScreen.styled';
import Text from '../../../components/atoms/Text';

type Props = {
  toLogIn: () => void;
};

const ChangePasswordForm: FunctionComponent<Props> = ({ toLogIn }) => {
  const [state, setState] = React.useState<'idle' | 'working' | 'incorrect-password'>('idle');
  const { showSnack } = SnackContainer.useContainer();
  const onSubmit = async (data: { password: string; confirmPassword: string }) => {
    if (data.password !== data.confirmPassword) {
      setState('incorrect-password');
      return;
    }
    setState('working');
    await api.post('/reset-password/assign', { ...data }).then(() => {
      setState('idle');
      showSnack({
        title: 'Success',
        message: 'Your password has been changed.',
      });
      toLogIn();
    });
  };
  return (
    <Form onSubmit={onSubmit}>
      <Styled.HeaderWrapper>
        <Styled.Header $size={'l'}>Change password</Styled.Header>
        <Text size={'l'} color={'grey'}>
          Enter token received in e-mail and new password.
        </Text>
      </Styled.HeaderWrapper>
      <Styled.InputWrapper>
        <div>
          <TextInput name={'token'} label={'Token'} placeholder={'TOKEN_FROM_EMAIL'} required />
        </div>
        <div>
          <TextInput name={'password'} label={'Password'} placeholder={'P@ssw0rd!'} required password />
        </div>
        <div>
          <TextInput name={'confirmPassword'} label={'Confirm password'} placeholder={'P@ssw0rd!'} required password />
        </div>
        {state === 'incorrect-password' && <Text color={'error'}>Passwords are not the same</Text>}
      </Styled.InputWrapper>
      <Styled.ButtonsWrapper>
        <Button type="submit" full={true} $text={'Change password'} waiting={state === 'working'} />
        <Button full={true} onClick={toLogIn} $text={'Log in'} />
      </Styled.ButtonsWrapper>
    </Form>
  );
};

export default ChangePasswordForm;
