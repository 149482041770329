import React, { FunctionComponent, useCallback } from 'react';
import { TaskType } from '../../../../../../../@types/Task/TaskType';
import TaskStatus from '../../../../../../molecules/Statuses/TaskStatus/TaskStatus';
import TimeProgress from '../../../../../../molecules/TimeProgress/TimeProgress';
import PriorityTag from '../../../../../../molecules/PriorityTag/PriorityTag';
import Chip from '../../../../../../atoms/Chips/Chip';
import TaskDeadlineDate from '../../../../../../molecules/TaskDeadlineDate/TaskDeadlineDate';
import Text from '../../../../../../atoms/Text';
import useTaskSidebar from '../../../../../../../hooks/useTaskSidebar';
import * as Styled from '../CommonStyles.styled';

type Props = {
  task: TaskType;
};

const WorkloadTask: FunctionComponent<Props> = ({ task }) => {
  const { openTask } = useTaskSidebar();
  const onItemClick = useCallback(() => {
    openTask(`/api/tasks/${task.id}`);
  }, []);

  return (
    <Styled.MainWrapper onClick={() => onItemClick()}>
      <div>
        <Text size={'l'}> {task.title}</Text>
      </div>
      <Styled.Wrapper>
        <TaskStatus status={task.status} />
        <Chip $rounded>{task.planned ? 'Planned' : 'Predicted'}</Chip>
        <TimeProgress unit={'minutes'} current={task.spentTime} estimated={Number(task.estimate)} width={20} />
        {task.deadline ? <TaskDeadlineDate task={task} /> : <div></div>}
        <PriorityTag priority={task.priority} />
      </Styled.Wrapper>
    </Styled.MainWrapper>
  );
};

export default WorkloadTask;
