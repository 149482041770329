import styled from 'styled-components';
import { GridItem } from '../../../atoms/Grid/Grid';

export const DashboardStyled = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`;

export const Column = styled(GridItem)`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`;
