import styled from 'styled-components';
import Heading from '../../../../../../../../atoms/Heading';
import { color } from '../../../../../../../../../styles/Variables';

export const ActionWrapper = styled.div`
  display: none;
`;
export const NameWrapper = styled.div`
  width: 100%;
`;

export const Name = styled(Heading)`
  padding: 1.6rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    ${ActionWrapper} {
      display: flex;
    }
  }
  span {
    padding-top: 0.4rem;
    color: ${color.neutral[60]};
    font-size: 0.8em;
  }
`;
