import styled from 'styled-components';
import { Grid } from '../../atoms/Grid/Grid';

export const Links = styled.div`
  padding: 10px 30px;
  display: flex;
  justify-content: flex-end;
`;

export const ColumnsGrid = styled(Grid)`
  margin: 6rem 0;
  gap: clamp(2rem, -10.333rem + 6.667vw, 5rem);
  flex-wrap: nowrap;
`;
