import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ArticleType } from '../../../../../@types/Article/ArticleType';
import Person from '../../../../molecules/Person/Person';
import { useIsInViewport } from '../../../../../hooks/useIsInViewport';
import Heading from '../../../../atoms/Heading';
import { Info, InfoInline } from '../../../../atoms/Projects/Wiki/ListItem';
import { HorizontalLine } from '../../../../atoms/HorizontalLine/HorizontalLine';
import { AnimatedWrapper } from '../../../../atoms/Projects/Wiki/AnimatedWrapper';
import { AuthContext } from '../../../../../Context/auth-context/auth-context';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import RichText from '../../../../atoms/RichText/RichText';
import ChecklistTemplate from '../../../../molecules/Projects/ChecklistTemplate/ChecklistTemplate';
import { TagType } from '../../../../../@types/Tag/Tag';
import Button from '../../../../atoms/Button/Button';
import DateString from '../../../../atoms/DateString/DateString';
import HeartButton from '../../../../molecules/HeartButton/HeartButton';
import useApi from '../../../../../hooks/useApi';
import Chip from '../../../../atoms/Chips/Chip';
import Tooltip from '../../../../molecules/Tooltip/Tooltip';
import InfoIcon from '../../../../../Icons/info.icon';
import Text from '../../../../atoms/Text';
import TableOfContents, { HeadingType } from './TableOfContents/TableOfContents';
import Collapse from '../../../../atoms/Collapse/Collapse';
import useModal from '../../../../../hooks/useModal';
import { Icon } from '../../../../atoms/Icon/Icon';
import Resources from '../../../Resources/Resources';
import { Helmet } from 'react-helmet';

type Props = {
  article: ArticleType;
  onChange: (data: ArticleType) => void;
  onTreeReload?: () => void;
  onReload?: () => void;
};

const Article: FunctionComponent<Props> = ({ article, onChange, onTreeReload, onReload }) => {
  const { can } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { post, del } = useApi();
  const wrapperInViewport = useRef(null);
  const [headings, setHeadings] = useState<HeadingType[]>([]);
  const { showModal, hideModal } = useModal();

  const isInViewport = useIsInViewport(wrapperInViewport);

  const handleEdit = useCallback(() => {
    navigate(`/projects/wiki/articles/${article.id}/edit`);
  }, [article]);

  const handleDelete = useCallback(() => {
    del(`/wiki/articles/${article.id}`).then(() => {
      onTreeReload && onTreeReload();
      navigate('/projects/wiki');
      hideModal();
    });
  }, [article]);

  const handleChecklistCreate = useCallback(() => {
    navigate(`/projects/wiki/articles/${article.id}/create-checklist`);
  }, [article]);

  useEffect(() => {
    if (location.pathname !== article.fullPath) {
      navigate(article.fullPath, { replace: true });
    }
  }, []);

  const onArticleLike = useCallback(() => {
    post(`/wiki/articles/${article.id}/favorite`, {}).then((r) => {
      onChange(r.data);
    });
  }, []);

  const canBeDeleted = useMemo(() => {
    return article.checklists.length === 0;
  }, [article]);

  const articleContentWithHeadingIds = useMemo(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(article.content, 'text/html');

    const headers = doc.querySelectorAll('h1, h2, h3, h4');
    headers.forEach((element, index) => {
      const tag = element.tagName.toLowerCase();
      element.id = `${tag}-${index}`;
    });

    return doc.body.innerHTML;
  }, [article.content]);

  useEffect(() => {
    const tempHeadings: HeadingType[] = [];
    const parser = new DOMParser();
    const doc = parser.parseFromString(article.content, 'text/html');

    const headers = doc.querySelectorAll('h1, h2, h3, h4');
    headers.forEach((element, index) => {
      const tag = element.tagName.toLowerCase();
      const id = `${tag}-${index}`;
      tempHeadings.push({ id, text: element.textContent, tag });
    });

    setHeadings(tempHeadings);
  }, [article.content]);

  return (
    <AnimatedWrapper ref={wrapperInViewport} $isInViewport={isInViewport}>
      <Helmet>
        <title>{article.name} | F.CAPE</title>
      </Helmet>
      <Grid $justifyContent={'end'}>
        <GridItem>
          <Grid spacing={3} $align={'center'}>
            {can('WIKI.CAN_MANAGE') && (
              <GridItem>
                <Button $text={'Add checklist'} onClick={handleChecklistCreate} kind={'link'} />
              </GridItem>
            )}
            {can('WIKI.CAN_MANAGE') && (
              <GridItem>
                <Button $text={'Edit'} onClick={handleEdit} kind={'ghost'} />
              </GridItem>
            )}
          </Grid>
        </GridItem>
      </Grid>
      <Grid $justifyContent={'space-between'} $align={'center'}>
        <GridItem>
          <Grid $align={'center'} spacing={1} style={{ marginBottom: '0.8rem' }}>
            <GridItem>
              <Heading level={1}>{article.name}</Heading>
            </GridItem>
            <GridItem>
              <HeartButton onClick={onArticleLike} isFavorite={article.isFavorite} />
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
      <InfoInline>
        <div>
          Last update:{' '}
          <strong>
            <DateString input={article.modifiedAt ? article.modifiedAt : article.createdAt} time />
          </strong>
        </div>
        <div style={{ display: 'flex', gap: '0.6rem', alignItems: 'center' }}>
          <Text size={'s'} color={'semiGrey'} bold>
            If you have any questions, please contact:{' '}
          </Text>
          <Person person={article.author} />
        </div>
      </InfoInline>
      {article.tags.length > 0 && (
        <Info>
          {article.tags.map((tag: TagType, idx) => (
            <Chip key={idx}>{tag.value}</Chip>
          ))}
        </Info>
      )}
      {headings.length === 0 && <HorizontalLine />}
      {headings.length > 0 && (
        <Collapse
          header={
            <Text size={'xl'} bold>
              Contents
            </Text>
          }
          hidden={<TableOfContents headings={headings} />}
        />
      )}

      <Grid spacing={4}>
        <GridItem $desktop={12}>
          <div style={{ marginBottom: '1.6rem' }}>{article.intro && <RichText content={article.intro} />}</div>
          <RichText content={articleContentWithHeadingIds} />
        </GridItem>
        <GridItem $desktop={12}>
          <Resources disableCreation subject={'WikiArticle'} subjectId={article.id} editable={can('WIKI.CAN_MANAGE')} />
        </GridItem>
        {article.checklists.map((checklist) => (
          <GridItem $desktop={12} key={checklist['@id']}>
            <ChecklistTemplate checklist={checklist} article={article} onChange={onReload} />
          </GridItem>
        ))}
        <GridItem style={{ marginBottom: '3rem' }}>
          <Grid $align={'center'} spacing={1}>
            {can('WIKI.CAN_MANAGE') && (
              <>
                <GridItem>
                  <Button
                    $text={'Delete'}
                    onClick={() => {
                      showModal({
                        title: 'Delete article',
                        body: (
                          <>
                            <Text>Are you sure you want to delete this article?</Text>
                            <div style={{ height: '3rem' }} />
                          </>
                        ),
                        footer: (
                          <Grid $justifyContent={'end'} spacing={3}>
                            <GridItem>
                              <Button $text={'Delete'} onClick={handleDelete} kind={'negative'} />
                            </GridItem>
                            <GridItem>
                              <Button $text={'Cancel'} onClick={() => hideModal()} kind={'ghost'} />
                            </GridItem>
                          </Grid>
                        ),
                        width: 60,
                      });
                    }}
                    kind={'link'}
                    disabled={!canBeDeleted}
                    style={{ paddingLeft: 0 }}
                  />
                </GridItem>
                {!canBeDeleted && (
                  <GridItem style={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip
                      content={
                        <Text size={'s'} color={'lightGrey'} style={{ width: '16rem' }}>
                          To delete an article, you must first delete all of its checklists
                        </Text>
                      }
                      trigger={
                        <Icon size={1.2}>
                          <InfoIcon />
                        </Icon>
                      }
                      arrowPosition={'bottomCenter'}
                    />
                  </GridItem>
                )}
              </>
            )}
          </Grid>
        </GridItem>
      </Grid>
    </AnimatedWrapper>
  );
};

export default Article;
