import React from 'react';
import { CellTemplate } from '../../CellTemplate';
import { ProjectListItemType } from '../../../../../@types/Project/Project';
import TimeProgress from '../../../TimeProgress/TimeProgress';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';

const ProjectProgressTemplate: CellTemplate<string, {}, ProjectListItemType> = ({ item }) => {
  return (
    <Grid style={{ width: 160 }}>
      <GridItem $desktop={12}>
        <TimeProgress unit={'minutes'} current={item.totalWorkTime ?? 0} estimated={item.estimatedWorkTime ? item.estimatedWorkTime * 60 : 0} />
      </GridItem>
    </Grid>
  );
};

export default ProjectProgressTemplate;
