import styled, { css } from 'styled-components';

type Props = {
  $narrow?: boolean | 'extra-narrow' | 'medium';
  $wide?: boolean;
};

const Container = styled.div<Props>`
  display: flex;
  flex-flow: column;
  position: relative;
  min-height: 100%;
  padding-top: 2.4rem;
  margin: 0 5rem;

  ${(props) =>
    props.$narrow &&
    css`
      margin: 0 auto;
      max-width: 1140px;
    `}
  ${(props) =>
    props.$narrow === 'extra-narrow' &&
    css`
      max-width: 750px;
      margin: auto;
      padding: 0 3rem;
    `} ${(props) =>
    props.$narrow === 'medium' &&
    css`
      max-width: 1100px;
      margin: auto;
      padding: 0 3rem;
    `}
  ${(props) =>
    props.$wide &&
    css`
      margin: 0 auto;
      max-width: 1460px;

      @media (max-width: 1550px) {
        max-width: 1360px;
      }
    `}
`;

export default Container;
