import styled from 'styled-components';

export type ProjectTimeGrid = {
  $gridTemplateColumns?: string;
  $columnGap?: string;
  $rowGap?: string;
};

export const ProjectTimeStyled = styled.div<ProjectTimeGrid>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ $gridTemplateColumns }) => $gridTemplateColumns || '1fr 1fr'};
  column-gap: ${({ $columnGap }) => $columnGap || '2.4rem;'};
  row-gap: ${({ $rowGap }) => $rowGap || '1.6rem;'};
`;
