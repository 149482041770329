import React, { FunctionComponent } from 'react';

type PropType = {
  size?: number;
  color?: string;
};

const AbsenceIcon: FunctionComponent<PropType> = ({ size = 1, color }) => {
  return (
    <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M11 13C11.2833 13 11.5208 12.9042 11.7125 12.7125C11.9042 12.5208 12 12.2833 12 12C12 11.7167 11.9042 11.4792 11.7125 11.2875C11.5208 11.0958 11.2833 11 11 11C10.7167 11 10.4792 11.0958 10.2875 11.2875C10.0958 11.4792 10 11.7167 10 12C10 12.2833 10.0958 12.5208 10.2875 12.7125C10.4792 12.9042 10.7167 13 11 13ZM7 21V19L13 18V6.875C13 6.625 12.925 6.4 12.775 6.2C12.625 6 12.4333 5.88333 12.2 5.85L7 5V3L12.5 3.9C13.2333 4.03333 13.8333 4.375 14.3 4.925C14.7667 5.475 15 6.11667 15 6.85V19.65L7 21ZM3 21V19H5V5C5 4.43333 5.19583 3.95833 5.5875 3.575C5.97917 3.19167 6.45 3 7 3H17C17.5667 3 18.0417 3.19167 18.425 3.575C18.8083 3.95833 19 4.43333 19 5V19H21V21H3ZM7 19H17V5H7V19Z"
          fill={color ?? 'currentColor'}
        />
      </g>
    </svg>
  );
};

export default AbsenceIcon;
