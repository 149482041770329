import React from 'react';
import { CellTemplate } from '../../../../molecules/CellTemplates/CellTemplate';
import { SubcostType } from '../../../../../@types/Finances/Cost/SubcostType';
import Text from '../../../../atoms/Text';
import Chip from '../../../../atoms/Chips/Chip';
import { PurchaseOrderType } from '../../../../../@types/Finances/PurchaseOrder/PurchaseOrderType';
import EmptyCell from '../../../../molecules/CellTemplates/common/EmptyCell';

const PurchaseOrderTemplate: CellTemplate<PurchaseOrderType, {}, any> = ({ value, options, item }) => {
  return (
    <div style={{ display: 'flex', gap: '0.3rem' }}>
      {value && (
        <>
          <Text color={'primary'} size={'m'} bold={true}>
            #{value.number}
          </Text>
          <Text size={'m'} bold={true}>
            {value.title}
          </Text>
        </>
      )}
      {!value && <EmptyCell />}
    </div>
  );
};
export default PurchaseOrderTemplate;
