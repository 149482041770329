import { DictValue } from '../../@types/Dictionary/DictValue';

const PaymentTermsDict: DictValue[] = [
  { value: '60_days', label: '60 days' },
  { value: '45_days', label: '45 days' },
  { value: '30_days', label: '30 days' },
  { value: '21_days', label: '21 days (only for less than 10 000 PLN gross)' },
  { value: 'special', label: 'Special payment terms' },
];

export default PaymentTermsDict;
