import React from 'react';
import { CellTemplate } from '../../../../molecules/CellTemplates/CellTemplate';
import { SubcostType } from '../../../../../@types/Finances/Cost/SubcostType';
import Text from '../../../../atoms/Text';
import FinanceStatus from '../../../../molecules/Statuses/FinanceStatus/FinanceStatus';

const SubcostNumberTemplate: CellTemplate<string, SubcostType> = ({ value, options, item }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.3rem' }}>
      <Text color={'primary'} size={'m'} bold={true}>
        {value}
      </Text>
      <div style={{ gap: '0.3rem', display: 'flex' }}>
        <FinanceStatus status={item.status} />
      </div>
    </div>
  );
};
export default SubcostNumberTemplate;
