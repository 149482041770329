import styled from 'styled-components';
import { color } from 'styles/Variables';

export const Navigation = styled.div``;

export const Tabs = styled.div`
  width: 100%;
  border-bottom: 1px solid ${color.neutral[40]};
  display: flex;
  margin-bottom: 2.4rem;
`;

export const TabWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3.2rem;
`;
