import React, { FunctionComponent } from 'react';

type PropType = {
  size?: number;
  color?: string;
};

const AcceptIcon: FunctionComponent<PropType> = ({ size = 1, color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g>
        <path d="M6.36641 12L2.56641 8.2L3.51641 7.25L6.36641 10.1L12.4831 3.98334L13.4331 4.93334L6.36641 12Z" fill={color ? color : 'currentColor'} />
      </g>
    </svg>
  );
};

export default AcceptIcon;
