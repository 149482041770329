import React, { FunctionComponent } from 'react';
import Form from '../../../components/molecules/Form/Form';
import { TextInput } from '../../../components/molecules/Form/TextInput/TextInput';
import Button from '../../../components/atoms/Button/Button';
import * as Styled from '../LoginScreen.styled';
import { AuthContext } from '../../../Context/auth-context/auth-context';
import Text from '../../../components/atoms/Text';
import type { Location } from '@remix-run/router';

type Props = {
  toForgotPassword: () => void;
  from: Partial<Location>;
};

const LoginForm: FunctionComponent<Props> = ({ from, toForgotPassword }) => {
  const { authenticate, state } = React.useContext(AuthContext);

  const onSubmit = (data: { username: string; password: string }) => {
    if (data.username && data.password) {
      authenticate(data.username, data.password, from);
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <Styled.HeaderWrapper>
        <Styled.Header $size={'l'}>Sign in</Styled.Header>
        <Text size={'l'} color={'grey'}>
          Please enter your login details below
        </Text>
      </Styled.HeaderWrapper>
      <Styled.InputWrapper>
        <div>
          <TextInput name={'username'} label={'Username'} placeholder={'Type your username or mail'} required />
        </div>
        <div>
          <TextInput name={'password'} label={'Password'} placeholder={'Type your password'} password required />
        </div>
      </Styled.InputWrapper>
      <Styled.ButtonsWrapper>
        <Button $text={'Login'} full={true} type={'submit'} />
        <Button $text={'Forgot password?'} onClick={toForgotPassword} kind={'link'} style={{ paddingLeft: 0 }} />
        {state.state === 'INVALID_CREDENTIALS' && <Text color={'error'}>Invalid credentials</Text>}
      </Styled.ButtonsWrapper>
    </Form>
  );
};

export default LoginForm;
