import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Grid, GridItem } from '../../../../../../components/atoms/Grid/Grid';
import { Card } from '../../../../../../components/atoms/Card/Card';
import Text from '../../../../../../components/atoms/Text/Text';
import CardHeading from '../../../../../../components/molecules/CardHeading/CardHeading';
import api from '../../../../../../services/api';
import { AclType } from '../../../../../../@types/Acl/AclType';
import CheckboxGroup from '../../../../../../components/molecules/Form/Checkbox/CheckboxGroup';

type Props = {};

type GroupedAcls = {
  [key: string]: AclType[];
};

const EmployeeRolesForm: FunctionComponent<Props> = (props) => {
  const [acls, setAcls] = useState<AclType[]>([]);

  useEffect(() => {
    api.get('/acls').then((response) => {
      setAcls(response.data.data);
    });
  }, []);

  const groupedAcls: GroupedAcls = useMemo(() => {
    if (!acls) {
      return { 'No roles': [] };
    }

    let grouped: {
      [key: string]: AclType[];
    } = {};

    acls.forEach((acl) => {
      if (grouped.hasOwnProperty(acl.group)) {
        grouped[acl.group].push(acl);
      } else {
        grouped[acl.group] = [acl];
      }
    });
    return grouped;
  }, [acls]);

  return (
    <GridItem $desktop={12}>
      {acls.length > 0 && Object.keys(groupedAcls).length > 0 && (
        <Card>
          <CardHeading heading={'Roles'} />
          <Grid spacing={3}>
            <GridItem $desktop={12}>
              {Object.keys(groupedAcls).map((group, idx) => (
                <div style={{ marginTop: '1.6rem' }} key={idx}>
                  <Text key={group} size={'l'} bold>
                    {group}
                  </Text>
                  <CheckboxGroup
                    name={'acls'}
                    options={groupedAcls[group].map((a) => ({
                      value: a.systemName,
                      label: a.visibleName,
                    }))}
                  />
                </div>
              ))}
            </GridItem>
          </Grid>
        </Card>
      )}
    </GridItem>
  );
};

export default EmployeeRolesForm;
