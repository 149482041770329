import { InputProps } from '../../../../@types/Form/InputProps';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessages } from '../../../atoms/Form/FormMessages/ErrorMessages';
import FormError from '../../../atoms/Form/FormError/FormError';
import * as Styled from './SwitchInput.styled';

type SwitchInputProps = InputProps & {
  style?: React.CSSProperties;
  defaultValue?: boolean;
};

export const SwitchInput: React.FC<SwitchInputProps> = ({ name, inputRef, label, disabled = false, defaultValue, required = false, onChange }) => {
  const { register, getValues, watch } = useFormContext();
  const valueWatch = watch(name);
  const [checkboxValue, setCheckboxValue] = useState<boolean | undefined>(defaultValue ?? valueWatch);

  useEffect(() => {
    setCheckboxValue(valueWatch);
  }, [valueWatch]);

  return (
    <>
      <Styled.Container>
        <Styled.SwitchLabel>
          <Styled.HiddenCheckbox
            defaultChecked={defaultValue}
            type="checkbox"
            onInput={(event) => (onChange ? onChange(getValues(name)) : null)}
            {...register(name, {
              required: required && ErrorMessages.required,
              onChange: onChange,
            })}
            id={name}
            disabled={disabled}
          />
          <Styled.SwitchWrapper $checked={checkboxValue}>
            <Styled.StyledCheckbox $checked={checkboxValue} />
          </Styled.SwitchWrapper>
          <Styled.SwitchText>{label}</Styled.SwitchText>
        </Styled.SwitchLabel>
      </Styled.Container>
      <FormError name={name} />
    </>
  );
};
