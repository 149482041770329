import React, { FunctionComponent } from 'react';
import DashboardCard from '../DashboardCard';
import LockIcon from '../../../../../../Icons/Lock.icon';
import { color } from '../../../../../../styles/Variables';
import PrivateNoteCardContentCard from '../../../../../molecules/Projects/Dashboard/DashboardCardContentCards/PrivateNoteCardContentCard/PrivateNoteCardContentCard';

type Props = {};

const PrivateNote: FunctionComponent<Props> = (props) => {
  return (
    <DashboardCard title={'Private note'} icon={<LockIcon size={2} color={color.primary['60']} />}>
      <PrivateNoteCardContentCard />
    </DashboardCard>
  );
};

export default PrivateNote;
