import React, { FunctionComponent } from 'react';
import * as Styled from './Styles.styled';
import Tooltip from '../../Tooltip/Tooltip';
import Text from '../../../atoms/Text';
import { MeetingAttendeeType } from '../../../../@types/Meeting/MeetingAttendeeType';

type Props = {
  avatarSize: number;
  move: number;
  idx: number;
  attendees: MeetingAttendeeType[];
};

const ShowMore: FunctionComponent<Props> = ({ avatarSize, move, idx, attendees }) => {
  return (
    <Styled.ShowMoreWrapper
      style={{
        marginLeft: `${(avatarSize / move) * idx + 0.6}rem`,
      }}
      key={idx}
    >
      <Tooltip
        content={
          <Styled.TooltipContentWrapper>
            {attendees.map((a) => (
              <Text size={'xs'} color={'lightGrey'}>
                -{a.name}
              </Text>
            ))}
          </Styled.TooltipContentWrapper>
        }
        trigger={
          <Text size={'s'} bold>
            +{attendees.length - 6} more
          </Text>
        }
        arrowPosition={'leftCenter'}
      />
    </Styled.ShowMoreWrapper>
  );
};

export default ShowMore;
