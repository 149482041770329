import React, { FunctionComponent, useCallback } from 'react';
import { FloatingButton } from '../../../atoms/FloatingButton/FloatingButton';
import * as Common from '../Common.styled';
import { ArrowIcon } from '../../../../Icons/arrow.icon';
import { Icon } from '../../../atoms/Icon/Icon';

type Props = {
  elementId: string;
};

const ScrollToTopButton: FunctionComponent<Props> = ({ elementId }) => {
  const scroll = useCallback(() => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  return (
    <>
      <FloatingButton onClick={scroll}>
        <Common.Button onClick={scroll}>
          <Icon>
            <ArrowIcon direction={'up'} />
          </Icon>
        </Common.Button>
      </FloatingButton>
    </>
  );
};

export default ScrollToTopButton;
