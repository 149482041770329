import React from 'react';
import { TableProperties } from '../../../../../@types/Table/TableProperty';
import IdTemplate from '../../../../../components/molecules/CellTemplates/common/IdTemplate/IdTemplate';
import StringTemplate from '../../../../../components/molecules/CellTemplates/common/StringTemplate/StringTemplate';
import ExclamationMarkIcon from '../../../../../Icons/ExclamationMarkIcon';
import DateString from '../../../../../components/atoms/DateString/DateString';
import { EntityType } from '../../../../../@types/Entity/EntityType';
import { color } from '../../../../../styles/Variables';
import EmptyCell from '../../../../../components/molecules/CellTemplates/common/EmptyCell';
import { Icon } from '../../../../../components/atoms/Icon/Icon';

const properties: TableProperties<EntityType> = [
  {
    name: 'id',
    visibleName: 'ID',
    template: IdTemplate,
  },
  {
    name: 'number',
    visibleName: 'Number',
    sortable: true,
    template: StringTemplate,
    transformValue: (v, o) => {
      if (o.warning) {
        return (
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
              justifyItems: 'flex-start',
              maxWidth: 300,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            <Icon color={color.semantic.error[80]} size={2}>
              <ExclamationMarkIcon />
            </Icon>{' '}
            {v}
          </div>
        );
      } else {
        return v;
      }
    },
  },
  {
    name: 'description',
    visibleName: 'Project',
    template: StringTemplate,
  },
  {
    name: 'contractor.name',
    visibleName: 'Vendors',
    sortable: true,
    template: StringTemplate,
  },
  {
    name: 'amountNet.asString',
    visibleName: 'NET',
    sortable: 'amountNet.value',
    template: StringTemplate,
  },
  {
    name: 'amountGross.asString',
    visibleName: 'GROSS',
    sortable: 'amountGross.value',
    template: StringTemplate,
  },
  {
    name: 'postedAt',
    visibleName: 'Isseued At',
    template: StringTemplate,
    sortable: true,
    transformValue: (value) => <DateString input={value} />,
  },
  {
    name: 'downloadedAt',
    visibleName: 'Downladed at',
    template: StringTemplate,
    sortable: true,
    transformValue: (value) => <DateString input={value} />,
  },
  {
    name: 'downloadedBy',
    visibleName: 'Downladed by',
    template: StringTemplate,
    transformValue: (value) => (value ? `${value.firstName} ${value.lastName}` : <EmptyCell />),
  },
];

export default properties;
