import styled from 'styled-components';
import { color } from '../../../../../styles/Variables';
import { Comment } from '../../../../molecules/Comment/Comment.styled';
import Heading from '../../../../atoms/Heading/Heading';

export const OverviewView = styled.div`
  min-height: 100%;
  margin: -2.4rem -5rem;
  display: grid;
  grid-template-columns: auto 300px;
`;
export const LeftColumn = styled.div`
  padding-top: 4rem;
`;

export const Section = styled.section`
  margin-bottom: 6rem;
  padding-left: -10rem;
`;

export const Tags = styled.div`
  margin-top: 0.8rem;
  display: flex;
  gap: 0.4rem;
`;
export const HeadingWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RightColumn = styled.div`
  background-color: ${color.neutral['20']};
  padding: 4rem 2.4rem;
  min-height: calc(100vh - 10rem);
  position: relative;
`;

export const CommentsWrapper = styled.div`
  position: relative;
  width: 100%;

  ${Comment} {
    position: relative;
    left: -5%;
    width: 110%;
  }
`;

export const SectionTitle = styled(Heading)`
  margin-bottom: 2.4rem;
`;
