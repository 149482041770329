import React, { FunctionComponent } from 'react';
import { color } from '../styles/Variables';

type Props = {
  iconColor?: string;
};

const UnionIcon: FunctionComponent<Props> = ({ iconColor = color.light }) => {
  return (
    <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5668 1.22247H14.4337C14.7711 1.22247 15.0449 0.948689 15.0449 0.611355C15.0449 0.274022 14.7711 0.000244141 14.4337 0.000244141H1.5668C1.22947 0.000244141 0.955688 0.274022 0.955688 0.611355C0.955688 0.948689 1.22947 1.22247 1.5668 1.22247ZM14.3192 4.61138H1.68098C1.28055 4.61138 0.955566 4.3376 0.955566 4.00027C0.955566 3.66294 1.28055 3.38916 1.68098 3.38916H14.3192C14.7196 3.38916 15.0446 3.66294 15.0446 4.00027C15.0446 4.3376 14.7196 4.61138 14.3192 4.61138ZM9.89258 7.99981H1.5668C1.22947 7.99981 0.955688 7.72603 0.955688 7.3887C0.955688 7.05137 1.22947 6.77759 1.5668 6.77759H9.89258C10.2299 6.77759 10.5037 7.05137 10.5037 7.3887C10.5037 7.72603 10.2299 7.99981 9.89258 7.99981Z"
        fill={iconColor}
      />
    </svg>
  );
};

export default UnionIcon;
