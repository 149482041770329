import React, { FunctionComponent, useCallback, useState } from 'react';
import CommentEditor from './elements/CommentEditor';
import useApi from '../../../hooks/useApi';
import Dropzone from '../Dropzone/Dropzone';
import * as Styled from './AddComment.styled';
import { AttachmentType } from '../../../@types/Attachment/AttachmentType';
import Attachments from '../Attachments/Attachments';
import { DropzoneOverlay } from 'components/atoms/Dropzone/DropzoneOverlay';

type Props = {
  subject: string;
  subjectId: string | number;
};

const AddComment: FunctionComponent<Props> = ({ subject, subjectId }) => {
  const [value, setValue] = useState<string | null>(null);
  const [uploadedAttachments, setUploadedAttachments] = useState<AttachmentType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { post } = useApi();

  const onSubmit = useCallback(async () => {
    const formData = {
      content: value,
      attachments: uploadedAttachments,
      subject: subject,
      subjectId: subjectId.toString(),
    };
    setLoading(true);
    post('/api/new_comments', { ...formData }).then(() => {
      setValue('');
      setUploadedAttachments([]);
      setLoading(false);
    });
  }, [uploadedAttachments, value]);

  const handleUploadedFile = useCallback((uuid: string) => {
    void post<AttachmentType>('/api/attachments', {
      fileUuid: uuid,
    }).then(({ data }) => {
      setUploadedAttachments((prev) => [...prev, data]);
    });
  }, []);

  return (
    <Dropzone noClick onUpload={handleUploadedFile} context={'project'}>
      {(isDragActive) => (
        <Styled.Container>
          <CommentEditor
            value={value}
            setValue={setValue}
            onSubmit={onSubmit}
            loading={loading}
            onCancel={() => setUploadedAttachments([])}
            onAttachmentUpload={(item) => setUploadedAttachments((prev) => [...prev, item])}
            localStorageKey={`comment_${subject}_${subjectId}`}
          />
          {isDragActive && <DropzoneOverlay>Drop the file here</DropzoneOverlay>}
          <Attachments attachments={uploadedAttachments} onDelete={setUploadedAttachments} />
        </Styled.Container>
      )}
    </Dropzone>
  );
};

export default AddComment;
