import React, { FunctionComponent } from 'react';
import * as Styled from './Status.styled';
import Chip from '../../atoms/Chips/Chip';
import { TextSizeType } from '../../../@types/Text/TextSizeType';

export type StatusProps = {
  bgColor: string;
  color: string;
  displayedText?: string;
  size?: TextSizeType;
};

const Status: FunctionComponent<StatusProps> = ({ bgColor, color, displayedText, size }) => {
  return (
    <>
      {displayedText && (
        <Chip $bgColor={bgColor} $size={size}>
          <Styled.Circle $color={color} />
          <span
            style={{
              color: `${color}`,
              whiteSpace: 'nowrap',
            }}
          >
            {displayedText}
          </span>
        </Chip>
      )}
      {!displayedText && <Styled.Circle $color={color} />}
    </>
  );
};

export default Status;
