import { InventoryAllocationType } from '../../../@types/Inventory/InventoryAllocationType';
import PendingIcon from '../../../Icons/Pending.icon';
import ArrowLongIcon from '../../../Icons/ArrowLong.icon';
import KeyboardKeysIcon from '../../../Icons/KeyboardKeys.icon';
import { color } from '../../../styles/Variables';
import StockIcon from '../../../Icons/Stock.icon';
import { InventoryItemStatusType } from '../../../@types/Inventory/InventoryCommonTypes';
import React from 'react';
import DangerousIcon from '../../../Icons/Dangerous.icon';
import { PiCurrencyEth } from 'react-icons/pi';

export type ChipProperties = { type: string; bgColor: string; icon: JSX.Element; textColor: string };

export const determineChipProperties = (type: InventoryAllocationType['type'] | InventoryItemStatusType): ChipProperties => {
  switch (type) {
    case 'destruction':
      return {
        type: 'Damaged',
        bgColor: color.semantic.error['20'],
        textColor: color.semantic.error['100'],
        icon: <DangerousIcon />,
      };
    case 'damaged':
      return {
        type: 'Damaged',
        bgColor: color.semantic.error['20'],
        textColor: color.semantic.error['100'],
        icon: <DangerousIcon />,
      };
    case 'return':
      return {
        type: 'Return',
        bgColor: color.semantic.information['20'],
        textColor: color.semantic.information['80'],
        icon: <ArrowLongIcon rotate={180} arrowColor={color.primary['80']} />,
      };
    case 'release':
      return {
        type: 'Release',
        bgColor: color.semantic.success['20'],
        textColor: color.semantic.success['80'],
        icon: <ArrowLongIcon arrowColor={color.secondary['80']} />,
      };
    case 'released':
      return {
        type: 'Release',
        bgColor: color.semantic.success['20'],
        textColor: color.semantic.success['100'],
        icon: <ArrowLongIcon arrowColor={color.secondary['80']} />,
      };
    case 'common_use':
      return {
        type: 'Office use',
        bgColor: color.neutral['30'],
        textColor: color.neutral['100'],
        icon: <KeyboardKeysIcon iconColor={color.neutral['100']} />,
      };
    case 'sold':
      return {
        type: 'Sold',
        bgColor: color.neutral['30'],
        textColor: color.neutral['100'],
        icon: <PiCurrencyEth />,
      };
    case 'restore':
      return {
        type: 'In stock',
        bgColor: color.primary['20'],
        textColor: color.primary['80'],
        icon: <StockIcon iconColor={color.primary['80']} />,
      };
    case 'stock':
      return {
        type: 'In Stock',
        bgColor: color.semantic.information['20'],
        textColor: color.semantic.information['80'],
        icon: <StockIcon iconColor={color.primary['80']} />,
      };
    case 'pending':
      return {
        type: 'Pending',
        bgColor: color.semantic.warning['20'],
        textColor: color.semantic.warning['80'],
        icon: <PendingIcon iconColor={color.semantic.warning['80']} />,
      };
    default:
      return {
        type: 'ERROR',
        bgColor: color.semantic.error['20'],
        textColor: color.semantic.error['100'],
        icon: <DangerousIcon />,
      };
  }
};
