import { TaskType } from '../../../../@types/Task/TaskType';
import { Grid, GridItem } from 'components/atoms/Grid/Grid';
import React, { FunctionComponent, useContext, useMemo } from 'react';
import * as Styled from './TaskTopBar.styled';
import TaskTopBarDropdownButton from './elements/TaskTopBarDropdownButton';
import TaskTopBarAddToTimesheetButton from './elements/TaskTopBarAddToTimesheetButton/TaskTopBarAddToTimesheetButton';
import TaskTopBarActions from './elements/TaskTopBarActions/TaskTopBarActions';
import { SidebarContext } from '../../../../Context/Sidebar/SidebarContext';

type PropType = {
  task: TaskType;
  onDelete?: () => void;
  onEdit?: () => void;
  onMove?: () => void;
  onChange?: () => void;
};

const TaskTopBar: FunctionComponent<PropType> = ({ task, onDelete, onMove, onChange }) => {
  const { closeSidebar } = useContext(SidebarContext);
  const showAddToTimesheet = useMemo(() => {
    if (!task.project) {
      return true;
    }
    if (task.project.status !== 'active' || task.project.archived) {
      return false;
    }
    return task.hasRole;
  }, [task]);
  const handleDelete = () => {
    closeSidebar();
  };
  return (
    <Styled.Bar>
      <GridItem $desktop={'max'}>
        <Grid spacing={2.4} $simple>
          <TaskTopBarDropdownButton task={task} disabled={!!task.deletedAt} onChange={onChange} />
          {showAddToTimesheet && <TaskTopBarAddToTimesheetButton task={task} disabled={!!task.deletedAt} />}
        </Grid>
      </GridItem>
      <GridItem $desktop={'auto'}>
        <TaskTopBarActions task={task} onDelete={handleDelete} onMove={onMove} />
      </GridItem>
    </Styled.Bar>
  );
};

export default TaskTopBar;
