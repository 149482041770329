import { DictValue } from '../@types/Dictionary/DictValue';

export const financesHistoryDict: DictValue[] = [
  { value: 'signedAt', label: 'Signed at' },
  { value: 'comment', label: 'Comment' },
  { value: 'closed', label: 'Closed' },
  { value: 'trueSigner', label: 'Signer' },
  { value: 'costType', label: 'Cost type' },
  { value: 'documentType', label: 'Document type' },
  { value: 'remainNet.value', label: 'Remain net value' },
  { value: 'originalRemainNet.value', label: 'Original remain net value' },
  { value: 'originalRemainNet.currency', label: 'Original remain net currency' },
  { value: 'originalInvoicedNet.value', label: 'Original invoiced net value' },
  { value: 'originalInvoicedNet.currency', label: 'Original invoiced net currency' },
  { value: 'originalAmountNet.value', label: 'Original amount net value' },
  { value: 'originalAmountNet.currency', label: 'Original amount net currency' },
  { value: 'amountNet.value', label: 'Amount net value' },
  { value: 'amountGross.value', label: 'Amount gross value' },
  { value: 'originalAmountGross.value', label: 'Original amount gross value' },
  { value: 'budgetSymbol', label: 'Budget symbol' },
  { value: 'specialPaymentTermsDetails', label: 'Details of special payment terms' },
  { value: 'specialPaymentTermsReason', label: 'Reason special payment terms' },
  { value: 'liabilities.value', label: 'Liabilities value' },
  { value: 'liabilities.currency', label: 'Liabilities currency' },
  { value: 'postedAt', label: 'Posted at' },
  { value: 'paymentDue', label: 'Payment due' },
  { value: 'issuedAt', label: 'Issued at' },
  { value: 'deletedAt', label: 'Deleted at' },
  { value: 'note', label: 'Note' },
  { value: 'internal', label: 'Internal' },
  { value: 'splitPayment', label: 'Split payment' },
  { value: 'excludeFromBankExport', label: 'Exclude from bank export' },
  { value: 'purchaseOrder', label: 'Purchase order' },
  { value: 'vat', label: 'VAT' },
];
