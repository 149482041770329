export type ColorType = 'primary' | 'secondary' | 'neutral' | 'semantic';
export type FontType = 'xs' | 's' | 'm' | 'l' | 'xl';

export const breakpoint = {
  mobile: 0,
  tablet: 640,
  laptop: 1024,
  desktop: 1200,
};

export const fontSize = {
  body: {
    xs: '1.1rem',
    s: '1.2rem',
    m: '1.3rem', //default
    l: '1.4rem',
    xl: '1.6rem',
    smaller: '0.8em',
  },
  heading: {
    xs: '1.4rem',
    s: '1.8rem',
    m: '2.4rem',
    l: '2.8rem',
  },
};

export const fontWeight = {
  regular: '400',
  medium: '500',
  bold: '600',
  extraBold: '700',
};

export const lineHeight = {
  body: {
    s: '1.2',
    m: '1.4',
    l: '1.75',
  },
  ui: {
    s: '1.2',
    m: '1.4',
    l: '1.75',
  },
  heading: {
    s: '1.2',
    m: '1.4',
  },
};

function getDarkMode() {
  const darkMode = localStorage.getItem('darkMode');
  return darkMode === 'true'; //
}

const isDarkModeEnabled: boolean = getDarkMode();

export function adjustColorBrightness(hex: string, amount: number) {
  if (!isDarkModeEnabled) {
    return hex;
  }
  if (hex[0] === '#') {
    hex = hex.slice(1);
  }
  let num = parseInt(hex, 16);
  let r = (num >> 16) + amount;
  let g = ((num >> 8) & 0x00ff) + amount;
  let b = (num & 0x0000ff) + amount;

  r = Math.min(255, Math.max(0, r));
  g = Math.min(255, Math.max(0, g));
  b = Math.min(255, Math.max(0, b));

  let newHex = (r << 16) | (g << 8) | b;
  return '#' + newHex.toString(16).padStart(6, '0');
}

function colorAdjustment(
  lighter: number,
  medium: number,
  darker: number,
): {
  lighter: number;
  medium: number;
  darker: number;
} {
  if (isDarkModeEnabled) {
    return {
      lighter: lighter,
      medium: medium,
      darker: darker,
    };
  } else {
    return {
      lighter: 0,
      medium: 0,
      darker: 0,
    };
  }
}

const bwValues = colorAdjustment(150, 50, -220);
const colorValues = colorAdjustment(80, 30, -180);

export const color = {
  white: '#ffffff',
  dark: adjustColorBrightness('#222328', bwValues.lighter),
  light: adjustColorBrightness('#ffffff', bwValues.darker),
  primary: {
    100: adjustColorBrightness('#0A1572', colorValues.lighter),
    90: adjustColorBrightness('#0F1DA1', colorValues.lighter),
    80: adjustColorBrightness('#1123C6', colorValues.lighter),
    70: adjustColorBrightness('#1529E4', colorValues.lighter),
    60: adjustColorBrightness('#172EFF', colorValues.medium),
    50: adjustColorBrightness('#7479FF', colorValues.medium),
    40: adjustColorBrightness('#A2A5FF', colorValues.medium),
    30: adjustColorBrightness('#C6C8FF', colorValues.darker),
    20: adjustColorBrightness('#E4E5FF', colorValues.darker),
    10: adjustColorBrightness('#F2F2FF', colorValues.darker),
  },
  secondary: {
    100: adjustColorBrightness('#155E25', colorValues.lighter),
    90: adjustColorBrightness('#1E8534', colorValues.lighter),
    80: adjustColorBrightness('#24A340', colorValues.lighter),
    70: adjustColorBrightness('#2ABD49', colorValues.lighter),
    60: adjustColorBrightness('#2FD352', colorValues.medium),
    50: adjustColorBrightness('#7ADD88', colorValues.medium),
    40: adjustColorBrightness('#A5E6AD', colorValues.medium),
    30: adjustColorBrightness('#C8EECC', colorValues.darker),
    20: adjustColorBrightness('#E5F7E7', colorValues.darker),
    10: adjustColorBrightness('#F2FBF3', colorValues.darker),
  },
  neutral: {
    100: adjustColorBrightness('#222328', bwValues.lighter),
    90: adjustColorBrightness('#2C2D33', bwValues.lighter),
    80: adjustColorBrightness('#373942', bwValues.lighter),
    70: adjustColorBrightness('#5F6170', bwValues.medium),
    60: adjustColorBrightness('#777A8C', bwValues.medium),
    50: adjustColorBrightness('#CDCFDB', bwValues.medium),
    40: adjustColorBrightness('#E7E8EE', bwValues.darker),
    30: adjustColorBrightness('#F0F0F2', bwValues.darker),
    20: adjustColorBrightness('#F5F5F7', bwValues.darker),
    10: adjustColorBrightness('#FAFAFC', bwValues.darker),
  },
  semantic: {
    information: {
      100: adjustColorBrightness('#1123C6', colorValues.lighter),
      80: adjustColorBrightness('#414FD1', colorValues.lighter),
      60: adjustColorBrightness('#A0A7E8', colorValues.medium),
      40: adjustColorBrightness('#CFD3F4', colorValues.darker),
      20: adjustColorBrightness('#E8E9F9', colorValues.darker),
    },
    warning: {
      100: adjustColorBrightness('#EF8B2C', colorValues.lighter),
      80: adjustColorBrightness('#FB9433', colorValues.lighter),
      60: adjustColorBrightness('#FBDC9D', colorValues.medium),
      40: adjustColorBrightness('#FDEDCE', colorValues.darker),
      20: adjustColorBrightness('#FEF6E7', colorValues.darker),
    },
    error: {
      100: adjustColorBrightness('#E10044', colorValues.lighter),
      80: adjustColorBrightness('#FF004D', colorValues.lighter),
      60: adjustColorBrightness('#FF80A6', colorValues.medium),
      40: adjustColorBrightness('#FFB3CA', colorValues.darker),
      20: adjustColorBrightness('#FFE6EE', colorValues.darker),
    },
    success: {
      100: adjustColorBrightness('#24A340', colorValues.lighter),
      80: adjustColorBrightness('#50B566', colorValues.lighter),
      60: adjustColorBrightness('#A7DAB3', colorValues.medium),
      40: adjustColorBrightness('#D3EDD9', colorValues.darker),
      20: adjustColorBrightness('#EAF6EC', colorValues.darker),
    },
  },
  additionals: {
    red: adjustColorBrightness('#E10044', colorValues.lighter),
    purple: adjustColorBrightness('#AE32F5', colorValues.lighter),
    magnolia: adjustColorBrightness('#F6EAFE', colorValues.darker),
  },
};
