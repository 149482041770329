import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import * as Styled from './Modal.styled';
import Heading from 'components/atoms/Heading';
import useModal from '../../../hooks/useModal';
import { Grid } from '../../atoms/Grid/Grid';
import Text from '../../atoms/Text';
import CloseButton from '../../atoms/CloseButton/CloseButton';

type PropType = {};

const Modal: FunctionComponent<PropType> = () => {
  const { modal, hideModal } = useModal();
  const [title, setTitle] = useState<string | ReactNode>('');

  useEffect(() => {
    if (!modal) {
      setTitle('');
      return;
    }
    if (typeof modal.title === 'string') {
      setTitle(<Heading>{modal.title}</Heading>);
    } else {
      setTitle(modal.title);
    }
  }, [modal]);

  return (
    <>
      {modal && (
        <Styled.Background onClick={hideModal} style={{ zIndex: modal.zIndex }}>
          <Styled.Wrapper $width={modal.width} onClick={(e) => e.stopPropagation()}>
            <Styled.TitleWrapper>
              <Styled.Title>
                {title}
                <CloseButton onClick={hideModal} />
              </Styled.Title>
              <Text size={'l'} color={'grey'}>
                {modal.subtitle}
              </Text>
            </Styled.TitleWrapper>
            <Styled.OverflowWrapper>{modal.body}</Styled.OverflowWrapper>
            {modal.footer && <ModalFooter>{modal.footer}</ModalFooter>}
          </Styled.Wrapper>
        </Styled.Background>
      )}
    </>
  );
};

export const ModalFooter: FunctionComponent<{
  children: ReactNode | ReactNode[];
}> = ({ children }) => {
  return (
    <Grid $simple $justifyContent={'end'} style={{ marginTop: '1rem' }} spacing={2}>
      {children}
    </Grid>
  );
};

export default Modal;
