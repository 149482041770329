import React, { FunctionComponent } from 'react';
import { FeedEntryType } from '../../../../../@types/FeedEntry/FeedEntryType';
import Text from '../../../../atoms/Text/Text';
import RichText from '../../../../atoms/RichText/RichText';
import FeedEntryBottomWrapper from '../../../WallEntryItem/WallEntryBottomWrapper/FeedEntryBottomWrapper';
import styled from 'styled-components';
import { Loader } from '../../../../../legacy/CapeMorris/components';

type Props = {
  feedEntry?: FeedEntryType;
};

const ContentWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

const Wrapper = styled.div`
  height: 100%;
  max-height: 41rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

const HotNewsCardItem: FunctionComponent<Props> = ({ feedEntry }) => {
  return (
    <Wrapper>
      {feedEntry ? (
        <>
          <ContentWrapper>
            <Text size={'xl'} bold>
              {feedEntry.title}
            </Text>
            <RichText content={feedEntry.content} context={'dashboard'} />
          </ContentWrapper>
          <FeedEntryBottomWrapper feedEntry={feedEntry} areCommentsVisible={false} context={'dashboard'} />
        </>
      ) : (
        <Loader />
      )}
    </Wrapper>
  );
};

export default HotNewsCardItem;
