import React, { FunctionComponent, useMemo } from 'react';
import * as Styled from './Backlog.styled';
import TaskCell from './TaskCell';
import { Droppable } from '@hello-pangea/dnd';
import { TaskListType } from '../../../../../@types/Task/TaskType';

type Props = {
  tasks: TaskListType[];
  startDay: Date;
  endDay: Date;
};

const Backlog: FunctionComponent<Props> = ({ tasks, startDay, endDay, ...props }) => {
  //sort tasks by deadline
  const sortedTasks = useMemo(() => {
    return tasks.sort((a, b) => {
      if (!a.deadline && !b.deadline) {
        return 0;
      }
      if (!a.deadline) {
        return 1;
      }
      if (!b.deadline) {
        return -1;
      }
      return a.deadline > b.deadline ? 1 : -1;
    });
  }, [tasks]);

  //get tasks that deadline is between start and end day

  const tasksBetweenStartAndEndDay = useMemo(() => {
    return sortedTasks.filter((task) => {
      if (!task.deadline) {
        return false;
      }
      const deadline = new Date(task.deadline);
      return deadline <= endDay;
    });
  }, [tasks, endDay, startDay]);

  const futureTasks = useMemo(() => {
    return sortedTasks.filter((task) => {
      const deadline = new Date(task.deadline);
      return !task.deadline || deadline > endDay;
    });
  }, [tasks, endDay, startDay]);

  return (
    <Droppable droppableId={'backlog'}>
      {(provided) => (
        <Styled.Backlog ref={provided.innerRef} {...provided.droppableProps}>
          {tasksBetweenStartAndEndDay.map((task, index) => (
            <TaskCell context={'task'} draggableId={task['@id']} task={task} key={task['@id']} index={index} />
          ))}
          <Styled.Divider>
            future <Styled.DividerLine />
          </Styled.Divider>
          {futureTasks.map((task, index) => (
            <TaskCell context={'task'} draggableId={task['@id']} task={task} key={task['@id']} index={index} />
          ))}
          {provided.placeholder}
        </Styled.Backlog>
      )}
    </Droppable>
  );
};

export default Backlog;
