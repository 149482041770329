import React, { FunctionComponent, useMemo } from 'react';
import * as Styled from './Progress.styled';
import { OverflowSentimentType } from '../../../../@types/OverflowSentiment/OverflowSentimentType';

type Props = {
  current: number;
  estimate: number;
  overflowSentiment?: OverflowSentimentType;
};

const ProgressBar: FunctionComponent<Props> = ({ current, estimate, overflowSentiment = 'neutral' }) => {
  const progress = useMemo(() => {
    return (current / estimate) * 100 > 100 ? 100 : (current / estimate) * 100;
  }, [current, estimate]);

  return (
    <Styled.Container>
      <Styled.ProgressBar $isFilled={current > 0} />
      {current > 0 && <Styled.Progress $width={progress} $maxValue={estimate} $overflowSentiment={overflowSentiment} />}
    </Styled.Container>
  );
};
export default ProgressBar;
