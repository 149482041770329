import React, { Fragment, FunctionComponent, useCallback } from 'react';
import * as Styled from './TableHead.styled';
import { TableProperties, TableProperty } from '../../../../@types/Table/TableProperty';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import Arrow from '../../../atoms/Arrow/Arrow';
import { OrderType } from '../Table';
import Checkbox from '../../../molecules/Form/Checkbox/Checkbox';
import Text from '../../../atoms/Text';
import { convertMinutesToHours } from '../../../../helpers/convertMinutesToHours';
import { color } from '../../../../styles/Variables';
import Form from '../../../molecules/Form/Form';

type Props = {
  properties: TableProperties<any>;
  onSelectAll?: () => void;
  onDeselectAll?: () => void;
  order: OrderType;
  onOrder: (order: OrderType) => void;
  selectable?: boolean;
  hasActions: boolean;
  sums: { [key: string]: string } | null | undefined;
  extendable: boolean;
};

const TableHead: FunctionComponent<Props> = ({ properties, onSelectAll, onDeselectAll, order, onOrder, hasActions, sums, ...props }) => {
  const handleSelectAll = useCallback(
    (checked: boolean) => {
      if (!onSelectAll || !onDeselectAll) {
        throw new Error('onSelectAll or onDeselectAll is not defined');
      }
      if (checked) {
        onSelectAll();
      } else {
        onDeselectAll();
      }
    },
    [onSelectAll, onDeselectAll],
  );

  const getOrderProperty = (property: TableProperty) => {
    return typeof property.sortable === 'string' ? property.sortable : property.name === '@id' ? 'id' : property.name;
  };

  const getOrderByProperty = useCallback(
    (property: TableProperty) => {
      const orderProperty = getOrderProperty(property);
      if (!order) {
        return null;
      }
      if (Object.prototype.hasOwnProperty.call(order, orderProperty)) {
        return order[orderProperty];
      }
      return null;
    },
    [order],
  );

  const handleOrderChange = useCallback(
    (property: TableProperty) => {
      if (!property.sortable) {
        return;
      }
      const orderProperty = getOrderProperty(property);
      if (!order) {
        onOrder({ [orderProperty]: 'ASC' });
        return;
      }
      if (Object.prototype.hasOwnProperty.call(order, orderProperty)) {
        if (order[orderProperty] === 'ASC') {
          onOrder({ [orderProperty]: 'DESC' });
        } else {
          onOrder(undefined);
        }
      } else {
        onOrder({ [orderProperty]: 'ASC' });
      }
    },
    [order, onOrder],
  );

  const parseSum = useCallback((property: TableProperty, value: number | string) => {
    if (property.sumAsTime) {
      return convertMinutesToHours(+value);
    }
    return value;
  }, []);

  const sortColumn = (property: TableProperty) => (
    <>
      {property.sortable && (
        <GridItem>
          <Arrow
            small
            direction={getOrderByProperty(property) === 'ASC' ? 'up' : 'down'}
            color={getOrderByProperty(property) !== null ? 'primary' : undefined}
          />
        </GridItem>
      )}
    </>
  );

  return (
    <Styled.Head>
      <tr>
        {props.extendable && <Styled.ExtendColumn />}
        {props.selectable && (
          <Styled.SelectColumn>
            <Form onSubmit={() => {}}>
              <Checkbox name="table-select-all" onChange={(value) => handleSelectAll(value)} />
            </Form>
          </Styled.SelectColumn>
        )}
        {properties.map((property, index) => (
          <Fragment key={`th-${property.name}-${index}`}>
            <Styled.Column
              $clickable={!!property.sortable}
              onClick={() => handleOrderChange(property)}
              title={property.title}
              $minWidth={property.templateOptions?.minWidth ?? null}
            >
              <Grid $align={'center'} spacing={1} $justifyContent={property.justifyContent ?? 'start'} style={{ flexWrap: 'nowrap' }}>
                <GridItem $desktop={'auto'}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: property.justifyContent ?? 'start',
                    }}
                  >
                    <Text size={'m'} color={'darkGrey'} bold style={{ display: 'flex', color: color.primary[80] }}>
                      {property.visibleName}&nbsp;&nbsp;&nbsp;{sortColumn(property)}
                    </Text>
                    {property.sumBy && sums && <Styled.ColumnSum>{parseSum(property, sums[property.sumBy])}</Styled.ColumnSum>}
                  </div>
                </GridItem>
              </Grid>
            </Styled.Column>
          </Fragment>
        ))}
        {hasActions && (
          <Styled.Column>
            <Styled.Label>Actions</Styled.Label>
          </Styled.Column>
        )}
      </tr>
    </Styled.Head>
  );
};

export default TableHead;
