import styled, { css } from 'styled-components';
import { kindType } from './Alert';
import { color } from '../../../styles/Variables';

type AlertProps = {
  $kind: kindType;
};

export const Alert = styled.div<AlertProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${color.semantic.warning['20']};
  padding: 2rem 3.2rem;
  border-radius: 6px;

  ${(props: AlertProps) =>
    props.$kind === 'success' &&
    css`
      background-color: ${color.semantic.success['20']};
    `}

  ${(props: AlertProps) =>
    props.$kind === 'warning' &&
    css`
      background-color: ${color.semantic.warning['20']};
    `}

  ${(props: AlertProps) =>
    props.$kind === 'error' &&
    css`
      background-color: ${color.semantic.error['20']};
    `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
`;

export const TextWrapper = styled.div``;
