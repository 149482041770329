import React, { FunctionComponent } from 'react';
import * as Styled from './Reaction.styled';
import Text from '../Text';

type Props = {
  reactionType: JSX.Element;
  count: number;
  onClick?: () => void;
};

const Reaction: FunctionComponent<Props> = ({ reactionType, count, onClick }) => {
  return (
    <Styled.ReactionWrapper onClick={onClick}>
      {reactionType}
      <Text size={'l'} bold>
        {count}
      </Text>
    </Styled.ReactionWrapper>
  );
};

export default Reaction;
