import React, { FunctionComponent } from 'react';
import { SharepointIcon } from '../../../../Icons/Sharepoint.icon';
import { Icon } from '../../../atoms/Icon/Icon';
import AttachmentIcon from '../../../../Icons/Attachment/Attachment.icon';
import * as Styled from './ResourceIcon.styled';
import { ResourceType } from '../../../../@types/Resource/ResourceType';
import { MattermostIcon } from '../../../../Icons/Mattermost.icon';
import KeynoteIcon from '../../../../Icons/Keynote.icon';
import FigmaIcon from '../../../../Icons/Figma.icon';
import MicrosoftProjectIcon from '../../../../Icons/MicrosoftProject.icon';
import { PiKanbanBold } from 'react-icons/pi';

type Props = {
  resource: ResourceType;
};

const ResourceIcon: FunctionComponent<Props> = ({ resource }) => {
  const isImage = !!resource.sharepointData?.file?.mimeType?.startsWith('image/') || !!resource.file?.mimeType?.startsWith('image/');
  const isFile = (!!resource.sharepointData?.file || !!resource.file) && !isImage;
  const isFolder = !!resource.sharepointData?.folder || (resource.type === 'sharepoint' && !resource.sharepointData);

  const fileName = resource.file ? resource.file.originalName : resource.sharepointData?.name;
  return (
    <>
      {isImage && resource.previewUrl && <AttachmentIcon name={fileName ?? '-'} url={resource.previewUrl} />}
      {isFile && <AttachmentIcon name={fileName ?? '-'} />}
      {resource.type === 'mattermost' && (
        <Styled.Icon>
          <Icon size={1.5}>
            <MattermostIcon />
          </Icon>
        </Styled.Icon>
      )}
      {resource.type === 'keynote' && (
        <Styled.Icon>
          <Icon size={1.5}>
            <KeynoteIcon />
          </Icon>
        </Styled.Icon>
      )}
      {resource.type === 'figma' && (
        <Styled.Icon>
          <Icon size={1.5}>
            <FigmaIcon />
          </Icon>
        </Styled.Icon>
      )}
      {resource.type === 'microsoft_project' && (
        <Styled.Icon>
          <Icon size={1.5}>
            <MicrosoftProjectIcon />
          </Icon>
        </Styled.Icon>
      )}
      {resource.type === 'gantt' && (
        <Styled.Icon>
          <Icon size={1.5}>
            <PiKanbanBold />
          </Icon>
        </Styled.Icon>
      )}
      {isFolder && (
        <Styled.Icon>
          <Icon size={1.5}>
            <SharepointIcon />
          </Icon>
        </Styled.Icon>
      )}
    </>
  );
};

export default ResourceIcon;
