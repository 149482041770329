import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CircleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
`;

export const Circle = styled.span<{ $color: string; $size?: 's' | 'm' }>`
  display: block;
  background-color: ${(props) => props.$color};
  border-radius: 50%;
  height: 8px;
  width: 8px;
  ${(props) =>
    props.$size === 'm' &&
    css`
      height: 10px;
      width: 10px;
    `}
`;
