export type MoneyType = {
  value: number;
  currency: string;
  asString?: string;
};

export const sum = (a: MoneyType, b: MoneyType): MoneyType => {
  return {
    value: (!!a.value && !isNaN(a.value) ? a.value : 0) + (!!b.value && !isNaN(b.value) ? b.value : 0),
    currency: a.currency,
  };
};
