import { components, OptionProps } from 'react-select';
import React, { FunctionComponent } from 'react';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import Text from '../../../atoms/Text';
import { fontWeight } from '../../../../styles/Variables';
import { PurchaseOrderType } from '../../../../@types/Finances/PurchaseOrder/PurchaseOrderType';

type Props = OptionProps<
  {
    value: string;
    label: string;
    meta: PurchaseOrderType;
  },
  false
>;
const PurchseOrderOption: FunctionComponent<Props> = (props) => {
  return (
    <components.Option {...props}>
      <Grid style={{ fontWeight: fontWeight.regular }}>
        <GridItem $desktop={12}>
          <Text as={'span'} color={'primary'} bold>
            #{props.data.meta.number}&nbsp;
          </Text>{' '}
          {props.data.meta.title}
        </GridItem>
        <GridItem $desktop={12}>
          <Grid spacing={1} $justifyContent={'space-between'}>
            <GridItem>
              <Text bold size={'xs'} color={'grey'}>
                {props.data.meta.contractor.name}
              </Text>
            </GridItem>
            <GridItem>
              <Text bold size={'xs'} color={'dark'}>
                {props.data.meta.originalAmountNet.asString}
              </Text>
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </components.Option>
  );
};
export default PurchseOrderOption;
