export const parseDate = (
  date: Date | string,
  withTime: boolean = false,
  monthFormat: boolean = false,
  dayFormat: boolean = false,
  onlyTime: boolean = false,
  longMonth: boolean = false,
  longMonthWithYear: boolean = false,
) => {
  date = new Date(date);
  let result = `${prefixZero(date.getDate())}.${prefixZero(date.getUTCMonth() + 1)}.${date.getFullYear()}`;

  if (monthFormat) {
    result = `${prefixZero(date.getUTCMonth() + 1)}.${date.getFullYear()}`;
  }

  if (dayFormat) {
    result = `${date.getDate()}.${prefixZero(date.getUTCMonth() + 1)}`;
  }

  if (withTime) {
    result = `${result}, ${prefixZero(date.getHours())}:${prefixZero(date.getMinutes())}`;
  }

  if (onlyTime) {
    result = `${prefixZero(date.getHours())}:${prefixZero(date.getMinutes())}`;
  }

  if (longMonth) {
    result = `${new Intl.DateTimeFormat('en-EN', {
      month: 'long',
      day: 'numeric',
    }).format(new Date(date))}`;
  }
  if (longMonthWithYear) {
    result =
      `${new Intl.DateTimeFormat('en-EN', {
        month: 'short',
        day: 'numeric',
      }).format(new Date(date))}` + `, ${date.getFullYear()}`;
  }

  return result;
};

const prefixZero = (toBePrefixed: number) => {
  if (toBePrefixed < 10) {
    return `0${toBePrefixed}`;
  } else {
    return toBePrefixed;
  }
};
