import React, { FunctionComponent } from 'react';

type PropType = {
  size?: number;
  filled?: boolean;
};

const BellIcon: FunctionComponent<PropType> = ({ size = 1, filled = false }) => {
  return (
    <>
      {!filled ? (
        <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3 15.875V14.125H4.75V8C4.75 6.78958 5.11458 5.71406 5.84375 4.77344C6.57292 3.83281 7.52083 3.21667 8.6875 2.925V2.3125C8.6875 1.94792 8.8151 1.63802 9.07031 1.38281C9.32552 1.1276 9.63542 1 10 1C10.3646 1 10.6745 1.1276 10.9297 1.38281C11.1849 1.63802 11.3125 1.94792 11.3125 2.3125V2.925C12.4792 3.21667 13.4271 3.83281 14.1562 4.77344C14.8854 5.71406 15.25 6.78958 15.25 8V14.125H17V15.875H3ZM10 18.5C9.51875 18.5 9.10677 18.3286 8.76406 17.9859C8.42135 17.6432 8.25 17.2313 8.25 16.75H11.75C11.75 17.2313 11.5786 17.6432 11.2359 17.9859C10.8932 18.3286 10.4813 18.5 10 18.5ZM6.5 14.125H13.5V8C13.5 7.0375 13.1573 6.21354 12.4719 5.52813C11.7865 4.84271 10.9625 4.5 10 4.5C9.0375 4.5 8.21354 4.84271 7.52813 5.52813C6.84271 6.21354 6.5 7.0375 6.5 8V14.125Z"
            fill="currentColor"
          />
        </svg>
      ) : (
        <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.54102 14.4719V15.7219H16.4576V14.4719H14.791V8.43023C14.791 7.29881 14.4411 6.29854 13.7413 5.42943C13.0415 4.56031 12.1414 4.00288 11.041 3.75716V3.17383C11.041 2.88002 10.9408 2.63296 10.7405 2.43264C10.5402 2.23232 10.2931 2.13216 9.99933 2.13216C9.70552 2.13216 9.45847 2.23232 9.25816 2.43264C9.05784 2.63296 8.95768 2.88002 8.95768 3.17383V3.75716C7.85725 4.00288 6.95714 4.56031 6.25735 5.42943C5.55757 6.29854 5.20768 7.29881 5.20768 8.43023V14.4719H3.54102Z"
            fill="currentColor"
          />
          <path
            d="M8.93843 17.7363C9.23545 18.028 9.58908 18.1738 9.99933 18.1738C10.4203 18.1738 10.7766 18.028 11.0682 17.7363C11.3599 17.4446 11.5057 17.0883 11.5057 16.6674H8.49293C8.49293 17.0883 8.64143 17.4446 8.93843 17.7363Z"
            fill="currentColor"
          />
        </svg>
      )}
    </>
  );
};

export default BellIcon;
