import React, { FunctionComponent } from 'react';
import Heading from '../../atoms/Heading';
import { Grid, GridItem } from '../../atoms/Grid/Grid';
import SingleDetail from '../../molecules/SingleDetail/SingleDetail';
import { PositionType } from '../../../@types/Finances/PositionType';
import moneyAsString from '../../../helpers/moneyAsString';

type Props = {
  position: PositionType;
  idx: number;
};

const Position: FunctionComponent<Props> = ({ position, idx }) => {
  return (
    <GridItem $desktop={12} style={{ margin: '2.6rem 0' }}>
      <div style={{ marginBottom: '0.6rem' }}>
        <Heading size={'s'}>Position no. {idx}</Heading>
      </div>
      <Grid spacing={3}>
        <SingleDetail title={'Name'} $desktop={12}>
          <span dangerouslySetInnerHTML={{ __html: position.name }} />
        </SingleDetail>
        <SingleDetail title={'VAT rate'} $desktop={4}>
          {position.vat === -1 ? 'ZW' : `${position.vat * 100}%`}
        </SingleDetail>
        {position.amountNet.currency != position.originalAmountNet.currency && (
          <SingleDetail title={'Exchange rate'} $desktop={4}>
            {position.exchangeRate}
          </SingleDetail>
        )}
        <SingleDetail title={'Net value'} $desktop={4} type={'money'}>
          {moneyAsString(position.amountNet)}
        </SingleDetail>
        <SingleDetail title={'Gross value'} $desktop={4} type={'money'}>
          {moneyAsString(position.amountGross)}
        </SingleDetail>
        {position.amountNet.currency != position.originalAmountNet.currency && (
          <>
            <SingleDetail title={'Original net value'} $desktop={4} type={'money'}>
              {moneyAsString(position.originalAmountNet)}
            </SingleDetail>
            <SingleDetail title={'Original gross value'} $desktop={8} type={'money'}>
              {moneyAsString(position.originalAmountGross)}
            </SingleDetail>
          </>
        )}
      </Grid>
    </GridItem>
  );
};

export default Position;
