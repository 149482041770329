import React, { FunctionComponent, useCallback, useContext, useMemo, useRef } from 'react';
import { CategoryType } from '../../../../../@types/Category/CategoryType';
import { ArticleType } from '../../../../../@types/Article/ArticleType';
import { useIsInViewport } from '../../../../../hooks/useIsInViewport';
import Heading from '../../../../atoms/Heading';
import ArticleListItem from '../../../../molecules/Projects/Wiki/ArticleListItem/ArticleListItem';
import { ContentWrapper } from '../../../../atoms/Projects/Wiki/ListItem';
import { HorizontalLine } from '../../../../atoms/HorizontalLine/HorizontalLine';
import { AnimatedWrapper } from '../../../../atoms/Projects/Wiki/AnimatedWrapper';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import { AuthContext } from '../../../../../Context/auth-context/auth-context';
import { useNavigate } from 'react-router-dom';
import RichText from '../../../../atoms/RichText/RichText';
import Button from '../../../../atoms/Button/Button';
import Text from '../../../../atoms/Text/Text';
import CategoryListItem from '../../../../molecules/Projects/Wiki/CategoryListItem/CategoryListItem';
import useApi from '../../../../../hooks/useApi';
import Tooltip from '../../../../molecules/Tooltip/Tooltip';
import InfoIcon from '../../../../../Icons/info.icon';
import useModal from '../../../../../hooks/useModal';
import { Helmet } from 'react-helmet';

type Props = {
  category: CategoryType;
  onTreeReload?: () => void;
};

const Category: FunctionComponent<Props> = ({ category, onTreeReload }) => {
  const wrapperInViewport = useRef(null);
  const isInViewport = useIsInViewport(wrapperInViewport);
  const { can } = useContext(AuthContext);
  const navigate = useNavigate();
  const { del } = useApi();
  const { showModal, hideModal } = useModal();

  const handleEdit = useCallback(() => {
    navigate(`/projects/wiki/categories/${category.id}/edit`);
  }, [category]);

  const handleCreateArticle = useCallback(() => {
    navigate('/projects/wiki/articles/create', {
      state: { category: category },
    });
  }, [category]);

  const handleCreateCategory = useCallback(() => {
    navigate('/projects/wiki/categories/create', {
      state: { category: category },
    });
  }, [category]);

  const handleDeleteCategory = useCallback(() => {
    del(`wiki/wiki_categories/${category.id}`).then(() => {
      onTreeReload && onTreeReload();
      navigate('/projects/wiki');
      hideModal();
    });
  }, [category]);

  const canBeDeleted = useMemo(() => {
    return category.children.length === 0 && category.articles.length === 0;
  }, [category]);

  return (
    <AnimatedWrapper ref={wrapperInViewport} $isInViewport={isInViewport}>
      <Helmet>
        <title>{category.name} | F.CAPE</title>
      </Helmet>
      <Grid $justifyContent={'end'} spacing={3} $align={'center'}>
        {can('WIKI.CAN_MANAGE') && (
          <GridItem $desktop={'auto'}>
            <Button $text={'Edit'} onClick={handleEdit} kind={'ghost'} />
          </GridItem>
        )}
        {can('WIKI.CAN_MANAGE') && (
          <>
            <GridItem>
              <Button
                $text={'Delete'}
                onClick={() => {
                  showModal({
                    title: 'Delete category',
                    body: (
                      <>
                        <Text>Are you sure you want to delete this category?</Text>
                        <div style={{ height: '3rem' }} />
                      </>
                    ),
                    footer: (
                      <Grid $justifyContent={'end'} spacing={3}>
                        <GridItem>
                          <Button $text={'Delete'} onClick={handleDeleteCategory} kind={'negative'} />
                        </GridItem>
                        <GridItem>
                          <Button $text={'Cancel'} onClick={() => hideModal()} kind={'ghost'} />
                        </GridItem>
                      </Grid>
                    ),
                    width: 60,
                  });
                }}
                kind={'negative'}
                disabled={!canBeDeleted}
              />
            </GridItem>
            {!canBeDeleted && (
              <GridItem>
                <Tooltip
                  content={
                    <Text size={'s'} color={'lightGrey'} style={{ width: '16rem' }}>
                      To delete a category, you must first delete all its subcategories and articles
                    </Text>
                  }
                  trigger={<InfoIcon />}
                  arrowPosition={'topCenter'}
                />
              </GridItem>
            )}
          </>
        )}
      </Grid>
      <Grid $justifyContent={'space-between'} $align={'center'}>
        <GridItem $desktop={'auto'} style={{ marginBottom: '0.8rem' }}>
          <Heading level={1}>{category.name}</Heading>
        </GridItem>
      </Grid>
      <Grid spacing={0.35}>
        <GridItem>
          <Text color={'primary'}>
            {category.articles.length} {category.articles.length === 1 ? 'article' : 'articles'}
          </Text>
        </GridItem>
        <GridItem>
          <Text> in this category</Text>
        </GridItem>
      </Grid>
      <HorizontalLine />
      <ContentWrapper>
        <RichText content={category.description} />
      </ContentWrapper>
      <ContentWrapper>
        <ContentWrapper>
          <Grid $justifyContent={'space-between'}>
            <GridItem $desktop={'auto'}>
              <Heading level={2} size={'s'}>
                Categories
              </Heading>
            </GridItem>
            {can('WIKI.CAN_MANAGE') && (
              <GridItem $desktop={'auto'}>
                <Button $text={'Create' + ' category'} onClick={handleCreateCategory} kind={'primary'} />
              </GridItem>
            )}
          </Grid>
        </ContentWrapper>
        {category.children.length > 0 ? (
          <>
            {category.children.map((c: CategoryType) => (
              <CategoryListItem category={c} key={c['@id']} />
            ))}
          </>
        ) : (
          <Text>No categories in this category</Text>
        )}
      </ContentWrapper>

      <ContentWrapper>
        <ContentWrapper>
          <Grid $justifyContent={'space-between'}>
            <GridItem $desktop={'auto'}>
              <Heading level={2} size={'s'}>
                Articles
              </Heading>
            </GridItem>
            {can('WIKI.CAN_MANAGE') && (
              <GridItem $desktop={'auto'}>
                <Button $text={'Create' + ' article'} onClick={handleCreateArticle} kind={'secondary'} />
              </GridItem>
            )}
          </Grid>
        </ContentWrapper>
        {category.articles.length > 0 ? (
          <>
            {category.articles.map((a: ArticleType) => (
              <ArticleListItem article={a} key={a['@id']} />
            ))}
          </>
        ) : (
          <Text>No articles in this category</Text>
        )}
      </ContentWrapper>
    </AnimatedWrapper>
  );
};

export default Category;
