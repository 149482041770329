import React from 'react';
import { CellTemplate } from '../CellTemplate';
import InventoryItemType from '../../../../@types/Inventory/InventoryItemType';
import IdText from '../../IdText/IdText';
import Chip from '../../../atoms/Chips/Chip';
import { ChipProperties, determineChipProperties } from '../../../atoms/Chips/determineChipProperties';
import Text from '../../../atoms/Text/Text';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import { Icon } from '../../../atoms/Icon/Icon';

const InventoryIdTemplate: CellTemplate<string, {}, InventoryItemType> = ({ value, item }) => {
  const inventoryStatus: ChipProperties = determineChipProperties(item.status);
  return (
    <Grid spacing={0.6}>
      <GridItem $desktop={12}>
        <Grid spacing={0.6}>
          <GridItem>
            <IdText withHash={true} value={value} />
          </GridItem>
          <GridItem>
            <Chip $bgColor={inventoryStatus.bgColor}>
              <Icon size={1.2}>{inventoryStatus.icon}</Icon>
              <Text size={'s'} color={'darkGrey'} bold>
                <span style={{ color: inventoryStatus.textColor }}>{inventoryStatus.type}</span>
              </Text>
            </Chip>
          </GridItem>
        </Grid>
      </GridItem>
      <GridItem $desktop={12}>
        <Text color={'darkGrey'} size={'s'} bold>
          {item.name}
        </Text>
      </GridItem>
      <GridItem $desktop={12}>
        <Text color={'grey'} size={'s'}>
          {item.manufacturer}&nbsp;/&nbsp;{item.model}
        </Text>
      </GridItem>
    </Grid>
  );
};
export default InventoryIdTemplate;
