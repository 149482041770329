import styled from 'styled-components';

export const CardHeadingWrapper = styled.div<{ $noMargin: boolean }>`
  margin-bottom: ${(p) => (p.$noMargin ? '0' : '2rem')};
`;

export const CardHeading = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  justify-content: space-between;
`;
