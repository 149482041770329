import React, { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import { TextInput } from '../../../../../../components/molecules/Form/TextInput/TextInput';
import Select from '../../../../../../components/molecules/Form/SelectInput/SelectInput';
import { Grid, GridItem } from '../../../../../../components/atoms/Grid/Grid';
import { Card } from '../../../../../../components/atoms/Card/Card';
import CardHeading from '../../../../../../components/molecules/CardHeading/CardHeading';
import EmployeeInput from '../../../../../../components/molecules/Form/EmployeeInput/EmployeeInput';
import DynamicSelectInput from '../../../../../../components/molecules/Form/SelectInput/DynamicSelectInput';

type Props = {};

const EmployeePositionForm: FunctionComponent<Props> = (props) => {
  const { watch, setValue } = useFormContext();
  const firstName = watch('firstName');
  const lastName = watch('lastName');

  React.useEffect(() => {
    if (!firstName || !lastName) {
      return;
    }
    const login = `${firstName.toLowerCase().substring(0, 1)}.${lastName
      .toLowerCase()
      .normalize('NFKD')
      .replace(/[\u0300-\u036f]/g, '')}`;
    setValue('login', login);
  }, [firstName, lastName]);

  return (
    <GridItem $desktop={6}>
      <Card>
        <CardHeading heading={'Position'} />
        <Grid spacing={2}>
          <GridItem $desktop={6}>
            <DynamicSelectInput
              baseUrl={'/employee_positions'}
              name={'position'}
              label={'Position'}
              required
              placeholder={'Junior Senior Everything Manager'}
            />
          </GridItem>
          <GridItem $desktop={6}>
            <Select name={'department'} label={'Department'} required baseUrl={'/api/departments/'} />
          </GridItem>
          <GridItem $desktop={6}>
            <EmployeeInput name={'teamLeader'} group={'organisation'} label={'Team leader'} />
          </GridItem>
        </Grid>
      </Card>
    </GridItem>
  );
};

export default EmployeePositionForm;
