import api from '../../../../../services/api';
import { ArrayResponseType } from '../../../../../@types/hydra/hydra';
import { HolidayType } from '../../../../../@types/Holiday/HolidayType';
import { AbsenceType } from '../../../../../@types/Absences/AbsenceType';
import { DayEventType } from '../components/DayEventType';

const splitAbsence = (absence: AbsenceType): DayEventType[] => {
  const from = absence.dateFrom;
  const to = absence.dateTo;
  const days = [];
  for (let day = new Date(from); day <= new Date(to); day.setDate(day.getDate() + 1)) {
    days.push(new Date(day));
  }
  return days.map((day) => ({
    name: absence.absenceType === 'vacation' ? 'Vacation' : 'Sick leave',
    day,
    type: absence.absenceType === 'vacation' ? 'vacation' : 'sick_leave',
  }));
};

export const loadDayEvents = async (selectedWeekDays: Date[], employeeId: number): Promise<DayEventType[]> => {
  return Promise.all([
    api
      .get<ArrayResponseType<HolidayType>>('holidays', {
        params: {
          'date[after]': selectedWeekDays[0],
          'date[before]': selectedWeekDays[1],
        },
      })
      .then((response) => {
        return response.data['hydra:member'].map((el) => ({
          name: el.name,
          day: el.date,
          type: 'holiday',
        }));
      }),
    api
      .get<ArrayResponseType<AbsenceType>>('absence_requests', {
        params: {
          'person.id': employeeId,
          'dateTo[after]': selectedWeekDays[0],
          'dateTo[before]': selectedWeekDays[1],
          absenceType: ['vacation', 'sick_leave'],
          status: 'accepted',
        },
      })
      .then((response) => {
        const events: DayEventType[] = [];
        response.data['hydra:member'].forEach((el) => {
          events.push(...splitAbsence(el));
        });
        return events;
      }),
    api
      .get<ArrayResponseType<AbsenceType>>('absence_requests', {
        params: {
          'person.id': employeeId,
          'dateFrom[after]': selectedWeekDays[0],
          'dateFrom[before]': selectedWeekDays[1],
          absenceType: ['vacation', 'sick_leave'],
          status: 'accepted',
        },
      })
      .then((response) => {
        const events: DayEventType[] = [];
        response.data['hydra:member'].forEach((el) => {
          events.push(...splitAbsence(el));
        });
        return events;
      }),
  ]).then((values) => {
    return values.flat() as DayEventType[];
  });
};
