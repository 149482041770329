import styled, { css } from 'styled-components';
import { color } from '../../../../../../styles/Variables';

type WrapperProps = {
  $kind: 'positive' | 'negative' | 'warning';
  $neutral?: boolean;
};

type ProgressProps = {
  $kind: WrapperProps['$kind'];
  $progress: number;
};

export const Wrapper = styled.div<WrapperProps>`
  width: 100px;
  height: 56px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  ${(props) =>
    props.$kind === 'positive' &&
    css`
      background-color: ${color.semantic.success['60']};
    `};
  ${(props) =>
    props.$kind === 'negative' &&
    css`
      background-color: ${color.semantic.error['60']};
    `};
  ${(props) =>
    props.$kind === 'warning' &&
    css`
      background-color: ${color.semantic.warning['60']};
    `};
  ${(props) =>
    props.$neutral &&
    css`
      background-color: ${color.neutral['30']};
    `};
`;

export const Progress = styled.div<ProgressProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: width 0.3s ease-in-out;
  border-radius: 4px;
  z-index: 1;

  ${(props) =>
    props.$kind === 'positive' &&
    css`
      background-color: ${color.semantic.success['80']};
    `};
  ${(props) =>
    props.$kind === 'negative' &&
    css`
      background-color: ${color.semantic.error['80']};
    `};
  ${(props) =>
    props.$kind === 'warning' &&
    css`
      background-color: ${color.semantic.warning['80']};
    `};

  ${(props) =>
    (props.$progress || props.$progress === 0) &&
    css`
      height: ${props.$progress}%;
    `};

  ${(props) =>
    props.$progress &&
    props.$progress > 100 &&
    css`
      height: 100%;
    `};
`;

export const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
`;
