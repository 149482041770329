import { CellTemplate } from '../../CellTemplate';
import React from 'react';
import Chip from '../../../../atoms/Chips/Chip';
import Tags from '../../../Tags';
import { TagType } from '../../../../../@types/Tag/Tag';
import Text from '../../../../atoms/Text';
import BetterChip, { ChipColor } from '../../../../atoms/Chips/BetterChip';
import EmptyCell from '../EmptyCell';

type Options = {
  color?: ChipColor;
};
const TagsTemplate: CellTemplate<string | TagType[], Options> = ({ value, options }) => {
  if (Array.isArray(value)) {
    return value.length ? <Tags color={options?.color} tags={value} /> : <EmptyCell />;
  } else {
    return value ? <BetterChip color={options?.color}>{value}</BetterChip> : <EmptyCell />;
  }
};
export default TagsTemplate;
