import React, { FunctionComponent, useCallback, useState } from 'react';
import { ChecklistItemType } from '../../../../../../../@types/Checklist/ChecklistType';
import Checkbox from '../../../../../../atoms/Checkbox/Checkbox';
import Text from '../../../../../../atoms/Text';
import * as Styled from './SingleChecklistItem.styled';
import { parseDate } from '../../../../../../../helpers/date';

type Props = {
  item: ChecklistItemType;
  onClick: (item: ChecklistItemType) => void;
};

const SingleChecklistItem: FunctionComponent<Props> = ({ item, onClick }) => {
  const [internalChecked, setInternalChecked] = useState<boolean>(!!item.completedAt);

  const handleClick = useCallback(() => {
    setInternalChecked((v) => !v);
    onClick(item);
  }, []);

  return (
    <Styled.ItemWrapper onClick={handleClick}>
      <Checkbox isChecked={internalChecked} />
      <Text size={'l'}>{item.content}</Text>
      {item.completedBy && (
        <Text color={'grey'} size={'s'}>
          {item.completedBy.name} completed the task on {parseDate(item.completedAt, false, false, false, false, true)}
        </Text>
      )}
    </Styled.ItemWrapper>
  );
};

export default SingleChecklistItem;
