import React, { FunctionComponent } from 'react';
import Person from '../../../../molecules/Person/Person';
import DateString from '../../../../atoms/DateString/DateString';
import TimeProgress from '../../../../molecules/TimeProgress/TimeProgress';
import { Grid } from '../../../../atoms/Grid/Grid';
import TaskDependency from './TaskDependency';
import { TaskType } from '../../../../../@types/Task/TaskType';
import * as Styled from './Styles.styled';
import EditableDetail from '../../../../molecules/EditableDetail/EditableDetail';
import TaskEmployeeDropdown from '../../../../molecules/Task/TaskForm/TaskEmployeeDropdown/TaskEmployeeDropdown';
import TaskDeadlineDropdown from '../../../../molecules/Task/TaskForm/TaskDeadlineDropdown/TaskDeadlineDropdown';
import TaskEstimationDropdown from '../../../../molecules/Task/TaskForm/TaskEstimationDropdown/TaskEstimationDropdown';
import { useEntityContext } from '../../../../../Context/EntityContext/EntityContext';

type Props = {
  task: TaskType;
};

const TaskSidebarDetails: FunctionComponent<Props> = ({ task }) => {
  const { replace } = useEntityContext();
  return (
    <Styled.Details>
      <Styled.Detail>
        <Styled.Label>Assignee:</Styled.Label>
        <EditableDetail<TaskType>
          name={'assignee'}
          subject={task}
          show={task.assignee ? <Person person={task.assignee} size={2.4} /> : <>No assignee</>}
          component={TaskEmployeeDropdown}
          disabled={!!task.deletedAt}
          onUpdate={(task) => replace(task)}
        />
      </Styled.Detail>
      <Styled.Detail>
        <Styled.Label>Owner:</Styled.Label>
        <Person person={task.createdBy} size={2.4} />
      </Styled.Detail>
      <Styled.Detail>
        <Styled.Label>Reviewer:</Styled.Label>
        <EditableDetail<TaskType>
          name={'reviewer'}
          subject={task}
          canValueBeCleared={true}
          show={<>{task.reviewer ? <Person person={task.reviewer} size={2.4} /> : 'No reviewer'}</>}
          component={TaskEmployeeDropdown}
          disabled={!!task.deletedAt}
          onUpdate={(task) => replace(task)}
        />
      </Styled.Detail>
      <Styled.Detail>
        <Styled.Label>Possible start:</Styled.Label>
        <EditableDetail<TaskType>
          name={'expectedStart'}
          subject={task}
          show={<>{task.expectedStart ? <DateString input={task.expectedStart} /> : 'not specified'}</>}
          component={TaskDeadlineDropdown}
          disabled={!!task.deletedAt}
          onUpdate={(task) => replace(task)}
        />
      </Styled.Detail>
      <Styled.Detail>
        <Styled.Label>Time estimate:</Styled.Label>
        <EditableDetail<TaskType>
          name={'estimate'}
          subject={task}
          show={<TimeProgress unit={'minutes'} current={task.spentTime} estimated={Number(task.estimate)} width={20} />}
          component={TaskEstimationDropdown}
          disabled={!!task.deletedAt}
          onUpdate={(task) => replace(task)}
        />
      </Styled.Detail>
      <Styled.Detail>
        <Styled.Label>Due date:</Styled.Label>
        <EditableDetail<TaskType>
          name={'deadline'}
          subject={task}
          show={<>{task.deadline ? <DateString input={task.deadline} /> : 'No due date'}</>}
          component={TaskDeadlineDropdown}
          disabled={!!task.deletedAt}
          onUpdate={(task) => replace(task)}
        />
      </Styled.Detail>
      {(!task.previousTasks || task.previousTasks.length === 0) && (!task.nextTasks || task.nextTasks.length === 0) ? null : (
        <Styled.Detail full>
          <Styled.Label>Dependencies:</Styled.Label>
          <Grid spacing={2} $simple>
            {task.previousTasks && task.previousTasks.length > 0
              ? task.previousTasks.map((d, idx) => <TaskDependency key={idx} title={d.title} status={'previous'} taskId={d.id} />)
              : null}
            {task.nextTasks && task.nextTasks.length > 0
              ? task.nextTasks.map((d, idx) => <TaskDependency key={idx} title={d.title} status={'next'} taskId={d.id} />)
              : null}
          </Grid>
        </Styled.Detail>
      )}
    </Styled.Details>
  );
};

export default TaskSidebarDetails;
