import React, { FunctionComponent } from 'react';
import { ModuleType } from '../../../../../@types/Module/Module';
import * as Components from './HeaderSubmenu.styled';
import HeaderSubmenuLink from './HeaderSubmenuLink';

interface OwnProps {
  module: ModuleType;
}

type Props = OwnProps;

export const HeaderSubmenu: FunctionComponent<Props> = ({ module }) => {
  const linkRefs = React.useRef<Array<HTMLDivElement | null>>([]);
  React.useEffect(() => {
    linkRefs.current = linkRefs.current.slice(0, module.submenu?.length);
  }, [module]);
  return (
    <Components.Container>
      {module.submenu
        ?.filter((s) => s.visible === undefined || !!s.visible)
        .map((link, idx) => <HeaderSubmenuLink base={module.to} link={link} key={link.url} innerRef={(ref) => (linkRefs.current[idx] = ref)} />)}
    </Components.Container>
  );
};
