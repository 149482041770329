import React, { FunctionComponent } from 'react';

type Props = {};

const SpeakerIcon: FunctionComponent<Props> = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.9679 10.625V9.37503H17.9807V10.625H14.9679ZM15.9199 16.2339L13.5096 14.4263L14.2692 13.4295L16.6794 15.2372L15.9199 16.2339ZM14.2371 6.53847L13.4776 5.5417L15.8878 3.73401L16.6474 4.73078L14.2371 6.53847ZM4.375 15.4006V12.1474H3.52562C3.11004 12.1474 2.75507 12.0003 2.46073 11.7059C2.16639 11.4116 2.01923 11.0566 2.01923 10.641V9.35899C2.01923 8.9434 2.16639 8.58844 2.46073 8.29409C2.75507 7.99976 3.11004 7.85259 3.52562 7.85259H6.74677L10.4807 5.62505V14.375L6.74677 12.1474H5.62495V15.4006H4.375ZM11.6346 12.5994V7.40066C11.9615 7.6966 12.2249 8.06946 12.4246 8.51924C12.6244 8.96903 12.7243 9.46262 12.7243 10C12.7243 10.5374 12.6244 11.031 12.4246 11.4808C12.2249 11.9306 11.9615 12.3034 11.6346 12.5994ZM3.52562 9.10257C3.46152 9.10257 3.40276 9.12928 3.34933 9.1827C3.29591 9.23613 3.26921 9.29489 3.26921 9.35899V10.641C3.26921 10.7051 3.29591 10.7639 3.34933 10.8173C3.40276 10.8707 3.46152 10.8974 3.52562 10.8974H7.09291L9.23075 12.1539V7.84615L7.09291 9.10257H3.52562Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SpeakerIcon;
