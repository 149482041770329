import React, { FunctionComponent } from 'react';
import Avatar from '../../../../atoms/Avatar/Avatar';
import Text from '../../../../atoms/Text/Text';
import * as Styled from './Items.styled';
import { fontWeight } from '../../../../../styles/Variables';
import { parseDate } from '../../../../../helpers/date';
import { useNavigate } from 'react-router-dom';
import { DashboardDataAbsenceType } from '../../../../../@types/Dashboard/DashboardDataAbsenceType';

type Props = {
  absence: DashboardDataAbsenceType;
};

const AbsencesCardItem: FunctionComponent<Props> = ({ absence }) => {
  const navigate = useNavigate();

  return (
    <Styled.CardItemWrapper onClick={() => navigate('/projects/absences/my-absences')} $cursor={'default'}>
      <Avatar name={absence.employee.name} photoUrl={absence.employee.profilePhotoUrl} size={3} />
      <Styled.NameWrapper>
        <Text size={'l'} bold>
          {absence.employee.name}
        </Text>
        <Styled.InlineWrapper>
          {absence.type === 'vacation' && (
            <>
              <Text size={'s'} color={'semiGrey'} style={{ fontWeight: fontWeight.medium }}>
                Vacation
              </Text>
            </>
          )}
          {absence.type === 'sick_leave' && (
            <>
              <Text size={'s'} color={'semiGrey'} style={{ fontWeight: fontWeight.medium }}>
                Sick leave
              </Text>
            </>
          )}
        </Styled.InlineWrapper>
        <Styled.InlineWrapper>
          {absence.days === 1 ? (
            <Styled.InlineWrapper>
              <Text color={'grey'} size={'xs'} style={{ fontWeight: fontWeight.medium }}>
                {parseDate(absence.dateFrom, false, false, true)}
              </Text>
              <Text color={'grey'} size={'xs'} style={{ fontWeight: fontWeight.medium }}>
                ({absence.days} day)
              </Text>
            </Styled.InlineWrapper>
          ) : (
            <Styled.InlineWrapper>
              <Text color={'grey'} size={'xs'} style={{ fontWeight: fontWeight.medium }}>
                {parseDate(absence.dateFrom, false, false, true)} - {parseDate(absence.dateTo, false, false, true)}
              </Text>
              <Text color={'grey'} size={'xs'} style={{ fontWeight: fontWeight.medium }}>
                ({absence.days} days)
              </Text>
            </Styled.InlineWrapper>
          )}
        </Styled.InlineWrapper>
      </Styled.NameWrapper>
    </Styled.CardItemWrapper>
  );
};

export default AbsencesCardItem;
