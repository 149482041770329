import styled, { css } from 'styled-components';
import { color, fontSize, fontWeight } from '../../../../styles/Variables';
import { BodyText } from '../../../../styles/Typography';

export const Container = styled.div<{ $isNew?: boolean; $canEdit?: boolean }>`
  position: relative;
  border: 1px solid ${color.neutral[50]};
  border-radius: 0.8rem;
  color: ${color.primary[60]};
  display: grid;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  grid-template-columns: 4rem 1fr 2rem;
  gap: 1rem;
  transition: background-color 0.2s ease-in-out;
  height: 6.4rem;
  width: 25rem;
  cursor: pointer;
  ${({ $isNew }) =>
    $isNew &&
    css`
      box-shadow: 0 0 0.4rem ${color.primary[40]};
    `}
  ${({ $canEdit }) =>
    !$canEdit &&
    css`
      grid-template-columns: 4rem 1fr;
    `}
`;

export const Info = styled.a`
  width: 4rem;
  height: 4rem;
`;

export const Content = styled.div`
  display: grid;
  align-items: center;

  a,
  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    transition: color 0.2s ease-in-out;
  }

  &:hover {
    p {
      color: ${color.primary[60]};
    }
  }
`;

export const Menu = styled.div`
  margin-left: auto;
  height: 2rem;
  width: 2rem;
  border-radius: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Name = styled.p`
  ${BodyText};
  width: 100%;
  font-weight: ${fontWeight.bold};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Details = styled.div`
  ${BodyText};
  width: 100%;
  transform: translateY(-3px);
  font-size: ${fontSize.body.xs};
  color: ${color.neutral[60]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 0.6rem;
`;

export const Action = styled.span`
  cursor: pointer;
  transition: 0.2s ease-in-out;
  padding: 0 0.5rem;
  border-right: 1px solid ${color.neutral[60]};

  &:hover {
    color: ${color.primary[60]};
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    border-right: none;
  }
`;

export const DeleteAction = styled(Action)`
  :hover {
    color: ${color.semantic.error[100]};
  }
`;
