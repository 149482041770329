import React, { FunctionComponent, useCallback, useState } from 'react';
import Heading from '../../atoms/Heading';
import { PaymentType } from '../../../@types/Finances/PaymentType';
import { Grid, GridItem } from '../../atoms/Grid/Grid';
import SingleDetail from '../../molecules/SingleDetail/SingleDetail';
import { parseDate } from '../../../helpers/date';
import { PaymentDict } from '../../../@dicts/Payment/PaymentDict';
import { Icon } from '../../atoms/Icon/Icon';
import EditIcon from '../../../Icons/edit.icon';
import moneyAsString from '../../../helpers/moneyAsString';
import TrashIcon from '../../../Icons/Trash.icon';
import { color } from '../../../styles/Variables';
import useApi from '../../../hooks/useApi';
import DeleteModal from '../../molecules/DeleteModal/DeleteModal';
import { useEntityContext } from '../../../Context/EntityContext/EntityContext';
import Form from '../../molecules/Form/Form';
import Button from '../../atoms/Button/Button';
import SinglePaymentCardForm from './SinglePaymentCard/SinglePaymentCardForm';
import { MoneyType } from '../../../@types/Money/MoneyType';

type Props = {
  payment: PaymentType;
  idx: number;
  onValueChange: (value: number) => void;
  remain: MoneyType;
};

const SinglePaymentCard: FunctionComponent<Props> = ({ payment, idx, onValueChange, remain }) => {
  const { reload } = useEntityContext();
  const [isEdit, setEdit] = useState(false);
  const [isDelete, setDelete] = useState<number>();
  const { del, put } = useApi();

  const handleEdit = useCallback(
    (data: PaymentType) => {
      put(payment['@id'], data).then(() => {
        setEdit(false);
        reload();
      });
    },
    [payment],
  );

  const handleDelete = useCallback(() => {
    return del(payment['@id']).then(reload);
  }, [payment]);

  return (
    <GridItem $desktop={12}>
      <Grid $align={'center'} style={{ marginBottom: '1.8rem' }}>
        <GridItem>
          <Heading size={'s'}>Payment no. {idx}</Heading>
        </GridItem>
        {!isEdit && (
          <>
            <GridItem style={{ marginLeft: 'auto' }}>
              <Icon size={1.2} onClick={() => setEdit((v) => !v)}>
                <EditIcon />
              </Icon>
            </GridItem>
            <GridItem style={{ marginLeft: '1rem' }}>
              <Icon size={1.2} color={color.semantic.error[80]} onClick={() => setDelete(Math.random())}>
                <TrashIcon />
              </Icon>
            </GridItem>
          </>
        )}
      </Grid>
      <Grid spacing={3}>
        {!isEdit && (
          <>
            <SingleDetail title={'Payment value'} $desktop={4} type={'money'}>
              {moneyAsString(payment.value)}
            </SingleDetail>

            <SingleDetail title={'Payment type'} $desktop={4} dictionary={PaymentDict}>
              {payment.type}
            </SingleDetail>
            <SingleDetail title={'Payment date'} $desktop={4} type={'date'}>
              {parseDate(payment.paidAt)}
            </SingleDetail>
          </>
        )}
        {isEdit && (
          <GridItem $desktop={12}>
            <Form onSubmit={handleEdit} defaultValues={payment}>
              <Grid spacing={3}>
                <SinglePaymentCardForm onValueChange={onValueChange} payment={payment} remain={remain} onCancel={() => setEdit(false)} />
              </Grid>
            </Form>
          </GridItem>
        )}
      </Grid>
      <DeleteModal show={isDelete} onConfirm={handleDelete} title={'Delete this payment?'} />
    </GridItem>
  );
};

export default SinglePaymentCard;
