import React, { FunctionComponent } from 'react';
import { color } from '../styles/Variables';

type PropType = {
  size?: number;
};

const ChurchIcon: FunctionComponent<PropType> = ({ size = 1 }) => {
  return (
    <svg width={`${size}rem`} height={`${size}rem`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 22V14L6 12.225V9L11 6.5V5H9V3H11V1H13V3H15V5H13V6.5L18 9V12.225L22 14V22H13V18C13 17.7167 12.9042 17.4792 12.7125 17.2875C12.5208 17.0958 12.2833 17 12 17C11.7167 17 11.4792 17.0958 11.2875 17.2875C11.0958 17.4792 11 17.7167 11 18V22H2ZM4 20H9V17.95C9 17.1 9.29167 16.3792 9.875 15.7875C10.4583 15.1958 11.1667 14.9 12 14.9C12.8333 14.9 13.5417 15.1958 14.125 15.7875C14.7083 16.3792 15 17.1 15 17.95V20H20V15.2L16 13.4V10.05L12 8L8 10.05V13.4L4 15.2V20ZM12 13.5C12.4167 13.5 12.7708 13.3542 13.0625 13.0625C13.3542 12.7708 13.5 12.4167 13.5 12C13.5 11.5833 13.3542 11.2292 13.0625 10.9375C12.7708 10.6458 12.4167 10.5 12 10.5C11.5833 10.5 11.2292 10.6458 10.9375 10.9375C10.6458 11.2292 10.5 11.5833 10.5 12C10.5 12.4167 10.6458 12.7708 10.9375 13.0625C11.2292 13.3542 11.5833 13.5 12 13.5Z"
        fill={'currentColor'}
      />
    </svg>
  );
};

export default ChurchIcon;
