import { Wrapper } from 'components/atoms/Wrapper/Wrapper';
import styled from 'styled-components';
import { color } from 'styles/Variables';
import Container from '../../atoms/Container/Container';

export const Navigation = styled.div`
  background: ${color.neutral[10]};
  min-height: 100%;
`;

export const Tabs = styled.div`
  width: 100%;
  background: ${color.neutral[10]};
  border-bottom: 1px solid ${color.neutral[40]};
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-right: 6rem;
`;

export const TabWrapper = styled(Wrapper)`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 3.2rem;
  padding-top: 1.6rem;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

export const ScreenContainer = Container;
