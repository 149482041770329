import React, { FunctionComponent } from 'react';

interface OwnProps {}

type Props = OwnProps;

const UploadIcon: FunctionComponent<Props> = (props) => {
  return (
    <svg viewBox="0 0 48 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <svg viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          stroke="currentColor"
          d="M33.3337 33.3333L25.0003 25L16.667 33.3333"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{ fill: 'none' }}
        />
        <path stroke="currentColor" d="M25 25V43.75" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ fill: 'none' }} />
        <path
          stroke="currentColor"
          d="M42.4792 38.3125C44.5112 37.2047 46.1164 35.4518 47.0414 33.3305C47.9665 31.2091 48.1588 28.8401 47.588 26.5973C47.0171 24.3545 45.7156 22.3657 43.8889 20.9447C42.0622 19.5237 39.8143 18.7515 37.5 18.75H34.875C34.2444 16.3109 33.0691 14.0465 31.4374 12.1271C29.8057 10.2076 27.7601 8.68303 25.4544 7.66794C23.1487 6.65285 20.6429 6.17367 18.1253 6.26643C15.6077 6.35919 13.1439 7.02147 10.9192 8.20348C8.69439 9.38549 6.76652 11.0565 5.2805 13.0908C3.79448 15.1251 2.78898 17.4699 2.33958 19.9487C1.89018 22.4276 2.00859 24.9761 2.6859 27.4026C3.36321 29.8292 4.58179 32.0706 6.25004 33.9583"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{ fill: 'none' }}
        />
        <path
          stroke="currentColor"
          d="M33.3337 33.3333L25.0003 25L16.667 33.3333"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{ fill: 'none' }}
        />
      </svg>
    </svg>
  );
};

export default UploadIcon;
