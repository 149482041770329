import { TableFiltersType } from '../../../../@types/Table/TableFilterType';
import { CurrencyTypeDict } from '../../../../@dicts/CurrencyTypeDict';
import FrequentFilters from '../../../../@types/Table/FrequentFilters';
import DateSelectFilter from '../../../molecules/TableFilters/DateSelectFilter/DateSelectFilter';

const incomeTableFilters: TableFiltersType = [
  {
    ...FrequentFilters._search,
    gridProps: {
      $desktop: 3,
    },
  },
  {
    ...FrequentFilters.contractor,
    gridProps: {
      $desktop: 3,
    },
  },
  {
    ...FrequentFilters.partner,
    name: 'subincomes.project.client.id',
    gridProps: {
      $desktop: 3,
    },
  },
  {
    ...FrequentFilters.project,
    name: 'subincomes.project.id',
    gridProps: {
      $desktop: 3,
    },
  },
  {
    type: 'select',
    name: 'financialCompany.id',
    label: 'Financial company',
    options: {
      baseUrl: '/api/financial_companies',
    },
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'select',
    name: 'kind',
    label: 'Kind',
    placeholder: 'Kind',
    options: {
      options: [
        { value: 'management', label: 'Management' },
        { value: 'project', label: 'Project' },
      ],
    },
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'date',
    name: 'paymentDue[before]',
    label: 'Payment date (before)',
    placeholder: 'Payment date',
    options: {
      before: true,
    },
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'select',
    name: 'originalAmountGross.currency',
    label: 'Currency',
    options: {
      options: CurrencyTypeDict,
    },
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'money',
    name: 'amountGross.value[gte]',
    label: 'Gross from',
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'money',
    name: 'amountGross.value[lte]',
    label: 'Gross to',
    gridProps: {
      $desktop: 2,
    },
  },
  {
    name: 'postedAt',
    type: 'custom',
    options: {
      component: DateSelectFilter,
    },
    label: 'Posting date',
    gridProps: {
      $desktop: 4,
    },
  },
  {
    type: 'money',
    name: 'liabilitiesPln.value[gte]',
    label: 'Liabilities from',
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'money',
    name: 'liabilitiesPln.value[lte]',
    label: 'Liabilities to',
    gridProps: {
      $desktop: 2,
    },
  },
];
export default incomeTableFilters;
