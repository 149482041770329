import { TableFiltersType } from '../../../../@types/Table/TableFilterType';
import { DictValue } from '../../../../@types/Dictionary/DictValue';
import { FinanceStatusType } from '../../../../@types/Finances/FinanceStatusType';

const statusOptions: DictValue<null, FinanceStatusType>[] = [
  { value: 'draft', label: 'Draft' },
  { value: 'pending', label: 'Pending' },
  { value: 'accepted', label: 'Accepted' },
  { value: 'rejected', label: 'Rejected' },
];

const filters: TableFiltersType = [
  {
    type: 'text',
    name: '_search',
    label: 'Search',
    placeholder: 'Search by vendor name or NIP',
    gridProps: {
      $desktop: 3,
    },
  },
  {
    type: 'text',
    name: 'id',
    label: 'ID',
    placeholder: 'Search by ID',
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'text',
    name: 'address.city',
    label: 'City',
    placeholder: 'Search by city',
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'select',
    name: 'client.id',
    label: 'Partner',
    placeholder: 'Search by partner',
    options: {
      baseUrl: '/api/clients',
    },
    gridProps: {
      $desktop: 3,
    },
  },
  {
    type: 'select',
    name: 'status',
    label: 'Status',
    options: {
      options: statusOptions,
    },
    gridProps: {
      $desktop: 2,
    },
  },
];

export default filters;
