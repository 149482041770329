import React, { FunctionComponent } from 'react';
import api from '../../../../../services/api';
import Button from '../../../../atoms/Button/Button';
import SnackContainer from '../../../../../legacy/CapeMorris/components/snack-bar/use-snack-container';
import InventoryItemType from '../../../../../@types/Inventory/InventoryItemType';

type Props = {
  entity: InventoryItemType;
};

const DeleteButton: FunctionComponent<Props> = ({ entity }) => {
  const { showSnack } = SnackContainer.useContainer();

  return (
    <Button
      $text={'Delete'}
      onClick={() => {
        return api
          .delete(`${entity['@id']}`)
          .then((r) => {
            showSnack({
              title: 'Success',
              message: 'Item deleted',
              type: 'success',
            });
          })
          .catch(() => {
            showSnack({
              title: 'Error',
              message: 'Cannot delete item',
              type: 'error',
            });
          });
      }}
    />
  );
};

export default DeleteButton;
