import api from '../../../services/api';

export default class UploadAdapter {
  loader: any;
  file: any;

  constructor(loader: any) {
    this.loader = loader;
    this.loader.file.then((pic: any) => (this.file = pic));
  }

  // Starts the upload process.
  upload() {
    const fd = new FormData();
    fd.append('file', this.file); // your image
    fd.append('context', 'project');
    // ...

    return new Promise((resolve, reject) => {
      api
        .post<{ urls: string[] }>('/files', fd, {
          onUploadProgress: (e) => {},
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject('Server Error');
          console.log('Server Error : ', error);
        });
    });
  }
}
