import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

interface OwnProps {
  open?: boolean;
}

type Props = OwnProps;

const StyledSvg = styled.svg<Props>`
  rect {
    transition: width 0.2s ease-in-out;
    width: 26px;
  }
  rect.x {
    width: 0;
  }
  ${(props) =>
    props.open &&
    css`
      rect.x {
        width: 26px;
      }
      rect:not(.x) {
        width: 0;
      }
    `}
`;

const BurgerIcon: FunctionComponent<Props> = ({ open = false }) => {
  return (
    <StyledSvg open={open} width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="4" width="26" height="2" fill="currentColor" />
      <rect y="9" width="26" height="2" fill="currentColor" />
      <rect y="14" width="26" height="2" fill="currentColor" />
      <rect className={'x'} x="3" y="18.3848" width="26" height="2" transform="rotate(-45 3 18.3848)" fill="currentColor" />
      <rect className={'x'} x="4.51562" y="0.100586" width="26" height="2" transform="rotate(45 4.51562 0.100586)" fill="currentColor" />
    </StyledSvg>
  );
};

export default BurgerIcon;
