import React, { FunctionComponent, useContext } from 'react';
import * as Styled from './ProjectsHomeHeader.styled';
import Text from '../../../../../../components/atoms/Text';
import { AuthContext } from '../../../../../../Context/auth-context/auth-context';
import Link from '../../../../../../components/atoms/Link/Link';
import { DashboardData } from '../../../../../../@types/Dashboard/DashboardDataType';

type Props = {
  data?: DashboardData;
};

const ProjectsHomeHeader: FunctionComponent<Props> = ({ data }) => {
  const {
    state: { user },
  } = useContext(AuthContext);

  const todaysDate = new Date().toLocaleDateString('en-us', {
    month: 'long',
    day: 'numeric',
    weekday: 'long',
  });

  return (
    <Styled.ProjectsHomeHeaderStyled>
      <Text color={'grey'} size={'l'} bold>
        {todaysDate}
      </Text>
      <Styled.HomeHeader>Nice to see you again, {user && user.firstName}</Styled.HomeHeader>
      {data && (
        <Text size={'l'} color={'grey'}>
          You have{' '}
          <Link href={'/projects/my-work/my-tasks?overdue'}>
            <Styled.Bold>{data.overdueTasks} overdue tasks</Styled.Bold>
          </Link>{' '}
          and{' '}
          <Link href={'/projects/timesheets'}>
            <Styled.Bold>{data.openTimesheets} open timesheets</Styled.Bold>
          </Link>
        </Text>
      )}
    </Styled.ProjectsHomeHeaderStyled>
  );
};

export default ProjectsHomeHeader;
