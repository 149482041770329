import React, { FunctionComponent, memo, useContext } from 'react';
import Text from '../../../../atoms/Text/Text';
import { AuthContext } from '../../../../../Context/auth-context/auth-context';
import Button from '../../../../atoms/Button/Button';
import useOverlay from '../../../../../hooks/useOverlay';
import AbsenceRequestOverlayBody from '../RequestOverlayBody/AbsenceRequestOverlayBody';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';

type Props = {};

const Actions: FunctionComponent<Props> = (props) => {
  const {
    state: { user },
  } = useContext(AuthContext);

  const { showOverlay } = useOverlay();

  return (
    <Grid $align={'center'} spacing={3} $justifyContent={'end'}>
      <GridItem>
        <Grid spacing={0.4}>
          <GridItem>
            <Text bold={true}>Days left:</Text>
          </GridItem>
          <GridItem>
            <Text color={'primary'} bold={true}>
              {user && user.vacationDays}
            </Text>
          </GridItem>
        </Grid>
      </GridItem>
      <GridItem>
        <Button
          $text={'New request'}
          kind={'secondary'}
          onClick={() => {
            showOverlay({ body: <AbsenceRequestOverlayBody /> });
          }}
        />
      </GridItem>
    </Grid>
  );
};

export default memo(Actions);
