import styled from 'styled-components';
import { color } from '../../../styles/Variables';

export const BackButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
  width: 3.4rem;
  height: 3.4rem;
  border-radius: 0.4rem;
  color: ${color.light};
  background: ${color.neutral[80]};
  border: 1px solid ${color.neutral[80]};
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  &:hover {
    background-color: ${color.neutral[20]};
    border: 1px solid ${color.neutral[80]};
    color: ${color.neutral[80]};
  }
`;
