import styled from 'styled-components';
import { color } from '../../../styles/Variables';

export const OverlayContainer = styled.div`
  z-index: 1000;
  position: fixed;
  top: 60px;
  height: calc(100vh - 60px);
  width: 100vw;
  background-color: ${color.neutral[10]};
  display: flex;
  justify-content: center;
`;

export const OverlayBody = styled.div`
  max-width: 500px;
  height: max-content;
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 4rem;
  right: 4rem;
`;

export const OverlayWrapper = styled.div`
  padding: 9rem 0;
  max-height: calc(100vh - 60px);
  overflow-y: auto;
  overflow-x: hidden;
  width: 100vw;
  display: flex;
  justify-content: center;
`;
