import React, { FunctionComponent, useMemo } from 'react';
import { NavigationNodeType } from '../../../../../../@types/NavigationTree/NavigationTreeType';
import useEntity from '../../../../../../hooks/useEntity';
import Category from '../../../../../organisms/Projects/Wiki/Category/Category';
import Article from '../../../../../organisms/Projects/Wiki/Article/Article';
import { CategoryType } from '../../../../../../@types/Category/CategoryType';
import { ArticleType } from '../../../../../../@types/Article/ArticleType';
import { Loader } from '../../../../../../legacy/CapeMorris/components';
import { ContentWrapper } from '../../../../../atoms/Projects/Wiki/ListItem';
import CategoryListItem from '../../../../../molecules/Projects/Wiki/CategoryListItem/CategoryListItem';
import FavoritesCategory from '../../../../../organisms/Projects/Wiki/FavoritesCategory/FavoritesCategory';

type Props = {
  slugs: string[];
  tree: NavigationNodeType[];
  treeReload?: () => void;
};

const WikiContent: FunctionComponent<Props> = ({ slugs, tree, treeReload }) => {
  const currentNode = useMemo(() => {
    let result: NavigationNodeType = {
      children: tree,
      type: 'category',
      name: '',
      slug: '',
      fullPath: '/projects/wiki/',
      '@id': '',
    };

    slugs.forEach((slug) => {
      if (!result.children) {
        throw Error('Something went wrong in WikiContent');
      }
      let nextNode = result.children.find((c) => c.slug === slug);
      if (nextNode) {
        result = nextNode;
      } else {
        throw Error('Something went wrong in WikiContent');
      }
    });
    return result;
  }, [tree, slugs]);

  const { entity, loading, overwrite, reload } = useEntity<CategoryType | ArticleType>(currentNode['@id']);
  return (
    <div style={{ position: 'relative' }}>
      {slugs.length === 0 && (
        <ContentWrapper>
          {tree.map((node) => (
            <CategoryListItem node={node} key={node['@id']} />
          ))}
        </ContentWrapper>
      )}
      {loading && <Loader />}
      {currentNode.slug === 'favorites' && <FavoritesCategory node={currentNode} />}
      {entity && entity['@id'].startsWith('/api/wiki/wiki_categories') && <Category category={entity as CategoryType} onTreeReload={treeReload} />}
      {entity && entity['@id'].startsWith('/api/wiki/articles') && (
        <Article onChange={overwrite} article={entity as ArticleType} onReload={reload} onTreeReload={treeReload} />
      )}
    </div>
  );
};

export default WikiContent;
