import React, { FunctionComponent } from 'react';
import * as Styled from './TableOfContents.styled';
import Button from '../../../../../atoms/Button/Button';
import { HorizontalLine } from '../../../../../atoms/HorizontalLine/HorizontalLine';

export type HeadingType = {
  id: string;
  text: string | null;
  tag: string;
};

type Props = {
  headings: HeadingType[];
};

const TableOfContents: FunctionComponent<Props> = ({ headings }) => {
  const handleClick = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <Styled.TableOfContentsContainer>
      {headings.map((heading) => (
        <Styled.TableOfContentsItem key={heading.id} $level={heading.tag}>
          <Button $text={heading.text as string} kind={'link'} onClick={() => handleClick(heading.id)} />
        </Styled.TableOfContentsItem>
      ))}
      <HorizontalLine style={{ marginTop: 0 }} />
    </Styled.TableOfContentsContainer>
  );
};

export default TableOfContents;
