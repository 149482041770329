import { TextSizeType } from '../../../@types/Text/TextSizeType';
import styled, { css } from 'styled-components';
import { color, fontWeight, fontSize } from 'styles/Variables';
import { ColorType } from '../../../@types/Color/ColorType';

export type TextType = {
  $size?: TextSizeType | undefined;
  $color?: ColorType;
  $bold?: boolean;
  $align?: 'left' | 'center' | 'right';
};

const parseColor = (colorStr: ColorType | string) => {
  switch (colorStr) {
    case 'primary':
      return color.primary[60];
    case 'dark':
      return color.dark;
    case 'light':
      return color.light;
    case 'lightGrey':
      return color.neutral[40];
    case 'grey':
      return color.neutral[60];
    case 'semiGrey':
      return color.neutral[70];
    case 'darkGrey':
      return color.neutral[80];
    case 'success':
      return color.secondary[70];
    case 'error':
      return color.semantic.error[100];
    case 'neutral50':
      return color.neutral[50];
    case 'neutral60':
      return color.neutral[60];
    case 'neutral70':
      return color.neutral[70];
    case 'neutral80':
      return color.neutral[80];
    case 'warning':
      return color.semantic.warning[80];
    case 'white':
      return color.white;
    default:
      return colorStr;
  }
};

export const Text = css<TextType>`
  font-weight: ${fontWeight.regular};
  transition: color 0.2s ease-in-out;
  color: ${(p) => (p.$color ? parseColor(p.$color) : 'inherit')};
  ${(p) =>
    p.$size &&
    css`
      font-size: ${fontSize.body[p.$size]};
    `}
  ${(p) =>
    p.$bold &&
    css`
      font-weight: ${fontWeight.bold};
    `}
  ${(p) =>
    p.$align &&
    css`
      text-align: ${p.$align};
    `}
`;

export const Paragraph = styled.div<TextType>`
  ${Text};
`;
