import Heading from 'components/atoms/Heading';
import { Wrapper } from 'components/atoms/Wrapper/Wrapper';
import styled from 'styled-components';
import { color } from 'styles/Variables';

export const Container = styled(Wrapper)`
  padding: 2.4rem 0 0.9rem 0;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
export const HeaderActions = styled.div`
  display: flex;
  gap: 0.8rem;
  height: 30%;
  align-items: center;
`;

export const Id = styled.div`
  color: ${color.primary[60]};
  cursor: pointer;
  margin-right: 1rem;
`;

export const Hash = styled.small`
  font-size: smaller;
`;

export const Title = styled(Heading)`
  display: flex;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;
`;

export const Details = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;
