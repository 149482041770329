import React from 'react';
import { FinancialCompanyType } from '../../@types/FinancialCompany/FinancialCompanyType';

export type BaseAction = {
  type: 'SAVE_COMPANY';
  financialCompany: FinancialCompanyType;
};

export type FinancialCompanyStateType = {
  financialCompany: FinancialCompanyType | null;
};

export type FinancialCompanyContextType = {
  state: FinancialCompanyStateType;
  setFinancialCompany: (financialCompany: FinancialCompanyType) => void;
};

export const FinancialCompanyContext = React.createContext<FinancialCompanyContextType>({
  state: { financialCompany: null },
  setFinancialCompany: (branch) => {},
});
