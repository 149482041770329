import React, { FunctionComponent } from 'react';

type PropType = {
  size?: number;
  color?: string;
};

const CompleteIcon: FunctionComponent<PropType> = ({ size = 1, color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g>
        <path
          d="M4.46752 11.7692L0.931641 8.23332L1.64444 7.53077L4.47777 10.3641L4.70857 10.1333L5.41111 10.8359L4.46752 11.7692ZM8.23419 11.7692L4.69831 8.23332L5.40086 7.52052L8.23419 10.3539L14.3675 4.22052L15.0701 4.93332L8.23419 11.7692ZM8.00341 8.23332L7.29061 7.53077L10.5906 4.23077L11.3034 4.93332L8.00341 8.23332Z"
          fill={color ? color : 'currentColor'}
        />
      </g>
    </svg>
  );
};

export default CompleteIcon;
