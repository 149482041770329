import React, { FunctionComponent } from 'react';
import { color } from '../styles/Variables';

type Props = {
  size?: number;
  iconColor?: string;
};

const KeyboardKeysIcon: FunctionComponent<Props> = ({ size = 1.4, iconColor = color.neutral['100'] }) => {
  return (
    <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_2652_317" maskUnits="userSpaceOnUse" x="0" y="0" width={`${size}em`} height={`${size}em`}>
        <rect width="16" height="16" fill={iconColor} />
      </mask>
      <path
        d="M4.66667 11.3333V9.99996H11.3333V11.3333H4.66667ZM2 8.66663V7.33329H3.33333V8.66663H2ZM4.66667 8.66663V7.33329H6V8.66663H4.66667ZM7.33333 8.66663V7.33329H8.66667V8.66663H7.33333ZM10 8.66663V7.33329H11.3333V8.66663H10ZM12.6667 8.66663V7.33329H14V8.66663H12.6667ZM2 5.99996V4.66663H3.33333V5.99996H2ZM4.66667 5.99996V4.66663H6V5.99996H4.66667ZM7.33333 5.99996V4.66663H8.66667V5.99996H7.33333ZM10 5.99996V4.66663H11.3333V5.99996H10ZM12.6667 5.99996V4.66663H14V5.99996H12.6667Z"
        fill={iconColor}
      />
    </svg>
  );
};

export default KeyboardKeysIcon;
