import styled from 'styled-components';
import { Column } from './Common';
import { color, fontSize } from '../../../../../styles/Variables';

export const Backlog = styled(Column)`
  padding-top: 0.3rem;
  display: grid;
  grid-auto-rows: 11rem;
`;

export const Divider = styled.div`
  display: flex;
  align-items: center;
  color: ${color.neutral[50]};
  font-size: ${fontSize.body.s};
  margin: 0.4rem;
`;

export const DividerLine = styled.div`
  width: 100%;
  height: 0;
  margin-left: 0.4rem;
  padding-top: 2px;
  border-bottom: 1px solid ${color.neutral[50]};
`;
