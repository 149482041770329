import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { ContractorType } from '../../../../@types/Finances/Contractor/ContractorType';
import ContractorShow from './ContractorShow';
import { Loader } from '../../../../legacy/CapeMorris/components';
import EntityProvider from '../../../../Context/EntityContext/EntityProvider';

type Props = {};

const ContractorShowView: FunctionComponent<Props> = () => {
  let { id } = useParams<{ id: string }>();

  return (
    <EntityProvider<ContractorType> url={`/api/contractors/${id}`}>
      {({ loading, entity }) => (
        <>
          {loading && <Loader />}
          {entity && <ContractorShow contractor={entity} />}
        </>
      )}
    </EntityProvider>
  );
};

export default ContractorShowView;
