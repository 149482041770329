import { components, OptionProps } from 'react-select';
import { ProjectType } from '../../../../@types/Project/Project';
import React, { FunctionComponent } from 'react';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import Text from '../../../atoms/Text';
import { fontWeight } from '../../../../styles/Variables';

type Props = OptionProps<{ value: string; label: string; meta: ProjectType }, false>;
const ProjectOption: FunctionComponent<Props> = (props) => {
  return (
    <components.Option {...props}>
      <Grid style={{ fontWeight: fontWeight.regular }}>
        <GridItem $desktop={12}>
          <Text as={'span'} color={'primary'}>
            #{props.data.meta.id}&nbsp;
          </Text>{' '}
          {props.data.meta.name}
        </GridItem>
        <GridItem $desktop={12}>
          {props.data.meta.category.name}{' '}
          <Text as={'span'} color={'primary'}>
            &nbsp;|&nbsp;
          </Text>{' '}
          {props.data.meta.client.name}
        </GridItem>
      </Grid>
    </components.Option>
  );
};
export default ProjectOption;
