import React from 'react';
import * as Styled from './Alert.styled';
import Heading from '../../atoms/Heading';
import Text from '../../atoms/Text';

export type kindType = 'success' | 'warning' | 'error';

type AlertProps = {
  text: JSX.Element | string;
  title?: string;
  icon?: JSX.Element;
  kind: kindType;
  button?: JSX.Element;
};

const Alert: React.FC<AlertProps> = ({ text, title, icon, kind, button }) => {
  return (
    <Styled.Alert $kind={kind}>
      <Styled.ContentWrapper style={{ display: 'flex', alignItems: 'center' }}>
        {icon}
        <Styled.TextWrapper>
          {title && <Heading>{title}</Heading>}
          {typeof text === 'string' ? <Text>{text}</Text> : text}
        </Styled.TextWrapper>
      </Styled.ContentWrapper>
      {button}
    </Styled.Alert>
  );
};

export default Alert;
