import styled, { css } from 'styled-components';
import { sidebarBg, sidebarPadding } from '../../TaskSidebar.styled';
import { UiText } from '../../../../../styles/Typography';
import { color, fontSize, fontWeight } from '../../../../../styles/Variables';
import Heading from '../../../../atoms/Heading';

export const Tags = styled.div`
  ${sidebarBg};
  ${sidebarPadding};
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding-top: 2.4rem;
`;

export const TitleContainer = styled.div`
  ${sidebarBg};
  ${sidebarPadding};
  padding-top: 0;
`;

export const ProjectInfo = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

export const Path = styled.a`
  ${UiText};
  font-size: ${fontSize.body.l};
  color: ${color.neutral[70]};
`;

export const TitleWrapper = styled.div`
  display: flex;
  gap: 0.6rem;
  align-items: center;
`;

export const Title = styled(Heading)`
  padding-top: 0;
`;

export const Details = styled.div`
  ${sidebarBg};
  ${sidebarPadding};
  padding-top: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

export const Detail = styled.div<{ full?: boolean }>`
  ${UiText};
  display: flex;
  align-items: center;
  gap: 0.8rem;
  width: 50%;
  padding-bottom: 2.4rem;
  margin-bottom: 0.8rem;
  color: ${color.neutral[60]};
  font-size: ${fontSize.body.s};
  ${(props) =>
    props.full &&
    css`
      width: 100%;
      flex-direction: column;
      align-items: start;
    `}
`;

export const Label = styled.div`
  padding-right: 0.5rem;
  color: ${color.neutral[60]};
  font-size: ${fontSize.body.s};
`;

export const Description = styled.div`
  ${sidebarBg};
  ${sidebarPadding};
  background-color: ${color.light};
  min-height: 10rem;
  max-width: 100%;
  width: 82rem;
  overflow: auto;
`;

export const InnerDescription = styled.div`
  width: 100%;

  * {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
  }
`;

export const InnerSavedDescription = styled.div`
  width: 100%;
  border: 1px solid ${color.primary[40]};
  background-color: ${color.primary[10]};
  padding: 1.2rem;
  border-radius: 0.3rem;
  margin-bottom: 1.6rem;

  * {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    font-size: ${fontSize.body.s};
    color: ${color.neutral[60]};
  }

  .info-warning {
    color: ${color.semantic.warning['80']};
    font-weight: ${fontWeight.bold};
    font-size: ${fontSize.body.s};
    margin-bottom: 0.6rem;
  }
`;

export const SourceFiles = styled.div`
  ${sidebarBg};
  ${sidebarPadding};
`;

export const SourceFilesWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  margin: 1.2rem 0 3rem;

  .attachments-container {
    margin: 0 0 0 1rem;
  }
`;

export const SourceFile = styled.div`
  width: 25rem;
`;

export const AddFileLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.2rem;
  height: 6.2rem;
  border-radius: 0.6rem;
  border: 1px dashed ${color.neutral['60']};
  background-color: transparent;
  cursor: pointer;
  transition: all 0.15s ease-out;
  transition-property: background-color, border;

  &:hover {
    border-color: ${color.neutral['30']};
    background-color: ${color.neutral['30']};
  }

  input {
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
  }
`;

export const ProjectButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2.4rem;
  border-top: 1px solid ${color.neutral[30]};
  justify-content: space-between;
  padding-top: 1.6rem;
`;
