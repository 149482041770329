import styled, { keyframes } from 'styled-components';
import { color } from '../../../styles/Variables';

const slideUpAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
  `;

const slideRightAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const slideDownAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideLeftAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  position: relative;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);

  .popover-container[data-state='open'][data-side='top'] {
    animation-name: ${slideDownAndFade};
  }
  .popover-container[data-state='open'][data-side='right'] {
    animation-name: ${slideLeftAndFade};
  }
  .popover-container[data-state='open'][data-side='bottom'] {
    animation-name: ${slideUpAndFade};
  }
  .popover-container[data-state='open'][data-side='left'] {
    animation-name: ${slideRightAndFade};
  }
`;
export const Handler = styled.div<{ $disabled: boolean }>`
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
`;

export const Dropdown = styled.div`
  border: 1px solid ${color.neutral[40]};
  border-radius: 8px;
  background: ${color.light};

  width: max-content;
  max-width: 20rem;
  padding: 0.5rem;
`;
