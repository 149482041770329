import React, { FunctionComponent, ReactNode } from 'react';
import { Grid, GridItem } from '../../atoms/Grid/Grid';
import { color } from '../../../styles/Variables';
import Heading from '../../atoms/Heading';

type Props = {
  heading: string;
  children: ReactNode | ReactNode[];
};

const OverlayFormSection: FunctionComponent<Props> = ({ heading, children }) => {
  return (
    <Grid style={{ marginBottom: '3rem' }}>
      <GridItem $desktop={12} style={{ marginBottom: '3.6rem' }}>
        <Heading level={3} size={'s'} color={color.primary[60]}>
          {heading}
        </Heading>
      </GridItem>
      <GridItem $desktop={12}>
        <Grid spacing={4} $align={'center'}>
          {children}
        </Grid>
      </GridItem>
    </Grid>
  );
};

export default OverlayFormSection;
