import React from 'react';
import { BasicProjectType } from '../../../../../@types/Project/Project';
import ProjectStatus from '../../../ProjectStatus/ProjectStatus';
import { CellTemplate } from '../../CellTemplate';
import { NonBillableDetailDict } from '../../../../../@dicts/Project/NonBillableDetailDict';
import BetterChip from '../../../../atoms/Chips/BetterChip';

const ProjectStatusTemplate: CellTemplate<BasicProjectType['status'], {}, BasicProjectType> = ({ value, item }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.3rem' }}>
      <ProjectStatus status={value} archived={item.archived} />

      {!item.billable && (
        <div>
          <BetterChip color={'success'}>{NonBillableDetailDict.find((v) => v.value === item.nonBillableDetail)?.label}</BetterChip>
        </div>
      )}
    </div>
  );
};

export default ProjectStatusTemplate;
