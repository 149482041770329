import React, { FunctionComponent } from 'react';
import { OptionProps, components } from 'react-select';
import { ChecklistTemplateType } from '../../../@types/ChecklistTemplate/ChecklistTemplateType';

type Props = OptionProps<{ value: string; label: string; meta: ChecklistTemplateType }, false>;

const SearchOption: FunctionComponent<Props> = (props) => {
  return (
    <components.Option {...props}>
      <span>{props.data.meta.name}</span>
    </components.Option>
  );
};

export default SearchOption;
