import React, { createElement, FunctionComponent, useEffect, useState } from 'react';
import { Command } from 'cmdk';
import * as Styled from './Commander.styled';
import * as Popover from '@radix-ui/react-popover';
import { PiBank, PiCalendarHeart, PiKanban, PiListChecks } from 'react-icons/pi';
import CommanderItem from './CommanderItem';
import api from '../../../services/api';

type Props = {};

export type Item<M extends string | number = string | number> = {
  icon?: FunctionComponent;
  value: string;
  type: string;
  keywords?: string[];
  metadata?: Record<M, string | number | boolean | null>;
  priority?: number;
  path?: string;
};

const Commander: FunctionComponent<Props> = (props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [items, setItems] = useState<Record<string, Item[]>>({
    'Quick links': [
      { type: 'Page', icon: PiListChecks, value: 'Projects', keywords: ['project', 'account', 'client'], path: '/projects/list' },
      { type: 'Page', icon: PiCalendarHeart, value: 'Timesheet', keywords: ['timesheet'], path: '/projects/timesheets' },
      { type: 'Page', icon: PiKanban, value: 'Workload', keywords: [], path: '/projects/workload' },
      { type: 'Page', icon: PiBank, value: 'Finances', keywords: ['costs', 'incomes', 'purchase', 'vendor'], path: '/finances' },
    ],
  });
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const [search, setSearch] = useState('');
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((open) => !open);
      }
      if (e.key === 'Escape') {
        e.preventDefault();
        setOpen(false);
      }
    };

    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, []);

  useEffect(() => {
    if (open) {
      inputRef.current?.focus();
    }
  }, [open]);

  useEffect(() => {
    setLoading(true);
    const t = setTimeout(() => {
      api.get<Item[]>('/search', { params: { search } }).then((response) => {
        setItems((i) => ({ ...i, 'Best matches': response.data }));
        setLoading(false);
      });
    }, 500);
    return () => {
      clearTimeout(t);
    };
  }, [search]);

  return (
    <Styled.Container $open={open}>
      <Styled.InnerContainer>
        <div className="raycast">
          <Command value={value} onValueChange={(v) => setValue(v)}>
            <div cmdk-raycast-top-shine="" />
            <Command.Input onValueChange={setSearch} ref={inputRef} autoFocus placeholder="Search for apps and commands..." />
            <hr cmdk-raycast-loader="" />
            <Command.List>
              <Command.Empty>No results found.</Command.Empty>
              {Object.keys(items).map((key) => (
                <Command.Group heading={key} key={key}>
                  {items[key].map((item) => (
                    <CommanderItem type={item.type} onSelect={() => setOpen(false)} item={item} key={`${key}-${item.value}`} />
                  ))}
                </Command.Group>
              ))}
            </Command.List>

            <div cmdk-raycast-footer="">
              <div style={{ display: 'flex' }}>
                {loading && (
                  <>
                    <span>Loading data...</span>
                  </>
                )}
              </div>
              <button cmdk-raycast-open-trigger="">
                Open Application
                <kbd>↵</kbd>
              </button>
            </div>
          </Command>
        </div>
      </Styled.InnerContainer>
    </Styled.Container>
  );
};

function SubCommand({
  inputRef,
  listRef,
  selectedValue,
}: {
  inputRef: React.RefObject<HTMLInputElement>;
  listRef: React.RefObject<HTMLElement>;
  selectedValue: string;
}) {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    function listener(e: KeyboardEvent) {
      if (e.key === 'k' && e.metaKey) {
        e.preventDefault();
        setOpen((o) => !o);
      }
    }

    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  React.useEffect(() => {
    const el = listRef.current;

    if (!el) return;

    if (open) {
      el.style.overflow = 'hidden';
    } else {
      el.style.overflow = '';
    }
  }, [open, listRef]);

  return (
    <Popover.Root open={open} onOpenChange={setOpen} modal>
      <Popover.Trigger cmdk-raycast-subcommand-trigger="" onClick={() => setOpen(true)} aria-expanded={open}>
        Actions
        <kbd>⌘</kbd>
        <kbd>K</kbd>
      </Popover.Trigger>
      <Popover.Content
        side="top"
        align="end"
        className="raycast-submenu"
        sideOffset={16}
        alignOffset={0}
        onCloseAutoFocus={(e) => {
          e.preventDefault();
          inputRef?.current?.focus();
        }}
      >
        <Command>
          <Command.List>
            <Command.Group heading={selectedValue}>
              <SubItem shortcut="↵">
                <WindowIcon />
                Open Application
              </SubItem>
              <SubItem shortcut="⌘ ↵">
                <FinderIcon />
                Show in Finder
              </SubItem>
              <SubItem shortcut="⌘ I">
                <FinderIcon />
                Show Info in Finder
              </SubItem>
              <SubItem shortcut="⌘ ⇧ F">
                <StarIcon />
                Add to Favorites
              </SubItem>
            </Command.Group>
          </Command.List>
          <Command.Input placeholder="Search for actions..." />
        </Command>
      </Popover.Content>
    </Popover.Root>
  );
}

function SubItem({ children, shortcut }: { children: React.ReactNode; shortcut: string }) {
  return (
    <Command.Item>
      {children}
      <div cmdk-raycast-submenu-shortcuts="">
        {shortcut.split(' ').map((key) => {
          return <kbd key={key}>{key}</kbd>;
        })}
      </div>
    </Command.Item>
  );
}

function WindowIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.25 4.75V3.75C14.25 2.64543 13.3546 1.75 12.25 1.75H3.75C2.64543 1.75 1.75 2.64543 1.75 3.75V4.75M14.25 4.75V12.25C14.25 13.3546 13.3546 14.25 12.25 14.25H3.75C2.64543 14.25 1.75 13.3546 1.75 12.25V4.75M14.25 4.75H1.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function FinderIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 4.75V6.25M11 4.75V6.25M8.75 1.75H3.75C2.64543 1.75 1.75 2.64543 1.75 3.75V12.25C1.75 13.3546 2.64543 14.25 3.75 14.25H8.75M8.75 1.75H12.25C13.3546 1.75 14.25 2.64543 14.25 3.75V12.25C14.25 13.3546 13.3546 14.25 12.25 14.25H8.75M8.75 1.75L7.08831 7.1505C6.9202 7.69686 7.32873 8.25 7.90037 8.25C8.36961 8.25 8.75 8.63039 8.75 9.09963V14.25M5 10.3203C5 10.3203 5.95605 11.25 8 11.25C10.0439 11.25 11 10.3203 11 10.3203"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function StarIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.43376 2.17103C7.60585 1.60966 8.39415 1.60966 8.56624 2.17103L9.61978 5.60769C9.69652 5.85802 9.92611 6.02873 10.186 6.02873H13.6562C14.2231 6.02873 14.4665 6.75397 14.016 7.10088L11.1582 9.3015C10.9608 9.45349 10.8784 9.71341 10.9518 9.95262L12.0311 13.4735C12.2015 14.0292 11.5636 14.4777 11.1051 14.1246L8.35978 12.0106C8.14737 11.847 7.85263 11.847 7.64022 12.0106L4.89491 14.1246C4.43638 14.4777 3.79852 14.0292 3.96889 13.4735L5.04824 9.95262C5.12157 9.71341 5.03915 9.45349 4.84178 9.3015L1.98404 7.10088C1.53355 6.75397 1.77692 6.02873 2.34382 6.02873H5.81398C6.07389 6.02873 6.30348 5.85802 6.38022 5.60769L7.43376 2.17103Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function HammerIcon() {
  return (
    <div cmdk-raycast-hammer-icon="">
      <svg width="32" height="32" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.73762 6.19288L2.0488 11.2217C1.6504 11.649 1.6504 12.3418 2.0488 12.769L3.13083 13.9295C3.52923 14.3568 4.17515 14.3568 4.57355 13.9295L9.26238 8.90071M6.73762 6.19288L7.0983 5.80605C7.4967 5.37877 7.4967 4.686 7.0983 4.25872L6.01627 3.09822L6.37694 2.71139C7.57213 1.42954 9.50991 1.42954 10.7051 2.71139L13.9512 6.19288C14.3496 6.62017 14.3496 7.31293 13.9512 7.74021L12.8692 8.90071C12.4708 9.328 11.8248 9.328 11.4265 8.90071L11.0658 8.51388C10.6674 8.0866 10.0215 8.0866 9.62306 8.51388L9.26238 8.90071M6.73762 6.19288L9.26238 8.90071"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
export default Commander;
