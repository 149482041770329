import { DictValue } from '../../@types/Dictionary/DictValue';

export const NonBillableDetailDict: DictValue[] = [
  {
    value: 'new_business',
    label: 'New Business',
  },
  {
    value: 'marketing',
    label: 'Marketing',
  },
  {
    value: 'internal',
    label: 'Internal',
  },
  {
    value: 'technical',
    label: 'Technical',
  },
];
