import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import * as Styled from './Chip.styled';
import { TextType } from '../Text/Text.styled';

type CopyType = TextType & {
  children: ReactElement | ReactNode | string;
  onClick?: any;
  $bgColor?: string;
  $rounded?: boolean;
  $hoverColor?: string;
  $equalPaddings?: boolean;
};
/**
 * @deprecated Use BetterChip instead
 */
const Chip: FunctionComponent<CopyType> = (props) => {
  return (
    <Styled.Chip
      {...props}
      onClick={props.onClick}
      // $bgColor={props.bgColor}
      $rounded={props.$rounded}
      $hoverColor={props.$hoverColor}
      $size={props.$size}
      $align={props.$align}
      $bold={props.$bold}
      $equalPaddings={props.$equalPaddings}
    >
      {props.children}
    </Styled.Chip>
  );
};

export default Chip;
