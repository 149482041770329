import React, { FunctionComponent, ReactElement } from 'react';
import { color } from '../styles/Variables';

export type SandStatus = 'fullFilled' | 'halfFilled' | 'crossed';

type PropType = {
  sandStatus: SandStatus;
  size?: number;
  sandColor?: string;
};

export const Sandglass: FunctionComponent<PropType> = ({ size = 1, sandColor = color.neutral['60'], sandStatus }) => {
  const sandglass: { [key: string]: ReactElement } = {
    fullFilled: (
      <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.666748 13.6667V12.3334H2.00008V10.3334C2.00008 9.6556 2.15841 9.01948 2.47508 8.42504C2.79175 7.8306 3.23341 7.3556 3.80008 7.00004C3.23341 6.64448 2.79175 6.16949 2.47508 5.57504C2.15841 4.9806 2.00008 4.34448 2.00008 3.66671V1.66671H0.666748V0.333374H11.3334V1.66671H10.0001V3.66671C10.0001 4.34448 9.84175 4.9806 9.52508 5.57504C9.20842 6.16949 8.76675 6.64448 8.20008 7.00004C8.76675 7.3556 9.20842 7.8306 9.52508 8.42504C9.84175 9.01948 10.0001 9.6556 10.0001 10.3334V12.3334H11.3334V13.6667H0.666748Z"
          fill={sandColor}
        />
      </svg>
    ),
    halfFilled: (
      <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.00008 6.33325C6.73342 6.33325 7.36119 6.07214 7.88341 5.54992C8.40564 5.0277 8.66675 4.39992 8.66675 3.66658V1.66659H3.33341V3.66658C3.33341 4.39992 3.59453 5.0277 4.11675 5.54992C4.63897 6.07214 5.26675 6.33325 6.00008 6.33325ZM0.666748 13.6666V12.3333H2.00008V10.3333C2.00008 9.65547 2.15841 9.01936 2.47508 8.42492C2.79175 7.83047 3.23341 7.35547 3.80008 6.99992C3.23341 6.64436 2.79175 6.16936 2.47508 5.57492C2.15841 4.98047 2.00008 4.34436 2.00008 3.66658V1.66659H0.666748V0.333252H11.3334V1.66659H10.0001V3.66658C10.0001 4.34436 9.84175 4.98047 9.52508 5.57492C9.20842 6.16936 8.76675 6.64436 8.20008 6.99992C8.76675 7.35547 9.20842 7.83047 9.52508 8.42492C9.84175 9.01936 10.0001 9.65547 10.0001 10.3333V12.3333H11.3334V13.6666H0.666748Z"
          fill={sandColor}
        />
      </svg>
    ),
    crossed: (
      <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.65 14.5333L12.7833 13.6666H2.66662V12.3333H3.99995V10.3333C3.99995 9.65547 4.15828 9.01936 4.47495 8.42492C4.79162 7.83047 5.23328 7.35547 5.79995 6.99992C5.4444 6.7777 5.14162 6.51103 4.89162 6.19992C4.64162 5.88881 4.43884 5.54436 4.28328 5.16659L0.449951 1.33325L1.39995 0.383252L14.6 13.5833L13.65 14.5333ZM10.0333 7.09992L9.03329 6.11659C9.53329 5.90548 9.93051 5.5777 10.225 5.13325C10.5194 4.68881 10.6666 4.19992 10.6666 3.66659V1.66659H5.33328V2.41659L3.24995 0.333252H13.3333V1.66659H12V3.66659C12 4.3777 11.8277 5.03881 11.4833 5.64992C11.1388 6.26103 10.6555 6.74436 10.0333 7.09992ZM5.33328 12.3333H10.6666V11.5499L6.98328 7.86659C6.48328 8.0777 6.08328 8.40548 5.78328 8.84992C5.48328 9.29436 5.33328 9.78881 5.33328 10.3333V12.3333Z"
          fill={sandColor}
        />
      </svg>
    ),
  };

  return <>{sandglass[sandStatus]}</>;
};
