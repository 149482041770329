import React, { FunctionComponent } from 'react';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import { ChecklistItemTemplateType } from '../../../../@types/ChecklistTemplate/ChecklistTemplateType';
import Text from '../../../atoms/Text';
import TickIcon from '../../../../Icons/TickIcon';
import { color } from '../../../../styles/Variables';

type Props = {
  item: ChecklistItemTemplateType;
};

const ChecklistTemplateItem: FunctionComponent<Props> = ({ item }) => {
  return (
    <GridItem $desktop={12}>
      <Grid spacing={1} $align={'center'} style={{ flexWrap: 'nowrap' }}>
        <GridItem $desktop={'auto'} style={{ display: 'flex', alignItems: 'center' }}>
          <TickIcon fill={color.neutral['80']} />
        </GridItem>
        <GridItem $desktop={'max'}>
          <Text bold={true}>{item.content}</Text>
        </GridItem>
      </Grid>
    </GridItem>
  );
};

export default ChecklistTemplateItem;
