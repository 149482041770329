import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import ContractorsList from '../../../../../components/templates/Contractors/ContractorsList/ContractorsList';
import ContractorsShowView from '../../../../../components/templates/Contractors/ContractorShow/ContractorShowView';
import ContractorFormEditView from '../../../../../components/templates/Contractors/ContractorForm/ContractorFormEditView';
import ContractorForm from '../../../../../components/templates/Contractors/ContractorForm/ContractorForm';
import { Helmet } from 'react-helmet';
import Container from '../../../../../components/atoms/Container/Container';

export function ContractorsView() {
  return (
    <Container>
      <Helmet>
        <title>Vendors | F.CAPE</title>
      </Helmet>
      <Routes>
        <Route path="" element={<ContractorsList />}></Route>
        <Route path=":id/show" element={<ContractorsShowView />}></Route>
        <Route path=":id/edit" element={<ContractorFormEditView />}></Route>
        <Route path="create" element={<ContractorForm />}></Route>
      </Routes>
    </Container>
  );
}
