import { TextSizeType } from '../../../@types/Text/TextSizeType';
import React, { CSSProperties, FunctionComponent, ReactNode } from 'react';
import * as Styled from './Text.styled';
import { ColorType } from '../../../@types/Color/ColorType';

export type TextProps = {
  children: ReactNode;
  size?: TextSizeType;
  color?: ColorType;
  bold?: boolean;
  as?: 'span' | 'small' | 'div';
  className?: string;
  style?: CSSProperties & { textWrap?: 'nowrap' };
};

const Text: FunctionComponent<TextProps> = ({ size = 'm', children, color, as = 'div', bold, className, style }) => {
  return (
    <Styled.Paragraph as={as} $size={size} $color={color} $bold={bold} className={className} style={style}>
      {children}
    </Styled.Paragraph>
  );
};

export default Text;
