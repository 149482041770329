import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FormPage from '../../Form/FormPage/FormPage';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import { ChecklistTemplateType } from '../../../../@types/ChecklistTemplate/ChecklistTemplateType';
import HiddenInput from '../../../atoms/Form/HiddenInput/HiddenInput';
import ChecklistItemTemplateList from './ChecklistItemTemplateList';
import { Card } from '../../../atoms/Card/Card';
import { TextInput } from '../../../molecules/Form/TextInput/TextInput';
import { TextAreaInput } from '../../../molecules/Form/TextAreaInput/TextAreaInput';
import Container from '../../../atoms/Container/Container';
import Heading from '../../../atoms/Heading';

type Props = {};

const ChecklistTemplateForm: FunctionComponent<Props> = (props) => {
  const navigate = useNavigate();
  const { articleId, id } = useParams<{ articleId: string | undefined; id: string | undefined }>();

  const handleSuccess = useCallback((entity: ChecklistTemplateType) => {
    navigate(entity.article.fullPath);
  }, []);

  const basePath = useMemo(() => {
    let base = '/api/wiki/checklist_templates';
    if (id) {
      return `${base}/${id}`;
    }
    return base;
  }, [id]);

  return (
    <Container>
      <FormPage
        basePath={basePath}
        createTitle={'Create checklist for article'}
        onCreate={handleSuccess}
        onEdit={handleSuccess}
        editTitle={'Edit checklist for article'}
      >
        <GridItem $desktop={6}>
          <Card>
            <Grid spacing={4}>
              <Heading size={'s'} noPadding>
                Checklist details
              </Heading>
              <GridItem $desktop={12}>
                <TextInput name={'name'} required label={'Checklist name'} placeholder={'Type checklist name'} />
              </GridItem>
              <GridItem $desktop={12}>
                <TextAreaInput name={'description'} label={'Checklist description'} placeholder={'Type checklist description'} />
              </GridItem>
              <HiddenInput name="article" value={`/api/wiki/articles/${articleId}`} />
            </Grid>
          </Card>
        </GridItem>
        <GridItem $desktop={6}>
          <Card>
            <Grid>
              <GridItem style={{ marginBottom: '3rem' }}>
                <Heading size={'s'} noPadding>
                  Checklist items
                </Heading>
              </GridItem>
            </Grid>
            <ChecklistItemTemplateList />
          </Card>
        </GridItem>
      </FormPage>
    </Container>
  );
};

export default ChecklistTemplateForm;
