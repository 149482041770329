import React, { FunctionComponent } from 'react';
import { components, OptionProps } from 'react-select';
import { CustomOptionType } from '../../../@types/Select/CustomOptionsType';
import { TeamMemberType } from '../../../@types/TeamMember/TeamMemberType';
import Text from '../../atoms/Text/Text';
import { Colored } from '../../../legacy/CapeMorris/components/layout/layout.styled';

type Props = OptionProps<CustomOptionType<TeamMemberType, number>, true | false>;
const TeamSelectorOption: FunctionComponent<Props> = (props) => {
  return (
    <components.Option {...props}>
      <Text color={'primary'}>{props.data.object.name}</Text>
      <Text color={'grey'} size={'xs'}>
        {props.data.object.position.name}
        &nbsp;&nbsp;&nbsp;<Colored>|</Colored>
        {props.data.object.departmentName}
      </Text>
    </components.Option>
  );
};
export default TeamSelectorOption;
