import styled from 'styled-components';
import { Cell } from '../../../../../atoms/Table/TableCell.styled';

export const History = styled(Cell)`
  flex-direction: column;
  vertical-align: unset;
  padding-top: 3rem;
`;
export const HistoryItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2.4rem;
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  margin-top: 2rem;
`;
