import styled, { css } from 'styled-components';
import { color } from '../../../styles/Variables';

export const CommentsMainContainer = styled.div`
  background-color: transparent;
  width: 100%;
  max-width: 72rem;
  position: relative;
`;

export const CommentsHeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 1.6rem 0;
  border-bottom: 1px solid ${color.neutral[40]};
`;

export const CommentsWrapper = styled.div<{ $finances: boolean | undefined }>`
  padding: 2.4rem 0;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  ${(props) =>
    props.$finances &&
    css`
      padding: 3.2rem 3.2rem 7rem 0;
    `}
`;

export const CommentsSort = styled.button`
  cursor: pointer;
  border: none;
  padding: 0.25rem;
  border-radius: 0.5em;
  transition: background-color 0.2s ease-in-out;
  color: ${color.primary[60]};

  &:hover {
    transform: none !important;
  }
`;
