import React, { FunctionComponent, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { ProjectListItemType } from '../../../../@types/Project/Project';
import { ClientListItemType } from '../../../../@types/Project/ClientListItemType';
import { CategoryListItemType } from '../../../../@types/Project/CategoryListItemType';
import api from '../../../../services/api';
import ProjectAll from 'components/templates/ProjectAll/ProjectAll';
import { ListItemType } from 'components/molecules/Projects/ListItem/ListItem';
import { ListColumnType } from 'components/molecules/Projects/ListColumn/ListColumn';
import AddNewPartnerButton from '../../../../components/molecules/Projects/ColumnButtons/AddNewPartnerButton';
import AddNewCategoryButton from '../../../../components/molecules/Projects/ColumnButtons/AddNewCategoryButton';
import AddNewProjectButton from '../../../../components/molecules/Projects/ColumnButtons/AddNewProjectButton';
import { useNavigate } from 'react-router-dom';

interface OwnProps {}

type Props = OwnProps;

export type ClientsResponse = AxiosResponse<{
  'hydra:member': ClientListItemType[];
}>;
export type CategoriesResponse = AxiosResponse<{
  'hydra:member': CategoryListItemType[];
}>;
export type ProjectsResponse = AxiosResponse<{
  'hydra:member': ProjectListItemType[];
}>;

const ProjectsColumnsView: FunctionComponent<Props> = (props) => {
  const [clients, setClients] = React.useState<ListItemType[]>([]);
  const [clientsLoading, setClientsLoading] = React.useState<boolean>(false);

  const [projects, setProjects] = React.useState<ListItemType[]>([]);
  const [projectsLoading, setProjectsLoading] = React.useState<boolean>(false);

  const [categories, setCategories] = React.useState<ListItemType[]>([]);
  const [categoriesLoading, setCategoriesLoading] = React.useState<boolean>(false);

  const [clientId, setClientId] = React.useState<number | null>(null);
  const [categoryId, setCategoryId] = React.useState<number | null>(null);
  const [showArchived, setShowArchived] = React.useState<boolean>(false);
  const navigate = useNavigate();

  const loadClients = React.useCallback(() => {
    setClientsLoading(true);
    const params: any = {};
    if (!showArchived) {
      params['archived'] = false;
    }

    api
      .get('/clients', {
        params: params,
      })
      .then((response: ClientsResponse) => {
        const clients = response.data['hydra:member'];
        const items: ListItemType[] = clients.map((c) => ({
          id: c.id,
          name: c.name,
          archived: c.archived,
          color: c.color,
        }));
        setClients(items);
        setClientsLoading(false);
      });
  }, [showArchived]);

  const loadCategories = React.useCallback(
    (clientId: number) => {
      setCategoriesLoading(true);
      const params: any = {
        'client.id': clientId,
      };
      if (!showArchived) {
        params['archived'] = false;
      }
      api
        .get('/categories', {
          params: params,
        })
        .then((response: CategoriesResponse) => {
          const categories = response.data['hydra:member'];
          const categoriesItems: ListItemType[] = categories.map((cat) => ({
            id: cat.id,
            name: cat.name,
            '@id': cat['@id'],
            archived: cat.archived,
          }));
          setCategories(categoriesItems);
          setCategoriesLoading(false);
        });
    },
    [showArchived],
  );

  const loadProjects = React.useCallback(
    (categoryId: number) => {
      setProjectsLoading(true);
      const params: any = {
        'category.id': categoryId,
      };
      if (!showArchived) {
        params['archived'] = false;
      }
      api
        .get('/projects', {
          params: params,
        })
        .then((response: ProjectsResponse) => {
          const projects = response.data['hydra:member'];
          const projectItems: ListItemType[] = projects.map((p) => ({
            id: p.id,
            name: p.name,
            archived: p.archived,
          }));
          setProjects(projectItems);
          setProjectsLoading(false);
        });
    },
    [showArchived],
  );

  const handleClientSelect = React.useCallback((item: ListItemType) => {
    setClientId(item.id);
    setProjects([]);
    setCategoryId(null);
  }, []);

  const handleCategorySelect = React.useCallback((item: ListItemType) => {
    setCategoryId(item.id);
  }, []);

  useEffect(() => {
    loadClients();
  }, [showArchived]);

  useEffect(() => {
    if (clientId) {
      loadCategories(clientId);
    }
  }, [clientId, showArchived]);

  useEffect(() => {
    if (categoryId) {
      loadProjects(categoryId);
    }
  }, [categoryId, showArchived]);

  const columns: ListColumnType[] = [
    {
      name: 'Partners',
      items: clients,
      onSelect: handleClientSelect,
      isLoading: clientsLoading,
      button: <AddNewPartnerButton buttonId={'add-new-partner'} loadPartners={loadClients} />,
      itemType: 'client',
    },
    {
      name: 'Categories',
      items: categories,
      onSelect: handleCategorySelect,
      isLoading: categoriesLoading,
      itemType: 'category',
      clientId: clientId,
      button: <AddNewCategoryButton buttonId={'add-new-category'} clientId={clientId} loadCategories={loadCategories} />,
    },
    {
      name: 'Projects',
      items: projects,
      onSelect: (item) => navigate(`/projects/projects/${item.id}`),
      isLoading: projectsLoading,
      itemType: 'project',
      button: <AddNewProjectButton buttonId={'add-new-project'} clientId={clientId} categoryId={categoryId} loadProjects={loadProjects} />,
    },
  ];

  return <ProjectAll columns={columns} onFilter={() => setShowArchived((prevState) => !prevState)} showArchived={showArchived} />;
};

export default ProjectsColumnsView;
