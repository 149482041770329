import { NavigationNodeType } from '../../../@types/NavigationTree/NavigationTreeType';
import { useCallback, useEffect, useMemo, useState } from 'react';
import api from '../../../services/api';
import { CategoryType } from '../../../@types/Category/CategoryType';
import { ArticleType } from '../../../@types/Article/ArticleType';
import { ArrayResponseType } from '../../../@types/hydra/hydra';

type UseWikiTreeResponse = {
  tree: NavigationNodeType[] | undefined;
  reload: () => void;
};

function useWikiTree(search: string | null = null, tags: string[] = []): UseWikiTreeResponse {
  const [tree, setTree] = useState<NavigationNodeType[]>();

  const filteredTree = useMemo<NavigationNodeType[] | undefined>(() => {
    if (!search && tags.length === 0) return tree;
    if (!tree) return tree;

    let _tree: NavigationNodeType[] = JSON.parse(JSON.stringify(tree));
    _tree = _tree.map((t) => cleanNode(t, tags, search));
    return _tree.filter((t) => t.children && t.children.length > 0);
  }, [tree, tags, search]);

  const loadTree = useCallback(() => {
    api.get<ArrayResponseType<CategoryType>>('/wiki/tree').then((response) => {
      setTree(response.data['hydra:member'].map((c) => categoryToNode(c)));
    });
  }, []);

  const reload = useCallback(() => {
    loadTree();
  }, [loadTree]);

  useEffect(() => {
    loadTree();
  }, []);
  return {
    tree: filteredTree,
    reload,
  };
}

function categoryToNode(category: CategoryType): NavigationNodeType {
  return {
    name: category.name,
    '@id': category['@id'],
    slug: category.slug,
    type: 'category',
    fullPath: category.fullPath,
    children: [...category.articles.map((a) => articleToNode(a)), ...category.children.map((c) => categoryToNode(c))],
  };
}

function articleToNode(article: ArticleType): NavigationNodeType {
  return {
    name: article.name,
    '@id': article['@id'],
    slug: article.slug,
    fullPath: article.fullPath,
    type: 'article',
    tags: article.tags,
    isProcess: article.isProcess,
  };
}

function checkArticle(node: NavigationNodeType, tags: string[] = [], search: string | null = null): boolean {
  if (node.type !== 'article') {
    return false;
  }
  let result = false;

  if (tags.length > 0) {
    if (!node.tags || node.tags.length === 0) {
      return false;
    } else {
      tags.forEach((filterTag) => {
        if (node.tags && node.tags.filter((tag) => tag.value === filterTag).length > 0) {
          result = true;
        }
      });
    }
  }
  if (search) {
    return node.name.includes(search);
  }
  return result;
}

function cleanNode(node: NavigationNodeType, tags: string[] = [], search: string | null = null): NavigationNodeType {
  if (node.type === 'article') {
    return node;
  }
  if (node.children) {
    node.children.forEach((c) => cleanNode(c, tags, search));
    node.children = node.children.filter((child) => {
      if (child.type === 'category') {
        return child.children && child.children.length > 0;
      } else {
        return checkArticle(child, tags, search);
      }
    });
  }

  return node;
}

export default useWikiTree;
