import React from 'react';
import { TableFilterInterface } from '../TableFilterInterface';
import DatePicker from '../../Form/DatePicker/DatePicker';

export type DateFilterOptions = {
  before?: boolean;
};
const DateFilter: TableFilterInterface<DateFilterOptions> = ({ name, filter, onChange, defaultValue, options }) => {
  return (
    <DatePicker
      name={name}
      onChange={onChange}
      defaultValue={defaultValue}
      label={filter.label ?? name}
      placeholder={filter.placeholder}
      before={options?.before}
    />
  );
};

export default DateFilter;
