import React, { FunctionComponent, useCallback, useMemo } from 'react';
import FormPage from '../../../organisms/Form/FormPage/FormPage';
import { Card } from '../../../atoms/Card/Card';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import { CategoryType } from '../../../../@types/Category/CategoryType';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import RichTextInput from '../../../molecules/Form/RichTextInput/RichTextInput';
import { TextInput } from '../../../molecules/Form/TextInput/TextInput';
import Select from '../../../molecules/Form/SelectInput/SelectInput';
import Container from '../../../atoms/Container/Container';
import Heading from '../../../atoms/Heading';

type Props = {};

const WikiCategoryForm: FunctionComponent<Props> = (props) => {
  const navigate = useNavigate();
  let { id } = useParams<{ id: string | undefined }>();
  const { state } = useLocation();
  const handleSuccess = useCallback((entity: CategoryType) => {
    navigate(entity.fullPath);
  }, []);

  const basePath = useMemo(() => {
    let base = '/api/wiki/wiki_categories';
    if (id) {
      return `${base}/${id}`;
    }
    return base;
  }, [id]);

  const defaults = useMemo(() => {
    if (id) {
      return undefined;
    }
    if (state && state.category) {
      return { parent: state.category };
    }
    return undefined;
  }, [state, id]);

  return (
    <Container>
      <FormPage
        basePath={basePath}
        createTitle={'Create category'}
        editTitle={'Edit category'}
        onCreate={handleSuccess}
        onEdit={handleSuccess}
        defaults={defaults}
      >
        <GridItem $desktop={12}>
          <Card>
            <Grid spacing={4}>
              <GridItem $desktop={12}>
                <Heading size={'s'} noPadding>
                  Category details
                </Heading>
              </GridItem>
              <GridItem $desktop={6}>
                <TextInput name={'name'} label={'Category name'} placeholder={'Type category name'} />
              </GridItem>
              <GridItem $desktop={6}>
                <Select name={'parent'} label={'Parent category'} baseUrl={'/api/wiki/wiki_categories/'} />
              </GridItem>
            </Grid>
          </Card>
        </GridItem>
        <GridItem $desktop={12}>
          <Card style={{ height: '100%', maxHeight: '50vh' }}>
            <Grid spacing={4}>
              <GridItem $desktop={12}>
                <Heading size={'s'} noPadding>
                  Category description
                </Heading>
              </GridItem>
              <GridItem $desktop={12}>
                <RichTextInput name="description" label={'Description'} placeholder={'Category description'} required />
              </GridItem>
            </Grid>
          </Card>
        </GridItem>
      </FormPage>
    </Container>
  );
};

export default WikiCategoryForm;
