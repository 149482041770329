import React, { FunctionComponent, ReactElement, useCallback, useState } from 'react';
import * as Styled from './EditableDetail.styled';
import useApi from '../../../hooks/useApi';
import Form from '../Form/Form';
import { EntityType } from '../../../@types/Entity/EntityType';
import { DictValue } from '../../../@types/Dictionary/DictValue';
import * as Popover from '@radix-ui/react-popover';

type Props<T> = {
  name: keyof T;
  subject: T;
  show: ReactElement;
  component: FunctionComponent<{
    name: any;
    onChange: (value: DictValue | undefined) => void;
    visible: boolean;
    onClear?: () => void;
  }>;
  dropdownPosition?: 'center' | 'right';
  canValueBeCleared?: boolean;
  onUpdate?: (object: T) => void;
  disabled?: boolean;
};

const EditableDetail = <T extends EntityType>({ name, show, component, canValueBeCleared, subject, onUpdate, disabled = false }: Props<T>) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { put } = useApi();
  const onSubmit = useCallback(
    (value: DictValue | undefined) => {
      void put(
        subject['@id'],
        {
          [name]: value ? value.value : null,
        },
        true,
      ).then((r) => {
        setIsEditing(false);
        onUpdate && onUpdate(r.data);
      });
    },
    [subject],
  );

  return (
    <Styled.Container onClick={disabled ? undefined : (e) => e.stopPropagation()}>
      <Popover.Root open={isEditing} onOpenChange={setIsEditing}>
        <Popover.Trigger asChild>
          <Styled.Handler $disabled={disabled}>{show}</Styled.Handler>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content align="center" className={'popover-container'} style={{ zIndex: 18 }}>
            <Styled.Dropdown>
              <Form onSubmit={() => {}} defaultValues={subject}>
                {() => (
                  <>
                    {React.createElement(component, {
                      name,
                      onChange: (value) => {
                        onSubmit(value);
                      },
                      visible: isEditing,
                      onClear: canValueBeCleared
                        ? () => {
                            onSubmit(undefined);
                          }
                        : undefined,
                    })}
                  </>
                )}
              </Form>
            </Styled.Dropdown>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
      {/*<Styled.Handler onClick={disabled ? undefined : () => setIsEditing((v) => !v)} style={{ cursor: disabled ? 'default' : 'pointer' }}>*/}
      {/*  {show}*/}
      {/*</Styled.Handler>*/}
      {/*{isEditing ? <Styled.Dropdown visible={true} vertical={dropdownPosition}></Styled.Dropdown> : null}*/}
    </Styled.Container>
  );
};

export default EditableDetail;
