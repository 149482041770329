import React, { FunctionComponent, useCallback } from 'react';
import Button from '../../../atoms/Button/Button';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';

type Props = {
  onCreate?: () => void;
};

const TableActions: FunctionComponent<Props> = ({ onCreate }) => {
  const handleCreateClick = useCallback(() => {
    if (onCreate) {
      onCreate();
    }
  }, [onCreate]);
  return (
    <Grid>
      {onCreate && (
        <GridItem $desktop={'auto'}>
          <Button onClick={handleCreateClick} $text={'Create'} />
        </GridItem>
      )}
    </Grid>
  );
};

export default TableActions;
