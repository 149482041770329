import styled, { css, keyframes } from 'styled-components';
import { color } from '../../../../../styles/Variables';

type ProjectIconProps = {
  $dashed?: boolean;
  $bgColor?: string;
};
const slideIn = keyframes`
  0% {
    width: 0;
    opacity: 0;
  }

  50% {
    width: 2.4rem;
  }

  100% {
    opacity: 1;
  }
`;

const slideOut = keyframes`
  0% {
    visibility: visible;
    width: 2.4rem;
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
    width: 0;
  }
`;

export const TaskItemAction = styled.div`
  visibility: hidden;
  width: 0;
  animation: ${slideOut} 0.5s forwards;
`;

export const ProjectIcon = styled.div<ProjectIconProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 8px;
  background-color: ${color.primary['60']};
  transition: background-color 0.15s ease-out;

  ${(props: ProjectIconProps) =>
    props.$dashed &&
    css`
      border: 1px $dashed ${color.neutral['60']};
      background-color: transparent;
    `}

  ${(props: ProjectIconProps) =>
    props.$bgColor &&
    css`
      background-color: ${props.$bgColor};
    `}
`;

export const ProjectButtonWrapper = styled.div`
  display: flex;
  gap: 1.2rem;

  &:hover > div > button {
    color: ${color.primary['80']};
  }
`;

export const CardItemWrapper = styled.div<{ $hover?: boolean; $cursor?: string }>`
  display: flex;
  gap: 1.2rem;
  cursor: ${({ $cursor }) => ($cursor ? $cursor : 'pointer')};
  transition: all 0.15s ease-out;
  padding: 0.6rem;
  border-radius: 0.4rem;
  height: fit-content;

  &:hover {
    ${({ $hover }) =>
      $hover &&
      css`
        background-color: ${color.neutral['20']};
      `}
    ${TaskItemAction} {
      visibility: visible;

      width: 2.4rem;
      animation: ${slideIn} 0.5s forwards;
    }

    ${ProjectIcon} {
      border: none;
      background-color: ${color.neutral['40']};
    }
  }
`;

export const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InlineWrapper = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: center;
`;

export const ItemWrapper = styled.div`
  display: flex;
  width: 99%;
  justify-content: space-between;
`;

export const ItemRightWrapper = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
  justify-content: space-between;
`;

export const ItemLeftWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 0.8rem;
  align-items: center;
  justify-content: start;
  min-width: 15rem;
  padding-right: 0.8rem;

  .person-icon {
    transform: translateY(-1px);
  }
`;

export const Date = styled.div`
  &:hover {
    background-color: ${color.primary['60']};
  }
`;
