import { TableProperties } from '../../../../@types/Table/TableProperty';
import { ContractorType } from '../../../../@types/Finances/Contractor/ContractorType';
import StringTemplate from '../../../molecules/CellTemplates/common/StringTemplate/StringTemplate';
import ContractorTypeTemplate from '../../../molecules/CellTemplates/Finances/ContractorTypeTemplate/ContractorTypeTemplate';
import FinanceIdTemplate from '../../../molecules/CellTemplates/Finances/FinanceIdTemplate/FinanceIdTemplate';
import ContractorItemTaxTemplate from '../../../molecules/CellTemplates/Finances/ContractorItemTaxTemplate/ContractorItemTaxTemplate';
import AddressTemplate from './Templates/AddressTemplate';

const properties: TableProperties<ContractorType> = [
  {
    name: 'id',
    visibleName: 'ID',
    template: FinanceIdTemplate,
    sortable: true,
  },
  {
    name: 'name',
    visibleName: 'Name',
    template: ContractorItemTaxTemplate,
    sortable: true,
    templateOptions: {
      textProperties: {
        size: 'l',
        bold: true,
      },
    },
  },
  {
    name: 'client.name',
    visibleName: 'Partner',
    template: StringTemplate,
    sortable: true,
    templateOptions: {
      textProperties: {
        size: 'm',
        bold: true,
      },
    },
  },
  {
    name: 'type',
    visibleName: 'Type',
    template: ContractorTypeTemplate,
    sortable: true,
  },
  {
    name: 'taxNumber',
    visibleName: 'Tax number',
    template: StringTemplate,
    sortable: true,
    templateOptions: {
      textProperties: {
        size: 'm',
        bold: true,
      },
    },
  },
  {
    name: 'address',
    visibleName: 'Address',
    template: AddressTemplate,
    sortable: true,
  },
];

export default properties;
