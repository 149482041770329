import React, { FunctionComponent, ReactElement, ReactNode, useMemo } from 'react';
import useOverlay from '../../../hooks/useOverlay';
import { Grid, GridItem } from '../../atoms/Grid/Grid';
import Form from '../Form/Form';
import Button from '../../atoms/Button/Button';
import { UseFormReturn } from 'react-hook-form';

type Props = {
  children: ReactNode | ((methods: UseFormReturn) => ReactNode);
  onSubmit: (data: any) => void;
  defaultValues?: any;
  state?: null | 'Working...' | 'Done' | 'Error';
  primaryButton?: Omit<ReactNode, 'onClick'>;
  buttonProps?: {
    text?: string;
    icon?: ReactElement;
    iconSize?: number;
    waiting?: boolean;
  };
};

const OverlayForm: FunctionComponent<Props> = ({ onSubmit, state, children, defaultValues, buttonProps }) => {
  const { hideOverlay } = useOverlay();
  const btnText = useMemo(() => {
    if (state) {
      return state;
    }
    return buttonProps?.text ?? 'Save';
  }, [state, buttonProps]);

  return (
    <Form onSubmit={onSubmit} defaultValues={defaultValues}>
      {(methods) => (
        <Grid style={{ paddingBottom: '6.4rem' }}>
          <GridItem
            style={{
              marginBottom: '6.4rem',
              width: 'inherit',
            }}
          >
            {typeof children === 'function' ? children(methods) : children}
          </GridItem>
          <GridItem $desktop={12} style={{ overflowX: 'hidden' }}>
            <Grid $justifyContent={'space-between'}>
              <GridItem>
                <Button
                  $text={btnText}
                  type={'submit'}
                  kind={'primary'}
                  waiting={buttonProps?.waiting}
                  $icon={buttonProps?.icon ?? undefined}
                  iconSize={buttonProps?.iconSize}
                />
              </GridItem>
              <GridItem>
                <Button $text={'Cancel'} onClick={hideOverlay} kind={'link'} style={{ paddingRight: 0 }} />
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
      )}
    </Form>
  );
};

export default OverlayForm;
