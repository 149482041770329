import React, { FunctionComponent } from 'react';
import Text from '../../../atoms/Text/Text';
import { GridItem, GridItemProps } from '../../../atoms/Grid/Grid';
import { PurchaseOrderType } from '../../../../@types/Finances/PurchaseOrder/PurchaseOrderType';
import IdText from '../../IdText/IdText';
import * as Styled from '../Common.styled';
import { Link } from 'react-router-dom';
import { shorten } from '../../../../helpers/shorten';
import { TextSizeType } from '../../../../@types/Text/TextSizeType';
import { ColorType } from '../../../../@types/Color/ColorType';

type Props = GridItemProps & {
  purchaseOrder?: PurchaseOrderType;
  hideTitle?: boolean;
  size?: TextSizeType;
  bold?: boolean;
  color?: ColorType;
};

const PurchaseOrderSingleDetail: FunctionComponent<Props> = ({ purchaseOrder, $desktop = 'auto', hideTitle, size, bold, color, ...props }) => {
  return (
    <GridItem style={{ display: 'flex', flexDirection: 'column' }} $desktop={$desktop} {...props}>
      <Text color={'grey'} size={'s'} bold>
        {hideTitle ? '' : 'Purchase order'}
      </Text>
      {purchaseOrder && (
        <Styled.ClickableSingleDetail>
          <Link to={`/finances/purchase-orders/${purchaseOrder.id}/show`} target={'_blank'}>
            <GridItem style={{ display: 'flex', gap: '0.3rem', alignItems: 'center' }}>
              <IdText withHash={true} value={purchaseOrder.number ?? 'N/A'} size={size ?? 'l'} bold={bold} />
              <Text size={size ?? 'l'} bold={bold} color={color ?? 'dark'}>
                {shorten(purchaseOrder.title, 30, 30)}
              </Text>
            </GridItem>
          </Link>
        </Styled.ClickableSingleDetail>
      )}
    </GridItem>
  );
};

export default PurchaseOrderSingleDetail;
