import React, { FunctionComponent } from 'react';
import { RouterPageType } from '../../../../@types/RouterPage/RouterPageType';
import TabsNavigatorRouter from '../../../../components/molecules/TabsNavigatorRouter/TabsNavigatorRouter';
import PendingRequestsView from '../../../../components/templates/Absences/PendingRequestsView/PendingRequestsView';
import MyAbsencesView from '../../../../components/templates/Absences/MyAbsencesView/MyAbsencesView';
import AllAbsencesView from '../../../../components/templates/Absences/AllAbsencesView/AllAbsencesView';
import Actions from '../../../../components/templates/Absences/components/Actions/Actions';
import { AuthContext } from '../../../../Context/auth-context/auth-context';
import { Helmet } from 'react-helmet';

interface OwnProps {}

type Props = OwnProps;

const AbsencesSubmodule: FunctionComponent<Props> = (props) => {
  const { can } = React.useContext(AuthContext);
  const normalUserPages: RouterPageType[] = [
    {
      to: '/my-absences',
      name: 'My absences',
      screen: <MyAbsencesView />,
    },
  ];

  const adminUserPages: RouterPageType[] = [
    {
      to: '/pending-requests',
      name: 'Pending requests',
      screen: <PendingRequestsView />,
    },
    { to: '/my-absences', name: 'My absences', screen: <MyAbsencesView /> },
    { to: '/all-absences', name: 'All absences', screen: <AllAbsencesView /> },
  ];

  return (
    <>
      <Helmet>
        <title>Absences | F.CAPE</title>
      </Helmet>
      <TabsNavigatorRouter pages={can('ROLE_TEAM_LEADER') ? adminUserPages : normalUserPages} actions={<Actions />} />
    </>
  );
};

export default AbsencesSubmodule;
