import React, { FunctionComponent } from 'react';
import Button from '../../../../atoms/Button/Button';
import InventoryItemType from '../../../../../@types/Inventory/InventoryItemType';
import { AuthContext } from '../../../../../Context/auth-context/auth-context';
import useApi from '../../../../../hooks/useApi';

type Props = {
  entity: InventoryItemType;
};

const OfficeUseButton: FunctionComponent<Props> = ({ entity }) => {
  const {
    state: { user },
  } = React.useContext(AuthContext);
  const { post } = useApi();

  return (
    <Button
      $text={'Office use'}
      onClick={() => {
        let formData = {
          person: '/api/employees/' + user?.employeeId,
          type: 'common_use',
          items: [entity['@id']],
        };
        return post('/api/inventory_allocations', formData);
      }}
    />
  );
};

export default OfficeUseButton;
