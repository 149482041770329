import React, { ReactElement } from 'react';
import { TableRowActionsType } from '../../../../@types/Table/TableRowActionType';
import { EntityType } from '../../../../@types/Entity/EntityType';
import TableCell from '../../../atoms/Table/TableCell';
import * as Styled from './TableActions.styled';
import TableAction from './TableAction';
type Props<T extends EntityType> = {
  actions: TableRowActionsType<T>;
  item: T;
};

const TableActions = <T extends EntityType>({ actions, item }: Props<T>): ReactElement => {
  return (
    <TableCell>
      <Styled.TableActions>
        {actions.map((action) => (
          <TableAction {...action} key={action.name} item={item} />
        ))}
      </Styled.TableActions>
    </TableCell>
  );
};

export default TableActions;
