import React, { FunctionComponent } from 'react';
import { InputProps } from '../../../../@types/Form/InputProps';
import { useFormContext, useWatch } from 'react-hook-form';
import FormError from '../../../atoms/Form/FormError/FormError';
import * as Styled from './RadioColorsInput.styled';
import { ErrorMessages } from '../../../atoms/Form/FormMessages/ErrorMessages';
import AcceptIcon from '../../../../Icons/Accept.icon';
import { Icon } from '../../../atoms/Icon/Icon';
import Text from '../../../atoms/Text/Text';

type RadioInputProps = InputProps & {
  defaultValue?: boolean;
};

const RadioColorsInput: FunctionComponent<RadioInputProps> = ({ inputRef, disabled = false, defaultValue, required = false, onChange, name }) => {
  const { register, getValues } = useFormContext();
  const checked = useWatch({ name: name });
  const colors = [
    '#241909',
    '#533535',
    '#715B5B',
    '#D94E1F',
    '#E2724C',
    '#EC8D71',
    '#EF8B2C',
    '#FCAB10',
    '#F1BD6C',
    '#FFCD75',
    '#F1E7AD',
    '#D1C779',
    '#D6E8E4',
    '#A2CDC2',
    '#ACBEA3',
    '#72A182',
    '#0F5257',
    '#0B3142',
    '#04724D',
    '#81BC30',
    '#9FD05C',
    '#1395BA',
    '#2978A0',
    '#03517F',
    '#9CAFC6',
    '#A9A5CE',
    '#996FCF',
    '#5847AD',
    '#492571',
    '#635988',
    '#40476D',
    '#828580',
    '#5B5D5A',
    '#343434',
    '#1F1F1F',
    '#000000',
  ];

  return (
    <>
      <Text size={'l'} bold style={{ marginTop: '3.6rem' }}>
        Select color
      </Text>
      <Styled.Container>
        {colors.map((color, idx) => (
          <React.Fragment key={idx}>
            <Styled.Label>
              <Styled.HiddenRadio
                defaultChecked={defaultValue}
                type="radio"
                {...register(name, {
                  required: required && ErrorMessages.required,
                  onChange: onChange,
                })}
                onInput={() => (onChange ? onChange(getValues(name)) : null)}
                id={name}
                disabled={disabled}
                value={color}
              />
              <Styled.Radio $color={color} $checked={checked === color}>
                {checked === color && (
                  <Icon color="white">
                    <AcceptIcon />
                  </Icon>
                )}
              </Styled.Radio>
            </Styled.Label>
          </React.Fragment>
        ))}
      </Styled.Container>
      <FormError name={name} />
    </>
  );
};

export default RadioColorsInput;
