import styled from 'styled-components';
import { color } from '../../../../../styles/Variables';

export const Container = styled.div`
  color: ${color.light};
  height: 100%;
`;

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  align-content: space-between;
  position: relative;
  height: 100%;
`;

export const ActiveLink = styled.div`
  color: ${color.primary[60]};
`;
