import React, { FunctionComponent, useEffect } from 'react';
import Button from '../../../atoms/Button/Button';
import CategoryForm from '../../../organisms/Projects/Forms/CategoryForm/CategoryForm';
import useOverlay from '../../../../hooks/useOverlay';
import { color } from '../../../../styles/Variables';
import PlusIcon from '../../../../Icons/Plus.icon';
import serverEvents from '../../../../services/serverEvents';

type Props = {
  buttonId: string;
  clientId: number | null;
  loadCategories: (clientId: number) => void;
};

const AddNewCategoryButton: FunctionComponent<Props> = ({ buttonId, clientId, loadCategories }) => {
  const { showOverlay, overlay } = useOverlay();

  useEffect(() => {
    if (!clientId) {
      return;
    }

    loadCategories(clientId);
    const subscription = serverEvents.listen('/api/categories').subscribe((event) => {
      loadCategories(clientId);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [overlay]);

  return (
    <Button
      id={buttonId}
      $text={'Add new'}
      onClick={() => {
        showOverlay({ body: <CategoryForm clientId={clientId} /> });
      }}
      kind={'link'}
      $icon={<PlusIcon />}
      iconColor={color.primary['80']}
    />
  );
};

export default AddNewCategoryButton;
