import React, { FunctionComponent } from 'react';
import * as Styled from '../Dropzone.styled';
import UploadIcon from '../../../../Icons/upload.icon';
import { color } from '../../../../styles/Variables';
import { Icon } from 'components/atoms/Icon/Icon';

interface OwnProps {
  isDragActive: boolean;
  simple?: boolean;
  text?: string;
}

type Props = OwnProps;

const Default: FunctionComponent<Props> = ({ isDragActive, simple, text = false }) => {
  return (
    <Styled.Container $active={isDragActive} $simple={simple}>
      {!simple && (
        <Icon color={color.primary[60]} size={3}>
          <UploadIcon />
        </Icon>
      )}
      <Styled.DragDropText>{text ?? 'DROP files or CLICK to search'}</Styled.DragDropText>
    </Styled.Container>
  );
};

export default Default;
