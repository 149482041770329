import styled, { css } from 'styled-components';
import { color } from '../../../styles/Variables';
import { RichTextStyles } from '../../atoms/RichText/RichText.styled';

export const Comment = styled.div<{ $highlighted?: boolean }>`
  width: 100%;
  max-width: 72rem;
  * {
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
  ${(props) =>
    props.$highlighted &&
    css`
      background-color: ${color.primary['20']};
      border-radius: 0.5rem;
      padding: 0.3rem;
    `}
`;

export const Message = styled.div`
  margin-left: 4rem;
  font-size: 1.4rem;
  line-height: 24px;
  padding-right: 7rem;
  ${RichTextStyles};
`;

export const Attachments = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin: 2rem 0 0 4rem;
`;
