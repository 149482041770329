import React, { FunctionComponent, ReactNode } from 'react';
import { ChildrenWrapper, InfoBoxWrapper } from './InfoBox.styled';
import Heading from '../../atoms/Heading/Heading';
import { color } from '../../../styles/Variables';
import { Grid } from '../../atoms/Grid/Grid';

type Props = {
  title: string;
  children: ReactNode;
};

const InfoBox: FunctionComponent<Props> = ({ children, title }) => {
  return (
    <InfoBoxWrapper>
      <Heading children={title} color={color.semantic.success[80]} size={'s'} />
      <ChildrenWrapper>
        <Grid spacing={2}>{children}</Grid>
      </ChildrenWrapper>
    </InfoBoxWrapper>
  );
};

export default InfoBox;
