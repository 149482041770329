import React from 'react';
import { createContainer } from 'unstated-next';

type SnackType = {
  title: React.ReactNode | string;
  message: React.ReactNode | string;
  type?: 'info' | 'warning' | 'success' | 'error';
};

type SnackContainerType = {
  snack: SnackType | null;
  showSnack: (modal: SnackType) => void;
  dismissSnack: () => void;
};

export const useModalContainer = (): SnackContainerType => {
  const [content, setContent] = React.useState<SnackType | null>(null);

  const showSnack = (snack: SnackType) => {
    setContent(snack);
    setTimeout(() => setContent(null), 4000);
  };
  const dismissSnack = () => setContent(null);

  return {
    snack: content,
    showSnack,
    dismissSnack,
  };
};

const SnackContainer = createContainer<SnackContainerType>(useModalContainer);
export default SnackContainer;
