import React, { FunctionComponent, useContext } from 'react';
import TaskFormContext from './TaskFormContext';
import TaskShortForm from '../../components/organisms/Task/Form/TaskShortForm/TaskShortForm';
import TaskLongForm from '../../components/organisms/Task/Form/TaskLongForm/TaskLongForm';

type Props = {};

const TaskFormContainer: FunctionComponent<Props> = (props) => {
  const { isOpen } = useContext(TaskFormContext);
  return (
    <>
      {isOpen === 'short' && <TaskShortForm />}
      {isOpen === 'long' && <TaskLongForm />}
    </>
  );
};

export default TaskFormContainer;
