import React, { FunctionComponent } from 'react';
import SnackContainer from './use-snack-container';
import * as Components from './SnackBar.typed';
import SuccessIcon from '../../../../Icons/success.icon';
import CloseIcon from '../../../../Icons/close.icon';
import ErrorIcon from '../../../../Icons/error.icon';
import InfoIcon from '../../../../Icons/info.icon';
import WarningIcon from '../../../../Icons/warning.icon';
import { Icon } from '../../../../components/atoms/Icon/Icon';
import { color } from '../../../../styles/Variables';
import { Grid } from '../../../../components/atoms/Grid/Grid';

interface OwnProps {}

type Props = OwnProps;

const SnackBarColors = {
  success: color.semantic.success,
  error: color.semantic.error,
  info: color.primary,
  warning: color.semantic.warning,
};

const SnackBar: FunctionComponent<Props> = (props) => {
  const { snack, dismissSnack } = SnackContainer.useContainer();
  const [_color, set_color] = React.useState(SnackBarColors.success);
  const [icon, setIcon] = React.useState<React.ReactElement>(<SuccessIcon />);

  React.useEffect(() => {
    if (!snack) {
      return;
    }
    if (snack.type) {
      set_color(SnackBarColors[snack.type]);
    } else {
      set_color(SnackBarColors.success);
    }
  }, [snack]);

  React.useEffect(() => {
    switch (snack?.type) {
      case 'error':
        setIcon(<ErrorIcon />);
        break;
      case 'info':
        setIcon(<InfoIcon />);
        break;
      case 'warning':
        setIcon(<WarningIcon />);
        break;
      case 'success':
      default:
        setIcon(<SuccessIcon />);
    }
  }, [snack]);

  return (
    <Components.Container>
      {snack && (
        <Components.Item>
          <Components.IconWrapper color={_color[40]}>
            <Icon color={_color[60]} size={2}>
              {icon}
            </Icon>
          </Components.IconWrapper>
          <Components.Wrapper>
            <Grid $simple>
              <Components.Title color={_color[60]}>{snack.title}</Components.Title>
              <Components.Message color={_color[60]}>{snack.message}</Components.Message>
            </Grid>
            <Components.Close onClick={dismissSnack}>
              <Icon color={color.neutral[100]}>
                <CloseIcon />
              </Icon>
            </Components.Close>
          </Components.Wrapper>
        </Components.Item>
      )}
    </Components.Container>
  );
};

export default SnackBar;
