import React, { FunctionComponent } from 'react';
import Container from '../../../../components/atoms/Container/Container';
import { Route, Routes } from 'react-router-dom';
import PurchaseOrdersList from '../../../../components/organisms/Tables/PurchaseOrdersList/PurchaseOrdersList';
import PurchaseOrderForm from '../../../../components/templates/PurchaseOrders/PurchaseOrderForm/PurchaseOrderForm';
import PurchaseOrderFormEditView from '../../../../components/templates/PurchaseOrders/PurchaseOrderForm/PurchaseOrderFormEditView';
import PurchaseOrderShowView from '../../../../components/templates/PurchaseOrders/PurchaseOrderShow/PurchaseOrderShowView';
import { Helmet } from 'react-helmet';

type Props = {};

const PurchaseOrdersScreen: FunctionComponent<Props> = (props) => {
  return (
    <Container>
      <Helmet>
        <title>Purchase Orders | F.CAPE</title>
      </Helmet>
      <Routes>
        <Route path="" element={<PurchaseOrdersList />}></Route>
        <Route path="create" element={<PurchaseOrderForm />}></Route>
        <Route path=":id/edit" element={<PurchaseOrderFormEditView />}></Route>
        <Route path=":id/show" element={<PurchaseOrderShowView />}></Route>
      </Routes>
    </Container>
  );
};

export default PurchaseOrdersScreen;
