import { components, OptionProps } from 'react-select';
import React, { FunctionComponent } from 'react';
import { Grid, GridItem } from '../../atoms/Grid/Grid';
import Text from '../../atoms/Text';
import { fontWeight } from '../../../styles/Variables';
import { PurchaseOrderType } from '../../../@types/Finances/PurchaseOrder/PurchaseOrderType';

type Props = OptionProps<{ value: string; label: string; meta: PurchaseOrderType }, false>;
const PurchaseOrderLabel: FunctionComponent<Props> = (props) => {
  return (
    <components.SingleValue {...props}>
      <div style={{ display: 'flex', gap: '0.3rem' }}>
        <Text as={'span'} color={'primary'} bold>
          #{props.data.meta.number ?? 'N/A'}&nbsp;
        </Text>{' '}
        {props.data.meta.title}
      </div>
    </components.SingleValue>
  );
};
export default PurchaseOrderLabel;
