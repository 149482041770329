import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import * as Styled from './WikiSearch.styled';
import { TagType } from '../../../../../@types/Tag/Tag';
import api from '../../../../../services/api';
import { ArrayResponseType } from '../../../../../@types/hydra/hydra';
import { SimpleArticleType } from '../../../../../@types/Article/ArticleType';
import Select, { OnChangeValue } from 'react-select';
import styles from './WikiSearch.styles';
import WikiSearchOption from './WikiSearchOption';
import { CustomOptionType } from '../../../../../@types/Select/CustomOptionsType';
import { EntityType } from '../../../../../@types/Entity/EntityType';

type Props = {
  onTagsChange: (tags: string[]) => void;
  onArticleChange: (fullPath: string) => void;
};

export type WikiSearchOptionType = SimpleArticleType | TagType;

const WikiSearch: FunctionComponent<Props> = (props) => {
  const [tags, setTags] = useState<TagType[] | undefined>();
  const [articles, setArticles] = useState<SimpleArticleType[] | undefined>();
  const isReady = useMemo(() => {
    return tags !== undefined && articles !== undefined;
  }, [tags, articles]);

  const options:
    | any[]
    | [
        { options: { label: string; value: string; object: EntityType & { value: string } }[]; label: string },
        { options: { label: string; value: string; object: EntityType & { name: string; fullPath: string; pathString: string } }[]; label: string },
      ] = useMemo(() => {
    if (!tags || !articles) {
      return [];
    }

    return [
      {
        label: 'Tags',
        options: tags.map((t) => ({ value: t.value, label: t.value, object: t })),
      },
      {
        label: 'Articles',
        options: articles.map((article) => ({
          value: article.fullPath,
          label: article.name,
          object: article,
        })),
      },
    ];
  }, [tags, articles]);

  const loadTags = useCallback(() => {
    api.get<ArrayResponseType<TagType>>('/project_tags').then((response) => {
      setTags(response.data['hydra:member']);
    });
  }, []);

  const loadArticles = useCallback(() => {
    api.get<ArrayResponseType<SimpleArticleType>>('/wiki/simple/articles').then((response) => setArticles(response.data['hydra:member']));
  }, []);

  const handleChange = useCallback((values: OnChangeValue<CustomOptionType<WikiSearchOptionType, string>, true>) => {
    if (!values) {
      props.onTagsChange([]);
      return;
    }
    if (!Array.isArray(values)) {
      return;
    }
    if (values.length === 0) {
      props.onTagsChange([]);
      return;
    }
    const lastIndex = values.length - 1;
    if (values[lastIndex].object['@type'] === 'Article') {
      props.onArticleChange(values[lastIndex].object.fullPath);
      values.splice(0, values.length);
    } else {
      props.onTagsChange(values.map((v) => v.value));
    }
  }, []);

  useEffect(() => {
    loadTags();
    loadArticles();
  }, []);

  return (
    <Styled.WikiSearchContainer id={'wiki-search-input'}>
      {isReady && (
        <Select
          styles={styles}
          options={options}
          isMulti
          onChange={handleChange}
          components={{
            IndicatorSeparator: null,
            Option: WikiSearchOption,
          }}
        />
      )}
    </Styled.WikiSearchContainer>
  );
};

export default WikiSearch;
