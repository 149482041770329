import React, { FunctionComponent } from 'react';
import * as Styled from './Arrow.styled';
import { color as colors } from '../../../styles/Variables';

export type ArrowDirection = 'right' | 'left' | 'up' | 'down';

type Props = {
  direction?: ArrowDirection;
  small?: boolean;
  color?: 'primary' | 'neutral' | string;
};

const Arrow: FunctionComponent<Props> = ({ direction = 'right', small = false, color = 'neutral' }) => {
  return (
    <Styled.ArrowContainer $direction={direction}>
      <svg width={small ? undefined : 6} height={small ? 8 : 10} viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 0.5L4.38971 3.63657C5.20343 4.38951 5.20343 5.61049 4.38971 6.36343L1 9.5"
          stroke={color === 'primary' ? colors.primary[80] : color === 'neutral' ? colors.neutral[60] : color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Styled.ArrowContainer>
  );
};

export default Arrow;
