import styled from 'styled-components';
import { color } from '../../../../styles/Variables';

export const MyWorkWrapper = styled.div<{ $extraPadding?: boolean }>`
  height: calc(100vh - ${(props) => (props.$extraPadding ? '220px' : '160px')});
`;

export const Head = styled.div`
  position: relative;
  height: 8.4rem;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-bottom: 1px solid ${color.neutral[40]};

  div {
    padding: 0 0.4rem;
  }
`;

export const Body = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  height: calc(100% - 8.4rem);
`;

export const Actions = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-100%);
  display: flex;
  gap: 2rem;
`;
