import React, { FunctionComponent } from 'react';
import { Grid } from 'components/atoms/Grid/Grid';
import ModalForm from '../../Form/ModalForm/ModalForm';
import { TextInput } from '../../../molecules/Form/TextInput/TextInput';
import { ResourceType } from '../../../../@types/Resource/ResourceType';

type Props = {
  resource: ResourceType;
  onSave: (resource: ResourceType) => void;
};

const RenameResourceModal: FunctionComponent<Props> = ({ resource, onSave }) => {
  const handleChange = (resource: ResourceType) => {
    onSave(resource);
  };

  return (
    <ModalForm basePath={resource['@id']} buttonText={'Save'} defaults={resource} onChange={handleChange}>
      <Grid spacing={1.5} $simple>
        <TextInput name={'name'} label={'New name'} />
      </Grid>
    </ModalForm>
  );
};

export default RenameResourceModal;
