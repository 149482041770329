import React, { FunctionComponent } from 'react';
import Button from '../../../../atoms/Button/Button';
import InventoryItemType from '../../../../../@types/Inventory/InventoryItemType';
import useApi from '../../../../../hooks/useApi';

type Props = {
  entity: InventoryItemType;
};

const ReturnButton: FunctionComponent<Props> = ({ entity }) => {
  const { post } = useApi();
  return (
    <Button
      $text={'Return'}
      onClick={() => {
        let formData = {
          person: entity.assignedTo['@id'],
          type: 'return',
          items: [entity['@id']],
        };
        return post('/api/inventory_allocations', formData);
      }}
    />
  );
};

export default ReturnButton;
