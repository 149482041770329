import React from 'react';
import { TextSizeType } from '../../../../@types/Text/TextSizeType';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import { CellTemplate } from '../CellTemplate';
import { ContractType } from '../../../../@types/Finances/Contracts/ContractType';
import { ColorType } from '../../../../@types/Color/ColorType';
import { getContractAppliesLabel } from '../../../../@dicts/Contract/ContractAppliesDict';
import Text from '../../../atoms/Text/Text';
import Person from '../../Person/Person';
import ProjectSingleDetail from '../../SingleDetail/ProjectSingleDetail/ProjectSingleDetail';
import PurchaseOrderSingleDetail from '../../SingleDetail/PurchaseOrderSingleDetail/PurchaseOrderSingleDetail';
import IdText from '../../IdText/IdText';

const ContractItemDetailsTemplate: CellTemplate<
  ContractType,
  { textProperties?: { bold?: boolean; size?: TextSizeType; color?: ColorType; shorten?: number } },
  ContractType
> = ({ value, options, item }) => {
  return (
    <Grid $align={'center'} spacing={0.3}>
      <GridItem $desktop={12}>
        <Grid spacing={0.3}>
          <GridItem $desktop={12}>
            <Grid spacing={0.3}>
              <GridItem>
                <Text size={'s'} bold color={'grey'}>
                  Applies:
                </Text>
              </GridItem>
              <GridItem $desktop={'max'}>
                <Text size={'s'} bold color={'semiGrey'}>
                  {getContractAppliesLabel(item.applies)}
                </Text>
              </GridItem>
            </Grid>
            <div style={{ paddingBottom: '0.3rem', width: '30%', marginBottom: '0.3rem', borderBottom: '1px solid lightGrey' }}></div>
          </GridItem>
          {item.detailsPartner && (
            <GridItem $desktop={12}>
              <Grid spacing={0.3}>
                <GridItem>
                  <Text size={'xs'} bold color={'grey'}>
                    Partner:
                  </Text>
                </GridItem>
                <GridItem style={{ display: 'flex', gap: '0.3rem' }}>
                  <IdText withHash={true} value={item.detailsPartner.id} size={'xs'} />
                  <Text size={'xs'} bold color={'semiGrey'}>
                    {item.detailsPartner.name}
                  </Text>
                </GridItem>
              </Grid>
            </GridItem>
          )}
          {item.detailsEmployee && (
            <GridItem $desktop={12}>
              <Grid spacing={0.3}>
                <GridItem style={{ display: 'flex', gap: '0.3rem' }}>
                  <Person person={item.detailsEmployee} textSize={'xs'} textColor={'semiGrey'} bold />
                </GridItem>
              </Grid>
            </GridItem>
          )}
          {item.detailsProject && (
            <GridItem $desktop={12}>
              <ProjectSingleDetail name={item.detailsProject.name} id={item.detailsProject.id} hideTitle size={'xs'} bold color={'semiGrey'} />
            </GridItem>
          )}
          {item.detailsPurchaseOrder && (
            <GridItem $desktop={12}>
              <PurchaseOrderSingleDetail purchaseOrder={item.detailsPurchaseOrder} hideTitle size={'xs'} bold color={'semiGrey'} />
            </GridItem>
          )}
          {item.parentContract && <div style={{ paddingBottom: '0.3rem', width: '30%', marginBottom: '0.3rem', borderBottom: '1px solid lightGrey' }}></div>}

          {item.parentContract && (
            <GridItem $desktop={12}>
              <Grid spacing={0.3}>
                <GridItem>
                  <Text size={'xs'} bold color={'grey'}>
                    Parent contract:
                  </Text>
                </GridItem>
                <GridItem style={{ display: 'flex', gap: '0.3rem' }}>
                  <IdText withHash={true} value={item.parentContract.id} size={'xs'} />
                  <Text size={'xs'} bold color={'semiGrey'}>
                    {item.parentContract.title}
                  </Text>
                </GridItem>
              </Grid>
            </GridItem>
          )}
        </Grid>
      </GridItem>
    </Grid>
  );
};
export default ContractItemDetailsTemplate;
