import styled from 'styled-components';

export const MainWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  width: 50vw;
`;
