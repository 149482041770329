import React, { FunctionComponent, useCallback, useContext } from 'react';
import { NavigationNodeType } from '../../../@types/NavigationTree/NavigationTreeType';
import NavigationNode from './NavigationNode';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../Context/auth-context/auth-context';
import { Grid, GridItem } from '../../atoms/Grid/Grid';
import * as Styled from './NavigationNode.styled';
import Button from '../../atoms/Button/Button';
import Chip from '../../atoms/Chips/Chip';

type Props = {
  tree: NavigationNodeType[];
  slugs: string[];
  tags: string[];
};

const NavigationTree: FunctionComponent<Props> = ({ tree, slugs, tags }) => {
  const navigate = useNavigate();
  const { can } = useContext(AuthContext);

  const onButtonClick = useCallback(() => {
    navigate('/projects/wiki/categories/create');
  }, []);

  return (
    <Grid spacing={2}>
      {tags.length > 0 && (
        <GridItem $desktop={12}>
          <Styled.TreeHeading>Selected tags:</Styled.TreeHeading>
          <Grid spacing={0.6}>
            {tags.map((tag) => (
              <GridItem>
                <Chip key={tag}>{tag}</Chip>
              </GridItem>
            ))}
          </Grid>
        </GridItem>
      )}
      <GridItem $desktop={12}>
        {tree.map((node) => (
          <NavigationNode {...node} key={node.slug} currentSlugs={slugs} prefix={'/projects/wiki'} isMainCategory={true} isProcess={node.isProcess} />
        ))}
      </GridItem>
      {can('WIKI.CAN_MANAGE') && (
        <GridItem $desktop={'auto'}>
          <Button kind={'primary'} onClick={onButtonClick} $text={'Create category'} />
        </GridItem>
      )}
    </Grid>
  );
};

export default NavigationTree;
