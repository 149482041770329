import styled, { css } from 'styled-components';
import { color, fontSize, fontWeight } from '../../../../../styles/Variables';
import { UiText } from '../../../../../styles/Typography';

export const Handler = styled.div<{
  $shape: 'square' | 'circle' | undefined;
  $filled: boolean;
  $size: 'lg' | 'sm' | undefined;
}>`
  border: 1px dashed ${color.neutral[60]};
  border-radius: ${(p) => (p.$shape === 'square' ? '8px' : '50%')};
  ${(props) =>
    props.$size === 'lg' &&
    css`
      height: 36px;
      width: 36px;
    `}
  ${(props) =>
    props.$size === 'sm' &&
    css`
      height: 24px;
      width: 24px;
    `}
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border 0.1s ease-out;
  ${(p) =>
    p.$filled &&
    css`
      border-style: solid;
    `}
`;

export const Container = styled.div`
  ${UiText};
  border-radius: 8px;
  font-size: ${fontSize.body.m};
  font-weight: ${fontWeight.bold};
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  color: ${color.neutral[60]};
  transition: all 0.1s ease-out;
  border: 1px solid white;
  box-sizing: content-box;

  .person-icon {
    transform: scale(0.9);
  }

  :hover {
    color: ${color.neutral[80]};

    ${Handler} {
      border-color: ${color.neutral[80]};
    }
  }

  :focus {
    border-color: 1px solid ${color.primary[40]};
    outline: ${color.primary[30]} solid 1px;
  }

  :active {
    outline: ${color.primary[50]} solid 1px;
  }
`;

export const Label = styled.div<{ noMargin?: boolean }>`
  margin-left: 1.2rem;
  ${(p) =>
    p.noMargin &&
    css`
      margin-left: 0;
    `}
`;
export const Value = styled(Label)`
  color: ${color.neutral[80]};
`;

export const Dropdown = styled.div<{
  visible?: boolean;
  dropdownOnTop?: boolean | undefined;
  translateXValue?: string | undefined;
}>`
  z-index: 1000;
  border: 1px solid ${color.neutral[40]};
  border-radius: 8px;
  background: white;
  position: absolute;
  top: 102%;
  left: calc(50% - 10rem);
  width: fit-content;
  min-width: 9rem;
  max-width: 25rem;
  padding: 0.5rem;
  ${(props) =>
    props.translateXValue &&
    css`
      left: ${props.translateXValue};
    `};
  ${(props) =>
    props.dropdownOnTop &&
    css`
      top: -150%;

      .css-tlfecz-indicatorContainer,
      .css-1gtu0rj-indicatorContainer {
        rotate: 180deg;
      }
    `}

  ${(props) =>
    props.visible === false &&
    css`
      display: none;
    `}
`;
