import { ProjectListItemType } from '../../../../@types/Project/Project';
import { TableProperty } from '../../../../@types/Table/TableProperty';
import MoneyTemplate from '../../../../components/molecules/CellTemplates/common/MoneyTemplate/MoneyTemplate';
import PersonTemplate from '../../../../components/molecules/CellTemplates/common/PersonTemplate/PersonTemplate';
import ProjectProgressTemplate from '../../../../components/molecules/CellTemplates/Projects/Projects/ProjectProgressTemplate';
import ProjectStatusTemplate from '../../../../components/molecules/CellTemplates/Projects/Projects/ProjectStatusTemplate';
import MarginTemplate from '../../../../components/molecules/CellTemplates/Projects/Projects/MarginTemplate';
import WorkTimeTemplate from '../../../../components/molecules/CellTemplates/Projects/Projects/WorkTimeTemplate/WorkTimeTemplate';
import ProjectDatesTemplate from '../../../../components/molecules/CellTemplates/Projects/Projects/ProjectDatesTemplate';
import ProjectNPSTemplate from '../../../../components/molecules/CellTemplates/Projects/Projects/ProjectNPSTemplate';
import ProjectTemplate from '../../../../components/molecules/CellTemplates/common/ProjectTemplate/ProjectTemplate';

const onFinancesClick = (item: ProjectListItemType) => {
  window.open(`/projects/projects/${item.id}/finances`);
};

export const properties: TableProperty[] = [
  {
    name: 'status',
    visibleName: 'Status',
    template: ProjectStatusTemplate,
  },
  {
    name: 'id',
    visibleName: 'Project',
    template: ProjectTemplate,
    sortable: true,
  },
  {
    name: 'leader',
    visibleName: 'Leader',
    template: PersonTemplate,
    templateOptions: {
      areInitials: true,
      textProperties: {
        size: 's',
        color: 'grey',
        bold: true,
      },
    },
  },

  {
    name: 'createdAt',
    visibleName: 'Dates',
    template: ProjectDatesTemplate,
    sortable: true,
  },
  {
    name: 'totalWorkTime',
    visibleName: 'Work time',
    template: ProjectProgressTemplate,
  },
  {
    name: 'developmentWork',
    visibleName: 'Hours',
    template: WorkTimeTemplate,
    templateOptions: {
      minWidth: 3,
    },
  },
  {
    name: 'incomesTotal',
    visibleName: 'Incomes',
    template: MoneyTemplate,
    justifyContent: 'flex-end',
    sortable: 'incomesTotal.value',
    sumBy: 'incomesTotal',
    onClick: onFinancesClick,
    title: 'Incomes',
    templateOptions: {
      textProperties: {
        size: 'm',
        color: 'darkGrey',
        bold: true,
      },
    },
  },

  {
    name: 'costsTotal',
    visibleName: 'Ext. costs',
    template: MoneyTemplate,
    justifyContent: 'flex-end',
    sortable: 'costsTotal.value',
    sumBy: 'costsTotal',
    onClick: onFinancesClick,
    title: 'External costs',
    templateOptions: {
      textProperties: {
        size: 'm',
        color: 'grey',
        bold: true,
      },
    },
  },
  {
    name: 'revenue',
    visibleName: 'Revenue',
    template: MoneyTemplate,
    justifyContent: 'flex-end',
    sortable: 'revenue.value',
    sumBy: 'revenue',
    onClick: onFinancesClick,
    title: 'Incomes minus costs',
    templateOptions: {
      textProperties: {
        size: 'm',
        color: 'darkGrey',
        bold: true,
      },
    },
  },
  {
    name: 'workCostsTotal',
    visibleName: 'Team costs',
    template: MoneyTemplate,
    justifyContent: 'flex-end',
    sortable: 'workCostsTotal.value',
    sumBy: 'workCostsTotal',
    onClick: onFinancesClick,
    title: 'Costs generated by team',
    templateOptions: {
      textProperties: {
        size: 'm',
        color: 'grey',
        bold: true,
      },
    },
  },
  {
    name: 'profit',
    visibleName: 'Profit',
    templateOptions: {
      colored: true,
      textProperties: {
        size: 'm',
        color: 'darkGrey',
        bold: true,
      },
    },
    template: MoneyTemplate,
    justifyContent: 'flex-end',
    sortable: 'profit.value',
    sumBy: 'profit',
    onClick: onFinancesClick,
    title: 'Revenue minus team cost',
  },
  {
    name: 'margin',
    visibleName: 'DM',
    template: MarginTemplate,
    justifyContent: 'flex-end',
    sortable: 'margin',
    onClick: onFinancesClick,
    title: 'Revenue divided by profit',
  },
  {
    name: 'nps',
    visibleName: 'NPS',
    template: ProjectNPSTemplate,
    justifyContent: 'flex-end',
    sortable: 'margin',
    templateOptions: {
      textProperties: {
        size: 'm',
        color: 'darkGrey',
        bold: true,
      },
    },
  },
];
