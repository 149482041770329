import { BodyText } from '../../../../styles/Typography';
import { color } from '../../../../styles/Variables';
import styled, { css } from 'styled-components';

export const Avatar = styled.div`
  margin-left: 1rem;
  width: 32px;
  height: 32px;
  border-radius: 11px;
  overflow: hidden;
  img {
    object-fit: cover;
    object-position: 50% 50%;
    width: 100%;
    height: 100%;
  }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Welcome = styled.div<{ $isImpersonating: boolean }>`
  ${BodyText};

  ${(props) =>
    props.$isImpersonating &&
    css`
      color: ${color.additionals.purple};
    `}

  span {
    font-weight: bold;
  }
`;
