import { TableProperties } from '../../../@types/Table/TableProperty';
import InventoryItemType from '../../../@types/Inventory/InventoryItemType';
import StringTemplate from '../../molecules/CellTemplates/common/StringTemplate/StringTemplate';
import InventoryIdTemplate from '../../molecules/CellTemplates/Admin/InventoryIdTemplate';
import PersonTemplate from '../../molecules/CellTemplates/common/PersonTemplate/PersonTemplate';
import GuaranteeTemplate from '../../molecules/CellTemplates/Admin/GuaranteeTemplate';

const properties: TableProperties<InventoryItemType> = [
  {
    name: '@id',
    visibleName: 'ID',
    template: InventoryIdTemplate,
  },

  {
    name: 'type.name',
    visibleName: 'Type',
    template: StringTemplate,
  },

  {
    name: 'serialNumber',
    visibleName: 'Serial number',
    template: StringTemplate,
  },
  {
    name: 'createdBy',
    visibleName: 'Assigned by',
    template: PersonTemplate,
    templateOptions: { areInitials: false },
  },
  {
    name: 'warrantyDate',
    visibleName: 'Guarantee',
    template: GuaranteeTemplate,
  },
];

export default properties;
