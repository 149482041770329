import React, { FunctionComponent } from 'react';
import * as Styled from './FormError.styled';
import useFormError from '../../../../hooks/Form/useFormError';

type Props = {
  name: string;
};

const FormError: FunctionComponent<Props> = ({ name }) => {
  const error = useFormError(name);

  return (
    <div style={{ position: 'relative' }}>
      <Styled.FormErrorContainer $visible={!!error}>{error}</Styled.FormErrorContainer>
    </div>
  );
};

export default FormError;
