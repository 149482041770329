import styled from 'styled-components';
import { Card } from '../../atoms/Card/Card';
import { color } from '../../../styles/Variables';

export const InfoBoxWrapper = styled(Card)`
  background-color: ${color.semantic.success[20]};
  margin-bottom: 3.6rem;
`;

export const ChildrenWrapper = styled.div`
  margin-top: 1.6rem;
`;
