import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import * as Styled from './Timeline.styled';
import TimelineEvent from './TimelineEvent/TimelineEvent';
import Heading from '../../../../../atoms/Heading';
import { ProjectEventType } from '../../../../../../@types/Project/ProjectEventType';
import AddDateIcon from '../../../../../../Icons/AddDate.icon';
import { ModalContext } from '../../../../../../Context/ModalContext/ModalContext';
import TimelineModal from './TimelineModal/TimelineModal';
import Button from '../../../../../atoms/Button/Button';
import api from '../../../../../../services/api';
import { ArrayResponseType } from '../../../../../../@types/hydra/hydra';
import { ProjectType } from '../../../../../../@types/Project/Project';
import { Loader } from '../../../../../../legacy/CapeMorris/components';

type Props = {
  events: ProjectEventType[];
  projectIri: string;
  onChange: () => void;
};

const Timeline: FunctionComponent<Props> = ({ projectIri, onChange }) => {
  const { showModal } = useContext(ModalContext);
  const [events, setEvents] = useState<ProjectEventType[] | undefined>();

  useEffect(() => {
    setEvents(undefined);
    api.get<ProjectType>(`${projectIri}/events`).then((response) => {
      setEvents(response.data.events);
    });
  }, [projectIri]);

  const openProjectEventModal = useCallback((editedEvent?: ProjectEventType) => {
    showModal({
      title: editedEvent ? 'Edit event' : 'Add event',
      body: <TimelineModal projectId={projectIri} event={editedEvent} onChange={onChange} />,
      width: 42,
    });
  }, []);

  return (
    <Styled.Timeline>
      {!events && <Loader />}
      <Styled.HeadingWrapper>
        <Heading>Timeline</Heading>
        <Button onClick={() => openProjectEventModal()} $text={'Add milestone'} $icon={<AddDateIcon />} />
      </Styled.HeadingWrapper>
      <Styled.TimelineEvents>
        {events &&
          events
            .filter((e) => e.type !== 'role')
            .map((event, index) => (
              <TimelineEvent key={index} event={event} isLastEvent={event.type === 'start'} openProjectEventModal={openProjectEventModal} onChange={onChange} />
            ))}
      </Styled.TimelineEvents>
    </Styled.Timeline>
  );
};

export default Timeline;
