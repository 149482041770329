import React from 'react';
import { CellTemplate } from '../../CellTemplate';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import { ContractorType } from '../../../../../@types/Finances/Contractor/ContractorType';
import Text from '../../../../atoms/Text';
import Chip from '../../../../atoms/Chips/Chip';
import { TextSizeType } from '../../../../../@types/Text/TextSizeType';
import { ColorType } from '../../../../../@types/Color/ColorType';
import EmptyCell from '../../common/EmptyCell';

const ContractorItemTaxTemplate: CellTemplate<
  string | number,
  { textProperties?: { bold?: boolean; size?: TextSizeType; color?: ColorType } },
  ContractorType
> = ({ value, options, item }) => {
  return (
    <Grid $align={'center'} spacing={0.3}>
      {value && (
        <>
          <GridItem $desktop={12}>
            <Text
              color={options?.textProperties?.color ?? 'darkGrey'}
              size={options?.textProperties?.size ?? 'l'}
              bold={options?.textProperties?.bold ?? false}
            >
              {value ?? `${item.firstName} ${item.lastName}`}
            </Text>
          </GridItem>
          {item.taxNumber && (
            <GridItem>
              <Chip $size={'l'}>{item.taxNumber}</Chip>
            </GridItem>
          )}
        </>
      )}
      {!value && <EmptyCell />}
    </Grid>
  );
};
export default ContractorItemTaxTemplate;
