import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { ContractorType } from '../../../../@types/Finances/Contractor/ContractorType';
import { Loader } from '../../../../legacy/CapeMorris/components';
import useEntity from '../../../../hooks/useEntity';
import ContractorForm from './ContractorForm';

type Props = {};

const ContractorFormEditView: FunctionComponent<Props> = () => {
  let { id } = useParams<{ id: string }>();
  const { entity, loading, reload } = useEntity<ContractorType>(`/api/contractors/${id}`);

  return (
    <>
      {loading && <Loader />}
      {entity && <ContractorForm contractor={entity} onChange={reload} />}
    </>
  );
};

export default ContractorFormEditView;
