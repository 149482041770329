import React, { FunctionComponent, useCallback, useState } from 'react';
import Form from '../../../../../molecules/Form/Form';
import DynamicSelectInput from '../../../../../molecules/Form/SelectInput/DynamicSelectInput';
import ProjectOption from '../../../../../molecules/Projects/ProjectOption/ProjectOption';
import Text from '../../../../../atoms/Text';
import Button from '../../../../../atoms/Button/Button';
import { ModalFooter } from '../../../../../molecules/Modal/Modal';
import api from '../../../../../../services/api';
import useModal from '../../../../../../hooks/useModal';
import { TimesheetEntryType } from '../../../../../../@types/TimesheetDay/TimesheetEntryType';

type Props = {
  entries: TimesheetEntryType[];
  onFinished: () => void;
};

const MoveTimesheetsModal: FunctionComponent<Props> = ({ entries, onFinished }) => {
  const { hideModal } = useModal();
  const [wait, setWait] = useState(false);
  const handleSubmit = useCallback((data: any) => {
    setWait(true);
    api
      .post('/move_timesheet_entries', {
        project: data.project,
        entries: entries.map((entry) => entry.id),
      })
      .finally(() => {
        setWait(false);
        hideModal();
        onFinished();
      });
  }, []);
  return (
    <Form onSubmit={handleSubmit}>
      <DynamicSelectInput placeholder={'Select target project'} name={'project'} baseUrl={'/my-projects'} optionComponent={ProjectOption} />
      <Text color={'grey'} style={{ paddingTop: '2rem' }}>
        Be informed that <strong>{entries.filter((e) => !!e.task).length}</strong> entries with tasks will be ignored. To move these timesheets you need to use
        "move task" functionality.
      </Text>
      <ModalFooter>
        <Button $text={`Move ${entries.length} entries`} type={'submit'} waiting={wait} />
      </ModalFooter>
    </Form>
  );
};

export default MoveTimesheetsModal;
