import React, { CSSProperties, FunctionComponent, ReactElement, ReactNode } from 'react';
import { IconWrapper } from './Icon.styled';

type IconComponentProps = {
  children: ReactElement | ReactNode;
  color?: string;
  size?: number;
  style?: CSSProperties;
  onClick?: (e?: any) => void;
  className?: string;
  disabled?: boolean;
  reversedHover?: boolean;
};

export const Icon: FunctionComponent<IconComponentProps> = ({ children, color, size = 1, style, onClick, className, disabled = false, reversedHover }) => {
  return (
    <IconWrapper
      style={style}
      $color={color ? color : 'inherit'}
      $size={size}
      onClick={onClick}
      className={className}
      $disabled={disabled}
      $reversedHover={reversedHover}
    >
      {children}
    </IconWrapper>
  );
};
