import { InputProps } from '../../../../@types/Form/InputProps';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import useFormError from '../../../../hooks/Form/useFormError';
import { FormInputContainer, FormLabel, FormTextarea } from '../../../atoms/Form/InputParts/InputParts';
import { ErrorMessages } from '../../../atoms/Form/FormMessages/ErrorMessages';
import FormError from '../../../atoms/Form/FormError/FormError';

type TextAreaInputProps = InputProps & {
  rows?: number;
  style?: React.CSSProperties;
};

export const TextAreaInput: React.FC<TextAreaInputProps> = ({
  name,
  inputRef,
  label,
  placeholder,
  disabled = false,
  rows = 4,
  style,
  defaultValue,
  required = false,
  onChange,
}) => {
  const { register, getValues } = useFormContext();
  const error = useFormError(name);
  return (
    <>
      <FormInputContainer style={style} status={error && 'error'}>
        <FormLabel status={error && 'error'} required={required}>
          {label ? label : name}
        </FormLabel>
        <FormTextarea
          status={error && 'error'}
          id={name}
          onInput={() => (onChange ? onChange(getValues(name)) : null)}
          {...register(name, { required: required && ErrorMessages.required })}
          placeholder={placeholder ?? label ?? name}
          disabled={disabled}
          defaultValue={defaultValue}
          rows={rows}
        />
      </FormInputContainer>
      <FormError name={name} />
    </>
  );
};
