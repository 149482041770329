import React, { FunctionComponent } from 'react';
import Button from '../../../../atoms/Button/Button';
import InventoryItemType from '../../../../../@types/Inventory/InventoryItemType';
import useApi from '../../../../../hooks/useApi';
import { PiCurrencyEth } from 'react-icons/pi';

type Props = {
  entity: InventoryItemType;
};

const SoldButton: FunctionComponent<Props> = ({ entity }) => {
  const { put } = useApi();
  return (
    <Button
      $text={'Sold'}
      kind={'ghost'}
      $icon={<PiCurrencyEth />}
      onClick={() => {
        return put(`${entity['@id']}/sold`, { status: 'sold' });
      }}
    />
  );
};

export default SoldButton;
