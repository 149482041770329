import { components, OptionProps } from 'react-select';
import React, { FunctionComponent } from 'react';
import { Grid, GridItem } from '../../atoms/Grid/Grid';
import Text from '../../atoms/Text';
import { PurchaseOrderType } from '../../../@types/Finances/PurchaseOrder/PurchaseOrderType';
import BetterChip from '../../atoms/Chips/BetterChip';
import { PiStarBold } from 'react-icons/pi';

type Props = OptionProps<{ value: string; label: string; meta: PurchaseOrderType }, false>;
const ContractorOption: FunctionComponent<Props> = (props) => {
  return (
    <components.Option {...props}>
      <Grid $align={'center'} spacing={0.3}>
        <GridItem $desktop={12}>
          <Text>{props.data.meta.name}</Text>
        </GridItem>
        {props.data.meta.clientName && (
          <GridItem>
            <BetterChip icon={props.data.meta.isPrimary ? PiStarBold : undefined} color={'information'}>
              {props.data.meta.clientName}
            </BetterChip>
          </GridItem>
        )}
        {props.data.meta.taxNumber && (
          <GridItem>
            <BetterChip>{props.data.meta.taxNumber}</BetterChip>
          </GridItem>
        )}
      </Grid>
    </components.Option>
  );
};
export default ContractorOption;
