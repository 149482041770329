import styled, { css } from 'styled-components';
import { color } from '../../../styles/Variables';

type CardProps = {
  blueBorder?: boolean;
};

export const Card = styled.div<CardProps>`
  background-color: ${color.light};
  padding: 3.2rem;
  border-radius: 6px;
  border: 1px solid ${color.neutral['30']};

  ${(props: CardProps) =>
    props.blueBorder &&
    css`
      border: 1px solid ${color.primary['60']};
    `}

  ul,
  ol {
    margin-left: 2.4rem;
  }
`;
