import React, { FunctionComponent, useMemo } from 'react';
import * as Styled from './WorkloadTime.styled';
import Text from '../../../../../../components/atoms/Text/Text';
import { convertMinutesToHours } from '../../../../../../helpers/convertMinutesToHours';
import Tooltip from '../../../../../../components/molecules/Tooltip/Tooltip';
import { WorkloadDay } from '../../../../../../@types/Workload/WorkloadType';

type Props = {
  plannedTime: number;
  predictedTime: number;
  dayTime: number;
  dayEvent?: WorkloadDay['dayEvent'];
};

const WorkloadTime: FunctionComponent<Props> = ({ plannedTime, predictedTime, dayTime, dayEvent }) => {
  const progress = useMemo(() => {
    return ((plannedTime + Math.abs(predictedTime)) / dayTime) * 100;
  }, [plannedTime, predictedTime, dayTime]);
  const kind = useMemo(() => {
    if (progress > 100) {
      return 'negative';
    }
    if (progress < 70) {
      return 'warning';
    }
    return 'positive';
  }, [progress]);

  const hours = useMemo(() => {
    if (plannedTime === 0 && predictedTime === 0) {
      return `0h/${convertMinutesToHours(dayTime)}`;
    }
    return `${convertMinutesToHours(plannedTime + Math.abs(predictedTime))}/${convertMinutesToHours(dayTime)}`;
  }, [plannedTime, predictedTime, dayTime]);

  return (
    <div style={{ marginBottom: '1.6rem' }}>
      {dayEvent && (
        <Styled.Wrapper $kind={'positive'} $neutral>
          {dayEvent === 'vacation' && (
            <Text color={'grey'} size={'s'} bold>
              Vacation
            </Text>
          )}
          {dayEvent === 'holiday' && (
            <Text color={'grey'} size={'s'} bold>
              Holiday
            </Text>
          )}
          {dayEvent === 'sick_leave' && (
            <Text color={'grey'} size={'s'} bold>
              Sick leave
            </Text>
          )}
        </Styled.Wrapper>
      )}
      {!dayEvent && (
        <Tooltip
          content={
            <Styled.TooltipContent>
              <Text color={'light'} size={'s'}>
                Planned time: {convertMinutesToHours(plannedTime) ? convertMinutesToHours(plannedTime) : '0h'}
              </Text>
              <Text color={'light'} size={'s'}>
                Predicted time: {convertMinutesToHours(predictedTime) ? convertMinutesToHours(predictedTime) : '0h'}
              </Text>
            </Styled.TooltipContent>
          }
          trigger={
            <Styled.Wrapper $kind={kind}>
              <Text style={{ zIndex: 2 }} bold color={'light'} size={'xl'}>
                {hours}
              </Text>
              <Styled.Progress $kind={kind} $progress={progress}></Styled.Progress>
            </Styled.Wrapper>
          }
          arrowPosition={'topCenter'}
        />
      )}
    </div>
  );
};

export default WorkloadTime;
