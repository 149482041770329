import { DictValue } from '../../../@types/Dictionary/DictValue';
import { TableFiltersType } from '../../../@types/Table/TableFilterType';
import FrequentFilters from '../../../@types/Table/FrequentFilters';
import { TaskPriorityType, TaskStatusType } from '../../../@types/Task/TaskType';
import ProjectOption from '../../molecules/Projects/ProjectOption/ProjectOption';
import ProjectLabel from '../../molecules/Projects/ProjectLabel/ProjectLabel';

const statusOptions: DictValue<null, TaskStatusType | TaskStatusType[]>[] = [
  { value: ['todo', 'in_progress'], label: 'Not started & In progress' },
  { value: ['in_review', 'acceptance'], label: 'Review & Acceptance' },
];

const priorityOptions: DictValue<null, TaskPriorityType>[] = [
  { value: 'Low', label: 'Low' },
  { value: 'Normal', label: 'Normal' },
  { value: 'High', label: 'High' },
  { value: 'ASAP', label: 'ASAP' },
];

const filters: TableFiltersType = [
  {
    ...FrequentFilters._search,
    placeholder: 'Search by task, tasklist, project, author',
  },
  {
    type: 'select',
    name: 'project.id',
    label: 'Project',
    options: {
      baseUrl: '/api/projects',
      optionComponent: ProjectOption,
      singleValueComponent: ProjectLabel,
    },

    gridProps: {
      $desktop: 3,
    },
  },
  {
    type: 'select',
    name: 'status',
    label: 'Status',
    options: {
      options: statusOptions,
    },
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'select',
    name: 'priority',
    label: 'Priority',
    options: {
      options: priorityOptions,
    },
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'tags',
    name: 'tags',
    label: 'Tags',
    options: {
      baseUrl: '/task_tags',
    },
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'daterange',
    name: 'createdAt',
    label: 'Creation date range',
    gridProps: {
      $desktop: 3,
    },
  },
  {
    type: 'daterange',
    name: 'deadline',
    label: 'Deadline date range',
    gridProps: {
      $desktop: 3,
    },
  },
  {
    type: 'daterange',
    name: 'start',
    label: 'Start time',
    gridProps: {
      $desktop: 3,
    },
  },
  {
    type: 'switch',
    name: 'overdue',
    label: 'Overdue',
    gridProps: {
      $desktop: 'auto',
    },
  },
  {
    type: 'switch',
    name: 'completed',
    label: 'Completed',
    gridProps: {
      $desktop: 'auto',
    },
  },
];

export default filters;
