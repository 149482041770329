import React, { FunctionComponent } from 'react';
import Chip from '../../atoms/Chips/Chip';
import { color } from '../../../styles/Variables';
import { Icon } from '../../atoms/Icon/Icon';
import FireIcon from '../../../Icons/Fire.icon';
import PaidIcon from '../../../Icons/Paid.icon';
import ArchivedIcon from '../../../Icons/Archived.icon';
import { BasicProjectType } from '../../../@types/Project/Project';
import CompleteIcon from '../../../Icons/Complete.icon';
import StarIcon from '../../../Icons/Star.icon';

type Props = {
  status: BasicProjectType['status'];
  archived: BasicProjectType['archived'];
  secret?: BasicProjectType['secret'];
};

const ProjectStatus: FunctionComponent<Props> = ({ status, archived, secret = false }) => {
  return (
    <>
      {status === 'active' && (
        <Chip $bgColor={color.primary['20']}>
          <Icon color={color.primary['60']} size={1}>
            <FireIcon />
          </Icon>
          <span
            style={{
              color: color.primary[60],
              whiteSpace: 'nowrap',
            }}
          >
            Active
          </span>
        </Chip>
      )}
      {status === 'settled' && (
        <Chip $bgColor={color.semantic.warning['20']}>
          <Icon color={color.semantic.warning[100]} size={1}>
            <PaidIcon />
          </Icon>
          <span
            style={{
              color: color.semantic.warning[100],
              whiteSpace: 'nowrap',
            }}
          >
            Settled
          </span>
        </Chip>
      )}
      {status === 'completed' && (
        <Chip $bgColor={color.secondary['20']}>
          <Icon color={color.secondary['70']} size={1}>
            <CompleteIcon />
          </Icon>
          <span
            style={{
              color: color.secondary[70],
              whiteSpace: 'nowrap',
            }}
          >
            Completed
          </span>
        </Chip>
      )}
      {archived && (
        <Chip $bgColor={color.neutral['30']}>
          <Icon size={1} color={color.neutral[70]}>
            <ArchivedIcon />
          </Icon>
          <span
            style={{
              color: color.neutral[70],
              whiteSpace: 'nowrap',
            }}
          >
            Archived
          </span>
        </Chip>
      )}
      {secret && (
        <Chip $bgColor={color.neutral['30']}>
          <Icon size={1} color={color.neutral[70]}>
            <StarIcon />
          </Icon>
          <span
            style={{
              color: color.neutral[70],
              whiteSpace: 'nowrap',
            }}
          >
            Secret
          </span>
        </Chip>
      )}
    </>
  );
};
export default ProjectStatus;
