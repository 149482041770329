import React, { FunctionComponent } from 'react';
import * as Styled from './SectionHeader.styled';
import SectionName from './SectionName/SectionName';
import { SectionType } from '../../../../../../../../@types/Section/SectionType';

type Props = { section: Partial<SectionType> };

const SectionHeader: FunctionComponent<Props> = ({ section }) => {
  return (
    <Styled.SectionNameWrapper>
      <SectionName section={section} />
    </Styled.SectionNameWrapper>
  );
};

export default SectionHeader;
