import React, { FunctionComponent, useCallback } from 'react';
import { ProjectType } from '../../../../../@types/Project/Project';
import * as Styled from './Items.styled';
import IdText from '../../../IdText/IdText';
import Text from '../../../../atoms/Text/Text';
import ChecklistIcon from '../../../../../Icons/Checklist.icon';
import { color } from '../../../../../styles/Variables';
import { useNavigate } from 'react-router-dom';
import { shorten } from '../../../../../helpers/shorten';
import useUser from '../../../../../hooks/useUser';

type Props = {
  project: ProjectType;
};

const MyProjectsCardItem: FunctionComponent<Props> = ({ project }) => {
  const navigate = useNavigate();
  const user = useUser();

  const sendDataLayerInfo = useCallback(() => {
    if (!window || !document) return;
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      event: 'go_to_project',
      element: 'dashboard_card',
      pageTitle: document.title,
      userDepartment: user?.department?.name,
    });
  }, [window, document]);

  const goToTheProject = useCallback(() => {
    navigate(`/projects/projects/${project.id}/overview`);
    sendDataLayerInfo();
  }, [project]);

  return (
    <Styled.CardItemWrapper
      onClick={() => {
        goToTheProject();
      }}
    >
      <Styled.ProjectIcon $bgColor={project.client.color}>
        <ChecklistIcon color={color.light} />
      </Styled.ProjectIcon>
      <Styled.NameWrapper>
        <Styled.InlineWrapper>
          <IdText withHash={true} value={project.id} />
          <Text size={'l'} bold>
            {shorten(project.name, 45, 45)}
          </Text>
        </Styled.InlineWrapper>
        <Text size={'s'} color={'grey'} bold>
          {project.client.name}
        </Text>
      </Styled.NameWrapper>
    </Styled.CardItemWrapper>
  );
};

export default MyProjectsCardItem;
