import React, { FunctionComponent } from 'react';
import { components, ControlProps } from 'react-select';
import { ProjectType } from '../../../../@types/Project/Project';
import { Icon } from '../../../../components/atoms/Icon/Icon';
import { PiMagnifyingGlassBold } from 'react-icons/pi';
import { color } from '../../../../styles/Variables';

const SearchControl: FunctionComponent<ControlProps<{ value: string; label: string; meta: ProjectType }, false>> = ({ children, ...rest }) => {
  return (
    <components.Control {...rest}>
      <Icon size={1.8} color={color.neutral[50]}>
        <PiMagnifyingGlassBold />
      </Icon>
      {children}
    </components.Control>
  );
};

export default SearchControl;
