import { TableFilterInterface } from '../TableFilterInterface';
import { useFormContext } from 'react-hook-form';
import React, { useCallback, useMemo } from 'react';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import StaticSelectInput from '../../Form/SelectInput/StaticSelectInput';
import { DictValue } from '../../../../@types/Dictionary/DictValue';

const months: DictValue[] = [
  { value: '', label: 'Not specified' },
  { value: '01', label: 'January' },
  { value: '02', label: 'February' },
  { value: '03', label: 'March' },
  { value: '04', label: 'April' },
  { value: '05', label: 'May' },
  { value: '06', label: 'June' },
  { value: '07', label: 'July' },
  { value: '08', label: 'August' },
  { value: '09', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' },
];

const years: DictValue[] = [{ value: '', label: 'Not specified' }];

for (let i = 2021; i <= new Date().getFullYear() + 1; i++) {
  years.push({ value: i.toString(10), label: i.toString(10) });
}

const yearsReversed = years.slice().reverse();

const DateSelectFilter: TableFilterInterface = (props) => {
  const methods = useFormContext();
  const [init, setInit] = React.useState(false);

  const yearWatcher = methods.watch(`__filters.${props.name}-year`);
  const monthWatcher = methods.watch(`__filters.${props.name}-month`);
  const globalWatcher = methods.watch(props.name);
  const parts = useMemo(() => {
    if (!globalWatcher) {
      return [undefined, undefined];
    }
    if (typeof globalWatcher === 'number') {
      return [globalWatcher.toString(), undefined];
    }
    if (globalWatcher.includes('-')) {
      return globalWatcher.split('-');
    }
    return [globalWatcher, undefined];
  }, [globalWatcher]);

  const currentMonth: string = React.useMemo(() => {
    let currentMonth = '';
    if (new Date().getMonth() + 1 < 10) {
      currentMonth = `0${new Date().getMonth() + 1}`;
    } else {
      currentMonth = `${new Date().getMonth() + 1}`;
    }
    return currentMonth;
  }, []);
  const currentMonthNamed: string = React.useMemo(() => {
    return months.filter((m) => m.value === currentMonth)[0].label;
  }, [currentMonth]);
  const currentYear: string = React.useMemo(() => {
    return `${new Date().getFullYear()}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMonth]);

  const setGlobalValue = useCallback(
    (year?: string, month?: string) => {
      let newValue: undefined | string = undefined;
      if (!year || year === '') {
        methods.setValue(props.name, newValue);
        props.onChange(newValue);
        return;
      }
      newValue = year;

      if (month && month !== '') {
        newValue = newValue + '-' + month;
      }
      methods.setValue(props.name, newValue);
      props.onChange(newValue);
    },
    [props.onChange, props.name],
  );

  React.useEffect(() => {
    if (!init) {
      setInit(true);
      return;
    }
    const timeout = setTimeout(() => {
      setGlobalValue(yearWatcher, monthWatcher);
    }, 300);
    return () => clearTimeout(timeout);
  }, [yearWatcher, monthWatcher, init]);

  return (
    <>
      {parts && (
        <Grid spacing={2}>
          <input type={'hidden'} {...methods.register(props.name)} />
          <GridItem $desktop={6}>
            <StaticSelectInput
              defaultValue={parts[0]}
              required={false}
              name={`__filters.${props.name}-year`}
              options={yearsReversed}
              label={'Year'}
              placeholder={currentYear}
            />
          </GridItem>
          <GridItem $desktop={6}>
            {yearWatcher && yearWatcher !== '' && (
              <StaticSelectInput
                defaultValue={parts[1]}
                name={`__filters.${props.name}-month`}
                options={months}
                label={'Month'}
                placeholder={currentMonthNamed}
              />
            )}
          </GridItem>
        </Grid>
      )}
    </>
  );
};
export default DateSelectFilter;
