import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { Grid, GridItem } from '../../../../../../atoms/Grid/Grid';
import Text from '../../../../../../atoms/Text';
import { Icon } from '../../../../../../atoms/Icon/Icon';
import Person from '../../../../../../molecules/Person/Person';
import { ChecklistType } from '../../../../../../../@types/Checklist/ChecklistType';
import { Link } from 'react-router-dom';
import { OverflowSentimentType } from '../../../../../../../@types/OverflowSentiment/OverflowSentimentType';
import Heading from '../../../../../../atoms/Heading';
import TimeProgress from '../../../../../../molecules/TimeProgress/TimeProgress';
import { parseDate } from '../../../../../../../helpers/date';
import ExternalLinkIcon from '../../../../../../../Icons/ExternalLink.icon';
import Tooltip from '../../../../../../molecules/Tooltip/Tooltip';
import InfoIcon from '../../../../../../../Icons/info.icon';
import TrashIcon from '../../../../../../../Icons/Trash.icon';
import useModal from '../../../../../../../hooks/useModal';
import DeleteChecklistFromProjectModal from '../../../../../../molecules/Modal/DeleteChecklistFromProjectModal';

type Props = {
  checklist: ChecklistType;
  onDelete: () => void;
};

const SingleChecklistHeader: FunctionComponent<Props> = ({ checklist, onDelete }) => {
  const { showModal } = useModal();

  const progress = useMemo(() => {
    return checklist.totalCount - checklist.unresolvedCount;
  }, [checklist]);

  const sentiment: OverflowSentimentType = useMemo(() => {
    if (progress === checklist.totalCount) {
      return 'good';
    }
    if (progress >= checklist.totalCount / 2) {
      return 'neutral';
    }
    if (progress < checklist.totalCount / 3) {
      return 'bad';
    }
    if (progress < checklist.totalCount / 2) {
      return 'warning';
    }
    return 'neutral';
  }, [progress, checklist]);

  const handleDelete = useCallback(() => {
    showModal({
      title: 'Are You sure You want to delete this checklist?',
      body: <DeleteChecklistFromProjectModal checklists={checklist} onDelete={onDelete} />,
    });
  }, []);

  return (
    <>
      <Grid $align={'center'} style={{ padding: '2.4rem 0', cursor: 'pointer' }}>
        <GridItem $desktop={5}>
          <Grid $align={'center'} spacing={2}>
            <GridItem>
              <Heading size={'s'}>{checklist.name}</Heading>
            </GridItem>
            {checklist.articleFullPath ? (
              <GridItem>
                <Link to={checklist.articleFullPath}>
                  <Icon>
                    <ExternalLinkIcon />
                  </Icon>
                </Link>
              </GridItem>
            ) : (
              <Tooltip
                content={
                  <Text size={'xs'} color={'lightGrey'} style={{ width: '16rem' }}>
                    Article of this checklist has been deleted
                  </Text>
                }
                trigger={
                  <Icon>
                    <InfoIcon />
                  </Icon>
                }
                arrowPosition={'topCenter'}
              />
            )}
            <GridItem>
              <Icon onClick={handleDelete}>
                <TrashIcon />
              </Icon>
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem $desktop={3}>
          <Grid $align={'center'}>
            <GridItem $desktop={12}>
              <TimeProgress
                current={progress}
                estimated={checklist.totalCount}
                overflowSentiment={sentiment}
                context={'checklist'}
                unit={'standard_unit'}
                width={20}
              />
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem $desktop={2}>
          <Person person={checklist.createdBy} />
        </GridItem>
        <GridItem $desktop={2}>
          <Grid spacing={0.3} $justifyContent={'end'}>
            <GridItem>
              <Text size={'s'} color={'grey'} bold>
                Last modified:
              </Text>
            </GridItem>
            <GridItem>
              <Text size={'s'} color={'darkGrey'} bold>
                {parseDate(checklist.modifiedAt ?? checklist.createdAt)}
              </Text>
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </>
  );
};

export default SingleChecklistHeader;
