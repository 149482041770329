import React, { forwardRef, FunctionComponent, useEffect, useState } from 'react';
import { FormHtmlInput, FormInputContainer, FormLabel } from '../../../atoms/Form/InputParts/InputParts';
import { Controller, useFormContext } from 'react-hook-form';
import BaseDatePicker from 'react-datepicker';
import { InputProps } from '../../../../@types/Form/InputProps';
import 'react-datepicker/dist/react-datepicker.css';
import FormError from '../../../atoms/Form/FormError/FormError';
import * as Styled from './DataPicker.styled';

type Props = InputProps & {
  error?: string | undefined;
  onClick?: (e: React.MouseEvent) => void;
  value?: any;
  style?: React.CSSProperties;
  nameStart: string;
  nameEnd: string;
  defaultValue?: { before: string | null; after: string | null };
  required?: boolean;
};

type CustomInputProps = Props & {
  onClick?: (e: React.MouseEvent) => void;
  value?: any;
};

export const CustomInput = forwardRef<{}, CustomInputProps>((props, ref) => {
  return (
    <>
      <FormInputContainer onClick={props.onClick} status={props.error && 'error'}>
        <FormLabel status={props.error && 'error'} required={!!props.required}>
          {props.label}
        </FormLabel>
        <FormHtmlInput
          onChange={props.onChange}
          style={{ cursor: 'pointer' }}
          status={props.error && 'error'}
          required={props.required}
          value={props.value}
          placeholder={props.placeholder ?? props.label}
        />
      </FormInputContainer>
    </>
  );
});

const DateRangePicker: FunctionComponent<Props> = (props) => {
  const { control, register, unregister, watch, setValue } = useFormContext();
  const [ready] = React.useState(true);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);

  useEffect(() => {
    if (
      props.defaultValue &&
      props.defaultValue.before &&
      props.defaultValue.before.length > 1 &&
      props.defaultValue.after &&
      props.defaultValue.after.length > 1
    ) {
      const before = new Date(props.defaultValue.before);
      const after = new Date(props.defaultValue.after);
      setDateRange([before, after]);
    }
  }, [props.defaultValue]);

  useEffect(() => {
    register(props.nameStart);
    register(props.nameEnd);
    return () => {
      unregister(props.nameStart);
      unregister(props.nameEnd);
    };
  }, [props.defaultValue]);

  const valueStart = watch(props.nameStart);
  const valueEnd = watch(props.nameEnd);

  useEffect(() => {
    if (!dateRange || dateRange.length < 2) {
      return;
    }
    if (dateRange[1]) {
      dateRange[0]?.setHours(2);
      dateRange[1]?.setHours(2);
    }
    setValue(props.nameStart, dateRange[0]);
    setValue(props.nameEnd, dateRange[1]);
  }, [dateRange, props.defaultValue]);
  return (
    <Styled.DataPickerContainer>
      {ready && (
        <Controller
          control={control}
          name={props.name}
          render={() => {
            return (
              <>
                <BaseDatePicker
                  portalId="data-picker-portal"
                  calendarStartDay={1}
                  placeholderText={props.placeholder ?? props.label}
                  onChange={(value) => {
                    setDateRange(value);
                    props.onChange?.(value);
                  }}
                  showYearDropdown={true}
                  required={props.required}
                  isClearable={!props.required}
                  customInput={<CustomInput {...props} />}
                  dateFormat={'dd-MM-yyyy'}
                  selectsRange
                  startDate={valueStart}
                  endDate={valueEnd}
                  onSelect={() => {}}
                />
                <FormError name={props.name} />
              </>
            );
          }}
        />
      )}
    </Styled.DataPickerContainer>
  );
};
export default DateRangePicker;
