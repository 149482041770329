import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { TableProperty } from '../../../../@types/Table/TableProperty';
import TableCell from '../../../atoms/Table/TableCell';
import { Link } from 'react-router-dom';

type Props = {
  property: TableProperty;
  item: any;
  itemLink?: (item: any) => string;
};

const TableItemCell: FunctionComponent<Props> = ({ item, property, itemLink }) => {
  const Component = property.template;
  const value = useMemo(() => {
    const nameParts = property.name.split('.');
    let value;
    if (nameParts.length === 1) {
      value = item[nameParts[0]];
    } else {
      value = nameParts.reduce((obj, part) => {
        if (!obj) {
          return null;
        }
        if (!obj.hasOwnProperty(part)) {
          throw new Error(`There is no ${part} in ${obj['@id']}`);
        }
        return obj[part];
      }, item);
    }

    if (typeof property.transformValue === 'function') {
      return property.transformValue(value, item);
    } else {
      return value;
    }
  }, [property, item]);

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      if (property.onClick) {
        event.stopPropagation();
        property.onClick(item);
      }
    },
    [property, item],
  );

  return (
    <TableCell onClick={handleClick} justifyContent={property.justifyContent ?? 'flex-start'}>
      {itemLink && !property.onClick ? (
        <Link to={itemLink(item)} style={{ width: '100%', height: '100%' }}>
          <Component item={item} value={value} options={property.templateOptions} />
        </Link>
      ) : (
        <Component item={item} value={value} options={property.templateOptions} />
      )}
    </TableCell>
  );
};

export default TableItemCell;
