import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Grid, GridItem } from '../../../../../atoms/Grid/Grid';
import NotificationToggle from '../../../../../atoms/NotificationToggle/NotificationToggle';
import Popover from '../../../../../atoms/Popover/Popover';
import Text from '../../../../../atoms/Text';
import { Icon } from '../../../../../atoms/Icon/Icon';
import LinkIcon from '../../../../../../Icons/Link.icon';
import DropdownMenu from '../../../../DropdownMenu/DropdownMenu';
import MoreIcon from '../../../../../../Icons/More.icon';
import { color } from '../../../../../../styles/Variables';
import ShiftIcon from '../../../../../../Icons/ShiftIcon';
import { TaskType } from '../../../../../../@types/Task/TaskType';
import useApi from '../../../../../../hooks/useApi';
import TaskTopBarMoveModal from './TaskTopBarMoveModal';
import useModal from '../../../../../../hooks/useModal';
import TaskFormContext from '../../../../../../Context/TaskFormContext/TaskFormContext';
import { SidebarContext } from '../../../../../../Context/Sidebar/SidebarContext';
import Button from '../../../../../atoms/Button/Button';
import DeleteIcon from '../../../../../../Icons/delete.icon';
import { useEntityContext } from '../../../../../../Context/EntityContext/EntityContext';
import { useNavigate } from 'react-router-dom';

type Props = {
  task: TaskType;
  onDelete?: () => void;
  onMove?: () => void;
};

const TaskTopBarActions: FunctionComponent<Props> = ({ task, onDelete, onMove }) => {
  const { post, del } = useApi();
  const { replace } = useEntityContext();
  const navigate = useNavigate();
  const { showModal, hideModal } = useModal();
  const { openLongForm } = useContext(TaskFormContext);
  const { closeSidebar } = useContext(SidebarContext);
  const [subscribed, setSubscribed] = useState(task.subscribed);

  const handleSubscribe = useCallback(() => {
    if (subscribed) {
      setSubscribed((s) => !s);
      post(`${task['@id']}/unsubscribe`, {}, true);
    } else {
      setSubscribed((s) => !s);
      post(`${task['@id']}/subscribe`, {}, true);
    }
  }, [subscribed]);

  useEffect(() => {
    setSubscribed(task.subscribed);
  }, [task]);

  const createLinkForTask = useCallback(() => {
    const host = `https://${window.location.host}`;
    if (task.project) {
      return `${host}/projects/projects/${task.project.id}/tasks?task=${task.id}`;
    } else {
      return `${host}/projects/dashboard/?task=${task.id}`;
    }
  }, [task]);

  const handleMoveModal = useCallback(() => {
    showModal({
      title: `Move "${task.title}" to:`,
      body: <TaskTopBarMoveModal task={task} />,
    });
  }, [task]);

  const prepareCopiedTask = (task: any) => {
    let copiedTask = { ...task };
    delete copiedTask['@id'];
    delete copiedTask.id;
    return copiedTask;
  };

  const confirmDelete = useCallback(() => {
    return del(task['@id']).then(() => {
      onDelete && onDelete();
      hideModal();
    });
  }, []);

  const handleDelete = useCallback(() => {
    showModal({
      title: `Delete "${task.title}"?`,
      body: <span>Are you sure you want to delete this task?</span>,
      footer: (
        <>
          <Button $text={'Cancel'} type={'button'} kind={'ghost'} onClick={hideModal} />
          <Button $text={'Delete'} type={'button'} kind={'negative'} $icon={<DeleteIcon />} onClick={confirmDelete} />
        </>
      ),
    });
  }, []);

  const moreItems = useMemo(() => {
    return [
      { text: 'Edit', onClick: () => openLongForm(task, task['@id']) },
      { text: 'Move', onClick: handleMoveModal },
      { text: 'Copy', onClick: () => openLongForm(prepareCopiedTask(task)) },
      { text: 'Delete', onClick: handleDelete },
    ];
  }, [onDelete, onMove, task]);

  const doCloseSidebar = useCallback(() => {
    closeSidebar();
    //remove task from url query
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('task');
    navigate({ search: searchParams.toString() }, { replace: true });
  }, [closeSidebar]);

  return (
    <>
      <Grid $align={'center'} $justifyContent={'end'} spacing={2.4}>
        <GridItem>
          <NotificationToggle active={!task.deletedAt} onClick={handleSubscribe} filled={subscribed} size={1.3} />
        </GridItem>
        <GridItem>
          <Popover popover={<Text size={'s'}>Copied!</Text>} duration={2000}>
            <Icon onClick={() => navigator.clipboard.writeText(createLinkForTask())} size={1.3}>
              <LinkIcon />
            </Icon>
          </Popover>
        </GridItem>
        <GridItem>
          <DropdownMenu menuItems={moreItems} disabled={!!task.deletedAt}>
            <Icon onClick={!!task.deletedAt ? undefined : () => {}} size={1.3}>
              <MoreIcon />
            </Icon>
          </DropdownMenu>
        </GridItem>
        <GridItem>
          <Icon color={color.primary[60]} onClick={doCloseSidebar} size={1.3}>
            <ShiftIcon />
          </Icon>
        </GridItem>
      </Grid>
    </>
  );
};

export default TaskTopBarActions;
