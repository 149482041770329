import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import DashboardCard from '../DashboardCard';
import ChecklistIcon from '../../../../../../Icons/Checklist.icon';
import { color } from '../../../../../../styles/Variables';
import MyProjectsCardContentCard from '../../../../../molecules/Projects/Dashboard/DashboardCardContentCards/MyProjectsCardContentCard';
import { ProjectType } from '../../../../../../@types/Project/Project';
import api from '../../../../../../services/api';

type Props = {};

const MyProjects: FunctionComponent<Props> = () => {
  const [myProjects, setMyProjects] = useState<ProjectType[] | undefined>();

  const getMyProjects = useCallback(() => {
    api.get('/api/projects-by-role', { params: { archived: false } }).then((response) => {
      setMyProjects(response.data['hydra:member']);
    });
  }, []);

  useEffect(() => {
    getMyProjects();
  }, []);

  return (
    <DashboardCard title={'My projects'} icon={<ChecklistIcon size={2} color={color.primary['60']} />}>
      <MyProjectsCardContentCard projects={myProjects} />
    </DashboardCard>
  );
};

export default MyProjects;
