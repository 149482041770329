import { CellTemplate } from '../CellTemplate';
import React from 'react';
import { InventoryAllocationType } from '../../../../@types/Inventory/InventoryAllocationType';
import IdText from '../../IdText/IdText';
import Text from '../../../atoms/Text';
import Chip from '../../../atoms/Chips/Chip';
import { ChipProperties, determineChipProperties } from '../../../atoms/Chips/determineChipProperties';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import { Icon } from '../../../atoms/Icon/Icon';

const ProtocolIdTemplate: CellTemplate<string, any, InventoryAllocationType> = ({ value, item, options = {} }) => {
  const protocolType: ChipProperties = determineChipProperties(item.type);
  return (
    <Grid spacing={0.6}>
      <GridItem $desktop={12}>
        <Grid spacing={0.6}>
          <GridItem>
            <IdText withHash={true} value={value} />
          </GridItem>
          <GridItem>
            <Text bold color={'grey'}>
              Protocol
            </Text>
          </GridItem>
        </Grid>
      </GridItem>
      <GridItem $desktop={12}>
        <Chip $size={'s'} $bgColor={protocolType.bgColor}>
          <Icon size={1.2}>{protocolType.icon}</Icon>
          <Text size={'s'} color={'darkGrey'} bold>
            <span style={{ color: protocolType.textColor }}>{protocolType.type}</span>
          </Text>
        </Chip>
      </GridItem>
    </Grid>
  );
};
export default ProtocolIdTemplate;
