import React, { FunctionComponent, useMemo } from 'react';
import InventoryItemModal from '../../../../../modules/admin/submodules/Inventory/Screens/InventoryScreen/InventoryItemModal/InventoryItemModal';
import Button from '../../../../atoms/Button/Button';
import useModal from '../../../../../hooks/useModal';
import InventoryItemType from '../../../../../@types/Inventory/InventoryItemType';

type Props = {
  entity: InventoryItemType;
};

const EditButton: FunctionComponent<Props> = ({ entity }) => {
  const { showModal } = useModal();
  const basePath = useMemo(() => {
    return `${entity['@id']}`;
  }, [entity['@id']]);

  return (
    <Button
      $text={'Edit'}
      onClick={() => {
        showModal({
          title: 'Edit inventory item',
          subtitle: 'Edit data for inventory item',
          body: <InventoryItemModal basePath={basePath} defaults={entity} />,
        });
      }}
    />
  );
};

export default EditButton;
