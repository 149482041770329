import React, { ComponentType, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import useFormError from '../../../../hooks/Form/useFormError';
import { InputProps } from '../../../../@types/Form/InputProps';
import { FormInputContainer, FormLabel } from '../../../atoms/Form/InputParts/InputParts';
import FormError from '../../../atoms/Form/FormError/FormError';
import { DictValue } from '../../../../@types/Dictionary/DictValue';
import { selectStyles } from './select.styles';
import AsyncSelect from 'react-select/async';
import api from '../../../../services/api';
import { ArrayResponseType } from '../../../../@types/hydra/hydra';
import { EntityType } from '../../../../@types/Entity/EntityType';
import { OnChangeValue } from 'react-select';

type Props = InputProps & {
  baseUrl: string;
  baseUrlParams?: { [key: string]: string | number | string[] | undefined };
  optionComponent?: ComponentType<any>;
  singleValueComponent?: ComponentType<any>;
  optionLabel?: string;
  isOptionDisabled?: (option: DictValue) => boolean;
};

/**
 * Component designed to be multi-select
 */
const MultiDynamicSelectInput: FunctionComponent<Props> = ({ baseUrl, ...props }) => {
  const { control, watch } = useFormContext();
  const error = useFormError(props.name);
  const optionLabel = props.optionLabel ? props.optionLabel : 'name';
  const [init, setInit] = useState(false);
  const watchValue = watch(props.name);
  const [internalValue, setInternalValue] = useState<any[]>([]);

  const handleDefaultValue = useCallback((uris: string[]) => {
    const requests = uris.map((uri) => api.get<EntityType>(uri));
    Promise.all(requests).then((responses) => {
      const values = responses.map((response) => response.data);
      setInternalValue(values);
      setInit(true);
    });
  }, []);

  useEffect(() => {
    if (!Array.isArray(watchValue)) {
      return;
    }

    if (watchValue.length > 0) {
      if (typeof watchValue[0] === 'string') {
        handleDefaultValue(watchValue);
      } else if (typeof watchValue[0] === 'number') {
        //todo: implement this as generic
        handleDefaultValue(watchValue.map((v) => `/api/employees/${v}`));
      }
    } else {
      setInternalValue(watchValue);
    }
  }, [watchValue]);

  useEffect(() => {
    if (init) {
      return;
    }
    if (Array.isArray(watchValue) && watchValue.every((v) => typeof v === 'string' && v.startsWith('/api/'))) {
      handleDefaultValue(watchValue);
    } else if (Array.isArray(watchValue)) {
      setInternalValue(watchValue);
      setInit(true);
    } else {
      if (props.defaultValue && Array.isArray(props.defaultValue)) {
        if (props.defaultValue.every((v) => typeof v === 'string' && v.startsWith('/api/'))) {
          handleDefaultValue(props.defaultValue);
        } else {
          setInternalValue(props.defaultValue);
          setInit(true);
        }
      } else {
        setInit(true);
      }
    }
  }, [watchValue, init]);

  const components = useMemo(() => {
    const result: any = {
      IndicatorSeparator: () => null,
    };
    if (props.optionComponent) {
      result.Option = props.optionComponent;
    }
    if (props.singleValueComponent) {
      result.SingleValue = props.singleValueComponent;
    }
    return result;
  }, [props.optionComponent, props.singleValueComponent]);

  const loadOptions = (inputValue: string, callback: Function) => {
    if (!baseUrl) {
      callback([]);
      return;
    }
    const params: { [key: string]: string | number | string[] | undefined } = props.baseUrlParams ? props.baseUrlParams : {};
    params['_search'] = inputValue;

    api.get<ArrayResponseType>(baseUrl, { params }).then((response) => {
      callback(
        response.data['hydra:member'].map((i) => {
          return { value: i['@id'], label: i[optionLabel], meta: i };
        }),
      );
    });
  };

  return (
    <>
      <FormInputContainer>
        <FormLabel status={error && 'error'} required={!!props.required} disabled={!!props.disabled}>
          {props.label}
        </FormLabel>
        {init && (
          <Controller
            disabled={props.disabled}
            control={control}
            defaultValue={props.defaultValue}
            name={props.name}
            rules={{
              required: props.required ? 'this field is required' : undefined,
            }}
            render={({ field: { onChange } }) => (
              <AsyncSelect
                menuPortalTarget={document.getElementById('data-picker-portal')}
                key={1}
                loadOptions={loadOptions}
                defaultOptions
                value={internalValue?.map((val) => ({
                  meta: val,
                  value: val['@id'],
                  label: val[optionLabel],
                }))}
                onChange={(options: OnChangeValue<DictValue, true>) => {
                  if (options && options.length) {
                    const values = options.map((option) => option.meta);
                    props.onChange && props.onChange(values);
                    onChange(values.map((option) => option['@id']));
                    setInternalValue(values);
                  } else {
                    props.onChange && props.onChange([]);
                    onChange([]);
                    setInternalValue([]);
                  }
                }}
                required={!!props.required}
                isDisabled={props.disabled}
                isOptionDisabled={props.isOptionDisabled}
                isMulti={true}
                placeholder={props.placeholder}
                components={components}
                noOptionsMessage={() => 'Start typing to search...'}
                isClearable={!props.required}
                styles={selectStyles(!!error, props.required, props.disabled)}
              />
            )}
          ></Controller>
        )}
      </FormInputContainer>
      <FormError name={props.name} />
    </>
  );
};

export default MultiDynamicSelectInput;
