import React, { FunctionComponent, ReactNode, useCallback, useState } from 'react';
import { ModalContext } from './ModalContext';
import { ModalType } from '../../@types/Modal/ModalType';

type Props = {
  children: ReactNode | ReactNode[];
};

const ModalContextProvider: FunctionComponent<Props> = ({ children }) => {
  const [modal, setModal] = useState<ModalType | undefined>();
  const showModal = useCallback((modal: ModalType) => {
    setModal(modal);
  }, []);

  const hideModal = useCallback(() => {
    setModal(undefined);
  }, []);

  return <ModalContext.Provider value={{ modal, showModal, hideModal }}>{children}</ModalContext.Provider>;
};

export default ModalContextProvider;
