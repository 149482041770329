import styled from 'styled-components';
import { Grid, GridItem } from '../../components/atoms/Grid/Grid';
import { Heading } from '../../components/atoms/Heading/Heading.styled';
import { color } from '../../styles/Variables';

type LoginPhotoProps = {
  $background: string;
};

export const LoginScreen = styled(Grid)`
  height: 100svh;
  background-color: ${color.neutral[10]};
`;

export const LeftGridItem = styled(GridItem)`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const RightGridItem = styled(GridItem)``;

export const FormContainer = styled.div`
  width: 30rem;
`;

export const LogoWrapper = styled.div`
  margin-bottom: 5.6rem;
`;

export const HeaderWrapper = styled.div`
  margin-bottom: 4.8rem;
`;

export const Header = styled(Heading)`
  padding: 0;
  margin-bottom: 0.4rem;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  justify-content: space-between;
`;

export const ButtonsWrapper = styled.div`
  gap: 2.4rem;
  display: flex;
  flex-direction: column;
  margin-top: 3.6rem;
`;

export const LoginPhotoWrapper = styled.div`
  padding: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const LoginPhoto = styled.div<LoginPhotoProps>`
  background: url(${(props) => props.$background}) no-repeat center;
  background-size: cover;
  width: 100%;
  height: 100%;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  border-radius: 16px;
`;
