import React, { FunctionComponent, useContext } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import CostEdit from '../../../../components/templates/Costs/CostForm/CostEdit';
import CostShowView from '../../../../components/templates/Costs/CostShow/CostShowView';
import { AuthContext } from '../../../../Context/auth-context/auth-context';
import CostCreate from '../../../../components/templates/Costs/CostForm/CostCreate';
import CostsListScreen from './CostsListScreen';
import { Helmet } from 'react-helmet';

type Props = {};

const CostsScreen: FunctionComponent<Props> = () => {
  const { can } = useContext(AuthContext);

  return (
    <>
      <Helmet>
        <title>Costs | F.CAPE</title>
      </Helmet>

      <Routes>
        {can('FINANCES.COSTS.CAN_MANAGE') && <Route path="costs/create" element={<CostCreate />}></Route>}
        {can('FINANCES.COSTS.CAN_MANAGE') && <Route path="costs/:id/edit" element={<CostEdit />}></Route>}
        <Route path="costs/:id/show" element={<CostShowView />}></Route>
        <Route path="*" element={<CostsListScreen />}></Route>
      </Routes>
    </>
  );
};

export default CostsScreen;
