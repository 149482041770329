import React, { FunctionComponent } from 'react';
import { Card } from '../../../../atoms/Card/Card';
import { ArticleType } from '../../../../../@types/Article/ArticleType';
import Heading from '../../../../atoms/Heading';
import { Info } from '../../../../atoms/Projects/Wiki/ListItem';
import { Link, useLocation } from 'react-router-dom';
import { color } from '../../../../../styles/Variables';
import RichText from '../../../../atoms/RichText/RichText';
import { TagType } from '../../../../../@types/Tag/Tag';
import DateString from '../../../../atoms/DateString/DateString';
import Chip from '../../../../atoms/Chips/Chip';

type Props = {
  article: ArticleType;
};

const ArticleListItem: FunctionComponent<Props> = ({ article }) => {
  const location = useLocation();
  return (
    <Link to={`${location.pathname.endsWith('/') ? location.pathname.substring(0, location.pathname.length - 1) : location.pathname}/${article.slug}`}>
      <Card style={{ marginBottom: '2.4rem' }}>
        <div style={{ marginBottom: '1.6rem' }}>
          <Heading level={3} size={'s'} color={color.primary[60]}>
            <strong>{article.name}</strong>
          </Heading>
        </div>

        {article.intro && (
          <Info>
            <RichText content={article.intro} />
          </Info>
        )}
        <Info>
          Last update:{' '}
          <strong>
            <DateString input={article.createdAt} />
          </strong>
        </Info>
        {article.tags.length > 0 && (
          <Info>
            {article.tags.map((tag: TagType, idx) => (
              <Chip key={idx}>{tag.value}</Chip>
            ))}
          </Info>
        )}
      </Card>
    </Link>
  );
};

export default ArticleListItem;
