import styled, { css } from 'styled-components';
import { color } from '../../../../styles/Variables';

type ItemRowProps = {
  $clickable?: boolean;
  $extended?: boolean;
};

export const ItemRow = styled.tr<ItemRowProps>`
  transition: background-color 0.2s ease-in-out;
  box-sizing: content-box;
  border-bottom: 1px solid ${color.neutral[40]};
  cursor: ${({ $clickable }) => ($clickable ? 'pointer' : 'default')};

  &:hover {
    background-color: ${color.neutral[20]};
  }

  svg:hover {
    path {
      fill: ${color.primary[60]};
    }
  }

  ${({ $extended }) =>
    $extended &&
    css`
      background-color: ${color.neutral[20]};
    `}
`;
