import React, { CSSProperties, useEffect, useCallback, useState, FunctionComponent, RefObject } from 'react';
import { Icon } from '../Icon/Icon';
import * as Styled from './Button.styled';

export type ButtonKind = 'primary' | 'secondary' | 'ghost' | 'link' | 'positive' | 'negative' | 'ghost-white';

export type ButtonType = {
  $text?: string;
  id?: string;
  kind?: ButtonKind;
  $icon?: React.ReactElement;
  iconColor?: string;
  iconSize?: number;
  small?: boolean;
  medium?: boolean;
  full?: boolean;
  waiting?: boolean;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  style?: CSSProperties;
  onClick?: (e?: any) => Promise<any> | void;
  children?: JSX.Element;
  buttonRef?: RefObject<HTMLButtonElement>; //otypować
};

const Button: FunctionComponent<ButtonType> = ({
  $text,
  id,
  kind = 'primary',
  $icon,
  iconColor,
  iconSize = 1,
  small = false,
  medium = false,
  full = false,
  waiting = false,
  disabled = false,
  type = 'button',
  style,
  onClick,
  children,
  buttonRef,
}) => {
  const [internalWaiting, setWaiting] = useState(false);

  const handleClick = useCallback(
    async (e: any) => {
      if (onClick) {
        const result: void | Promise<any> = onClick();
        if (result) {
          setWaiting(true);
          await Promise.resolve(result).finally(() => {
            setWaiting(false);
          });
        }
      }
    },
    [onClick],
  );

  useEffect(() => {
    if (waiting === undefined) {
      return;
    }
    setWaiting(waiting);
  }, [waiting]);

  return (
    <Styled.Wrapper>
      <Styled.Button
        id={id}
        onClick={handleClick}
        $hasChildren={!!children}
        $kind={kind}
        $small={small}
        $medium={medium}
        $full={full}
        $disabled={disabled || waiting || internalWaiting}
        type={type}
        style={style}
        ref={buttonRef}
        $iconColor={iconColor}
      >
        <Styled.Text>
          {$icon && (
            <Icon size={iconSize} color={iconColor}>
              {$icon}
            </Icon>
          )}
          {$text && <span>{internalWaiting ? 'Loading...' : $text}</span>}
        </Styled.Text>
      </Styled.Button>
      {children}
    </Styled.Wrapper>
  );
};

export default Button;
