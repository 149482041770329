import React, { FunctionComponent, useCallback, useMemo } from 'react';
import FormPage from '../../../organisms/Form/FormPage/FormPage';
import { Card } from '../../../atoms/Card/Card';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { CategoryType } from '../../../../@types/Category/CategoryType';
import RichTextInput from '../../../molecules/Form/RichTextInput/RichTextInput';
import { TextInput } from '../../../molecules/Form/TextInput/TextInput';
import Container from '../../../atoms/Container/Container';
import Select from '../../../molecules/Form/SelectInput/SelectInput';
import EmployeeInput from '../../../molecules/Form/EmployeeInput/EmployeeInput';
import Heading from '../../../atoms/Heading';
import Resources from '../../../organisms/Resources/Resources';
import Text from '../../../atoms/Text';

type Props = {};

const WikiArticleForm: FunctionComponent<Props> = (props) => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string | undefined }>();
  const { state } = useLocation();
  const handleSuccess = useCallback((entity: CategoryType) => {
    navigate(entity.fullPath);
  }, []);

  const basePath = useMemo(() => {
    let base = '/api/wiki/articles';
    if (id) {
      return `${base}/${id}`;
    }
    return base;
  }, [id]);

  const defaults = useMemo(() => {
    if (id) {
      return undefined;
    }
    if (state && state.category) {
      return { category: state.category };
    }
    return undefined;
  }, [state, id]);
  return (
    <Container>
      <FormPage
        basePath={basePath}
        createTitle={'Create article'}
        editTitle={'Edit article'}
        onEdit={handleSuccess}
        onCreate={handleSuccess}
        defaults={defaults}
      >
        <GridItem $desktop={12}>
          <Card>
            <Grid spacing={4}>
              <GridItem $desktop={12}>
                <Heading size={'s'} noPadding>
                  Article details
                </Heading>
              </GridItem>
              <GridItem $desktop={3}>
                <TextInput label={'Article name'} name={'name'} required />
              </GridItem>
              <GridItem $desktop={3}>
                <Select name={'category'} label={'Category'} baseUrl={'/api/wiki/wiki_categories/'} required />
              </GridItem>
              <GridItem $desktop={3}>
                <EmployeeInput name={'author'} group={'organisation'} required label={'Author'} />
              </GridItem>
              <GridItem $desktop={3}>
                <Select name="tags" label="Tags" placeholder="Tags" baseUrl="/project_tags" optionLabel="value" creatable isMulti />
              </GridItem>

              <GridItem $desktop={12} style={{ marginTop: '2.6rem' }}>
                {id && <Resources subject={'WikiArticle'} subjectId={id} />}
                {!id && <Text>You can add resources to article after you save it.</Text>}
              </GridItem>
            </Grid>
          </Card>
        </GridItem>
        <GridItem $desktop={12}>
          <Card>
            <Grid spacing={4}>
              <GridItem $desktop={12}>
                <Heading size={'s'} noPadding>
                  Article content
                </Heading>
              </GridItem>
              <GridItem $desktop={12}>
                <RichTextInput name="intro" label={'Article intro'} placeholder={'Clue what everyone can find in this article'} required />
              </GridItem>
              <GridItem $desktop={12}>
                <RichTextInput name="content" label={'Article content'} required />
              </GridItem>
            </Grid>
          </Card>
        </GridItem>
      </FormPage>
    </Container>
  );
};

export default WikiArticleForm;
