import React from 'react';
import { TableFilterInterface } from '../TableFilterInterface';
import { Controller, useFormContext } from 'react-hook-form';
import * as Styled from '../../Form/SwitchInput/SwitchInput.styled';
import FormError from '../../../atoms/Form/FormError/FormError';

const SwitchIncludeFilter: TableFilterInterface = ({ name, filter, onChange, defaultValue }) => {
  const formMethods = useFormContext();
  const valueWatch = formMethods.watch(name);
  return (
    <>
      <Styled.Container>
        <Styled.SwitchLabel>
          <Controller
            name={name}
            control={formMethods.control}
            defaultValue={defaultValue}
            render={({ field: { onChange: doOnChange, value, name } }) => {
              return (
                <Styled.HiddenCheckbox
                  name={name}
                  type="checkbox"
                  value={value === undefined ? true : value}
                  onChange={(e) => {
                    doOnChange(e.target.checked ? 'undefined' : false);
                    onChange && onChange(e.target.checked);
                  }}
                  id={name}
                />
              );
            }}
          />
          <Styled.SwitchWrapper $checked={valueWatch}>
            <Styled.StyledCheckbox $checked={valueWatch} />
          </Styled.SwitchWrapper>
          <Styled.SwitchText>{filter.label}</Styled.SwitchText>
        </Styled.SwitchLabel>
      </Styled.Container>
      <FormError name={name} />
    </>
  );
};

export default SwitchIncludeFilter;
