import React, { FunctionComponent } from 'react';
import properties from '../AbsencesTable.properties';
import AbsencesItemExtendedRow from '../../../organisms/Absences/AbsencesItemExtendedRow/AbsencesItemExtendedRow';
import filters from '../AbsencesTable.filters';
import useTableReload from '../../../../hooks/Table/useTableReload';
import TablePage from '../../../organisms/TablePage/TablePage';

type Props = {};

const AllAbsencesView: FunctionComponent<Props> = (props) => {
  const { key } = useTableReload();

  return (
    <TablePage
      url={'/api/absence_requests/'}
      context={'/api/absence_requests'}
      properties={properties}
      filters={filters}
      extendedRow={AbsencesItemExtendedRow}
      reloadKey={key}
      heading={'All absences'}
    />
  );
};

export default AllAbsencesView;
