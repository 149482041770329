import React, { FunctionComponent, useMemo } from 'react';
import { color } from 'styles/Variables';
import Status, { StatusProps } from '../Status';
import { FinanceStatusType } from '../../../../@types/Finances/FinanceStatusType';
import { PurchaseStatus } from '../../../../@types/Finances/PurchaseOrder/PurchaseOrderType';
import { TextSizeType } from '../../../../@types/Text/TextSizeType';

type PropType = {
  status: FinanceStatusType | PurchaseStatus;
  size?: TextSizeType;
  showText?: boolean;
};

const FinanceStatus: FunctionComponent<PropType> = ({ status, showText = true, size }) => {
  const state = useMemo<StatusProps>(() => {
    switch (status) {
      case 'automated_draft':
        return { displayedText: 'Automate', color: color.neutral[60], bgColor: color.neutral[20] };
      case 'draft':
        return { displayedText: 'Draft', color: color.neutral[60], bgColor: color.neutral[20] };
      case 'closed':
        return { displayedText: 'Closed', color: color.primary[60], bgColor: color.primary[20] };
      case 'pending':
        return { displayedText: 'Pending', color: color.semantic.warning[100], bgColor: color.semantic.warning[20] };
      case 'rejected':
        return { displayedText: 'Rejected', color: color.semantic.error[100], bgColor: color.semantic.error[20] };
      case 'accepted':
        return { displayedText: 'Accepted', color: color.secondary[70], bgColor: color.secondary[20] };
      case 'cancelled':
        return { displayedText: 'Canceled', color: color.neutral[100], bgColor: color.neutral[40] };
      default:
        return { displayedText: 'Unknown', color: color.semantic.error[100], bgColor: color.semantic.error[20] };
    }
  }, [status]);

  return <Status color={state.color} bgColor={state.bgColor} displayedText={showText ? state.displayedText : undefined} size={size} />;
};

export default FinanceStatus;
