import { TaskStatusType } from '../../../../@types/Task/TaskType';
import React, { FunctionComponent, useMemo } from 'react';
import { color } from 'styles/Variables';
import Status, { StatusProps } from '../Status';
import { TextSizeType } from '../../../../@types/Text/TextSizeType';

type PropType = {
  status: TaskStatusType;
  size?: TextSizeType;
};

const TaskStatus: FunctionComponent<PropType> = ({ status, size }) => {
  const state = useMemo<StatusProps>(() => {
    switch (status) {
      case 'todo':
        return { displayedText: 'Not started', color: color.neutral[70], bgColor: color.neutral[30] };
      case 'in_progress':
        return { displayedText: 'In progress', color: color.primary[60], bgColor: color.primary[20] };
      case 'in_review':
        return { displayedText: 'Review', color: color.semantic.warning[100], bgColor: color.semantic.warning[20] };
      case 'acceptance':
        return { displayedText: 'Acceptance', color: color.additionals.purple, bgColor: color.additionals.magnolia };
      case 'completed':
        return { displayedText: 'Completed', color: color.secondary[70], bgColor: color.secondary[20] };
    }
  }, [status]);

  return <Status bgColor={state.bgColor} color={state.color} displayedText={state.displayedText} size={size} />;
};

export default TaskStatus;
