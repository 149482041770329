import React, { FunctionComponent } from 'react';
import Container from '../../../../components/atoms/Container/Container';
import AllTasks from '../../../../components/templates/Tasks/AllTasks/AllTasks';
import { Helmet } from 'react-helmet';

const TasksSubmodule: FunctionComponent = () => {
  return (
    <Container>
      <Helmet>
        <title>Tasks | F.CAPE</title>
      </Helmet>
      <AllTasks />
    </Container>
  );
};

export default TasksSubmodule;
