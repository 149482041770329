import React, { FunctionComponent } from 'react';

type PropType = {
  size?: number;
};

const MoreIcon: FunctionComponent<PropType> = ({ size = 1 }) => {
  return (
    <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.9987 17.1667C9.54036 17.1667 9.148 17.0035 8.82161 16.6771C8.49523 16.3507 8.33203 15.9583 8.33203 15.5C8.33203 15.0417 8.49523 14.6493 8.82161 14.3229C9.148 13.9965 9.54036 13.8333 9.9987 13.8333C10.457 13.8333 10.8494 13.9965 11.1758 14.3229C11.5022 14.6493 11.6654 15.0417 11.6654 15.5C11.6654 15.9583 11.5022 16.3507 11.1758 16.6771C10.8494 17.0035 10.457 17.1667 9.9987 17.1667ZM9.9987 12.1667C9.54036 12.1667 9.148 12.0035 8.82161 11.6771C8.49523 11.3507 8.33203 10.9583 8.33203 10.5C8.33203 10.0417 8.49523 9.64931 8.82161 9.32292C9.148 8.99653 9.54036 8.83333 9.9987 8.83333C10.457 8.83333 10.8494 8.99653 11.1758 9.32292C11.5022 9.64931 11.6654 10.0417 11.6654 10.5C11.6654 10.9583 11.5022 11.3507 11.1758 11.6771C10.8494 12.0035 10.457 12.1667 9.9987 12.1667ZM9.9987 7.16667C9.54036 7.16667 9.148 7.00347 8.82161 6.67708C8.49523 6.35069 8.33203 5.95833 8.33203 5.5C8.33203 5.04167 8.49523 4.64931 8.82161 4.32292C9.148 3.99653 9.54036 3.83333 9.9987 3.83333C10.457 3.83333 10.8494 3.99653 11.1758 4.32292C11.5022 4.64931 11.6654 5.04167 11.6654 5.5C11.6654 5.95833 11.5022 6.35069 11.1758 6.67708C10.8494 7.00347 10.457 7.16667 9.9987 7.16667Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MoreIcon;
