import React, { FunctionComponent, useCallback } from 'react';
import TablePage from '../../../organisms/TablePage/TablePage';
import properties from './ContractorsList.properties';
import { useNavigate, useLocation } from 'react-router-dom';
import { ContractorType } from '../../../../@types/Finances/Contractor/ContractorType';
import filters from './ContractorsList.filters';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import Button from '../../../atoms/Button/Button';
import PlusIcon from '../../../../Icons/Plus.icon';

type Props = {};

const ContractorsList: FunctionComponent<Props> = (props) => {
  let location = useLocation();
  const navigate = useNavigate();

  const handleCreate = useCallback(() => {
    navigate(`${location.pathname}/create`);
  }, [location.pathname, navigate]);

  const CreateAction = () => {
    return (
      <Grid spacing={2} $align={'center'}>
        <GridItem $desktop={'auto'}>
          <Button $text={' New vendor'} kind={'primary'} onClick={() => handleCreate()} $icon={<PlusIcon />} />
        </GridItem>
      </Grid>
    );
  };

  return (
    <TablePage<ContractorType>
      context={'/api/contractors'}
      heading={'Vendors'}
      headingRight={<CreateAction />}
      itemLink={(item) => `${location.pathname}/${item.id}/show`}
      url={'/contractors'}
      properties={properties}
      filters={filters}
      globalFilters={{
        _archived: 0,
      }}
      onCreate={handleCreate}
    />
  );
};

export default ContractorsList;
