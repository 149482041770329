import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor, Editor } from 'ckeditor5';
import React, { FunctionComponent, useCallback } from 'react';
import * as Styled from './CKEditorAtom.styled';
import { editorConfig } from './editorConfig';
import UploadAdapter from './UploadAdapter';
import { ArticleType } from '../../../@types/Article/ArticleType';
import { CategoryType } from '../../../@types/Category/CategoryType';
import { EmployeeType } from '../../../@types/Employee/EmployeeType';
import { ArrayResponseType } from '../../../@types/hydra/hydra';
import api from '../../../services/api';

type Props = {
  value: string | null;
  onChange: (value: string | null) => void;
  mentions: boolean;
  active?: boolean;
  hideToolbar?: boolean;
  placeholder?: string;
  onBlur?: () => void;
};

function CustomUploadAdapterPlugin(editor: Editor) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-expect-error
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new UploadAdapter(loader);
  };
}

const CKEditorAtom: FunctionComponent<Props> = ({ value, onChange, hideToolbar, active = false, placeholder, onBlur, mentions }) => {
  const loadEmployees = useCallback(
    (search: string) => {
      if (!mentions) {
        return Promise.resolve([]);
      }
      return api
        .get<ArrayResponseType<EmployeeType>>('/employees', {
          params: { _search: search, status: 'active' },
        })
        .then((response) => {
          return response.data['hydra:member'].map((employee) => {
            return {
              name: employee.name,
              id: `@${employee.username}`,
              userId: employee.id,
            };
          });
        });
    },
    [mentions],
  );

  const handleReady = useCallback((editor: any) => {
    const handlePaste = (event: any, data: any) => {
      const pastedText = data.dataTransfer.getData('text/plain');
      if (!pastedText.includes('/projects/wiki') || !pastedText.includes(window.location.origin)) {
        return;
      }
      const path = pastedText.replace(window.location.origin, '');
      event.stop();

      api
        .get<ArticleType | CategoryType>('/wiki/articles-by-path', {
          params: {
            path: path,
          },
        })
        .then((response) => {
          const modifiedText = pastedText.replace(path, response.data.persistentUrl);
          const viewFragment = editor.data.processor.toView(modifiedText);
          const modelFragment = editor.data.toModel(viewFragment);
          editor.model.insertContent(modelFragment, editor.model.document.selection);
        });
    };

    editor.editing.view.document.on('clipboardInput', handlePaste);

    return () => {
      editor.editing.view.document.off('clipboardInput', handlePaste);
    };
  }, []);

  return (
    <Styled.CKEditorWrapper $active={active} $hideToolbar={hideToolbar}>
      <CKEditor
        editor={ClassicEditor}
        data={value}
        onReady={handleReady}
        config={{
          ...editorConfig,
          placeholder: placeholder ? placeholder : "What's on your mind?",
          mention: {
            feeds: [
              {
                marker: '@',
                feed: loadEmployees,
                minimumCharacters: 1,
              },
            ],
          },
          extraPlugins: [CustomUploadAdapterPlugin],
        }}
        onChange={(event: any, editor: Editor) => {
          onChange(editor.getData());
        }}
        onBlur={onBlur}
      />
    </Styled.CKEditorWrapper>
  );
};

export default CKEditorAtom;
