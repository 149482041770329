import styled, { css } from 'styled-components';
import { color } from '../../../styles/Variables';
import { BodyText } from '../../../styles/Typography';

export type TooltipArrowPosition =
  | 'topLeft'
  | 'topCenter'
  | 'topRight'
  | 'rightTop'
  | 'rightCenter'
  | 'rightBottom'
  | 'bottomRight'
  | 'bottomCenter'
  | 'bottomLeft'
  | 'leftBottom'
  | 'leftCenter'
  | 'leftTop';

type TooltipContainerProps = {
  $arrowPosition: TooltipArrowPosition;
};

export const TooltipContainerWrapper = styled.div`
  cursor: default;
  position: absolute;
  display: none;
  z-index: 2;
`;
export const TooltipContainer = styled.div<TooltipContainerProps>`
  ${BodyText};
  color: ${color.light};
  background-color: ${color.neutral['100']};
  padding: 0.8rem;
  border-radius: 6px;
  position: relative;

  ::after {
    background-color: ${color.neutral['100']};
    display: block;
    content: ' ';
    width: 5px;
    height: 5px;
    position: absolute;
  }

  ${(props) =>
    props.$arrowPosition === 'topLeft' &&
    css`
      left: -8%;
      top: 3px;

      ::after {
        top: -1.3%;
        left: 10%;
        transform: rotate(45deg);
      }
    `}

  ${(props) =>
    props.$arrowPosition === 'topCenter' &&
    css`
      left: -50%;
      top: 3px;

      ::after {
        top: -1.3%;
        left: 52%;
        transform: rotate(45deg);
      }
    `}

  ${(props) =>
    props.$arrowPosition === 'topRight' &&
    css`
      left: -90%;
      top: 3px;

      ::after {
        top: -1.3%;
        left: 92%;
        transform: rotate(45deg);
      }
    `}

  ${(props) =>
    props.$arrowPosition === 'rightTop' &&
    css`
      left: -105%;
      top: 0;
      transform: translateY(-20%);

      ::after {
        top: 10%;
        left: 98%;
        transform: rotate(45deg);
      }
    `}

  ${(props) =>
    props.$arrowPosition === 'rightCenter' &&
    css`
      left: -105%;
      top: 0;
      transform: translateY(-50%);

      ::after {
        top: 35%;
        left: 98%;
        transform: rotate(45deg);
      }
    `}

  ${(props) =>
    props.$arrowPosition === 'rightBottom' &&
    css`
      left: -105%;
      top: 0;
      transform: translateY(-95%);

      ::after {
        top: 80%;
        left: 98%;
        transform: rotate(45deg);
      }
    `}

  ${(props) =>
    props.$arrowPosition === 'bottomRight' &&
    css`
      left: -90%;
      top: 0;
      transform: translateY(-130%);

      ::after {
        top: 92%;
        left: 92%;
        transform: rotate(45deg);
      }
    `}

  ${(props) =>
    props.$arrowPosition === 'bottomCenter' &&
    css`
      left: -50%;
      top: 0;
      transform: translateY(-130%);

      ::after {
        top: 92%;
        left: 52%;
        transform: rotate(45deg);
      }
    `}

  ${(props) =>
    props.$arrowPosition === 'bottomLeft' &&
    css`
      left: -10%;
      top: 0;
      transform: translateY(-170%);

      ::after {
        top: 92%;
        left: 12%;
        transform: rotate(45deg);
      }
    `}

  ${(props) =>
    props.$arrowPosition === 'leftBottom' &&
    css`
      left: 10%;
      top: 0;
      transform: translateY(-100%);

      ::after {
        top: 80%;
        left: -1.3%;
        transform: rotate(45deg);
      }
    `}

  ${(props) =>
    props.$arrowPosition === 'leftCenter' &&
    css`
      left: 10%;
      top: 0;
      transform: translateY(-70%);

      ::after {
        top: 50%;
        left: -1.3%;
        transform: rotate(45deg);
      }
    `}

  ${(props) =>
    props.$arrowPosition === 'leftTop' &&
    css`
      left: 10%;
      top: 0;
      transform: translateY(-30%);

      ::after {
        top: 12%;
        left: -1.3%;
        transform: rotate(45deg);
      }
    `}
`;

export const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    > div {
      display: flex;
    }
  }
`;
