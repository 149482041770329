import React, { FunctionComponent, useEffect } from 'react';
import { InputProps } from '../../../../@types/Form/InputProps';
import { Controller, useFormContext } from 'react-hook-form';
import * as Styled from './RichTextInput.styled';
import FormError from '../../../atoms/Form/FormError/FormError';
import { ErrorMessages } from '../../../atoms/Form/FormMessages/ErrorMessages';
import { FormLabel } from '../../../atoms/Form/InputParts/InputParts';
import CKEditorAtom from '../../../atoms/CKEditor/CKEditorAtom';
import useLocalStorage from '../../../../hooks/useLocalStorage';

type Props = InputProps & {
  mentions?: boolean;
  disabled?: string;
  localStorageKey?: string;
};

const RichTextInput: FunctionComponent<Props> = ({ mentions = false, localStorageKey, ...props }) => {
  const { control, formState, setValue } = useFormContext();

  return (
    <>
      <FormLabel required={props.required}>{props.label}</FormLabel>
      <Controller
        control={control}
        defaultValue={props.defaultValue}
        name={props.name}
        rules={{
          required: props.required && ErrorMessages.required,
        }}
        render={({ field: { onChange, onBlur, value } }) => {
          if (localStorageKey) {
            const { setStorageValue, storageValue, deleteStorageValue } = useLocalStorage(localStorageKey, value);
            useEffect(() => {
              if ((!value && !storageValue) || value === '') {
                deleteStorageValue();
              } else if (value) {
                setStorageValue(value);
              }
            }, [value]);

            useEffect(() => {
              if (storageValue) {
                setValue(props.name, storageValue);
              }
            }, []);

            useEffect(() => {
              if (formState.isSubmitted) {
                deleteStorageValue();
              }
            }, [formState.isSubmitted]);
          }

          return (
            <Styled.Container>
              <Styled.Editor>
                <CKEditorAtom value={value} onChange={onChange} onBlur={onBlur} mentions={mentions} active />
              </Styled.Editor>
              <FormError name={props.name} />
            </Styled.Container>
          );
        }}
      />
    </>
  );
};

export default RichTextInput;
