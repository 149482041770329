import React from 'react';
import { TaskType } from '../../@types/Task/TaskType';

export type TaskFormContextType = {
  openShortForm: () => void;
  openLongForm: (defaults: Partial<TaskType>, iri?: string) => void;
  closeForm: () => void;
  isOpen: false | 'short' | 'long';
  defaults: Partial<TaskType>;
};

const TaskFormContext = React.createContext<TaskFormContextType>({
  openShortForm: () => {},
  openLongForm: () => {},
  closeForm: () => {},
  isOpen: false,
  defaults: {},
});

export default TaskFormContext;
