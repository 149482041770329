import styled, { css } from 'styled-components';
import { color } from '../../../../styles/Variables';

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  background-color: ${color.neutral['10']};
`;

export const HeaderContainer = styled.div`
  z-index: 14;
  height: 60px;
  border-bottom: 1px solid ${color.neutral['40']};
  display: block;
  background-color: ${color.light};
`;

export const LayoutInline = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 60px);
`;

type MenuProps = {
  $closed?: boolean;
  onClick?: () => void;
};
export const MenuContainer = styled.div<MenuProps>`
  padding: 30px 30px;
  width: 300px;
  border-right: 1px solid ${color.neutral['40']};
  background: ${color.light};

  ${(props) =>
    props.$closed &&
    css`
      padding: 30px 0;
      width: 0;
      overflow: hidden;
    `};

  transition: all 0.2s ease;
  border-top: 1px solid ${color.neutral[30]};
`;

export const ContentContainer = styled.div`
  flex-grow: 2;
  overflow-y: auto;
  max-height: 100%;
`;

export type ColoredProps = {
  color?: string;
};

export const Colored = styled.span<ColoredProps>`
  color: ${(props) => (props.color ? props.color : color.primary[60])};
  padding-right: 0.4em;
`;
