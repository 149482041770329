import React, { FunctionComponent } from 'react';
import { HistoryEntryType } from '../../../@types/History/HistoryEntry';
import { Grid, GridItem } from '../../atoms/Grid/Grid';
import Text from '../../atoms/Text';
import HistoryEntry from '../../atoms/HistoryEntry/HistoryEntry';
import { BasicEmployeeType } from '../../../@types/Employee/EmployeeType';
import { parseDate } from '../../../helpers/date';
import { color } from '../../../styles/Variables';

type Props = {
  entries: HistoryEntryType[];
  itemCreatedAt?: string;
  itemCreatedBy?: BasicEmployeeType;
};

const HistoryModal: FunctionComponent<Props> = ({ entries, itemCreatedAt, itemCreatedBy }) => {
  return (
    <Grid spacing={1.4}>
      {entries.length === 0 && <Text>No changes found.</Text>}
      {entries.length !== 0 &&
        entries.map((entry, idx) => (
          <GridItem $desktop={12} key={idx}>
            <HistoryEntry entry={entry} />
          </GridItem>
        ))}
      {itemCreatedAt && (
        <div style={{ display: 'flex', gap: '0.3rem', width: '100%', marginBottom: '1rem' }}>
          <Text bold color={'grey'}>
            Item created at <span style={{ color: `${color.primary['60']}` }}>{parseDate(itemCreatedAt)}</span>
          </Text>
          {itemCreatedBy && (
            <Text bold color={'grey'}>
              by <span style={{ color: `${color.primary['60']}` }}>{itemCreatedBy.name}</span>
            </Text>
          )}
        </div>
      )}
    </Grid>
  );
};

export default HistoryModal;
