import styled from 'styled-components';
import { Container } from '../CommonStyles';
import { color } from '../../../../../styles/Variables';

export const ShortFormContainer = styled.div`
  ${Container};
  position: fixed;
  bottom: 24px;
  right: 88px;
  button {
    &:focus {
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: calc(100% + 4px);
        width: calc(100% + 4px);
        border-radius: 0.6rem;
        border: 1px solid ${color.primary[30]};
      }
    }
  }
`;
