import React, { FunctionComponent } from 'react';
import { color } from '../styles/Variables';

type PropType = {
  size?: number;
  iconColor?: string;
};

const RecreationIcon: FunctionComponent<PropType> = ({ size = 1, iconColor }) => {
  return (
    <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.85 15.65L17.75 13.3C18 13.2333 18.1875 13.0792 18.3125 12.8375C18.4375 12.5958 18.4667 12.35 18.4 12.1C18.3333 11.85 18.1875 11.6625 17.9625 11.5375C17.7375 11.4125 17.5 11.3833 17.25 11.45L14.8 12.1L10.8 8.35L9.4 8.7L11.8 12.9L9.4 13.5L8.15 12.55L7.2 12.8L8.85 15.65ZM20 20H4C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V14C2.55 14 3.02083 13.8042 3.4125 13.4125C3.80417 13.0208 4 12.55 4 12C4 11.45 3.80417 10.9792 3.4125 10.5875C3.02083 10.1958 2.55 10 2 10V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H20C20.55 4 21.0208 4.19583 21.4125 4.5875C21.8042 4.97917 22 5.45 22 6V18C22 18.55 21.8042 19.0208 21.4125 19.4125C21.0208 19.8042 20.55 20 20 20ZM20 18V6H4V8.55C4.61667 8.91667 5.10417 9.40417 5.4625 10.0125C5.82083 10.6208 6 11.2833 6 12C6 12.7167 5.82083 13.3792 5.4625 13.9875C5.10417 14.5958 4.61667 15.0833 4 15.45V18H20Z"
        fill={iconColor ?? 'currentColor'}
      />
    </svg>
  );
};

export default RecreationIcon;
