import React, { FunctionComponent, ReactNode } from 'react';
import styled, { CSSProperties } from 'styled-components';
import * as ReactPopover from '@radix-ui/react-popover';
import { color } from '../../../styles/Variables';

type Props = {
  children: ReactNode;
  popover: ReactNode | string;
  className?: string;
  position?: 'right' | 'top' | 'top-right' | 'center';
  duration?: number;
  onHover?: boolean;
  width?: number;
  style?: CSSProperties;
};

const Popover: FunctionComponent<Props> = ({ children, popover }) => {
  return (
    <ReactPopover.Root>
      <ReactPopover.Trigger asChild>{children}</ReactPopover.Trigger>
      <ReactPopover.Portal>
        <ReactPopover.Content>
          <StyledContent>{popover}</StyledContent>
        </ReactPopover.Content>
      </ReactPopover.Portal>
    </ReactPopover.Root>
  );
};

const StyledContent = styled.div`
  border: 1px solid ${color.neutral[40]};
  border-radius: 8px;
  background: ${color.light};

  width: max-content;
  max-width: 20rem;
  padding: 0.5rem;
`;

export default Popover;
