import React, { FunctionComponent } from 'react';
import Text from '../../atoms/Text';
import IriHelper from '../../../helpers/iri-helper';
import { TextProps } from '../../atoms/Text/Text';

type Props = Omit<TextProps, 'children'> & {
  withHash: boolean;
  value: string | number;
};

const IdText: FunctionComponent<Props> = ({ withHash, value, ...props }) => {
  return (
    <Text color={'primary'} {...props} as={'span'} bold>
      {withHash && '#'}
      {typeof value === 'number' ? value : IriHelper.iriToId(value as string)}
    </Text>
  );
};

export default IdText;
