import { DictValue } from '../../@types/Dictionary/DictValue';

export const ContractAppliesDict: DictValue[] = [
  {
    value: 'general',
    label: 'General',
  },
  {
    value: 'partner',
    label: 'Partner',
  },
  {
    value: 'project',
    label: 'Project',
  },
  {
    value: 'purchase_order',
    label: 'Purchase order',
  },
  {
    value: 'employee',
    label: 'Employee',
  },
];

export const getContractAppliesLabel = (value: string): string => {
  const item = ContractAppliesDict.find((item) => item.value === value);
  return item ? item.label : '';
};
