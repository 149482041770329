import React from 'react';
import { LocaleType } from '../../legacy/CapeMorris/@types';

export const DEFAULT_LOCALE = 'pl';

export type AuthContextType = {
  locale: LocaleType;
  availableLocales: LocaleType[];
};

export const LocaleContext = React.createContext<AuthContextType>({
  locale: DEFAULT_LOCALE,
  availableLocales: ['pl', 'en', 'de'],
});
