import React, { FunctionComponent, useEffect, useState } from 'react';
import RichTextInput from '../../../../Form/RichTextInput/RichTextInput';
import { useFormContext } from 'react-hook-form';

type Props = {
  onChange: (note: string) => void;
};

const PrivateNoteInput: FunctionComponent<Props> = ({ onChange }) => {
  const [init, setInit] = useState(false);
  const { watch } = useFormContext();
  const value = watch('content');

  useEffect(() => {
    if (!init) {
      setInit(true);
      return;
    }
    const timeout = setTimeout(() => {
      onChange(value);
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [value]);

  return <RichTextInput placeholder={'You can write anything here. This is your private space. It saves automatically.'} name={'content'} />;
};

export default PrivateNoteInput;
