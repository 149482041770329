import React, { FunctionComponent, useMemo } from 'react';
import * as Styled from './Items.styled';
import Avatar from '../../../../atoms/Avatar/Avatar';
import Text from '../../../../atoms/Text';
import { parseDate } from '../../../../../helpers/date';
import { DashboardDataAbsenceType } from '../../../../../@types/Dashboard/DashboardDataAbsenceType';
import { fontWeight } from '../../../../../styles/Variables';

type Props = {
  homeOffice: DashboardDataAbsenceType;
};

const HomeOfficesCardItem: FunctionComponent<Props> = ({ homeOffice }) => {
  const multipleDays = useMemo(() => {
    return homeOffice.days > 1;
  }, [homeOffice]);

  return (
    <Styled.CardItemWrapper $cursor={'default'}>
      <Avatar name={homeOffice.employee.name} size={3} photoUrl={homeOffice.employee.profilePhotoUrl} />
      <Styled.NameWrapper>
        <Text size={'l'} bold>
          {homeOffice.employee.name}
        </Text>

        <Styled.InlineWrapper>
          <Text color={'grey'} size={'xs'} style={{ fontWeight: fontWeight.medium }}>
            {parseDate(homeOffice.dateFrom, false, false, true)} {multipleDays ? ` - ${parseDate(homeOffice.dateTo, false, false, true)}` : ''}
          </Text>
          <Text color={'grey'} size={'xs'} style={{ fontWeight: fontWeight.medium }}>
            ({homeOffice.days} day{multipleDays ? 's' : ''})
          </Text>
        </Styled.InlineWrapper>
      </Styled.NameWrapper>
    </Styled.CardItemWrapper>
  );
};

export default HomeOfficesCardItem;
