import React, { FunctionComponent } from 'react';
import ListColumn, { ListColumnType } from '../../molecules/Projects/ListColumn/ListColumn';
import { Grid, GridItem } from 'components/atoms/Grid/Grid';
import Button from '../../atoms/Button/Button';
import Heading from '../../atoms/Heading';
import useProjectForm from '../../../hooks/useProjectForm';
import ArchivedIcon from '../../../Icons/Archived.icon';
import { color } from '../../../styles/Variables';
import PlusIcon from '../../../Icons/Plus.icon';
import { ColumnsGrid } from './ProjectAll.styled';

interface OwnProps {
  columns: ListColumnType[];
  onFilter: () => void;
  showArchived: boolean;
}

type PropType = OwnProps;

const ProjectAll: FunctionComponent<PropType> = ({ columns, onFilter, showArchived }) => {
  const { show } = useProjectForm();

  return (
    <>
      <Grid spacing={2} $justifyContent={'space-between'}>
        <GridItem>
          <Heading>All projects</Heading>
        </GridItem>
        <GridItem style={{ display: 'flex', flexDirection: 'row', gap: '2rem' }}>
          <GridItem>
            <Button
              id={'show-hide-archived'}
              $text={showArchived ? 'Hide archived' : 'Show archived'}
              onClick={onFilter}
              kind={'link'}
              $icon={<ArchivedIcon iconColor={color.primary['80']} />}
            />
          </GridItem>
          <GridItem>
            <Button
              id={'open-project-form'}
              $text={'Add new project'}
              onClick={() => {
                show();
              }}
              $icon={<PlusIcon />}
            />
          </GridItem>
        </GridItem>
      </Grid>
      <ColumnsGrid>
        {columns &&
          columns.map((column, i) => (
            <GridItem key={i} $desktop={i === 2 ? 6 : 3}>
              <ListColumn
                items={column.items}
                name={column.name}
                onSelect={column.onSelect}
                isLoading={column.isLoading}
                button={column.button}
                itemType={column.itemType}
                clientId={column.clientId}
              />
            </GridItem>
          ))}
      </ColumnsGrid>
    </>
  );
};

export default ProjectAll;
