import React, { FunctionComponent, useCallback } from 'react';
import { GridItem } from '../../../atoms/Grid/Grid';
import MoneyInput from '../../../molecules/Form/MoneyInput/MoneyInput';
import DatePicker from '../../../molecules/Form/DatePicker/DatePicker';
import StaticSelectInput from '../../../molecules/Form/SelectInput/StaticSelectInput';
import { PaymentDict } from '../../../../@dicts/Payment/PaymentDict';
import { PaymentType } from '../../../../@types/Finances/PaymentType';
import { useFormContext } from 'react-hook-form';
import { MoneyType } from '../../../../@types/Money/MoneyType';
import Button from '../../../atoms/Button/Button';
import moneyAsString from '../../../../helpers/moneyAsString';

type Props = {
  onValueChange: (value: number) => void;
  payment: Partial<PaymentType>;
  remain: MoneyType;
  onCancel: () => void;
};

const SinglePaymentCardForm: FunctionComponent<Props> = ({ onValueChange, payment, remain, onCancel }) => {
  const { setValue, watch } = useFormContext();
  const currentValue = watch('value.value');

  const fillRemain = useCallback(() => {
    setValue('value.value', +currentValue + remain.value);
    onValueChange(remain.value + +currentValue);
  }, [remain, onValueChange, currentValue]);

  return (
    <>
      <GridItem $desktop={4}>
        <MoneyInput onChange={onValueChange} name={'value'} currency={payment.value?.currency ?? 'PLN'} label={'Amount'} defaultMoney={payment.value} />
      </GridItem>
      <GridItem $desktop={4}>
        <DatePicker name={'paidAt'} placeholder={'Payment date'} label={'Payment date'} defaultValue={payment.paidAt} required />
      </GridItem>
      <GridItem $desktop={4}>
        <StaticSelectInput name={'type'} options={PaymentDict} label={'Payment method'} required defaultValue={payment.type} />
      </GridItem>
      {remain.value !== 0 && (
        <GridItem $desktop={6}>
          <Button full type="button" small kind={'ghost'} $text={`Add ${moneyAsString(remain)}`} onClick={fillRemain} />
        </GridItem>
      )}
      <GridItem style={{ marginLeft: 'auto' }}>
        <Button
          type={'button'}
          $text={'Cancel'}
          kind={'secondary'}
          small={true}
          onClick={() => {
            onValueChange(payment.value?.value ?? 0);
            onCancel();
          }}
        />
      </GridItem>
      <GridItem>
        <Button type={'submit'} $text={'Save'} kind={'positive'} small={true} />
      </GridItem>
    </>
  );
};

export default SinglePaymentCardForm;
