import React, { FunctionComponent } from 'react';
import { Route, Routes } from 'react-router-dom';
import ContactsScreen from './screens/ContactsScreen';

const ContactsModule: FunctionComponent = () => {
  return (
    <Routes>
      <Route path="" element={<ContactsScreen />}></Route>
    </Routes>
  );
};

export default ContactsModule;
