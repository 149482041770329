import React, { FunctionComponent } from 'react';

type PropType = {
  size?: number;
  color?: string;
};

const BigCalendarInTheCircleIcon: FunctionComponent<PropType> = ({ size = 1, color }) => {
  return (
    <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5384 22.8334C11.2016 22.8334 10.9166 22.7167 10.6833 22.4834C10.4499 22.2501 10.3333 21.965 10.3333 21.6283V12.7052C10.3333 12.3685 10.4499 12.0834 10.6833 11.8501C10.9166 11.6168 11.2016 11.5001 11.5384 11.5001H12.4615V10.0898H13.4871V11.5001H18.5384V10.0898H19.5383V11.5001H20.4614C20.7982 11.5001 21.0832 11.6168 21.3166 11.8501C21.5499 12.0834 21.6666 12.3685 21.6666 12.7052V21.6283C21.6666 21.965 21.5499 22.2501 21.3166 22.4834C21.0832 22.7167 20.7982 22.8334 20.4614 22.8334H11.5384ZM11.5384 21.8334H20.4614C20.5127 21.8334 20.5597 21.812 20.6025 21.7693C20.6452 21.7266 20.6666 21.6795 20.6666 21.6283V15.3719H11.3332V21.6283C11.3332 21.6795 11.3546 21.7266 11.3973 21.7693C11.4401 21.812 11.4871 21.8334 11.5384 21.8334ZM11.3332 14.3719H20.6666V12.7052C20.6666 12.6539 20.6452 12.6069 20.6025 12.5642C20.5597 12.5214 20.5127 12.5001 20.4614 12.5001H11.5384C11.4871 12.5001 11.4401 12.5214 11.3973 12.5642C11.3546 12.6069 11.3332 12.6539 11.3332 12.7052V14.3719Z"
        fill={color}
      />
      <rect x="0.5" y="1" width="31" height="31" rx="15.5" stroke={color} />
    </svg>
  );
};

export default BigCalendarInTheCircleIcon;
