import React, { FunctionComponent } from 'react';
import * as Styled from './Attachment.icon.styled';
import { getResourceType } from '../../helpers/getResourceType';

type Props = {
  name: string;
  url?: string | undefined;
};

const AttachmentIcon: FunctionComponent<Props> = ({ name, url }) => {
  const extension = name.split('.').pop() ?? '-';

  return (
    <>
      {!!url && <Styled.AttachmentImage src={url} />}
      {!url && <Styled.AttachmentIcon>{extension}</Styled.AttachmentIcon>}
    </>
  );
};

export default AttachmentIcon;
