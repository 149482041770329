import React from 'react';
import { CellTemplate } from '../../CellTemplate';
import { TaskListType, TaskType } from '../../../../../@types/Task/TaskType';
import TimeProgress from '../../../TimeProgress/TimeProgress';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import EditableDetail from '../../../EditableDetail/EditableDetail';
import TaskEstimationDropdown from '../../../Task/TaskForm/TaskEstimationDropdown/TaskEstimationDropdown';

const TaskProgressTemplate: CellTemplate<number | string, any, TaskType | TaskListType> = ({ item }) => {
  return (
    <Grid>
      <GridItem $desktop={12}>
        <EditableDetail<TaskType | TaskListType>
          name={'estimate'}
          subject={item}
          show={<TimeProgress unit={'minutes'} current={item.spentTime} estimated={Number(item.estimate)} width={20} />}
          component={TaskEstimationDropdown}
        />
      </GridItem>
    </Grid>
  );
};

export default TaskProgressTemplate;
