import React, { FunctionComponent, ReactElement, useCallback, useMemo } from 'react';
import DropdownButton from '../../../../molecules/DropdownButton/DropdownButton';
import CompleteIcon from '../../../../../Icons/Complete.icon';
import PlayIcon from '../../../../../Icons/Play.icon';
import ReviewIcon from '../../../../../Icons/Review.icon';
import { TaskType } from '../../../../../@types/Task/TaskType';
import { DropdownMenuItemType } from '../../../DropdownMenu/DropdownMenuItem/DropdownMenuItem';
import { Icon } from '../../../../atoms/Icon/Icon';
import useApi from '../../../../../hooks/useApi';
import Button from '../../../../atoms/Button/Button';
import AcceptIcon from '../../../../../Icons/Accept.icon';
import ReopenIcon from '../../../../../Icons/Reopen.icon';
import { color } from '../../../../../styles/Variables';
import StopIcon from '../../../../../Icons/Stop.icon';
import { useEntityContext } from '../../../../../Context/EntityContext/EntityContext';
import Text from '../../../../atoms/Text';

type Props = {
  task: TaskType;
  disabled?: boolean;
  onChange?: () => void;
};

const TaskTopBarDropdownButton: FunctionComponent<Props> = ({ task, disabled, onChange }) => {
  const { put } = useApi();
  const { replace } = useEntityContext<TaskType>();
  const changeStatus = useCallback(
    (status: string) => {
      put(`tasks/${task.id}/status`, { status: status }).then((response) => {
        replace(response.data);
        onChange && onChange();
      });
    },
    [task],
  );

  const taskActions = useMemo<{
    mainButton: {
      displayedText: string;
      icon: ReactElement;
      onClick: () => void;
    };
  } | null>(() => {
    switch (task.status) {
      case 'todo':
        return {
          mainButton: {
            displayedText: 'Start task',
            icon: <PlayIcon />,
            onClick: () => changeStatus('in_progress'),
          },
        };
      case 'in_progress':
        if (task.reviewer) {
          return {
            mainButton: {
              displayedText: 'Send to review',
              icon: <ReviewIcon />,
              onClick: () => changeStatus('in_review'),
            },
          };
        } else {
          return {
            mainButton: {
              displayedText: 'Send to acceptance',
              icon: <AcceptIcon />,
              onClick: () => changeStatus('acceptance'),
            },
          };
        }
      case 'in_review':
        return {
          mainButton: {
            displayedText: 'Send to acceptance',
            icon: <AcceptIcon />,
            onClick: () => changeStatus('acceptance'),
          },
        };
      case 'acceptance':
        return {
          mainButton: {
            displayedText: 'Complete',
            icon: <CompleteIcon />,
            onClick: () => changeStatus('completed'),
          },
        };
      case 'completed':
        return null;
    }
  }, [task]);

  const additionalActions: DropdownMenuItemType[] = [
    {
      text: <Text color={'neutral50'}>change status:</Text>,
    },
    {
      text: 'In progress',
      icon: (
        <Icon color={color.neutral['80']}>
          <PlayIcon />
        </Icon>
      ),
      onClick: () => changeStatus('in_progress'),
    },
    {
      text: 'Review',
      icon: (
        <Icon color={color.neutral['80']}>
          <ReviewIcon />
        </Icon>
      ),
      onClick: () => changeStatus('in_review'),
    },
    {
      text: 'Acceptance',
      icon: (
        <Icon color={color.neutral['80']}>
          <AcceptIcon />
        </Icon>
      ),
      onClick: () => changeStatus('acceptance'),
    },

    {
      text: 'Completed',
      icon: (
        <Icon color={color.neutral['80']}>
          <CompleteIcon />
        </Icon>
      ),
      onClick: () => changeStatus('completed'),
    },
    {
      text: task.status === 'completed' ? 'Reopen' : 'Pause',
      icon: <Icon color={color.neutral['80']}>{task.status === 'completed' ? <ReopenIcon /> : <StopIcon />}</Icon>,
      onClick: () => changeStatus('todo'),
    },
  ];

  return (
    <>
      {task.status !== 'completed' ? (
        <DropdownButton
          $text={taskActions ? taskActions.mainButton.displayedText : 'Task completed'}
          $icon={taskActions ? taskActions.mainButton.icon : <CompleteIcon />}
          dropdownMenuItems={additionalActions}
          triggerType={'click'}
          onClick={taskActions ? taskActions.mainButton.onClick : () => {}}
          disabled={disabled}
        />
      ) : (
        <Button $text={'Reopen'} onClick={() => changeStatus('todo')} $icon={<ReopenIcon />} disabled={disabled} />
      )}
    </>
  );
};

export default TaskTopBarDropdownButton;
