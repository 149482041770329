import React, { FunctionComponent } from 'react';

type Props = {};

const TransformIcon: FunctionComponent<Props> = () => {
  return (
    <svg fill="#000000" height="800px" width="800px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 344.581 344.581">
      <path
        fill="currentColor"
        d="M341.176,258.009l-58.024-40.053c-4.527-3.123-8.151-1.179-8.151,4.321v25.207h-47.913c-4.122,0-10.803-3.876-12.768-7.41
	L176.59,172.28l37.731-67.986c2.029-3.649,8.83-7.81,12.766-7.81H275v25.82c0,5.5,3.624,7.445,8.151,4.322l58.024-40.054
	c4.528-3.123,4.548-8.233,0.02-11.356l-58.044-40.053c-4.527-3.123-8.151-1.178-8.151,4.322v25h-47.913
	c-15.531,0-33.042,10.429-40.739,24.27l-28.082,50.6L133.27,94.439c-7.595-13.656-25.105-23.955-40.731-23.955H16
	c-8.837,0-16,7.163-16,16s7.163,16,16,16h76.539c4.094,0,10.774,3.929,12.768,7.512l34.674,62.305l-34.676,62.482
	c-2.041,3.671-8.722,7.701-12.765,7.701H16c-8.837,0-16,7.163-16,16c0,8.837,7.163,16,16,16h76.539
	c15.564,0,33.075-10.382,40.738-24.16l25.027-45.096l28.05,50.402c7.563,13.599,25.073,23.854,40.732,23.854H275v25.613
	c0,5.5,3.624,7.445,8.151,4.322l58.044-40.054C345.723,266.242,345.703,261.132,341.176,258.009z"
      />
    </svg>
  );
};

export default TransformIcon;
