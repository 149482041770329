import { DictValue } from '../../@types/Dictionary/DictValue';
import { CostType } from '../../@types/Finances/Cost/CostType';

export const DocumentTypeDict: DictValue<{}, CostType['documentType']>[] = [
  {
    value: 'invoice',
    label: 'Invoice',
  },
  {
    value: 'receipt',
    label: 'Receipt',
  },
  {
    value: 'taxes',
    label: 'Taxes',
  },
  {
    value: 'salary',
    label: 'Salary',
  },
];
