import React from 'react';
import { CellTemplate } from '../../../CellTemplate';
import InfoIcon from '../../../../../../Icons/info.icon';
import { ProjectListItemType, WorkTimeType } from '../../../../../../@types/Project/Project';
import SingleEntry from './SingleEntry';
import { Icon } from '../../../../../atoms/Icon/Icon';
import Tooltip from '../../../../Tooltip/Tooltip';
import { color } from '../../../../../../styles/Variables';

const WorkTimeTemplate: CellTemplate<WorkTimeType, {}, ProjectListItemType> = ({ value, item }) => {
  return (
    <Tooltip
      content={
        <span>
          {item.developmentWork && <SingleEntry value={item.developmentWork} department={'Development'} />}
          {item.creationWork && <SingleEntry value={item.creationWork} department={'Creation'} />}
          {item.mediaWork && <SingleEntry value={item.mediaWork} department={'Media'} />}
          {item.socialMediaWork && <SingleEntry value={item.socialMediaWork} department={'Social Media'} />}
          {item.clientServiceWork && <SingleEntry value={item.clientServiceWork} department={'Partners Care'} />}
          {item.strategyWork && <SingleEntry value={item.strategyWork} department={'Strategy'} />}
          {item.administrationWork && <SingleEntry value={item.administrationWork} department={'Administration'} />}
          {item.marketingWork && <SingleEntry value={item.marketingWork} department={'Marketing'} />}
          {item.peopleAndCultureWork && <SingleEntry value={item.peopleAndCultureWork} department={'P&C'} />}
          {item.designStudioWork && <SingleEntry value={item.designStudioWork} department={'ID Studio'} />}
        </span>
      }
      trigger={
        <Icon color={color.primary['60']} size={2}>
          <InfoIcon />
        </Icon>
      }
      arrowPosition={'leftTop'}
    />
  );
};

export default WorkTimeTemplate;
