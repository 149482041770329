import styled, { css } from 'styled-components';
import { color, fontSize, fontWeight } from '../../../styles/Variables';
import { Text, TextType } from '../Text/Text.styled';

type WrapperProps = {
  $isActive: boolean;
};

const CommonStyles = css`
  font-size: ${fontSize.body.l};
  color: ${color.neutral[60]};
  flex-direction: column;
  display: flex;
  cursor: pointer;
  background-color: transparent;
`;

export const Underline = styled.div`
  width: 0;
  height: 2px;
  background-color: ${color.primary[60]};
  transition: width 150ms cubic-bezier(0.67, 0.2, 1, 0.72);
`;

export const Wrapper = styled.div<WrapperProps>`
  ${CommonStyles};
  transition: color 150ms cubic-bezier(0.67, 0.2, 1, 0.72);

  ${({ $isActive }) =>
    $isActive &&
    css`
      ${Underline} {
        width: 100%;
      }

      color: ${color.neutral[80]};
    `}
`;

export const TabContainer = styled.div`
  padding-bottom: 1.2rem;
  display: flex;
  align-items: center;
  transition: color 150ms ease-in-out;
  gap: 0.4rem;

  &:hover {
    color: ${color.primary[60]};
  }
`;

export const Count = styled.div<TextType>`
  ${Text};
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  background-color: ${color.primary[60]};
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0.8rem;
  color: ${color.light};
  font-weight: ${fontWeight.bold};
`;
