import React from 'react';
import useOverlay from './useOverlay';
import { ProjectType } from '../@types/Project/Project';
import { useCallback } from 'react';
import ProjectForm from '../components/organisms/Projects/Forms/ProjectForm/ProjectForm';

type Props = {};

type Result = {
  show: (project?: ProjectType, onSuccess?: (project: ProjectType) => void, categoryId?: number, clientId?: number) => void;
};

function useProjectForm(): Result {
  const { showOverlay } = useOverlay();

  const show = useCallback((project?: ProjectType, onSuccess?: (project: ProjectType) => void, categoryId?: number, clientId?: number) => {
    showOverlay({
      body: <ProjectForm project={project} onSuccess={onSuccess} categoryId={categoryId} clientId={clientId} />,
    });
  }, []);

  return {
    show,
  };
}

export default useProjectForm;
