import React, { FunctionComponent, useCallback, useEffect } from 'react';
import Button from '../../atoms/Button/Button';
import DeleteIcon from '../../../Icons/delete.icon';
import useModal from '../../../hooks/useModal';

type Props = {
  show: number | undefined | null;
  onConfirm: () => Promise<void>;
  title: string;
};

const DeleteModal: FunctionComponent<Props> = ({ show, title, onConfirm }) => {
  const { showModal, hideModal } = useModal();

  const handleShow = useCallback(() => {
    showModal({
      title: title,
      body: <span>Are you sure you want to delete this element?</span>,
      footer: (
        <>
          <Button $text={'Cancel'} type={'button'} kind={'ghost'} onClick={hideModal} />
          <Button
            $text={'Delete'}
            type={'button'}
            kind={'negative'}
            $icon={<DeleteIcon />}
            onClick={() => {
              onConfirm().then(() => {
                hideModal();
              });
            }}
          />
        </>
      ),
    });
  }, []);
  useEffect(() => {
    if (!show) {
      hideModal();
    } else {
      handleShow();
    }
  }, [show]);
  return <></>;
};

export default DeleteModal;
