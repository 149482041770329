import React, { FunctionComponent } from 'react';
import * as Styled from './DashboardCard.styled';
import CardHeading from '../../../../molecules/CardHeading/CardHeading';

type Props = {
  title: string;
  icon?: JSX.Element;
  action?: JSX.Element;
  children?: JSX.Element | JSX.Element[];
};

const DashboardCard: FunctionComponent<Props> = ({ title, icon, action, children }) => {
  return (
    <Styled.DashboardCardStyled>
      <CardHeading heading={title} icon={icon} action={action} />
      {children}
    </Styled.DashboardCardStyled>
  );
};

export default DashboardCard;
