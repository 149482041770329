import React, { FunctionComponent } from 'react';
import DashboardCard from '../DashboardCard';
import TaskAltIcon from '../../../../../../Icons/TaskAlt.icon';
import { color } from '../../../../../../styles/Variables';
import Button from '../../../../../atoms/Button/Button';
import Tabs, { TabObject } from '../../../../../molecules/Tabs/Tabs';
import TaskCardsContentCard from '../../../../../molecules/Projects/Dashboard/DashboardCardContentCards/TaskCardsContentCard';
import useUser from '../../../../../../hooks/useUser';
import useTasksProvider from '../../../../../../hooks/useTasksProvider';
import { useNavigate } from 'react-router-dom';
import { TaskListType } from '../../../../../../@types/Task/TaskType';

const AssignedTasks: FunctionComponent = () => {
  const user = useUser();
  const navigate = useNavigate();
  const upcomingLimit = new Date();
  upcomingLimit.setDate(upcomingLimit.getDate() + 8);
  const twoWeeksAgo = new Date().getTime() - 14 * 24 * 60 * 60 * 1000;
  const today = new Date();

  const { data: overdueTasks } = useTasksProvider(
    'MyTasks::Overdue',
    {
      'createdBy.id': user.employeeId,
      'deadline[strictly_before]': new Date(today).toISOString().slice(0, 10),
      status: ['todo', 'in_progress', 'in_review', 'acceptance'],
    },
    undefined,
    false,
  );

  const additionalFilterFunction = (tasks: TaskListType[] | undefined) => {
    return tasks?.filter((task) => task.assignee.id !== user.employeeId) ?? [];
  };

  const assignedTasksTabs: TabObject[] = [
    {
      tabName: 'Upcoming',
      tabIdName: 'tab-assigned-tasks-upcoming',
      component: (
        <TaskCardsContentCard
          ctx={'AssignedTasks::Upcoming'}
          filters={{
            'createdBy.id': user.employeeId,
            'deadline[before]': upcomingLimit.toISOString().slice(0, 10),
            status: ['todo', 'in_progress', 'in_review', 'acceptance'],
          }}
          key={1}
          additionalFilterFunction={additionalFilterFunction}
          taskFormType={'short'}
        />
      ),
    },
    {
      tabName: 'Overdue',
      tabIdName: 'tab-assigned-tasks-overdue',
      component: (
        <TaskCardsContentCard
          ctx={'AssignedTasks::Overdue'}
          filters={{
            'createdBy.id': user.employeeId,
            'deadline[strictly_before]': new Date(today).toISOString().slice(0, 10),
            status: ['todo', 'in_progress', 'in_review', 'acceptance'],
          }}
          key={2}
          additionalFilterFunction={additionalFilterFunction}
          taskFormType={'short'}
        />
      ),
      count: additionalFilterFunction(overdueTasks ? overdueTasks : [])?.length || 0,
    },
    {
      tabName: 'Done',
      tabIdName: 'tab-assigned-tasks-done',
      component: (
        <TaskCardsContentCard
          ctx={'AssignedTasks::Completed'}
          filters={{
            'createdBy.id': user.employeeId,
            'completedAt[after]': new Date(twoWeeksAgo).toISOString().slice(0, 10),
            'order[completedAt]': 'DESC',
            completed: 1,
          }}
          key={3}
          taskFormType={'none'}
          additionalFilterFunction={additionalFilterFunction}
        />
      ),
    },
  ];
  return (
    <DashboardCard
      title={'Assigned tasks'}
      icon={<TaskAltIcon size={2} color={color.primary['60']} />}
      action={<Button $text={'Show all assigned tasks'} kind={'link'} onClick={() => navigate('/projects/my-work/assigned-tasks')} />}
    >
      <Tabs tabs={assignedTasksTabs} />
    </DashboardCard>
  );
};

export default AssignedTasks;
