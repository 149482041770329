import { TableFiltersType } from '../../../../../@types/Table/TableFilterType';
import FrequentFilters from '../../../../../@types/Table/FrequentFilters';

const filters: (projectId: number) => TableFiltersType = (projectId: number) => {
  return [
    {
      type: 'select',
      name: 'task.id',
      label: 'Task name',
      options: {
        baseUrl: '/tasks',
        optionLabel: 'title',
      },
      gridProps: {
        $desktop: 4,
      },
    },
    {
      type: 'select',
      name: 'employee.id',
      label: 'Employee',
      options: {
        baseUrl: '/employees',
        optionLabel: 'name',
      },
      gridProps: {
        $desktop: 2,
      },
    },

    {
      ...FrequentFilters.department,
      name: 'employee.department.id',
      gridProps: {
        $desktop: 2,
      },
    },
    {
      name: 'timesheetDay.day',
      type: 'month',
      label: 'Month',
      gridProps: {
        $desktop: 2,
      },
    },
    {
      type: 'select',
      name: 'task.section.id',
      label: 'Section',
      options: {
        baseUrl: '/sections',
        optionLabel: 'name',
        baseUrlParams: { 'project.id': projectId },
      },
      gridProps: {
        $desktop: 2,
      },
    },
  ];
};

export default filters;
