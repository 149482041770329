import React, { FunctionComponent } from 'react';

type Props = {
  width?: string;
};

const Pumpkin: FunctionComponent<Props> = ({ width }) => {
  return (
    <svg fill="#000000" style={{ width: width }} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.664 511.664">
      <g>
        <path
          fill="orange"
          d="M476.016,166.4c-20.587-25.707-48.64-40.747-81.173-43.413c-26.667-2.133-51.84,4.267-73.28,18.667
  c-10.133-8-21.547-14.187-33.707-18.347c1.387-27.627,8.64-47.147,21.973-58.133c17.067-14.08,39.253-9.28,39.467-9.28
  c5.76,1.387,11.413-2.24,12.8-7.893c0.213-0.747,0.32-1.6,0.32-2.453v-34.88c0-5.867-4.693-10.56-10.453-10.667
  c-1.067,0-2.133,0-3.093,0C244.656,0,220.443,86.507,214.896,122.667c-10.987,3.52-21.44,8.64-30.933,15.36
  c-20.267-11.84-43.84-17.067-67.2-15.04c-32.533,2.667-60.587,17.707-81.067,43.413C5.189,204.48-6.224,263.573,3.483,332.8
  c18.667,132.16,79.893,178.773,133.76,178.773c2.56,0,5.12-0.107,7.68-0.32c19.2-1.6,36.693-8.853,52.267-21.653
  c16.427,11.413,34.347,17.173,53.44,17.173h0.32c21.333-0.107,41.067-7.147,58.667-21.013
  c16.533,15.253,35.733,23.787,57.067,25.6c55.68,4.48,122.027-39.893,141.44-178.453
  C517.936,263.573,506.523,204.48,476.016,166.4z M341.083,21.547V33.92c-16.107-0.64-31.893,4.587-44.48,14.72
  c-17.6,14.293-27.52,37.867-29.76,69.973c-5.547-0.747-11.093-1.173-16.64-1.28h-0.427c-4.267,0-8.533,0.32-12.8,0.853
  C242.949,85.44,264.603,25.387,341.083,21.547z M487.216,330.133c-14.4,102.187-61.013,165.333-118.72,160.32
  c-18.987-1.6-35.947-10.347-50.453-26.133c-1.92-2.133-4.587-3.307-7.467-3.413h-0.96c-2.667,0-5.227,1.067-7.147,2.88
  c-15.147,14.293-32.427,21.653-51.093,21.653h-0.96c-16.853,0-32.747-5.973-47.147-17.813c-4.16-3.413-10.24-3.2-14.187,0.533
  c-13.547,13.12-29.12,20.48-46.08,21.867c-57.6,5.013-104.213-58.133-118.613-160.107c-8.853-63.253,0.853-116.587,27.733-150.08
  c16.853-21.013,39.68-33.28,66.24-35.52c21.227-1.92,42.347,3.627,60.053,15.573c3.84,2.667,8.96,2.453,12.693-0.533
  c16.64-13.44,37.333-20.693,58.667-20.693h0.533c23.573-0.213,46.4,8.533,63.787,24.533c3.84,3.52,9.707,3.733,13.76,0.533
  c18.667-14.613,41.387-21.333,65.6-19.413c26.56,2.133,49.493,14.507,66.24,35.52
  C486.256,213.333,496.069,266.667,487.216,330.133z"
        />
        <path
          fill="orange"
          d="M425.669,279.467c-4.587-2.027-10.027-0.427-12.907,3.627c-19.733,27.947-44.8,49.387-74.453,63.787
  c-4.48,2.24-6.933,7.36-5.653,12.267l1.813,6.933l-20.8,5.333l-0.747-2.987c-1.387-5.653-7.147-9.067-12.8-7.787
  c-29.12,6.933-59.52,6.933-88.64-0.107c-5.547-1.387-11.2,1.92-12.8,7.467l-0.96,3.52l-20.8-5.867l1.813-6.4
  c1.387-5.013-0.96-10.24-5.547-12.48c-29.547-14.4-54.613-35.84-74.24-63.787c-3.413-4.8-10.027-5.973-14.827-2.56
  c-3.2,2.24-4.907,6.08-4.48,10.027c0.213,1.493,18.88,144.213,153.707,158.72c0.427,0,0.747,0.107,1.173,0.107
  c5.867,0,10.667-4.8,10.667-10.667v-11.947h21.333v11.947c0,3.093,1.28,5.973,3.627,8c2.24,2.027,5.333,2.987,8.32,2.667
  c123.627-14.507,153.173-156.373,153.493-157.867C432.816,286.4,430.256,281.493,425.669,279.467z M287.749,426.027V416
  c0-5.867-4.8-10.667-10.667-10.667h-42.667c-5.867,0-10.667,4.8-10.667,10.667v10.347
  c-65.707-11.733-96.853-59.307-111.253-95.36c13.12,12.053,27.627,22.507,43.307,30.933l-2.347,8.32
  c-1.6,5.653,1.6,11.52,7.253,13.227c0,0,0,0,0.107,0l41.173,11.733c5.653,1.6,11.52-1.707,13.227-7.36l1.173-4.16
  c25.813,4.907,52.373,5.013,78.293,0.107l0.853,3.413c1.493,5.76,7.253,9.173,13.013,7.68l41.493-10.56
  c5.653-1.493,9.173-7.253,7.68-13.013l-2.347-9.067c13.653-7.253,26.347-16.107,38.08-26.133
  C376.069,371.2,343.963,414.507,287.749,426.027z"
        />
        <path
          fill="orange"
          d="M203.483,313.92c1.813,3.733,5.547,6.08,9.6,6.08h85.333c5.867,0,10.667-4.8,10.667-10.667c0-2.453-0.853-4.8-2.347-6.72
  l-42.667-53.333c-4.053-5.12-12.587-5.12-16.64,0l-42.667,53.333C202.203,305.92,201.669,310.293,203.483,313.92z
  M255.749,273.067l20.48,25.6h-40.96L255.749,273.067z"
        />
        <path d="M292.976,244.373h0.21c-0.098-0.05-0.176-0.089-0.21-0.107V244.373z" fill="orange" />
        <path
          fill="orange"
          d="M291.696,243.093c0.533,0.533,1.173,0.96,1.813,1.28h-0.323c2.323,1.179,22.412,11.115,55.576,11.627h0.533
  c18.56,0,33.28-8.853,40.533-24.32c8.107-17.173,5.227-39.787-6.827-53.76c-4.053-4.693-10.027-7.36-16.213-7.253h-0.213
  c-25.707,0.107-64.853,43.947-76.16,57.387C286.576,232.533,287.216,239.253,291.696,243.093z M366.896,191.893
  c6.507,7.573,8.107,21.12,3.627,30.827c-3.84,8.107-10.987,12.587-21.867,12.053c-10.773-0.107-21.44-1.6-31.787-4.267
  C337.989,207.893,360.496,190.293,366.896,191.893z"
        />
        <path
          fill="orange"
          d="M162.203,256h0.96c34.027-0.533,54.08-11.093,54.933-11.52c5.227-2.773,7.147-9.28,4.373-14.4
  c-0.32-0.64-0.747-1.28-1.28-1.813c-11.307-13.547-50.56-57.493-76.373-57.6c-6.72,0.427-12.267,2.453-16.427,7.253
  c-12.053,13.867-14.827,36.48-6.827,53.76C128.923,247.147,143.643,256,162.203,256z M144.283,192
  c0.32-0.107,0.64-0.107,0.96-0.107c7.68,0,29.227,16.96,49.387,38.507c-10.347,2.667-21.013,4.16-31.787,4.267
  c-10.773,0.533-18.027-3.84-21.867-12.053C136.496,213.013,138.096,199.467,144.283,192z"
        />
      </g>
    </svg>
  );
};

export default Pumpkin;
