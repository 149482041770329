import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Grid, GridItem } from '../../atoms/Grid/Grid';

type Props = PropsWithChildren<{}>;

const FinanceColumn: FunctionComponent<Props> = ({ children }) => {
  return (
    <GridItem $desktop={6}>
      <Grid spacing={4} style={{ position: 'sticky', top: 0, paddingBottom: '2rem' }}>
        {children}
      </Grid>
    </GridItem>
  );
};

export default FinanceColumn;
