import styled from 'styled-components';
import { color } from '../../../styles/Variables';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 0;
  border-top: 1px solid ${color.neutral['40']};
  border-bottom: 1px solid ${color.neutral['40']};
  margin-bottom: 2.4rem;
`;

export const CollapseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;
