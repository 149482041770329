import React, { FunctionComponent } from 'react';

type PropType = {
  size?: number;
  color?: string;
};

const PlayIcon: FunctionComponent<PropType> = ({ size = 1, color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g>
        <path
          d="M5.33398 12.6666V3.33331L12.6673 7.99998L5.33398 12.6666ZM6.66732 10.2333L10.1673 7.99998L6.66732 5.76665V10.2333Z"
          fill={color ? color : 'currentColor'}
        />
      </g>
    </svg>
  );
};

export default PlayIcon;
