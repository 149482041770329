import React, { FunctionComponent, useEffect } from 'react';
import Button from '../../../atoms/Button/Button';
import useProjectForm from '../../../../hooks/useProjectForm';
import { color } from '../../../../styles/Variables';
import PlusIcon from '../../../../Icons/Plus.icon';
import serverEvents from '../../../../services/serverEvents';
import useOverlay from '../../../../hooks/useOverlay';

type Props = {
  buttonId: string;
  clientId: number | null;
  categoryId: number | null;
  loadProjects: (categoryId: number) => void;
};

const AddNewProjectButton: FunctionComponent<Props> = ({ buttonId, clientId, categoryId, loadProjects }) => {
  const { show } = useProjectForm();
  const { overlay } = useOverlay();

  useEffect(() => {
    if (!categoryId) {
      return;
    }
    loadProjects(categoryId);
    const subscription = serverEvents.listen('/api/projects').subscribe((event) => {
      loadProjects(categoryId);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [overlay]);

  return (
    <Button
      id={buttonId}
      $text={'Add new'}
      onClick={() => {
        show(undefined, () => {}, categoryId ?? undefined, clientId ?? undefined);
      }}
      kind={'link'}
      $icon={<PlusIcon />}
      iconColor={color.primary['80']}
    />
  );
};

export default AddNewProjectButton;
