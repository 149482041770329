import React, { FunctionComponent } from 'react';
import * as Styled from './ProjectRoles.styled';
import Role from '../../../../../molecules/Projects/Role/Role';
import { ProjectRoleType } from '../../../../../../@types/Project/ProjectRoleType';
import AddRoleButton from './AddRoleButton/AddRoleButton';

type Props = {
  roles: ProjectRoleType[];
  projectId: number;
  onChange: () => void;
};

const ProjectRoles: FunctionComponent<Props> = ({ roles, projectId, onChange }) => {
  return (
    <Styled.ProjectRoles>
      <AddRoleButton projectId={projectId} onChange={onChange} />
      {roles.map((role, idx) => (
        <Role key={idx} projectId={projectId} role={role} onChange={onChange} />
      ))}
    </Styled.ProjectRoles>
  );
};

export default ProjectRoles;
