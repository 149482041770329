import React, { FunctionComponent } from 'react';
import * as Styled from './OverlayContainer.styled';
import useOverlay from '../../../hooks/useOverlay';
import CloseButton from '../../atoms/CloseButton/CloseButton';

type Props = {};

const OverlayContainer: FunctionComponent<Props> = (props) => {
  const { overlay, hideOverlay } = useOverlay();
  return (
    <>
      {overlay && (
        <Styled.OverlayContainer>
          <Styled.OverlayWrapper>
            <Styled.OverlayBody>{overlay.body}</Styled.OverlayBody>
          </Styled.OverlayWrapper>
          <Styled.CloseButtonContainer>
            <CloseButton onClick={hideOverlay} />
          </Styled.CloseButtonContainer>
        </Styled.OverlayContainer>
      )}
    </>
  );
};

export default OverlayContainer;
