import styled from 'styled-components';

export const Container = styled.div<{ $wrap?: boolean }>`
  display: flex;
  gap: 0.3rem;
  align-items: center;
  white-space: ${({ $wrap }) => ($wrap ? 'normal' : 'nowrap')};
  max-width: 65rem;

  @media (max-width: 1500px) {
    max-width: 25rem;
  }
`;
