import React, { FunctionComponent, PropsWithChildren, useCallback, useContext, useState } from 'react';
import TaskFormContext, { TaskFormContextType } from './TaskFormContext';
import { TaskType } from '../../@types/Task/TaskType';

const TaskFormProvider: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<TaskFormContextType['isOpen']>(false);
  const [defaults, setDefaults] = useState<Partial<TaskType>>({});

  const openShortForm = useCallback(() => {
    setIsOpen('short');
  }, []);

  const openLongForm = useCallback((defaults: Partial<TaskType>) => {
    if (defaults) {
      setDefaults(defaults);
    }
    setIsOpen('long');
  }, []);

  const closeForm = useCallback(() => {
    setIsOpen(false);
    setDefaults({});
  }, []);

  return <TaskFormContext.Provider value={{ defaults, isOpen, openLongForm, openShortForm, closeForm }}>{children}</TaskFormContext.Provider>;
};

export default TaskFormProvider;
