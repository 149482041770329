import React, { CSSProperties, FunctionComponent, useMemo } from 'react';
import * as Styled from './Avatar.styled';
import { createFullApiUrl } from '../../../services/api';
import Tooltip from '../../molecules/Tooltip/Tooltip';
import Text from '../Text/Text';
import { TooltipArrowPosition } from '../../molecules/Tooltip/Tooltip.styled';

type Props = {
  photoUrl?: string | null;
  name: string;
  size?: number;
  border?: boolean;
  style?: CSSProperties;
  tooltipPosition?: TooltipArrowPosition;
};

const Avatar: FunctionComponent<Props> = ({ photoUrl, name, size, border, style, tooltipPosition }) => {
  const initials = useMemo(() => {
    if (name && name.length > 0) {
      const [lastName, firstName] = name.split(' ');
      if (lastName && firstName) {
        return `${firstName[0]}${lastName[0]}`;
      } else {
        return name[0];
      }
    }
  }, [name]);

  return (
    <>
      {tooltipPosition ? (
        <Tooltip
          content={
            <Styled.TooltipWrapper>
              <Text color={'light'} size={'xs'}>
                {name}
              </Text>
            </Styled.TooltipWrapper>
          }
          trigger={
            <Styled.Avatar $border={border} $size={size} $photoUrl={photoUrl ? createFullApiUrl(photoUrl) : undefined} style={style}>
              {!photoUrl ? initials : ''}
            </Styled.Avatar>
          }
          arrowPosition={tooltipPosition}
        />
      ) : (
        <Styled.Avatar $border={border} $size={size} $photoUrl={photoUrl ? createFullApiUrl(photoUrl) : undefined} style={style}>
          {!photoUrl ? initials : ''}
        </Styled.Avatar>
      )}
    </>
  );
};

export default Avatar;
