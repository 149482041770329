import React, { FunctionComponent } from 'react';
import { HomeIcon } from '../../../../../../Icons/home.icon';
import { color } from '../../../../../../styles/Variables';
import DashboardCard from '../DashboardCard';
import Tabs, { TabObject } from '../../../../../molecules/Tabs/Tabs';
import HomeOfficesCardContentCard from '../../../../../molecules/Projects/Dashboard/DashboardCardContentCards/HomeOfficesCardContentCard';
import { DashboardDataAbsenceType } from '../../../../../../@types/Dashboard/DashboardDataAbsenceType';

type Props = {
  homeOfficeToday: DashboardDataAbsenceType[] | undefined;
  homeOfficeUpcoming: DashboardDataAbsenceType[] | undefined;
};

const HomeOffices: FunctionComponent<Props> = ({ homeOfficeToday, homeOfficeUpcoming }) => {
  const homeOfficesTabs: TabObject[] = [
    {
      tabName: 'Today',
      component: <HomeOfficesCardContentCard homeOffices={homeOfficeToday} key={1} showCounter />,
    },
    {
      tabName: 'Upcoming',
      component: <HomeOfficesCardContentCard homeOffices={homeOfficeUpcoming} key={2} showDateFilter showCounter />,
    },
  ];

  return (
    <DashboardCard title={'Home offices'} icon={<HomeIcon size={2} color={color.primary['60']} />}>
      <Tabs tabs={homeOfficesTabs} />
    </DashboardCard>
  );
};

export default HomeOffices;
