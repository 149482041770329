import styled from 'styled-components';
import { color } from '../../../styles/Variables';

export const Icon = styled.div`
  svg {
    height: 2.2rem;
    margin-left: 2rem;
    margin-right: 1rem;
    cursor: pointer;
    display: flex;

    path {
      stroke: ${color.neutral['70']};
    }

    &:hover {
      path {
        stroke: ${color.primary['60']};
      }
    }
  }
`;
