import React, { FunctionComponent } from 'react';
import { Route, Routes } from 'react-router-dom';
import IncomeFormEditView from '../../../../components/templates/Income/IncomeForm/IncomeFormEditView';
import IncomeShowView from '../../../../components/templates/Income/IncomeShow/IncomeShowView';
import { AuthContext } from '../../../../Context/auth-context/auth-context';
import { IncomeType } from '../../../../@types/Finances/Income/IncomeType';
import EntityProvider from '../../../../Context/EntityContext/EntityProvider';
import IncomeCreate from '../../../../components/templates/Income/IncomeForm/IncomeCreate';
import IncomesListScreen from './IncomesListScreen';
import { Helmet } from 'react-helmet';

type Props = {};

const IncomesScreen: FunctionComponent<Props> = (props) => {
  const { can } = React.useContext(AuthContext);

  return (
    <>
      <Helmet>
        <title>Incomes | F.CAPE</title>
      </Helmet>
      <EntityProvider<IncomeType>>
        {() => (
          <Routes>
            {can('FINANCES.INCOMES.CAN_MANAGE') && <Route path="incomes/create" element={<IncomeCreate />}></Route>}
            {can('FINANCES.INCOMES.CAN_MANAGE') && <Route path="incomes/:id/edit" element={<IncomeFormEditView />}></Route>}
            <Route path="incomes/:id/show" element={<IncomeShowView />}></Route>
            <Route path="*" element={<IncomesListScreen />}></Route>
          </Routes>
        )}
      </EntityProvider>
    </>
  );
};

export default IncomesScreen;
