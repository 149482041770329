import styled from 'styled-components';
import { color } from '../../../../styles/Variables';

export const OutsideAttendee = styled.div`
  position: absolute;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 100%;
  border-radius: 50%;
  background-color: ${color.neutral[30]};
  border: 2px solid #fff;
`;

export const ShowMoreWrapper = styled.div`
  position: absolute;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 50%;
  border: 2px solid #fff;
`;

export const TooltipContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 25rem;
  flex-wrap: wrap;
  gap: 0.4rem;
  width: max-content;
  padding: 2rem;
`;
