import React, { FunctionComponent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Item } from '../Commander';
import { Command } from 'cmdk';
import TaskStatus from '../../../molecules/Statuses/TaskStatus/TaskStatus';
import { TaskStatusType } from '../../../../@types/Task/TaskType';
import { PiCheckCircle } from 'react-icons/pi';

type Props = {
  item: Item<'taskId' | 'projectId' | 'status'>;
  onSelect: (value: string) => void;
};

const TaskCommanderItem: FunctionComponent<Props> = ({ item, onSelect }) => {
  const navigate = useNavigate();
  const handleSelect = useCallback(() => {
    navigate(`${window.location.pathname}?task=${item.metadata?.taskId}`);
    onSelect?.(item.value);
  }, []);
  return (
    <Command.Item onSelect={handleSelect} value={`${item.metadata?.taskId}-${item.value}`}>
      <PiCheckCircle />
      {item.value}
      {item.metadata?.status && <TaskStatus status={item.metadata.status as TaskStatusType} />}
      <span cmdk-raycast-meta="">{item.type}</span>
    </Command.Item>
  );
};
export default TaskCommanderItem;
