import React, { ReactElement, ReactNode } from 'react';
import Table, { TableProps } from '../Table/Table';
import PageTop from '../../molecules/Page/PageTop';
import TableActions from './TablePage/TableActions';
import { EntityType } from '../../../@types/Entity/EntityType';
import { Helmet } from 'react-helmet';

type Props<T extends EntityType> = TableProps<T> & {
  heading: string | ReactNode;
  headingRight?: ReactNode;
  onCreate?: () => void;
};

const TablePage = <T extends EntityType>({ heading, headingRight, ...props }: Props<T>): ReactElement => {
  return (
    <>
      <Helmet>{typeof heading === 'string' ? <title>{heading}</title> : null}</Helmet>
      <PageTop heading={heading} rightColumn={headingRight || <TableActions onCreate={props.onCreate} />} />
      <Table {...props} />
    </>
  );
};

export default TablePage;
