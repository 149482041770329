import styled from 'styled-components';
import { ArrowDirection } from './Arrow';

type Props = {
  $direction?: ArrowDirection;
};

const calculateRotation = (props: Props) => {
  switch (props.$direction) {
    case 'left':
      return '180deg';
    case 'up':
      return '-90deg';
    case 'down':
      return '90deg';
    default:
      return 0;
  }
};

export const ArrowContainer = styled.div<Props>`
  display: inline-block;
  & > svg {
    transition: transform 0.2s ease-in-out;
    transform: rotate(${(p) => calculateRotation(p)});
  }
`;
