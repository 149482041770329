import styled, { css } from 'styled-components';
import { color } from 'styles/Variables';

type StyledListItemProps = {
  $isSelected: boolean;
};

export const EditIconContainer = styled.div`
  display: flex;
  visibility: hidden;
  width: 3rem;
  height: 3rem;
  transition: background-color 0.15s ease-in-out;
  border-radius: 50%;
  padding: 6px;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${color.neutral[50]};
  }
`;

export const Item = styled.div<StyledListItemProps>`
  padding: 1rem 2rem;
  transition: 0.2s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: ${color.neutral[20]};

    ${EditIconContainer} {
      visibility: visible;
    }
  }

  ${(props: StyledListItemProps) =>
    props.$isSelected &&
    css`
      background-color: ${color.neutral[40]};

      &:hover {
        background-color: ${color.neutral[40]};
      }
    `}
`;

export const DetailsWrapper = styled.div`
  display: flex;
  gap: 0.8rem;
`;
