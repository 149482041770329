import React, { FunctionComponent } from 'react';
import Container from '../../../../components/atoms/Container/Container';
import { Route, Routes } from 'react-router-dom';
import CostsInboxList from '../../../../components/templates/CostsInbox/CostsInboxList/CostsInboxList';
import CostInboxShowView from '../../../../components/templates/CostsInbox/CostInboxShow/CostInboxShowView';

type Props = {};

const CostsInboxScreen: FunctionComponent<Props> = () => {
  return (
    <Container>
      <Routes>
        <Route path="" element={<CostsInboxList />}></Route>
        <Route path=":id/show" element={<CostInboxShowView />}></Route>
      </Routes>
    </Container>
  );
};

export default CostsInboxScreen;
