import React, { FunctionComponent } from 'react';
import * as Styled from './Tooltip.styled';

type Props = {
  content: string | React.ReactElement;
  trigger: string | React.ReactElement;
  arrowPosition:
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'rightTop'
    | 'rightCenter'
    | 'rightBottom'
    | 'bottomRight'
    | 'bottomCenter'
    | 'bottomLeft'
    | 'leftBottom'
    | 'leftCenter'
    | 'leftTop';
};

const Tooltip: FunctionComponent<Props> = ({ content, trigger, arrowPosition }) => {
  return (
    <Styled.Wrapper>
      {trigger}
      <Styled.TooltipContainerWrapper>
        <Styled.TooltipContainer className={'tooltip-container'} $arrowPosition={arrowPosition}>
          {content}
        </Styled.TooltipContainer>
      </Styled.TooltipContainerWrapper>
    </Styled.Wrapper>
  );
};

export default Tooltip;
