import React, { FunctionComponent } from 'react';
import { Icon } from '../../atoms/Icon/Icon';
import { GridItem } from '../../atoms/Grid/Grid';
import { color } from '../../../styles/Variables';
import HeartIcon from '../../../Icons/Heart.icon';

type Props = {
  isFavorite: boolean;
  onClick: () => void;
  iconColor?: string;
};

const HeartButton: FunctionComponent<Props> = ({ isFavorite, onClick, iconColor }) => {
  return (
    <GridItem style={{ alignItems: 'center', display: 'flex' }}>
      <Icon
        color={isFavorite ? color.semantic.error['80'] : (iconColor ?? color.neutral['100'])}
        onClick={() => {
          onClick();
        }}
        style={{ cursor: 'pointer' }}
        size={1.3}
      >
        <HeartIcon fill={isFavorite} />
      </Icon>
    </GridItem>
  );
};

export default HeartButton;
