import React, { FunctionComponent } from 'react';
import { ProjectRoleType } from '../../../../@types/Project/ProjectRoleType';
import Text from '../../../atoms/Text/Text';
import Person from '../../Person/Person';
import * as Styled from './Role.styled';
import RoleDropdownMenu from '../../../organisms/Projects/ProjectDetails/Overview/ProjectRoles/AddRoleButton/RoleDropdownMenu/RoleDropdownMenu';

type Props = {
  role: ProjectRoleType;
  projectId: number;
  onChange: () => void;
};

const Role: FunctionComponent<Props> = ({ role, projectId, onChange }) => {
  return (
    <Styled.RoleWrapper>
      <Person person={role.employee} size={3.6} hideName={true} />
      <Styled.Wrapper>
        <Text bold={true}>{role.employee.name}</Text>
        {role.name ? (
          <Text size={'s'} color={'grey'}>
            {role.name}
          </Text>
        ) : (
          <Text size={'s'} color={'grey'} style={{ fontStyle: 'italic' }}>
            No role
          </Text>
        )}
      </Styled.Wrapper>
      <RoleDropdownMenu role={role} projectId={projectId} onChange={onChange} />
    </Styled.RoleWrapper>
  );
};

export default Role;
