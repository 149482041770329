import React, { FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react';
import useEntity from '../../../../hooks/useEntity';
import Form from '../../../molecules/Form/Form';
import { UseFormReturn } from 'react-hook-form';
import { EntityType } from '../../../../@types/Entity/EntityType';
import Button from '../../../atoms/Button/Button';
import useModal from '../../../../hooks/useModal';
import { Loader } from '../../../../legacy/CapeMorris/components';
import { ModalFooter } from '../../../molecules/Modal/Modal';
import SnackContainer from '../../../../legacy/CapeMorris/components/snack-bar/use-snack-container';

type Props = {
  basePath: string;
  children: ((methods: UseFormReturn<any>, isEdit: boolean, entity?: EntityType | null) => ReactNode) | ReactNode;
  editTitle?: string;
  onEntityLoad?: (entity: any) => void;
  defaults?: any;
  buttonText?: string;
  onSubmit?: () => void;
  onChange?: (entity: any) => void;
  errorMessage?: string;
};

const ModalForm: FunctionComponent<Props> = ({ basePath, children, defaults, buttonText, onSubmit, onChange, ...props }) => {
  const { hideModal } = useModal();
  const { loading, entity, create, save } = useEntity(basePath);
  const [working, setWorking] = useState(false);
  const { showSnack } = SnackContainer.useContainer();

  const handleSubmit =
    onSubmit ??
    useCallback(
      (data: any) => {
        setWorking(true);
        if (!entity) {
          create(data)
            .then((response) => {
              if (onChange) onChange(response);
              setWorking(false);
              hideModal();
            })
            .catch((error) => {
              setWorking(false);
              showSnack({
                type: 'error',
                title: 'Error',
                message: props.errorMessage ?? error,
              });
            });
        } else {
          save(data)
            .then((response) => {
              if (onChange) onChange(response);
              setWorking(false);
              hideModal();
            })
            .catch((error) => {
              setWorking(false);
              showSnack({
                type: 'error',
                title: 'Error',
                message: props.errorMessage ?? error,
              });
            });
        }
      },
      [entity, create, save],
    );

  useEffect(() => {
    if (entity && props.onEntityLoad) {
      props.onEntityLoad(entity);
    }
  }, [entity, props.onEntityLoad]);

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <Form defaultValues={entity ?? defaults} onSubmit={handleSubmit}>
          {(m) => (
            <>
              {typeof children === 'function' ? children(m, !!entity, entity) : children}
              <ModalFooter>
                <Button $text={buttonText ? buttonText : entity ? 'Save' : 'Create item'} type={'submit'} waiting={working} />
              </ModalFooter>
            </>
          )}
        </Form>
      )}
    </>
  );
};

export default ModalForm;
