import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { NavigationNodeType } from '../../../@types/NavigationTree/NavigationTreeType';
import Arrow from '../../atoms/Arrow/Arrow';
import { ArrowReplacement, ArrowWrapper, NavigationNodeWrapper, TreeLink, TreeLinkWrapper, Wrapper } from './NavigationNode.styled';
import { useLocation } from 'react-router-dom';
import { ProcessIcon } from '../../../Icons/Process.icon';

type Props = NavigationNodeType & {
  // only slugs from current Node and descendants
  currentSlugs: string[];
  prefix: string;
  isMainCategory?: boolean;
  isLoading?: boolean;
  isProcess?: boolean;
};

const NavigationNode: FunctionComponent<Props> = ({ name, slug, currentSlugs, children, prefix, isMainCategory, isLoading, isProcess }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const { search } = useLocation();

  const shiftedSlugs = useMemo(() => {
    const result = [...currentSlugs];
    result.shift();
    return result;
  }, [currentSlugs]);

  const link = useMemo(() => {
    return `${prefix}/${slug}`;
  }, [slug, prefix]);

  useEffect(() => {
    setExpanded(currentSlugs.length > 0 && slug === currentSlugs[0]);
  }, [currentSlugs, slug]);

  const toggleExpand = () => {
    setExpanded((prevState) => !prevState);
  };

  const isActive = useMemo(() => {
    return [...currentSlugs].pop() === slug && expanded;
  }, [slug, currentSlugs, expanded]);

  return (
    <Wrapper>
      <TreeLinkWrapper>
        <ArrowWrapper onClick={toggleExpand}>
          {children && children.length > 0 ? <Arrow direction={expanded ? 'down' : 'right'} /> : isProcess ? <ProcessIcon /> : <ArrowReplacement />}
        </ArrowWrapper>
        <TreeLink
          to={{ pathname: link, search: search }}
          $haschildren={!!(children && isMainCategory) || !!(children && children.length > 0) ? 'true' : ''}
          $ismaincategory={isMainCategory ? isMainCategory.toString() : ''}
          $isclicked={isActive ? 'true' : ''}
          onClick={toggleExpand}
        >
          {name}
        </TreeLink>{' '}
      </TreeLinkWrapper>
      {children &&
        children.map((child) => (
          <NavigationNodeWrapper key={child.slug} $isExpanded={expanded}>
            <NavigationNode {...child} currentSlugs={shiftedSlugs} prefix={link} />
          </NavigationNodeWrapper>
        ))}
    </Wrapper>
  );
};

export default NavigationNode;
