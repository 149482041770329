import React, { FunctionComponent } from 'react';

type Props = {
  size?: number;
};

const TrashIcon: FunctionComponent<Props> = ({ size = 1 }) => {
  return (
    <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M7.3077 20.5C6.80898 20.5 6.38302 20.3234 6.02982 19.9701C5.67661 19.6169 5.5 19.191 5.5 18.6923V5.99999H4.5V4.50002H8.99997V3.61542H15V4.50002H19.5V5.99999H18.5V18.6923C18.5 19.1974 18.325 19.625 17.975 19.975C17.625 20.325 17.1974 20.5 16.6922 20.5H7.3077ZM17 5.99999H6.99997V18.6923C6.99997 18.782 7.02883 18.8557 7.08652 18.9134C7.14422 18.9711 7.21795 19 7.3077 19H16.6922C16.7692 19 16.8397 18.9679 16.9038 18.9038C16.9679 18.8397 17 18.7692 17 18.6923V5.99999ZM9.40385 17H10.9038V7.99999H9.40385V17ZM13.0961 17H14.5961V7.99999H13.0961V17Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default TrashIcon;
