import React, { ReactElement } from 'react';
import * as Styled from './TableBody.styled';
import { Loader } from '../../../../legacy/CapeMorris/components';
import { TableProperties } from '../../../../@types/Table/TableProperty';
import { EntityType } from '../../../../@types/Entity/EntityType';
import TableItem from '../TableItem/TableItem';
import ExtendedRowComponent from '../../../atoms/Table/ExtendedRowComponent';
import { TableRowActionsType } from '../../../../@types/Table/TableRowActionType';
import EmptyBody from './EmptyBody';

type Props<T extends EntityType> = {
  isLoading: boolean;
  items: T[] | undefined;
  properties: TableProperties<any>;
  onItemClick?: (item: T) => void;
  onSelect?: (item: T) => void;
  onDeselect?: (item: T) => void;
  selectedItems: T[];
  selectable?: boolean;
  extendedRow?: ExtendedRowComponent<T>;
  rowActions?: TableRowActionsType<T>;
  itemLink?: (item: T) => string;
};

const TableBody = <T extends EntityType>({
  isLoading,
  items,
  properties,
  onItemClick,
  onSelect,
  onDeselect,
  selectedItems,
  selectable,
  ...props
}: Props<T>): ReactElement => {
  return (
    <Styled.Body>
      {isLoading && (
        <tr>
          <td>
            <Styled.LoaderContainer>
              <Loader />
            </Styled.LoaderContainer>
          </td>
        </tr>
      )}
      {items?.length ? (
        items.map((item) => (
          <TableItem
            item={item}
            properties={properties}
            key={item['@id']}
            onItemClick={onItemClick}
            onSelect={onSelect}
            onDeselect={onDeselect}
            isSelected={selectedItems.filter((i) => i['@id'] === item['@id']).length > 0}
            selectable={selectable}
            extendedRow={props.extendedRow}
            rowActions={props.rowActions}
            itemLink={props.itemLink}
          />
        ))
      ) : (
        <EmptyBody isButton={false} buttonText={'Add'} buttonAction={() => {}} />
      )}
    </Styled.Body>
  );
};

export default TableBody;
