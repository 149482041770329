import React, { FunctionComponent, useMemo } from 'react';
import { Card } from '../../../../atoms/Card/Card';
import { CategoryType } from '../../../../../@types/Category/CategoryType';
import Heading from '../../../../atoms/Heading';
import { NavigationNodeType } from '../../../../../@types/NavigationTree/NavigationTreeType';
import { Link, useLocation } from 'react-router-dom';
import { color } from '../../../../../styles/Variables';
import Text from '../../../../atoms/Text';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
type Props = {
  category?: CategoryType;
  node?: NavigationNodeType;
};

const CategoryListItem: FunctionComponent<Props> = ({ category, node }) => {
  let location = useLocation();
  const name = useMemo(() => (category ? category.name : node?.name), [category, node]);
  const count = useMemo(() => (category ? category.articles.length : node?.children?.length), [category, node]);
  return (
    <Link
      to={`${location.pathname.endsWith('/') ? location.pathname.substring(0, location.pathname.length - 1) : location.pathname}/${
        category ? category.slug : node ? node.slug : ''
      }`}
    >
      <Card style={{ marginBottom: '2.4rem' }}>
        <div style={{ marginBottom: '1.6rem' }}>
          <Heading level={3} size={'s'} color={color.primary[60]}>
            <strong>{name}</strong>
          </Heading>
        </div>
        <Grid spacing={0.35} style={{ marginBottom: '1.6rem' }}>
          <GridItem>
            <Text color={'primary'}>
              {count} {count === 1 ? 'element' : 'elements'}
            </Text>
          </GridItem>
          <GridItem>
            <Text>in this category</Text>
          </GridItem>
        </Grid>
      </Card>
    </Link>
  );
};

export default CategoryListItem;
