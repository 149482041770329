import styled from 'styled-components';

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.6rem;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
`;

export const TopWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: start;
  justify-content: space-between;

  &:hover {
    ${ActionsWrapper} {
      opacity: 1;
    }
  }
`;
