import styled from 'styled-components';
import { color } from '../../../../../styles/Variables';

export const WikiSearchContainer = styled.div`
  margin-bottom: 3.2rem;
  margin-right: 3rem;
  margin-top: 1.5rem;
  width: 65%;

  .tag-option {
    display: inline-block;
    width: max-content;
    padding: 0.6rem 0;
    background-color: ${color.light} !important;
    margin-right: 0;
  }

  .tag {
    margin-left: 1rem;
  }
`;
