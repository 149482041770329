import React, { FunctionComponent } from 'react';

type Props = {
  size?: number;
  color?: string;
};

const PersonIcon: FunctionComponent<Props> = ({ size = 1, color }) => {
  return (
    <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className={'person-icon'}>
      <path
        d="M6.99998 6.74343C6.19791 6.74343 5.51128 6.45784 4.9401 5.88668C4.36892 5.3155 4.08333 4.62887 4.08333 3.8268C4.08333 3.02472 4.36892 2.33809 4.9401 1.76693C5.51128 1.19575 6.19791 0.910156 6.99998 0.910156C7.80205 0.910156 8.48867 1.19575 9.05985 1.76693C9.63103 2.33809 9.91663 3.02472 9.91663 3.8268C9.91663 4.62887 9.63103 5.3155 9.05985 5.88668C8.48867 6.45784 7.80205 6.74343 6.99998 6.74343ZM0.75 13.0896V11.237C0.75 10.8289 0.860847 10.451 1.08254 10.1032C1.30422 9.75549 1.60042 9.48813 1.97115 9.30116C2.79487 8.89731 3.6258 8.59443 4.46394 8.39251C5.30208 8.19059 6.14742 8.08964 6.99998 8.08964C7.85253 8.08964 8.69788 8.19059 9.53602 8.39251C10.3742 8.59443 11.2051 8.89731 12.0288 9.30116C12.3995 9.48813 12.6957 9.75549 12.9174 10.1032C13.1391 10.451 13.25 10.8289 13.25 11.237V13.0896H0.75ZM1.99998 11.8396H12V11.237C12 11.0682 11.9511 10.912 11.8533 10.7683C11.7556 10.6246 11.6228 10.5073 11.4551 10.4165C10.7372 10.0629 10.0051 9.79499 9.25889 9.61282C8.5127 9.43067 7.75973 9.33959 6.99998 9.33959C6.24023 9.33959 5.48726 9.43067 4.74106 9.61282C3.99487 9.79499 3.2628 10.0629 2.54485 10.4165C2.37712 10.5073 2.24437 10.6246 2.14662 10.7683C2.04886 10.912 1.99998 11.0682 1.99998 11.237V11.8396ZM6.99998 5.49347C7.45831 5.49347 7.85067 5.33028 8.17706 5.00389C8.50345 4.6775 8.66665 4.28514 8.66665 3.8268C8.66665 3.36847 8.50345 2.97611 8.17706 2.64972C7.85067 2.32333 7.45831 2.16014 6.99998 2.16014C6.54165 2.16014 6.14928 2.32333 5.8229 2.64972C5.49651 2.97611 5.33331 3.36847 5.33331 3.8268C5.33331 4.28514 5.49651 4.6775 5.8229 5.00389C6.14928 5.33028 6.54165 5.49347 6.99998 5.49347Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  );
};

export default PersonIcon;
