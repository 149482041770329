import React from 'react';
import OverlayType from '../../@types/Overlay/OverlayType';

export type OverlayContextType = {
  overlay: undefined | OverlayType;
  showOverlay: (overlay: OverlayType) => void;
  hideOverlay: () => void;
};

export const OverlayContext = React.createContext<OverlayContextType>({
  overlay: undefined,
  showOverlay: () => {},
  hideOverlay: () => {},
});
