import React, { FunctionComponent, useEffect } from 'react';
import Button from '../../../atoms/Button/Button';
import useOverlay from '../../../../hooks/useOverlay';
import PartnerForm from '../../../organisms/Projects/Forms/PartnerForm/PartnerForm';
import PlusIcon from '../../../../Icons/Plus.icon';
import { color } from '../../../../styles/Variables';
import serverEvents from '../../../../services/serverEvents';

type Props = {
  buttonId: string;
  loadPartners: () => void;
};

const AddNewPartnerButton: FunctionComponent<Props> = ({ buttonId, loadPartners }) => {
  const { showOverlay, overlay } = useOverlay();

  useEffect(() => {
    loadPartners();
    const subscription = serverEvents.listen('/api/clients').subscribe((event) => {
      loadPartners();
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [overlay]);

  return (
    <Button
      id={buttonId}
      $text={'Add new'}
      onClick={() => {
        showOverlay({ body: <PartnerForm /> });
      }}
      kind={'link'}
      $icon={<PlusIcon />}
      iconColor={color.primary['80']}
    />
  );
};

export default AddNewPartnerButton;
