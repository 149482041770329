import React, { FunctionComponent, useCallback } from 'react';
import * as Styled from './ProjectHeader.styled';
import { ProjectType } from '../../../@types/Project/Project';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { Grid } from '../../atoms/Grid/Grid';
import Text from 'components/atoms/Text';
import Popover from '../../atoms/Popover/Popover';
import useProjectForm from '../../../hooks/useProjectForm';
import ProjectHeaderDropdownMenu from './components/ProjectHeaderDropdownMenu/ProjectHeaderDropdownMenu';
import DatabaseIcon from '../../../Icons/Database.icon';
import Person from '../Person/Person';
import { Icon } from '../../atoms/Icon/Icon';
import { color } from '../../../styles/Variables';
import { useEntityContext } from '../../../Context/EntityContext/EntityContext';
import ProjectStatus from '../ProjectStatus/ProjectStatus';
import { NonBillableDetailDict } from '../../../@dicts/Project/NonBillableDetailDict';
import BetterChip from '../../atoms/Chips/BetterChip';

type PropType = {};

const ProjectHeader: FunctionComponent<PropType> = () => {
  const { entity: project, replace } = useEntityContext<ProjectType>();
  const { show } = useProjectForm();
  const handleEdit = useCallback(() => {
    show(project, replace);
  }, [show, project, replace]);

  return (
    <Styled.Container>
      <Grid $justifyContent={'space-between'} $align={'center'}>
        <Styled.HeaderWrapper>
          <Styled.Title>
            <Styled.Id>
              <Popover popover={<Text size={'s'}>Copied!</Text>} duration={2000}>
                <span
                  onClick={(e) => {
                    useCopyToClipboard(e);
                  }}
                >
                  #{project.id}
                </span>
              </Popover>
            </Styled.Id>
            {project.name}
          </Styled.Title>
          <Styled.HeaderActions>
            <ProjectHeaderDropdownMenu status={project.status} archived={project.archived} id={project.id} onEdit={handleEdit} />
            <ProjectStatus status={project.status} archived={project.archived} secret={project.secret} />
            {!project.billable && (
              <>
                <BetterChip>Not Billable</BetterChip>
                {NonBillableDetailDict.find((v) => v.value === project.nonBillableDetail)?.label && (
                  <BetterChip>{NonBillableDetailDict.find((v) => v.value === project.nonBillableDetail)?.label}</BetterChip>
                )}
              </>
            )}
          </Styled.HeaderActions>
        </Styled.HeaderWrapper>
        <Styled.Info>
          <Styled.Details>
            <Icon color={color.primary['80']}>
              <DatabaseIcon />
            </Icon>
            <Text>{project.client.name}</Text>
            <Text color={'primary'}>/</Text>
            <Text>{project.category.name}</Text>
            <Text color={'primary'}>/</Text>
            <Person person={project.leader} />
          </Styled.Details>
        </Styled.Info>
      </Grid>
    </Styled.Container>
  );
};

export default ProjectHeader;
