import React from 'react';
import { TableFilterInterface } from '../TableFilterInterface';
import { FormHtmlInput, FormInputContainer, FormLabel } from '../../../atoms/Form/InputParts/InputParts';
import { Controller, useFormContext } from 'react-hook-form';

const MoneyFilter: TableFilterInterface = ({ name, filter, onChange: doOnChange, defaultValue }) => {
  const { control } = useFormContext();
  return (
    <FormInputContainer>
      <FormLabel>{filter.label ? filter.label : name}</FormLabel>
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        render={({ field: { value, onChange } }) => (
          <FormHtmlInput
            type={'number'}
            id={name}
            step="any"
            onChange={(event) => {
              let inputValue = event.target.value;
              inputValue = inputValue.replace(',', '.');
              onChange(inputValue);
              doOnChange(inputValue);

              const valueInDollars = parseFloat(inputValue);

              if (!isNaN(valueInDollars)) {
                const valueInCents = Math.round(valueInDollars * 100);
                onChange(valueInCents);
                doOnChange(valueInCents);
              }
            }}
            placeholder={filter.placeholder ?? filter.label ?? name}
            defaultValue={defaultValue}
            value={value !== undefined && value !== null && value !== '' ? value / 100 : ''}
          />
        )}
      />
    </FormInputContainer>
  );
};
export default MoneyFilter;
