import React, { FunctionComponent } from 'react';

type PropType = {
  size?: number;
};

const AttachmentIcon: FunctionComponent<PropType> = ({ size = 1 }) => {
  return (
    <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.21527 9.53551C1.67316 8.99339 1.4021 8.34521 1.4021 7.59097C1.4021 6.83672 1.67316 6.18854 2.21527 5.64642L5.92758 1.93411C6.31649 1.5452 6.7879 1.35075 7.3418 1.35075C7.8957 1.35075 8.3671 1.5452 8.75601 1.93411C9.14492 2.32302 9.33937 2.79442 9.33937 3.34832C9.33937 3.90222 9.14492 4.37363 8.75601 4.76254L5.39725 8.1213C5.14977 8.36878 4.85514 8.49253 4.51337 8.49253C4.1716 8.49253 3.87697 8.36878 3.62949 8.1213C3.382 7.87381 3.25826 7.57918 3.25826 7.23741C3.25826 6.89564 3.382 6.60102 3.62949 6.35353L6.98824 2.99477L7.51857 3.5251L4.15982 6.88386C4.05964 6.98403 4.00956 7.10188 4.00956 7.23741C4.00956 7.37294 4.05964 7.49079 4.15982 7.59097C4.25999 7.69114 4.37784 7.74123 4.51337 7.74123C4.6489 7.74123 4.76675 7.69114 4.86692 7.59097L8.22568 4.23221C8.47317 3.98472 8.59691 3.69009 8.59691 3.34832C8.59691 3.00656 8.47317 2.71193 8.22568 2.46444C7.97819 2.21695 7.68357 2.09321 7.3418 2.09321C7.00003 2.09321 6.7054 2.21695 6.45791 2.46444L2.7456 6.17675C2.35669 6.56566 2.16224 7.03707 2.16224 7.59097C2.16224 8.14487 2.35669 8.61627 2.7456 9.00518C3.13451 9.39409 3.60592 9.58854 4.15982 9.58854C4.71372 9.58854 5.18512 9.39409 5.57403 9.00518L9.28634 5.29287L9.81667 5.8232L6.10436 9.53551C5.56225 10.0776 4.91406 10.3487 4.15982 10.3487C3.40557 10.3487 2.75739 10.0776 2.21527 9.53551Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default AttachmentIcon;
