import React, { FunctionComponent } from 'react';
import { Route, Routes } from 'react-router-dom';
import InvoicesTable from './submodules/invoices/screens/InvoicesTable';
import CostsTable from './submodules/invoices/screens/CostsTable';

const ExchangeModule: FunctionComponent = () => {
  return (
    <Routes>
      <Route path={'invoices'} element={<InvoicesTable />} />
      <Route path={'costs'} element={<CostsTable />} />
    </Routes>
  );
};

export default ExchangeModule;
