import { EntityType } from '../@types/Entity/EntityType';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { io } from 'socket.io-client';

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL ?? '';

interface MessageEvent {
  data: any;
}

export class ServerEvents {
  private _socket: any;
  private _state: 'closed' | 'initializing' | 'ready' | 'errored' = 'closed';
  private subject: Subject<any> = new Subject();

  init(): void {
    if (this._state !== 'closed') {
      return;
    }
    this._socket = io(SOCKET_URL, {
      reconnectionDelayMax: 10000,
      rejectUnauthorized: false,
    });
    this._socket.on('connect', () => {
      console.log('Connected to socket.io server');
      this._state = 'ready';
    });
    this._socket.on('update', (data: any) => {
      this.onMessage({ data } as MessageEvent);
    });
  }

  public get state(): 'closed' | 'initializing' | 'ready' | 'errored' {
    return this._state;
  }

  public listen<T extends EntityType = EntityType>(context?: string): Observable<T> {
    return this.subject.asObservable().pipe(filter((entity: T) => !context || entity['@id'].startsWith(context)));
  }

  private onMessage(message: MessageEvent): void {
    this.subject.next(message.data);
  }
}

const serverEvents = new ServerEvents();

export default serverEvents;
