import React, { FunctionComponent, useContext } from 'react';
import SelectInput from '../../../../molecules/Form/SelectInput/SelectInput';
import useUser from '../../../../../hooks/useUser';
import useApi from '../../../../../hooks/useApi';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import useModal from '../../../../../hooks/useModal';
import { AuthContext } from '../../../../../Context/auth-context/auth-context';
import { SwitchInput } from '../../../../molecules/Form/SwitchInput/SwitchInput';
import ModalForm from '../../../Form/ModalForm/ModalForm';

type Props = {
  onSubmit: () => void;
};

const DashboardConfigurationModal: FunctionComponent<Props> = ({ onSubmit }) => {
  const { hideModal } = useModal();
  const user = useUser();
  const { loadUser } = useContext(AuthContext);
  const { put } = useApi();

  const deadlineSelectOptions = [
    { value: '3', label: '+3 days' },
    { value: '7', label: '+7 days' },
    { value: '14', label: '+14 days' },
    { value: '21', label: '+21 days' },
  ];

  return (
    <>
      <ModalForm
        basePath={`/api/dashboard-configuration/${user.employeeId}`}
        buttonText={'Save configuration'}
        onChange={(entity) => {
          hideModal();
          loadUser();
          onSubmit();
        }}
      >
        <Grid spacing={1.5}>
          <GridItem $desktop={12}>
            <SelectInput name={'dashboardConfiguration.deadline'} options={deadlineSelectOptions} label={'Deadline'} required />
          </GridItem>
          <GridItem $desktop={'auto'}>
            <SwitchInput name={'dashboardConfiguration.showWithoutDeadline'} label={'Show tasks without deadline'} />
          </GridItem>
          <GridItem $desktop={'auto'}>
            <SwitchInput name={'dashboardConfiguration.showMeetings'} label={'Show meetings in "Upcoming" section'} />
          </GridItem>
        </Grid>
      </ModalForm>
    </>
  );
};

export default DashboardConfigurationModal;
