import { ModalType } from '../../@types/Modal/ModalType';
import React from 'react';

export type ModalContextType = {
  modal: undefined | ModalType;
  showModal: (modal: ModalType) => void;
  hideModal: () => void;
};

export const ModalContext = React.createContext<ModalContextType>({
  modal: undefined,
  showModal: () => {},
  hideModal: () => {},
});
