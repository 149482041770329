import React, { FunctionComponent } from 'react';
import { TextInput } from '../../../../molecules/Form/TextInput/TextInput';
import DatePicker from '../../../../molecules/Form/DatePicker/DatePicker';
import { GridItem } from '../../../../atoms/Grid/Grid';
import Select from '../../../../molecules/Form/SelectInput/SelectInput';
import StaticSelectInput from '../../../../molecules/Form/SelectInput/StaticSelectInput';

const PersonBasicDataForm: FunctionComponent = () => {
  return (
    <>
      <GridItem $desktop={6} />
      <GridItem $desktop={6}>
        <TextInput name={'firstName'} label={'First name'} required placeholder={'Jan'} />
      </GridItem>
      <GridItem $desktop={6}>
        <TextInput name={'lastName'} label={'Last name'} required placeholder={'Kowalski'} />
      </GridItem>
      <GridItem $desktop={6}>
        <DatePicker name={'birthDate'} label={'Date of birth'} birthday />
      </GridItem>
      <GridItem $desktop={6}>
        <TextInput name={'pesel'} label={'PESEL'} required placeholder={'89010116811'} />
      </GridItem>
      <GridItem $desktop={12}>
        <TextInput name={'taxOffice'} label={'Tax office'} required placeholder={'Urząd Skarbowy Waraszawa'} />
      </GridItem>
      <GridItem $desktop={6}>
        <StaticSelectInput
          name={'documentType'}
          label="Document type"
          options={[
            { value: 'id', label: 'ID' },
            { value: 'passport', label: 'Passport' },
          ]}
        />
      </GridItem>
      <GridItem $desktop={6}>
        <TextInput name={'documentNumber'} label={'Number'} required placeholder={'HAS 000000'} />
      </GridItem>
      <GridItem $desktop={6}>
        <DatePicker name={'documentValidTo'} label="Valid until" />
      </GridItem>
      <GridItem $desktop={6}>
        <Select name={'client'} label="Partner" baseUrl={'/api/clients/'} />
      </GridItem>
    </>
  );
};

export default PersonBasicDataForm;
