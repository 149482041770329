import React from 'react';
import { SplashWrapper } from './Splash.styled';
import { Logo } from '../../components/atoms/Logo/Logo';

export const Splash: React.FC = () => {
  return (
    <SplashWrapper>
      <Logo width={400} height={400} />
    </SplashWrapper>
  );
};
