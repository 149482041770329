import React, { FunctionComponent, useState, Fragment, useMemo } from 'react';
import * as Styled from './HistoryEntry.styled';
import Avatar from '../Avatar/Avatar';
import Button from '../Button/Button';
import { HistoryEntryType } from '../../../@types/History/HistoryEntry';
import Text from '../Text/Text';
import { parseDate } from '../../../helpers/date';
import RichText from '../RichText/RichText';
import BetterChip, { ChipColor } from '../Chips/BetterChip';
import translateDictionary from '../../../helpers/translateDictionary';
import { financesHistoryDict } from '../../../@dicts/FinancesHistoryDict';

type Props = {
  entry: HistoryEntryType;
};

const HistoryEntry: FunctionComponent<Props> = ({ entry }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const author = entry.author ?? entry.externalUser;

  function translate(text: string) {
    return translateDictionary(financesHistoryDict, text);
  }

  function formatValue(value: any, key: string) {
    // check if value is a date string
    const isDate = typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/);

    if (key.endsWith('.value')) {
      return (+value / 100).toFixed(2).replace('.', ',');
    }

    if (isDate) {
      const date = new Date(value);
      return parseDate(date, true);
    }
    return value;
  }

  const arrayIncludesString = (array: string[], string: string) => array.some((item) => string.includes(item));

  const chipSentiment: ChipColor | undefined = useMemo(() => {
    const successActions = ['created', 'updated', 'approved', 'accepted', 'completed', 'signed'];
    const errorActions = ['rejected', 'deleted', 'declined', 'cancelled', 'archived'];
    const neutralActions = ['draft'];
    const informationActions = ['changes'];
    const warningActions = ['partially', 'pending'];
    if (!entry.readableStatus) return 'neutral';
    if (arrayIncludesString(successActions, entry.readableStatus.toLowerCase())) return 'success';
    if (arrayIncludesString(errorActions, entry.readableStatus.toLowerCase())) return 'error';
    if (arrayIncludesString(neutralActions, entry.readableStatus.toLowerCase())) return 'neutral';
    if (arrayIncludesString(informationActions, entry.readableStatus.toLowerCase())) return 'primary';
    if (arrayIncludesString(warningActions, entry.readableStatus.toLowerCase())) return 'warning';
  }, [entry]);
  return (
    <Styled.HistoryEntryStyled>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Styled.InlineWrapper>
          <div style={{ display: 'grid', gridTemplateColumns: '3rem auto auto', alignItems: 'start', gap: '0.6rem', marginBottom: '.5rem' }}>
            <Avatar name={author.name} size={3} />
            <Styled.EntryTitle>
              <RichText content={'<strong>' + author.name + '</strong> ' + entry.readableAction} />
            </Styled.EntryTitle>
          </div>
          {entry.readableStatus && <BetterChip color={chipSentiment}>{entry.readableStatus}</BetterChip>}
        </Styled.InlineWrapper>{' '}
      </div>

      {entry.changeSet && (
        <Styled.DetailsWrapper>
          {Object.entries(entry.changeSet as Object).map(([key, value], index) => (
            <Fragment key={index}>
              <Text size={'xs'} color={'primary'} bold>
                {translate(key)}
              </Text>
              {expanded && (
                <Text size={'s'}>
                  {formatValue(value.before, key)} → {formatValue(value.after, key)}
                </Text>
              )}
            </Fragment>
          ))}
        </Styled.DetailsWrapper>
      )}

      <Styled.InlineWrapper>
        <Text size={'s'} color={'grey'} style={{ marginTop: '1.4rem' }}>
          {parseDate(entry.createdAt, true)}
        </Text>
        {entry.changeSet && !Array.isArray(entry.changeSet) && (
          <Button
            $text={expanded ? 'Hide details' : 'See details'}
            onClick={() => {
              setExpanded(!expanded);
            }}
            kind={'link'}
          />
        )}
      </Styled.InlineWrapper>
    </Styled.HistoryEntryStyled>
  );
};

export default HistoryEntry;
