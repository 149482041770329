import styled from 'styled-components';
import { color } from 'styles/Variables';

export const Container = styled.div`
  padding-top: 0.6rem;
  height: 100%;
`;

export const Editor = styled.div`
  border-radius: 0.5rem;
  border: solid 1px ${color.neutral[50]} !important;
  ul,
  ol {
    margin-left: 2rem;
  }
  p {
    padding-bottom: 0.5rem;
  }
`;
