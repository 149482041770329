import { TableProperties } from '../../../../@types/Table/TableProperty';
import IdTemplate from '../../../molecules/CellTemplates/common/IdTemplate/IdTemplate';
import StringTemplate from '../../../molecules/CellTemplates/common/StringTemplate/StringTemplate';
import DateTemplate from '../../../molecules/CellTemplates/common/DateTemplate/DateTemplate';
import { ContractType } from '../../../../@types/Finances/Contracts/ContractType';
import { ContractTypeDict } from '../../../../@dicts/Contract/ContractTypeDict';
import FinanceItemInfoTemplate from '../../../molecules/CellTemplates/Finances/FinanceItemInfoTemplate/FinanceItemInfoTemplate';
import ContractorValueTaxTemplate from '../../../molecules/CellTemplates/Finances/ContractorValueTaxTemplate/ContractorValueTaxTemplate';
import ContractItemDetailsTemplate from '../../../molecules/CellTemplates/Finances/ContractItemDetailsTemplate';

export const contractsListProperties: TableProperties<ContractType> = [
  {
    name: 'id',
    visibleName: 'ID',
    template: IdTemplate,
    sortable: true,
  },
  {
    name: 'contractor',
    visibleName: 'Vendor',
    template: ContractorValueTaxTemplate,
    sortable: true,
    templateOptions: {
      textProperties: {
        maxWidth: '20rem',
      },
    },
  },
  {
    name: 'date',
    visibleName: 'Contract date',
    template: DateTemplate,
    sortable: true,
    templateOptions: {
      textProperties: {
        size: 'm',
        bold: true,
      },
    },
  },
  {
    name: 'title',
    visibleName: 'Title',
    template: FinanceItemInfoTemplate,
    sortable: true,
    templateOptions: {
      chips: [
        {
          display: () => 'Confidential',
          condition: (item: ContractType) => item.confidential,
        },
      ],
      textProperties: {
        size: 'm',
        bold: true,
      },
    },
  },
  {
    name: 'type',
    visibleName: 'Type',
    template: StringTemplate,
    templateOptions: {
      dictionary: ContractTypeDict,
      textProperties: {
        size: 'm',
        bold: true,
      },
    },
    sortable: true,
  },
  {
    name: 'binderReference',
    visibleName: 'Binder reference',
    template: StringTemplate,
    sortable: true,
    templateOptions: {
      textProperties: {
        size: 'm',
        bold: true,
      },
    },
  },
  {
    name: 'applies',
    visibleName: 'Details',
    template: ContractItemDetailsTemplate,
    templateOptions: {
      textProperties: {
        size: 'm',
        bold: true,
      },
    },
  },
];
