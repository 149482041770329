import React from 'react';
import { ProjectType } from '../../../../../@types/Project/Project';
import { CellTemplate } from '../../CellTemplate';
import Text from '../../../../atoms/Text';
import { TextSizeType } from '../../../../../@types/Text/TextSizeType';
import { ColorType } from '../../../../../@types/Color/ColorType';
import { parseDate } from '../../../../../helpers/date';

const ProjectNPSTemplate: CellTemplate<
  ProjectType,
  { textProperties?: { shorten?: number; bold?: boolean; size?: TextSizeType; color?: ColorType } },
  ProjectType
> = ({ item, options }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.3rem' }}>
      {item.nps ? (
        <Text color={options?.textProperties?.color ?? 'darkGrey'} size={options?.textProperties?.size ?? 'l'} bold={options?.textProperties?.bold ?? false}>
          {item.nps}
        </Text>
      ) : (
        <Text color={'lightGrey'} size={'s'} bold>
          N/A
        </Text>
      )}
      {item.npsRatedAt ? (
        <Text color={'grey'} size={'xs'} bold={options?.textProperties?.bold ?? false}>
          {parseDate(item.npsRatedAt)}
        </Text>
      ) : (
        <Text color={'lightGrey'} size={'s'} bold>
          N/A
        </Text>
      )}
    </div>
  );
};

export default ProjectNPSTemplate;
