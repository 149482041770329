import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { DashboardDataAbsenceType } from '../../../@types/Dashboard/DashboardDataAbsenceType';
import Form from '../Form/Form';
import DatePicker from '../Form/DatePicker/DatePicker';

type Props = {
  absences: DashboardDataAbsenceType[] | undefined;
  onSelect: (absences: DashboardDataAbsenceType[] | undefined) => void;
};

const AbsencesPerDaySelect: FunctionComponent<Props> = ({ absences, onSelect }) => {
  const isDateInRange = (dateToCheck: string, startDate: string, endDate: string) => {
    const checkDate = new Date(dateToCheck);
    const start = new Date(startDate);
    const end = new Date(endDate);
    return checkDate >= start && checkDate <= end;
  };

  const filterAbsences = useCallback(
    (selectedDate: string | undefined) => {
      if (!selectedDate) {
        onSelect(absences);
      }
      onSelect(
        absences?.filter((absence) => {
          if (!selectedDate) {
            return true;
          }
          return isDateInRange(selectedDate, absence.dateFrom, absence.dateTo);
        }),
      );
    },
    [absences],
  );

  return (
    <Form onSubmit={() => {}}>
      <DatePicker
        name={'date'}
        label={'Day'}
        onChange={(value) => {
          filterAbsences(value);
        }}
        placeholder={'Select day'}
      />
    </Form>
  );
};

export default AbsencesPerDaySelect;
