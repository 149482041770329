import React from 'react';

export type EntityContextType<E = any> = {
  entity: E;
  reload: () => Promise<E | void>;
  replace: (entity: E) => void;
  save: (data: E) => void;
  clone: (data: E) => void;
  loading: boolean;
};

const EntityContext = React.createContext<EntityContextType>({
  entity: undefined,
  reload: () => Promise.resolve(),
  save: () => {},
  clone: () => {},
  loading: true,
  replace: () => {},
});

export const useEntityContext = <E extends {} = any>() => {
  const ctx = React.useContext<EntityContextType<E>>(EntityContext);
  return ctx;
};
export default EntityContext;
