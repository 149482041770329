import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { HistoryEntryType } from '../../../@types/History/HistoryEntry';
import { Icon } from '../../atoms/Icon/Icon';
import HistoryIcon from '../../../Icons/History.icon';
import useModal from '../../../hooks/useModal';
import api from '../../../services/api';
import HistoryModal from './HistoryModal';
import { BasicEmployeeType } from '../../../@types/Employee/EmployeeType';

type Props = {
  subject: string;
  subjectId: number | string;
  reloadToken?: number | string;
  itemCreatedAt?: string;
  itemCreatedBy?: BasicEmployeeType;
};

type ApiResponse = AxiosResponse<{
  'hydra:member': HistoryEntryType[];
}>;

const HistoryCard: FunctionComponent<Props> = ({ subject, subjectId, reloadToken = null, itemCreatedAt, itemCreatedBy }) => {
  const { showModal } = useModal();
  const [entries, setEntries] = useState<HistoryEntryType[]>([]);

  const loadItems = () => {
    api
      .get('/history_entries', {
        params: {
          subject: subject,
          subjectId: subjectId,
        },
      })
      .then((response: ApiResponse) => {
        setEntries(response.data['hydra:member']);
      });
  };

  const handleClick = useCallback(() => {
    const body = <HistoryModal entries={entries} itemCreatedAt={itemCreatedAt} itemCreatedBy={itemCreatedBy} />;
    showModal({
      title: 'History log',
      body: body,
    });
  }, [entries]);

  useEffect(() => {
    loadItems();
  }, [reloadToken]);

  return (
    <Icon onClick={handleClick}>
      <HistoryIcon />
    </Icon>
  );
};

export default HistoryCard;
