import React, { FunctionComponent } from 'react';
import { color } from '../styles/Variables';

type Props = {
  size?: number;
  iconColor?: string;
};

const ArchivedIcon: FunctionComponent<Props> = ({ size = 1.2, iconColor = color.neutral['70'] }) => {
  return (
    <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.42301 13.3333V5.79992C2.23413 5.6777 2.07579 5.52214 1.94801 5.33325C1.82024 5.14436 1.75635 4.92214 1.75635 4.66658V2.66659C1.75635 2.29992 1.8869 1.98603 2.14801 1.72492C2.40913 1.46381 2.72301 1.33325 3.08968 1.33325H13.7563C14.123 1.33325 14.4369 1.46381 14.698 1.72492C14.9591 1.98603 15.0897 2.29992 15.0897 2.66659V4.66658C15.0897 4.92214 15.0258 5.14436 14.898 5.33325C14.7702 5.52214 14.6119 5.6777 14.423 5.79992V13.3333C14.423 13.6999 14.2925 14.0138 14.0313 14.2749C13.7702 14.536 13.4563 14.6666 13.0897 14.6666H3.75635C3.38968 14.6666 3.07579 14.536 2.81468 14.2749C2.55357 14.0138 2.42301 13.6999 2.42301 13.3333ZM3.75635 5.99992V13.3333H13.0897V5.99992H3.75635ZM13.7563 4.66658V2.66659H3.08968V4.66658H13.7563ZM6.42301 9.33325H10.423V7.99992H6.42301V9.33325Z"
        fill={iconColor ?? 'currentColor'}
      />
    </svg>
  );
};

export default ArchivedIcon;
