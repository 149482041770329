import styled from 'styled-components';
import { Container } from '../CommonStyles';
import { ModalBackground } from '../../../../molecules/Modal/Modal.styled';
import { color, fontSize } from '../../../../../styles/Variables';

export const Background = styled.div`
  ${ModalBackground};
  cursor: unset;
`;

export const LongFormContainer = styled.div`
  ${Container};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 80vh;
  overflow-y: scroll;
  overflow-x: visible;
  textarea {
    height: 100%;
  }
  button {
    &:focus {
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: calc(100% + 4px);
        width: calc(100% + 4px);
        border-radius: 0.6rem;
        border: 1px solid ${color.primary[30]};
      }
    }
  }
`;

export const AddFileLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: ${fontSize.body.m};
  cursor: pointer;
  &:hover {
    color: ${color.primary['80']};
    button {
      border-color: ${color.neutral['30']};
      background-color: ${color.neutral['30']};
    }
  }
  input {
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
  }
`;

export const AddFileButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 0.6rem;
  margin-right: 1rem;
  border: 1px dashed ${color.neutral['60']};
  background-color: transparent;
  cursor: pointer;
  transition: all 0.15s ease-out;
  transition-property: background-color, border;
`;
