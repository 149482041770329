import { DictValue } from '../../@types/Dictionary/DictValue';

export const BillingTypeDict: DictValue[] = [
  {
    value: 'contract',
    label: 'Contract',
  },
  {
    value: 'invoice',
    label: 'Invoice',
  },
];
