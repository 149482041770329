import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { properties } from './TimeView.properties';
import filters from './TimeView.filters';
import Table from '../../../../organisms/Table/Table';
import { useEntityContext } from '../../../../../Context/EntityContext/EntityContext';
import { ProjectType } from '../../../../../@types/Project/Project';
import * as Styled from './TimeView.styled';
import ProjectTime from '../../../../organisms/Projects/ProjectDetails/Overview/ProjectTime/ProjectTime';
import Text from '../../../../atoms/Text/Text';
import { parseDate } from '../../../../../helpers/date';
import Button from '../../../../atoms/Button/Button';
import DatabaseIcon from '../../../../../Icons/Database.icon';
import useModal from '../../../../../hooks/useModal';
import { Grid } from '../../../../atoms/Grid/Grid';
import MoveTimesheetsModal from './components/MoveTimesheetsModal';
import { TimesheetEntryType } from '../../../../../@types/TimesheetDay/TimesheetEntryType';

const TimeView: FunctionComponent = () => {
  const {
    entity: { 'hydra:reports': reports, ...project },
  } = useEntityContext<ProjectType>();
  const [selectedEntries, setSelectedEntries] = useState<TimesheetEntryType[]>([]);
  const { showModal } = useModal();
  const [reload, setReload] = useState(0);

  const canMoveEntries = useMemo(() => {
    return project.financesShown;
  }, [project]);

  const handleClick = useCallback(() => {
    showModal({
      title: 'Move timesheet',
      subtitle: `Are you sure, you want to move ${selectedEntries.filter((e) => !e.task).length} entries to another project?`,
      body: (
        <MoveTimesheetsModal
          entries={selectedEntries}
          onFinished={() => {
            setReload(Math.random());
            setSelectedEntries([]);
          }}
        />
      ),
    });
  }, [selectedEntries]);

  return (
    <>
      {reports && reports['project-finances'] && (
        <Styled.InfoContainer>
          <Text size={'s'}>Last updated at: {parseDate(reports['project-finances'], true)}</Text>
        </Styled.InfoContainer>
      )}
      <ProjectTime
        project={project}
        componentGrid={{
          $gridTemplateColumns: '1fr 1fr 1fr 1fr',
          $columnGap: '6.4rem',
          $rowGap: '2.4rem',
        }}
      />
      <div style={{ height: '6rem' }} />
      {selectedEntries.length > 0 && canMoveEntries && (
        <Grid $simple $justifyContent={'flex-end'} style={{ marginBottom: '1rem' }}>
          <Button $text={'Move entries'} $icon={<DatabaseIcon />} onClick={handleClick} />
        </Grid>
      )}
      <Table
        reloadKey={reload}
        onSelect={setSelectedEntries}
        context={'/api/timesheet_entries'}
        url={'/timesheet_entries'}
        properties={properties}
        filters={filters(project.id)}
        globalFilters={{
          'project.id': project.id,
        }}
      />
    </>
  );
};

export default TimeView;
