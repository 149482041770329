import styled from 'styled-components';
import { BodyText } from '../../../styles/Typography';
import { fontSize } from '../../../styles/Variables';

export const PersonWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: ${fontSize.body.xs};
  gap: 0.8rem;
`;

export const PersonLabel = styled.p`
  ${BodyText};
  margin-left: 0.8rem;
`;
