import React, { FunctionComponent } from 'react';
import ModalForm from '../../../../organisms/Form/ModalForm/ModalForm';
import SelectInput from '../../../Form/SelectInput/SelectInput';
import HiddenInput from '../../../../atoms/Form/HiddenInput/HiddenInput';
import Button from '../../../../atoms/Button/Button';
import useModal from '../../../../../hooks/useModal';
import InventoryItemType from '../../../../../@types/Inventory/InventoryItemType';

type Props = {
  entity: InventoryItemType;
};

const AssignButton: FunctionComponent<Props> = ({ entity }) => {
  const { showModal } = useModal();

  return (
    <Button
      $text={'Assign'}
      onClick={() => {
        showModal({
          title: 'Assign item to employee',
          subtitle: 'Assign inventory item',
          body: (
            <ModalForm basePath={'/api/inventory_allocations'} buttonText={'Assign'}>
              <SelectInput name={'person'} baseUrl={'/api/employees/'} baseUrlParams={{ status: ['active'] }} />
              <HiddenInput name={'type'} value={'release'} />
              <HiddenInput name={'items.0'} value={entity['@id']} />
            </ModalForm>
          ),
        });
      }}
    />
  );
};

export default AssignButton;
