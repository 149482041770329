import React, { useCallback, useEffect, useState } from 'react';
import * as Styled from './CostListExtendedRow.styled';
import ExtendedRowComponent from '../../atoms/Table/ExtendedRowComponent';
import { CostType } from '../../../@types/Finances/Cost/CostType';
import TableCell from '../../atoms/Table/TableCell';
import { Grid, GridItem } from '../../atoms/Grid/Grid';
import SingleDetail from '../../molecules/SingleDetail/SingleDetail';
import Text from '../../atoms/Text/Text';
import { color } from '../../../styles/Variables';
import api from '../../../services/api';
import { SubcostType } from '../../../@types/Finances/Cost/SubcostType';
import PurchaseOrderSingleDetail from '../../molecules/SingleDetail/PurchaseOrderSingleDetail/PurchaseOrderSingleDetail';
import ProjectSingleDetail from '../../molecules/SingleDetail/ProjectSingleDetail/ProjectSingleDetail';

const CostListExtendedRow: ExtendedRowComponent<CostType> = ({ entity }) => {
  const [subcosts, setSubcosts] = useState<null | SubcostType[]>(null);

  const getSubcosts = useCallback(() => {
    api.get(`/subcosts?cost.id=${entity.id}`).then((response) => {
      setSubcosts(response.data['hydra:member']);
    });
  }, []);

  useEffect(() => {
    if (!subcosts) {
      getSubcosts();
    }
  }, [subcosts]);

  return (
    <Styled.CostListExtendedRow>
      <TableCell colSpan={12}>
        <table style={{ width: '100%' }}>
          {subcosts && subcosts.length > 0 ? (
            subcosts.map((subcost, idx) => {
              let bg = idx % 2 === 0 ? color.neutral['40'] : color.neutral['10'];
              return (
                <tr key={idx} style={{ backgroundColor: bg }}>
                  <TableCell colSpan={2}>
                    <Grid spacing={2}>
                      <GridItem $desktop={3}>
                        <SingleDetail title={'Net value'}>{subcost.amountNet.asString}</SingleDetail>
                      </GridItem>
                      <GridItem $desktop={4}>
                        <SingleDetail title={'Cost type'}>{subcost.costType.label}</SingleDetail>
                      </GridItem>
                      {subcost.purchaseOrder?.number && (
                        <GridItem $desktop={6}>
                          <PurchaseOrderSingleDetail purchaseOrder={subcost.purchaseOrder} />
                        </GridItem>
                      )}
                    </Grid>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <Grid spacing={2}>
                      <GridItem $desktop={12}>
                        <SingleDetail title={'Kind'}>{subcost.kind}</SingleDetail>
                      </GridItem>
                      {subcost.project && (
                        <GridItem $desktop={12}>
                          <ProjectSingleDetail name={subcost.project.name} id={subcost.project.id} />
                        </GridItem>
                      )}
                      {subcost.employee && (
                        <GridItem $desktop={4}>
                          <SingleDetail title={'Employee'}>{subcost.employee.name}</SingleDetail>
                        </GridItem>
                      )}
                    </Grid>
                  </TableCell>
                  <TableCell colSpan={6}>
                    <Grid spacing={2}>
                      <GridItem $desktop={6}>
                        <SingleDetail title={'Location'}>{subcost.branch?.shortName}</SingleDetail>
                      </GridItem>
                      <GridItem $desktop={6}>
                        <SingleDetail title={'Budget symbol'}>{subcost.budgetSymbol}</SingleDetail>
                      </GridItem>
                      <GridItem $desktop={12}>
                        <SingleDetail title={'Description'}>{subcost.description}</SingleDetail>
                      </GridItem>
                    </Grid>
                  </TableCell>
                </tr>
              );
            })
          ) : (
            <Text>No subcosts</Text>
          )}
        </table>
      </TableCell>
    </Styled.CostListExtendedRow>
  );
};

export default CostListExtendedRow;
