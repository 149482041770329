import DateString from '../../../../atoms/DateString/DateString';
import React from 'react';
import { CellTemplate } from '../../CellTemplate';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import Text from '../../../../atoms/Text';
import { DictValue } from '../../../../../@types/Dictionary/DictValue';
import { TextSizeType } from '../../../../../@types/Text/TextSizeType';
import { ColorType } from '../../../../../@types/Color/ColorType';

const DateTemplate: CellTemplate<string, { textProperties?: { bold?: boolean; size?: TextSizeType; color?: ColorType } }> = ({ value, options }) => {
  return (
    <Text color={options?.textProperties?.color ?? 'darkGrey'} size={options?.textProperties?.size ?? 'l'} bold={true}>
      {value ? <DateString input={value} /> : 'N/A'}
    </Text>
  );
};
export default DateTemplate;
