import React, { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import BaseDatePicker from 'react-datepicker';
import { InputProps } from '../../../../@types/Form/InputProps';
import { CustomInput } from '../DatePicker/DatePicker';
import FormError from '../../../atoms/Form/FormError/FormError';
import styled from 'styled-components';
import { color } from '../../../../styles/Variables';

const Container = styled.div`
  .react-datepicker__close-icon {
    top: unset;
    bottom: 0;
    height: 3.8rem;
    display: flex;
    justify-content: end;
    align-items: center;
    &:after {
      color: ${color.primary[60]};
      background-color: transparent;
      font-size: 2.2rem;
      display: flex;
      align-items: center;
    }
  }
`;

type Props = InputProps & { required?: boolean };

const MonthPicker: FunctionComponent<Props> = (props) => {
  const { control } = useFormContext();
  const [ready] = React.useState(true);

  return (
    <Container>
      {ready && (
        <Controller
          defaultValue={props.defaultValue}
          control={control}
          name={props.name}
          render={({ field }) => {
            return (
              <>
                <BaseDatePicker
                  portalId="data-picker-portal"
                  placeholderText={props.placeholder ?? props.label}
                  onChange={(date) => {
                    if (!date) {
                      field.onChange(undefined);
                      props.onChange && props.onChange(undefined);
                      return;
                    }
                    date?.setHours(2);
                    const newValue = date.toISOString().substring(0, 7);
                    field.onChange(newValue);
                    props.onChange && props.onChange(newValue);
                  }}
                  required={props.required}
                  selected={field.value ? new Date(field.value) : null}
                  showYearDropdown={true}
                  isClearable={!props.required}
                  dateFormat={'yyyy-MM'}
                  customInput={<CustomInput {...props} value={field.value} />}
                  showMonthYearPicker
                  onSelect={() => {}}
                />
                <FormError name={props.name} />
              </>
            );
          }}
        />
      )}
    </Container>
  );
};

export default MonthPicker;
