import React, { FunctionComponent } from 'react';

type PropType = {
  size?: number;
};

const KeynoteIcon: FunctionComponent<PropType> = ({ size = 1 }) => {
  return (
    <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.8245 12V20H16.8245V22H6.82455V20H10.8245V12H2.81655C2.26855 12 1.91055 11.57 2.01955 11.023L3.62955 2.977C3.73755 2.437 4.26955 2 4.82455 2H18.8225C19.3755 2 19.9095 2.43 20.0185 2.977L21.6285 11.023C21.7365 11.563 21.3685 12 20.8315 12H12.8245Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default KeynoteIcon;
