import { useCallback, useMemo } from 'react';

type SaveEditableValueInLocalStorageMethods = {
  setStorageValue: (value: string | null) => void;
  storageValue: string | null;
  deleteStorageValue: () => void;
};

function useLocalStorage(localStorageKey: string, value: string | null): SaveEditableValueInLocalStorageMethods {
  const setStorageValue = useCallback(
    (value: any) => {
      localStorage.setItem(localStorageKey, value as string);
    },
    [localStorageKey, value],
  );

  const storageValue = useMemo(() => {
    return localStorage.getItem(localStorageKey);
  }, [value, localStorageKey]);

  const deleteStorageValue = useCallback(() => {
    localStorage.removeItem(localStorageKey);
  }, [localStorageKey]);

  return { setStorageValue: setStorageValue, storageValue: storageValue, deleteStorageValue: deleteStorageValue };
}

export default useLocalStorage;
