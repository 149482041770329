import React, { useEffect } from 'react';
import { TableFilterInterface } from '../../../../molecules/TableFilters/TableFilterInterface';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import SelectFilter from '../../../../molecules/TableFilters/SelectFilter/SelectFilter';
import { ContractAppliesDict } from '../../../../../@dicts/Contract/ContractAppliesDict';
import { useFormContext } from 'react-hook-form';
import PurchaseOrderOption from '../../../../molecules/Finances/PurchaseOrderOption/PurchaseOrderOption';
import PurchaseOrderLabel from '../../../../molecules/Finances/PurchaseOrderLabel';
import ProjectOption from '../../../../molecules/Projects/ProjectOption/ProjectOption';
import ProjectLabel from '../../../../molecules/Projects/ProjectLabel/ProjectLabel';

type Props = {};

const ContractAppliesFilter: TableFilterInterface<{}> = ({ name, filter, onChange }) => {
  const { watch, setValue } = useFormContext();
  const type = watch(name);

  useEffect(() => {
    setValue('filters.detailsEmployee__id', undefined);
    setValue('filters.detailsPartner__id', undefined);
    setValue('filters.detailsPurchaseOrder__id', undefined);
    setValue('filters.detailsProject__id', undefined);
  }, [type]);

  return (
    <Grid spacing={2}>
      <GridItem $desktop={6}>
        <SelectFilter
          filter={filter}
          options={{
            options: ContractAppliesDict,
          }}
          onChange={onChange}
          name={name}
        />
      </GridItem>
      <GridItem $desktop={6}>
        {type === 'employee' && (
          <SelectFilter
            filter={{
              name: 'filters.detailsEmployee.id',
              type: 'select',
              label: 'Employee',
              options: {
                baseUrl: '/api/employees',
              },
            }}
            options={{
              baseUrl: '/api/employees',
              baseUrlParams: {
                demo: 0,
              },
            }}
            onChange={onChange}
            name={'filters.detailsEmployee__id'}
          />
        )}
        {type === 'partner' && (
          <SelectFilter
            filter={{
              name: 'filters.detailsPartner__id',
              type: 'select',
              label: 'Partner',
              options: {
                baseUrl: '/api/clients',
              },
            }}
            options={{
              baseUrl: '/api/clients',
            }}
            onChange={onChange}
            name={'filters.detailsPartner__id'}
          />
        )}
        {type === 'purchase_order' && (
          <SelectFilter
            filter={{
              name: 'filters.detailsPurchaseOrder__id',
              type: 'select',
              label: 'Purchase order',
              options: {
                baseUrl: '/api/purchase_orders',
              },
            }}
            options={{
              baseUrl: '/api/purchase_orders',
              optionComponent: PurchaseOrderOption,
              singleValueComponent: PurchaseOrderLabel,
              baseUrlParams: {
                status: 'accepted',
              },
            }}
            onChange={onChange}
            name={'filters.detailsPurchaseOrder__id'}
          />
        )}
        {type === 'project' && (
          <SelectFilter
            filter={{
              name: 'filters.detailsProject__id',
              type: 'select',
              label: 'Project',
              options: {
                baseUrl: '/api/projects',
              },
            }}
            options={{
              baseUrl: '/api/projects',
              optionComponent: ProjectOption,
              singleValueComponent: ProjectLabel,
            }}
            onChange={onChange}
            name={'filters.detailsProject__id'}
          />
        )}
      </GridItem>
    </Grid>
  );
};

export default ContractAppliesFilter;
