import React, { FunctionComponent } from 'react';

type Props = {
  size?: number;
  color?: string;
};

const AvatarInCircleIcon: FunctionComponent<Props> = ({ size = 1.4, color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={`${size}em`} height={`${size}em`} viewBox="0 0 24 24" fill="none">
      <g>
        <path
          d="M12 11.7949C11.3583 11.7949 10.809 11.5664 10.3521 11.1095C9.89514 10.6526 9.66667 10.1033 9.66667 9.46161C9.66667 8.81995 9.89514 8.27065 10.3521 7.81371C10.809 7.35677 11.3583 7.1283 12 7.1283C12.6416 7.1283 13.1909 7.35677 13.6479 7.81371C14.1048 8.27065 14.3333 8.81995 14.3333 9.46161C14.3333 10.1033 14.1048 10.6526 13.6479 11.1095C13.1909 11.5664 12.6416 11.7949 12 11.7949ZM7 16.8718V15.3898C7 15.0633 7.08868 14.761 7.26603 14.4828C7.44338 14.2046 7.68034 13.9907 7.97692 13.8411C8.63589 13.518 9.30064 13.2757 9.97115 13.1142C10.6417 12.9526 11.3179 12.8719 12 12.8719C12.682 12.8719 13.3583 12.9526 14.0288 13.1142C14.6993 13.2757 15.3641 13.518 16.023 13.8411C16.3196 13.9907 16.5566 14.2046 16.7339 14.4828C16.9113 14.761 17 15.0633 17 15.3898V16.8718H7ZM7.99998 15.8718H16V15.3898C16 15.2548 15.9609 15.1298 15.8827 15.0148C15.8045 14.8998 15.6983 14.806 15.5641 14.7334C14.9897 14.4505 14.4041 14.2362 13.8071 14.0904C13.2102 13.9447 12.6078 13.8718 12 13.8718C11.3922 13.8718 10.7898 13.9447 10.1928 14.0904C9.59589 14.2362 9.01024 14.4505 8.43588 14.7334C8.30169 14.806 8.1955 14.8998 8.1173 15.0148C8.03909 15.1298 7.99998 15.2548 7.99998 15.3898V15.8718ZM12 10.7949C12.3667 10.7949 12.6805 10.6644 12.9416 10.4033C13.2028 10.1422 13.3333 9.82828 13.3333 9.46161C13.3333 9.09495 13.2028 8.78106 12.9416 8.51995C12.6805 8.25884 12.3667 8.12828 12 8.12828C11.6333 8.12828 11.3194 8.25884 11.0583 8.51995C10.7972 8.78106 10.6666 9.09495 10.6666 9.46161C10.6666 9.82828 10.7972 10.1422 11.0583 10.4033C11.3194 10.6644 11.6333 10.7949 12 10.7949Z"
          fill={color ?? 'currentColor'}
        />
      </g>
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke={color ?? 'currentColor'} strokeDasharray="2 2" />
    </svg>
  );
};

export default AvatarInCircleIcon;
