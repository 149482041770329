import React, { FunctionComponent } from 'react';

type PropType = {
  size?: number;
  fill?: boolean;
};

const StarIcon: FunctionComponent<PropType> = ({ size = 1, fill = false }) => {
  return fill ? (
    <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.88398 14.6818L4.96732 9.99847L1.33398 6.84847L6.13398 6.4318L8.00065 2.01514L9.86732 6.4318L14.6673 6.84847L11.034 9.99847L12.1173 14.6818L8.00065 12.1985L3.88398 14.6818Z"
        fill="currentColor"
      />
    </svg>
  ) : (
    <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.90065 11.8985L8.00065 10.6318L10.1007 11.9151L9.55065 9.51514L11.4007 7.91514L8.96732 7.69847L8.00065 5.4318L7.03398 7.6818L4.60065 7.89847L6.45065 9.51514L5.90065 11.8985ZM3.88398 14.6818L4.96732 9.99847L1.33398 6.84847L6.13398 6.4318L8.00065 2.01514L9.86732 6.4318L14.6673 6.84847L11.034 9.99847L12.1173 14.6818L8.00065 12.1985L3.88398 14.6818Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default StarIcon;
