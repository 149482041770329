import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { SidebarContext } from './SidebarContext';

type Props = {
  children: ReactNode;
};

export const SidebarProvider: React.FC<Props> = ({ children }) => {
  const [previousBody, setPreviousBody] = useState<ReactNode | string | undefined>();
  const [body, setBody] = useState<ReactNode | string | undefined>();
  const isOpen = useMemo(() => body !== undefined, [body]);
  const [isPdfContext, setIsPdfContext] = useState<boolean>(false);

  const closeSidebar = useCallback(() => {
    if (!previousBody) {
      setBody(undefined);
    } else {
      setBody(previousBody);
      setPreviousBody(undefined);
    }
    setIsPdfContext(false);
  }, [previousBody]);

  const openSidebar = useCallback(
    (newBody: ReactNode | string) => {
      if (body) {
        setPreviousBody(body);
        setBody(newBody);
      } else {
        setBody(newBody);
      }
    },
    [body],
  );

  const setPdfContext = useCallback(() => {
    setIsPdfContext(true);
  }, []);

  return (
    <SidebarContext.Provider
      value={{
        body,
        openSidebar,
        closeSidebar,
        isOpen,
        isPdfContext,
        setPdfContext,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
