export function convertTimeStringToMinutes(timeString: string) {
  if (/^\d+$/.test(timeString)) return Number(timeString);

  const hhmmMatch = timeString.match(/^(\d{1,2}):(\d{2})$/);
  if (hhmmMatch) {
    const hours = parseInt(hhmmMatch[1], 10);
    const minutes = parseInt(hhmmMatch[2], 10);
    return hours * 60 + minutes;
  }

  const daysMatch = timeString.match(/\d+(?=\s*d)/);
  const hoursMatch = timeString.match(/\d+(?=\s*h)/);
  const minutesMatch = timeString.match(/\d+(?=\s*m)/);

  const days = daysMatch ? parseInt(daysMatch[0], 10) : 0;
  const hours = hoursMatch ? parseInt(hoursMatch[0], 10) : 0;
  const minutes = minutesMatch ? parseInt(minutesMatch[0], 10) : 0;

  return days * 8 * 60 + hours * 60 + minutes;
}
