import React, { FunctionComponent, useEffect } from 'react';
import StaticSelectInput from '../../../molecules/Form/SelectInput/StaticSelectInput';
import { useFormContext } from 'react-hook-form';
import { NotificationContentType } from '../../../../@types/Notification/NotificationType';
import { DictValue } from '../../../../@types/Dictionary/DictValue';

type Props = {
  onSelect: (notificationType: NotificationContentType | undefined) => void;
};

const NotificationTypeSelect: FunctionComponent<Props> = ({ onSelect }) => {
  const notificationTypeDict: DictValue<any, NotificationContentType>[] = [
    { value: 'absence_accept', label: 'Absence acceptance' },
    { value: 'absence_cancel', label: 'Absence cancel' },
    { value: 'absence_create', label: 'Absence create' },
    { value: 'absence_reject', label: 'Absence reject' },
    { value: 'timesheet', label: 'Timesheet' },
    { value: 'role', label: 'Role' },
    { value: 'mention', label: 'Mention' },
    { value: 'wall', label: 'Wall' },
    { value: 'comment', label: 'Comment' },
    { value: 'task_change', label: 'Task change' },
    { value: 'task_assignment', label: 'Task assignment' },
    { value: 'task_status', label: 'Task status' },
    { value: 'inventory_confirmed', label: 'Inventory confirmed' },
    { value: 'inventory_release', label: 'Inventory release' },
    { value: 'inventory_return', label: 'Inventory return' },
    { value: 'inventory_warranty', label: 'Inventory warranty' },
    { value: 'project_settled', label: 'Project settled' },
    { value: 'request_status', label: 'Request status' },
    { value: 'cost_sign', label: 'Costs' },
  ];

  const { watch } = useFormContext();
  const notificationType = watch('notificationType');

  useEffect(() => {
    onSelect(notificationType);
  }, [notificationType]);

  return <StaticSelectInput defaultValue={null} name={'notificationType'} options={notificationTypeDict} label={'Select notification type'} />;
};

export default NotificationTypeSelect;
