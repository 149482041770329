import React from 'react';
import { TableProperties } from '../../../../../../@types/Table/TableProperty';
import StringTemplate from '../../../../../../components/molecules/CellTemplates/common/StringTemplate/StringTemplate';
import PersonTemplate from '../../../../../../components/molecules/CellTemplates/common/PersonTemplate/PersonTemplate';
import InventoryItemType from '../../../../../../@types/Inventory/InventoryItemType';
import InventoryIdTemplate from '../../../../../../components/molecules/CellTemplates/Admin/InventoryIdTemplate';
import GuaranteeTemplate from '../../../../../../components/molecules/CellTemplates/Admin/GuaranteeTemplate';

const inventoryItemTableProperties: TableProperties<InventoryItemType> = [
  {
    name: '@id',
    visibleName: 'ID',
    template: InventoryIdTemplate,
  },

  {
    name: 'type.name',
    visibleName: 'Type',
    template: StringTemplate,
    templateOptions: {
      textProperties: {
        size: 's',
        color: 'darkGrey',
        bold: true,
      },
    },
  },
  {
    name: 'serialNumber',
    visibleName: 'Serial number',
    template: StringTemplate,
    templateOptions: {
      textProperties: {
        size: 's',
        color: 'grey',
        bold: true,
      },
    },
  },
  {
    name: 'assignedTo',
    visibleName: 'Assignee',
    template: PersonTemplate,
    templateOptions: {
      areInitials: false,
      textProperties: {
        size: 's',
        color: 'darkGrey',
        bold: true,
      },
    },
  },
  {
    name: 'warrantyDate',
    visibleName: 'Guarantee',
    template: GuaranteeTemplate,
  },
];

export default inventoryItemTableProperties;
