import styled from 'styled-components';
import Container from '../Container/Container';

export const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  padding-top: 3.6rem;
  min-height: unset;
  gap: 4.8rem;
`;
