import React, { FunctionComponent } from 'react';

type PropType = {
  size?: number;
};

const StopIcon: FunctionComponent<PropType> = ({ size = 1 }) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.3 14.7V9.30005H14.7V14.7H9.3Z" fill={'currentColor'} />
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" strokeWidth={1.5} stroke={'currentColor'} />
    </svg>
  );
};

export default StopIcon;
