import React from 'react';
import { TableProperties } from '../../../@types/Table/TableProperty';
import { TaskType } from '../../../@types/Task/TaskType';
import StringTemplate from '../../molecules/CellTemplates/common/StringTemplate/StringTemplate';
import TaskProgressTemplate from '../../molecules/CellTemplates/Projects/Tasks/TaskProgressTemplate';
import TaskPriorityTemplate from '../../molecules/CellTemplates/Projects/Tasks/TaskPriorityTemplate';
import TaskDateTemplate from '../../molecules/CellTemplates/Projects/Tasks/TaskDateTemplate';
import DateTemplate from '../../molecules/CellTemplates/common/DateTemplate/DateTemplate';
import TaskIdTemplate from './Templates/TaskIdTemplate';
import ProjectTemplate from '../../molecules/CellTemplates/common/ProjectTemplate/ProjectTemplate';

const properties: TableProperties<TaskType> = [
  {
    name: '@id',
    visibleName: 'ID',
    template: TaskIdTemplate,
    sortable: true,
  },
  {
    name: 'title',
    visibleName: 'Title',
    template: StringTemplate,
    sortable: true,
    templateOptions: {
      textProperties: {
        size: 'm',
        color: 'darkGrey',
        bold: true,
      },
    },
  },
  {
    name: 'project',
    visibleName: 'Project',
    template: ProjectTemplate,
    sortable: true,
  },
  {
    name: 'deadline',
    visibleName: 'Deadline',
    template: TaskDateTemplate,
    sortable: true,
  },
  {
    name: 'estimate',
    visibleName: 'Time',
    template: TaskProgressTemplate,
  },
  {
    name: 'priority',
    visibleName: 'Priority',
    template: TaskPriorityTemplate,
    sortable: true,
  },
  {
    name: 'createdAt',
    visibleName: 'Created',
    template: DateTemplate,
    templateOptions: {
      textProperties: {
        size: 's',
        color: 'darkGrey',
        bold: true,
      },
    },
  },
];

export default properties;
