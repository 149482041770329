import { useMemo } from 'react';

export function getResourceType(name: string, previewUrl?: string): string | undefined {
  return useMemo(() => {
    if (previewUrl) {
      return 'image';
    }
    return name.split('.').pop();
  }, [name]);
}
