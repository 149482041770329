import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { ProjectType } from '../../../../../@types/Project/Project';
import OverlayForm from '../../../../molecules/OverlayForm/OverlayForm';
import Heading from '../../../../atoms/Heading';
import { GridItem } from '../../../../atoms/Grid/Grid';
import SelectInput from '../../../../molecules/Form/SelectInput/SelectInput';
import Select from '../../../../molecules/Form/SelectInput/SelectInput';
import IriHelper from '../../../../../helpers/iri-helper';
import OverlayFormSection from '../../../../molecules/OverlayForm/OverlayFormSection';
import { TextInput } from '../../../../molecules/Form/TextInput/TextInput';
import EmployeeInput from '../../../../molecules/Form/EmployeeInput/EmployeeInput';
import DatePicker from '../../../../molecules/Form/DatePicker/DatePicker';
import { SwitchInput } from '../../../../molecules/Form/SwitchInput/SwitchInput';
import { NonBillableDetailDict } from '../../../../../@dicts/Project/NonBillableDetailDict';
import CurrencyInput from '../../../../molecules/Form/CurrencyInput/CurrencyInput';
import MoneyInput from '../../../../molecules/Form/MoneyInput/MoneyInput';
import SingleDetail from '../../../../molecules/SingleDetail/SingleDetail';
import moneyAsString from '../../../../../helpers/moneyAsString';
import NumberInput from '../../../../molecules/Form/NumberInput/NumberInput';
import RichTextInput from '../../../../molecules/Form/RichTextInput/RichTextInput';
import useEntity from '../../../../../hooks/useEntity';
import useOverlay from '../../../../../hooks/useOverlay';
import HiddenInput from '../../../../atoms/Form/HiddenInput/HiddenInput';
import { ClientType } from '../../../../../@types/Project/Client/ClientType';
import api from '../../../../../services/api';
import { CategoryType } from '../../../../../@types/Category/CategoryType';
import Text from '../../../../atoms/Text';
import PlusIcon from '../../../../../Icons/Plus.icon';
import AcceptIcon from '../../../../../Icons/Accept.icon';
import Tooltip from '../../../../molecules/Tooltip/Tooltip';
import InfoIcon from '../../../../../Icons/info.icon';
import styled from 'styled-components';
import { Icon } from '../../../../atoms/Icon/Icon';
import { color } from '../../../../../styles/Variables';
import Button from '../../../../atoms/Button/Button';
import useModal from '../../../../../hooks/useModal';
import ModalForm from '../../../Form/ModalForm/ModalForm';

type Props = {
  project?: ProjectType;
  onSuccess?: (project: ProjectType) => void;
  categoryId?: number;
  clientId?: number;
};

const defaultValues = {
  billable: true,
  defaultCurrency: 'PLN',
};

const TooltipWrapper = styled.div`
  .tooltip-container {
    left: 10%;
  }
`;

const TooltipInfoIcon = styled.div`
  position: absolute;
  right: 0;
`;

const ProjectForm: FunctionComponent<Props> = ({ onSuccess, project, categoryId, clientId }) => {
  const isEdit = !!project;
  const { create, save } = useEntity<ProjectType>(project ? project['@id'] : '/api/projects');
  const { hideOverlay } = useOverlay();
  const [client, setClient] = useState<null | ClientType>(null);
  const [category, setCategory] = useState<null | CategoryType>(null);
  const [loading, setLoading] = useState(false);
  const { showModal } = useModal();

  const handleSubmit = useCallback(
    (data: ProjectType) => {
      setLoading(true);
      if (isEdit) {
        save(data)
          .then((response) => {
            onSuccess && onSuccess(response);
            hideOverlay();
            setLoading(false);
          })
          .catch(() => setLoading(false));
      } else {
        create(data)
          .then((response) => {
            onSuccess && onSuccess(response);
            hideOverlay();
            setLoading(false);
          })
          .catch(() => setLoading(false));
      }
    },
    [isEdit, onSuccess],
  );

  const loadPartner = useCallback(() => {
    if (!clientId) {
      return null;
    }
    api.get<ClientType>(`/clients/${clientId}`).then((response) => {
      setClient(response.data);
    });
  }, [clientId]);

  const loadCategory = useCallback(() => {
    if (!categoryId) {
      return null;
    }
    api.get<CategoryType>(`/categories/${categoryId}`).then((response) => {
      setCategory(response.data);
    });
  }, [categoryId]);

  useEffect(() => {
    if (!client) {
      loadPartner();
    }
    if (!category) {
      loadCategory();
    }
  }, [category, client]);

  return (
    <OverlayForm
      onSubmit={handleSubmit}
      defaultValues={project ?? defaultValues}
      buttonProps={{
        text: isEdit ? 'Save project' : 'Add new project',
        icon: isEdit ? <AcceptIcon /> : <PlusIcon />,
        iconSize: 1.3,
        waiting: loading,
      }}
    >
      {({ watch, unregister }) => {
        let partner = watch('client');
        if (partner && typeof partner !== 'string') {
          partner = partner['@id'];
        }
        const partnerId = partner ? IriHelper.iriToId(partner) : undefined;
        const billable = watch('billable');
        const currency = watch('defaultCurrency');
        const incomesEstimated = watch('incomesEstimated');
        const costsEstimated = watch('costsEstimated');
        const disableSharepointSpace = watch('disableSharepointSpace');

        const [key, setKey] = useState(Math.random());

        useEffect(() => {
          if (partnerId) {
            setKey(Math.random());
          }
        }, [partnerId]);

        useEffect(() => {
          if (billable) {
            unregister('nonBillableDetail');
          }
        }, [billable]);

        return (
          <>
            <div style={{ marginBottom: '5rem' }}>
              <Heading size={'l'}>{isEdit ? 'Edit project' : 'Create new project'}</Heading>
            </div>
            <OverlayFormSection heading={"Project's information"}>
              <GridItem $desktop={12}>
                <TextInput name={'name'} label={'Project name'} required />
              </GridItem>
              {categoryId && clientId ? (
                <>
                  <HiddenInput name={'category'} value={`api/categories/${categoryId}`} />
                  <HiddenInput name={'client'} value={`api/clients/${clientId}`} />
                </>
              ) : null}
              {client && category ? (
                <>
                  <GridItem $desktop={12}>
                    <Text bold={true}>Partner: </Text>&nbsp;
                    <Text>{client.name}</Text>
                  </GridItem>
                  <GridItem $desktop={12}>
                    <Text bold={true}>Category: </Text>&nbsp;
                    <Text>{category.name}</Text>
                  </GridItem>
                </>
              ) : null}
              {(!categoryId || !clientId) && (
                <>
                  <GridItem $desktop={12}>
                    <SelectInput name={'client'} label={'Partner'} baseUrl={'/api/clients/'} baseUrlParams={{ archived: 'false' }} required />
                  </GridItem>
                  {partnerId && (
                    <>
                      <GridItem $desktop={12} style={{ display: 'flex' }}>
                        <div style={{ width: '100%' }}>
                          <SelectInput
                            key={key}
                            name={'category'}
                            label={'Category'}
                            baseUrl={'/api/categories/'}
                            baseUrlParams={{
                              'client.id': partnerId,
                              archived: 'false',
                            }}
                            required
                          />
                        </div>
                        <Button
                          $text={'Add category'}
                          kind={'link'}
                          style={{ paddingRight: 0 }}
                          onClick={() => {
                            showModal({
                              title: 'New category',
                              subtitle: 'Add new category',
                              body: (
                                <ModalForm basePath={'/api/categories'} buttonText={'Add'} onChange={() => setKey(Math.random())}>
                                  <HiddenInput name={'client'} value={`/api/clients/${partnerId}`} />
                                  <TextInput name={'name'} label={'Category name'} placeholder={'Type category name'} required />
                                </ModalForm>
                              ),
                              width: 42,
                            });
                          }}
                        />
                      </GridItem>
                      <GridItem $desktop={6}>
                        <TextInput
                          name={'partnerSideNotificationEmail'}
                          label={'Notifications email'}
                          placeholder={"Email of the person receiving project status updates on the partner's side"}
                        />
                      </GridItem>
                      <GridItem $desktop={6}>
                        <TextInput
                          name={'partnerSideResponsiblePersonEmail'}
                          label={'Responsible person email'}
                          placeholder={"Contact to the person responsible for the project on the partner's side"}
                        />
                      </GridItem>
                      <GridItem
                        $desktop={12}
                        style={{
                          justifyContent: 'end',
                          display: 'flex',
                          paddingRight: 0,
                        }}
                      ></GridItem>
                    </>
                  )}
                </>
              )}

              <GridItem $desktop={12}>
                <EmployeeInput name={'leader'} label={'Project owner'} group={'team'} required />
              </GridItem>
              <GridItem $desktop={6}>
                <DatePicker name={'plannedCompletionDate'} label={'End date'} placeholder={'Select deadline date'} required />
              </GridItem>
              <GridItem $desktop={6}>
                <SwitchInput name={'secret'} label={'Secret'} />
              </GridItem>
              <GridItem
                $desktop={12}
                style={{
                  maxWidth: 'unset',
                  transform: 'translateX(-25%)',
                }}
              >
                <RichTextInput name={'description'} label={'Project description'} required />
              </GridItem>
              <GridItem $desktop={6}>{!disableSharepointSpace && <TextInput name={'sharepointUrl'} label={'Sharepoint space'} required />}</GridItem>
              <GridItem $desktop={6}>
                <SwitchInput name={'disableSharepointSpace'} label={'There is no sharepoint space'} />
              </GridItem>
              <GridItem $desktop={12}>
                <Select name="tags" label="Tags" placeholder="Tags" baseUrl="/project_tags" optionLabel="value" creatable isMulti />
              </GridItem>
              <GridItem $desktop={6} style={billable ? { marginBottom: '6.4rem' } : {}}>
                <SwitchInput name={'billable'} label={'Billable'} />
              </GridItem>
              <GridItem $desktop={12}>
                {!billable && <SelectInput name={'nonBillableDetail'} label={'Details'} options={NonBillableDetailDict} required={!billable} />}
              </GridItem>
            </OverlayFormSection>
            <OverlayFormSection heading={'Finance information'}>
              <GridItem $desktop={12}>
                <SelectInput name={'branch'} label={'Location'} optionLabel={'shortName'} baseUrl={'/api/branches/'} required />
              </GridItem>
              <GridItem $desktop={4}>
                <CurrencyInput name={'defaultCurrency'} required />
              </GridItem>
              <GridItem $desktop={4} style={{ position: 'relative' }}>
                <TooltipWrapper>
                  <Tooltip
                    content={
                      <Text color={'light'} size={'xs'}>
                        If no income type 0
                      </Text>
                    }
                    trigger={
                      <TooltipInfoIcon>
                        <Icon size={0.8} color={color.neutral[60]}>
                          <InfoIcon />
                        </Icon>
                      </TooltipInfoIcon>
                    }
                    arrowPosition={'rightCenter'}
                  />
                </TooltipWrapper>
                <MoneyInput name={'incomesEstimated'} required label={'Planned income'} currency={currency} suffix={false} placeholder={'Value'} />
              </GridItem>
              <GridItem $desktop={4}>
                <MoneyInput name={'costsEstimated'} required label={'Planned costs'} currency={currency} suffix={false} placeholder={'Value'} />
              </GridItem>
              <SingleDetail size={'xl'} title={'Result'} color={'success'} $desktop={12} bold={true}>
                {!isNaN(incomesEstimated?.value) && !isNaN(costsEstimated?.value)
                  ? moneyAsString({
                      value: incomesEstimated.value - costsEstimated.value,
                      currency: currency,
                    })
                  : '-'}
              </SingleDetail>
              <GridItem $desktop={6}>
                <NumberInput name={'creationWork.estimated'} label={'Creation time (hours)'} placeholder={'Value'} suffix={false} />
              </GridItem>
              <GridItem $desktop={6}>
                <NumberInput name={'clientServiceWork.estimated'} label={"Partner's care time (hours)"} placeholder={'Value'} suffix={false} />
              </GridItem>
              <GridItem $desktop={6}>
                <NumberInput name={'developmentWork.estimated'} label={'Technology time (hours)'} placeholder={'Value'} suffix={false} />
              </GridItem>
              <GridItem $desktop={6}>
                <NumberInput name={'strategyWork.estimated'} label={'Strategy time (hours)'} placeholder={'Value'} suffix={false} />
              </GridItem>
              <GridItem $desktop={6}>
                <NumberInput name={'mediaWork.estimated'} label={'Media time (hours)'} placeholder={'Value'} suffix={false} />
              </GridItem>
              <GridItem $desktop={6}>
                <NumberInput name={'socialMediaWork.estimated'} label={'Social Media time (hours)'} placeholder={'Value'} suffix={false} />
              </GridItem>
              <GridItem $desktop={6}>
                <NumberInput name={'designStudioWork.estimated'} label={'ID Studio time (hours)'} placeholder={'Value'} suffix={false} />
              </GridItem>
              <GridItem $desktop={6}>
                <NumberInput name={'marketingWork.estimated'} label={'Marketing time (hours)'} placeholder={'Value'} suffix={false} />
              </GridItem>
              <GridItem $desktop={6}>
                <NumberInput name={'administrationWork.estimated'} label={'Administration time (hours)'} placeholder={'Value'} suffix={false} />
              </GridItem>
              <GridItem $desktop={6}>
                <NumberInput name={'peopleAndCultureWork.estimated'} label={'P&C time (hours)'} placeholder={'Value'} suffix={false} />
              </GridItem>
            </OverlayFormSection>
          </>
        );
      }}
    </OverlayForm>
  );
};

export default ProjectForm;
