import React, { FunctionComponent } from 'react';
import DashboardCard from '../DashboardCard';
import AbsenceIcon from '../../../../../../Icons/Absence.icon';
import { color } from '../../../../../../styles/Variables';
import Tabs, { TabObject } from '../../../../../molecules/Tabs/Tabs';
import AbsencesCardContentCard from '../../../../../molecules/Projects/Dashboard/DashboardCardContentCards/AbsencesCardContentCard';
import { DashboardDataAbsenceType } from '../../../../../../@types/Dashboard/DashboardDataAbsenceType';

type Props = {
  absencesToday: DashboardDataAbsenceType[] | undefined;
  absencesUpcoming: DashboardDataAbsenceType[] | undefined;
};

const Absences: FunctionComponent<Props> = ({ absencesToday, absencesUpcoming }) => {
  const absencesTabs: TabObject[] = [
    {
      tabName: 'Today',
      component: <AbsencesCardContentCard absences={absencesToday} key={1} showCounter />,
    },
    {
      tabName: 'Upcoming',
      component: <AbsencesCardContentCard absences={absencesUpcoming} key={2} showDateFilter showCounter />,
    },
  ];

  return (
    <DashboardCard title={'Absences'} icon={<AbsenceIcon size={2} color={color.primary['60']} />}>
      <Tabs tabs={absencesTabs} />
    </DashboardCard>
  );
};

export default Absences;
