import React, { FunctionComponent, useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import ChecklistItemTemplateForm from './ChecklistItemTemplateForm';
import { ChecklistItemTemplateType } from '../../../../@types/ChecklistTemplate/ChecklistTemplateType';
import Button from '../../../atoms/Button/Button';

type Props = {};

const ChecklistItemTemplateList: FunctionComponent<Props> = (props) => {
  const { control } = useFormContext();
  const {
    fields: items,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'items',
    keyName: '_id',
  });

  const handleCreate = useCallback(() => {
    append({
      content: null,
    });
  }, [append]);

  return (
    <Grid spacing={4}>
      {/* todo: upgraded react-hook-form is more typing strict */}\
      {items.map((item: any, idx) => (
        <ChecklistItemTemplateForm
          namePrefix={`items.${idx}`}
          onDelete={() => remove(idx)}
          key={item['_id']}
          defaultValue={item as ChecklistItemTemplateType}
        />
      ))}
      <GridItem $desktop={12}>
        <Grid $justifyContent={'end'}>
          <GridItem>
            <Button $text={'Add item'} onClick={handleCreate} kind={'secondary'} />
          </GridItem>
        </Grid>
      </GridItem>
    </Grid>
  );
};

export default ChecklistItemTemplateList;
