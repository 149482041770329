import React from 'react';
import * as Styled from './InventoryItemExtendedRow.styled';
import ExtendedRowComponent from '../../../atoms/Table/ExtendedRowComponent';
import InventoryItemType from '../../../../@types/Inventory/InventoryItemType';
import Details from './components/Details/Details';
import History from './components/History/History';

const InventoryItemExtendedRow: ExtendedRowComponent<InventoryItemType> = ({ entity }) => {
  return (
    <Styled.InventoryItemExtendedRow>
      <Details entity={entity} />
      <History allocations={entity.inventoryAllocations} entity={entity} />
    </Styled.InventoryItemExtendedRow>
  );
};

export default InventoryItemExtendedRow;
