import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import useOverlay from '../../../../../hooks/useOverlay';
import OverlayForm from '../../../../molecules/OverlayForm/OverlayForm';
import { TextInput } from '../../../../molecules/Form/TextInput/TextInput';
import useEntity from '../../../../../hooks/useEntity';
import { CategoryType } from '../../../../../@types/Project/Category/CategoryType';
import SelectInput from '../../../../molecules/Form/SelectInput/SelectInput';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import Heading from '../../../../atoms/Heading';
import HiddenInput from '../../../../atoms/Form/HiddenInput/HiddenInput';
import { ListItemType } from '../../../../molecules/Projects/ListItem/ListItem';
import { ClientType } from '../../../../../@types/Project/Client/ClientType';
import api from '../../../../../services/api';
import Text from '../../../../atoms/Text';
import useApi from '../../../../../hooks/useApi';
import Button from '../../../../atoms/Button/Button';

type Props = {
  clientId: number | null;
  category?: ListItemType;
};

const CategoryForm: FunctionComponent<Props> = ({ clientId, category }) => {
  const { hideOverlay } = useOverlay();
  const { create, save } = useEntity<CategoryType>(category ? category['@id'] : '/categories');
  const isEdit = !!category;
  const [partner, setPartner] = useState<null | ClientType>(null);
  const { post } = useApi();

  const handleSubmit = useCallback((data: CategoryType) => {
    if (isEdit) {
      save(data).then(() => {
        hideOverlay();
      });
    } else {
      create(data).then(() => {
        hideOverlay();
      });
    }
  }, []);

  const loadPartner = useCallback(() => {
    if (!clientId) {
      return null;
    }
    api.get<ClientType>(`/clients/${clientId}`).then((response) => {
      setPartner(response.data);
    });
  }, [clientId]);

  useEffect(() => {
    if (!partner) {
      loadPartner();
    }
  }, [partner]);

  const onArchive = useCallback(() => {
    if (isEdit && category) {
      post(`/categories/${category.id}/archive`).then((r) => {
        hideOverlay();
      });
    }
  }, []);

  const onRestore = useCallback(() => {
    if (isEdit && category) {
      post(`/categories/${category.id}/restore`).then((r) => {
        hideOverlay();
      });
    }
  }, []);

  return (
    <OverlayForm onSubmit={handleSubmit} defaultValues={category ?? {}}>
      <Grid spacing={2}>
        <GridItem $desktop={12}>
          <Heading>{isEdit ? 'Edit category' : 'Create category'}</Heading>
        </GridItem>
        <GridItem $desktop={12}>
          {clientId && <HiddenInput name={'client'} value={`/api/clients/${clientId}`} />}
          {partner && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Text bold={true}>Partner: </Text>&nbsp;
              <Text>{partner.name}</Text>
            </div>
          )}
          {!clientId && <SelectInput baseUrl={'/api/clients'} label={'Partner'} name={'client'} placeholder={'Pick a Partner'} required />}
        </GridItem>
        <GridItem $desktop={12}>
          <TextInput name={'name'} label={'Category name'} placeholder={'Type category name'} required />
        </GridItem>
        <GridItem $desktop={12}>
          <Grid $justifyContent={'end'}>
            <GridItem>
              {category && (
                <Button
                  $text={!category?.archived ? 'Archive Category' : 'Restore Category'}
                  onClick={() => {
                    !category.archived ? onArchive() : onRestore();
                  }}
                  kind={'link'}
                />
              )}
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
      <div style={{ marginBottom: '3rem' }} />
    </OverlayForm>
  );
};

export default CategoryForm;
