import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useLocationQuery from '../../../../../hooks/useLocationQuery';
import WikiLayout from '../../../../../components/templates/Projects/Wiki/WikiLayout';

type Props = {};

const WikiMainPage: FunctionComponent<Props> = () => {
  const location = useLocation();
  const slug = location.pathname.split('?')[0].replace('/projects/wiki', '');

  const tagsQuery = useLocationQuery('tags');
  const navigate = useNavigate();
  const slugs = useMemo(() => {
    if (!slug) {
      return [];
    }
    return slug.split('/').filter((s) => s !== '');
  }, [slug]);

  const tags = useMemo(() => {
    if (!tagsQuery) {
      return [];
    }
    return tagsQuery.split(',');
  }, [tagsQuery]);

  const handleTagsChange = useCallback((tags: string[]) => {
    navigate({
      pathname: '/projects/wiki',
      search: `?tags=${tags.join(',')}`,
    });
  }, []);

  return <WikiLayout slugs={slugs} tags={tags} onTagsChange={handleTagsChange} />;
};

export default WikiMainPage;
