import React, { FunctionComponent, useCallback, useContext, useMemo } from 'react';
import * as Styled from './WallEntryTopWrapper.styled';
import FeedEntryAuthor from '../../Projects/FeedEntryAuthor/FeedEntryAuthor';
import EditIcon from '../../../../Icons/edit.icon';
import TrashIcon from '../../../../Icons/Trash.icon';
import { FeedEntryType } from '../../../../@types/FeedEntry/FeedEntryType';
import { AuthContext } from '../../../../Context/auth-context/auth-context';
import useApi from '../../../../hooks/useApi';
import IriHelper from '../../../../helpers/iri-helper';
import Button from '../../../atoms/Button/Button';
import { Icon } from '../../../atoms/Icon/Icon';
import { color } from '../../../../styles/Variables';
import Chip from '../../../atoms/Chips/Chip';
import Text from '../../../atoms/Text/Text';
import UnpublishedIcon from '../../../../Icons/Unpublished.icon';
import PublishedIcon from '../../../../Icons/Published.icon';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';

type Props = {
  feedEntry: FeedEntryType;
  onEdit: () => void;
  onChange?: () => void;
};

const FeedEntryTopWrapper: FunctionComponent<Props> = ({ feedEntry, onEdit, onChange }) => {
  const {
    can,
    state: { user },
  } = useContext(AuthContext);
  const { del, post } = useApi();

  const canEdit: boolean = useMemo(() => {
    return can('WALL.CAN_MANAGE') || user?.employeeId === IriHelper.iriToId(feedEntry.createdBy['@id']);
  }, [user]);

  const handleDelete = useCallback(() => {
    del(`${feedEntry['@id']}`).then(() => {
      if (onChange) {
        onChange();
      }
    });
  }, []);

  const handlePublish = useCallback(async () => {
    return post(`${feedEntry['@id']}/publish`);
  }, [feedEntry]);

  const handleUnpublish = useCallback(async () => {
    return post(`${feedEntry['@id']}/unpublish`);
  }, [feedEntry]);

  return (
    <Styled.TopWrapper>
      <Grid>
        <GridItem $desktop={'max'}>
          <Grid spacing={1.6} $align={'center'}>
            <GridItem>
              <FeedEntryAuthor person={feedEntry.createdBy} date={feedEntry.publishedAt} />
            </GridItem>
            <GridItem>
              {canEdit && (feedEntry.status === 'draft' || feedEntry.status === 'inactive') && (
                <Chip $bgColor={color.neutral[30]}>
                  <Icon size={1.35} color={color.neutral[70]}>
                    <UnpublishedIcon />
                  </Icon>
                  <Text size={'s'} color={'semiGrey'} style={{ fontWeight: '500' }}>
                    Unpublished
                  </Text>
                </Chip>
              )}
              {canEdit && feedEntry.status === 'active' && (
                <Chip $bgColor={color.secondary[20]}>
                  <Icon size={1.35} color={color.secondary[70]}>
                    <PublishedIcon />
                  </Icon>
                  <Text size={'s'} color={'success'} style={{ fontWeight: '500' }}>
                    Published
                  </Text>
                </Chip>
              )}
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem>
          {canEdit && (
            <Styled.ActionsWrapper>
              {feedEntry.status === 'draft' && <Button $text={'Publish'} onClick={handlePublish} small />}
              {feedEntry.status !== 'draft' && <Button $text={'Unpublish'} onClick={handleUnpublish} small />}

              <Icon
                color={color.neutral['60']}
                size={1.4}
                onClick={() => {
                  onEdit();
                }}
              >
                <EditIcon />
              </Icon>

              <Icon
                color={color.neutral['60']}
                size={1.4}
                onClick={() => {
                  handleDelete();
                }}
              >
                <TrashIcon />
              </Icon>
            </Styled.ActionsWrapper>
          )}
        </GridItem>
      </Grid>
    </Styled.TopWrapper>
  );
};

export default FeedEntryTopWrapper;
