import { TableFiltersType } from '../../../../@types/Table/TableFilterType';
import FrequentFilters from '../../../../@types/Table/FrequentFilters';
import { DictValue } from '../../../../@types/Dictionary/DictValue';

const statusOptions: DictValue[] = [
  { value: 'active', label: 'Yes' },
  { value: 'inactive', label: 'No' },
];

const filters: TableFiltersType = [
  {
    ...FrequentFilters._search,
    placeholder: 'Search by name, email, login...',
  },
  FrequentFilters.department,
  {
    type: 'employee',
    name: 'teamLeader.id',
    label: 'Team leader',
    options: {
      group: 'organisation',
      context: 'team-leaders',
    },
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'select',
    name: 'status',
    label: 'Status',
    options: {
      options: statusOptions,
    },
    gridProps: {
      $desktop: 2,
    },
  },
];
export default filters;
