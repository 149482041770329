import React, { FunctionComponent } from 'react';

type Props = {
  size?: number;
};

const PublishedIcon: FunctionComponent<Props> = ({ size = 1 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={`${size}em`} height={`${size}em`} viewBox="0 0 16 16" fill="none">
      <g>
        <path
          d="M4.00004 9.33333H9.33337V8H4.00004V9.33333ZM4.00004 7.33333H12V6H4.00004V7.33333ZM4.00004 5.33333H12V5.26667C11.5889 5.17778 11.2139 5.025 10.875 4.80833C10.5362 4.59167 10.2445 4.32222 10 4H4.00004V5.33333ZM1.33337 14.6667V2.66667C1.33337 2.3 1.46393 1.98611 1.72504 1.725C1.98615 1.46389 2.30004 1.33333 2.66671 1.33333H9.40004C9.3556 1.55556 9.33337 1.77778 9.33337 2C9.33337 2.22222 9.3556 2.44444 9.40004 2.66667H2.66671V11.4167L3.43337 10.6667H13.3334V5.26667C13.5889 5.21111 13.8278 5.13611 14.05 5.04167C14.2723 4.94722 14.4778 4.82222 14.6667 4.66667V10.6667C14.6667 11.0333 14.5362 11.3472 14.275 11.6083C14.0139 11.8694 13.7 12 13.3334 12H4.00004L1.33337 14.6667ZM12.6667 4C12.1112 4 11.6389 3.80556 11.25 3.41667C10.8612 3.02778 10.6667 2.55556 10.6667 2C10.6667 1.44444 10.8612 0.972222 11.25 0.583333C11.6389 0.194444 12.1112 0 12.6667 0C13.2223 0 13.6945 0.194444 14.0834 0.583333C14.4723 0.972222 14.6667 1.44444 14.6667 2C14.6667 2.55556 14.4723 3.02778 14.0834 3.41667C13.6945 3.80556 13.2223 4 12.6667 4Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default PublishedIcon;
