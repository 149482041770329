import React from 'react';
import { TableProperties } from '../../../../../@types/Table/TableProperty';
import { EntityType } from '../../../../../@types/Entity/EntityType';
import StringTemplate from '../../../../molecules/CellTemplates/common/StringTemplate/StringTemplate';
import DateTemplate from '../../../../molecules/CellTemplates/common/DateTemplate/DateTemplate';
import PersonTemplate from '../../../../molecules/CellTemplates/common/PersonTemplate/PersonTemplate';
import { convertMinutesToHours } from '../../../../../helpers/convertMinutesToHours';

export const properties: TableProperties<EntityType> = [
  {
    name: 'id',
    visibleName: 'ID',
    template: StringTemplate,
  },
  {
    name: 'task.title',
    visibleName: 'Task name',
    template: StringTemplate,
  },
  {
    name: 'description',
    visibleName: 'Entry description',
    template: StringTemplate,
    templateOptions: {
      wrap: true,
    },
  },
  {
    name: 'employee',
    visibleName: 'Employee',
    template: PersonTemplate,
  },
  {
    name: 'duration',
    visibleName: 'Time',
    sumBy: 'duration',
    sumAsTime: true,
    template: StringTemplate,
    sortable: false,
    transformValue: (value) => convertMinutesToHours(value),
  },
  {
    name: 'timesheetDay.day',
    visibleName: 'Date',
    template: DateTemplate,
  },
  {
    name: 'task.section.name',
    visibleName: 'Section',
    template: StringTemplate,
  },
];
