import React, { FunctionComponent, useCallback, useState } from 'react';
import Form from '../Form/Form';
import api from '../../../services/api';
import useModal from '../../../hooks/useModal';
import { ModalFooter } from './Modal';
import Button from '../../atoms/Button/Button';
import { ChecklistType } from '../../../@types/Checklist/ChecklistType';

type Props = {
  checklists: ChecklistType;
  onDelete: () => void;
};

const DeleteChecklistFromProjectModal: FunctionComponent<Props> = ({ checklists, onDelete }) => {
  const { hideModal } = useModal();
  const [waiting, setWaiting] = useState(false);

  const handleSubmit = useCallback(() => {
    setWaiting(true);
    api.delete(`/wiki/checklists/${checklists.id}`).then(() => {
      hideModal();
      setWaiting(false);
      onDelete();
    });
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <ModalFooter>
        <Button $text={'Delete checklist'} type="submit" waiting={waiting} kind={'negative'} />
        <Button $text={'Cancel'} onClick={hideModal} kind={'ghost'} />
      </ModalFooter>
    </Form>
  );
};

export default DeleteChecklistFromProjectModal;
