import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

function useLocationQueryObject(defaults: any = {}) {
  const location = useLocation();
  const [result, setResult] = useState(undefined);

  useEffect(() => {
    const queryObj: any = {};
    if (!location.search) {
      setResult(queryObj);
      return;
    }
    const params = new URLSearchParams(location.search);
    params.forEach((value, key) => {
      // Extended regular expression to match keys for objects in arrays (e.g., "positions[0][name]")
      const deepMatch = key.match(/^(\w+)\[(\d+)\]\[(\w+)\](\[(\w+)\])?$/);
      if (deepMatch) {
        const [, arrayKey, index, propKey, , subPropKey] = deepMatch;
        queryObj[arrayKey] = queryObj[arrayKey] || [];
        queryObj[arrayKey][index] = queryObj[arrayKey][index] || {};

        if (subPropKey) {
          queryObj[arrayKey][index][propKey] = queryObj[arrayKey][index][propKey] || {};
          queryObj[arrayKey][index][propKey][subPropKey] = value;
        } else {
          queryObj[arrayKey][index][propKey] = value;
        }
      } else {
        const simpleMatch = key.match(/^(\w+)\[(\d*)\]$/);
        if (simpleMatch) {
          const [, arrayKey, index] = simpleMatch;
          queryObj[arrayKey] = queryObj[arrayKey] || [];
          queryObj[arrayKey][index] = value;
        } else {
          // For simple key-value pairs
          if (key in queryObj) {
            // Convert existing entry into an array if it's not already one
            if (!Array.isArray(queryObj[key])) {
              queryObj[key] = [queryObj[key]];
            }
            queryObj[key].push(value);
          } else {
            queryObj[key] = value;
          }
        }
      }
    });

    setResult({ ...defaults, ...queryObj });
  }, [location]);

  return result;
}

export default useLocationQueryObject;
