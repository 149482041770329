import { TableFilterType } from './TableFilterType';
import ContractorOption from '../../components/molecules/Finances/ContractorOption';
import ProjectOption from '../../components/molecules/Projects/ProjectOption/ProjectOption';
import ProjectLabel from '../../components/molecules/Projects/ProjectLabel/ProjectLabel';
import PersonTemplate from '../../components/molecules/CellTemplates/common/PersonTemplate/PersonTemplate';

const FrequentFilters: { [key: string]: TableFilterType } = {
  _search: {
    type: 'text',
    name: '_search',
    label: 'Search',
    placeholder: 'Search by many fields',
    gridProps: {
      $desktop: 3,
    },
  },
  department: {
    type: 'department',
    name: 'department.id',
    label: 'Department',
    gridProps: {
      $desktop: 2,
    },
  },
  archived: {
    type: 'switch',
    name: '_archived',
    label: 'Archived',
  },
  contractor: {
    type: 'select',
    name: 'contractor.id',
    label: 'Vendor',
    options: {
      baseUrl: '/api/contractors',
      optionComponent: ContractorOption,
    },
    gridProps: {
      $desktop: 2,
    },
  },
  partner: {
    type: 'select',
    name: 'client.id',
    label: 'Partner',
    options: {
      baseUrl: '/api/clients',
    },
    gridProps: {
      $desktop: 2,
    },
  },
  project: {
    type: 'select',
    name: 'project.id',
    label: 'Project',
    placeholder: 'Select project',
    options: {
      baseUrl: '/api/projects',
      optionComponent: ProjectOption,
      singleValueComponent: ProjectLabel,
    },
    gridProps: {
      $desktop: 2,
    },
  },
};
export default FrequentFilters;
