import React, { FunctionComponent, useMemo } from 'react';
import { SignType } from '../../../@types/Request/RequestType';
import { Grid, GridItem } from '../../atoms/Grid/Grid';
import Person from '../../molecules/Person/Person';
import FinanceStatus from '../../molecules/Statuses/FinanceStatus/FinanceStatus';
import { parseDate } from '../../../helpers/date';
import Text from '../../atoms/Text';

type Props = {
  sign: SignType;
};

const RequestSign: FunctionComponent<Props> = ({ sign }) => {
  const isSignerATrueSigner = useMemo(() => {
    if (!sign.trueSigner) return null;
    return sign.trueSigner?.id === sign.signer.id;
  }, [sign]);

  return (
    <Grid spacing={0.4}>
      <GridItem $desktop={12}>
        <Grid $align={'center'} $justifyContent={'space-between'}>
          <GridItem>
            <Grid $align={'center'} spacing={1}>
              <GridItem>
                <Person size={3.5} person={sign.signer} hideName />
              </GridItem>
              <GridItem>
                <Text
                  bold
                  style={
                    isSignerATrueSigner === false
                      ? {
                          textDecoration: 'line-through',
                          fontSize: '1.1rem',
                        }
                      : {}
                  }
                >
                  {sign.signer.name}
                </Text>
              </GridItem>
              {isSignerATrueSigner === false && (
                <GridItem>
                  <Text bold>{sign.trueSigner?.name}</Text>
                </GridItem>
              )}
              <GridItem>
                <FinanceStatus status={sign.status} size={'xs'} />
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem>
            {sign.signedAt && sign.status !== 'cancelled' ? (
              <Text color={'grey'} size={'s'}>
                {sign.status === 'rejected' ? 'rejected' : 'signed'} on {sign.signedAt ? parseDate(sign.signedAt, true) : null}{' '}
              </Text>
            ) : null}
            {sign.signedAt && sign.status === 'cancelled' ? (
              <Text color={'grey'} size={'s'}>
                canceled on {sign.signedAt ? parseDate(sign.signedAt, true) : null}
              </Text>
            ) : null}
          </GridItem>
        </Grid>
      </GridItem>

      <GridItem $desktop={12}>
        {sign.comment && (
          <GridItem $desktop={12} style={{ marginLeft: '4.4rem' }}>
            <Text size={'s'} color={'semiGrey'}>
              {sign.comment}
            </Text>
          </GridItem>
        )}
      </GridItem>
    </Grid>
  );
};

export default RequestSign;
