import React, { FunctionComponent } from 'react';
import properties from './MyInventoryList.properties';
import Table from '../../organisms/Table/Table';
import Heading from '../../atoms/Heading';
import MyInventoryProtocols from './Components/MyInventoryProtocols';
import InventoryItemExtendedRow from '../../molecules/ExtendedRow/InventoryItemExtendedRow/InventoryItemExtendedRow';

type Props = {};

const MyInventoryList: FunctionComponent<Props> = (props) => {
  return (
    <>
      <Heading>My inventory</Heading>
      <MyInventoryProtocols />
      <Table url={'/api/my_inventory_items'} context={'/api/inventory_items'} properties={properties} extendedRow={InventoryItemExtendedRow} />
    </>
  );
};

export default MyInventoryList;
