import React, { FunctionComponent } from 'react';
import * as Styled from './TaskSidebar.styled';
import EntityProvider from '../../../Context/EntityContext/EntityProvider';
import { Loader } from '../../../legacy/CapeMorris/components';
import { TaskType } from '../../../@types/Task/TaskType';
import TaskTopBar from './TaskSidebarTopBar/TaskTopBar';
import AddComment from '../../molecules/Comment/AddComment';
import TaskSidebarBody from './TaskSidebarBody/TaskSidebarBody';

type Props = {
  iri: string;
  ref?: React.RefObject<HTMLDivElement>;
  onChange?: () => void;
};

const TaskSidebar: FunctionComponent<Props> = ({ iri, ref, onChange }) => {
  return (
    <EntityProvider<TaskType> url={iri}>
      {({ loading, entity }) => (
        <>
          {loading && <Loader />}
          {!loading && entity && (
            <Styled.ExternalContainer ref={ref}>
              <Styled.TaskSidebarContainer>
                <TaskTopBar task={entity} onChange={onChange} />
                <TaskSidebarBody task={entity} />
              </Styled.TaskSidebarContainer>
              {!entity.deletedAt && (
                <Styled.CommentInputContainer>
                  <AddComment subject={'Task'} subjectId={entity.id} />
                </Styled.CommentInputContainer>
              )}
            </Styled.ExternalContainer>
          )}
        </>
      )}
    </EntityProvider>
  );
};

export default TaskSidebar;
