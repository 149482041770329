import styled, { css } from 'styled-components';
import { CollapseDisplayType } from './Collapse';
import { color } from '../../../styles/Variables';

type ExpandedProps = {
  $isExpanded: boolean;
  $displayType?: CollapseDisplayType;
};

type ElementDisplayType = {
  $displayType?: CollapseDisplayType;
};

export const CollapseContainer = styled.div<ElementDisplayType>`
  transition: background-color 0.2s ease-in-out;
  margin-bottom: 3.6rem;

  ${(props) =>
    props.$displayType === 'card' &&
    css`
      background: ${color.light};
      border-radius: 6px;
      padding: 0 3.2rem;
      margin-bottom: 0.8rem;
    `};
`;

export const CollapseHeader = styled.div<ElementDisplayType>`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  cursor: pointer;
  ${(props) =>
    props.$displayType === 'section' &&
    css`
      border-bottom: 1px solid ${color.neutral[40]};
    `};
`;

export const CollapseHidden = styled.div<ExpandedProps>`
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  transition:
    max-height 0.2s ease-in-out,
    visibility 0.2s ease-in-out,
    opacity 0.2s ease-in-out;
  ${(props) =>
    props.$displayType === 'section' &&
    css`
      max-height: 5px;
      opacity: 1;
      visibility: visible;
      overflow: hidden;
    `};
  ${(props) =>
    props.$isExpanded &&
    css`
      max-height: 100%;
      opacity: 1;
      visibility: visible;
      overflow: visible;
    `};
`;
