import React, { FunctionComponent } from 'react';
import { Card } from '../../atoms/Card/Card';
import CardHeading from '../../molecules/CardHeading/CardHeading';
import { Grid } from '../../atoms/Grid/Grid';
import { PositionType } from '../../../@types/Finances/PositionType';
import Position from './Position';

type Props = {
  positions: PositionType[];
};

const PositionsCard: FunctionComponent<Props> = ({ positions }) => {
  return (
    <>
      <Card>
        <CardHeading heading={'Positions'} />
        <Grid spacing={2}>
          {positions.map((position, idx) => (
            <Position position={position} key={idx} idx={idx + 1} />
          ))}
        </Grid>
      </Card>
    </>
  );
};

export default PositionsCard;
