import React from 'react';
import { CellTemplate } from '../../CellTemplate';
import { ProjectListItemType } from '../../../../../@types/Project/Project';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import monthlyDate from '../../../../../helpers/monthlyDate';
import Text from '../../../../atoms/Text';
import Popover from '../../../../atoms/Popover/Popover';

const ProjectDatesTemplate: CellTemplate<string | Date, {}, ProjectListItemType> = ({ value, item }) => {
  return (
    <Grid>
      <GridItem>
        <Grid style={{ marginBottom: '0.6rem' }}>
          <GridItem $desktop={'max'}>
            {item.createdAt && (
              <Text color={'grey'} size={'s'} bold>
                Start: {monthlyDate(item.createdAt)}
              </Text>
            )}
          </GridItem>
        </Grid>
        <Grid style={{ marginBottom: '0.6rem' }}>
          <GridItem $desktop={'max'}>
            {item.status === 'settled' ? (
              <Text color={'darkGrey'} size={'s'} bold>
                End : {monthlyDate(item.settlementDate)}
              </Text>
            ) : (
              <Text color={'darkGrey'} size={'s'} bold>
                End: {item.plannedCompletionDate ? monthlyDate(item.plannedCompletionDate) : 'n/a'}
              </Text>
            )}
          </GridItem>
        </Grid>
      </GridItem>
    </Grid>
  );
};
export default ProjectDatesTemplate;
