import React, { FunctionComponent } from 'react';
import MyWorkCalendar from '../../../../components/templates/MyWork/MyWorkCalendar/MyWorkCalendar';
import { RouterPageType } from '../../../../@types/RouterPage/RouterPageType';
import MyTasks from '../../../../components/templates/MyWork/MyTasks/MyTasks';
import AssignedTasks from '../../../../components/templates/MyWork/AssignedTasks/AssignedTasks';
import MyTeamTasks from '../../../../components/templates/MyWork/MyTeamTasks/MyTeamTasks';
import TabsNavigatorRouter from '../../../../components/molecules/TabsNavigatorRouter/TabsNavigatorRouter';
import ReviewerTasks from '../../../../components/templates/MyWork/ReviewerTasks/ReviewerTasks';
import useUser from '../../../../hooks/useUser';
import { Helmet } from 'react-helmet';

interface OwnProps {}

type Props = OwnProps;

const ProjectsMyWork: FunctionComponent<Props> = (props) => {
  const user = useUser();
  const pages: RouterPageType[] = [
    { to: '/my-work', name: 'My work', screen: <MyWorkCalendar employeeId={user.employeeId} /> },
    { to: '/my-tasks', name: 'My tasks', screen: <MyTasks /> },
    { to: '/reviewer-tasks', name: 'Reviewer tasks', screen: <ReviewerTasks /> },
    { to: '/assigned-tasks', name: 'Assigned tasks', screen: <AssignedTasks /> },
    { to: '/my-team-tasks', name: 'My team tasks', screen: <MyTeamTasks /> },
  ];
  return (
    <>
      <Helmet>
        <title>My work | F.CAPE</title>
      </Helmet>
      <TabsNavigatorRouter pages={pages} />
    </>
  );
};

export default ProjectsMyWork;
