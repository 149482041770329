import React, { FunctionComponent } from 'react';
import Table from '../../Table/Table';
import subcostsListTableProperties from './SubcostsListTable.properties';

type Props = {
  globalFilters?: any;
};

const SubcostsListTable: FunctionComponent<Props> = ({ globalFilters }) => {
  return (
    <Table
      url={'/subcosts'}
      context={'/api/subcosts'}
      properties={subcostsListTableProperties}
      globalFilters={globalFilters}
      itemLink={(item) => `/finances/costs/costs/${item.cost.id}/show`}
    />
  );
};

export default SubcostsListTable;
