import React, { FunctionComponent, useCallback } from 'react';
import * as Styled from './ListItem.styled';
import Text from '../../../atoms/Text';
import IdText from '../../IdText/IdText';
import Chip from '../../../atoms/Chips/Chip';
import EditIcon from '../../../../Icons/edit.icon';
import PartnerForm from '../../../organisms/Projects/Forms/PartnerForm/PartnerForm';
import useOverlay from '../../../../hooks/useOverlay';
import CategoryForm from '../../../organisms/Projects/Forms/CategoryForm/CategoryForm';
import ExternalLinkIcon from '../../../../Icons/ExternalLink.icon';
import { Icon } from '../../../atoms/Icon/Icon';
import useUser from '../../../../hooks/useUser';

export type ListItemType = {
  '@id'?: string;
  id: number;
  name: string;
  archived?: boolean;
  color?: string;
};

export type ItemType = 'project' | 'category' | 'client';

type PropType = {
  onSelect: (item: ListItemType) => void;
  item: ListItemType;
  itemType: ItemType;
  isSelected: boolean;
  clientId?: number | null;
};

const ListItem: FunctionComponent<PropType> = ({ onSelect, item, isSelected, itemType, clientId }) => {
  const { showOverlay } = useOverlay();
  const user = useUser();

  const sendDataLayerInfo = useCallback(() => {
    if (!window || !document) return;
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      event: 'go_to_project',
      element: 'all_projects_view',
      pageTitle: document.title,
      userDepartment: user?.department?.name,
    });
  }, [window, document]);

  const handleOnClick = (item: ListItemType) => {
    onSelect(item);
    if (itemType === 'project') {
      sendDataLayerInfo();
    }
  };

  return (
    <Styled.Item onClick={() => handleOnClick(item)} $isSelected={isSelected}>
      <Styled.DetailsWrapper onClick={() => onSelect(item)}>
        {itemType === 'project' && <IdText withHash={true} value={item.id} />}
        <Text>{item.name}</Text>
        {item.archived && (
          <Chip>
            <Text size={'s'} color={'grey'}>
              Archived
            </Text>
          </Chip>
        )}
      </Styled.DetailsWrapper>
      {itemType === 'client' && (
        <Styled.EditIconContainer
          onClick={() => {
            showOverlay({ body: <PartnerForm client={item} /> });
          }}
        >
          <EditIcon />
        </Styled.EditIconContainer>
      )}
      {itemType === 'project' && (
        <Styled.EditIconContainer>
          <Icon>
            <ExternalLinkIcon />
          </Icon>
        </Styled.EditIconContainer>
      )}
      {itemType === 'category' && (
        <Styled.EditIconContainer
          onClick={() => {
            showOverlay({
              body: <CategoryForm category={item} clientId={clientId ?? null} />,
            });
          }}
        >
          <EditIcon />
        </Styled.EditIconContainer>
      )}
    </Styled.Item>
  );
};

export default ListItem;
