import React, { FunctionComponent } from 'react';

type PropType = {
  size?: number;
  color?: string;
};

const LocationIcon: FunctionComponent<PropType> = ({ size = 1, color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <g>
        <path
          d="M6 6C6.275 6 6.51042 5.90208 6.70625 5.70625C6.90208 5.51042 7 5.275 7 5C7 4.725 6.90208 4.48958 6.70625 4.29375C6.51042 4.09792 6.275 4 6 4C5.725 4 5.48958 4.09792 5.29375 4.29375C5.09792 4.48958 5 4.725 5 5C5 5.275 5.09792 5.51042 5.29375 5.70625C5.48958 5.90208 5.725 6 6 6ZM6 9.675C7.01667 8.74167 7.77083 7.89375 8.2625 7.13125C8.75417 6.36875 9 5.69167 9 5.1C9 4.19167 8.71042 3.44792 8.13125 2.86875C7.55208 2.28958 6.84167 2 6 2C5.15833 2 4.44792 2.28958 3.86875 2.86875C3.28958 3.44792 3 4.19167 3 5.1C3 5.69167 3.24583 6.36875 3.7375 7.13125C4.22917 7.89375 4.98333 8.74167 6 9.675ZM6 11C4.65833 9.85833 3.65625 8.79792 2.99375 7.81875C2.33125 6.83958 2 5.93333 2 5.1C2 3.85 2.40208 2.85417 3.20625 2.1125C4.01042 1.37083 4.94167 1 6 1C7.05833 1 7.98958 1.37083 8.79375 2.1125C9.59792 2.85417 10 3.85 10 5.1C10 5.93333 9.66875 6.83958 9.00625 7.81875C8.34375 8.79792 7.34167 9.85833 6 11Z"
          fill={color ? color : 'currentColor'}
        />
      </g>
    </svg>
  );
};

export default LocationIcon;
