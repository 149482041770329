import styled from 'styled-components';

export const SectionNameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  width: 100%;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
`;
