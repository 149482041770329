import styled, { css } from 'styled-components';
import { color } from '../../../../../../styles/Variables';

export const TableOfContentsContainer = styled.div`
  padding-top: 1.6rem;
  gap: 1.6rem;
  display: flex;
  flex-direction: column;
`;

export const TableOfContentsItem = styled.div<{ $level: string }>`
  padding-left: 1.6rem;
  width: fit-content;

  button {
    padding: 0;
    min-height: unset;

    &::after {
      content: ' ';
      position: absolute;
      left: 0;
      bottom: -3px;
      height: 1px;
      width: 100%;
      background-color: ${color.neutral['60']};
      transition: background-color 0.2s ease-in-out;
    }

    &:hover {
      color: ${color.primary['60']};

      &::after {
        background-color: ${color.primary['60']};
      }
    }
  }

  ${({ $level }) =>
    $level === 'h2' &&
    css`
      margin-left: 0;
    `}

  ${({ $level }) =>
    $level === 'h3' &&
    css`
      margin-left: 1.2rem;
    `}

  ${({ $level }) =>
    $level === 'h4' &&
    css`
      margin-left: 2.4rem;
    `}
`;
