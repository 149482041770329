import React, { FunctionComponent } from 'react';
import Text from '../../../atoms/Text';

type Props = {};

const EmptyCell: FunctionComponent<Props> = (props) => {
  return (
    <Text color={'lightGrey'} size={'m'} bold={true}>
      N/A
    </Text>
  );
};

export default EmptyCell;
