import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ProjectType } from '../../../../@types/Project/Project';
import EntityProvider from '../../../../Context/EntityContext/EntityProvider';
import ProjectHeader from '../../../../components/molecules/ProjectHeader/ProjectHeader';
import TabsNavigatorRouter from '../../../../components/molecules/TabsNavigatorRouter/TabsNavigatorRouter';
import OverviewView from '../../../../components/templates/ProjectDetails/views/OverviewView/OverviewView';
import TimeView from '../../../../components/templates/ProjectDetails/views/TimeView/TimeView';
import ChecklistView from '../../../../components/templates/ProjectDetails/views/ChecklistView/ChecklistView';
import TasksView from '../../../../components/templates/ProjectDetails/views/ProjectTasksView/TasksView';
import { Loader } from '../../../../legacy/CapeMorris/components';
import FinancesView from '../../../../components/templates/ProjectDetails/views/FinancesView/FinancesView';
import { RouterPageType } from '../../../../@types/RouterPage/RouterPageType';
import ContractsView from '../../../../components/templates/ProjectDetails/views/ContractsView/ContractsView';
import { Helmet } from 'react-helmet';
import api from '../../../../services/api';

interface OwnProps {}

type Props = OwnProps;

const ProjectDetailsView: FunctionComponent<Props> = (props) => {
  let { id } = useParams<{ id: string | undefined }>();

  useEffect(() => {
    void api.post(`/projects/${id}/activity`, {});
  }, [id]);

  return (
    <EntityProvider<ProjectType> url={`/projects/${id}`}>
      {({ entity: project, loading }) => {
        const pages: RouterPageType[] = useMemo(() => {
          let routes: RouterPageType[] = [];

          if (project) {
            routes = [
              { to: '/overview', name: 'Overview', screen: <OverviewView /> },
              { to: '/tasks', name: 'Tasks', screen: <TasksView /> },
              { to: '/time', name: 'Time', screen: <TimeView /> },
              { to: '/checklist', name: 'Checklist', screen: <ChecklistView /> },
              {
                to: `/projects/wiki?tags=${project.tags.map((t) => t.value)}`,
                name: 'Wiki',
                isExternal: true,
              },
            ];
            if (project.financesShown) {
              routes.push({
                to: '/finances',
                name: 'Finances',
                screen: <FinancesView />,
              });
              routes.push({
                to: '/contracts',
                name: 'Contracts',
                screen: <ContractsView />,
              });
            }
          }
          return routes;
        }, [loading, project]);

        return (
          <>
            {loading && <Loader />}
            {!loading && pages && project && (
              <>
                <Helmet>
                  <title>{`#${project.id} ${project.name} | F.CAPE`}</title>
                </Helmet>
                <ProjectHeader />
                <TabsNavigatorRouter pages={pages} />
              </>
            )}
          </>
        );
      }}
    </EntityProvider>
  );
};

export default ProjectDetailsView;
