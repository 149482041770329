import React, { FunctionComponent, PropsWithChildren, useCallback } from 'react';
import { Command } from 'cmdk';
import { useNavigate } from 'react-router-dom';
import { Item } from './Commander';
import PageCommanderItem from './items/PageCommanderItem';
import ProjectCommanderItem from './items/ProjectCommanderItem';
import TaskCommanderItem from './items/TaskCommanderItem';

type Props = PropsWithChildren & {
  item: Item;
  type: string;
  onSelect: (value: string) => void;
};

const CommanderItem: FunctionComponent<Props> = ({ onSelect, item, type }) => {
  const navigate = useNavigate();
  const handleSelect = useCallback(() => {
    if (item.path) {
      navigate(item.path);
    }
    onSelect?.(item.value);
  }, [item]);

  return (
    <>
      {type === 'Page' && <PageCommanderItem item={item} onSelect={handleSelect} />}
      {type === 'Project' && <ProjectCommanderItem item={item} onSelect={handleSelect} />}
      {type === 'Task' && <TaskCommanderItem item={item} onSelect={handleSelect} />}
    </>
  );
};

export default CommanderItem;
