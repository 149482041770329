import styled from 'styled-components';
import { color } from '../../../../../../styles/Variables';

export const MainWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 50vw;
  border-bottom: 1px solid ${color.neutral['40']};
  align-items: center;
  padding: 1.6rem 0;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: ${color.neutral['30']};
  }
`;

export const Wrapper = styled.div`
  display: grid;
  align-items: center;
  gap: 3.6rem;
  grid-template-columns: repeat(6, 1fr);
  justify-items: start;
`;

export const MeetingWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
`;

export const MeetingInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;
`;
