import React, { FunctionComponent } from 'react';

type Props = {
  color?: string;
};

const PriorityIcon: FunctionComponent<Props> = ({ color }) => {
  return (
    <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.582031 14.2916V0.75H7.32878L7.66211 2.41667H12.2487V9.91663H7.16857L6.83524 8.24996H1.83201V14.2916H0.582031ZM8.20701 8.66665H10.9987V3.66665H6.62368L6.29034 1.99998H1.83201V6.99998H7.87368L8.20701 8.66665Z"
        fill={color ?? 'currentColor'}
      />
    </svg>
  );
};

export default PriorityIcon;
