import React, { createElement, FunctionComponent, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import * as Styled from './InlineInput.styled';
import { Icon } from '../../../../atoms/Icon/Icon';
import PlusIcon from '../../../../../Icons/Plus.icon';
import useOutsideClick from '../../../../../hooks/useOutsideClick';
import useKeyboard from '../../../../../hooks/useKeyboard';
import { InlineInputDropdownType } from './InlineInputDropdownType';
import { DictValue } from '../../../../../@types/Dictionary/DictValue';
import HiddenInput from '../../../../atoms/Form/HiddenInput/HiddenInput';
import { useFormContext } from 'react-hook-form';
import Avatar from '../../../../atoms/Avatar/Avatar';

type Props = {
  /**
   * Name propagated to the input
   */
  name: string;
  /**
   * Icon can be ReactElement. <PlusIcon/> is default
   */
  icon?: ReactElement;
  shape?: 'circle' | 'square';
  label?: string | false;
  /**
   * Form to be displayed when input is active
   */
  dropdown: InlineInputDropdownType;
  parseValue?: (value: DictValue) => string | ReactElement;
  customHandler?: (value: DictValue) => ReactElement;
  handlerSize?: 'lg' | 'sm';
  dropdownOnTop?: boolean;
  translateXValue?: string;
  preventDefault?: boolean;
  onFocusLost?: () => void;
};

type StateType = {
  enabled: boolean;
  focused: boolean;
  value: string | number | undefined;
  dictValue?: DictValue | undefined;
  displayValue: ReactElement | string | undefined;
};

const InlineInput: FunctionComponent<Props> = ({
  dropdown,
  name,
  label,
  icon = <PlusIcon />,
  shape = 'circle',
  parseValue,
  customHandler,
  handlerSize = 'lg',
  dropdownOnTop = false,
  translateXValue,
  onFocusLost,
}) => {
  const { watch } = useFormContext();
  const value = watch(name);
  const [state, setState] = useState<StateType>({
    enabled: false,
    focused: false,
    value: undefined,
    displayValue: undefined,
  });

  const containerRef = useRef<HTMLDivElement | null>(null);
  useOutsideClick(containerRef, () =>
    setState((s) => ({
      ...s,
      enabled: false,
    })),
  );

  const onSpacePressed = useCallback(() => {
    if (!state.enabled && state.focused) {
      setState((s) => ({ ...s, enabled: true }));
    }
  }, [state]);

  const onEscPressed = useCallback(() => {
    if (state.enabled) {
      setState((s) => ({ ...s, enabled: false }));
    }
  }, [state]);

  const handleChange = useCallback((value: DictValue | undefined) => {
    if (!value) {
      return;
    }
    setState((state) => ({
      ...state,
      value: value.value,
      dictValue: value,
      displayValue: parseValue ? parseValue(value) : value.label,
      enabled: false,
      focused: false,
    }));
  }, []);

  const onEnterPressed = useCallback(
    (e: Event) => {
      if (state.focused) {
        e.preventDefault();
        if (typeof value === 'object') {
          handleChange(value);
        }
      }
    },
    [handleChange, name, state],
  );

  useKeyboard(' ', onSpacePressed);
  useKeyboard('Escape', onEscPressed);
  useKeyboard('Enter', onEnterPressed, 'keydown', false);

  useEffect(() => {
    if (typeof value === 'object') {
      handleChange(value);
    }
  }, [handleChange, name]);

  useEffect(() => {
    if (!state.enabled && onFocusLost) {
      onFocusLost();
    }
  }, [state.enabled]);

  return (
    <Styled.Container
      ref={containerRef}
      tabIndex={0}
      onClick={() => setState((s) => ({ ...s, enabled: !s.enabled }))}
      onFocus={() => setState((s) => ({ ...s, focused: true }))}
      onBlur={() => setState((s) => ({ ...s, focused: false }))}
    >
      {state.dictValue ? (
        <>
          {customHandler ? (
            customHandler(state.dictValue)
          ) : (name === 'assignee' || name === 'reviewer') && state.dictValue.meta ? (
            <Avatar name={state.dictValue.meta.name} photoUrl={state.dictValue.meta.profilePhotoUrl} size={3.6} />
          ) : name === 'project' && state.dictValue.meta ? (
            <></>
          ) : (
            <Styled.Handler $shape={shape} $filled={true} $size={handlerSize}>
              <Icon size={1.2}>{icon}</Icon>
            </Styled.Handler>
          )}
        </>
      ) : (
        <Styled.Handler $shape={shape} $filled={false} $size={handlerSize}>
          <Icon size={1.2}>{icon}</Icon>
        </Styled.Handler>
      )}
      <HiddenInput name={name} value={state.value} />
      {label && !state.displayValue && <Styled.Label>{label}</Styled.Label>}
      {label !== false && state.displayValue && <Styled.Value noMargin={name === 'project'}>{state.displayValue}</Styled.Value>}
      <Styled.Dropdown visible={state.enabled} onClick={(e) => e.stopPropagation()} dropdownOnTop={dropdownOnTop} translateXValue={translateXValue}>
        {createElement(dropdown, {
          name: `__.${name}`,
          onChange: handleChange,
          visible: state.enabled,
        })}
      </Styled.Dropdown>
    </Styled.Container>
  );
};

export default InlineInput;
