import React, { FunctionComponent } from 'react';
import { color } from '../styles/Variables';

type Props = {
  rotate?: number;
  size?: number;
  arrowColor?: string;
};

const ArrowLongIcon: FunctionComponent<Props> = ({ rotate = 0, size = 1, arrowColor = color.neutral['60'] }) => {
  return (
    <svg
      style={{ transform: `rotate(${rotate}deg)`, transition: 'all 0.2s ease-in-out' }}
      width={`${size}em`}
      height={`${size}em`}
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99961 9.4001L8.14961 8.5501L11.0996 5.6001H0.599609V4.4001H11.0996L8.14961 1.4501L8.99961 0.600098L13.3996 5.0001L8.99961 9.4001Z"
        fill={arrowColor}
      />
    </svg>
  );
};

export default ArrowLongIcon;
