import React, { ComponentType, FunctionComponent, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { DictValue } from '../../../../@types/Dictionary/DictValue';
import api from '../../../../services/api';
import { CreatableSelect, SelectWithBaseUrl, SelectWithOptions } from './internal-components';
import { InputProps } from '../../../../@types/Form/InputProps';
import { FormInputContainer, FormLabel } from '../../../atoms/Form/InputParts/InputParts';
import useFormError from '../../../../hooks/Form/useFormError';
import FormError from '../../../atoms/Form/FormError/FormError';
import { ErrorMessages } from '../../../atoms/Form/FormMessages/ErrorMessages';

export type SelectProps = InputProps & {
  baseUrl?: string;
  baseUrlParams?: { [key: string]: string | number | string[] };
  /** @deprecated use StaticSelectInput component*/
  options?: DictValue[];
  optionLabel?: string;
  isMulti?: boolean;
  onChange?: (option: any) => void;
  optionComponent?: ComponentType<any>;
  singleValueComponent?: ComponentType<any>;
  creatable?: boolean;
  isFilter?: boolean;
};
/**
 * @deprecated
 */
const Select: FunctionComponent<SelectProps> = ({
  placeholder,
  label,
  baseUrl,
  baseUrlParams,
  options,
  optionLabel = 'name',
  name,
  isMulti = false,
  required,
  onChange,
  optionComponent,
  creatable = false,
  singleValueComponent,
  ...props
}) => {
  const formMethods = useFormContext();
  const [defaultOptions] = React.useState<DictValue[]>([]);
  const watchValue = formMethods.watch(name);
  const [ready, setReady] = React.useState<boolean>(false);
  const error = useFormError(name);
  React.useEffect(() => {
    if (!isMulti) {
      formMethods.register(name, { required: required && ErrorMessages.required });
    } else {
      formMethods.register(name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, formMethods.register, required]);

  const handleChange = React.useCallback(
    (value: any) => {
      if (!value) {
        formMethods.setValue(name, undefined);
        if (onChange) {
          onChange(undefined);
        }
        return;
      }
      if (value['__isNew__'] && baseUrl) {
        const data: any = {};
        data[optionLabel] = value.value;
        void api.post(baseUrl, data).then((response) => {
          const item = response.data;
          const value = {
            value: item['@id'],
            label: item[optionLabel],
            meta: item,
          };
          handleChange(value);
        });
        return;
      }
      if (onChange) {
        onChange(value);
      }
      if (!Array.isArray(value)) {
        formMethods.setValue(name, value.value);
        formMethods.clearErrors(name);
        return;
      }

      if (watchValue) {
        watchValue.forEach((el: any, index: number) => {
          formMethods.setValue(`${name}[${index}]`, '');
        });
      }
      value.forEach((element: any, index: number) => {
        if (element['__isNew__'] && baseUrl) {
          const data: any = {};
          data[optionLabel] = element.value;
          void api.post(baseUrl, data).then((response) => {
            const item = response.data;
            const newElement = {
              value: item['@id'],
              label: item[optionLabel],
              meta: item,
            };
            value.splice(index, 1, newElement);
            handleChange(value);
          });
        }
        formMethods.register(`${name}[${index}]`);
        formMethods.setValue(`${name}[${index}]`, element.value);
        formMethods.clearErrors(name);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formMethods, watchValue, onChange, name, baseUrl, baseUrlParams, isMulti],
  );

  React.useEffect(() => {
    if (ready) {
      return;
    }
    if (watchValue === undefined) {
      setReady(true);
      return;
    }
    if (Array.isArray(watchValue)) {
      const values = watchValue.map((item: { '@id': string } & any) => {
        if (typeof item === 'string') {
          return item;
        }
        if (item.hasOwnProperty('@id')) {
          const value = {
            value: item['@id'],
            label: item[optionLabel],
            meta: item,
          };
          defaultOptions.push(value);
          return value;
        } else {
          throw new Error(`Nie znaleziono ${optionLabel} w selekcie ${name}`);
        }
      });
      handleChange(values);
    } else {
      if (watchValue && watchValue.hasOwnProperty('@id')) {
        formMethods.setValue(name, watchValue['@id']);
        const value = {
          value: watchValue['@id'],
          label: watchValue[optionLabel],
          meta: watchValue,
        };
        defaultOptions.push(value);
      } else if (watchValue && options) {
        const filtered = options.filter((o: DictValue) => o.value === watchValue);
        defaultOptions.push(filtered[0]);
      }
    }
    setReady(true);
  }, [watchValue, setReady, formMethods, defaultOptions, name, optionLabel, options, ready, handleChange]);

  return (
    <>
      <FormInputContainer status={error && 'error'}>
        <FormLabel status={error && 'error'} required={required} disabled={props.disabled}>
          {label}
        </FormLabel>
        {ready && options && (
          <SelectWithOptions
            disabled={props.disabled}
            optionComponent={optionComponent}
            name={name}
            isMulti={isMulti}
            placeholder={placeholder ? placeholder : label}
            optionLabel={optionLabel}
            error={error}
            defaultValue={watchValue ? (isMulti ? defaultOptions : defaultOptions[0]) : null}
            onChange={handleChange}
            options={options}
            required={required}
            baseUrlParams={baseUrlParams}
          />
        )}
        {ready && baseUrl && !creatable && (
          <SelectWithBaseUrl
            disabled={props.disabled}
            optionComponent={optionComponent}
            name={name}
            placeholder={placeholder ? placeholder : label}
            error={error}
            isMulti={isMulti}
            defaultValue={watchValue ? (isMulti ? defaultOptions : defaultOptions[0]) : null}
            optionLabel={optionLabel}
            singleValueComponent={singleValueComponent}
            baseUrl={baseUrl}
            onChange={handleChange}
            required={required}
            baseUrlParams={baseUrlParams}
          />
        )}
        {ready && baseUrl && creatable && (
          <CreatableSelect
            optionComponent={optionComponent}
            name={name}
            placeholder={placeholder ? placeholder : label}
            error={error}
            isMulti={isMulti}
            defaultValue={watchValue ? (isMulti ? defaultOptions : defaultOptions[0]) : null}
            optionLabel={optionLabel}
            baseUrl={baseUrl}
            onChange={handleChange}
            required={required}
            baseUrlParams={baseUrlParams}
          />
        )}
      </FormInputContainer>
      <FormError name={name} />
    </>
  );
};
export default Select;
