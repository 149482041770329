import React, { FunctionComponent } from 'react';
import { SubcostType } from '../../../../../@types/Finances/Cost/SubcostType';
import SingleDetail from '../../../../molecules/SingleDetail/SingleDetail';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import { Card } from '../../../../atoms/Card/Card';
import CardHeading from '../../../../molecules/CardHeading/CardHeading';
import moneyAsString from '../../../../../helpers/moneyAsString';
import FinanceStatus from '../../../../molecules/Statuses/FinanceStatus/FinanceStatus';
import RequestCard from '../../../../organisms/RequestCard/RequestCard';
import PurchaseOrderSingleDetail from '../../../../molecules/SingleDetail/PurchaseOrderSingleDetail/PurchaseOrderSingleDetail';
import ProjectSingleDetail from '../../../../molecules/SingleDetail/ProjectSingleDetail/ProjectSingleDetail';
import { color } from '../../../../../styles/Variables';
import Heading from '../../../../atoms/Heading';
import Collapse from '../../../../atoms/Collapse/Collapse';

type Props = {
  subcost: SubcostType;
  index: number;
};

const Subcost: FunctionComponent<Props> = ({ subcost, index }) => {
  const currentRequest = subcost.requests && subcost.requests?.length > 0 ? subcost.requests[subcost.requests.length - 1] : null;
  return (
    <Card>
      <CardHeading heading={`Assignment ${index + 1}`} rightElement={<FinanceStatus status={subcost.status} />} />
      <Grid spacing={3}>
        <SingleDetail title={'Net value'} $desktop={8} type={'money'}>
          {moneyAsString(subcost.amountNet)}
        </SingleDetail>
        {subcost.amountNet.currency !== subcost.originalAmountNet.currency && (
          <>
            <SingleDetail title={'Exchange rate'} $desktop={4}>
              {subcost.exchangeRate}
            </SingleDetail>
            <SingleDetail title={'Original net value'} $desktop={4}>
              {moneyAsString(subcost.originalAmountNet)}
            </SingleDetail>
          </>
        )}
        <GridItem $desktop={12} style={{ borderBottom: `1px solid ${color.neutral[40]}` }}></GridItem>
        {subcost.purchaseOrder && <PurchaseOrderSingleDetail purchaseOrder={subcost.purchaseOrder} $desktop={12} />}
        {subcost.purchaseOrder && (
          <SingleDetail $desktop={4} title={'Amount net (PO)'} type={'money'}>
            {subcost.purchaseOrder.originalAmountNet.asString}
          </SingleDetail>
        )}
        {subcost.purchaseOrder && (
          <SingleDetail $desktop={4} title={'Remain to invoice (PO)'} type={'money'}>
            {subcost.purchaseOrder.originalRemainNet ? subcost.purchaseOrder.originalRemainNet.asString : 'N/A'}
          </SingleDetail>
        )}
        {subcost.purchaseOrder && (
          <SingleDetail $desktop={4} title={'Payment terms (PO)'}>
            {subcost.purchaseOrder.paymentTerms}
          </SingleDetail>
        )}
        {subcost.purchaseOrder && <GridItem $desktop={12} style={{ borderBottom: `1px solid ${color.neutral[40]}` }}></GridItem>}
        <GridItem $desktop={12}>{subcost.project && <ProjectSingleDetail name={subcost.project.name} id={subcost.project.id} $desktop={12} />}</GridItem>
        <SingleDetail title={'Location'} $desktop={4}>
          {subcost.branch?.shortName}
        </SingleDetail>
        <SingleDetail title={'Kind'} $desktop={4}>
          {subcost.kind}
        </SingleDetail>
        <SingleDetail title={'Cost type'} $desktop={4}>
          {subcost.costType.label}
        </SingleDetail>
        <SingleDetail title={'Budget symbol'} $desktop={4}>
          {subcost.budgetSymbol}
        </SingleDetail>
        {subcost.employee && (
          <SingleDetail title={'Employee'} $desktop={4}>
            {subcost.employee?.name}
          </SingleDetail>
        )}{' '}
        <SingleDetail title={'Description'} $desktop={12}>
          {subcost.description}
        </SingleDetail>
        {subcost.requests && subcost.requests.length > 1 && (
          <Collapse
            header={
              <Heading level={3} size={'s'}>
                Approval history
              </Heading>
            }
            hidden={
              <div style={{ marginTop: '2rem' }}>
                <Grid spacing={2}>{subcost.requests?.filter((r) => r.id !== currentRequest?.id).map((r) => <RequestCard request={r} key={r['@id']} />)}</Grid>
              </div>
            }
          />
        )}
        {currentRequest && <RequestCard request={currentRequest} current />}
      </Grid>
    </Card>
  );
};

export default Subcost;
