import React, { FunctionComponent, useEffect, useState } from 'react';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import Text from '../../../../atoms/Text';
import * as Styled from './Notification.styled';
import CloseIcon from '../../../../../Icons/CloseIcon';
import { color } from '../../../../../styles/Variables';
import { Icon } from '../../../../atoms/Icon/Icon';

type Props = {
  title: string;
  onClick?: () => void;
};

const Notification: FunctionComponent<Props> = ({ title, onClick }) => {
  const [isNotificationVisible, setIsNotificationVisible] = useState<boolean>(false);

  useEffect(() => {
    const v = localStorage.getItem('feedHidden');
    if (v && v === new Date().toISOString().slice(0, 10)) {
      setIsNotificationVisible(false);
    } else {
      setIsNotificationVisible(true);
    }
  }, []);

  return (
    <Grid>
      <GridItem $desktop={'max'}>
        <Styled.Notification $isVisible={isNotificationVisible} onClick={onClick}>
          <Styled.NotificationTitleWrapper>
            <Styled.Label>
              <Text size={'xs'} color={'primary'}>
                News
              </Text>
            </Styled.Label>
            <Text color={'light'} bold>
              {title}
            </Text>
          </Styled.NotificationTitleWrapper>
          <Icon
            size={0.8}
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              localStorage.setItem('feedHidden', new Date().toISOString().slice(0, 10));
              setIsNotificationVisible(false);
            }}
          >
            <CloseIcon color={color.light} />
          </Icon>
        </Styled.Notification>
      </GridItem>
    </Grid>
  );
};

export default Notification;
