import React, { FunctionComponent } from 'react';
import Text from '../../../atoms/Text';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import { Icon } from '../../../atoms/Icon/Icon';
import InfoIcon from '../../../../Icons/info.icon';
import Tooltip from '../../Tooltip/Tooltip';

type Props = {};

const TimeInputTooltip: FunctionComponent<Props> = (props) => {
  return (
    <Tooltip
      content={
        <div>
          <Text color={'light'} size={'xs'}>
            You can pass time values in such forms:
          </Text>
          <Grid style={{ width: '20rem', marginTop: '.6rem' }} spacing={0.6}>
            <GridItem $desktop={12}>
              <Text size={'xs'} color={'lightGrey'}>
                - 120 ➝ 120 min
              </Text>
            </GridItem>
            <GridItem $desktop={12}>
              <Text size={'xs'} color={'lightGrey'}>
                - 1d ➝ one day
              </Text>
            </GridItem>
            <GridItem $desktop={12}>
              <Text size={'xs'} color={'lightGrey'}>
                - 1h ➝ one hour (60 mins)
              </Text>
            </GridItem>
            <GridItem $desktop={12}>
              <Text size={'xs'} color={'lightGrey'}>
                - 10m ➝ 10 mins
              </Text>
            </GridItem>
            <GridItem $desktop={12}>
              <Text size={'xs'} color={'lightGrey'}>
                - 1d 2h 10m
              </Text>
            </GridItem>
            <GridItem $desktop={12}>
              <Text size={'xs'} color={'lightGrey'}>
                - 01:10 ➝ one hour, 10 mins{' '}
              </Text>
            </GridItem>
          </Grid>
        </div>
      }
      trigger={
        <Icon>
          <InfoIcon />
        </Icon>
      }
      arrowPosition={'topLeft'}
    />
  );
};

export default TimeInputTooltip;
