import React, { forwardRef, FunctionComponent, useMemo } from 'react';
import { FormHtmlInput, FormInputContainer, FormLabel } from '../../../atoms/Form/InputParts/InputParts';
import { Controller, useFormContext } from 'react-hook-form';
import BaseDatePicker from 'react-datepicker';
import { InputProps } from '../../../../@types/Form/InputProps';
import 'react-datepicker/dist/react-datepicker.css';
import FormError from '../../../atoms/Form/FormError/FormError';
import * as Styled from './DataPicker.styled';

type Props = InputProps & {
  error?: string | undefined;
  onClick?: (e: React.MouseEvent) => void;
  value?: any;
  style?: React.CSSProperties;
  birthday?: boolean;
  before?: boolean;
  required?: boolean;
};

type CustomInputProps = Props & {
  onClick?: (e: React.MouseEvent) => void;
  value?: any;
};

export const CustomInput = forwardRef<{}, CustomInputProps>((props, ref) => {
  return (
    <>
      <FormInputContainer onClick={props.onClick} status={props.error && 'error'}>
        <FormLabel status={props.error && 'error'} required={props.required}>
          {props.label}
        </FormLabel>
        <FormHtmlInput
          onChange={props.onChange}
          style={{ cursor: 'pointer' }}
          status={props.error && 'error'}
          required={props.required}
          value={props.value}
          placeholder={props.placeholder ?? props.label}
        />
      </FormInputContainer>
    </>
  );
});
const DatePicker: FunctionComponent<Props> = (props) => {
  const { control } = useFormContext();
  const [ready] = React.useState(true);
  const birthdate = useMemo(() => {
    // has to be 13 years old
    const today = new Date().getTime();
    return new Date(today - 13 * 365 * 24 * 60 * 60 * 1000);
  }, []);
  return (
    <Styled.DataPickerContainer>
      {ready && (
        <Controller
          control={control}
          name={props.name}
          defaultValue={props.defaultValue ?? ''}
          render={({ field }) => {
            return (
              <>
                <BaseDatePicker
                  portalId="data-picker-portal"
                  calendarStartDay={1}
                  placeholderText={props.placeholder ?? props.label}
                  onChange={(date) => {
                    if (Array.isArray(date)) {
                      return date;
                    } else {
                      date?.setHours(props.before ? 23 : 2);
                      field.onChange(date?.toISOString());
                      props.onChange?.(date?.toISOString());
                    }
                  }}
                  selected={field.value ? new Date(field.value) : undefined}
                  showYearDropdown={true}
                  maxDate={props.birthday ? birthdate : undefined}
                  scrollableYearDropdown
                  yearDropdownItemNumber={props.birthday ? 60 : 10}
                  required={props.required}
                  isClearable={!props.required}
                  customInput={<CustomInput {...props} />}
                  dateFormat={'yyyy-MM-dd'}
                  onSelect={() => {}}
                ></BaseDatePicker>
                <FormError name={props.name} />
              </>
            );
          }}
        />
      )}
    </Styled.DataPickerContainer>
  );
};
export default DatePicker;
