import React, { FunctionComponent } from 'react';
import { components, ControlProps } from 'react-select';
import { FinancialCompanyType } from '../../../../@types/FinancialCompany/FinancialCompanyType';
import { color } from '../../../../styles/Variables';
import { PiMagnifyingGlassBold } from 'react-icons/pi';
import { Icon } from '../../../../components/atoms/Icon/Icon';

const FinancialCompanySelectorControl: FunctionComponent<ControlProps<{ value: string; label: string; meta: FinancialCompanyType }, false>> = ({
  children,
  ...rest
}) => {
  return (
    <components.Control {...rest}>
      <Icon size={1.8} color={color.neutral[50]}>
        <PiMagnifyingGlassBold />
      </Icon>
      {children}
    </components.Control>
  );
};

export default FinancialCompanySelectorControl;
