import React, { FunctionComponent, useMemo } from 'react';
import * as Styled from './TimeProgress.styled';
import Text from '../../atoms/Text/Text';
import { OverflowSentimentType } from '../../../@types/OverflowSentiment/OverflowSentimentType';
import ProgressBar from './ProgressBar/ProgressBar';
import { convertMinutesToHours } from '../../../helpers/convertMinutesToHours';

type Props = {
  current: number;
  estimated: number;
  unit?: 'hours' | 'minutes' | 'standard_unit';
  label?: string;
  overflowSentiment?: OverflowSentimentType;
  context?: 'table' | 'checklist';
  width?: number;
};

const TimeProgress: FunctionComponent<Props> = ({ unit = 'minutes', current, estimated, label, overflowSentiment, context = 'table', width }) => {
  //This is default sentiment. You can add the another one in parent component and pass it with a prop.
  const getSentiment = (estimate: number, value: number): OverflowSentimentType =>
    useMemo(() => {
      if (value > estimate) {
        return 'bad';
      }
      if (value >= estimate * 0.75) {
        return 'warning';
      }
      if (value < estimate * 0.75 && value >= estimate * 0.4) {
        return 'neutral';
      }
      if (value < estimate * 0.4) {
        return 'good';
      }
      return 'neutral';
    }, [estimate, value]);

  const sentiment = overflowSentiment ?? getSentiment(estimated, current);

  return (
    <Styled.TimeProgressStyled>
      {label && (
        <Text size={'l'} bold={true}>
          {label}
        </Text>
      )}
      <Styled.ProgressWrapper $width={width}>
        <ProgressBar current={current} estimate={estimated} overflowSentiment={sentiment} />
        <Styled.HoursWrapper>
          <Text
            size={'xs'}
            color={
              current === 0
                ? 'grey'
                : sentiment === 'good' && context === 'checklist'
                  ? 'success'
                  : sentiment === 'good' || sentiment === 'neutral'
                    ? 'darkGrey'
                    : sentiment === 'warning' && context === 'checklist'
                      ? 'darkGrey'
                      : sentiment === 'bad' && context === 'checklist'
                        ? 'darkGrey'
                        : sentiment === 'warning'
                          ? 'warning'
                          : 'error'
            }
            bold
          >
            {unit === 'standard_unit' && <>{current ?? '0'}</>}
            {unit === 'hours' && <>{current ?? '0'}h</>}
            {unit === 'minutes' && <>{current ? convertMinutesToHours(current) : '0'}</>}
          </Text>
          <Text size={'xs'} color={sentiment === 'good' && current / estimated === 1 ? 'success' : 'neutral50'} bold>
            &nbsp;/&nbsp;
          </Text>
          <Text size={'xs'} color={sentiment === 'good' && current / estimated === 1 ? 'success' : 'darkGrey'} bold>
            {unit === 'standard_unit' && <>{estimated}</>}
            {unit === 'hours' && <>{estimated}h</>}
            {unit === 'minutes' && <>{estimated ? convertMinutesToHours(estimated) : '0'}</>}
          </Text>
        </Styled.HoursWrapper>
      </Styled.ProgressWrapper>
    </Styled.TimeProgressStyled>
  );
};

export default TimeProgress;
