import { CellTemplate } from '../../CellTemplate';
import React, { memo, useMemo } from 'react';
import Chip from '../../../../atoms/Chips/Chip';
import { color, fontWeight } from '../../../../../styles/Variables';
import IdText from '../../../IdText/IdText';
import { AbsenceType } from '../../../../../@types/Absences/AbsenceType';
import Text from '../../../../atoms/Text/Text';
import ChildbirthIcon from '../../../../../Icons/Childbirth.icon';
import RecreationIcon from '../../../../../Icons/Recreation.icon';
import ChurchIcon from '../../../../../Icons/Church.icon';
import { Icon } from '../../../../atoms/Icon/Icon';
import SickLeaveIcon from '../../../../../Icons/SickLeave.icon';
import { HomeIcon } from '../../../../../Icons/home.icon';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import OnDemandIcon from '../../../../../Icons/OnDemandIcon';
import HeartIcon from '../../../../../Icons/Heart.icon';

const AbsenceIdTemplate: CellTemplate<
  string,
  {
    withHash?: boolean;
  },
  AbsenceType
> = ({ value, item }) => {
  const typeChip = useMemo(() => {
    switch (item.absenceType) {
      case 'sick_leave':
        return (
          <Chip $bgColor={color.semantic.warning[20]}>
            <Icon color={color.semantic.warning['100']} size={1.4}>
              <SickLeaveIcon />
            </Icon>
            <Text
              size={'s'}
              style={{
                color: color.semantic.warning[100],
                fontWeight: fontWeight.medium,
              }}
            >
              Sick leave
            </Text>
          </Chip>
        );
      case 'home_office':
        return (
          <Chip $bgColor={color.primary[20]}>
            <Icon color={color.primary['60']} size={1.4}>
              <HomeIcon />
            </Icon>
            <Text
              size={'s'}
              style={{
                color: color.primary[60],
                fontWeight: fontWeight.medium,
              }}
            >
              Home office
            </Text>
          </Chip>
        );
    }
  }, [value]);

  const kindChip = useMemo(() => {
    switch (item.absenceKind) {
      case 'wedding':
        return (
          <Chip $bgColor={color.semantic.error['20']}>
            <Icon color={color.semantic.error['80']} size={1.4}>
              <HeartIcon />
            </Icon>
            <Text
              size={'s'}
              style={{
                color: color.semantic.error['80'],
                fontWeight: fontWeight.medium,
              }}
            >
              Wedding
            </Text>
          </Chip>
        );
      case 'funeral':
        return (
          <Chip $bgColor={color.additionals.magnolia}>
            <Icon color={color.additionals.purple} size={1.4}>
              <ChurchIcon />
            </Icon>
            <Text
              size={'s'}
              style={{
                color: color.additionals.purple,
                fontWeight: fontWeight.medium,
              }}
            >
              Funeral
            </Text>
          </Chip>
        );
      case 'childbirth':
        return (
          <Chip $bgColor={color.additionals.magnolia}>
            <Icon color={color.additionals.purple} size={1.4}>
              <ChildbirthIcon />
            </Icon>
            <Text size={'s'} style={{ color: color.additionals.purple, fontWeight: fontWeight.medium }}>
              Childbirth
            </Text>
          </Chip>
        );
      case 'recreation':
        return (
          <Chip $bgColor={color.secondary['20']}>
            <Icon color={color.secondary['70']} size={1.4}>
              <RecreationIcon />
            </Icon>
            <Text
              size={'s'}
              style={{
                color: color.secondary[70],
                fontWeight: fontWeight.medium,
              }}
            >
              Recreation
            </Text>
          </Chip>
        );
      case 'on_demand':
        return (
          <Chip $bgColor={color.neutral['30']}>
            <Icon color={color.neutral['70']} size={1.4}>
              <OnDemandIcon />
            </Icon>
            <Text
              size={'s'}
              style={{
                color: color.neutral[70],
                fontWeight: fontWeight.medium,
              }}
            >
              On demand
            </Text>
          </Chip>
        );
    }
  }, [value]);

  return (
    <Grid spacing={0.6}>
      <GridItem $desktop={12}>
        <Grid $align={'center'} spacing={0.3}>
          <GridItem>
            <IdText withHash={true} value={item['@id']} size={'s'} bold />
          </GridItem>
          <GridItem>
            <Text size={'s'} bold color={'grey'}>
              Request
            </Text>
          </GridItem>
        </Grid>
      </GridItem>
      {typeChip && <GridItem>{typeChip}</GridItem>}
      {kindChip && <GridItem>{kindChip}</GridItem>}
    </Grid>
  );
};

export default memo(AbsenceIdTemplate);
