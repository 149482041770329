import React from 'react';
import { TableFilterInterface } from '../TableFilterInterface';
import Select from '../../Form/SelectInput/SelectInput';

const DepartmentFilter: TableFilterInterface = ({ name, filter, onChange, defaultValue }) => {
  return (
    <Select
      name={name}
      onChange={onChange}
      defaultValue={defaultValue}
      baseUrl={'/departments'}
      label={filter.label ?? name}
      placeholder={filter.placeholder}
      isFilter={true}
    />
  );
};

export default DepartmentFilter;
