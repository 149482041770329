import { UserType } from '../@types/User/user';
import { useContext } from 'react';
import { AuthContext } from '../Context/auth-context/auth-context';

function useUser(): UserType {
  const {
    state: { user },
  } = useContext(AuthContext);
  return user as UserType;
}

export default useUser;
