import React, { FunctionComponent } from 'react';

type PropType = {
  size?: number;
  color?: string;
};

const MicrosoftProjectIcon: FunctionComponent<PropType> = ({ size = 1, color }) => {
  return (
    <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 200 175" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_666_146)">
        <path
          d="M191.402 172.038H77.7081C74.3117 172.038 71.6094 169.336 71.6094 165.939V118.758L112 118.758L167.199 118.758H191.402C194.799 118.758 197.501 121.46 197.501 124.857V165.939C197.501 169.336 194.799 172.038 191.402 172.038Z"
          stroke={color ?? 'currentColor'}
          strokeWidth="5"
        />
        <path
          d="M132.5 9.29508L132.194 60.5H83.4294H27.5V9.29508C27.5 5.34197 30.3931 2.5 33.6589 2.5H126.341C129.605 2.5 132.497 5.33871 132.5 9.2883C132.5 9.29056 132.5 9.29282 132.5 9.29508Z"
          stroke={color ?? 'currentColor'}
          strokeWidth="5"
        />
        <path
          d="M27.5 60.5H158.384C161.615 60.5 164.5 63.3298 164.5 67.2951V118.5H33.6157C30.3852 118.5 27.5 115.67 27.5 111.705V60.5Z"
          stroke={color ?? 'currentColor'}
          strokeWidth="5"
        />
        <path
          opacity="0.1"
          d="M93.311 141.742C98.088 141.742 101.91 137.92 101.91 133.462V44.9269C101.91 40.1498 98.088 36.3281 93.6294 36.3281H25.4766V107.666C25.4766 112.443 29.2982 116.264 34.0753 116.264H69.1071V141.742H93.311Z"
          fill="black"
        />
        <path
          d="M8.59873 36.3281H93.3121C98.0892 36.3281 101.911 40.1498 101.911 44.9269V129.64C101.911 134.417 98.0892 138.239 93.3121 138.239H8.59873C3.82166 138.239 0 134.417 0 129.64V44.9269C0 40.1498 3.82166 36.3281 8.59873 36.3281Z"
          fill={color ?? 'currentColor'}
        />
        <path
          d="M52.5508 58.9384C57.9648 58.62 63.3788 60.2123 67.519 63.397C71.0221 66.9002 72.933 71.6773 72.6145 76.4544C72.6145 79.9575 71.6591 83.1423 70.0667 86.327C68.1559 89.1932 65.6081 91.4225 62.7419 93.0149C59.2387 94.6072 55.4171 95.5626 51.5954 95.2442H41.0858V115.308H30.2578V58.9384H52.5508ZM41.4043 86.6454H50.64C53.5062 86.9639 56.3725 86.0085 58.9202 84.0977C60.8311 82.1868 61.7865 79.6391 61.7865 76.7728C61.7865 70.4034 58.2833 67.5372 51.2769 67.5372H41.7228L41.4043 86.6454Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_666_146">
          <rect width="200" height="174.56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MicrosoftProjectIcon;
