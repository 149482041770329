import React, { FunctionComponent } from 'react';
import { BasicEmployeeType } from '../../../@types/Employee/EmployeeType';
import * as Styled from './Person.styled';
import Avatar from '../../atoms/Avatar/Avatar';
import Text from '../../atoms/Text/Text';
import { TooltipArrowPosition } from '../Tooltip/Tooltip.styled';
import { TextSizeType } from '../../../@types/Text/TextSizeType';
import { ColorType } from '../../../@types/Color/ColorType';

type Props = {
  person: BasicEmployeeType;
  hideName?: boolean;
  hideAvatar?: boolean;
  size?: number;
  bold?: boolean;
  tooltipPosition?: TooltipArrowPosition;
  textSize?: TextSizeType;
  textColor?: ColorType;
};

const Person: FunctionComponent<Props> = ({ person, hideName, hideAvatar, size = 2, bold = false, tooltipPosition, textSize, textColor }) => {
  return (
    <Styled.PersonWrapper>
      {!hideAvatar && <Avatar photoUrl={person.profilePhotoUrl} name={person.name} size={size} tooltipPosition={tooltipPosition} />}
      {!hideName && (
        <Text bold={bold} color={textColor ?? 'darkGrey'} size={textSize ?? 'l'} style={{ textWrap: 'nowrap' }}>
          {person.name}
        </Text>
      )}
    </Styled.PersonWrapper>
  );
};

export default Person;
