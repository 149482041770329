import { TableProperty } from '../../../../@types/Table/TableProperty';
import StringTemplate from '../../../molecules/CellTemplates/common/StringTemplate/StringTemplate';
import MoneyTemplate from '../../../molecules/CellTemplates/common/MoneyTemplate/MoneyTemplate';
import ContractorValueTaxTemplate from '../../../molecules/CellTemplates/Finances/ContractorValueTaxTemplate/ContractorValueTaxTemplate';
import SubcostNumberTemplate from './Templates/SubcostNumberTemplate';
import DateTemplate from '../../../molecules/CellTemplates/common/DateTemplate/DateTemplate';
import PurchaseOrderTemplate from './Templates/PurchaseOrderTemplate';
import CostTypeTemplate from './Templates/CostTypeTemplate';
import ProjectTemplate from '../../../molecules/CellTemplates/common/ProjectTemplate/ProjectTemplate';

export const properties: TableProperty[] = [
  {
    name: 'cost.number',
    visibleName: 'Number',
    template: SubcostNumberTemplate,
    templateOptions: {
      withHash: false,
      textProperties: {
        size: 'm',
        color: 'primary',
        bold: true,
      },
    },
    sortable: true,
  },
  {
    name: 'cost.contractor',
    visibleName: 'Vendor',
    template: ContractorValueTaxTemplate,
    sortable: true,
  },
  {
    name: 'cost.postedAt',
    visibleName: 'Posting date',
    template: DateTemplate,
    sortable: true,
    templateOptions: {
      textProperties: {
        size: 'm',
        bold: true,
      },
    },
  },
  {
    name: 'budgetSymbol',
    visibleName: 'Budget',
    template: StringTemplate,
    sortable: true,
    templateOptions: {
      textProperties: {
        size: 'm',
        bold: true,
      },
    },
  },
  {
    name: 'costType',
    visibleName: 'Type',
    template: CostTypeTemplate,
    sortable: 'costType.label',
  },
  {
    name: 'project',
    visibleName: 'Project',
    template: ProjectTemplate,
    sortable: 'project.id',
    templateOptions: {
      textProperties: {
        size: 'm',
        color: 'grey',
        bold: true,
      },
    },
  },
  {
    name: 'purchaseOrder',
    visibleName: 'Purchase order',
    template: PurchaseOrderTemplate,
    sortable: 'purchaseOrder.id',
    templateOptions: {
      textProperties: {
        size: 'm',
        bold: true,
      },
    },
  },
  {
    name: 'amountNet',
    visibleName: 'Net',
    template: MoneyTemplate,
    justifyContent: 'flex-end',
    sumBy: 'amountNet',
    sortable: 'amountNet.value',
    templateOptions: {
      textProperties: {
        size: 'm',
        bold: true,
      },
    },
  },
];
