import React, { FunctionComponent } from 'react';
import TablePage from '../../../organisms/TablePage/TablePage';
import { properties } from './SubcostsList.properties';
import { filters } from './SubcostsList.filters';

type Props = {};

const SubcostsList: FunctionComponent<Props> = (props) => {
  return (
    <TablePage
      itemLink={(item) => `/finances/costs/costs/${item.cost.id}/show`}
      url={'/api/subcosts'}
      context={'/api/subcosts'}
      properties={properties}
      heading={'Assignments'}
      filters={filters}
      defaultFilters={{
        cost__postedAt: new Date().getFullYear(),
      }}
      exportUrl={'/api/subcosts/export'}
    />
  );
};

export default SubcostsList;
