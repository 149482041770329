import styled, { css } from 'styled-components';
import { color } from '../../../styles/Variables';
import Text from '../../atoms/Text';

type Props = {
  $isRead: boolean;
};

export const Container = styled.div<Props>`
  display: flex;
  background-color: ${color.light};
  padding: 2.2rem 2.1rem 2.3rem 2.2rem;
  align-items: flex-end;
  width: 100%;
  transition: background-color 0.3s ease-in-out;
  border-bottom: 1px solid ${color.neutral['40']};
  gap: 0.6rem;
  flex-direction: column;

  ${(props) =>
    !props.$isRead &&
    css`
      background-color: ${color.primary['10']};
    `};

  &:hover {
    background-color: ${color.neutral['30']};
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  gap: 1.2rem;
  position: relative;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  gap: 1.3rem;
  position: relative;
`;

export const AvatarWrapper = styled.div`
  padding-top: 0.6rem;
`;

export const Time = styled.div`
  display: flex;
`;

export const Indicator = styled.div<Props>`
  display: block;
  width: 10px;
  height: 10px;
  background-color: ${color.primary['60']};
  border-radius: 50%;
  cursor: pointer;

  transition: all 0.15s ease-in-out;

  &:hover {
    transform: scale(120%);
  }

  ${(props) =>
    props.$isRead &&
    css`
      background-color: ${color.neutral['40']};
    `};
`;

export const ContentTitle = styled(Text)`
  display: flex;
  gap: 0.6rem;
  align-items: flex-start;
`;

export const TopWrapper = styled.div`
  display: flex;
  gap: 1.2rem;
  align-items: center;
`;
