import styled, { css } from 'styled-components';
import { BodyText } from 'styles/Typography';
import { color, fontWeight } from 'styles/Variables';

export const Wrapper = styled.div`
  padding: 0.8rem 0 0;
`;

export const Input = styled.input<{ $error: boolean; $fontSize?: string }>`
  ${BodyText};
  background-color: transparent;
  border: none;
  margin: 0;
  outline: none;
  padding: 0 0 0.3rem 0;
  font-size: inherit;
  width: 100%;
  font-weight: ${fontWeight.bold};

  :focus {
    border-bottom: 2px solid ${color.primary[40]};
  }

  ::placeholder {
    color: ${color.neutral[70]};
  }

  ${(p) =>
    p.$error &&
    css`
      color: ${color.semantic.error[80]};

      ::placeholder {
        color: ${color.semantic.error[80]};
      }
    `}

  ${(p) =>
    p.$fontSize &&
    css`
      font-size: ${p.$fontSize};
    `}
`;

export const Tooltip = styled.span``;
