import React, { FunctionComponent } from 'react';

type Props = {};

const EstimationIcon: FunctionComponent<Props> = (props) => {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.79674 14.6653H10.207V12.1653C10.207 11.238 9.89929 10.4346 9.28391 9.75507C8.66853 9.07559 7.90785 8.73585 7.00186 8.73585C6.09588 8.73585 5.3352 9.07559 4.71982 9.75507C4.10443 10.4346 3.79674 11.238 3.79674 12.1653V14.6653ZM7.00186 7.26151C7.90785 7.26151 8.66853 6.92177 9.28391 6.24228C9.89929 5.56278 10.207 4.75936 10.207 3.83201V1.33201H3.79674V3.83201C3.79674 4.75936 4.10443 5.56278 4.71982 6.24228C5.3352 6.92177 6.09588 7.26151 7.00186 7.26151ZM0.832031 15.9153V14.6653H2.54678V12.1653C2.54678 11.2594 2.77942 10.4268 3.2447 9.66774C3.70998 8.90866 4.34486 8.3523 5.14936 7.99868C4.34486 7.64505 3.70998 7.0887 3.2447 6.32962C2.77942 5.57053 2.54678 4.738 2.54678 3.83201V1.33201H0.832031V0.0820312H13.1717V1.33201H11.4569V3.83201C11.4569 4.738 11.2243 5.57053 10.759 6.32962C10.2938 7.0887 9.65886 7.64505 8.85436 7.99868C9.65886 8.3523 10.2938 8.90866 10.759 9.66774C11.2243 10.4268 11.4569 11.2594 11.4569 12.1653V14.6653H13.1717V15.9153H0.832031Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default EstimationIcon;
