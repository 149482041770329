import React, { useCallback, useEffect } from 'react';
import { InlineInputDropdownType } from '../InlineInput/InlineInputDropdownType';
import { useFormContext } from 'react-hook-form';
import useKeyboard from '../../../../../hooks/useKeyboard';
import * as Styled from './TaskEstimationDropdown.styled';
import { convertTimeStringToMinutes } from '../../../../../helpers/convertTimeStringToMinutes';
import { convertMinutesToHours } from '../../../../../helpers/convertMinutesToHours';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import { FormLabel } from '../../../../atoms/Form/InputParts/InputParts';
import TimeInputTooltip from '../../../Form/TimeInput/TimeInputTooltip';

const TaskEstimationDropdown: InlineInputDropdownType = ({ name, onChange, visible }) => {
  const { register, watch, setError } = useFormContext();
  const value = watch(name);

  useEffect(() => {
    if (!value) {
      return;
    }
  }, [value]);

  const handleChange = useCallback(() => {
    if (!visible) {
      return;
    }
    if (!value) {
      onChange(undefined);
    }
    try {
      onChange({ value: convertTimeStringToMinutes(value), label: convertMinutesToHours(convertTimeStringToMinutes(value)) });
    } catch (error) {
      setError(name, { message: 'error' });
    }
  }, [value, onChange, visible]);

  useKeyboard('Enter', handleChange);

  return (
    <div className={'dropdown'}>
      <Grid spacing={0.3} $align={'center'}>
        <GridItem>
          <FormLabel>Time estimate</FormLabel>
        </GridItem>
        <GridItem>
          <TimeInputTooltip />
        </GridItem>
      </Grid>
      <Styled.EstimationInput autoFocus type={'text'} {...register(name)} />{' '}
    </div>
  );
};

export default TaskEstimationDropdown;
