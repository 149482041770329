import React, { FunctionComponent } from 'react';
import { color } from '../styles/Variables';

type Props = {
  size?: number;
  iconColor?: string;
};

const StockIcon: FunctionComponent<Props> = ({ size = 1.4, iconColor = color.primary['60'] }) => {
  return (
    <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_2652_1629" maskUnits="userSpaceOnUse" x="0" y="0" width={`${size}em`} height={`${size}em`}>
        <rect width={`${size}em`} height={`${size}em`} fill={iconColor} />
      </mask>
      <path
        d="M7.33333 14H3.33333C2.96667 14 2.65278 13.8694 2.39167 13.6083C2.13056 13.3472 2 13.0333 2 12.6666V3.33329C2 2.96663 2.13056 2.65274 2.39167 2.39163C2.65278 2.13051 2.96667 1.99996 3.33333 1.99996H6.11667C6.23889 1.61107 6.47778 1.29163 6.83333 1.04163C7.18889 0.791626 7.57778 0.666626 8 0.666626C8.44444 0.666626 8.84167 0.791626 9.19167 1.04163C9.54167 1.29163 9.77778 1.61107 9.9 1.99996H12.6667C13.0333 1.99996 13.3472 2.13051 13.6083 2.39163C13.8694 2.65274 14 2.96663 14 3.33329V6.66663H12.6667V3.33329H11.3333V5.33329H4.66667V3.33329H3.33333V12.6666H7.33333V14ZM10.3333 13.2833L7.5 10.45L8.43333 9.51663L10.3333 11.4166L14.1 7.64996L15.0333 8.58329L10.3333 13.2833ZM8 3.33329C8.18889 3.33329 8.34722 3.2694 8.475 3.14163C8.60278 3.01385 8.66667 2.85551 8.66667 2.66663C8.66667 2.47774 8.60278 2.3194 8.475 2.19163C8.34722 2.06385 8.18889 1.99996 8 1.99996C7.81111 1.99996 7.65278 2.06385 7.525 2.19163C7.39722 2.3194 7.33333 2.47774 7.33333 2.66663C7.33333 2.85551 7.39722 3.01385 7.525 3.14163C7.65278 3.2694 7.81111 3.33329 8 3.33329Z"
        fill={iconColor}
      />
    </svg>
  );
};

export default StockIcon;
