import styled from 'styled-components';
import { color, fontWeight } from '../../../../styles/Variables';

type ColorProps = {
  color: string;
};

export const Container = styled.div`
  position: fixed;
  bottom: 30px;
  left: 30px;
  z-index: 99099;
`;
export const Item = styled.div`
  width: 640px;
  height: 70px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  background-color: ${color.light};
  border: 1px solid ${color.neutral[40]};
  border-radius: 5px;
`;
export const IconWrapper = styled.div<ColorProps>`
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.color};
  border-radius: 5px;
`;
export const Wrapper = styled.div`
  width: calc(100% - 70px);
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 0 30px;
`;
export const Title = styled.div<ColorProps>`
  color: ${(props) => props.color};
  font-size: 14px;
  font-weight: ${fontWeight.bold};
  width: 100%;
`;
export const Message = styled.div<ColorProps>`
  color: ${(props) => props.color};
  font-size: 14px;
  width: 100%;
`;

export const Close = styled.div`
  cursor: pointer;
  transition: transform 0.2s ease;
  padding: 10px;

  &:hover {
    transform: rotate(90deg);
  }

  &:active {
    transform: scale(0.8) rotate(90deg);
  }
`;
