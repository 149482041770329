import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { MeetingType } from '../../../../@types/Meeting/MeetingType';
import { color } from '../../../../styles/Variables';
import * as Styled from './MyWorkTask.styled';
import { parseDate } from '../../../../helpers/date';
import Text from '../../../atoms/Text';
import { Icon } from '../../../atoms/Icon/Icon';
import CalendarIcon from '../../../../Icons/Calendar.icon';
import useModal from '../../../../hooks/useModal';
import MeetingsBody, { MeetingsSubtitle } from '../../../organisms/MeetingDetailsModal/MeetingDetailsModal';
import { useNavigate } from 'react-router-dom';

type PropsType = {
  meeting: MeetingType;
  isOverlapped: boolean;
  position: 'left' | 'right' | undefined;
};

const Meeting: FunctionComponent<PropsType> = ({ meeting, isOverlapped, position }) => {
  const navigate = useNavigate();
  const duration = useMemo(() => {
    const start = new Date(meeting.start);
    const end = new Date(meeting.end);
    return (end.getTime() - start.getTime()) / 1000 / 60 / 30;
  }, [meeting]);
  const topPosition = useMemo(() => {
    const minutes = new Date(meeting.start).getHours() * 60 + new Date(meeting.start).getMinutes() - 540;
    return 2 * minutes + minutes / 3;
  }, []);
  const { showModal, hideModal } = useModal();
  const onMeetingSubtitleClick = useCallback(() => {
    navigate(`projects/${meeting.project.id}`);
    hideModal();
  }, []);

  const onMeetingClick = useCallback(() => {
    showModal({
      title: meeting.subject,
      subtitle: meeting.project && MeetingsSubtitle(meeting, () => onMeetingSubtitleClick()),
      body: <MeetingsBody meeting={meeting} />,
      width: 62,
    });
  }, [meeting]);

  return (
    <Styled.Task
      $color={isOverlapped ? 'rgba(23, 46, 255, .5)' : color.primary[60]}
      $isDone={false}
      $isTaskOverdue={false}
      $topPosition={topPosition}
      $duration={duration}
      $isShort={duration === 1}
      $isOverlapped={isOverlapped}
      $isMeeting={true}
      onClick={(e) => onMeetingClick()}
    >
      <Styled.Head>
        <Text color={'lightGrey'} size={'xs'} bold>
          {parseDate(meeting.start, false, false, false, true)}&nbsp;-&nbsp;{parseDate(meeting.end, false, false, false, true)}
        </Text>
        <Icon size={1.3}>
          <CalendarIcon color={color.light} />
        </Icon>
      </Styled.Head>
      <Styled.Body $isShort={duration === 1}>
        <Styled.BodyText color={'light'} size={'xl'} className={'task-title'} bold>
          {meeting.subject}
        </Styled.BodyText>
      </Styled.Body>
    </Styled.Task>
  );
};

export default Meeting;
