import React, { useCallback } from 'react';
import { InlineInputDropdownType } from '../InlineInput/InlineInputDropdownType';
import * as Styled from './TaskPriorityDropdown.styled';
import { useFormContext } from 'react-hook-form';
import useKeyboard from '../../../../../hooks/useKeyboard';
import { TaskPriorityType } from '../../../../../@types/Task/TaskType';
import PriorityTag from 'components/molecules/PriorityTag/PriorityTag';

const TaskPriorityDropdown: InlineInputDropdownType = ({ onChange, name, visible }) => {
  const priorities: TaskPriorityType[] = ['Low', 'Normal', 'High', 'ASAP'];
  const { register, watch } = useFormContext();
  const watcher = watch(name);

  const handleFocus = useCallback((e: any) => {
    e.target.focus();
  }, []);

  const handleChange = useCallback(
    (value: string | undefined = undefined) => {
      if (!visible) {
        return;
      }
      onChange({ value: value ?? watcher, label: value ?? watcher });
    },
    [onChange, watcher, visible],
  );

  useKeyboard('Enter', () => handleChange());
  useKeyboard('ArrowUp', handleFocus);
  useKeyboard('ArrowDown', handleFocus);

  return (
    <Styled.TagsGroup className={'dropdown'}>
      {priorities.map((value, i) => (
        <Styled.Label key={value}>
          <Styled.Input autoFocus={i === 0 ? true : false} type={'radio'} {...register(name)} value={value} onClick={() => handleChange(value)} />
          <PriorityTag priority={value} />
        </Styled.Label>
      ))}
    </Styled.TagsGroup>
  );
};

export default TaskPriorityDropdown;
