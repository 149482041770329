import styled, { css } from 'styled-components';
import { color, fontSize, fontWeight } from '../../../styles/Variables';

type WrapperProps = {
  $isVisible: boolean;
};

export const IndicatorWrapper = styled.div``;

export const Wrapper = styled.div<WrapperProps>`
  position: absolute;
  height: calc(100vh - 59px);
  width: 600px;
  top: 59px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  right: -600px;
  opacity: 0;
  transition:
    opacity 0.15s ease-in-out,
    right 0.15s ease-in-out;
  background-color: ${color.light};
  border-left: 1px solid ${color.neutral['40']};
  border-bottom: 1px solid ${color.neutral['40']};
  display: none;

  ${(props) =>
    props.$isVisible &&
    css`
      right: 0;
      opacity: 1;
      display: block;
    `};
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3.2rem 3.2rem 3.2rem 2.2rem;
  border-top: 1px solid ${color.neutral['40']};
`;

export const AllAsReadWrapper = styled.div`
  cursor: pointer;
  display: flex;
  gap: 0.8rem;
  align-items: center;
`;

export const Counter = styled.div`
  position: absolute;
  display: flex;
  transform: translate(2.7rem, -0.3rem);
  padding: 0.2rem;
  width: 17px;
  height: 17px;
  background: ${color.semantic.error['100']};
  border-radius: 7px;
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.body.xs};
  text-align: center;
  color: ${color.neutral['10']};
  justify-content: center;
  align-items: center;
`;
