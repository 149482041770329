import React, { FunctionComponent } from 'react';
import CardHeading from '../../molecules/CardHeading/CardHeading';
import { Grid, GridItem } from '../../atoms/Grid/Grid';
import { Card } from '../../atoms/Card/Card';
import { Requestable, RequestType } from '../../../@types/Request/RequestType';
import DraftStatus from './DraftStatus';
import RequestCard from './RequestCard';
import Collapse from '../../atoms/Collapse/Collapse';
import Heading from '../../atoms/Heading';

type Props = {
  onApprovalRequest: () => Promise<void>;
  requestable: Requestable;
  requests: RequestType[];
};

const ApprovalCard: FunctionComponent<Props> = ({ onApprovalRequest, requestable, requests }) => {
  const lastRequest = requests.length > 0 ? requests[requests.length - 1] : undefined;
  return (
    <Card>
      <CardHeading heading={'Approval status'} />
      <Grid spacing={3}>
        <GridItem $desktop={12}>
          <DraftStatus status={requestable.status} onApprovalRequest={onApprovalRequest} />
        </GridItem>
        <GridItem $desktop={12}>
          {requests.length > 1 && (
            <Collapse
              header={
                <Heading level={3} size={'s'}>
                  Approval history
                </Heading>
              }
              hidden={
                <div style={{ marginTop: '2rem' }}>
                  <Grid spacing={2}>
                    {requests.map(
                      (request) =>
                        request.id !== lastRequest?.id && (
                          <GridItem $desktop={12} key={request['@id']}>
                            <RequestCard request={request} />
                          </GridItem>
                        ),
                    )}
                  </Grid>
                </div>
              }
            />
          )}
          {lastRequest && (
            <GridItem $desktop={12}>
              <RequestCard request={lastRequest} current />
            </GridItem>
          )}
        </GridItem>
      </Grid>
    </Card>
  );
};

export default ApprovalCard;
