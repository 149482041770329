import React, { FunctionComponent } from 'react';
import * as Styled from './Styles.styled';
import TaskStatus from '../../../../molecules/Statuses/TaskStatus/TaskStatus';
import PriorityTag from '../../../../molecules/PriorityTag/PriorityTag';
import { TaskType } from '../../../../../@types/Task/TaskType';
import EditableDetail from '../../../../molecules/EditableDetail/EditableDetail';
import TaskPriorityDropdown from '../../../../molecules/Task/TaskForm/TaskPriorityDropdown/TaskPriorityDropdown';
import { useEntityContext } from '../../../../../Context/EntityContext/EntityContext';
import Tags from '../../../../molecules/Tags';
import Chip from '../../../../atoms/Chips/Chip';
import Popover from '../../../../atoms/Popover/Popover';
import { parseDate } from '../../../../../helpers/date';
import { convertMinutesToHours } from '../../../../../helpers/convertMinutesToHours';
import { color } from '../../../../../styles/Variables';

type Props = {
  task: TaskType;
};

const TaskSidebarTags: FunctionComponent<Props> = ({ task }) => {
  const { replace } = useEntityContext();
  const plannedText = task.taskEntries && task.taskEntries.length > 0 ? 'Planned on ' + parseDate(task.taskEntries[0].date) : '';
  return (
    <Styled.Tags>
      <TaskStatus status={task.status} />
      <EditableDetail
        name={'priority'}
        subject={task}
        show={<PriorityTag priority={task.priority} />}
        component={TaskPriorityDropdown}
        disabled={!!task.deletedAt}
        onUpdate={(task) => replace(task)}
      />
      {task.planned && (
        <Chip $color={'neutral70'} $bgColor={color.neutral[30]}>
          <Popover
            onHover
            popover={
              <div>
                {task.taskEntries &&
                  task.taskEntries?.map((entry) => (
                    <div key={entry['@id']}>
                      {convertMinutesToHours(entry.duration)} on {parseDate(entry.date)}
                    </div>
                  ))}
              </div>
            }
          >
            <span>{plannedText}</span>
          </Popover>
        </Chip>
      )}
      <Tags tags={task.tags} />
    </Styled.Tags>
  );
};

export default TaskSidebarTags;
