import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import * as Styled from './Feed.styled';
import FeedEntries from '../../../organisms/Projects/Home/WallEntries/FeedEntries';
import { FeedEntryType } from '../../../../@types/FeedEntry/FeedEntryType';
import api from '../../../../services/api';
import { ArrayResponseType } from '../../../../@types/hydra/hydra';
import { AuthContext } from '../../../../Context/auth-context/auth-context';
import Button from '../../../atoms/Button/Button';
import { Helmet } from 'react-helmet';

type Props = {};

const Feed: FunctionComponent<Props> = () => {
  const [entries, setEntries] = useState<FeedEntryType[]>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<string>();
  const { can } = useContext(AuthContext);

  const load = useCallback(() => {
    api
      .get<ArrayResponseType<FeedEntryType>>('/feed_entries', {
        params: {
          status: can('WALL.CAN_MANAGE') ? null : 'active',
          page: currentPage,
        },
      })
      .then((r) => {
        if (!r.data.hasOwnProperty('hydra:view')) {
          setLastPage('1');
        } else {
          setLastPage(r.data['hydra:view']['hydra:last'].split('page=')[1]);
        }

        setCurrentPage(currentPage + 1);
        if (entries) {
          setEntries([...entries, ...r.data['hydra:member']]);
          return;
        }
        setEntries(r.data['hydra:member']);
      });
  }, [currentPage, entries]);

  useEffect(() => {
    load();
    localStorage.setItem('Dashboard::lastVisited', '/projects/feed');
  }, []);

  useEffect(() => {
    if (window.location.href.includes('comments')) {
      const element = document.getElementById('firstFeedEntryComments');
      if (element && entries && entries?.length > 0) {
        const timeout = setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }, 1000);
        return () => {
          clearTimeout(timeout);
        };
      }
    }
  }, [entries]);

  return (
    <Styled.FeedStyled $narrow={'extra-narrow'}>
      <Helmet>
        <title>Feed | F.CAPE</title>
      </Helmet>
      <FeedEntries wallEntries={entries} onNewEntry={(e) => setEntries((entries) => [e, ...(entries || [])])} />
      {lastPage && currentPage <= parseInt(lastPage) && (
        <Button
          $text={'Load more'}
          onClick={() => {
            load();
          }}
          kind={'link'}
        />
      )}
    </Styled.FeedStyled>
  );
};

export default Feed;
