import styled, { css } from 'styled-components';
import { color, fontSize } from '../../../../../styles/Variables';
import { UiText } from '../../../../../styles/Typography';

export const Container = styled.div`
  width: 100%;
`;

export const Buttons = styled.div`
  display: flex;
  padding-bottom: 1.6rem;
  border-bottom: 1px solid ${color.neutral[40]};
  justify-content: flex-end;
  gap: 1.6rem;
`;

export const ProjectTasksGrid = css`
  display: grid;
  grid-template-columns: 4fr 1fr 1fr 2fr 1fr 1fr 1fr 1fr;
  align-items: center;
  column-gap: 0.8rem;
`;

export const Head = styled.div`
  ${UiText};
  font-size: ${fontSize.body.s};
  color: ${color.neutral[60]};
  padding: 2.4rem 0 1.6rem;
  border-bottom: 1px solid ${color.neutral[40]};
  ${ProjectTasksGrid};

  div:not(:nth-of-type(1)) {
    margin-left: 1rem;
  }

  div:nth-of-type(4) {
    margin-left: 0.6rem;
  }
  div:nth-of-type(5) {
    margin-left: 0.2rem;
  }
  div:nth-of-type(6) {
    margin-left: 0.3rem;
  }
`;
