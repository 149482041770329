import styled from 'styled-components';
import { color } from '../../../styles/Variables';

export const DropzoneOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${color.neutral[30]};
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border: 2px dashed ${color.neutral[50]};
`;
