import styled, { css } from 'styled-components';
import { fontSize, fontWeight } from '../../../../../../../../../styles/Variables';

type CustomInputProps = {
  $largeFont?: boolean;
};

export const CustomInput = styled.input<CustomInputProps>`
  width: 80%;
  border: none;
  background-color: transparent;
  transform: translateX(-8px);
  font-size: ${fontSize.body.xl};
  font-weight: ${fontWeight.bold};
  ${(props: CustomInputProps) =>
    props.$largeFont &&
    css`
      font-size: ${fontSize.heading.s};
      transform: translateX(0);
    `}
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;
