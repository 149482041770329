import { Cell } from './Common';
import styled from 'styled-components';
import { color, fontSize } from '../../../../../styles/Variables';
import Text from '../../../../atoms/Text';

export const TaskCell = styled(Cell)`
  background: ${({ $background }) => ($background ? $background : color.primary[40])};
  font-size: ${fontSize.body.s};
  gap: 0.3rem;
  justify-content: space-between;
  cursor: grab;
`;

export const Title = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
