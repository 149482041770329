import { TableFiltersType } from '../../../../../../legacy/CapeMorris/@types';
import FrequentFilters from '../../../../../../@types/Table/FrequentFilters';

const tableFilters: TableFiltersType = [
  FrequentFilters._search,
  {
    name: 'postedAt',
    type: 'month',
    label: 'Posted At',
    gridProps: {
      $desktop: 2,
    },
  },
  {
    name: 'downloadedAt',
    type: 'exists',
    label: 'Show downloaded',
    gridProps: {
      $desktop: 2,
    },
  },
];

export default tableFilters;
