import React, { FunctionComponent, PropsWithChildren, MouseEvent } from 'react';
import * as Styled from './TableCell.styled';

type Props = PropsWithChildren<{
  onClick?: React.EventHandler<MouseEvent>;
  colSpan?: number;
  style?: React.CSSProperties;
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | undefined;
}>;

const TableCell: FunctionComponent<Props> = ({ children, colSpan, onClick, justifyContent, style }) => {
  return (
    <Styled.Cell onClick={onClick} colSpan={colSpan} style={style} $clickable={!!onClick}>
      <Styled.CellContent $justifyContent={justifyContent}>{children}</Styled.CellContent>
    </Styled.Cell>
  );
};

export default TableCell;
