import React, { FunctionComponent, useCallback, useState } from 'react';
import Form from '../../Form/Form';
import SearchOption from '../../../../legacy/CapeMorris/components/Search/SearchOption';
import api from '../../../../services/api';
import { ChecklistTemplateType } from '../../../../@types/ChecklistTemplate/ChecklistTemplateType';
import useModal from '../../../../hooks/useModal';
import { ModalFooter } from '../../Modal/Modal';
import Button from '../../../atoms/Button/Button';
import Select from '../../Form/SelectInput/SelectInput';
import SnackContainer from '../../../../legacy/CapeMorris/components/snack-bar/use-snack-container';

type Props = {
  checklist: ChecklistTemplateType;
};

const AddToProjectModal: FunctionComponent<Props> = ({ checklist }) => {
  const { hideModal } = useModal();
  const { showSnack } = SnackContainer.useContainer();
  const [waiting, setWaiting] = useState(false);

  const handleSubmit = useCallback((data: { project: string }) => {
    setWaiting(true);
    api
      .post(`/wiki/checklist_templates/${checklist.id}/assign`, {
        project: data.project,
      })
      .then(() => {
        hideModal();
        showSnack({
          type: 'success',
          title: 'Checklist added to project',
          message: 'Checklist has been added to project',
        });
        setWaiting(false);
      });
  }, []);
  return (
    <Form onSubmit={handleSubmit}>
      {/*todo: refactor. Stworznie specjalnego komponentu ProjectSelect*/}
      <Select name={'project'} label={'Project'} baseUrl={'/projects'} optionComponent={SearchOption} />
      <ModalFooter>
        <Button $text={'Add to Project'} type="submit" waiting={waiting} />
      </ModalFooter>
    </Form>
  );
};

export default AddToProjectModal;
