export function convertMinutesToHours(minutes: number, withDays = false) {
  if (withDays) {
    const days = Math.floor(minutes / (8 * 60));
    minutes -= days * 8 * 60;
    const hours = Math.floor(minutes / 60);
    minutes -= hours * 60;

    let result = '';
    if (days) result += `${days}d `;
    if (hours) result += `${hours}h `;
    if (minutes) result += `${minutes}m`;
    return result.trim();
  }

  const hours = Math.floor(minutes / 60);
  minutes -= hours * 60;

  let result = '';
  if (hours) result += `${hours}h `;
  if (minutes) result += `${minutes}m`;
  return result.trim();
}
