import React, { FunctionComponent, useContext, useState } from 'react';
import Form from '../../../molecules/Form/Form';
import CostFormInner from './CostFormInner';
import useApi from '../../../../hooks/useApi';
import { useNavigate, useLocation } from 'react-router-dom';
import { CostType, CreateCostTypeDto } from '../../../../@types/Finances/Cost/CostType';
import { ResourceType } from '../../../../@types/Resource/ResourceType';
import { SidebarContext } from '../../../../Context/Sidebar/SidebarContext';

type Props = {
  cost?: CostType;
  onChange?: (cost: CostType) => void;
  isEdit?: boolean;
};

const defaults = {
  currency: 'PLN',
  subcosts: [
    {
      originalAmountNet: { value: undefined, currency: 'PLN' },
      originalAmountGross: { value: undefined, currency: 'PLN' },
    },
  ],
  payments: [],
};
const CostForm: FunctionComponent<Props> = ({ cost, isEdit = false }) => {
  const [attachments, setAttachments] = useState<ResourceType[]>([]); //
  const { post, put } = useApi();
  const navigate = useNavigate();
  const location = useLocation();
  const [working, setWorking] = useState(false);
  const { closeSidebar } = useContext(SidebarContext);

  const handleSubmit = (data: CreateCostTypeDto) => {
    closeSidebar();
    data.uploadedResources = attachments.map((resource) => resource.id);
    if (!data.payments) {
      data.payments = [];
    }
    if (!data.purchaseOrder) {
      data.purchaseOrder = null;
    }
    setWorking(true);
    if (isEdit && cost) {
      put(`${cost['@id']}`, data)
        .then((response) => {
          setWorking(false);
          navigate(-1);
        })
        .catch(() => {
          setWorking(false);
        });
    } else {
      post<CostType>('/api/costs', data)
        .then((response) => {
          setWorking(false);
          navigate(location.pathname.replace('create', `${response.data.id}/show`), { replace: true });
        })
        .catch(() => {
          setWorking(false);
        });
    }
  };

  return (
    <Form onSubmit={handleSubmit} defaultValues={cost ?? defaults}>
      {(methods) => <CostFormInner cost={cost} onUpload={setAttachments} working={working} />}
    </Form>
  );
};

export default CostForm;
