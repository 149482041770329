import React, { FunctionComponent, useCallback, useRef, useState } from 'react';
import * as Common from '../Common.styled';
import PlusIcon from 'Icons/Plus.icon';
import EditIcon from 'Icons/edit.icon';
import ShareIcon from 'Icons/Share.icon';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import { FloatingButton } from '../../../atoms/FloatingButton/FloatingButton';
import DropdownMenu from '../../../organisms/DropdownMenu/DropdownMenu';

type AddButtonTypes = {
  add: { [key: string]: () => void };
};

const AddButton: FunctionComponent<AddButtonTypes> = ({ add }) => {
  const [open, setOpen] = useState<boolean>(false);

  const closePopover = useCallback(() => {
    setOpen(false);
  }, []);

  const containerRef = useRef(null);
  useOutsideClick(containerRef, closePopover);

  return (
    <FloatingButton id={'floating-button-add-main'}>
      <DropdownMenu
        childrenLeft
        menuItems={[
          {
            text: 'Add project',
            id: 'floating-button-add-project',
            onClick: () => {
              add.project();
            },
            icon: <EditIcon size={1.1} />,
          },
          {
            text: 'Add task',
            id: 'floating-button-add-task',
            onClick: () => {
              add.task();
            },
            icon: <ShareIcon size={1.1} />,
          },
        ]}
      >
        <Common.Button>
          <PlusIcon size={2} />
        </Common.Button>
      </DropdownMenu>
    </FloatingButton>
  );
};

export default AddButton;
