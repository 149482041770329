import React, { FunctionComponent, useEffect, useState } from 'react';
import { Grid, GridItem } from 'components/atoms/Grid/Grid';
import { ResourceType } from '../../../@types/Resource/ResourceType';
import Dropzone from '../../molecules/Dropzone/Dropzone';
import useApi from '../../../hooks/useApi';
import { FileContextType } from '../../../@types/File/File';
import Resources from './Resources';

type Props = {
  subject?: string;
  subjectId?: string | number;
  context?: FileContextType;
  maxFiles?: number;
  onChange?: (resources: ResourceType[], newResources: ResourceType[]) => void;
  resources?: ResourceType[];
};

const ResourceDropzone: FunctionComponent<Props> = ({ onChange, resources, subject, subjectId, context = 'project', maxFiles = 1 }) => {
  const [uploadedResources, setUploadedResources] = useState<ResourceType[]>(resources ?? []);
  const [newResources, setNewResources] = useState<ResourceType[]>([]);
  const [init, setInit] = useState(false);

  const { post } = useApi();
  const [token, setToken] = useState<number>(0);

  const handleUpload = (uuid: string) => {
    post<ResourceType>(
      '/resources',
      {
        fileUuid: uuid,
        subject: subject,
        subjectId: subjectId ? `${subjectId}` : null,
      },
      true,
    ).then(({ data }) => {
      if (subjectId) {
        setToken(Math.random());
      } else {
        setUploadedResources((prev) => [...prev, data]);
        setNewResources((prev) => [...prev, data]);
      }
    });
  };

  useEffect(() => {
    if (!init) {
      setInit(true);
      return;
    }
    onChange && onChange(uploadedResources, newResources);
  }, [uploadedResources, newResources]);

  return (
    <Grid spacing={1.5} $simple>
      <GridItem $desktop={12}>
        <Dropzone maxFiles={maxFiles} onUpload={handleUpload} text={'Upload file from your computer'} context={context} />
      </GridItem>
      <GridItem>
        <Resources subject={subject} subjectId={subjectId} disableCreation maxWidth reloadToken={token} customResources={uploadedResources} />
      </GridItem>
    </Grid>
  );
};

export default ResourceDropzone;
