import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import * as Styled from './Heading.styled';

type Props = {
  children: ReactNode | ReactElement;
  level?: 1 | 2 | 3 | 4;
  size?: 'l' | 'm' | 's';
  color?: string;
  noPadding?: boolean;
  className?: string;
};

const Heading: FunctionComponent<Props> = ({ level = 2, size = 'm', children, color, noPadding, className }) => {
  return (
    <Styled.Heading className={className} $size={size} as={`h${level}`} $color={color} $noPadding={noPadding}>
      {children}
    </Styled.Heading>
  );
};

export default Heading;
