import React, { FunctionComponent, MouseEvent, ReactElement, useCallback, useMemo } from 'react';
import * as Styled from './Notification.styled';
import { NotificationType } from '../../../@types/Notification/NotificationType';
import { Link } from 'react-router-dom';
import DateString from '../../atoms/DateString/DateString';
import Text from '../../atoms/Text';
import Avatar from '../../atoms/Avatar/Avatar';
import CommentNotification from './NotificationContent/CommentNotification';
import AbsenceNotification from './NotificationContent/AbsenceNotification';
import InventoryNotification from './NotificationContent/InventoryNotification';
import Person from '../Person/Person';
import DefaultNotification from './NotificationContent/DefaultNotification';
import FinanceNotification from './NotificationContent/FinanceNotification';

type Props = {
  notification: NotificationType;
  onRead: (notification: NotificationType, close: boolean, toggle: boolean) => void;
};

export type NotificationAction = {
  [key: string]: string;
};
export const getNotificationAction = (notification: NotificationType): NotificationAction | null => {
  if (!notification.action) {
    return null;
  }
  const parts = notification.action.split('/');

  return parts.reduce((obj, part) => {
    obj[part.split(':')[0]] = part.split(':')[1];
    return obj;
  }, {} as NotificationAction);
};

const Notification: FunctionComponent<Props> = ({ notification, onRead }) => {
  const markAsRead = useCallback(
    (e: MouseEvent, close = true, toggle = false, preventDefault = false) => {
      preventDefault && e.preventDefault();
      e.stopPropagation();
      onRead(notification, close, toggle);
    },
    [notification, onRead],
  );

  const timeComponent = (
    <Styled.Time>
      <Text size={'xs'} color={'grey'} bold>
        <DateString input={notification.createdAt} fromNow />
      </Text>
    </Styled.Time>
  );

  const indicatorComponent = <Styled.Indicator $isRead={notification.isRead} onClick={(e) => markAsRead(e, false, true, true)} />;

  const guessNotificationContentType = useMemo(() => {
    if (['absence_accept', 'absence_reject', 'absence_cancel', 'absence_create'].includes(notification.notificationType)) {
      return <AbsenceNotification notification={notification} timeComponent={timeComponent} indicatorComponent={indicatorComponent} />;
    }
    if (['inventory_confirmed', 'inventory_release', 'inventory_return', 'inventory_warranty'].includes(notification.notificationType)) {
      return <InventoryNotification notification={notification} timeComponent={timeComponent} indicatorComponent={indicatorComponent} />;
    }
    if (['comment', 'mention'].includes(notification.notificationType)) {
      return <CommentNotification notification={notification} timeComponent={timeComponent} indicatorComponent={indicatorComponent} />;
    }
    if (['cost_sign', 'purchase_order_sign'].includes(notification.notificationType)) {
      return (
        <FinanceNotification
          notification={notification}
          timeComponent={timeComponent}
          indicatorComponent={indicatorComponent}
          title={notification.notificationType === 'cost_sign' ? 'Cost' : 'Purchase Order'}
        />
      );
    }
    return <DefaultNotification notification={notification} timeComponent={timeComponent} indicatorComponent={indicatorComponent} />;
  }, [notification, markAsRead, indicatorComponent]);

  const action = notification.actions?.[0]?.relativeUrl;
  return (
    <>
      {action && (
        <Link to={action} onClick={markAsRead}>
          <InnerNotification notification={notification} guessNotificationContentType={guessNotificationContentType} />
        </Link>
      )}
      {!action && <InnerNotification notification={notification} guessNotificationContentType={guessNotificationContentType} />}
    </>
  );
};

const InnerNotification: FunctionComponent<{
  notification: NotificationType;
  guessNotificationContentType: ReactElement;
}> = ({ notification, guessNotificationContentType }) => {
  return (
    <Styled.Container $isRead={notification.isRead}>
      <Styled.InnerContainer>
        <Styled.InnerContainer>
          <Styled.Content>{guessNotificationContentType}</Styled.Content>
        </Styled.InnerContainer>
        <Styled.AvatarWrapper>
          {notification.author ? <Person person={notification.author} hideName size={4.8} /> : <Avatar name={'CAPE FLEXIBLE'} size={4.8} />}
        </Styled.AvatarWrapper>
      </Styled.InnerContainer>
    </Styled.Container>
  );
};

export default Notification;
