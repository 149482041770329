import styled, { css } from 'styled-components';

type WrapperProps = {
  $width?: number;
};
export const TimeProgressStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

export const ProgressWrapper = styled.div<WrapperProps>`
  display: grid;
  align-items: center;
  grid-template-columns: 5fr 1fr;
  gap: 0.8rem;

  ${(props: WrapperProps) =>
    props.$width &&
    css`
      width: ${props.$width}rem;
    `}
`;

export const HoursWrapper = styled.div`
  display: flex;

  p {
    white-space: nowrap;
    word-wrap: break-word;
    overflow: auto;
  }
`;
