import React from 'react';
import { ModuleContextType, ModuleType } from '../../@types/Module/Module';

const ModuleContext = React.createContext<ModuleContextType>({
  state: {
    currentModule: undefined,
  },
  setModule: (module: ModuleType) => {
    return;
  },
});

export default ModuleContext;
