import React, { FunctionComponent, useContext, useMemo } from 'react';
import { TaskType } from '../../../../@types/Task/TaskType';
import properties from '../../Tasks/Tasks.properties';
import filters from '../../Tasks/Tasks.filters';
import TablePage from '../../../organisms/TablePage/TablePage';
import TaskFormContext from '../../../../Context/TaskFormContext/TaskFormContext';
import Button from '../../../atoms/Button/Button';
import useUser from '../../../../hooks/useUser';
import useTaskSidebar from '../../../../hooks/useTaskSidebar';
import PlusIcon from '../../../../Icons/Plus.icon';
import TaskAssigneeTemplate from '../../../molecules/CellTemplates/Projects/Tasks/TaskAssigneeTemplate';

type Props = {};

const MyTeamTasks: FunctionComponent<Props> = (props) => {
  const { openLongForm } = useContext(TaskFormContext);
  const user = useUser();
  const { openTask } = useTaskSidebar();

  const assignedTasksFilters = useMemo(() => {
    const result = [...filters];
    result.splice(result.length - 2, 0, {
      type: 'employee',
      name: 'assignee.id',
      label: 'Assignee',
      options: {
        group: 'organisation',
        all: true,
      },
      gridProps: {
        $desktop: 3,
      },
    });
    return result;
  }, [filters]);

  const assignedTasksProperties = [
    ...properties,
    {
      name: 'assignee',
      visibleName: 'Assignee',
      template: TaskAssigneeTemplate,
      sortable: true,
      templateOptions: {
        textProperties: {
          size: 's',
          color: 'grey',
          bold: true,
        },
      },
    },
  ];
  return (
    <TablePage<TaskType>
      url={'/tasks'}
      heading={'My team tasks'}
      context={'/api/tasks'}
      properties={assignedTasksProperties}
      filters={assignedTasksFilters}
      globalFilters={{
        'assignee.department.id': user.department.id,
        _archived: 0,
      }}
      headingRight={
        <Button id={'open-task-form'} $text={'Add task'} kind={'primary'} onClick={openLongForm as () => void} $icon={<PlusIcon />} iconSize={1.2} />
      }
      onItemClick={(item) => {
        openTask(item['@id']);
      }}
    />
  );
};

export default MyTeamTasks;
