import React, { FunctionComponent, useMemo, useState } from 'react';
import { TaskListType, TaskType } from '../../../../../@types/Task/TaskType';
import TasksCardItem from '../DashboardCardItems/TasksCardItem';
import Text from '../../../../atoms/Text/Text';
import * as Styled from './ContentCard.styled';
import useTasksProvider from '../../../../../hooks/useTasksProvider';
import { Loader } from '../../../../../legacy/CapeMorris/components';
import TaskInlineForm from 'components/molecules/Task/TaskInlineForm/TaskInlineForm';
import { MeetingType } from '../../../../../@types/Meeting/MeetingType';
import MeetingItem from '../DashboardCardItems/MeetingItem';

type Props = {
  tasks?: TaskType[] | undefined;
  filters?: any;
  meetings?: MeetingType[] | undefined;
  ctx?: any;
  additionalFilterFunction?: (tasks: TaskListType[] | undefined) => TaskListType[];
  taskFormType?: 'short' | 'inline' | 'none';
  customUrl?: string;
};

const TaskCardsContentCard: FunctionComponent<Props> = ({ ctx, filters, meetings, additionalFilterFunction, taskFormType, customUrl }) => {
  const { data: tasks, isLoading } = useTasksProvider(ctx, filters, customUrl);
  const [createdTasks, setCreatedTasks] = useState<TaskListType[]>([]);
  const filteredData = useMemo(() => {
    if (!tasks) {
      return createdTasks;
    }
    const result = additionalFilterFunction ? additionalFilterFunction(tasks) : tasks;
    return result.filter((task) => !createdTasks.find((createdTask) => createdTask.id === task.id));
  }, [additionalFilterFunction, tasks, createdTasks]);

  const handleCreate = (task: TaskType) => {
    if (taskFormType !== 'inline') {
      return;
    }
    setCreatedTasks((p) => [task, ...p]);
  };

  const handleUpdate = (task: TaskListType) => {
    if (taskFormType !== 'inline') {
      return;
    }
    setCreatedTasks((p) => p.map((t) => (t.id === task.id ? task : t)));
  };

  const sortData = (filteredData: TaskListType[]) => {
    if (filters?.['order[deadline]']) {
      return filteredData;
    }

    return filteredData.sort((a, b) => {
      if (!a.deadline && !b.deadline) {
        return 0;
      } else if (!a.deadline) {
        return -1;
      } else if (!b.deadline) {
        return 1;
      } else {
        // @ts-ignore
        return new Date(a.deadline) - new Date(b.deadline); // Sort deadlines in ascending order
      }
    });
  };

  return (
    <>
      <Styled.ItemsColumnWrapper $scrollable={true}>
        {taskFormType === 'inline' && <TaskInlineForm onCreate={handleCreate} />}
        {isLoading && <Loader />}
        {filteredData?.length === 0 && createdTasks.length === 0 && <Text color={'grey'}>No items to display</Text>}
        {meetings && meetings.sort((a, b) => (a.start > b.start ? 1 : -1)).map((m: MeetingType, idx: number) => <MeetingItem meeting={m} key={idx} />)}
        {createdTasks && createdTasks.map((task, index) => <TasksCardItem task={task} key={index} onUpdate={handleUpdate} />)}
        {filteredData && sortData(filteredData).map((task, index) => <TasksCardItem task={task} key={index} />)}
      </Styled.ItemsColumnWrapper>
    </>
  );
};

export default TaskCardsContentCard;
