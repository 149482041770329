import React, { FunctionComponent, useCallback } from 'react';
import { Grid, GridItem } from '../../../../../components/atoms/Grid/Grid';
import Button from '../../../../../components/atoms/Button/Button';
import IriHelper from '../../../../../helpers/iri-helper';
import { createFullApiUrl } from '../../../../../services/api';
import { FinancialCompanyContext } from '../../../../../Context/BranchContext/FinancialCompanyContext';

type Props = {
  context: 'costs' | 'invoices';
  selectedItems: any[];
  onDownload?: () => void;
  invalidateCallback?: Function;
};

const HeadingRight: FunctionComponent<Props> = ({ context, selectedItems, onDownload, invalidateCallback }) => {
  const {
    state: { financialCompany },
  } = React.useContext(FinancialCompanyContext);

  const handleDownloadSelected = useCallback(() => {
    const link = document.createElement('a');
    link.href = selectedItems.reduce(
      (url, item: { '@id': string }) => {
        url += 'ids[]=' + IriHelper.iriToId(item['@id']) + '&';
        return url;
      },
      createFullApiUrl(`/exchange/${context}/download?`),
    );
    link.download = 'download';
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setTimeout(() => {
      onDownload && onDownload();
      invalidateCallback && invalidateCallback();
    }, 1000);
  }, [selectedItems, context]);

  const handleDownloadNew = React.useCallback(() => {
    if (!financialCompany) {
      return;
    }
    const link = document.createElement('a');
    link.href = createFullApiUrl(`/exchange/new_${context}/download`) + `?financialCompany=${IriHelper.iriToId(financialCompany['@id'])}`;
    link.download = 'download';
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setTimeout(() => {
      onDownload && onDownload();
      invalidateCallback && invalidateCallback();
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [financialCompany]);

  return (
    <Grid $align={'center'} spacing={2}>
      {selectedItems.length > 0 && (
        <GridItem>
          <Button kind={'primary'} onClick={handleDownloadSelected} $text={'Download selected'} />
        </GridItem>
      )}
    </Grid>
  );
};

export default HeadingRight;
