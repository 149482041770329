import styled, { css } from 'styled-components';
import { BodyText } from '../../../styles/Typography';
import { color, color as colors, fontSize, fontWeight } from '../../../styles/Variables';
import { ChipColor } from './BetterChip';

// function returning white or black based on background color
const getContrast = (background: string) => {
  background = background.replace('#', '');
  const r = parseInt(background.substr(0, 2), 16);
  const g = parseInt(background.substr(2, 2), 16);
  const b = parseInt(background.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? color.neutral[80] : color.white;
};

export const InnerChip = styled.span<{
  $color: ChipColor;
  $customBackgroundColor?: string;
  $rounded?: boolean;
}>`
  ${BodyText};
  display: inline-flex;
  align-items: center;
  gap: 0.3rem;
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.body.s};
  border-radius: ${(props) => (props.$rounded ? '10em' : '0.25em')};
  padding: 0.2rem 0.6rem;
  white-space: nowrap;
  ${({ $color }) => [
    $color === 'neutral' &&
      css`
        color: ${colors.neutral[60]};
        background-color: ${colors.neutral[40]};
      `,
    $color === 'primary' &&
      css`
        color: ${colors.primary[60]};
        background-color: ${colors.primary[30]};
      `,
    $color === 'secondary' &&
      css`
        color: ${colors.secondary[70]};
        background-color: ${colors.secondary[20]};
      `,
    $color === 'success' &&
      css`
        color: ${colors.secondary[70]};
        background-color: ${colors.secondary[20]};
      `,
    $color === 'error' &&
      css`
        color: ${colors.semantic.error[80]};
        background-color: ${colors.semantic.error[40]};
      `,
    $color === 'warning' &&
      css`
        color: ${colors.semantic.warning[80]};
        background-color: ${colors.semantic.warning[40]};
      `,
    $color === 'information' &&
      css`
        color: ${colors.primary[60]};
        background-color: ${colors.primary[30]};
      `,
  ]}
  ${({ $customBackgroundColor }) =>
    $customBackgroundColor &&
    css`
      background-color: ${$customBackgroundColor};
      color: ${getContrast($customBackgroundColor)};
    `}
`;
