import styled from 'styled-components';
import { color } from '../../../../styles/Variables';

type AnimatedWrapperProps = {
  areCommentsVisible: boolean;
};

export const BottomWrapper = styled.div<{ $isDashboard: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 3rem;
  padding: 1.6rem 0;
  border-top: 1px solid ${color.neutral['40']};
  border-bottom: 1px solid ${color.neutral['40']};
`;

export const AnimatedWrapper = styled.div<AnimatedWrapperProps>`
  display: ${(props) => (props.areCommentsVisible ? 'flex' : 'none')};
  overflow: hidden;
  max-height: ${(props) => (props.areCommentsVisible ? 'fit-content' : '1px')};
  transition: max-height 0.3s ease-in-out;
`;
