import React from 'react';

export const CloseIcon: React.FC = () => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M8.86006 8.00075L15.7746 1.06771C16.0181 0.823613 16.0181 0.427864 15.7746 0.183802C15.5312 -0.0602596 15.1365 -0.0602909 14.8931 0.183802L7.97847 7.11685L1.06389 0.183802C0.820449 -0.0602909 0.425752 -0.0602909 0.182339 0.183802C-0.061074 0.427895 -0.0611052 0.823644 0.182339 1.06771L7.09692 8.00072L0.182339 14.9338C-0.0611052 15.1779 -0.0611052 15.5736 0.182339 15.8177C0.304045 15.9397 0.463588 16.0007 0.623132 16.0007C0.782674 16.0007 0.942186 15.9397 1.06392 15.8177L7.97847 8.88465L14.8931 15.8177C15.0148 15.9397 15.1743 16.0007 15.3338 16.0007C15.4934 16.0007 15.6529 15.9397 15.7746 15.8177C16.0181 15.5736 16.0181 15.1778 15.7746 14.9338L8.86006 8.00075Z"
          fill="#0C0C0C"
        />
      </g>
      <defs>
        <rect width="15.9574" height="16" fill="white" />
      </defs>
    </svg>
  );
};

export default CloseIcon;
