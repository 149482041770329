import React, { FunctionComponent, useContext, useRef } from 'react';
import * as Styled from './SidebarContainer.styled';
import { SidebarContext } from '../../../Context/Sidebar/SidebarContext';

const SidebarContainer: FunctionComponent = () => {
  const { isOpen, body, isPdfContext } = useContext(SidebarContext);
  const ref = useRef(null);

  return (
    <Styled.Sidebar $full={false} $visible={isOpen} ref={ref} $pdfContext={isPdfContext}>
      <Styled.Body>{body}</Styled.Body>
    </Styled.Sidebar>
  );
};

export default SidebarContainer;
