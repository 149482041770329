import React from 'react';
import { TableProperties } from '../../../../../../@types/Table/TableProperty';
import PersonTemplate from '../../../../../../components/molecules/CellTemplates/common/PersonTemplate/PersonTemplate';
import { InventoryAllocationType } from '../../../../../../@types/Inventory/InventoryAllocationType';
import ProtocolIdTemplate from '../../../../../../components/molecules/CellTemplates/Admin/ProtocolIdTemplate';
import DateTemplate from '../../../../../../components/molecules/CellTemplates/common/DateTemplate/DateTemplate';

const protocolsTableProperties: TableProperties<InventoryAllocationType> = [
  {
    name: '@id',
    visibleName: 'ID',
    template: ProtocolIdTemplate,
  },

  {
    name: 'createdAt',
    visibleName: 'Creation date',
    template: DateTemplate,
    templateOptions: {
      textProperties: {
        shorten: 10,
        size: 'm',
        color: 'grey',
        bold: true,
      },
    },
  },
  {
    name: 'person',
    visibleName: 'Assignee',
    template: PersonTemplate,
    templateOptions: {
      areInitials: false,
      textProperties: {
        shorten: 10,
        size: 'm',
        color: 'darkGrey',
        bold: true,
      },
    },
  },
  {
    name: 'createdBy',
    visibleName: 'Creator',
    template: PersonTemplate,
    templateOptions: {
      areInitials: false,
      textProperties: {
        shorten: 10,
        size: 'm',
        color: 'grey',
        bold: true,
      },
    },
  },
];

export default protocolsTableProperties;
