import { css } from 'styled-components';
import { color, fontSize, lineHeight } from './Variables';

export const BodyText = css`
  font-size: ${fontSize.body.m};
  color: ${color.neutral[80]};
  line-height: ${lineHeight.body.m};
`;

export const UiText = css`
  ${BodyText};
  line-height: ${lineHeight.ui.m};
`;
