import React, { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { InputProps } from '../../../../@types/Form/InputProps';
import { DictValue } from '../../../../@types/Dictionary/DictValue';
import Checkbox from './Checkbox';

type Props = InputProps & {
  options: DictValue[];
};

const CheckboxGroup: FunctionComponent<Props> = ({ name, options, ...props }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value } }) => (
        <div>
          {options.map((option) => (
            <Checkbox selected={value?.includes(option.value)} key={option.value} name={name} label={option.label} value={option.value} />
          ))}
        </div>
      )}
    />
  );
};

export default CheckboxGroup;
