import styled from 'styled-components';
import { color } from '../../../styles/Variables';

export const HistoryEntryStyled = styled.div`
  width: 100%;
  display: flex;
  background-color: ${color.neutral[20]};
  border: 1px solid ${color.neutral[80]};
  padding: 1.6rem;
  border-radius: 0.8rem;
  flex-direction: column;
`;

export const InlineWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
  margin-left: 3.8rem;
  max-width: fit-content;
`;
export const EntryTitle = styled.div`
  * {
    display: inline-block;
  }
`;
