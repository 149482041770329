import React, { FunctionComponent } from 'react';
import { DayEventType } from './DayEventType';
import * as Styled from './Day.styled';
import DayEvent from './DayEvent';
import TaskCell from './TaskCell';
import { Droppable } from '@hello-pangea/dnd';
import { TaskListType } from '../../../../../@types/Task/TaskType';
import Meeting from '../../MyWorkTask/Meeting';
import { MeetingType } from '../../../../../@types/Meeting/MeetingType';
import { TaskEntryType } from '../../../../../@types/Task/TaskEntryType';

type Props = {
  day: Date;
  dayEvent?: DayEventType;
  taskEntries: TaskEntryType[];
  areConflicts?: boolean;
  meetings: MeetingType[];
};

const Day: FunctionComponent<Props> = ({ dayEvent, taskEntries, day, areConflicts, meetings, ...props }) => {
  return (
    <Droppable droppableId={day.toISOString()} isDropDisabled={!!dayEvent}>
      {(provided) => (
        <Styled.Day ref={provided.innerRef} {...provided.droppableProps}>
          {dayEvent && <DayEvent dayEvent={dayEvent} areConflicts={areConflicts} />}
          <>
            {areConflicts && <div style={{ marginTop: '8rem' }} />}
            {meetings.map((meeting, index) => (
              <Meeting meeting={meeting} key={meeting['@id']} isOverlapped={false} position={'left'} />
            ))}
            {taskEntries.map((entry, index) => (
              <TaskCell
                context={'entry'}
                draggableId={entry['@id']}
                task={entry.task}
                key={entry['@id']}
                index={index}
                duration={entry.duration}
                entry={entry}
              />
            ))}
          </>
          {provided.placeholder}
        </Styled.Day>
      )}
    </Droppable>
  );
};

export default Day;
