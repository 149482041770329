import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './TaskLongForm.styled';
import TaskFormContext from '../../../../../Context/TaskFormContext/TaskFormContext';
import useApi from '../../../../../hooks/useApi';
import Form from '../../../../molecules/Form/Form';
import TaskNameInput from '../../../../molecules/Task/TaskForm/TaskNameInput/TaskNameInput';
import InlineInput from '../../../../molecules/Task/TaskForm/InlineInput/InlineInput';
import PersonIcon from '../../../../../Icons/Project/Person.icon';
import TaskEmployeeDropdown from '../../../../molecules/Task/TaskForm/TaskEmployeeDropdown/TaskEmployeeDropdown';
import TaskProjectDropdown from '../../../../molecules/Task/TaskForm/TaskProjectDropdown/TaskProjectDropdown';
import { DictValue } from '../../../../../@types/Dictionary/DictValue';
import { ProjectType } from '../../../../../@types/Project/Project';
import CloseButton from '../../../../atoms/CloseButton/CloseButton';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import PriorityIcon from '../../../../../Icons/Project/Priority.icon';
import TaskPriorityDropdown from '../../../../molecules/Task/TaskForm/TaskPriorityDropdown/TaskPriorityDropdown';
import CalendarIcon from '../../../../../Icons/Calendar.icon';
import TaskDeadlineDropdown from '../../../../molecules/Task/TaskForm/TaskDeadlineDropdown/TaskDeadlineDropdown';
import EstimationIcon from '../../../../../Icons/Project/Estimation.icon';
import TaskEstimationDropdown from '../../../../molecules/Task/TaskForm/TaskEstimationDropdown/TaskEstimationDropdown';
import RichTextInput from '../../../../molecules/Form/RichTextInput/RichTextInput';
import Button from '../../../../atoms/Button/Button';
import TextareaInput from '../../../../molecules/Task/TaskForm/TextareaInput/TextareaInput';
import DateString from 'components/atoms/DateString/DateString';
import PriorityTag from 'components/molecules/PriorityTag/PriorityTag';
import { TaskType } from '../../../../../@types/Task/TaskType';
import Text from '../../../../atoms/Text';
import { CloseButtonContainer, Column, InnerContainer, Line, Row } from '../CommonStyles';
import AcceptIcon from '../../../../../Icons/Accept.icon';
import TaskDependenciesSelect from '../../../../molecules/Task/TaskForm/TaskDependenciesSelect/TaskDependenciesSelect';
import { convertMinutesToHours } from '../../../../../helpers/convertMinutesToHours';
import TaskSectionDropdown from '../../../../molecules/Task/TaskForm/TaskSectionDropdown/TaskSectionDropdown';
import Resources from '../../../Resources/Resources';
import { ResourceType } from '../../../../../@types/Resource/ResourceType';
import api from '../../../../../services/api';
import TagsInput from '../../../../molecules/Form/TagsInput/TagsInput';
import { ArrayResponseType } from '../../../../../@types/hydra/hydra';

type Props = {};

type TaskDataType = {
  name: string;
  description: string;
  assignee: string;
  project: string;
};

const TaskLongForm: FunctionComponent<Props> = (props) => {
  const { closeForm, defaults } = useContext(TaskFormContext);
  const [loading, setLoading] = useState(false);
  const [resources, setResources] = useState<any>([]);

  const loadResources = (subject: string, subjectId: string) => {
    api
      .get<ArrayResponseType<ResourceType>>('/resources', {
        params: {
          subject,
          subjectId,
        },
      })
      .then((response) => {
        setResources(response.data['hydra:member']);
      });
  };

  const saveResources = (subject: string, subjectId: number, resources: ResourceType[]) => {
    const newResources = resources.map((resource) => {
      if (resource.file?.id) {
        return {
          name: resource.name,
          type: resource.type,
          subject: 'Task',
          subjectId: subjectId.toString(),
          fileUuid: resource.file?.id ? resource.file.uuid : '',
        };
      } else {
        return {
          name: resource.name,
          type: resource.type,
          url: resource.url ?? null,
          subject: 'Task',
          subjectId: subjectId.toString(),
        };
      }
    });
    newResources.forEach((resource) => {
      void api.post('/resources', resource);
    });
  };

  useEffect(() => {
    if (defaults && defaults['@id'] && defaults.id) {
      loadResources('Task', defaults.id.toString());
    }
  }, [defaults, defaults.id]);

  const internalDefaults = useMemo(() => {
    return {
      ...defaults,
      project: defaults?.project
        ? {
            meta: defaults?.project,
            value: defaults?.project['@id'],
            label: defaults?.project.name,
          }
        : null,
      assignee: defaults?.assignee
        ? {
            meta: defaults?.assignee,
            value: defaults?.assignee['@id'],
            label: defaults?.assignee.name,
          }
        : null,
      section: defaults?.section
        ? {
            meta: defaults?.section,
            value: defaults?.section['@id'],
            label: defaults?.section.name,
          }
        : null,
      reviewer: defaults?.reviewer
        ? {
            meta: defaults?.reviewer,
            value: defaults?.reviewer['@id'],
            label: defaults?.reviewer.name,
          }
        : null,
      expectedStart: defaults.expectedStart
        ? {
            value: defaults?.expectedStart,
          }
        : null,
      deadline: defaults.deadline
        ? {
            value: defaults?.deadline,
          }
        : null,
      priority: defaults.priority
        ? {
            value: defaults?.priority,
          }
        : null,
      estimate: defaults?.estimate
        ? {
            value: defaults.estimate,
            label: convertMinutesToHours(Number(defaults.estimate)),
          }
        : null,
    };
  }, [defaults]);

  const { post, put } = useApi();

  const handleSubmit = useCallback(
    (data: TaskDataType) => {
      setLoading(true);
      if (!defaults['@id']) {
        post('/api/tasks', data)
          .then((response) => {
            if (resources.length > 0) saveResources('Task', response.data.id, resources);
            closeForm();
            setLoading(false);
          })
          .catch(() => setLoading(false));
      } else {
        put(`${defaults['@id']}`, data)
          .then(() => {
            closeForm();
            setLoading(false);
          })
          .catch(() => setLoading(false));
      }
    },
    [resources],
  );

  return (
    <Styled.Background>
      <Styled.LongFormContainer onClick={(e) => e.stopPropagation()}>
        <Form onSubmit={handleSubmit} defaultValues={internalDefaults}>
          {({ watch }) => {
            const project = watch('project');
            return (
              <InnerContainer>
                <TaskNameInput />
                <Row>
                  <Column>
                    <Text bold color={'grey'}>
                      For
                    </Text>
                    <InlineInput
                      name={'assignee'}
                      icon={<PersonIcon />}
                      dropdown={TaskEmployeeDropdown}
                      parseValue={(v: DictValue<TaskType['assignee']>) => <>{v.label}</>}
                    />
                  </Column>
                  <Column>
                    <Text bold color={'grey'}>
                      in project
                    </Text>
                    <InlineInput
                      name={'project'}
                      shape={'square'}
                      dropdown={TaskProjectDropdown}
                      parseValue={(v: DictValue<ProjectType>) => (
                        <Line>
                          <Text
                            color={'primary'}
                            style={{
                              display: 'inline',
                              textWrap: 'nowrap',
                              marginRight: '0.35rem',
                            }}
                            bold
                          >
                            #{v.meta?.id}
                          </Text>
                          <Text
                            color={'primary'}
                            bold
                            style={{
                              display: 'inline',
                              textWrap: 'nowrap',
                            }}
                          >
                            {v.label}
                          </Text>
                        </Line>
                      )}
                    />
                  </Column>
                </Row>
                <Grid spacing={2}>
                  <GridItem $desktop={6}>
                    <InlineInput name={'section'} label={'Section'} dropdown={TaskSectionDropdown} />
                  </GridItem>
                  <GridItem $desktop={6}>
                    <InlineInput
                      label={'Add reviewer'}
                      name={'reviewer'}
                      icon={<PersonIcon />}
                      dropdown={TaskEmployeeDropdown}
                      parseValue={(v: DictValue<TaskType['reviewer']>) => <>{v.label}</>}
                    />
                  </GridItem>
                  <GridItem $desktop={6}>
                    <InlineInput
                      label={'Priority'}
                      name={'priority'}
                      icon={<PriorityIcon />}
                      dropdown={TaskPriorityDropdown}
                      parseValue={(v: DictValue<TaskType['priority']>) => <PriorityTag priority={v.value} />}
                    />
                  </GridItem>
                  <GridItem $desktop={6}>
                    <InlineInput
                      label={'Due date'}
                      name={'deadline'}
                      icon={<CalendarIcon />}
                      dropdown={TaskDeadlineDropdown}
                      parseValue={(v: DictValue<TaskType['deadline']>) => <DateString input={v.value as string} />}
                    />
                  </GridItem>
                  <GridItem $desktop={6}>
                    <InlineInput label={'Time estimation'} name={'estimate'} icon={<EstimationIcon />} dropdown={TaskEstimationDropdown} preventDefault />
                  </GridItem>
                  {project && (
                    <GridItem $desktop={12}>
                      <TaskDependenciesSelect project={project} />
                    </GridItem>
                  )}
                  <GridItem $desktop={12}>
                    <TagsInput name="tags" baseUrl={'/task_tags'} />
                  </GridItem>
                </Grid>

                <Grid spacing={2}>
                  <GridItem $desktop={12} style={{ maxHeight: '50vh', height: '50rem' }}>
                    <RichTextInput name={'description'} label={'Description'} />
                  </GridItem>
                </Grid>
                <Grid spacing={2}>
                  <GridItem $desktop={12}>
                    <TextareaInput name={'trafficComment'} placeholder={'Should Traffic Manager be aware of anything?'} />
                  </GridItem>
                </Grid>
                {defaults && defaults.id && (
                  <Grid>
                    <GridItem $desktop={12}>
                      <Resources subject={'Task'} subjectId={defaults.id} editable={resources.length === 0} disableCreation={true} maxWidth />
                    </GridItem>
                  </Grid>
                )}
                <Row
                  style={{
                    justifyContent: 'space-between',
                    paddingBottom: '3.2rem',
                  }}
                >
                  <Button $text={defaults['@id'] ? 'Save' : 'Add task'} type={'submit'} waiting={loading} $icon={<AcceptIcon />} />
                  <Button $text={'Cancel'} kind={'link'} onClick={closeForm} />
                </Row>
              </InnerContainer>
            );
          }}
        </Form>
        <CloseButtonContainer>
          <CloseButton onClick={closeForm} />
        </CloseButtonContainer>
      </Styled.LongFormContainer>
    </Styled.Background>
  );
};

export default TaskLongForm;
