import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import api from '../../../../services/api';
import { findFriday, findMonday, getDayName, getNextWeek, getPreviousWeek } from '../../../../helpers/getDaysForWeekMethods';
import * as Styled from './MyWorkCalendar.styled';
import Text from '../../../atoms/Text';
import { parseDate } from '../../../../helpers/date';
import { Icon } from '../../../atoms/Icon/Icon';
import CalendarIcon from '../../../../Icons/Calendar.icon';
import { ArrowIcon } from '../../../../Icons/arrow.icon';
import { MeetingType } from '../../../../@types/Meeting/MeetingType';
import serverEvents from '../../../../services/serverEvents';
import { color } from '../../../../styles/Variables';
import { DayEventType } from './components/DayEventType';
import Day from './components/Day';
import Backlog from './components/Backlog';
import { loadDayEvents } from './functions/loadDayEvents';
import { TaskListType } from '../../../../@types/Task/TaskType';
import { ArrayResponseType } from '../../../../@types/hydra/hydra';
import { DragDropContext, DropResult } from '@hello-pangea/dnd';
import DangerousIcon from '../../../../Icons/Dangerous.icon';
import { convertMinutesToHours } from '../../../../helpers/convertMinutesToHours';
import { TaskEntryType } from '../../../../@types/Task/TaskEntryType';

type Props = {
  employeeId: number;
  extraPadding?: boolean;
};

const MyWorkCalendar: FunctionComponent<Props> = ({ employeeId, ...props }) => {
  const [start, setStart] = useState(findMonday());
  const [end, setEnd] = useState(findFriday());
  const [dayEvents, setDayEvents] = useState<DayEventType[]>([]);
  const [taskEntries, setTaskEntries] = useState<TaskEntryType[]>([]);
  const [backlog, setBacklog] = useState<TaskListType[]>([]);
  const [backlogTotal, setBacklogTotal] = useState(0);
  const days: Date[] = useMemo(() => {
    let result: Date[] = [];
    let iterator = new Date(start);

    while (iterator <= end) {
      result.push(new Date(iterator));
      iterator.setDate(iterator.getDate() + 1);
    }
    return result;
  }, [start, end]);

  const nextWeek = useCallback(() => {
    setStart(getNextWeek(start));
    setEnd(getNextWeek(end));
  }, [start, end]);

  const previousWeek = useCallback(() => {
    setStart(getPreviousWeek(start));
    setEnd(getPreviousWeek(end));
  }, [start, end]);

  const currentWeek = useCallback(() => {
    setStart(findMonday());
    setEnd(findFriday());
  }, []);

  const [meetings, setMeetings] = useState<MeetingType[]>([]);

  const loadEvents = useCallback(() => {
    loadDayEvents([start, end], employeeId).then((res) => setDayEvents(res));
  }, [employeeId, start, end]);

  const loadTaskEntries = useCallback(() => {
    api
      .get<ArrayResponseType<TaskEntryType>>('task_entries', {
        params: {
          'employee.id': employeeId,
          'date[after]': start.toISOString(),
          'date[before]': end.toISOString(),
        },
      })
      .then((res) => setTaskEntries(res.data['hydra:member']));
  }, [employeeId, start, end]);

  const loadBacklog = useCallback(() => {
    api
      .get<ArrayResponseType<TaskListType>>('/tasks', {
        params: {
          unplanned: 'true',
          'assignee.id': employeeId,
          _archived: 0,
        },
      })
      .then((response) => {
        setBacklog(
          response.data['hydra:member'].map((v, i) => ({
            ...v,
            index: i,
          })),
        );
        setBacklogTotal(response.data['hydra:totalItems']);
      });
  }, [employeeId]);

  const loadMeetings = useCallback(() => {
    api
      .get<ArrayResponseType<MeetingType>>('outlook_meetings/by-employees', {
        params: {
          'start[from]': start.toISOString().substring(0, 10),
          'start[to]': end.toISOString().substring(0, 10),
          'employee.id': [employeeId],
        },
      })
      .then((response) => {
        setMeetings(response.data['hydra:member']);
      });
  }, [start, end]);

  const getMyMeetingsForADay = useCallback(
    (day: Date) => {
      return meetings
        .filter((v) => {
          if (!v.start || !v.end) return false;
          const meetingStart = new Date(v.start);
          meetingStart.setHours(0, 0, 0, 0);
          const meetingEnd = new Date(v.end);
          meetingEnd.setHours(23);
          return day >= meetingStart && day <= meetingEnd;
        })
        .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());
    },
    [meetings],
  );

  const [reloadKey, setReloadKey] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      reload(true);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [reloadKey]);

  useEffect(() => {
    reload();
  }, [start, end, employeeId]);

  const reload = useCallback(
    (tasksOnly: boolean = false) => {
      !tasksOnly && loadMeetings();
      !tasksOnly && loadEvents();
      loadTaskEntries();
      loadBacklog();
    },
    [start, end, employeeId],
  );

  const dayWrapperStyles = {
    padding: '1.6rem',
    borderTopRightRadius: '8px',
    borderTopLeftRadius: '8px',
  };

  const findEventForDay = (day: Date) => {
    return dayEvents.find((v) => parseDate(v.day) === parseDate(day));
  };

  const findTasksForDay = useCallback(
    (day: Date) => {
      return taskEntries.filter((v) => {
        return parseDate(v.date) === parseDate(day);
      });
    },
    [taskEntries],
  );

  const findTask = useCallback(
    (iri: any) => {
      return backlog.find((v) => v['@id'] === iri);
    },
    [backlog],
  );

  const findTaskEntry = useCallback(
    (iri: any) => {
      return taskEntries.find((v) => v['@id'] === iri);
    },
    [taskEntries],
  );

  const onDragEnd = useCallback(
    (dropResult: DropResult) => {
      const { source, destination, draggableId } = dropResult;
      let entry: TaskEntryType | undefined;
      let task: TaskListType | undefined;
      if (draggableId.startsWith('/api/task_entries')) {
        entry = findTaskEntry(draggableId);
      } else {
        task = findTask(draggableId);
      }
      if (!destination) return;
      if (!task && !entry) return;

      if (entry) {
        if (destination.droppableId === 'backlog') {
          setTaskEntries(taskEntries.filter((v) => v['@id'] !== draggableId));
          void api.delete(entry['@id']);
        } else {
          void api
            .put(entry['@id'], {
              date: destination.droppableId,
            })
            .then(() => {
              setReloadKey(Math.random());
            });
          entry.date = destination.droppableId;
          setTaskEntries([...taskEntries]);
        }
        return;
      } else if (task) {
        if (destination.droppableId === 'backlog') {
          return;
        } else {
          void api
            .post('task_entries', {
              duration: task.remainingDuration,
              date: destination.droppableId,
              employee: '/api/employees/' + employeeId,
              task: task['@id'],
            })
            .then((res) => {
              setReloadKey(Math.random());
            });

          const taskEntry = {
            '@id': '/api/task_entries/x',
            '@type': 'TaskEntry',
            '@context': '/api/contexts/TaskEntry',
            id: 0,
            task: task,
            date: destination.droppableId,
            duration: task.remainingDuration,
          };
          setTaskEntries((v) => [...v, taskEntry]);
        }
      }
    },
    [taskEntries, backlog, findTask, findTaskEntry, reload, employeeId],
  );

  function calculateMeetingDuration(start: string, end: string) {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const diff = endDate.getTime() - startDate.getTime();
    return diff / 60000;
  }

  const totalTimePerDay = useCallback(
    (day: any) => {
      const meetingsTime = getMyMeetingsForADay(day).reduce(
        (accumulator, currentValue) => accumulator + calculateMeetingDuration(currentValue.start, currentValue.end),
        0,
      );
      const tasksTime = findTasksForDay(day).reduce((accumulator, currentValue) => accumulator + Number(currentValue.duration), 0);
      return convertMinutesToHours(meetingsTime + tasksTime);
    },
    [findTasksForDay, getMyMeetingsForADay],
  );

  const areConflicts = useCallback((dayEvent: DayEventType | undefined, tasks: TaskEntryType[]) => {
    return tasks.length > 0 && !!dayEvent;
  }, []);

  useEffect(() => {
    const listener = serverEvents.listen('/api/tasks').subscribe((event) => {
      setReloadKey(Math.random());
    });
    return () => {
      listener.unsubscribe();
    };
  }, []);

  return (
    <Styled.MyWorkWrapper $extraPadding={props.extraPadding}>
      <Styled.Head>
        <div style={dayWrapperStyles}>
          <Text size={'xl'} bold>
            Backlog
          </Text>
          <Text size={'l'} color={'grey'}>
            {backlogTotal} tasks in total
          </Text>
          <div style={{ height: '2rem' }}></div>
        </div>
        {days.map((day, index) => (
          <div key={index} style={index % 2 === 0 ? { backgroundColor: color.neutral[30], ...dayWrapperStyles } : { ...dayWrapperStyles }}>
            <Text size={'xl'} bold color={parseDate(day) === parseDate(new Date()) ? 'primary' : 'dark'}>
              {getDayName(day)}
            </Text>

            <div
              style={{
                display: 'flex',
                padding: '0',
                alignItems: 'center',
                gap: '0.3rem',
              }}
            >
              <Text size={'m'} color={'semiGrey'}>
                {parseDate(day, false, false, true)}
              </Text>
              {totalTimePerDay(day) && meetings.length > 0 && (
                <Text size={'m'} color={'grey'} style={{ display: 'flex' }} bold>
                  - Total:{' '}
                  <Text size={'m'} color={'primary'} style={{ display: 'inline-block', marginLeft: '0.5rem' }} bold>
                    {totalTimePerDay(day)}
                  </Text>
                </Text>
              )}
            </div>
            {areConflicts(findEventForDay(day), findTasksForDay(day)) && (
              <div style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
                <Icon size={1.3} style={{ paddingLeft: 0 }}>
                  <DangerousIcon />
                </Icon>
                <Text size={'s'} color={'error'} bold>
                  Employee has planned tasks
                </Text>
              </div>
            )}
            <div style={{ height: '2rem' }}></div>
          </div>
        ))}
        <Styled.Actions>
          <Icon size={2} onClick={currentWeek}>
            <CalendarIcon />
          </Icon>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '1.2rem',
            }}
          >
            <Icon size={1.8} onClick={previousWeek}>
              <ArrowIcon direction={'left'} />
            </Icon>
            <Icon size={1.8} onClick={nextWeek}>
              <ArrowIcon direction={'right'} />
            </Icon>
          </div>
        </Styled.Actions>
      </Styled.Head>
      <DragDropContext onDragEnd={onDragEnd}>
        <Styled.Body>
          <Backlog tasks={backlog} startDay={start} endDay={end} />
          {days.map((day, index) => (
            <Day
              key={index}
              day={day}
              dayEvent={findEventForDay(day)}
              taskEntries={findTasksForDay(day)}
              areConflicts={false}
              meetings={getMyMeetingsForADay(day)}
            />
          ))}
        </Styled.Body>
      </DragDropContext>
    </Styled.MyWorkWrapper>
  );
};

export default MyWorkCalendar;
