import styled from 'styled-components';
import { WEIGHTS } from '../../../../../assets/fonts';
import { color } from '../../../../../styles/Variables';

export const MenuLink = styled.div`
  cursor: pointer;
  margin-bottom: 26px;
  min-width: 200px;
  font-weight: ${WEIGHTS.BOLD};
  transition: transform 0.2s ease-out;
  &:active {
    transform: scale(0.98);
    color: ${color.primary[60]};
  }
`;

type MenuTooltipProps = {
  show?: boolean;
};
