import React, { FunctionComponent, ReactNode, useCallback, useContext } from 'react';
import { Grid, GridItem } from '../../atoms/Grid/Grid';
import Heading from '../../atoms/Heading';
import { useNavigate } from 'react-router-dom';
import { SidebarContext } from '../../../Context/Sidebar/SidebarContext';
import * as Styled from './PageTop.styled';
import { ArrowIcon } from '../../../Icons/arrow.icon';

export type PageTopProps = {
  heading: string | ReactNode;
  rightColumn?: ReactNode;
  paddingBottom?: string;
  isPdfOpen?: boolean;
  showBackButton?: boolean;
  backBtnLink?: string;
};

const PageTop: FunctionComponent<PageTopProps> = ({ heading, rightColumn, paddingBottom, isPdfOpen, showBackButton, backBtnLink }) => {
  const navigate = useNavigate();
  const { closeSidebar } = useContext(SidebarContext);

  const handleBack = useCallback(() => {
    if (backBtnLink) {
      navigate(backBtnLink);
    } else {
      navigate(-1);
    }
    closeSidebar();
  }, [backBtnLink]);

  return (
    <Grid
      $align={'center'}
      $justifyContent={'space-between'}
      style={{
        position: 'relative',
        paddingBottom: paddingBottom ?? '2.7rem',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', gap: '1.2rem' }}>
        {showBackButton && (
          <Styled.BackButton onClick={handleBack}>
            <ArrowIcon direction={'left'} size={0.9} />
          </Styled.BackButton>
        )}
        <GridItem>{typeof heading === 'string' ? <Heading>{heading}</Heading> : heading}</GridItem>
      </div>
      {rightColumn && !isPdfOpen && <GridItem>{rightColumn}</GridItem>}
      {rightColumn && isPdfOpen && <GridItem $desktop={9}>{rightColumn}</GridItem>}
    </Grid>
  );
};

export default PageTop;
