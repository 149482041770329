import React, { FunctionComponent } from 'react';
import { color } from '../styles/Variables';

type Props = {
  size?: number;
  iconColor?: string;
};

const DangerousIcon: FunctionComponent<Props> = ({ size = 1.3, iconColor = color.semantic.error['100'] }) => {
  return (
    <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_2580_19282" maskUnits="userSpaceOnUse" x="0" y="0" width={`${size}em`} height={`${size}em`}>
        <rect width={`${size}em`} height={`${size}em`} fill={iconColor} />
      </mask>
      <path
        d="M5.68324 13.5999L2.3999 10.2999V5.68324L5.68324 2.3999H10.3166L13.5999 5.68324V10.3166L10.2999 13.5999H5.68324ZM6.21657 10.6166L7.9999 8.8499L9.78324 10.6166L10.6166 9.78324L8.8499 7.9999L10.6166 6.21657L9.78324 5.38324L7.9999 7.1499L6.21657 5.38324L5.38324 6.21657L7.1499 7.9999L5.38324 9.78324L6.21657 10.6166ZM6.18324 12.3999H9.81657L12.3999 9.81657V6.18324L9.7999 3.5999H6.18324L3.5999 6.18324V9.81657L6.18324 12.3999Z"
        fill={iconColor}
      />
    </svg>
  );
};

export default DangerousIcon;
