import React, { FunctionComponent, useEffect, useState } from 'react';
import AbsencesCardItem from '../DashboardCardItems/AbsencesCardItem';
import * as Styled from './ContentCard.styled';
import Text from '../../../../atoms/Text/Text';
import { Loader } from '../../../../../legacy/CapeMorris/components';
import { DashboardDataAbsenceType } from '../../../../../@types/Dashboard/DashboardDataAbsenceType';
import AbsencesPerDaySelect from '../../../AbsencesPerDaySelect/AbsencesPerDaySelect';
import AbsencesCounter from '../../../AbsencesCounter/AbsencesCounter';

type Props = {
  absences: DashboardDataAbsenceType[] | undefined;
  showDateFilter?: boolean;
  showCounter?: boolean;
};

const AbsencesCardContentCard: FunctionComponent<Props> = ({ absences, showDateFilter, showCounter }) => {
  const [filteredAbsences, setFilteredAbsences] = useState<DashboardDataAbsenceType[] | undefined>(undefined);

  useEffect(() => {
    if (!absences) {
      return;
    }
    setFilteredAbsences(absences);
  }, [absences]);

  return (
    <Styled.ItemsColumnWrapper $scrollable={true} $heightLocked>
      {showDateFilter && <AbsencesPerDaySelect absences={absences} onSelect={setFilteredAbsences} />}
      {showCounter && <AbsencesCounter count={filteredAbsences?.length || 0} />}
      {filteredAbsences &&
        filteredAbsences.sort((a, b) => (a.employee.name > b.employee.name ? 1 : -1)).map((absence, idx) => <AbsencesCardItem absence={absence} key={idx} />)}
      {filteredAbsences?.length === 0 && <Text color={'grey'}>No absences to display</Text>}
      {!filteredAbsences && <Loader />}
    </Styled.ItemsColumnWrapper>
  );
};

export default AbsencesCardContentCard;
