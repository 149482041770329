import React from 'react';
import { CellTemplate } from '../../CellTemplate';
import { PurchaseOrderType } from '../../../../../@types/Finances/PurchaseOrder/PurchaseOrderType';
import { CostType } from '../../../../../@types/Finances/Cost/CostType';
import { IncomeType } from '../../../../../@types/Finances/Income/IncomeType';
import IdText from '../../../IdText/IdText';
import FinanceStatus from '../../../Statuses/FinanceStatus/FinanceStatus';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import { ContractorType } from '../../../../../@types/Finances/Contractor/ContractorType';

const FinanceIdTemplate: CellTemplate<string | number, {}, IncomeType | PurchaseOrderType | CostType | ContractorType> = ({ value, item }) => {
  return (
    <Grid $align={'center'} spacing={1}>
      <GridItem>
        <IdText withHash={!!value} value={value ?? ''} />
      </GridItem>
      <GridItem>
        <FinanceStatus status={item.status} />
      </GridItem>
    </Grid>
  );
};
export default FinanceIdTemplate;
