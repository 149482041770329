import { useEffect } from 'react';

function useKeyboard(key: string, callback: (event: KeyboardEvent) => void, eventType: 'keydown' | 'keyup' = 'keydown', preventDefault = false) {
  useEffect(() => {
    const handleEvent = (event: KeyboardEvent) => {
      if (event.key === key && preventDefault) {
        event.preventDefault();
        callback(event);
      } else if (event.key === key) {
        event.stopPropagation();
        callback(event);
      }
    };

    window.addEventListener(eventType, handleEvent);

    return () => {
      window.removeEventListener(eventType, handleEvent);
    };
  }, [eventType, key, callback]);
}

export default useKeyboard;
