import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../legacy/CapeMorris/components';
import { PurchaseOrderType } from '../../../../@types/Finances/PurchaseOrder/PurchaseOrderType';
import PurchaseOrderShow from './PurchaseOrderShow';
import EntityProvider from '../../../../Context/EntityContext/EntityProvider';

const PurchaseOrderShowView: FunctionComponent = () => {
  let { id } = useParams<{ id: string }>();

  return (
    <EntityProvider<PurchaseOrderType> url={`/api/purchase_orders/${id}`}>
      {({ loading, entity }) => (
        <>
          {loading && <Loader />}
          {entity && <PurchaseOrderShow purchaseOrder={entity} />}
        </>
      )}
    </EntityProvider>
  );
};

export default PurchaseOrderShowView;
