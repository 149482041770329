import React, { FunctionComponent } from 'react';
import { color } from '../styles/Variables';

type PropType = {
  size?: number;
  iconColor?: string;
};

const OnDemandIcon: FunctionComponent<PropType> = ({ size = 1, iconColor }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g>
        <path
          d="M14.6666 3.35001C14.6666 2.78334 14.4722 2.30557 14.0833 1.91668C13.6944 1.52779 13.2166 1.33334 12.65 1.33334V0.333344C13.4833 0.333344 14.1944 0.627788 14.7833 1.21668C15.3722 1.80557 15.6666 2.51668 15.6666 3.35001H14.6666ZM3.99996 15.3333C3.07774 15.3333 2.29163 15.0083 1.64163 14.3583C0.991626 13.7083 0.666626 12.9222 0.666626 12H1.66663C1.66663 12.6445 1.8944 13.1945 2.34996 13.65C2.80552 14.1056 3.35552 14.3333 3.99996 14.3333V15.3333ZM6.69996 15.3333C6.36663 15.3333 6.05552 15.2583 5.76663 15.1083C5.47774 14.9583 5.23885 14.7445 5.04996 14.4667L0.799959 8.25001L1.19996 7.86668C1.41107 7.65557 1.66107 7.53334 1.94996 7.50001C2.23885 7.46668 2.49996 7.53334 2.73329 7.70001L4.66663 9.05001V2.66668C4.66663 2.47779 4.73052 2.31945 4.85829 2.19168C4.98607 2.0639 5.1444 2.00001 5.33329 2.00001C5.52218 2.00001 5.68052 2.0639 5.80829 2.19168C5.93607 2.31945 5.99996 2.47779 5.99996 2.66668V11.6167L3.53329 9.88334L6.14996 13.7C6.20552 13.7889 6.28329 13.8611 6.38329 13.9167C6.48329 13.9722 6.58885 14 6.69996 14H11.3333C11.7 14 12.0138 13.8695 12.275 13.6083C12.5361 13.3472 12.6666 13.0333 12.6666 12.6667V3.33334C12.6666 3.14445 12.7305 2.98612 12.8583 2.85834C12.9861 2.73057 13.1444 2.66668 13.3333 2.66668C13.5222 2.66668 13.6805 2.73057 13.8083 2.85834C13.9361 2.98612 14 3.14445 14 3.33334V12.6667C14 13.4 13.7388 14.0278 13.2166 14.55C12.6944 15.0722 12.0666 15.3333 11.3333 15.3333H6.69996ZM7.33329 8.00001V1.33334C7.33329 1.14445 7.39718 0.986121 7.52496 0.858344C7.65274 0.730566 7.81107 0.666677 7.99996 0.666677C8.18885 0.666677 8.34718 0.730566 8.47496 0.858344C8.60274 0.986121 8.66663 1.14445 8.66663 1.33334V8.00001H7.33329ZM9.99996 8.00001V2.00001C9.99996 1.81112 10.0638 1.65279 10.1916 1.52501C10.3194 1.39723 10.4777 1.33334 10.6666 1.33334C10.8555 1.33334 11.0138 1.39723 11.1416 1.52501C11.2694 1.65279 11.3333 1.81112 11.3333 2.00001V8.00001H9.99996Z"
          fill={iconColor ?? 'currentColor'}
        />
      </g>
    </svg>
  );
};

export default OnDemandIcon;
