import styled from 'styled-components';
import Heading from '../../../../../../components/atoms/Heading';
import { color, fontWeight } from '../../../../../../styles/Variables';

export const ProjectsHomeHeaderStyled = styled.div`
  margin-bottom: 3.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HomeHeader = styled(Heading)`
  padding: 0;
  font-size: 3.6rem;
  margin-bottom: 0.8rem;
`;

export const Bold = styled.span`
  font-weight: ${fontWeight.bold};
  position: relative;
  color: ${color.neutral['60']};
  transition: color 0.2s ease-in-out;
  &::after {
    content: ' ';
    position: absolute;
    left: 0;
    bottom: -4px;
    height: 1px;
    width: 100%;
    background-color: ${color.neutral['50']};
    transition: background-color 0.2s ease-in-out;
  }

  &:hover {
    color: ${color.primary['60']};
    &::after {
      background-color: ${color.primary['60']};
    }
  }
`;
