import { CellTemplate } from '../../CellTemplate';
import React, { memo, useMemo } from 'react';
import Chip from '../../../../atoms/Chips/Chip';
import { color, fontWeight } from '../../../../../styles/Variables';
import { AbsenceType } from '../../../../../@types/Absences/AbsenceType';
import Text from '../../../../atoms/Text/Text';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import { DotIcon } from '../../../../../Icons/Dot.icon';

const AbsenceStatusTemplate: CellTemplate<
  string,
  {
    withHash?: boolean;
  },
  AbsenceType
> = ({ value, item }) => {
  const typeChip = useMemo(() => {
    switch (item.status) {
      case 'pending':
        return (
          <Chip $bgColor={color.semantic.warning[20]}>
            <DotIcon dotColor={color.semantic.warning['100']} size={0.7} />
            <Text
              size={'s'}
              style={{
                color: color.semantic.warning[100],
                fontWeight: fontWeight.medium,
              }}
            >
              Pending
            </Text>
          </Chip>
        );
      case 'accepted':
        return (
          <Chip $bgColor={color.secondary[20]}>
            <DotIcon dotColor={color.secondary['70']} size={0.7} />
            <Text
              size={'s'}
              style={{
                color: color.secondary[70],
                fontWeight: fontWeight.medium,
              }}
            >
              Approved
            </Text>
          </Chip>
        );
      case 'cancelled':
        return (
          <Chip $bgColor={color.neutral[30]}>
            <DotIcon dotColor={color.neutral['70']} size={0.7} />
            <Text
              size={'s'}
              style={{
                color: color.neutral[70],
                fontWeight: fontWeight.medium,
              }}
            >
              Cancelled
            </Text>
          </Chip>
        );
      case 'rejected':
        return (
          <Chip $bgColor={color.semantic.error[20]}>
            <DotIcon dotColor={color.semantic.error['100']} size={0.7} />
            <Text
              size={'s'}
              style={{
                color: color.semantic.error[100],
                fontWeight: fontWeight.medium,
              }}
            >
              Rejected
            </Text>
          </Chip>
        );
    }
  }, [value]);

  return (
    <Grid spacing={0.6}>
      <GridItem>{typeChip}</GridItem>
    </Grid>
  );
};

export default memo(AbsenceStatusTemplate);
