import { purchaseStatusDict } from '../../../../@dicts/PurchaseOrder/PurchaseStatusDict';
import { TableFiltersType } from '../../../../@types/Table/TableFilterType';
import { CurrencyTypeDict } from '../../../../@dicts/CurrencyTypeDict';
import ContractorOption from '../../../molecules/Finances/ContractorOption';
import { CostKindDict } from '../../../../@dicts/Cost/CostKind';
import DateSelectFilter from '../../../molecules/TableFilters/DateSelectFilter/DateSelectFilter';

export const filters: TableFiltersType = [
  {
    type: 'text',
    name: '_search',
    label: 'Search',
    placeholder: 'Search by vendor, requester, PO title or number',
    gridProps: {
      $desktop: 3,
    },
  },
  {
    type: 'select',
    name: 'contractor.id',
    label: 'Vendor',
    options: {
      baseUrl: '/contractors',
      optionComponent: ContractorOption,
    },
    gridProps: {
      $desktop: 3,
    },
  },
  {
    type: 'select',
    options: {
      baseUrl: '/employees',
    },
    name: 'createdBy.id',
    label: 'Requester',
    gridProps: {
      $desktop: 3,
    },
  },
  {
    type: 'select',
    name: 'status',
    label: 'Status',
    options: {
      options: purchaseStatusDict,
    },
    gridProps: {
      $desktop: 3,
    },
  },
  {
    type: 'custom',
    name: 'createdAt',
    label: 'Created at',
    options: {
      component: DateSelectFilter,
    },
    gridProps: {
      $desktop: 4,
    },
  },
  {
    type: 'select',
    name: 'kind',
    label: 'Cost kind',
    gridProps: {
      $desktop: 2,
    },
    options: {
      options: CostKindDict,
    },
  },
  {
    type: 'select',
    name: 'costType.id',
    label: 'Cost type',
    gridProps: {
      $desktop: 2,
    },
    options: {
      baseUrl: '/api/cost_types',
      optionLabel: 'label',
    },
  },

  {
    type: 'money',
    name: 'amountNet.value[gte]',
    label: 'Amount from',
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'money',
    name: 'amountNet.value[lte]',
    label: 'Amount to',
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'select',
    name: 'originalAmountNet.currency',
    label: 'Original currency',
    options: {
      options: CurrencyTypeDict,
    },
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'money',
    name: 'remainNet.value[gte]',
    label: 'Remain from',
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'money',
    name: 'remainNet.value[lte]',
    label: 'Remain up to',
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'money',
    name: 'invoicedNet.value[gte]',
    label: 'Invoiced from',
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'money',
    name: 'invoicedNet.value[lte]',
    label: 'Invoiced up to',
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'switch',
    name: 'closed',
    label: 'Closed',
    gridProps: {
      $desktop: 'auto',
    },
  },
];
