import React, { FunctionComponent, useCallback, useState } from 'react';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import Button from '../../../atoms/Button/Button';
import Form from '../../../molecules/Form/Form';
import { PaymentType } from '../../../../@types/Finances/PaymentType';
import SinglePaymentCardForm from './SinglePaymentCardForm';
import { MoneyType } from '../../../../@types/Money/MoneyType';
import { useEntityContext } from '../../../../Context/EntityContext/EntityContext';
import { CostType } from '../../../../@types/Finances/Cost/CostType';
import { IncomeType } from '../../../../@types/Finances/Income/IncomeType';
import useApi from '../../../../hooks/useApi';
import Heading from '../../../atoms/Heading';

type Props = {
  remain: MoneyType;
  currency: string;
  onValueChange: (value: number) => void;
  subject: CostType | IncomeType;
};

const CreatePayment: FunctionComponent<Props> = ({ remain, currency, onValueChange, subject }) => {
  const [isEnabled, setEnabled] = useState(false);
  const { reload } = useEntityContext();
  const { post } = useApi();

  const handleCreate = useCallback((data: PaymentType) => {
    let request: Partial<PaymentType & { cost?: string; income?: string }> = {};

    let url = '/income_payments';
    if (subject['@type'] === 'Cost') {
      request.cost = subject['@id'];
      url = '/cost_payments';
    } else {
      request.income = subject['@id'];
    }
    request = {
      ...request,
      ...data,
    };
    post(url, request).then(() => {
      reload();
      setEnabled(false);
    });
  }, []);

  return (
    <Grid spacing={2} style={{ marginTop: '1.8rem' }}>
      <GridItem $desktop={12}>
        {isEnabled && (
          <Form
            onSubmit={handleCreate}
            defaultValues={{
              value: { value: 0, currency: currency },
            }}
          >
            <GridItem style={{ marginBottom: '1.8rem' }}>
              <Heading size={'s'}>Create payment</Heading>
            </GridItem>
            <Grid spacing={2}>
              <SinglePaymentCardForm
                onValueChange={onValueChange}
                payment={{
                  value: { value: 0, currency: currency },
                }}
                remain={remain}
                onCancel={() => setEnabled(false)}
              />
            </Grid>
          </Form>
        )}
      </GridItem>
      <GridItem $desktop={12}>
        <Grid $justifyContent={'end'}>
          <GridItem>
            {!isEnabled && (
              <Button
                full
                $text={'Add payment'}
                onClick={() => {
                  setEnabled(true);
                  onValueChange(0);
                }}
              />
            )}
          </GridItem>
        </Grid>
      </GridItem>
    </Grid>
  );
};

export default CreatePayment;
