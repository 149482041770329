import React, { FunctionComponent } from 'react';

type PropType = {
  size?: number;
  color?: string;
};

const AddDateIcon: FunctionComponent<PropType> = ({ size = 1, color = '#fff' }) => {
  return (
    <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3333 14.6668V12.6668H9.33333V11.3335H11.3333V9.3335H12.6667V11.3335H14.6667V12.6668H12.6667V14.6668H11.3333ZM3.33333 13.3335C2.96667 13.3335 2.65278 13.2029 2.39167 12.9418C2.13056 12.6807 2 12.3668 2 12.0002V4.00016C2 3.6335 2.13056 3.31961 2.39167 3.0585C2.65278 2.79739 2.96667 2.66683 3.33333 2.66683H4V1.3335H5.33333V2.66683H9.33333V1.3335H10.6667V2.66683H11.3333C11.7 2.66683 12.0139 2.79739 12.275 3.0585C12.5361 3.31961 12.6667 3.6335 12.6667 4.00016V8.06683C12.4444 8.0335 12.2222 8.01683 12 8.01683C11.7778 8.01683 11.5556 8.0335 11.3333 8.06683V6.66683H3.33333V12.0002H8C8 12.2224 8.01667 12.4446 8.05 12.6668C8.08333 12.8891 8.14444 13.1113 8.23333 13.3335H3.33333ZM3.33333 5.3335H11.3333V4.00016H3.33333V5.3335Z"
        fill={color}
      />
    </svg>
  );
};

export default AddDateIcon;
