import React from 'react';
import { FunctionComponent } from 'react';
import { OptionProps, components } from 'react-select';
import { fontWeight } from '../../../styles/Variables';
import Chip from '../../atoms/Chips/Chip';
import Text from '../../atoms/Text';
import { Grid, GridItem } from '../../atoms/Grid/Grid';
import { CostType } from '../../../@types/Finances/Cost/CostType';

type Props = OptionProps<{ value: string; label: string; meta: CostType }, false>;
const CostTypeOption: FunctionComponent<Props> = (props) => {
  return (
    <components.Option {...props}>
      <Grid style={{ fontWeight: fontWeight.regular }} spacing={1}>
        <GridItem>
          <Text bold color={'grey'} size={'xs'}>
            {props.data.label}
          </Text>
        </GridItem>
        <GridItem>{props.data.meta.confidential && <Chip $size={'xs'}>Confidential</Chip>}</GridItem>
      </Grid>
    </components.Option>
  );
};
export default CostTypeOption;
