import { components, OptionProps } from 'react-select';
import { ProjectType } from '../../../../@types/Project/Project';
import React, { FunctionComponent } from 'react';
import Text from '../../../atoms/Text';
import IdText from '../../IdText/IdText';

type Props = OptionProps<{ value: string; label: string; meta: ProjectType }, false>;
const ProjectLabel: FunctionComponent<Props> = (props) => {
  return (
    <components.SingleValue {...props}>
      <div style={{ display: 'flex', gap: '0.3rem' }}>
        <IdText value={props.data.meta.id} withHash={true} />
        <Text bold>{props.data.meta.name}</Text>
      </div>
    </components.SingleValue>
  );
};
export default ProjectLabel;
