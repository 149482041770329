import React from 'react';
import { color } from '../../../styles/Variables';

type LogoProps = {
  width?: number;
  height?: number;
};

export const Logo: React.FC<LogoProps> = ({ width = 36, height = 26 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 69 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7842 17.519C22.2843 17.966 21.7386 18.3351 21.1595 18.6166C19.5282 19.4058 17.9667 19.3732 16.6446 18.5245C15.9751 18.0936 15.4393 17.4967 15.0526 16.7508C14.6724 16.0108 14.4796 15.1774 14.4796 14.273C14.4796 13.3756 14.672 12.5435 15.0509 11.7993C15.4369 11.0521 15.9743 10.4582 16.6479 10.031C18.0183 9.16662 19.6975 9.17322 21.2569 10.0528C21.8241 10.3707 22.3441 10.783 22.8044 11.2804L24.8216 8.87272C24.1232 8.21807 23.2749 7.67321 22.2979 7.25219C21.1533 6.75852 19.9955 6.50797 18.8567 6.50797C17.3063 6.50797 15.8905 6.85139 14.6476 7.52916C13.4106 8.20569 12.4294 9.1468 11.7322 10.3265C11.0289 11.5062 10.6723 12.8498 10.6723 14.3209C10.6723 15.825 11.0223 17.1995 11.712 18.4056C12.3964 19.6039 13.3581 20.5582 14.5717 21.2426C15.7819 21.9253 17.1693 22.2716 18.6953 22.2716C19.8258 22.2716 20.9919 22.0005 22.1609 21.4663C23.1565 21.0069 24.0452 20.4043 24.8043 19.6737L22.7842 17.519Z"
        fill="#2C2D33"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.4647 14.9783V9.38606H48.3508C49.2226 9.38606 49.9206 9.63042 50.4258 10.1125C50.9364 10.6074 51.1948 11.2976 51.1948 12.1644C51.1948 13.0432 50.9381 13.7412 50.4316 14.2402C49.9268 14.7302 49.2267 14.9783 48.3508 14.9783H45.4647ZM53.0877 16.108C54.1151 15.1549 54.636 13.7796 54.636 12.0204C54.636 10.3623 54.1188 9.06947 53.0989 8.17624C52.055 7.26815 50.5356 6.8075 48.5844 6.8075H41.7721V21.9479H45.4647V17.5853L48.5799 17.563C50.524 17.563 52.0401 17.0735 53.0877 16.108Z"
        fill="#2C2D33"
      />
      <mask id="mask0_1703_15966" maskUnits="userSpaceOnUse" x="55" y="6" width="13" height="16">
        <path fillRule="evenodd" clipRule="evenodd" d="M55.0472 6.80774H67.4443V21.9477H55.0472V6.80774Z" fill="white" />
      </mask>
      <g mask="url(#mask0_1703_15966)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M63.3038 15.6583V13.0794H58.7402V9.38631H67.2107V6.80774H55.0472V21.9477H67.4443V19.3691H58.7402V15.6583H63.3038Z"
          fill="#2C2D33"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.357 21.9478H41.2638L35.0228 6.8074H30.8117L24.3527 21.9478H28.0672L32.7868 9.44046L35.5441 16.9549H33.2161L31.2529 19.3482H36.4043L37.357 21.9478Z"
        fill="#2C2D33"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9262 6.51859H11.4039L11.6668 5.30918C12.1551 2.95887 14.3779 3.24368 15.8197 3.7324L15.9014 3.4934C16.2411 2.3393 16.5515 1.29871 16.8087 0.454179C12.7896 -0.594256 8.5533 -0.141448 7.37856 5.20433L7.07063 6.51859H4.71991L4.13089 9.415H6.42919L4.07434 20.3815C3.71399 22.139 3.14313 22.4771 1.43839 22.3421L0.64257 25.4961C4.35997 26.2391 7.43139 25.2105 8.46043 20.4343L10.8153 9.415H18.0821C18.3401 8.52218 18.596 7.65041 18.9262 6.51859Z"
        fill={color.primary['60']}
      />
    </svg>
  );
};
