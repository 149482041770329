import React, { FunctionComponent } from 'react';
import { NotificationType } from '../../../../@types/Notification/NotificationType';
import Text from '../../../atoms/Text';
import { Icon } from '../../../atoms/Icon/Icon';
import AcceptIcon from '../../../../Icons/Accept.icon';
import { color } from '../../../../styles/Variables';
import * as Styled from '../Notification.styled';
import CancelIcon from '../../../../Icons/Cancel.icon';
import { HomeIcon } from '../../../../Icons/home.icon';
import Chip from '../../../atoms/Chips/Chip';

type Props = {
  notification: NotificationType;
  timeComponent: JSX.Element;
  indicatorComponent: JSX.Element;
};

const AbsenceNotification: FunctionComponent<Props> = ({ notification, timeComponent, indicatorComponent }) => {
  return (
    <>
      <Styled.TopWrapper>
        {indicatorComponent}
        {timeComponent}{' '}
        {notification.notificationType === 'absence_accept' && (
          <Chip $size={'s'} $bgColor={color.semantic.success[40]}>
            <Icon color={color.semantic.success[100]} size={1.3}>
              <AcceptIcon />
            </Icon>
            <Text size={'xs'} color={'success'}>
              Accepted
            </Text>
          </Chip>
        )}
        {notification.notificationType === 'absence_reject' && (
          <Chip $size={'s'} $bgColor={color.semantic.error[40]}>
            <Icon color={color.semantic.error[100]} size={1.3}>
              <CancelIcon />
            </Icon>
            <Text size={'xs'} color={'error'}>
              Rejected
            </Text>
          </Chip>
        )}
        {notification.notificationType === 'absence_create' && (
          <Chip $size={'s'} $bgColor={color.primary[40]}>
            <Icon color={color.primary[80]} size={1.3}>
              <HomeIcon />
            </Icon>
            <Text size={'xs'} color={'primary'}>
              Request
            </Text>
          </Chip>
        )}
      </Styled.TopWrapper>
      <Styled.ContentTitle size={'l'} bold={true}>
        {notification.subject}
      </Styled.ContentTitle>
      <Text size={'m'} color={'semiGrey'}>
        {notification.subtext}
      </Text>
    </>
  );
};

export default AbsenceNotification;
