import React, { FunctionComponent, useLayoutEffect, useRef, useState } from 'react';
import { Link, TabContainer, Underline, Wrapper } from './NavigatorRouterTab.styled';
import { NavLink, NavLinkProps } from 'react-router-dom';
import ExternalLinkIcon from '../../../Icons/ExternalLink.icon';
import { Icon } from '../Icon/Icon';

type Props = NavLinkProps & {
  isExternal: boolean;
  to: string;
};

const NavigatorRouterTab: FunctionComponent<Props> = ({ isExternal, ...props }) => {
  const ref = useRef(null);

  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    if (ref.current) {
      // @ts-ignore
      setWidth(ref.current.offsetWidth);
    }
  }, []);

  return (
    <>
      {!isExternal && (
        <NavLink {...props}>
          {({ isActive }) => (
            <Wrapper>
              <TabContainer ref={ref}>{props.children}</TabContainer>
              <Underline $active={isActive} />
            </Wrapper>
          )}
        </NavLink>
      )}
      {isExternal && (
        <Link href={props.to} target={'_blank'}>
          <TabContainer ref={ref}>
            <Icon size={1.2}>
              <ExternalLinkIcon />
            </Icon>
            {props.children}
          </TabContainer>
          <Underline $active={false} />
        </Link>
      )}
    </>
  );
};

export default NavigatorRouterTab;
