import React, { FunctionComponent, useContext } from 'react';
import TablePage from '../../../organisms/TablePage/TablePage';
import { TaskType } from '../../../../@types/Task/TaskType';
import properties from '../../Tasks/Tasks.properties';
import filters from '../../Tasks/Tasks.filters';
import TaskFormContext from '../../../../Context/TaskFormContext/TaskFormContext';
import Button from '../../../atoms/Button/Button';
import useUser from '../../../../hooks/useUser';
import useTaskSidebar from '../../../../hooks/useTaskSidebar';
import PlusIcon from '../../../../Icons/Plus.icon';
import useLocationQuery from '../../../../hooks/useLocationQuery';
import PersonTemplate from '../../../molecules/CellTemplates/common/PersonTemplate/PersonTemplate';

type Props = {};

const MyTasks: FunctionComponent<Props> = (props) => {
  const { openLongForm } = useContext(TaskFormContext);
  const user = useUser();
  const { openTask } = useTaskSidebar();
  const overdue = useLocationQuery('overdue');

  return (
    <TablePage<TaskType>
      url={'/tasks'}
      heading={'My tasks'}
      context={'/api/tasks'}
      properties={[
        ...properties,
        {
          name: 'createdBy',
          visibleName: 'Author',
          template: PersonTemplate,
          sortable: true,
          templateOptions: {
            textProperties: {
              size: 's',
              color: 'grey',
              bold: true,
            },
          },
        },
      ]}
      filters={[
        ...filters,

        {
          type: 'select',
          name: 'author',
          label: 'Author',
          gridProps: {
            $desktop: 2,
          },
          options: {
            options: [
              { value: 'me', label: 'Me' },
              { value: 'someone', label: 'Someone else' },
            ],
          },
        },
      ]}
      defaultFilters={{ overdue: overdue !== null ? true : null }}
      globalFilters={{ 'assignee.id': user.employeeId, _archived: 0 }}
      headingRight={
        <Button id={'open-task-form'} $text={'Add task'} kind={'primary'} onClick={openLongForm as () => void} $icon={<PlusIcon />} iconSize={1.2} />
      }
      onItemClick={(item) => {
        openTask(item['@id']);
      }}
    />
  );
};

export default MyTasks;
