import React, { FunctionComponent, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import Default from './elements/Default';
import api from '../../../services/api';
import { AxiosResponse } from 'axios';
import { FileContextType } from '../../../@types/File/File';

interface OwnProps {
  children?: (isDragActive: boolean) => JSX.Element;
  onUpload?: (uuid: string, file: File) => void;
  noClick?: boolean;
  maxFiles?: number;
  disabled?: boolean;
  text?: string;
}

type Props = OwnProps & {
  simple?: boolean;
  context: FileContextType;
};

const Dropzone: FunctionComponent<Props> = ({ children, onUpload, noClick = false, maxFiles = 0, disabled = false, simple, text, context }) => {
  const onDrop = useCallback(
    (files: File[]) => {
      files.forEach((file) => {
        let formData = new FormData();
        formData.append('file', file);
        formData.append('context', context);
        api
          .post('/files', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response: AxiosResponse<{ uuid: string }>) => {
            if (onUpload) {
              onUpload(response.data.uuid, file);
            }
          });
      });
    },
    [onUpload],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick,
    maxFiles,
    multiple: maxFiles !== 1,
    disabled,
  });

  return (
    <div {...getRootProps()} style={{ outline: 'none' }}>
      <input {...getInputProps()} />
      {children ? children(isDragActive) : <Default text={text} isDragActive={isDragActive} simple={simple} />}
    </div>
  );
};

export default Dropzone;
