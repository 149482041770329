import React, { FunctionComponent } from 'react';
import { RouterPageType } from '../../../../@types/RouterPage/RouterPageType';
import TabsNavigatorRouter from '../../../../components/molecules/TabsNavigatorRouter/TabsNavigatorRouter';
import SubincomesList from '../../../../components/templates/Income/SubincomesList/SubincomesList';
import IncomeList from '../../../../components/templates/Income/IncomeList/IncomeList';

type Props = {};

const IncomesListScreen: FunctionComponent<Props> = (props) => {
  const pages: RouterPageType[] = [
    {
      name: 'Incomes',
      to: '/incomes',
      screen: <IncomeList />,
    },
    {
      name: 'Assignments',
      to: '/assignments',
      screen: <SubincomesList />,
    },
  ];
  return <TabsNavigatorRouter pages={pages} />;
};

export default IncomesListScreen;
