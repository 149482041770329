import FireIcon from '../../../../../Icons/Fire.icon';
import { DropdownMenuItemType } from '../../../../organisms/DropdownMenu/DropdownMenuItem/DropdownMenuItem';
import React from 'react';
import SnackContainer from '../../../../../legacy/CapeMorris/components/snack-bar/use-snack-container';
import api from '../../../../../services/api';
import { color } from '../../../../../styles/Variables';
import { Icon } from '../../../../atoms/Icon/Icon';
import { useEntityContext } from '../../../../../Context/EntityContext/EntityContext';
import { ProjectType } from '../../../../../@types/Project/Project';

export const ReopenButton = (id: number): DropdownMenuItemType => {
  const { showSnack } = SnackContainer.useContainer();

  const { replace } = useEntityContext();
  return {
    icon: (
      <Icon color={color.semantic.success['80']}>
        <FireIcon />
      </Icon>
    ),
    text: 'Active',
    onClick: () => {
      api
        .post<ProjectType>(`/api/projects/${id}/reopen`, {})
        .then((response) => {
          replace(response.data);
          showSnack({
            title: 'Success',
            message: 'Project reopened',
            type: 'success',
          });
        })
        .catch((error) => {
          showSnack({
            title: 'Error',
            message: error.response.data['hydra:description'],
            type: 'error',
          });
        });
    },
  };
};
