import React, { forwardRef, MouseEventHandler } from 'react';
import { CheckboxWrapper, HiddenInput } from './Checkbox.styled';
import TickIcon from '../../../Icons/TickIcon';
import { Icon } from '../Icon/Icon';

export type CheckboxSize = 'small' | 'normal';

type Props = {
  isChecked?: boolean;
  disabled?: boolean;
  size?: CheckboxSize;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

/** todo: refactor to not to use forwardRef as it is not needed */
const Checkbox = forwardRef<HTMLInputElement, Props>((Props, ref) => {
  const { isChecked, disabled = true, size = 'normal', onClick } = Props;
  return (
    <CheckboxWrapper onClick={disabled ? () => {} : onClick} $disabled={disabled}>
      <HiddenInput type={'checkbox'} checked={isChecked} readOnly ref={ref} />
      <Icon size={1.5}>
        <TickIcon checked={isChecked} />
      </Icon>
    </CheckboxWrapper>
  );
});

export default Checkbox;
