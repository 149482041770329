import React, { FunctionComponent, useCallback } from 'react';
import * as Styled from './ExportBottom.styled';
import { CostType } from '../../../../../@types/Finances/Cost/CostType';
import Button from '../../../../atoms/Button/Button';
import { PiBankBold } from 'react-icons/pi';
import Text from '../../../../atoms/Text';
import moneyAsString from '../../../../../helpers/moneyAsString';
import api from '../../../../../services/api';
import ExportModal from './ExportModal';
import DatabaseIcon from '../../../../../Icons/Database.icon';
import useModal from '../../../../../hooks/useModal';
import { AuthContext } from '../../../../../Context/auth-context/auth-context';

type Props = {
  costs: CostType[];
};
const ExportBottom: FunctionComponent<Props> = ({ costs }) => {
  const { showModal, hideModal } = useModal();
  const { can } = React.useContext(AuthContext);
  const exportableCosts = costs.filter((cost) => cost.bankExportEnabled);
  const total = exportableCosts.reduce((acc, cost) => acc + cost.amountGross.value, 0);
  const companyCount = new Set(exportableCosts.map((cost) => cost.financialCompany?.['@id'])).size;
  const confirmExport = useCallback(() => {
    const costsIds = exportableCosts.map((cost) => cost.id);
    return api
      .post(
        '/export-costs-to-bank',
        { costs: costsIds },
        {
          headers: {
            Accept: 'application/csv',
          },
        },
      )
      .then((response) => {
        const content = response.data;

        const element = document.createElement('a');
        const file = new Blob([content], { type: 'text/csv' });
        element.href = URL.createObjectURL(file);
        element.download = `${costs[0].financialCompany?.name.replace(' PL Sp. z o.o.', '').replace(' ', '')}-${new Date().toISOString().split('T')[0]}.csv`;
        document.body.appendChild(element);
        element.click();
        hideModal();
      });
  }, [exportableCosts]);
  const openModal = useCallback(() => {
    showModal({
      title: 'Export selected costs to bank',
      body: <ExportModal costs={costs} />,
      footer: <Button $text={'Export'} kind={'primary'} onClick={confirmExport} $icon={<DatabaseIcon />} />,
    });
  }, [costs]);

  return (
    <Styled.ExportBottomContainer $show={can('FINANCES.COSTS.CAN_EXPORT_TO_BANK') && costs.some((cost) => cost.bankExportEnabled)}>
      <Text color={'white'}>Exportable elements: {exportableCosts.length}</Text>
      <Text color={'white'}>
        Total:{' '}
        {moneyAsString({
          value: total,
          currency: 'PLN',
        })}
      </Text>
      {companyCount === 1 && <Button kind={'ghost-white'} onClick={openModal} $text={'Export to bank'} $icon={<PiBankBold />} />}
      {companyCount > 1 && <Text color={'white'}>You can export costs only from one company at a time</Text>}
    </Styled.ExportBottomContainer>
  );
};

export default ExportBottom;
