import { color, fontSize, fontWeight } from '../../../../../styles/Variables';
import { CSSObjectWithLabel, StylesConfig } from 'react-select';

const styles: StylesConfig<any, true> = {
  container: (styles: CSSObjectWithLabel) => ({
    ...styles,
    margin: 0,
  }),
  control: (styles: CSSObjectWithLabel) => ({
    ...styles,
    fontSize: fontSize.body.m,
    fontWeight: fontWeight.bold,
    color: color.neutral[60],
    borderRadius: 0,
    cursor: 'pointer',
    borderWidth: 0,
    boxShadow: 'none',
    height: 0,
    borderBottom: `1px solid ${color.primary[80]}`,
  }),
  indicatorsContainer: (styles: CSSObjectWithLabel) => ({
    ...styles,
  }),
  valueContainer: (styles: CSSObjectWithLabel) => ({
    ...styles,
  }),
  singleValue: (styles: CSSObjectWithLabel) => ({
    ...styles,
    color: color.neutral[80],
    paddingLeft: 0,
  }),
  input: (styles: CSSObjectWithLabel) => ({
    ...styles,
    fontSize: fontSize.body.m,
    padding: 0,
    color: color.neutral[80],
    fontWeight: fontWeight.bold,
  }),
  placeholder: (styles: CSSObjectWithLabel) => ({
    ...styles,
    fontSize: fontSize.body.m,
    fontWeight: fontWeight.regular,
    color: color.neutral[60],
    padding: 0,
    margin: 0,
  }),
  menu: (styles: CSSObjectWithLabel) => ({
    ...styles,
    minHeight: 48,
    padding: 0,
    borderRadius: 0,
  }),
  menuList: (styles: CSSObjectWithLabel) => ({
    ...styles,
    padding: 0,
    backgroundColor: color.light,
    borderRadius: 0,
    boxShadow: 'box-shadow: 0px 2px 4px rgba(0, 0, 0, .2)',
  }),
  option: (styles: CSSObjectWithLabel) => ({
    ...styles,
    transition: 'all .3s ease-in-out',
    transitionProperty: 'color, background-color',
    fontSize: fontSize.body.s,
    cursor: 'pointer',
    '&:hover': {
      color: color.primary[60],
      backgroundColor: color.primary[10],
    },
  }),
  group: (base: CSSObjectWithLabel) => ({
    ...base,
    padding: 0,
  }),
  groupHeading: (base: CSSObjectWithLabel) => ({
    ...base,
    marginBottom: 0,
    padding: '1.2rem 1.2rem  1.2rem 1.2rem',
    backgroundColor: color.neutral[20],
    color: color.neutral[80],
    fontWeight: fontWeight.bold,
    textTransform: 'none',
  }),
};
export default styles;
