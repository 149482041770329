import React, { createElement, FunctionComponent, ReactNode } from 'react';
import * as Styled from './BetterChip.styled';
import { IconType } from 'react-icons';

export type ChipColor = 'primary' | 'secondary' | 'neutral' | 'success' | 'error' | 'warning' | 'information';

type Props = {
  color?: ChipColor;
  children?: string | string[] | number | number[] | ReactNode;
  icon?: IconType;
  title?: string;
  customBackgroundColor?: string;
  $rounded?: boolean;
};

/**
 * You can either pass color of ChipColor or customBackgroundColor. If you pass customBackgroundColor, color will be ignored and text color will be guessed based on contrast.
 * @param children
 * @param icon
 * @param color
 * @param props
 * @param $rounded
 * @constructor
 */
const BetterChip: FunctionComponent<Props> = ({ children, icon, color = 'neutral', ...props }) => {
  return (
    <Styled.InnerChip title={props.title} $color={color} $customBackgroundColor={props.customBackgroundColor} $rounded={props.$rounded}>
      {icon ? createElement(icon) : undefined}
      {children}
    </Styled.InnerChip>
  );
};

export default BetterChip;
