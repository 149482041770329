import React, { FunctionComponent } from 'react';
import * as Styled from './Styles.styled';
import Tooltip from '../../Tooltip/Tooltip';
import Text from '../../../atoms/Text';
import { Icon } from '../../../atoms/Icon/Icon';
import { color } from '../../../../styles/Variables';
import PersonIcon from '../../../../Icons/Project/Person.icon';

type Props = {
  avatarSize: number;
  move: number;
  idx: number;
  name: string;
};

const OutsideAttendee: FunctionComponent<Props> = ({ move, avatarSize, idx, name }) => {
  return (
    <Styled.OutsideAttendee style={{ marginLeft: `${(avatarSize / move) * idx}rem` }} key={idx}>
      <Tooltip
        content={
          <Text color={'light'} size={'xs'}>
            {name}
          </Text>
        }
        trigger={
          <Icon color={color.neutral['70']} size={0.9}>
            <PersonIcon />
          </Icon>
        }
        arrowPosition={'bottomLeft'}
      />
    </Styled.OutsideAttendee>
  );
};

export default OutsideAttendee;
