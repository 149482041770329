import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { GridItem } from '../../../../../atoms/Grid/Grid';
import Button from '../../../../../atoms/Button/Button';
import Subcost from './Subcost';
import { PurchaseOrderType } from '../../../../../../@types/Finances/PurchaseOrder/PurchaseOrderType';
import CardHeading from '../../../../../molecules/CardHeading/CardHeading';
import { MoneyType, sum } from '../../../../../../@types/Money/MoneyType';
import { Card } from '../../../../../atoms/Card/Card';
import moneyAsString from '../../../../../../helpers/moneyAsString';
import { SubcostType } from '../../../../../../@types/Finances/Cost/SubcostType';
import { color } from '../../../../../../styles/Variables';

type Props = {
  name?: string;
  purchaseOrder?: PurchaseOrderType | null;
};

const SubcostsInput: FunctionComponent<Props> = ({ name = 'subcosts', purchaseOrder }) => {
  const { control, watch } = useFormContext();
  const originalAmountNet = watch('originalAmountNet.value');
  const currency = watch('currency');
  const {
    fields: subcosts,
    append,
    remove,
  } = useFieldArray({
    control,
    name,
    keyName: '_id',
  });
  const watchedSubcosts = useWatch({ name, defaultValue: [] });

  const remainNet = useMemo(() => {
    const total = watchedSubcosts.reduce((acc: MoneyType, subcost: SubcostType) => sum(acc, subcost.originalAmountNet), {
      value: 0,
      currency: currency,
    });
    return {
      ...total,
      value: originalAmountNet - total.value,
    };
  }, [watchedSubcosts, currency, originalAmountNet]);

  const handleCreate = useCallback(() => {
    if (!Array.isArray(watchedSubcosts) || watchedSubcosts.length === 0) {
      append({
        id: undefined,
        originalAmountNet: { value: undefined, currency },
        originalAmountGross: { value: undefined, currency },
        purchaseOrder: undefined,
        vat: undefined,
        kind: undefined,
        costType: undefined,
        project: undefined,
        employee: undefined,
      });
    } else {
      const last = watchedSubcosts[watchedSubcosts.length - 1];
      append({
        ...last,
        id: undefined,
        originalAmountNet: { value: undefined, currency },
        purchaseOrder: last.purchaseOrder,
        costType: last.costType,
        project: last.project,
        employee: last.employee,
        budget: last.budget ?? undefined,
      });
    }
  }, [append, watchedSubcosts]);

  const handleRemove = useCallback(
    (idx: number) => {
      remove(idx);
    },
    [remove],
  );

  return (
    <>
      <GridItem
        $desktop={12}
        style={{
          position: 'sticky',
          top: -20,
          zIndex: 10,
          filter: `drop-shadow(1 1 1rem ${color.neutral[20]})`,
        }}
      >
        <Card>
          <CardHeading $noMargin heading={`Remain to assign (Net): ${moneyAsString(remainNet)}`} />
        </Card>
      </GridItem>
      {/* todo: upgraded react-hook-form is more typing strict */}
      {subcosts.map((subcost: any, idx) => (
        <Subcost
          name={`${name}.${idx}`}
          subcost={subcost as SubcostType}
          index={idx}
          onDelete={() => handleRemove(idx)}
          key={subcost._id}
          costPurchaseOrder={purchaseOrder}
        />
      ))}
      <GridItem $desktop={12}>
        <Button full $text={'Add assignment'} onClick={handleCreate} />
      </GridItem>
    </>
  );
};

export default SubcostsInput;
