import { Card } from 'components/atoms/Card/Card';
import React, { FunctionComponent, useState } from 'react';
import { PaymentType } from '../../../@types/Finances/PaymentType';
import { Grid, GridItem } from '../../atoms/Grid/Grid';
import CardHeading from '../../molecules/CardHeading/CardHeading';
import Button from '../../atoms/Button/Button';
import TrashIcon from '../../../Icons/Trash.icon';
import MoneyInput from '../../molecules/Form/MoneyInput/MoneyInput';
import { useFormContext } from 'react-hook-form';
import DatePicker from '../../molecules/Form/DatePicker/DatePicker';
import { PaymentDict } from '../../../@dicts/Payment/PaymentDict';
import HiddenInput from '../../atoms/Form/HiddenInput/HiddenInput';
import StaticSelectInput from '../../molecules/Form/SelectInput/StaticSelectInput';
import Heading from '../../atoms/Heading';

type Props = {
  name: string;
  payment: PaymentType;
  index: number;
  onDelete: () => void;
  currency: string;
};

const Payment: FunctionComponent<Props> = ({ name, payment, onDelete, index, currency }) => {
  return (
    <GridItem $desktop={12} style={{ width: '100%', margin: '2rem 0' }}>
      <div style={{ marginBottom: '1.2rem', display: 'flex', alignItems: 'center' }}>
        <Heading size={'s'}>Payment no. {index + 1}</Heading>
        <Button iconSize={1.5} kind={'link'} $icon={<TrashIcon />} onClick={onDelete} />
      </div>

      <Grid spacing={2}>
        <HiddenInput name={`${name}.id`} value={payment.id} />
        <HiddenInput name={`${name}.isNew`} value={payment.isNew ? 1 : 0} />
        <GridItem $desktop={4}>
          <MoneyInput name={`${name}.value`} currency={currency} label={'Amount'} defaultMoney={payment.value} />
        </GridItem>
        <GridItem $desktop={4}>
          <DatePicker name={`${name}.paidAt`} placeholder={'Payment date'} label={'Payment date'} defaultValue={payment.paidAt} required />
        </GridItem>
        <GridItem $desktop={4}>
          <StaticSelectInput name={`${name}.type`} options={PaymentDict} label={'Payment method'} required defaultValue={payment.type} />
        </GridItem>
      </Grid>
    </GridItem>
  );
};

export default Payment;
