import React, { FunctionComponent, useEffect } from 'react';
import * as Styled from './SectionInlineForm.styled';
import { useFormContext } from 'react-hook-form';
import { SectionType } from '../../../../../../../../../@types/Section/SectionType';

type Props = {
  defaultValue?: string;
  enabled: boolean;
  largeFont?: boolean;
  onEnabledChange: (enabled: boolean) => void;
};

type StateType = {
  enabled: boolean;
  section?: Partial<SectionType>;
};

const SectionInlineInput: FunctionComponent<Props> = ({ defaultValue, enabled, largeFont, onEnabledChange }) => {
  const { register, setValue } = useFormContext();

  useEffect(() => {
    if (!enabled) {
      setValue('name', undefined);
    }
  }, [enabled]);

  return (
    <Styled.InputWrapper>
      <Styled.CustomInput
        onFocus={() => onEnabledChange(true)}
        {...register('name', {
          required: true,
          onBlur: () => onEnabledChange(false),
        })}
        placeholder={enabled ? 'Type section name and hit Enter' : '+ Add section'}
        defaultValue={defaultValue}
        $largeFont={largeFont}
      />
    </Styled.InputWrapper>
  );
};

export default SectionInlineInput;
