import React, { FunctionComponent } from 'react';

type Props = {
  color?: string;
};

const CalendarInCircleIcon: FunctionComponent<Props> = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g>
        <path
          d="M7.53839 18.3333C7.20163 18.3333 6.91659 18.2166 6.68325 17.9833C6.44992 17.7499 6.33325 17.4649 6.33325 17.1281V8.20511C6.33325 7.86835 6.44992 7.5833 6.68325 7.34997C6.91659 7.11664 7.20163 6.99997 7.53839 6.99997H8.46147V5.58972H9.48709V6.99997H14.5384V5.58972H15.5383V6.99997H16.4614C16.7982 6.99997 17.0832 7.11664 17.3166 7.34997C17.5499 7.5833 17.6666 7.86835 17.6666 8.20511V17.1281C17.6666 17.4649 17.5499 17.7499 17.3166 17.9833C17.0832 18.2166 16.7982 18.3333 16.4614 18.3333H7.53839ZM7.53839 17.3333H16.4614C16.5127 17.3333 16.5597 17.3119 16.6025 17.2692C16.6452 17.2264 16.6666 17.1794 16.6666 17.1281V10.8718H7.33324V17.1281C7.33324 17.1794 7.3546 17.2264 7.39734 17.2692C7.44008 17.3119 7.4871 17.3333 7.53839 17.3333ZM7.33324 9.87179H16.6666V8.20511C16.6666 8.15382 16.6452 8.1068 16.6025 8.06406C16.5597 8.02132 16.5127 7.99996 16.4614 7.99996H7.53839C7.4871 7.99996 7.44008 8.02132 7.39734 8.06406C7.3546 8.1068 7.33324 8.15382 7.33324 8.20511V9.87179Z"
          fill={color ?? 'currentColor'}
        />
      </g>
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke={color ?? 'currentColor'} strokeDasharray="2 2" />
    </svg>
  );
};

export default CalendarInCircleIcon;
