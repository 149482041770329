import React, { FunctionComponent, useContext } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import ContractsSubmodule from './Submodules/Contracts/ContractsSubmodule';
import PurchaseOrdersScreen from './Submodules/PurchaseOrders/PurchaseOrdersScreen';
import CostsScreen from './Submodules/Costs/CostsScreen';
import IncomesScreen from './Submodules/Incomes/IncomesScreen';
import { AuthContext } from '../../Context/auth-context/auth-context';
import CostsInboxScreen from './Submodules/CostsInbox/CostsInboxScreen';
import { ContractorsView } from './Submodules/Contractors/screens/ContractorsView';

const FinancesModule: FunctionComponent = () => {
  const location = useLocation();
  let path = location.pathname;
  const { can } = useContext(AuthContext);

  return (
    <Routes>
      <Route path="costs-inbox/*" element={<CostsInboxScreen />}></Route>
      <Route path="incomes/*" element={<IncomesScreen />}></Route>
      <Route path="costs/*" element={<CostsScreen />}></Route>
      <Route path="purchase-orders/*" element={<PurchaseOrdersScreen />}></Route>

      <Route path="vendors/*" element={<ContractorsView />}></Route>
      {can('FINANCES.CONTRACTS.CAN_SEE') && <Route path="contracts/*" element={<ContractsSubmodule />}></Route>}
    </Routes>
  );
};

export default FinancesModule;
