import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import api from '../../../../../services/api';
import { ArrayResponseType } from '../../../../../@types/hydra/hydra';
import AsyncSelect from 'react-select/async';
import { InlineInputDropdownType } from '../InlineInput/InlineInputDropdownType';
import IriHelper from '../../../../../helpers/iri-helper';

const TaskSectionDropdown: InlineInputDropdownType = ({ onChange, name }) => {
  const { control, watch } = useFormContext();
  const value = watch(name);
  const project = watch('project');
  const projectId = useMemo(() => {
    return project ? (typeof project === 'string' ? IriHelper.iriToId(project) : IriHelper.iriToId(project.value)) : null;
  }, [project]);

  useEffect(() => {
    onChange(value);
  }, [value]);

  const loadOptions = useCallback(
    (inputValue: string, callback: Function) => {
      api.get<ArrayResponseType>('/sections', { params: { _search: inputValue, 'project.id': projectId } }).then((response) => {
        callback(
          response.data['hydra:member'].map((i) => {
            return { value: i['@id'], label: i['name'], meta: i };
          }),
        );
      });
    },
    [projectId],
  );

  return (
    <div style={{ width: '20rem' }}>
      <Controller
        key={projectId}
        name={name}
        control={control}
        render={({ field: { onChange } }) => (
          <AsyncSelect
            autoFocus
            menuIsOpen={true}
            isClearable={true}
            loadOptions={loadOptions}
            defaultOptions={true}
            onChange={onChange}
            className={'dropdown'}
          />
        )}
      />
    </div>
  );
};

export default TaskSectionDropdown;
