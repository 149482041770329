import styled, { css } from 'styled-components';
import { color } from 'styles/Variables';

export const Container = styled.div`
  border: solid 1px ${color.neutral[50]} !important;
`;

export const CKEWrapper = styled.div<{ $isActive?: boolean }>`
  max-height: 4.4rem;
  overflow-y: hidden;

  ${({ $isActive }) =>
    $isActive &&
    css`
      max-height: 30rem;
      overflow-y: auto;
    `}
`;

export const Comment = styled.div`
  margin-top: 0.6rem;
  background-color: ${color.neutral[10]};

  * {
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem;
  background-color: ${color.neutral[10]};
`;

const EditorElement = css`
  background-color: ${color.neutral[10]};
  border: none;
  padding: 0.25rem 0.75rem;
  border-radius: 0.5em;
  transition: background-color 0.2s ease-in-out;
  color: ${color.neutral[60]};
  cursor: pointer;

  &:hover {
    transform: none !important;
    background-color: ${color.neutral[20]};
  }
`;

export const CancelButton = styled.button`
  ${EditorElement};
`;

export const AddFileLabel = styled.label`
  ${EditorElement};
`;

export const SendButton = styled.button`
  ${EditorElement};
  background-color: ${color.primary[10]};
  color: ${color.primary[60]};
  display: flex;
  p {
    margin-left: 0.5rem;
    color: ${color.neutral[60]};
  }
  &:hover {
    background-color: ${color.primary[20]};
  }

  &:disabled {
    pointer-events: none;
    background-color: ${color.neutral[10]};
    svg path {
      fill: ${color.neutral[60]};
    }
  }
`;
