import { Card } from 'components/atoms/Card/Card';
import React, { FunctionComponent } from 'react';
import { Grid, GridItem } from '../../atoms/Grid/Grid';
import CardHeading from '../../molecules/CardHeading/CardHeading';
import Button from '../../atoms/Button/Button';
import TrashIcon from '../../../Icons/Trash.icon';
import MoneyInput from '../../molecules/Form/MoneyInput/MoneyInput';
import { useFormContext } from 'react-hook-form';
import HiddenInput from '../../atoms/Form/HiddenInput/HiddenInput';
import { PositionType } from '../../../@types/Finances/PositionType';
import { TextInput } from '../../molecules/Form/TextInput/TextInput';
import StaticSelectInput from '../../molecules/Form/SelectInput/StaticSelectInput';
import { VatDict } from '../../../@dicts/Cost/Vat';
import CalculateGrossButton from '../../atoms/CalculateGross/CalculateGrossButton';

type Props = {
  name: string;
  position: PositionType;
  index: number;
  onDelete: () => void;
};

const Position: FunctionComponent<Props> = ({ name, position, onDelete, index }) => {
  const { watch, setValue } = useFormContext();
  const currency = watch('currency');
  const vat = watch(`${name}.vat`);
  const netAmount = watch(`${name}.originalAmountNet`);

  const calculateGross = () => {
    if (vat > 0) {
      const grossValue = Math.round(netAmount.value * (1 + vat));
      setValue(`${name}.originalAmountGross.value`, grossValue);
    } else {
      setValue(`${name}.originalAmountGross.value`, netAmount.value);
    }
  };

  return (
    <GridItem $desktop={12}>
      <Card>
        <CardHeading
          heading={`Position no. ${index + 1}`}
          action={<Button style={{ transform: 'translateX(19px)' }} iconSize={1.5} kind={'link'} $icon={<TrashIcon />} onClick={onDelete} />}
        />
        <Grid spacing={2} $align={'center'}>
          <HiddenInput name={`${name}.id`} value={position.id} />
          <GridItem $desktop={6}>
            <TextInput name={`${name}.name`} label={'Name'} defaultValue={position.name} required />
          </GridItem>
          <GridItem $desktop={12}></GridItem>
          <GridItem $desktop={4}>
            <MoneyInput name={`${name}.originalAmountNet`} currency={currency} label={'Net amount'} required defaultMoney={position.originalAmountNet} />
          </GridItem>
          <GridItem $desktop={3}>
            <StaticSelectInput name={`${name}.vat`} options={VatDict} label={'VAT'} required defaultValue={position.vat} />
          </GridItem>
          <GridItem $desktop={1} style={{ display: 'flex', justifyContent: 'center' }}>
            <CalculateGrossButton onClick={calculateGross} />
          </GridItem>
          <GridItem $desktop={4}>
            <MoneyInput name={`${name}.originalAmountGross`} currency={currency} label={'Gross amount'} required defaultMoney={position.originalAmountGross} />
          </GridItem>
        </Grid>
      </Card>
    </GridItem>
  );
};

export default Position;
