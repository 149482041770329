import React, { FunctionComponent, useEffect, useMemo } from 'react';
import * as Styled from './OverviewView.styled';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import Timeline from './Timeline/Timeline';
import Container from '../../../../atoms/Container/Container';
import RichText from '../../../../atoms/RichText/RichText';
import { ProjectType } from '../../../../../@types/Project/Project';
import Comments from '../../../../organisms/Comments/Comments';
import ProjectRoles from '../../../../organisms/Projects/ProjectDetails/Overview/ProjectRoles/ProjectRoles';
import ProjectTime from '../../../../organisms/Projects/ProjectDetails/Overview/ProjectTime/ProjectTime';
import { useEntityContext } from '../../../../../Context/EntityContext/EntityContext';
import Chip from '../../../../atoms/Chips/Chip';
import { parseDate } from '../../../../../helpers/date';
import Text from '../../../../atoms/Text/Text';
import Resources from '../../../../organisms/Resources/Resources';
import useUser from '../../../../../hooks/useUser';
import ProjectFinancialData from '../../../../organisms/Projects/ProjectDetails/Overview/ProjectFinancialData/ProjectFinancialData';
import SingleDetail from '../../../../molecules/SingleDetail/SingleDetail';
import Heading from '../../../../atoms/Heading';

type Props = {};

const OverviewView: FunctionComponent<Props> = () => {
  const { entity: project, reload } = useEntityContext<ProjectType>();
  const lastReportDate = useMemo(() => {
    if (!project) return '';
    const date = project['hydra:reports'] ? project['hydra:reports']['project-finances'] : null;
    if (date) {
      return date;
    }
    return '';
  }, [project]);
  const user = useUser();

  useEffect(() => {
    if (!location || !window || !document) return;
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      event: 'project_overview_page',
      pagePathname: location.pathname,
      pageHash: location.hash,
      pageTitle: document.title,
      userDepartment: user?.department?.name,
    });
  }, [window, document, location]);

  return (
    <Styled.OverviewView>
      <Styled.LeftColumn>
        <Container $narrow={'medium'}>
          <Styled.Section>
            <Styled.SectionTitle>About project</Styled.SectionTitle>
            <RichText content={project.description} />
            {project.partnerSideNotificationEmail || project.partnerSideResponsiblePersonEmail || project.nps ? (
              <div
                style={{
                  paddingTop: '3.2rem',
                }}
              >
                <Heading size={'s'} level={4}>
                  Additional information
                </Heading>
                <Grid style={{ marginTop: '1.6rem' }}>
                  {project.partnerSideResponsiblePersonEmail && (
                    <SingleDetail title={'Responsible person email'} size={'s'} bold $desktop={3}>
                      {project.partnerSideResponsiblePersonEmail}
                    </SingleDetail>
                  )}
                  {project.partnerSideNotificationEmail && (
                    <SingleDetail title={'Notifications email'} size={'s'} bold $desktop={3}>
                      {project.partnerSideNotificationEmail}
                    </SingleDetail>
                  )}
                  {project.nps && (
                    <SingleDetail title={'NPS'} size={'s'} bold $desktop={3}>
                      {project.nps}
                    </SingleDetail>
                  )}
                  {project.npsRatedAt && (
                    <SingleDetail title={'NPS Rated at'} size={'s'} bold $desktop={3} type={'date'}>
                      {parseDate(project.npsRatedAt)}
                    </SingleDetail>
                  )}
                </Grid>
              </div>
            ) : null}

            <Styled.Tags>
              {project.tags.map((tag, idx) => (
                <Chip key={idx}>{tag.value}</Chip>
              ))}
            </Styled.Tags>
          </Styled.Section>
          <Styled.Section>
            <Styled.SectionTitle>Roles</Styled.SectionTitle>
            <ProjectRoles roles={project.roles} projectId={project.id} onChange={reload} />
          </Styled.Section>
          <Styled.Section>
            <Styled.SectionTitle>Resources</Styled.SectionTitle>
            <Resources
              customResources={
                project.sharepointUrl && !project.disableSharepointSpace
                  ? [
                      {
                        '@id': '/custom',
                        id: 0,
                        createdBy: project.leader,
                        type: 'sharepoint',
                        '@type': 'Resource',
                        '@context': 'Resource',
                        name: 'Sharepoint space',
                        url: project.sharepointUrl,
                      },
                    ]
                  : []
              }
              subject={'project'}
              subjectId={project.id}
            />
          </Styled.Section>
          {project.financesShown && (
            <Styled.Section>
              <Styled.SectionTitle>Financial overview</Styled.SectionTitle>
              <ProjectFinancialData project={project} />
            </Styled.Section>
          )}
          <Styled.Section>
            <Grid $justifyContent={'space-between'} $align={'baseline'}>
              <GridItem>
                <Styled.SectionTitle>Time</Styled.SectionTitle>
              </GridItem>
              {lastReportDate && (
                <GridItem>
                  <Text size={'xs'}>Last updated at: {parseDate(lastReportDate, true)}</Text>
                </GridItem>
              )}
            </Grid>
            <Grid>
              <ProjectTime project={project} />
              <div style={{ height: '1.6rem' }} />
            </Grid>
          </Styled.Section>
          <Styled.Section>
            <Styled.SectionTitle>Key points</Styled.SectionTitle>
            <Styled.HeadingWrapper>
              <Styled.CommentsWrapper>
                <Comments subject={'project'} subjectId={project.id.toString()} header={false} localStorageKey={project['@id']} />
              </Styled.CommentsWrapper>
            </Styled.HeadingWrapper>
          </Styled.Section>
        </Container>
      </Styled.LeftColumn>
      <Styled.RightColumn>
        <Timeline events={project.events} projectIri={project['@id']} onChange={reload} />
      </Styled.RightColumn>
    </Styled.OverviewView>
  );
};

export default OverviewView;
