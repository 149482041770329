import React, { FunctionComponent } from 'react';
import Container from '../../../components/atoms/Container/Container';
import MyInventoryList from '../../../components/templates/MyInventory/MyInventoryList';
import { Helmet } from 'react-helmet';

type Props = {};

const MyInventorySubmodule: FunctionComponent<Props> = (props) => {
  return (
    <Container>
      <Helmet>
        <title>My Inventory | F.CAPE</title>
      </Helmet>
      <MyInventoryList />
    </Container>
  );
};

export default MyInventorySubmodule;
