import React, { FunctionComponent, ReactElement, useState } from 'react';
import Button, { ButtonType } from '../../atoms/Button/Button';
import DropdownMenu from '../../organisms/DropdownMenu/DropdownMenu';
import { ArrowIcon } from '../../../Icons/arrow.icon';
import * as Styled from './DropdownButton.styled';
import { DropdownMenuItemType } from '../../organisms/DropdownMenu/DropdownMenuItem/DropdownMenuItem';
type DropdownButtonType = ButtonType & {
  dropdownMenuItems: DropdownMenuItemType[];
  triggerType?: 'hover' | 'click';
  $icon?: ReactElement;
};

const DropdownButton: FunctionComponent<DropdownButtonType> = ({ triggerType = 'hover', dropdownMenuItems, kind = 'primary', ...props }) => {
  const [clicked, setClicked] = useState<boolean>(false);

  return (
    <div style={{ marginRight: '2.7rem' }}>
      <Button kind={kind} iconSize={1} {...props}>
        <>
          <DropdownMenu menuItems={dropdownMenuItems} triggerType={triggerType}>
            <Styled.ArrowWrapper
              {...props}
              $kind={kind}
              onClick={() => {
                triggerType === 'click' ? setClicked((prevState) => !prevState) : null;
              }}
            >
              <ArrowIcon direction={triggerType === 'click' ? (clicked ? 'up' : 'down') : 'down'} />
            </Styled.ArrowWrapper>
          </DropdownMenu>
        </>
      </Button>
    </div>
  );
};

export default DropdownButton;
