import styled from 'styled-components';
import { color } from '../../../../../styles/Variables';

export const TagsGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Label = styled.label`
  cursor: pointer;
  display: flex;
  padding: 0.5rem;
  width: 100%;
  :hover {
    background-color: ${color.neutral[30]};
  }
`;

export const Input = styled.input`
  margin: 0;
`;
