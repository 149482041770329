import React, { FunctionComponent, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid, GridItem } from '../../../../../atoms/Grid/Grid';
import { Card } from '../../../../../atoms/Card/Card';
import CardHeading from '../../../../../molecules/CardHeading/CardHeading';
import Button from '../../../../../atoms/Button/Button';
import TrashIcon from '../../../../../../Icons/Trash.icon';
import HiddenInput from '../../../../../atoms/Form/HiddenInput/HiddenInput';
import { CostKindDict } from '../../../../../../@dicts/Cost/CostKind';
import EmployeeInput from '../../../../../molecules/Form/EmployeeInput/EmployeeInput';
import ProjectOption from '../../../../../molecules/Projects/ProjectOption/ProjectOption';
import TextareaInput from '../../../../../molecules/Task/TaskForm/TextareaInput/TextareaInput';
import MoneyInput from '../../../../../molecules/Form/MoneyInput/MoneyInput';
import { PurchaseOrderType } from '../../../../../../@types/Finances/PurchaseOrder/PurchaseOrderType';
import SingleDetail from '../../../../../molecules/SingleDetail/SingleDetail';
import api from '../../../../../../services/api';
import { SubcostType } from '../../../../../../@types/Finances/Cost/SubcostType';
import StaticSelectInput from '../../../../../molecules/Form/SelectInput/StaticSelectInput';
import costTypeOption from '../../../../../molecules/Finances/CostTypeOption';
import PurchaseOrderOption from '../../../../../molecules/Finances/PurchaseOrderOption/PurchaseOrderOption';
import { TextInput } from '../../../../../molecules/Form/TextInput/TextInput';
import ProjectLabel from '../../../../../molecules/Projects/ProjectLabel/ProjectLabel';
import DynamicSelectInput from '../../../../../molecules/Form/SelectInput/DynamicSelectInput';
import PurchaseOrderLabel from '../../../../../molecules/Finances/PurchaseOrderLabel';
import PurchaseOrderSingleDetail from '../../../../../molecules/SingleDetail/PurchaseOrderSingleDetail/PurchaseOrderSingleDetail';
import ProjectSingleDetail from '../../../../../molecules/SingleDetail/ProjectSingleDetail/ProjectSingleDetail';
import { color } from '../../../../../../styles/Variables';

type Props = {
  name: string;
  subcost: SubcostType;
  index: number;
  onDelete: () => void;
  costPurchaseOrder?: PurchaseOrderType | null;
};

const Subcost: FunctionComponent<Props> = ({ name, subcost, onDelete, index, costPurchaseOrder }) => {
  const { watch, unregister, setValue } = useFormContext();
  const kind = watch(`${name}.kind`);
  const currency = watch('currency');
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = React.useState<PurchaseOrderType | undefined | null>(index === 0 ? costPurchaseOrder : undefined);

  useEffect(() => {
    if (kind === 'project') {
      unregister(`${name}.employee`);
    } else if (kind === 'management') {
      unregister(`${name}.project`);
    }
  }, [kind]);

  useEffect(() => {
    if (index !== 0) {
      return;
    }
    setSelectedPurchaseOrder(costPurchaseOrder);
  }, [costPurchaseOrder, index]);

  useEffect(() => {
    if (subcost.branch) {
      return;
    }
    api.get('/api/branches/1').then((response) => {
      setValue(`${name}.branch`, response.data);
    });
  }, [subcost]);

  useEffect(() => {
    if (!selectedPurchaseOrder) {
      return;
    }
    setValue(`${name}.branch`, selectedPurchaseOrder.location);
    if (selectedPurchaseOrder.budgetSymbol) {
      setValue(`${name}.budgetSymbol`, selectedPurchaseOrder.budgetSymbol);
    }
  }, [selectedPurchaseOrder]);

  useEffect(() => {
    if (costPurchaseOrder) {
      return;
    }
    setValue(`${name}.purchaseOrder`, null);
  }, [currency, costPurchaseOrder]);

  return (
    <GridItem $desktop={12}>
      <Card key={costPurchaseOrder?.id}>
        <CardHeading
          heading={`Assignment no. ${index + 1}`}
          action={index > 0 ? <Button $text={'Delete'} kind={'ghost'} $icon={<TrashIcon />} onClick={onDelete} /> : undefined}
        />
        <Grid spacing={2} $align={'center'}>
          <HiddenInput name={`${name}.id`} value={subcost.id} />
          <GridItem $desktop={12}>
            {!costPurchaseOrder || index !== 0 ? (
              <DynamicSelectInput
                optionLabel={'title'}
                defaultValue={index === 0 ? costPurchaseOrder : undefined}
                name={`${name}.purchaseOrder`}
                label={'Purchase order'}
                disabled={!!costPurchaseOrder && index === 0}
                baseUrl={'/api/purchase_orders'}
                optionComponent={PurchaseOrderOption}
                singleValueComponent={PurchaseOrderLabel}
                onChange={(value: any) => {
                  if (!value) {
                    setValue(`${name}.purchaseOrder`, null);
                    setSelectedPurchaseOrder(undefined);
                    return;
                  }
                  setSelectedPurchaseOrder(value?.meta);
                }}
                baseUrlParams={
                  costPurchaseOrder && index !== 0
                    ? {
                        currency: costPurchaseOrder.currency,
                        'company.id': costPurchaseOrder.company.id,
                        'contractor.id': costPurchaseOrder.contractor.id,
                        status: 'accepted',
                        closed: 0,
                      }
                    : {
                        currency: currency,
                        status: 'accepted',
                        closed: 0,
                      }
                }
              />
            ) : (
              <HiddenInput name={`${name}.purchaseOrder`} value={costPurchaseOrder['@id']} />
            )}
          </GridItem>
          {selectedPurchaseOrder && (
            <>
              <GridItem $desktop={12}></GridItem>
              <PurchaseOrderSingleDetail purchaseOrder={selectedPurchaseOrder} $desktop={4} />

              <SingleDetail $desktop={6} title={'Cost type'}>
                {selectedPurchaseOrder.costType.label}
              </SingleDetail>
              <SingleDetail $desktop={4} title={'Amount Net (PO)'} type={'money'}>
                {selectedPurchaseOrder.originalAmountNet.asString}
              </SingleDetail>
              <SingleDetail $desktop={4} title={'Payment terms (PO)'}>
                {selectedPurchaseOrder.paymentTerms}
              </SingleDetail>

              {selectedPurchaseOrder.originalRemainNet ? (
                <SingleDetail $desktop={4} title={'Remain to invoice (PO)'} type={'money'}>
                  {selectedPurchaseOrder.originalRemainNet.asString}
                </SingleDetail>
              ) : (
                <SingleDetail $desktop={4} title={'Remain to invoice'} type={'money'}>
                  -
                </SingleDetail>
              )}
              <GridItem $desktop={12} style={{ borderBottom: `1px solid ${color.neutral[40]}` }}></GridItem>

              <SingleDetail $desktop={4} title={'Cost kind'}>
                {selectedPurchaseOrder.kind}
              </SingleDetail>
              {selectedPurchaseOrder.project && (
                <ProjectSingleDetail name={selectedPurchaseOrder.project.name} id={selectedPurchaseOrder.project.id} $desktop={6} />
              )}
            </>
          )}
          {!selectedPurchaseOrder && (
            <>
              <GridItem $desktop={6}>
                <StaticSelectInput name={`${name}.kind`} options={CostKindDict} label={'Kind'} required defaultValue={subcost.kind} />
              </GridItem>
              <GridItem $desktop={6}>
                <DynamicSelectInput
                  key={kind}
                  optionLabel={'label'}
                  name={`${name}.costType`}
                  baseUrl={'/api/cost_types/'}
                  label={'Cost type'}
                  required
                  baseUrlParams={{ kind }}
                  defaultValue={subcost.costType}
                  optionComponent={costTypeOption}
                />
              </GridItem>
              {kind === 'project' && (
                <GridItem $desktop={6}>
                  <DynamicSelectInput
                    name={`${name}.project`}
                    baseUrl={'/api/projects/'}
                    label={'Project'}
                    required
                    optionComponent={ProjectOption}
                    singleValueComponent={ProjectLabel}
                    defaultValue={subcost.project}
                    baseUrlParams={{
                      status: ['active', 'completed'],
                      archived: 0,
                    }}
                  />
                </GridItem>
              )}
            </>
          )}
          {((!selectedPurchaseOrder && kind === 'management') || selectedPurchaseOrder?.kind === 'management') && (
            <GridItem $desktop={6}>
              <EmployeeInput label={'Employee'} name={`${name}.employee`} group={'organisation'} defaultValue={subcost.employee} />
            </GridItem>
          )}
          <GridItem $desktop={6}>
            <DynamicSelectInput
              name={`${name}.branch`}
              baseUrl={'/api/branches/'}
              optionLabel={'shortName'}
              label={'Location'}
              required
              defaultValue={subcost.branch}
            />
          </GridItem>
          <GridItem $desktop={6}>
            <TextInput name={`${name}.budgetSymbol`} label={'Budget symbol'} defaultValue={subcost.budgetSymbol} />
          </GridItem>
          <GridItem $desktop={12}></GridItem>
          <GridItem $desktop={4}>
            <MoneyInput name={`${name}.originalAmountNet`} currency={currency} label={'Net amount'} required defaultMoney={subcost.originalAmountNet} />
          </GridItem>
          <GridItem $desktop={12}>
            <TextareaInput name={`${name}.description`} placeholder={'Description'} defaultValue={subcost.description} />
          </GridItem>
        </Grid>
      </Card>
    </GridItem>
  );
};

export default Subcost;
