import React, { FunctionComponent } from 'react';
import { Icon } from '../../../../../../../atoms/Icon/Icon';
import { color } from '../../../../../../../../styles/Variables';
import TaskStatus from '../../../../../../../molecules/Statuses/TaskStatus/TaskStatus';
import EditableDetail from '../../../../../../../molecules/EditableDetail/EditableDetail';
import { TaskListType } from '../../../../../../../../@types/Task/TaskType';
import Chip from '../../../../../../../atoms/Chips/Chip';
import PersonTemplate from '../../../../../../../molecules/CellTemplates/common/PersonTemplate/PersonTemplate';
import AvatarInCircleIcon from '../../../../../../../../Icons/AvatarInCircle.icon';
import TaskEmployeeDropdown from '../../../../../../../molecules/Task/TaskForm/TaskEmployeeDropdown/TaskEmployeeDropdown';
import TimeProgress from '../../../../../../../molecules/TimeProgress/TimeProgress';
import TaskEstimationDropdown from '../../../../../../../molecules/Task/TaskForm/TaskEstimationDropdown/TaskEstimationDropdown';
import { parseDate } from '../../../../../../../../helpers/date';
import CalendarInCircleIcon from '../../../../../../../../Icons/CalendarInCircle.icon';
import TaskDeadlineDropdown from '../../../../../../../molecules/Task/TaskForm/TaskDeadlineDropdown/TaskDeadlineDropdown';
import PriorityTag from '../../../../../../../molecules/PriorityTag/PriorityTag';
import PriorityIcon from '../../../../../../../../Icons/Project/Priority.icon';
import TaskPriorityDropdown from '../../../../../../../molecules/Task/TaskForm/TaskPriorityDropdown/TaskPriorityDropdown';
import Tags from '../../../../../../../molecules/Tags';

type Props = {
  task: TaskListType;
};

const SectionTaskDetails: FunctionComponent<Props> = ({ task }) => {
  return (
    <>
      <div>
        <TaskStatus status={task.status} />
      </div>
      <div onClick={(e) => e.stopPropagation()}>
        <EditableDetail<TaskListType>
          name={'assignee'}
          subject={task}
          dropdownPosition={'right'}
          show={
            <>
              {task.assignee ? (
                <Chip $bgColor={'transparent'} $hoverColor={color.neutral['40']}>
                  <PersonTemplate item={task} value={task.assignee} />
                </Chip>
              ) : (
                <Icon size={1.5} onClick={(e) => e.preventDefault()}>
                  <AvatarInCircleIcon color={color.neutral['60']} />
                </Icon>
              )}
            </>
          }
          component={TaskEmployeeDropdown}
        />
      </div>
      <div>
        <EditableDetail<TaskListType>
          name={'estimate'}
          subject={task}
          show={
            <Chip $bgColor={'transparent'} $hoverColor={color.neutral['40']} $equalPaddings>
              <TimeProgress current={task.spentTime} estimated={Number(task.estimate)} unit={'minutes'} width={20} />
            </Chip>
          }
          component={TaskEstimationDropdown}
        />
      </div>
      <div onClick={(e) => e.stopPropagation()}>
        <EditableDetail<TaskListType>
          name={'deadline'}
          dropdownPosition={'right'}
          subject={task}
          show={
            <>
              {task.deadline ? (
                <Chip $bgColor={'transparent'} $hoverColor={color.neutral['40']}>
                  {parseDate(task.deadline)}
                </Chip>
              ) : (
                <Icon size={1.5} onClick={(e) => e.preventDefault()}>
                  <CalendarInCircleIcon color={color.neutral['60']} />
                </Icon>
              )}
            </>
          }
          component={TaskDeadlineDropdown}
        />
      </div>

      <div onClick={(e) => e.stopPropagation()}>
        <EditableDetail<TaskListType>
          name={'priority'}
          dropdownPosition={'right'}
          subject={task}
          show={
            <>
              {task.priority ? (
                <Chip $bgColor={'transparent'} $hoverColor={color.neutral['40']} $equalPaddings>
                  <PriorityTag priority={task.priority} />
                </Chip>
              ) : (
                <Icon size={1.5} onClick={(e) => e.preventDefault()}>
                  <PriorityIcon color={color.neutral['60']} />
                </Icon>
              )}
            </>
          }
          component={TaskPriorityDropdown}
        />
      </div>
      <div>
        <Tags tags={task.tags} />
      </div>
      <div>{task.planned && <Chip $rounded>Planned</Chip>}</div>
    </>
  );
};

export default SectionTaskDetails;
