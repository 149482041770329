import React, { useEffect, useState } from 'react';
import { InlineInputDropdownType } from '../InlineInput/InlineInputDropdownType';
import { Controller, useFormContext } from 'react-hook-form';
import BaseDatePicker from 'react-datepicker';
import { parseDate } from '../../../../../helpers/date';

const TaskDeadlineDropdown: InlineInputDropdownType = ({ name, onChange }) => {
  const { control, watch } = useFormContext();
  const [init, setInit] = useState(false);
  const value = watch(name);
  useEffect(() => {
    if (!init) {
      setInit(true);
      return;
    }
    if (!value) {
      return;
    }
    value.setHours(2);
    onChange({ value: new Date(value).toISOString(), label: parseDate(value) });
  }, [value]);
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange } }) => (
        <BaseDatePicker portalId="data-picker-portal" calendarStartDay={1} autoFocus onChange={onChange} inline className={'dropdown'} />
      )}
    />
  );
};

export default TaskDeadlineDropdown;
