import React, { FunctionComponent } from 'react';

type Props = {
  color?: string;
  size?: number;
};

const HolidayIcon: FunctionComponent<Props> = ({ size = 1, color }) => {
  return (
    <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 22C10.7833 21.85 9.575 21.5208 8.375 21.0125C7.175 20.5042 6.10417 19.775 5.1625 18.825C4.22083 17.875 3.45833 16.675 2.875 15.225C2.29167 13.775 2 12.0333 2 10V9H3C3.85 9 4.725 9.10833 5.625 9.325C6.525 9.54167 7.36667 9.86667 8.15 10.3C8.35 8.86667 8.80417 7.39583 9.5125 5.8875C10.2208 4.37917 11.05 3.08333 12 2C12.95 3.08333 13.7792 4.37917 14.4875 5.8875C15.1958 7.39583 15.65 8.86667 15.85 10.3C16.6333 9.86667 17.475 9.54167 18.375 9.325C19.275 9.10833 20.15 9 21 9H22V10C22 12.0333 21.7083 13.775 21.125 15.225C20.5417 16.675 19.7792 17.875 18.8375 18.825C17.8958 19.775 16.8292 20.5042 15.6375 21.0125C14.4458 21.5208 13.2333 21.85 12 22ZM11.95 19.95C11.7667 17.1833 10.9458 15.0917 9.4875 13.675C8.02917 12.2583 6.21667 11.3833 4.05 11.05C4.23333 13.9 5.07917 16.025 6.5875 17.425C8.09583 18.825 9.88333 19.6667 11.95 19.95ZM12 13.6C12.25 13.2333 12.5542 12.8542 12.9125 12.4625C13.2708 12.0708 13.6167 11.7333 13.95 11.45C13.9167 10.5 13.7292 9.50833 13.3875 8.475C13.0458 7.44167 12.5833 6.43333 12 5.45C11.4167 6.43333 10.9542 7.44167 10.6125 8.475C10.2708 9.50833 10.0833 10.5 10.05 11.45C10.3833 11.7333 10.7333 12.0708 11.1 12.4625C11.4667 12.8542 11.7667 13.2333 12 13.6ZM13.95 19.5C14.5667 19.3 15.2083 19.0083 15.875 18.625C16.5417 18.2417 17.1625 17.7208 17.7375 17.0625C18.3125 16.4042 18.8042 15.5833 19.2125 14.6C19.6208 13.6167 19.8667 12.4333 19.95 11.05C18.3833 11.2833 17.0083 11.8042 15.825 12.6125C14.6417 13.4208 13.7333 14.45 13.1 15.7C13.3 16.2333 13.4708 16.8167 13.6125 17.45C13.7542 18.0833 13.8667 18.7667 13.95 19.5Z"
        fill={color ?? 'currentColor'}
      />
    </svg>
  );
};

export default HolidayIcon;
