import { TableFilterType } from '../../../../@types/Table/TableFilterType';
import FrequentFilters from '../../../../@types/Table/FrequentFilters';
import ContractorOption from '../../../molecules/Finances/ContractorOption';
import ProjectOption from '../../../molecules/Projects/ProjectOption/ProjectOption';
import ProjectLabel from '../../../molecules/Projects/ProjectLabel/ProjectLabel';
import { BillableTypeOptions } from '../../../../modules/projects/submodules/Components/MyProjects.filters';
import { DictValue } from '../../../../@types/Dictionary/DictValue';
import { FinanceStatusType } from '../../../../@types/Finances/FinanceStatusType';
import PurchaseOrderOption from '../../../molecules/Finances/PurchaseOrderOption/PurchaseOrderOption';
import PurchaseOrderLabel from '../../../molecules/Finances/PurchaseOrderLabel';
import DateSelectFilter from '../../../molecules/TableFilters/DateSelectFilter/DateSelectFilter';

const statusOptions: DictValue<null, FinanceStatusType>[] = [
  { value: 'draft', label: 'Draft' },
  { value: 'pending', label: 'Pending' },
  { value: 'accepted', label: 'Accepted' },
  { value: 'rejected', label: 'Rejected' },
];
export const filters: TableFilterType[] = [
  {
    ...FrequentFilters._search,
    placeholder: 'Search by cost, vendor, project',
    gridProps: {
      $desktop: 3,
    },
  },
  {
    type: 'select',
    name: 'cost.contractor.id',
    label: 'Vendor',
    options: {
      baseUrl: '/api/contractors',
      optionComponent: ContractorOption,
    },
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'select',
    name: 'project.id',
    label: 'Project',
    placeholder: 'Select project',
    options: {
      baseUrl: '/api/projects',
      optionComponent: ProjectOption,
      singleValueComponent: ProjectLabel,
    },
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'select',
    name: 'billableType',
    label: 'Project type',
    gridProps: {
      $desktop: 3,
    },
    options: {
      options: BillableTypeOptions,
      isMulti: true,
    },
  },
  {
    type: 'select',
    name: 'status',
    label: 'Status',
    options: {
      options: statusOptions,
    },
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'custom',
    name: 'cost.postedAt',
    label: 'Posting date',
    options: {
      component: DateSelectFilter,
    },
    gridProps: {
      $desktop: 4,
    },
  },
  {
    type: 'select',
    name: 'purchaseOrder.id',
    label: 'Purchase order',
    placeholder: 'Select purchase order',
    options: {
      baseUrl: '/api/purchase_orders',
      optionComponent: PurchaseOrderOption,
      singleValueComponent: PurchaseOrderLabel,
      baseUrlParams: {
        status: 'accepted',
      },
    },
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'text',
    name: 'budgetSymbol',
    label: 'Budget symbol',
    gridProps: {
      $desktop: 2,
    },
  },
];
