import React, { FunctionComponent } from 'react';
import Text from '../../atoms/Text';
import { color } from '../../../styles/Variables';

type Props = {
  count: number;
};

const AbsencesCounter: FunctionComponent<Props> = ({ count }) => {
  return (
    <>
      <Text size={'xs'} color={'grey'} bold>
        Absences: <span style={{ color: color.primary['70'] }}>{count}</span>
      </Text>
    </>
  );
};

export default AbsencesCounter;
