import React, { ReactElement, useCallback, useState } from 'react';
import { EntityType } from '../../../../@types/Entity/EntityType';
import { TableProperties } from '../../../../@types/Table/TableProperty';
import * as Styled from './TableItem.styled';
import TableItemCell from './TableItemCell';
import TableCell from '../../../atoms/Table/TableCell';
import Checkbox from '../../../molecules/Form/Checkbox/Checkbox';
import ExtendedRowComponent from '../../../atoms/Table/ExtendedRowComponent';
import { TableRowActionsType } from '../../../../@types/Table/TableRowActionType';
import TableActions from '../Table/TableActions';
import IriHelper from '../../../../helpers/iri-helper';
import { Icon } from '../../../atoms/Icon/Icon';
import { ArrowIcon } from '../../../../Icons/arrow.icon';
import Form from '../../../molecules/Form/Form';

type Props<T extends EntityType> = {
  item: T;
  properties: TableProperties<any>;
  onItemClick?: (item: T) => void;
  onSelect?: (item: T) => void;
  onDeselect?: (item: T) => void;
  isSelected: boolean;
  selectable?: boolean;
  extendedRow?: ExtendedRowComponent<T>;
  rowActions?: TableRowActionsType<T>;
  itemLink?: (item: T) => string;
};

const TableItem = <T extends EntityType>({
  item,
  properties,
  onItemClick,
  onSelect,
  onDeselect,
  isSelected,
  selectable,
  extendedRow,
  rowActions,
  ...props
}: Props<T>): ReactElement => {
  const [extendedComponent, setExtendedComponent] = useState<ReactElement | ReactElement[] | null>(null);
  const handleItemClick = useCallback(
    (e: any) => {
      if (onItemClick) {
        onItemClick(item);
      } else {
        extend(e);
      }
    },
    [onItemClick, extendedComponent, item],
  );

  const extend = useCallback(
    (e: any) => {
      e.stopPropagation();
      if (!extendedRow) {
        return;
      }
      if (!extendedComponent) {
        const Component = extendedRow;
        setExtendedComponent(<Component entity={item} />);
      } else {
        setExtendedComponent(null);
      }
    },
    [extendedRow, extendedComponent],
  );

  const handleSelect = useCallback(
    (value: boolean) => {
      if (value && onSelect) onSelect(item);
      if (!value && onDeselect) onDeselect(item);
    },
    [onSelect],
  );

  return (
    <>
      <Styled.ItemRow onClick={handleItemClick} $clickable={!!onItemClick} $extended={!!extendedComponent}>
        {!!extendedRow && (
          <TableCell onClick={extend} style={{ width: '2rem' }}>
            <Icon>
              <ArrowIcon direction={extendedComponent ? 'down' : 'right'} />
            </Icon>
          </TableCell>
        )}
        {!!selectable && (
          <TableCell onClick={(e) => e.stopPropagation()} style={{ maxWidth: '1rem' }}>
            <Form onSubmit={() => {}}>
              <Checkbox name={`table-item-${IriHelper.iriToId(item['@id'])}`} onChange={handleSelect} selected={isSelected} />
            </Form>
          </TableCell>
        )}
        {properties.map((property) => (
          <TableItemCell key={`${property.name}-${item.id}`} property={property} item={item} itemLink={props.itemLink} />
        ))}
        {!!rowActions && <TableActions actions={rowActions} item={item} />}
      </Styled.ItemRow>
      {extendedComponent && extendedComponent}
    </>
  );
};

export default TableItem;
