import styled, { css } from 'styled-components';

type FloatingButtonProps = {
  $right?: boolean;
};

export const FloatingButtonsContainer = styled.div<FloatingButtonProps>`
  position: fixed;
  bottom: 24px;
  left: 24px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2rem;
  ${(props: FloatingButtonProps) =>
    props.$right &&
    css`
      left: unset;
      right: 24px;
    `}
`;
export const FloatingButton = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;
