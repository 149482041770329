import React, { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react';
import { ContractSubject, ContractType } from '../../../../@types/Finances/Contracts/ContractType';
import { useLocation, useNavigate } from 'react-router-dom';
import useApi from '../../../../hooks/useApi';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import Button from '../../../atoms/Button/Button';
import EditIcon from '../../../../Icons/edit.icon';
import AcceptIcon from '../../../../Icons/Accept.icon';
import Form from '../../../molecules/Form/Form';
import PageTop from '../../../molecules/Page/PageTop';
import CardHeading from '../../../molecules/CardHeading/CardHeading';
import { Card } from '../../../atoms/Card/Card';
import Select from '../../../molecules/Form/SelectInput/SelectInput';
import { TextInput } from '../../../molecules/Form/TextInput/TextInput';
import { TextAreaInput } from '../../../molecules/Form/TextAreaInput/TextAreaInput';
import DatePicker from '../../../molecules/Form/DatePicker/DatePicker';
import { SwitchInput } from '../../../molecules/Form/SwitchInput/SwitchInput';
import { ContractTypeDict } from '@dicts/Contract/ContractTypeDict';
import { ContractAppliesDict } from '@dicts/Contract/ContractAppliesDict';
import { ResourceType } from '../../../../@types/Resource/ResourceType';
import ResourceDropzone from '../../../organisms/Resources/ResourceDropzone';
import { AuthContext } from '../../../../Context/auth-context/auth-context';
import StaticSelectInput from '../../../molecules/Form/SelectInput/StaticSelectInput';
import { SidebarContext } from '../../../../Context/Sidebar/SidebarContext';
import ProjectOption from '../../../molecules/Projects/ProjectOption/ProjectOption';
import ProjectLabel from '../../../molecules/Projects/ProjectLabel/ProjectLabel';
import ContractorOption from '../../../molecules/Finances/ContractorOption';
import PurchaseOrderOption from '../../../molecules/Finances/PurchaseOrderOption/PurchaseOrderOption';
import PurchaseOrderLabel from '../../../molecules/Finances/PurchaseOrderLabel';
import FinanceColumn from '../../../molecules/Finances/FinanceColumn';
import { Helmet } from 'react-helmet';

type Props = {
  contract?: ContractType;
  onChange?: () => void;
};

const ContractForm: FunctionComponent<Props> = ({ contract, onChange }) => {
  const [loading, setLoading] = useState(false);
  const [uploadedResources, setUploadedResources] = useState<ResourceType[]>([]);
  const { can } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { isOpen, closeSidebar } = useContext(SidebarContext);

  const pageHeading = useMemo(() => {
    if (!contract) {
      return 'Add new contract';
    } else {
      return `#${contract.id} ${contract.title}`;
    }
  }, [contract]);
  const { post, put } = useApi();

  const handleSubmit = (data: ContractType) => {
    setLoading(true);
    data.uploadedResources = uploadedResources.map((resource) => resource.id);
    closeSidebar();

    if (!contract) {
      post('/api/contracts', data)
        .then((response) => {
          setLoading(false);
          navigate(location.pathname.replace('create', `${response.data.id}/show`));
        })
        .catch(() => setLoading(false));
    } else {
      put(`${contract['@id']}`, data)
        .then((response) => {
          navigate(location.pathname.replace('edit', 'show'));
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  };

  const PageActions = () => {
    return (
      <Grid spacing={2} $align={'center'}>
        <Helmet>
          <title>{contract ? 'Edit contract' : 'Create contract'} | F.CAPE</title>
        </Helmet>
        <GridItem $desktop={'auto'}>
          <Button
            $text={loading ? 'Saving...' : 'Save contract'}
            waiting={loading}
            kind={'primary'}
            type={'submit'}
            $icon={contract ? <EditIcon /> : <AcceptIcon />}
          />
        </GridItem>
        <GridItem $desktop={'auto'}>
          <Button
            $text={'Cancel'}
            kind={'secondary'}
            onClick={() => {
              navigate(-1);
              closeSidebar();
            }}
          />
        </GridItem>
      </Grid>
    );
  };

  return (
    <>
      <Form onSubmit={(data) => handleSubmit(data)} defaultValues={contract ?? {}}>
        {({ watch, unregister }) => {
          const applies = watch('applies');
          const contractor = watch('contractor');

          useEffect(() => {
            if (applies === 'employee') {
              unregister('detailsPartner');
              unregister('detailsProject');
              unregister('detailsPurchaseOrder');
            } else if (applies === 'partner') {
              unregister('detailsEmployee');
              unregister('detailsProject');
              unregister('detailsPurchaseOrder');
            } else if (applies === 'project') {
              unregister('detailsEmployee');
              unregister('detailsPartner');
              unregister('detailsPurchaseOrder');
            } else if (applies === 'purchase_order') {
              unregister('detailsEmployee');
              unregister('detailsPartner');
              unregister('detailsProject');
            } else if (applies === 'general') {
              unregister('detailsEmployee');
              unregister('detailsPartner');
              unregister('detailsProject');
              unregister('detailsPurchaseOrder');
            }
          }, [applies]);
          return (
            <Grid spacing={4} style={{ marginBottom: '2rem' }}>
              <GridItem $desktop={12}>
                <PageTop heading={pageHeading} rightColumn={<PageActions />} paddingBottom={'0'} isPdfOpen={isOpen} />
              </GridItem>

              <FinanceColumn>
                <GridItem $desktop={12}>
                  <Card>
                    <CardHeading heading={'Basic info'} />
                    <Grid spacing={4}>
                      <GridItem $desktop={6}>
                        <Select name={'contractor'} label={'Contractor'} baseUrl={'/api/contractors/'} required optionComponent={ContractorOption} />
                      </GridItem>
                      <GridItem $desktop={6}>
                        <Select name={'branch'} label={'Location'} optionLabel={'shortName'} baseUrl={'/api/branches/'} required />
                      </GridItem>
                      <GridItem $desktop={12}>
                        <TextInput name={'title'} label={'Title'} placeholder={'Contract title'} required />
                      </GridItem>
                      <GridItem $desktop={12}>
                        <TextAreaInput name={'description'} label={'Contract description'} placeholder={'Contract description'} rows={5} />
                      </GridItem>
                      <GridItem $desktop={12}>
                        <Select name={'owner'} label={'Owner'} baseUrl={'/api/employees/'} baseUrlParams={{ status: ['active'] }} required />
                      </GridItem>
                      <GridItem $desktop={6}>
                        <Select name={'company'} label={'Company'} baseUrl={'/api/financial_companies/'} required />
                      </GridItem>
                    </Grid>
                  </Card>
                </GridItem>
                <GridItem $desktop={12}>
                  <Card>
                    <CardHeading heading={'Details'} />
                    <Grid spacing={4}>
                      <GridItem $desktop={6}>
                        <TextInput name={'number'} label={'Contract number'} placeholder={'Contract number'} />
                      </GridItem>
                      <GridItem $desktop={6}>
                        {!!contractor && (
                          <Select
                            name={'parentContract'}
                            label={'Parent contract'}
                            optionLabel={'title'}
                            baseUrl={'/api/contracts/'}
                            baseUrlParams={{ 'contractor.id': contractor['@id'] }}
                          />
                        )}
                      </GridItem>
                      <GridItem $desktop={6}>
                        <TextInput name={'binderReference'} label={'Binder reference'} placeholder={'Binder reference'} />
                      </GridItem>
                      <GridItem $desktop={6} />
                      <GridItem $desktop={6}>
                        <DatePicker name={'date'} label={'Contract date'} placeholder={'Choose contract date'} required />
                      </GridItem>
                      <GridItem $desktop={6}>
                        <DatePicker name={'dateSigned'} label={'Contract signing date'} placeholder={'Choose contract signing date'} />
                      </GridItem>
                      <GridItem $desktop={6}>
                        <StaticSelectInput name={'type'} label={'Contract type'} options={ContractTypeDict} required />
                      </GridItem>
                      <GridItem $desktop={6}>
                        <StaticSelectInput name={'applies'} label={'Contract applies'} options={ContractAppliesDict} required />
                      </GridItem>
                      {!!applies && applies === 'partner' && (
                        <GridItem $desktop={12}>
                          <Select name={'detailsPartner'} label={'Partner'} baseUrl={'/api/clients/'} required />
                        </GridItem>
                      )}
                      {!!applies && applies === 'project' && (
                        <GridItem $desktop={12}>
                          <Select
                            name={'detailsProject'}
                            label={'Project'}
                            baseUrl={'/api/projects/'}
                            required
                            optionComponent={ProjectOption}
                            singleValueComponent={ProjectLabel}
                            baseUrlParams={{
                              status: ['active'],
                              archived: 0,
                            }}
                          />
                        </GridItem>
                      )}
                      {!!applies && applies === 'purchase_order' && (
                        <GridItem $desktop={12}>
                          <Select
                            name={'detailsPurchaseOrder'}
                            label={'Purchase order'}
                            baseUrl={'/api/purchase_orders/'}
                            optionLabel={'title'}
                            required
                            optionComponent={PurchaseOrderOption}
                            singleValueComponent={PurchaseOrderLabel}
                          />
                        </GridItem>
                      )}
                      {!!applies && applies === 'employee' && (
                        <GridItem $desktop={12}>
                          <Select name={'detailsEmployee'} label={'Employee'} baseUrl={'/api/employees/'} required />
                        </GridItem>
                      )}
                      {can('FINANCES.CONTRACTS.CAN_MANAGE_CONFIDENTIAL') && (
                        <GridItem $desktop={12}>
                          <SwitchInput name={'confidential'} label={'Confidential contract'} />
                        </GridItem>
                      )}
                    </Grid>
                  </Card>
                </GridItem>
              </FinanceColumn>
              {isOpen && <GridItem $desktop={12} />}
              <FinanceColumn>
                <GridItem $desktop={12}>
                  <Card>
                    <CardHeading heading={'Documents'} />
                    <Grid spacing={4}>
                      <ResourceDropzone
                        subject={ContractSubject}
                        subjectId={contract ? contract.id : undefined}
                        context={'finances'}
                        onChange={(data) => setUploadedResources(data)}
                      />
                    </Grid>
                  </Card>
                </GridItem>
              </FinanceColumn>
            </Grid>
          );
        }}
      </Form>
    </>
  );
};

export default ContractForm;
