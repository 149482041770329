import { Grid, GridItem } from '../../atoms/Grid/Grid';
import Text from '../../atoms/Text';
import React, { FunctionComponent, useCallback } from 'react';
import { MeetingType } from '../../../@types/Meeting/MeetingType';
import { Icon } from '../../atoms/Icon/Icon';
import { color } from '../../../styles/Variables';
import ClockIcon from '../../../Icons/Clock.icon';
import { parseDate } from '../../../helpers/date';
import LocationIcon from '../../../Icons/Location.icon';
import Attendees from '../../molecules/Attendees/Attendees';
import Button from '../../atoms/Button/Button';
import CalendarIcon from '../../../Icons/Calendar.icon';
import AddWorkIcon from '../../../Icons/AddWork.icon';
import useModal from '../../../hooks/useModal';
import AddToTimesheetModal from '../AddToTimesheetModal/AddToTimesheetModal';

export const MeetingsSubtitle = (meeting: MeetingType, onClick?: () => void) => (
  <div
    onClick={onClick}
    style={{
      cursor: 'pointer',
      position: 'relative',
      zIndex: '10',
      marginTop: '0.6rem',
    }}
  >
    <Grid spacing={0.3}>
      <GridItem>
        <Text color={'semiGrey'}>{meeting.project.client.name}</Text>
      </GridItem>
      <GridItem>
        <Text color={'semiGrey'}>/</Text>
      </GridItem>
      <GridItem>
        <Text color={'primary'}>#{meeting.project.id}</Text>
      </GridItem>
      <GridItem>
        <Text color={'semiGrey'}>{meeting.project.name}</Text>
      </GridItem>
    </Grid>
  </div>
);

type Props = {
  meeting: MeetingType;
};

const MeetingsBody: FunctionComponent<Props> = ({ meeting }) => {
  const { showModal } = useModal();

  const handleAddToTimesheetModal = useCallback(() => {
    showModal({
      title: 'Add to timesheet',
      body: <AddToTimesheetModal meeting={meeting} />,
      width: 45,
    });
  }, [meeting]);

  return (
    <Grid spacing={4}>
      <GridItem $desktop={12} style={{ marginTop: '2rem' }}>
        <Grid $align={'center'}>
          <GridItem $desktop={6}>
            <Text size={'s'} color={'grey'}>
              Start time
            </Text>
            <Grid spacing={0.3} $align={'center'}>
              <GridItem style={{ marginRight: '0.6rem' }}>
                <Icon color={color.neutral[60]} size={0.8}>
                  <ClockIcon />
                </Icon>
              </GridItem>
              <GridItem>{meeting.start && <Text color={'semiGrey'}>{parseDate(new Date(meeting.start), false, false, false, true)}</Text>}</GridItem>
              <GridItem>
                <Text color={'semiGrey'}>-</Text>
              </GridItem>
              <GridItem>{meeting.end && <Text color={'semiGrey'}>{parseDate(new Date(meeting.end), false, false, false, true)}</Text>}</GridItem>
            </Grid>
          </GridItem>
          <GridItem $desktop={6}>
            <Text size={'s'} color={'grey'}>
              Location
            </Text>
            <Grid $align={'center'}>
              <GridItem style={{ marginRight: '0.9rem' }}>
                <Icon size={0.8} color={color.neutral[60]}>
                  <LocationIcon />
                </Icon>
              </GridItem>
              <GridItem>
                <Text color={'semiGrey'}>
                  {meeting.location && meeting.location.displayName.length === 0 ? 'Online meeting' : meeting.location.displayName}
                </Text>
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
      </GridItem>
      {meeting.description && meeting.description.length > 0 ? (
        <GridItem $desktop={12}>
          <Text size={'s'} color={'grey'}>
            {meeting.description}
          </Text>
        </GridItem>
      ) : null}
      <GridItem>
        {meeting.attendees && meeting.attendees.length > 0 ? (
          <Text size={'s'} color={'grey'}>
            Attendees
          </Text>
        ) : null}
      </GridItem>
      {meeting.attendees && meeting.attendees.length > 0 ? (
        <GridItem $desktop={12} style={{ paddingTop: 0 }}>
          <Attendees attendees={meeting.attendees} />
        </GridItem>
      ) : null}
      <GridItem $desktop={6}>
        <Button
          $text={'Join meeting'}
          $icon={<CalendarIcon />}
          onClick={() => {
            window.open(meeting.webLink, '_blank');
          }}
        />
      </GridItem>
      <GridItem $desktop={6} style={{ display: 'flex', justifyContent: 'end' }}>
        <Button $text={'Add to timesheet'} $icon={<AddWorkIcon />} kind={'ghost'} onClick={() => handleAddToTimesheetModal()} />
      </GridItem>
    </Grid>
  );
};

export default MeetingsBody;
