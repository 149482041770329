import React, { FunctionComponent } from 'react';

type PropType = {
  size?: number;
  color?: string;
};

const ClockIcon: FunctionComponent<PropType> = ({ size = 1, color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <g>
        <path
          d="M7.275 8.27498L5.5 6.49998V3.99998H6.5V6.08748L7.975 7.56248L7.275 8.27498ZM5.5 2.99998V1.99998H6.5V2.99998H5.5ZM9 6.49998V5.49998H10V6.49998H9ZM5.5 9.99998V8.99998H6.5V9.99998H5.5ZM2 6.49998V5.49998H3V6.49998H2ZM6 11C5.30833 11 4.65833 10.8687 4.05 10.6062C3.44167 10.3437 2.9125 9.98748 2.4625 9.53749C2.0125 9.08749 1.65625 8.55832 1.39375 7.94999C1.13125 7.34165 1 6.69165 1 5.99998C1 5.30832 1.13125 4.65832 1.39375 4.04998C1.65625 3.44165 2.0125 2.91248 2.4625 2.46248C2.9125 2.01248 3.44167 1.65623 4.05 1.39373C4.65833 1.13123 5.30833 0.999985 6 0.999985C6.69167 0.999985 7.34167 1.13123 7.95 1.39373C8.55833 1.65623 9.0875 2.01248 9.5375 2.46248C9.9875 2.91248 10.3438 3.44165 10.6062 4.04998C10.8687 4.65832 11 5.30832 11 5.99998C11 6.69165 10.8687 7.34165 10.6062 7.94999C10.3438 8.55832 9.9875 9.08749 9.5375 9.53749C9.0875 9.98748 8.55833 10.3437 7.95 10.6062C7.34167 10.8687 6.69167 11 6 11ZM6 9.99998C7.11667 9.99998 8.0625 9.61248 8.8375 8.83748C9.6125 8.06248 10 7.11665 10 5.99998C10 4.88332 9.6125 3.93748 8.8375 3.16248C8.0625 2.38748 7.11667 1.99998 6 1.99998C4.88333 1.99998 3.9375 2.38748 3.1625 3.16248C2.3875 3.93748 2 4.88332 2 5.99998C2 7.11665 2.3875 8.06248 3.1625 8.83748C3.9375 9.61248 4.88333 9.99998 6 9.99998Z"
          fill={color ? color : 'currentColor'}
        />
      </g>
    </svg>
  );
};

export default ClockIcon;
