import React, { FunctionComponent, ReactElement, ReactFragment } from 'react';
import * as Styled from './Link.styled';
import { TextType } from '../Text/Text.styled';

type PropType = {
  children: ReactElement | ReactFragment;
  href: string;
  target?: '_blank';
  size?: TextType['$size'];
};

const Link: FunctionComponent<PropType> = ({ children, href, target, size }) => {
  return (
    <Styled.Link to={href} target={target} rel={target && 'noopener'} $size={size}>
      <>{children}</>
    </Styled.Link>
  );
};

export default Link;
