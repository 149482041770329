import React, { useMemo } from 'react';
import { CellTemplate } from '../CellTemplate';
import { calculateDaysFromToday } from '../../../../helpers/calculateDays';
import { WarrantyStatus } from '../../../../@types/Inventory/InventoryCommonTypes';
import { Icon } from '../../../atoms/Icon/Icon';
import { Sandglass, SandStatus } from '../../../../Icons/Sandglass.icon';
import { color } from '../../../../styles/Variables';
import Chip from '../../../atoms/Chips/Chip';
import Tooltip from '../../Tooltip/Tooltip';
import Text from '../../../atoms/Text/Text';
import { parseDate } from '../../../../helpers/date';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';

const GuaranteeTemplate: CellTemplate<string> = ({ value }) => {
  const warrantyStatus: {
    label: WarrantyStatus;
    sandglassStatus: SandStatus;
    bgColor: string;
    sandColor: string;
  } = useMemo(() => {
    if (calculateDaysFromToday(value) > 30) {
      return {
        label: 'Active',
        sandglassStatus: 'fullFilled',
        bgColor: color.semantic.success['20'],
        sandColor: color.secondary['70'],
      };
    }
    if (calculateDaysFromToday(value) < 0) {
      return {
        label: 'Expired',
        sandglassStatus: 'crossed',
        bgColor: color.semantic.error['20'],
        sandColor: color.semantic.error['100'],
      };
    }

    if (calculateDaysFromToday(value) <= 30) {
      return {
        label: 'Expiring',
        sandglassStatus: 'halfFilled',
        bgColor: color.semantic.warning['20'],
        sandColor: color.semantic.warning['100'],
      };
    }
    return {
      label: 'Expired',
      sandglassStatus: 'crossed',
      bgColor: color.semantic.warning['20'],
      sandColor: color.semantic.warning['100'],
    };
  }, [value]);

  return (
    <Tooltip
      content={
        <Grid spacing={0.6}>
          <GridItem>
            <Text color={'grey'} size={'s'}>
              Expiring:&nbsp;
            </Text>
          </GridItem>
          <GridItem>
            <Text size={'s'} color={'light'}>
              {parseDate(value)}
            </Text>
          </GridItem>
        </Grid>
      }
      trigger={
        <Grid>
          <GridItem>
            <Chip $bgColor={warrantyStatus.bgColor}>
              <Icon size={1} color={warrantyStatus.sandColor}>
                <Sandglass sandStatus={warrantyStatus.sandglassStatus} sandColor={warrantyStatus.sandColor} />
              </Icon>
              <Text color={'darkGrey'} size={'s'}>
                {warrantyStatus.label}
              </Text>
            </Chip>
          </GridItem>
        </Grid>
      }
      arrowPosition={'topCenter'}
    />
  );
};

export default GuaranteeTemplate;
