import styled from 'styled-components';
import { color } from 'styles/Variables';
import { Text, TextType } from '../Text/Text.styled';
import { Link as ReactLink, LinkProps } from 'react-router-dom';
import * as React from 'react';

export const Link = styled(ReactLink)<LinkProps & React.RefAttributes<HTMLAnchorElement> & TextType>`
  ${Text};
  display: inline-flex;
  align-items: center;
  gap: 0.5em;
  transition: 0.2s;
  color: ${color.primary[60]};
  cursor: pointer;

  &:hover {
    color: ${color.primary[80]};
  }
`;
