import React, { FunctionComponent, useMemo } from 'react';
import * as Styled from './TimelineEvent.styled';
import Text from '../../../../../../atoms/Text/Text';
import { parseDate } from '../../../../../../../helpers/date';
import { ProjectEventType } from '../../../../../../../@types/Project/ProjectEventType';
import { Icon } from '../../../../../../atoms/Icon/Icon';
import BigCalendarInTheCircleIcon from '../../../../../../../Icons/TimelineIcons/BigCalendarInTheCircle.icon';
import MilestoneIcon from '../../../../../../../Icons/TimelineIcons/Milestone.icon';
import { color } from '../../../../../../../styles/Variables';
import PeopleIcon from '../../../../../../../Icons/TimelineIcons/People.icon';
import EditIcon from '../../../../../../../Icons/edit.icon';
import Attendees from '../../../../../../molecules/Attendees/Attendees';
import TrashIcon from '../../../../../../../Icons/Trash.icon';
import useApi from '../../../../../../../hooks/useApi';
type Props = {
  event: ProjectEventType;
  isLastEvent: boolean;
  openProjectEventModal: (event: ProjectEventType) => void;
  onChange: () => void;
};

const TimelineEvent: FunctionComponent<Props> = ({ event, isLastEvent, openProjectEventModal, onChange }) => {
  const { del } = useApi();
  const eventIcon: JSX.Element = useMemo(() => {
    switch (event.type) {
      case 'milestone':
        return <MilestoneIcon color={color.secondary[80]} />;
      case 'meeting':
        return <BigCalendarInTheCircleIcon color={color.neutral[60]} />;
      case 'role':
        return <PeopleIcon color={color.neutral[60]} />;
      case 'end':
        return <BigCalendarInTheCircleIcon color={color.secondary[80]} />;
      default:
        return <BigCalendarInTheCircleIcon color={color.secondary[80]} />;
    }
  }, [event.type]);

  const deleteMilestone = () => {
    del(`/milestones/${event.subjectId}`).then(() => onChange());
  };

  return (
    <Styled.TimelineEvent>
      <Styled.LeftColumn>
        <Icon size={2.5}>{eventIcon}</Icon>
        {!isLastEvent && <Styled.Line />}
      </Styled.LeftColumn>
      <Styled.RightColumn>
        <Styled.TitleWrapper>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text bold={true} size={'l'}>
              {event.type == 'start'
                ? 'Project created'
                : event.type === 'end'
                  ? 'Deadline'
                  : event.type !== 'role'
                    ? event.title
                    : `${event.leader?.name} joined to project`}
            </Text>
            <div>
              {event.leader && event.type !== 'role' && (
                <Text size={'s'} bold={true}>
                  {`${event.leader.firstName} ${event.leader.lastName}`}
                </Text>
              )}
              <Text color={'grey'} size={'s'}>
                {parseDate(event.date)}
              </Text>
            </div>
          </div>

          <div>
            {event.type === 'milestone' && (
              <Icon onClick={() => openProjectEventModal(event)}>
                <EditIcon />
              </Icon>
            )}
            {event.type === 'milestone' && (
              <Icon onClick={deleteMilestone}>
                <TrashIcon />
              </Icon>
            )}
          </div>
        </Styled.TitleWrapper>
        {event.participants && (
          <Styled.Participants>
            <Attendees attendees={event.participants} />
          </Styled.Participants>
        )}
      </Styled.RightColumn>
    </Styled.TimelineEvent>
  );
};

export default TimelineEvent;
