import React, { FunctionComponent } from 'react';
import Heading from '../../../atoms/Heading';
import RichText from '../../../atoms/RichText/RichText';
import { FeedEntryType } from '../../../../@types/FeedEntry/FeedEntryType';

type Props = {
  feedEntry: FeedEntryType;
  forceShow: boolean;
};

const FeedEntryBody: FunctionComponent<Props> = ({ feedEntry, forceShow }) => {
  return (
    <>
      <Heading size={'l'}>{feedEntry.title}</Heading>
      <RichText content={feedEntry.content} wordsLimit={!forceShow ? 50 : undefined} />
    </>
  );
};

export default FeedEntryBody;
