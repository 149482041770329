import React, { useContext } from 'react';
import api from '../../services/api';
import { AxiosResponse } from 'axios';
import serverEvents from '../../services/serverEvents';
import useUser from '../useUser';
import { AuthContext } from '../../Context/auth-context/auth-context';

const useAbsenceRequestCount: () => number = () => {
  const [count, setCount] = React.useState(0);
  const user = useUser();
  const {
    state: { context },
  } = useContext(AuthContext);
  const load = React.useCallback(() => {
    api.get('/absences-count').then((r: AxiosResponse<{ count: number }>) => {
      setCount(r.data.count);
    });
  }, []);

  React.useEffect(() => {
    if (context !== 'employee') {
      return;
    }
    load();
    const subscription = serverEvents.listen('/api/absence_requests').subscribe(() => load());

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return count;
};

export default useAbsenceRequestCount;
