import { DropdownMenuItemType } from '../../../../organisms/DropdownMenu/DropdownMenuItem/DropdownMenuItem';
import PaidIcon from '../../../../../Icons/Paid.icon';
import React from 'react';
import useModal from '../../../../../hooks/useModal';
import ModalForm from '../../../../organisms/Form/ModalForm/ModalForm';
import MonthPicker from '../../../Form/MonthPicker/MonthPicker';
import { Icon } from '../../../../atoms/Icon/Icon';
import { color } from '../../../../../styles/Variables';
import { useEntityContext } from '../../../../../Context/EntityContext/EntityContext';
import { ProjectType } from '../../../../../@types/Project/Project';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import { SwitchInput } from '../../../Form/SwitchInput/SwitchInput';

export const SettleButton = (id: number): DropdownMenuItemType => {
  const { showModal } = useModal();
  const { replace } = useEntityContext<ProjectType>();

  return {
    icon: (
      <Icon color={color.neutral[70]}>
        <PaidIcon />
      </Icon>
    ),
    text: 'Settled',
    onClick: () => {
      showModal({
        title: 'Settle project',
        body: (
          <ModalForm basePath={`/api/projects/${id}/settle`} buttonText={'Settle'} onChange={replace}>
            <Grid>
              <GridItem $desktop={12}>
                <MonthPicker name={'settlementDate'} required label={'Pick settlement date'} />
                <div style={{ height: '0.5rem' }} />
                <SwitchInput name={'archived'} label={'Archive project'} />
              </GridItem>
            </Grid>
          </ModalForm>
        ),
        width: 42,
      });
    },
  };
};
