import styled, { css } from 'styled-components';
import { color, fontSize, fontWeight } from '../../../../styles/Variables';

export const TableFiltersContainer = styled.div`
  background-color: ${color.light};
  padding: 3rem 2.4rem 1.8rem;
  border-radius: 8px 8px 0 8px;
`;

export const TableFiltersFooterCommon = css`
  border: none;
  background-color: ${color.light};
  padding: 1rem 1.8rem;
`;

export const TableStatus = styled.div<{ $visible: boolean }>`
  ${TableFiltersFooterCommon};
  margin-right: auto;
  color: ${color.primary[60]};
  border-radius: 0 0 8px 8px;
  font-size: ${fontSize.body.s};
  opacity: ${(props) => (props.$visible ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
`;
export const TableFiltersResetButton = styled.button`
  ${TableFiltersFooterCommon};
  margin-left: auto;
  cursor: pointer;
  color: ${color.semantic.error[100]};
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.body.l};
  transition: background-color 0.2s;
  border-radius: 0 0 8px 8px;
  :hover {
    background-color: ${color.primary[20]};
  }
`;
