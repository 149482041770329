import { useQuery as baseUseQuery, useQueryClient } from 'react-query';
import { useCallback, useEffect } from 'react';
import api from '../services/api';
import qs from 'qs';
import serverEvents from '../services/serverEvents';

type Params = {
  context: string;
  url: string;
  params?: { [key: string]: string | number };
};

const createUrl = (url: string, params?: Params['params']) => {
  let _url = `${url}`;
  if (params) {
    _url += `?${qs.stringify(params)}`;
  }
  return _url;
};

const fetchData = async <T>(url: string, params?: Params['params']) => {
  const _url = createUrl(url, params);
  return api.get<T>(_url).then((response) => {
    return response.data;
  });
};

function useQuery<T>(context: string, url: string, params?: { [key: string]: string | number }) {
  const queryResponse = baseUseQuery<T>([context, params], () => fetchData<T>(url), {
    keepPreviousData: true,
  });
  const client = useQueryClient();

  const invalidate = useCallback(() => {
    client.invalidateQueries({
      queryKey: [context, params],
    });
  }, [client]);

  useEffect(() => {
    const subscription = serverEvents.listen(context).subscribe(() => {
      invalidate();
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return { ...queryResponse, invalidate, client };
}

export default useQuery;
