import React, { FunctionComponent, useEffect, useState } from 'react';
import { InputProps } from '../../../../@types/Form/InputProps';
import { Controller, FormState, useFormContext } from 'react-hook-form';
import FormError from '../../../atoms/Form/FormError/FormError';
import { FormHtmlInput, FormInputContainer, FormInputSuffix, FormLabel, SuffixWrapper } from '../../../atoms/Form/InputParts/InputParts';
import useFormError from '../../../../hooks/Form/useFormError';
import { MoneyType } from '../../../../@types/Money/MoneyType';

type MoneyInputProps = InputProps & {
  currency: string;
  suffix?: boolean;
  defaultMoney?: MoneyType;
};

const MoneyInput: FunctionComponent<MoneyInputProps> = ({
  name,
  label,
  placeholder,
  disabled = false,
  defaultMoney,
  required = false,
  currency,
  onChange: componentOnChange,
}) => {
  const formMethods = useFormContext();
  const error = useFormError(name);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    formMethods.setValue(`${name}.currency`, currency);
  }, [currency]);

  const divideValue = (value: number | '', state: FormState<any>) => {
    if (value === null) return '';
    if (value === '') return '';
    if (state.isDirty || focused) {
      return Math.round(value) / 100;
    } else {
      if (value === 0) return 0;
      return (Math.round(value) / 100).toFixed(2);
    }
  };

  return (
    <>
      <FormInputContainer status={error && 'error'}>
        <FormLabel status={error && 'error'} required={required}>
          {label}
        </FormLabel>
        <Controller
          defaultValue={defaultMoney?.value ?? null}
          control={formMethods.control}
          name={`${name}.value`}
          render={({ field: { onChange, value }, formState }) => (
            <SuffixWrapper $arrows={false}>
              <FormHtmlInput
                type={'number'}
                step={0.01}
                status={error && 'error'}
                onFocus={() => setFocused(true)}
                onBlur={() => {
                  setFocused(false);
                }}
                placeholder={placeholder ?? label ?? name}
                disabled={disabled}
                value={divideValue(value, formState)}
                onChange={(e) => {
                  let parsed: string | number = Math.round(+e.target.value * 100);
                  const parsedFloat = parseFloat(e.target.value);
                  if (isNaN(parsedFloat)) {
                    parsed = '';
                  }

                  componentOnChange && componentOnChange(parsed);

                  onChange(parsed);
                }}
              />
              {currency && <FormInputSuffix>{currency}</FormInputSuffix>}
            </SuffixWrapper>
          )}
        />
        <input type="hidden" {...formMethods.register(`${name}.currency`)} defaultValue={currency} />
      </FormInputContainer>
      <FormError name={name} />
    </>
  );
};

export default MoneyInput;
