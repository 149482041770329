import React, { FunctionComponent, useState } from 'react';
import * as Styled from './Attachments.styled';
import { AttachmentType } from '../../../@types/Attachment/AttachmentType';
import AttachmentIcon from '../../../Icons/Attachment/Attachment.icon';
import { getResourceType } from '../../../helpers/getResourceType';
import ImageViewer from 'react-simple-image-viewer';
import { color } from '../../../styles/Variables';

type Props = {
  attachment: AttachmentType;
  onDelete: (iri: string) => void;
  className?: string;
  canEdit?: boolean;
};

const AttachmentCard: FunctionComponent<Props> = ({ attachment, className, canEdit, onDelete }) => {
  const [isPreview, setPreview] = useState(false);

  const showPreview = () => {
    setPreview(true);
  };

  const download = () => {
    window.open(attachment.downloadUrl);
  };

  const isPhoto = getResourceType(attachment.name, attachment.previewUrl) === 'image';

  return (
    <Styled.Card className={className} onClick={() => (isPhoto ? showPreview() : download())}>
      <AttachmentIcon name={attachment.name} url={attachment.previewUrl} />
      <Styled.Content>
        <Styled.Name className={'title'}>{attachment.name}</Styled.Name>
        <Styled.Details>
          {isPhoto && (
            <>
              <p onClick={() => showPreview()}>Preview</p>
              <span>|</span>
            </>
          )}
          <a href={attachment.downloadUrl}>Download</a>
          {canEdit && (
            <>
              <span>|</span>
              <p
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(attachment['@id']);
                }}
              >
                Delete
              </p>
            </>
          )}
        </Styled.Details>
      </Styled.Content>
      {attachment.previewUrl && isPreview && (
        <div onClick={(e) => e.stopPropagation()} style={{ zIndex: 1700 }}>
          <ImageViewer
            closeOnClickOutside={true}
            src={[attachment.previewUrl]}
            onClose={() => setPreview(false)}
            backgroundStyle={{ backgroundColor: color.dark }}
          />
        </div>
      )}
    </Styled.Card>
  );
};

export default AttachmentCard;
