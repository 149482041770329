import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import api from '../../../../../services/api';
import { ArrayResponseType } from '../../../../../@types/hydra/hydra';
import { InlineInputDropdownType } from '../InlineInput/InlineInputDropdownType';
import * as Styled from './TaskEmployeeDropdown.styled';
import CloseIcon from '../../../../../Icons/CloseIcon';
import { Icon } from '../../../../atoms/Icon/Icon';

const TaskEmployeeDropdown: InlineInputDropdownType = ({ onChange, name, onClear }) => {
  const { control } = useFormContext();

  const handleChange = useCallback(
    (value: any) => {
      onChange(value);
    },
    [onChange],
  );

  const loadOptions = useCallback((inputValue: string, callback: Function) => {
    api
      .get<ArrayResponseType>('/my-company-team', {
        params: {
          _search: inputValue,
          status: 'active',
        },
      })
      .then((response) => {
        callback(
          response.data['hydra:member'].map((i) => {
            return { value: i['@id'], label: i['name'], meta: i };
          }),
        );
      });
  }, []);

  return (
    <div style={{ width: '20rem' }}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, onBlur } }) => (
          <>
            {onClear && (
              <Styled.SelectWrapper className={'dropdown'}>
                <AsyncSelect
                  autoFocus
                  menuIsOpen={true}
                  menuPlacement={'top'}
                  loadOptions={loadOptions}
                  defaultOptions={true}
                  onChange={(value) => {
                    onChange(value);
                    handleChange(value);
                  }}
                  onBlur={onBlur}
                />
                <Styled.ClearButton className={'close-icon'}>
                  <Icon size={1.2} onClick={onClear}>
                    <CloseIcon />
                  </Icon>
                </Styled.ClearButton>
              </Styled.SelectWrapper>
            )}
            {!onClear && (
              <AsyncSelect
                menuIsOpen={true}
                loadOptions={loadOptions}
                defaultOptions={true}
                onChange={(value) => {
                  onChange(value);
                  handleChange(value);
                }}
              />
            )}
          </>
        )}
      />
    </div>
  );
};

export default TaskEmployeeDropdown;
