import React, { FunctionComponent } from 'react';
import ModulePropsType from '../../../../@types/Module/ModulePropsType';
import ProtocolsScreen from './Screens/ProtocolsScreen/ProtocolsScreen';
import { RouterPageType } from '../../../../@types/RouterPage/RouterPageType';
import TabsNavigatorRouter from '../../../../components/molecules/TabsNavigatorRouter/TabsNavigatorRouter';
import InventoryItemScreen from './Screens/InventoryScreen/InventoryItemScreen';
import { Helmet } from 'react-helmet';

const AdminInventory: FunctionComponent = () => {
  const [pages] = React.useState<RouterPageType[]>([
    { to: '/inventory', name: 'Inventory', screen: <InventoryItemScreen /> },
    { to: '/protocols', name: 'Protocols', screen: <ProtocolsScreen /> },
  ]);
  return (
    <>
      <Helmet>
        <title>Inventory | F.CAPE</title>
      </Helmet>
      <TabsNavigatorRouter pages={pages} />
    </>
  );
};

export default AdminInventory;
