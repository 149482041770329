import { AbsenceType } from '../../../@types/Absences/AbsenceType';
import StringTemplate from '../../molecules/CellTemplates/common/StringTemplate/StringTemplate';
import { TableProperties } from '../../../@types/Table/TableProperty';
import PersonTemplate from '../../molecules/CellTemplates/common/PersonTemplate/PersonTemplate';
import AbsenceIdTemplate from '../../molecules/CellTemplates/Projects/Absences/AbsenceIdTemplate';
import DateTemplate from '../../molecules/CellTemplates/common/DateTemplate/DateTemplate';
import AbsenceStatusTemplate from '../../molecules/CellTemplates/Projects/Absences/AbsenceStatusTemplate';

const properties: TableProperties<AbsenceType> = [
  {
    name: '@id',
    visibleName: 'ID',
    template: AbsenceIdTemplate,
  },
  {
    name: 'person',
    visibleName: 'Person',
    template: PersonTemplate,
    templateOptions: {
      textProperties: {
        size: 'm',
        color: 'darkGrey',
        bold: true,
      },
    },
  },
  {
    name: 'dateFrom',
    visibleName: 'From',
    template: DateTemplate,
    templateOptions: {
      textProperties: {
        size: 'm',
        color: 'grey',
        bold: true,
      },
    },
  },
  {
    name: 'dateTo',
    visibleName: 'To',
    template: DateTemplate,
    templateOptions: {
      textProperties: {
        size: 'm',
        color: 'darkGrey',
        bold: true,
      },
    },
  },
  {
    name: 'days',
    visibleName: 'Days',
    sumBy: 'days',
    template: StringTemplate,
    templateOptions: {
      textProperties: {
        size: 'm',
        color: 'grey',
        bold: true,
      },
    },
  },
  {
    name: 'teamLeader',
    visibleName: 'Team leader',
    template: PersonTemplate,
    templateOptions: {
      textProperties: {
        size: 'm',
        color: 'grey',
        bold: true,
      },
    },
  },
  {
    name: 'personReplacing',
    visibleName: 'Person replacing',
    template: PersonTemplate,
    templateOptions: {
      textProperties: {
        size: 'm',
        color: 'darkGrey',
        bold: true,
      },
    },
  },
  {
    name: 'status',
    visibleName: 'Status',
    template: AbsenceStatusTemplate,
  },
];

export default properties;
