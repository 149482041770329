import React from 'react';

export const ProcessIcon: React.FC = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 14.8633C13.1046 14.8633 14 13.9679 14 12.8633C14 11.7587 13.1046 10.8633 12 10.8633C10.8954 10.8633 10 11.7587 10 12.8633C10 13.9679 10.8954 14.8633 12 14.8633Z"
        stroke="#172EFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 6.86328C5.10457 6.86328 6 5.96785 6 4.86328C6 3.75871 5.10457 2.86328 4 2.86328C2.89543 2.86328 2 3.75871 2 4.86328C2 5.96785 2.89543 6.86328 4 6.86328Z"
        stroke="#172EFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.6665 4.86328H10.6665C11.0201 4.86328 11.3593 5.00376 11.6093 5.25381C11.8594 5.50385 11.9998 5.84299 11.9998 6.19661V10.8633"
        stroke="#172EFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M4 6.86328L4 14.8633" stroke="#172EFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
