import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import api from '../../../../../services/api';
import Button from 'components/atoms/Button/Button';
import PlusIcon from 'Icons/Plus.icon';
import SelectChecklistModal from 'components/molecules/Modal/SelectChecklistModal';
import { ChecklistType } from '../../../../../@types/Checklist/ChecklistType';
import useModal from '../../../../../hooks/useModal';
import SingleChecklist from './SingleChecklist/SingleChecklist';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import { useEntityContext } from '../../../../../Context/EntityContext/EntityContext';
import { ProjectType } from '../../../../../@types/Project/Project';

type Props = {};

const ChecklistView: FunctionComponent<Props> = () => {
  const {
    entity: { id },
  } = useEntityContext<ProjectType>();
  const [checklists, setChecklists] = useState<ChecklistType[]>();
  const { showModal } = useModal();

  const loadProjectChecklists = useCallback(() => {
    api.get(`/wiki/checklists?project.id=${id}`).then((response) => {
      setChecklists(response.data['hydra:member']);
    });
  }, []);

  const handleSelectChecklist = useCallback(() => {
    showModal({
      title: 'Add checklist to project',
      body: <SelectChecklistModal projectId={id} dataLoad={loadProjectChecklists} />,
    });
  }, []);

  useEffect(() => {
    loadProjectChecklists();
  }, []);

  const handleItemComplete = useCallback((checklist: ChecklistType) => {
    api.get(checklist['@id']).then((response) => {
      setChecklists((prev) => {
        if (!prev) {
          return;
        }
        return prev.map((item) => {
          if (item['@id'] !== response.data['@id']) {
            return item;
          } else {
            return response.data;
          }
        });
      });
    });
  }, []);

  return (
    <Grid spacing={4}>
      <GridItem $desktop={12}>
        <Grid $justifyContent={'end'}>
          <GridItem>
            <Button onClick={handleSelectChecklist} $text={'Add new checklist'} $icon={<PlusIcon />} />
          </GridItem>
        </Grid>
      </GridItem>
      <GridItem $desktop={12}>
        <Grid>
          {checklists?.map((checklist: ChecklistType) => (
            <SingleChecklist onComplete={() => handleItemComplete(checklist)} checklist={checklist} key={checklist['@id']} onDelete={loadProjectChecklists} />
          ))}
        </Grid>
      </GridItem>
    </Grid>
  );
};

export default ChecklistView;
