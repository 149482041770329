import React, { FunctionComponent } from 'react';
import ModuleContext from './ModuleContext';
import { ModuleType } from '../../@types/Module/Module';
import moduleReducer from './ModuleReducer';

interface OwnProps {}

type Props = OwnProps & {
  children?: React.ReactNode;
};

const ModuleProvider: FunctionComponent<Props> = (props) => {
  const [state, dispatch] = React.useReducer(moduleReducer, {
    currentModule: undefined,
  });

  const setModule = React.useCallback(
    (module: ModuleType) => {
      if (module.systemName !== 'projects') {
        document.title = module.name;
      }
      dispatch({ type: 'SET_MODULE', module: module });
    },
    [dispatch],
  );
  return <ModuleContext.Provider value={{ setModule, state }}>{props.children}</ModuleContext.Provider>;
};

export default ModuleProvider;
