import React, { FunctionComponent } from 'react';
import * as Styled from '../Comment/Comment.styled';
import AttachmentCard from './AttachmentCard';
import { AttachmentType } from '../../../@types/Attachment/AttachmentType';
import useApi from '../../../hooks/useApi';
import useUser from '../../../hooks/useUser';

type Props = {
  attachments: AttachmentType[];
  onDelete?: (newAttachments: AttachmentType[]) => void;
  disabled?: boolean;
};

const Attachments: FunctionComponent<Props> = ({ attachments, onDelete, disabled }) => {
  const { del } = useApi();
  const user = useUser();

  const handleDelete = (iri: string) => {
    void del(iri).then(() => {
      onDelete && onDelete(attachments.filter((attachment) => attachment['@id'] !== iri));
    });
  };

  return (
    <Styled.Attachments className={'attachments-container'}>
      {attachments.map((attachment) => (
        <AttachmentCard
          key={attachment['@id']}
          attachment={attachment}
          canEdit={attachment.createdBy.id === user.employeeId && !disabled}
          onDelete={handleDelete}
        />
      ))}
    </Styled.Attachments>
  );
};

export default Attachments;
