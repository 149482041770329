import React, { FunctionComponent, useCallback, useMemo } from 'react';
import * as Styled from './TablePagination.styled';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import Arrow from '../../../atoms/Arrow/Arrow';
import CountSelect from './CountSelect';
import Button from '../../../atoms/Button/Button';

type Props = {
  page: number;
  count: number;
  setPage: (page: number) => void;
  setCount: (count: number) => void;
  totalCount?: number;
  exportable?: boolean;
  onExport?: () => void;
};

const TablePagination: FunctionComponent<Props> = ({ page, count, totalCount = 0, setPage, setCount, exportable, onExport }) => {
  const maxPage = useMemo(() => {
    return Math.ceil(totalCount / count);
  }, [totalCount, count]);

  const pickCount = useCallback((newCount: number) => {
    setCount(newCount);
  }, []);

  const handleExport = useCallback(() => {
    if (onExport) {
      onExport();
    }
  }, [onExport]);

  return (
    <Styled.PaginationContainer>
      <Grid spacing={2}>
        <Styled.Pages $desktop={'auto'}>
          <Styled.Page $disabled={page === 1} onClick={() => setPage(Math.max(page - 1, 1))}>
            <Arrow direction={'left'} />
          </Styled.Page>
          {page > 3 && (
            <>
              <Styled.Page onClick={() => setPage(1)}>1</Styled.Page>
              <Styled.Page $disabled>...</Styled.Page>
            </>
          )}
          {[page - 2, page - 1, page, page + 1, page + 2]
            .filter((p) => p >= 1 && p <= maxPage)
            .map((p) => (
              <Styled.Page key={p} $active={page === p} onClick={() => setPage(p)}>
                {p}
              </Styled.Page>
            ))}
          {page < maxPage - 2 && (
            <>
              <Styled.Page $disabled>...</Styled.Page>
              <Styled.Page onClick={() => setPage(maxPage)}>{maxPage}</Styled.Page>
            </>
          )}
          <Styled.Page $disabled={page === maxPage} onClick={() => setPage(Math.min(page + 1, maxPage))}>
            <Arrow direction={'right'} />
          </Styled.Page>
        </Styled.Pages>
        <GridItem $desktop={'max'} />
        {exportable && (
          <GridItem $desktop={'auto'}>
            <Button $text={'Export'} kind={'ghost'} onClick={handleExport} small />
          </GridItem>
        )}
        <GridItem $desktop={'auto'}>
          <Styled.PaginationSelectWrapper>
            Show <CountSelect value={count} onChange={pickCount} />
            items of {totalCount}
          </Styled.PaginationSelectWrapper>
        </GridItem>
      </Grid>
    </Styled.PaginationContainer>
  );
};

export default TablePagination;
