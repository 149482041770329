import { TableProperties } from '../../../../@types/Table/TableProperty';
import { EmployeeType } from '../../../../@types/Employee/EmployeeType';
import IdTemplate from '../../../molecules/CellTemplates/common/IdTemplate/IdTemplate';
import StringTemplate from '../../../molecules/CellTemplates/common/StringTemplate/StringTemplate';
import TagsTemplate from '../../../molecules/CellTemplates/common/TagsTemplate/TagsTemplate';

const properties: TableProperties<EmployeeType> = [
  {
    name: '@id',
    visibleName: 'ID',
    template: IdTemplate,
  },
  {
    name: 'name',
    visibleName: 'Name',
    template: StringTemplate,
    templateOptions: {
      textProperties: {
        size: 'm',
        color: 'darkGrey',
        bold: true,
      },
    },
  },
  {
    name: 'email',
    visibleName: 'E-mail',
    template: StringTemplate,
    templateOptions: {
      textProperties: {
        size: 'm',
        color: 'grey',
        bold: true,
      },
    },
  },
  {
    name: 'phoneNumber',
    visibleName: 'Phone',
    template: StringTemplate,
    templateOptions: {
      textProperties: {
        size: 'm',
        color: 'darkGrey',
        bold: true,
      },
    },
  },
  {
    name: 'department.name',
    visibleName: 'Department',
    template: StringTemplate,
    templateOptions: {
      textProperties: {
        size: 'm',
        color: 'grey',
        bold: true,
      },
    },
  },
  {
    name: 'position.name',
    visibleName: 'Position',
    template: StringTemplate,
    templateOptions: {
      textProperties: {
        size: 'm',
        color: 'grey',
        bold: true,
      },
    },
  },
  {
    name: 'teamLeader.name',
    visibleName: 'Leader',
    template: StringTemplate,
    templateOptions: {
      textProperties: {
        size: 'm',
        bold: true,
      },
    },
  },
  {
    name: 'availableDays',
    visibleName: 'Available days',
    template: StringTemplate,
    templateOptions: {
      textProperties: {
        size: 'm',
        bold: true,
      },
    },
  },
  {
    name: 'active',
    visibleName: 'Active',
    template: TagsTemplate,
    transformValue: (value) => {
      return value ? 'YES' : 'NO';
    },
  },
];
export default properties;
