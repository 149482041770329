import React, { useState } from 'react';
import * as Styled from './Tabs.styled';
import Tab from '../../atoms/Tab/Tab';

export type TabObject = {
  tabName: string;
  tabIdName?: string;
  component: JSX.Element;
  count?: number;
  action?: JSX.Element;
};

type Props = {
  tabs: TabObject[];
};

const Tabs: React.FC<Props> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState<string>(tabs[0].tabName);

  return (
    <Styled.Navigation>
      <Styled.Tabs>
        <Styled.TabWrapper>
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              onClick={() => setActiveTab(tab.tabName)}
              name={tab.tabName}
              isActive={tab.tabName === activeTab}
              count={tab.count}
              action={tab.action}
              idName={tab.tabIdName}
            />
          ))}
        </Styled.TabWrapper>
      </Styled.Tabs>
      <div>
        {tabs.map((tab) => {
          if (tab.tabName === activeTab) {
            return tab.component;
          }
          return null;
        })}
      </div>
    </Styled.Navigation>
  );
};

export default Tabs;
