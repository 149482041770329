import React from 'react';
import { CellTemplate } from '../../../../molecules/CellTemplates/CellTemplate';
import { AddressType } from '../../../../../@types/Finances/Contractor/AddressType';
import Text from '../../../../atoms/Text';
import EmptyCell from '../../../../molecules/CellTemplates/common/EmptyCell';

type Props = {};

const AddressTemplate: CellTemplate<AddressType> = ({ value, options, item }) => {
  return (
    <div style={{ display: 'flex', gap: '0.3rem', flexDirection: 'column' }}>
      {value && (
        <>
          <Text bold>{value?.street}</Text>
          <Text bold>
            {value?.postCode} {value?.city}
          </Text>
        </>
      )}
      {(!value || (!value.street && !value.city)) && <EmptyCell />}
    </div>
  );
};

export default AddressTemplate;
