import React, { FunctionComponent, useCallback } from 'react';
import * as Styled from './CountPerPageSelect.styled';
import Arrow from '../../../atoms/Arrow/Arrow';

type Props = {
  value: number;
  onChange: (count: number) => void;
};

const perPageOptions = [10, 25, 50, 100, 500];

const CountSelect: FunctionComponent<Props> = ({ value, onChange }) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      onChange(parseInt(event.target.value));
    },
    [onChange],
  );
  return (
    <Styled.CountPerPageSelectWrapper>
      <Styled.CountPerPageSelect value={value} onChange={handleChange}>
        {perPageOptions.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Styled.CountPerPageSelect>
      <Arrow small={true} direction={'down'} />
    </Styled.CountPerPageSelectWrapper>
  );
};

export default CountSelect;
