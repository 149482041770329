import React, { FunctionComponent, useCallback } from 'react';
import InventoryItemType from '../../../../@types/Inventory/InventoryItemType';
import { Grid } from '../../../atoms/Grid/Grid';
import { ModalFooter } from '../../../molecules/Modal/Modal';
import Button from '../../../atoms/Button/Button';
import useModal from '../../../../hooks/useModal';
import Checkbox from '../../../molecules/Form/Checkbox/Checkbox';
import Text from '../../../atoms/Text';
import api from '../../../../services/api';
import IriHelper from '../../../../helpers/iri-helper';
import { InventoryAllocationRequestType } from '../../../../@types/Inventory/InventoryAllocationRequestType';
import * as Styled from './AcceptInventoryModal.styled';
import SingleDetail from '../../../molecules/SingleDetail/SingleDetail';
import RichText from '../../../atoms/RichText/RichText';
import Form from '../../../molecules/Form/Form';

type Props = {
  items: InventoryItemType[];
  requests: InventoryAllocationRequestType[];
  onAccept: () => void;
};

const AcceptInventoryModal: FunctionComponent<Props> = ({ items, requests, onAccept }) => {
  const { hideModal } = useModal();
  const [agreed, setAgreed] = React.useState(false);
  const [waiting, setWaiting] = React.useState(false);

  const confirmAcceptance = useCallback(() => {
    setWaiting(true);
    const promises = requests.map((request) => {
      return api.post(`/requests/${IriHelper.iriToId(request['@id'])}/sign`, {});
    });
    Promise.all(promises).then(() => {
      setWaiting(false);
      onAccept();
      hideModal();
    });
  }, [onAccept, requests]);

  return (
    <>
      <Grid>
        {items.map((i, idx) => (
          <Styled.ItemWrapper $desktop={12} key={idx}>
            <Text size={'l'} color={'primary'} bold={true}>
              {i.name}
            </Text>
            <SingleDetail title={'Serial number'} size={'xs'}>
              {i.serialNumber}
            </SingleDetail>
            {i.comment && (
              <SingleDetail title={'Comment'} size={'xs'}>
                <RichText content={i.comment} />
              </SingleDetail>
            )}
          </Styled.ItemWrapper>
        ))}
        <Styled.AgreementWrapper>
          <Form onSubmit={() => {}}>
            <Checkbox
              name={'agreed'}
              label={
                <>
                  I agree to&nbsp;
                  <a target="_blank" href="/CM_Equipment_Terms-of-use.pdf" style={{ textDecoration: 'underline' }}>
                    Terms of Using Cape Morris' equipment.
                  </a>
                </>
              }
              onChange={(v) => {
                setAgreed(v);
              }}
            />
          </Form>
        </Styled.AgreementWrapper>
      </Grid>
      <ModalFooter>
        <Button kind={'ghost'} $text={'Cancel'} onClick={hideModal} />
        <Button $text={'Accept'} onClick={confirmAcceptance} disabled={!agreed} waiting={waiting} />
      </ModalFooter>
    </>
  );
};

export default AcceptInventoryModal;
