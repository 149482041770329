import React, { FunctionComponent, useCallback, useState } from 'react';
import Form from '../../Form/Form';
import RichTextInput from '../../Form/RichTextInput/RichTextInput';
import { TextInput } from '../../Form/TextInput/TextInput';
import useEntity from '../../../../hooks/useEntity';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import Button from '../../../atoms/Button/Button';
import { Loader } from '../../../../legacy/CapeMorris/components';
import { Card } from '../../../atoms/Card/Card';
import Tooltip from '../../Tooltip/Tooltip';
import Text from '../../../atoms/Text';
import { Icon } from '../../../atoms/Icon/Icon';
import InfoIcon from '../../../../Icons/info.icon';
import { FeedEntryType } from '../../../../@types/FeedEntry/FeedEntryType';

type Props = {
  onChange: (entry: FeedEntryType) => void;
  basePath: string;
  onCancel: () => void;
  localStorageKey?: string;
};

const FeedEntryForm: FunctionComponent<Props> = ({ onChange, basePath, onCancel, localStorageKey }) => {
  const { entity, loading, create, save, waiting } = useEntity<FeedEntryType>(basePath);
  const [visible, setVisible] = useState<boolean>(true);

  const handleSubmit = useCallback(
    (data: any) => {
      if (!entity) {
        void create(data).then((entity) => {
          onChange(entity);
          setVisible(false);
        });
      } else {
        void save(data).then((entity) => {
          setVisible(false);
          onChange(entity);
        });
      }
    },
    [entity, create, save],
  );

  return (
    <>
      {visible && (
        <div style={{ position: 'relative' }}>
          {basePath !== '/api/feed_entries' && (waiting || loading) ? (
            <Loader />
          ) : (
            <Card>
              <Form defaultValues={entity} onSubmit={handleSubmit}>
                <Grid spacing={4}>
                  <GridItem $desktop={12}>
                    <TextInput name={'title'} placeholder={'Type story title'} label={'Title'} required />
                  </GridItem>
                  <GridItem $desktop={12}>
                    <RichTextInput name={'content'} label={'Story'} required localStorageKey={localStorageKey} />
                  </GridItem>

                  <GridItem $desktop={12}>
                    <Grid $justifyContent={'end'} spacing={2} $align={'center'}>
                      <GridItem>
                        <Button $text={'Save'} type={'submit'} waiting={loading} />
                      </GridItem>
                      <GridItem>
                        <Button $text={'Cancel'} onClick={() => onCancel()} kind={'link'} />
                      </GridItem>
                      <GridItem>
                        <Tooltip
                          content={
                            <Text color={'lightGrey'} size={'s'} style={{ width: '15rem' }}>
                              By clicking on "Cancel" button you will delete all unsaved changes
                            </Text>
                          }
                          trigger={
                            <Icon>
                              <InfoIcon />
                            </Icon>
                          }
                          arrowPosition={'topCenter'}
                        />
                      </GridItem>
                    </Grid>
                  </GridItem>
                </Grid>
              </Form>
            </Card>
          )}
        </div>
      )}
    </>
  );
};

export default FeedEntryForm;
