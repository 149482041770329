import React, { FunctionComponent, useCallback } from 'react';
import * as Styled from './Items.styled';
import { Icon } from '../../../../atoms/Icon/Icon';
import Text from '../../../../atoms/Text/Text';
import Chip from '../../../../atoms/Chips/Chip';
import useModal from '../../../../../hooks/useModal';
import { MeetingType } from '../../../../../@types/Meeting/MeetingType';
import CalendarIcon from '../../../../../Icons/Calendar.icon';
import { parseDate } from '../../../../../helpers/date';
import { color } from '../../../../../styles/Variables';
import { useNavigate } from 'react-router-dom';
import { useTextShade } from '../../../../../helpers/useTextShade';
import { shorten } from '../../../../../helpers/shorten';
import MeetingsBody, { MeetingsSubtitle } from '../../../../organisms/MeetingDetailsModal/MeetingDetailsModal';

type Props = {
  meeting: MeetingType;
};

const MeetingItem: FunctionComponent<Props> = ({ meeting }) => {
  const { showModal, hideModal } = useModal();
  const navigate = useNavigate();
  const onMeetingSubtitleClick = () => {
    navigate(`projects/${meeting.project.id}`);
    hideModal();
  };
  const getDate = () => {
    if (!meeting.start) {
      return;
    }
    var yesterday = new Date(Date.now() - 86400000);
    var tomorrow = new Date(Date.now() + 86400000);

    if (new Date(meeting.start).toDateString() === yesterday.toDateString()) {
      return (
        <Text size={'s'} color={'error'}>
          Yesterday
        </Text>
      );
    }

    if (new Date(meeting.start).toDateString() === tomorrow.toDateString()) {
      return (
        <Text size={'s'} color={'success'}>
          Tomorrow
        </Text>
      );
    }

    if (new Date(meeting.start).toDateString() === new Date().toDateString()) {
      return (
        <Text size={'s'} color={'success'}>
          Today
        </Text>
      );
    }
    return (
      <Text size={'s'} color={new Date(meeting.start) < new Date() ? 'error' : 'grey'}>
        {new Date(meeting.start).toLocaleDateString('en-us', {
          month: 'long',
          day: 'numeric',
        })}
      </Text>
    );
  };

  const onMeetingClick = useCallback(() => {
    showModal({
      title: meeting.subject,
      subtitle: meeting.project && MeetingsSubtitle(meeting, () => onMeetingSubtitleClick()),
      body: React.createElement(MeetingsBody, { meeting }),
      width: 64,
    });
  }, [meeting]);

  const textShade = useTextShade(meeting?.project?.client.color);

  return (
    <Styled.CardItemWrapper
      onClick={(e) => {
        onMeetingClick();
      }}
      $hover
    >
      <Styled.ItemWrapper>
        <Styled.ItemLeftWrapper>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Icon
              onClick={() => {
                onMeetingClick();
              }}
              size={1.5}
              color={color.neutral[80]}
            >
              <CalendarIcon />
            </Icon>
          </div>

          <Text color={'darkGrey'}>{shorten(meeting.subject, 40, 35)}</Text>
          {meeting.start && (
            <Text color={'darkGrey'} bold>
              {parseDate(new Date(meeting.start), false, false, false, true)}
            </Text>
          )}
        </Styled.ItemLeftWrapper>
        <Styled.ItemRightWrapper>
          {meeting.project && (
            <Chip $bgColor={meeting.project.client.color} $rounded={true}>
              <Text color={textShade === 'light' ? 'light' : 'dark'} size={'xs'}>
                <strong>#{meeting.project.id}</strong> {shorten(meeting.project?.name, 30, 25)}
              </Text>
            </Chip>
          )}
          {meeting.start && (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {getDate()}
            </div>
          )}
          <div style={{ paddingRight: '0.8rem' }} />
        </Styled.ItemRightWrapper>
      </Styled.ItemWrapper>
    </Styled.CardItemWrapper>
  );
};

export default MeetingItem;
