import React, { FunctionComponent, ReactNode } from 'react';
import * as Styled from './Badge.styled';

type Props = {
  children: ReactNode;
};
const Badge: FunctionComponent<Props> = (props) => {
  return <Styled.Container>{props.children}</Styled.Container>;
};
export default Badge;
