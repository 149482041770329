import React from 'react';
import { Burger, Container, ModuleName, UserContainer } from './header.styled';
import { UserIndicator } from '../../user-indicator/user-indicator';
import BurgerIcon from '../../../../../Icons/BurgerIcon';
import ModuleContext from '../../../../../Context/ModuleContext/ModuleContext';
import HeaderSubmenu from '../HeaderSubmenu';
import Search from '../../Search';
import { AuthContext } from '../../../../../Context/auth-context/auth-context';
import FinancialCompanySelector from '../../FinancialCompanySelector';
import Notifications from '../../../../../components/organisms/Notifications/Notifications';
import { Icon } from '../../../../../components/atoms/Icon/Icon';
import { color } from '../../../../../styles/Variables';

type HeaderProps = {
  onToggleMenu: () => void;
  closed: boolean;
};

export const Header: React.FC<HeaderProps> = ({ onToggleMenu, closed }) => {
  const { state: moduleState } = React.useContext(ModuleContext);
  const { state: user } = React.useContext(AuthContext);
  return (
    <Container>
      <Burger onClick={onToggleMenu}>
        <Icon size={1.7} color={color.neutral[70]}>
          <BurgerIcon open={!closed} />
        </Icon>
      </Burger>
      <ModuleName>{moduleState.currentModule?.name}</ModuleName>
      {moduleState.currentModule?.submenu && <HeaderSubmenu module={moduleState.currentModule} />}
      <div style={{ marginLeft: 'auto' }} />
      {user.context === 'employee' && <Search style={{ marginRight: '2.2rem' }} />}
      {user.context === 'accounting' && <FinancialCompanySelector />}
      <UserContainer>
        <UserIndicator />
      </UserContainer>
      {user.context === 'employee' && <Notifications />}
    </Container>
  );
};
