import { CellTemplate } from '../../CellTemplate';
import React from 'react';
import IdText from '../../../IdText/IdText';

import { GridItem, Grid } from '../../../../atoms/Grid/Grid';

const IdTemplate: CellTemplate<number | string, { withHash?: boolean }> = ({ value, options = {} }) => {
  return (
    <Grid>
      <GridItem $desktop={'max'}>
        <IdText withHash={options.withHash ?? true} value={value} />
      </GridItem>
    </Grid>
  );
};
export default IdTemplate;
