import styled, { css } from 'styled-components';
import { color, fontSize } from '../../../styles/Variables';

export const Underline = styled.div<{ $active: boolean }>`
  width: ${({ $active }) => ($active ? '100%' : '0')};
  height: 2px;
  background-color: ${color.primary[60]};
  transition: width 150ms cubic-bezier(0.67, 0.2, 1, 0.72);
`;

const CommonStyles = css`
  font-size: ${fontSize.body.m};
  color: ${color.neutral[90]};
  padding-top: 1.2rem;
  flex-direction: column;
  display: flex;
`;

export const Wrapper = styled.span`
  ${CommonStyles};
  padding-top: 0;
`;

export const Link = styled.a`
  ${CommonStyles};
  padding-top: 0;
`;

// If you know how to fix this typing instead of any, please send a PR
export const TabContainer = styled.div<{ children?: any }>`
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
`;
