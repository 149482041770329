import styled from 'styled-components';
import { color } from '../../../styles/Variables';

export const Container = styled.div`
  display: flex;
  background-color: ${color.semantic.error[100]};
  color: ${color.light};
  border-radius: 7px;
  padding: 0.2rem;
  width: 17px;
  height: 17px;
  font-size: 10px;
  text-align: center;
  margin-left: 5px;
  justify-content: center;
  align-items: center;
`;
