import styled from 'styled-components';
import { color } from 'styles/Variables';

export const Column = styled.div`
  position: relative;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ColumnBody = styled.div`
  position: relative;
  overflow-y: auto;
  border: 1px solid ${color.neutral['30']};
  border-radius: 8px;
  background-color: ${color.light};
  margin-top: 2rem;
  height: 60vh;
`;
