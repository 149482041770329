import React, { FunctionComponent, ReactElement, useCallback, useState } from 'react';
import InnerDropdownMenu from './InnerDropdownMenu';
import { DropdownMenuItemType } from './DropdownMenuItem/DropdownMenuItem';
import useOutsideClick from '../../../hooks/useOutsideClick';

type Props = {
  children: ReactElement;
  triggerType?: 'hover' | 'click';
  menuItems: DropdownMenuItemType[];
  childrenLeft?: boolean;
  onOutsideClick?: () => void;
  disabled?: boolean;
};

const DropdownMenu: FunctionComponent<Props> = ({ children, triggerType = 'hover', onOutsideClick, disabled, ...props }) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const innerDropdownMenuRef = React.useRef<HTMLDivElement>(null);
  const handleMouseEnter = useCallback(() => {
    if (triggerType !== 'hover') {
      return;
    }
    if (disabled) {
      return;
    }
    setOpen(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    if (triggerType !== 'hover') {
      return;
    }
    if (disabled) {
      return;
    }
    setOpen(false);
  }, []);

  const handleClick = useCallback(() => {
    if (triggerType !== 'click') {
      return;
    }
    if (disabled) {
      return;
    }
    setOpen((open) => !open);
  }, [triggerType]);

  useOutsideClick(innerDropdownMenuRef, () => {
    setOpen(false);
    if (onOutsideClick) {
      onOutsideClick();
    }
  });

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ position: 'relative' }} ref={innerDropdownMenuRef}>
      <span onClick={handleClick} style={{ alignItems: 'center', display: 'flex', height: '100%', cursor: disabled ? 'default' : 'pointer' }}>
        {children}
      </span>
      <InnerDropdownMenu {...props} isVisible={isOpen} />
    </div>
  );
};

export default DropdownMenu;
