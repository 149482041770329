import React, { FunctionComponent } from 'react';
import Button from '../../../../../../components/atoms/Button/Button';
import { Icon } from '../../../../../../components/atoms/Icon/Icon';
import { SlidersIcon } from '../../../../../../Icons/Sliders.icon';
import ModalForm from '../../../../../../components/organisms/Form/ModalForm/ModalForm';
import SelectInput from '../../../../../../components/molecules/Form/SelectInput/SelectInput';
import HiddenInput from '../../../../../../components/atoms/Form/HiddenInput/HiddenInput';
import InventoryItemModal from './InventoryItemModal/InventoryItemModal';
import useModal from '../../../../../../hooks/useModal';
import InventoryItemType from '../../../../../../@types/Inventory/InventoryItemType';

type Props = {
  selectedItems: InventoryItemType[];
};

const InventoryItemScreenHeadingRight: FunctionComponent<Props> = ({ selectedItems }) => {
  const { showModal } = useModal();
  return (
    <div style={{ display: 'flex', gap: '1rem' }}>
      <Button
        $text={'Assign inventory'}
        disabled={selectedItems.length <= 0}
        $icon={
          <Icon>
            <SlidersIcon />
          </Icon>
        }
        kind={'secondary'}
        onClick={() => {
          showModal({
            title: 'Assign item to employee',
            subtitle: `You chose ${selectedItems.length} items to assign. Select person to assign items.`,
            body: (
              <ModalForm basePath={'/api/inventory_allocations'} buttonText={'Assign'}>
                <SelectInput name={'person'} baseUrl={'/api/employees/'} baseUrlParams={{ status: ['active'] }} />
                <HiddenInput name={'type'} value={'release'} />
                {selectedItems.map((item, idx) => (
                  <HiddenInput name={`items.${idx}`} value={item['@id']} />
                ))}
              </ModalForm>
            ),
          });
        }}
      />
      <Button
        $text={'Add inventory'}
        $icon={
          <Icon>
            <SlidersIcon size={2} />
          </Icon>
        }
        onClick={() => {
          showModal({
            title: 'Create new inventory item',
            subtitle: 'Fill data about new inventory item',
            body: <InventoryItemModal basePath={'/api/inventory_items'} />,
          });
        }}
      />
    </div>
  );
};

export default InventoryItemScreenHeadingRight;
