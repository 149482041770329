import styled from 'styled-components';
import { color, fontSize, fontWeight } from 'styles/Variables';
import { UiText } from '../../../../styles/Typography';

export const FormErrorContainer = styled.div<{ $visible: boolean }>`
  ${UiText};
  font-size: ${fontSize.body.xs};
  color: ${color.semantic.error[100]};
  height: 0;
  transition: height 0.2s ease;
  position: absolute;
  top: 3px;
  font-weight: ${fontWeight.bold};
`;
