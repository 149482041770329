import React, { useContext } from 'react';
import { AuthContext } from '../Context/auth-context/auth-context';
import { MenuItem } from '../legacy/CapeMorris/components/layout/menu/MenuItem';
import ModuleProvider from '../Context/ModuleContext/ModuleProvider';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { Layout } from '../legacy/CapeMorris/components';
import ProjectsModule from '../modules/projects';
import ExchangeModule from '../modules/exchange';
import AdminModule from '../modules/admin/AdminModule';
import FinancesModule from '../modules/Finance';
import ProfileModule from '../modules/profile/ProfileModule';
import useAbsenceRequestCount from '../hooks/AbsenceRequest/useAbsenceRequestCount';
import TaskSidebarQueryHandler from '../components/organisms/TaskSidebar/TaskSidebarQueryHandler';
import SidebarContainer from '../components/organisms/Sidebar/SidebarContainer';
import ContactsModule from '../modules/contacts/ContactsModule';
import ModuleContainer from '../modules/ModuleContainer';
import AddButton from '../components/molecules/FloatingButtons/AddButton/AddButton';
import HelpButton from '../components/molecules/FloatingButtons/HelpButton/HelpButton';
import ScrollToTopButton from '../components/molecules/FloatingButtons/ScrollToTopButton/ScrollToTopButton';
import useProjectForm from '../hooks/useProjectForm';
import TaskFormContext from '../Context/TaskFormContext/TaskFormContext';
import Commander from '../components/organisms/Commander/Commander';

const AuthenticatedRouter: React.FC = () => {
  const { show } = useProjectForm();
  const { openShortForm } = useContext(TaskFormContext);
  const absenceRequestCount = useAbsenceRequestCount();
  const {
    can,
    state: { user },
  } = React.useContext(AuthContext);

  const exchangeModule = {
    to: '/exchange',
    name: 'F.CAPE EXCHANGE',
    systemName: 'excahnge',
    submenu: [
      { name: 'Incomes', url: '/invoices' },
      { name: 'Costs', url: '/costs' },
    ],
  };
  const projectsModule = {
    to: '/projects',
    name: 'F.CAPE PROJECTS',
    systemName: 'projects',
    submenu: [
      { name: 'Home', url: '' },
      { name: 'My Work', url: '/my-work' },
      { name: 'Timesheet', url: '/timesheets' },
      { name: 'Projects', url: '/list' },
      { name: 'Tasks', url: '/tasks' },
      { name: 'Workload', url: '/workload' },
      { name: 'Absences', url: '/absences', badge: absenceRequestCount },
      { name: 'Wiki', url: '/wiki' },
    ],
  };
  const contactsModule = {
    to: '/contacts',
    name: 'CONTACTS',
    systemName: 'contacts',
    submenu: [{ name: 'Contacts', url: '' }],
  };
  const adminSubmenu = [];
  if (can('EMPLOYEE')) {
    adminSubmenu.push({ name: 'Employees', url: '/employees' });
  }
  if (can('INVENTORY.CAN_MANAGE')) {
    adminSubmenu.push({ name: 'Inventory', url: '/inventory' });
  }
  const adminModule = {
    to: '/admin',
    name: 'F.CAPE ADMIN',
    systemName: 'admin',
    submenu: adminSubmenu,
  };

  const profileModule = {
    to: '/profile',
    name: 'F.CAPE PROFILE',
    systemName: 'profile',
    submenu: [
      { name: 'Inventory', url: '/inventory' },
      { name: 'Profile', url: '' },
    ],
  };

  const financesModule = {
    to: '/finances',
    name: 'F.CAPE FINANCES',
    systemName: 'finances',
    submenu: [
      { name: 'Inbox', url: '/costs-inbox' },
      { name: 'Vendors', url: '/vendors' },
      { name: 'Contracts', url: '/contracts', visible: can('FINANCES.CONTRACTS.CAN_SEE') },
      { name: 'Incomes', url: '/incomes', visible: can('FINANCES.INCOMES.CAN_SEE') },
      { name: 'Costs', url: '/costs' },
      { name: 'Purchase orders', url: '/purchase-orders', visible: true },
    ],
  };

  const menuItems = [];
  let hasAdminAccess = false;
  let hasFinanceAccess = false;

  if (user?.context === 'accounting') {
    menuItems.push(<MenuItem key={'exchange'} module={exchangeModule} />);
  } else {
    menuItems.push(<MenuItem key={'projects'} module={projectsModule} />);
    hasAdminAccess = can('INVENTORY.CAN_MANAGE') || can('EMPLOYEE.CAN_SEE_ALL');
    hasFinanceAccess = true;

    if (can('EXCHANGE.HAS_ACCESS')) {
      menuItems.push(<MenuItem key={'exchange'} module={exchangeModule} />);
    }
    if (hasFinanceAccess) {
      menuItems.push(<MenuItem key={'finances'} module={financesModule} />);
    }
    if (hasAdminAccess) {
      menuItems.push(<MenuItem key={'admin'} module={adminModule} />);
    }
    menuItems.push(<MenuItem key={'contacts'} module={contactsModule} />);
  }

  return (
    <ModuleProvider>
      <Router
        future={{
          v7_relativeSplatPath: true,
        }}
      >
        <Layout menuItems={menuItems}>
          {user?.context === 'employee' ? (
            <Routes>
              <Route
                path="projects"
                element={
                  <ModuleContainer
                    module={projectsModule}
                    buttons={
                      <>
                        <AddButton add={{ project: show, task: openShortForm }} />
                        <HelpButton />
                        {window.location.href.includes('wiki') && <ScrollToTopButton elementId={'#wiki'} />}
                      </>
                    }
                  />
                }
              >
                <Route path="" element={<Navigate to="dashboard" replace />} />
                <Route path="*" element={<ProjectsModule />} />
              </Route>
              {can('EXCHANGE.HAS_ACCESS') && (
                <Route path="/exchange" element={<ModuleContainer module={exchangeModule} />}>
                  <Route path="" element={<Navigate to="invoices" />} />
                  <Route path="*" element={<ExchangeModule />} />
                </Route>
              )}
              {hasAdminAccess && (
                <Route path="admin" element={<ModuleContainer module={adminModule} />}>
                  <Route path="*" element={<AdminModule />} />
                  <Route path="" element={<Navigate to="employees" />} />
                </Route>
              )}
              {hasFinanceAccess && (
                <Route path="finances" element={<ModuleContainer module={financesModule} />}>
                  <Route path="" element={<Navigate to="costs-inbox" />} />
                  <Route path="*" element={<FinancesModule />} />
                </Route>
              )}
              <Route path="profile" element={<ModuleContainer module={profileModule} />}>
                <Route path="" element={<ProfileModule />} />
                <Route path="*" element={<ProfileModule />} />
              </Route>
              <Route path="contacts" element={<ModuleContainer module={contactsModule} />}>
                <Route path="" element={<ContactsModule />} />
              </Route>
              <Route path="" element={<Navigate to="projects" replace />} />
            </Routes>
          ) : (
            <Routes>
              <Route path="exchange" element={<ModuleContainer module={exchangeModule} />}>
                <Route path="" element={<Navigate to="invoices" />} />
                <Route path="*" element={<ExchangeModule />} />
              </Route>
              <Route path="" element={<Navigate to="exchange/invoices" replace />} />
            </Routes>
          )}
          <TaskSidebarQueryHandler />
          <SidebarContainer />
          <Commander />
        </Layout>
      </Router>
    </ModuleProvider>
  );
};

export default AuthenticatedRouter;
