import React from 'react';

type PropType = {
  size?: number;
  direction?: 'up' | 'down' | 'right' | 'left';
};

export const ArrowIcon: React.FC<PropType> = ({ size = 1, direction }) => {
  return (
    <svg
      className="arrow-icon"
      style={{
        rotate: direction === 'right' ? '-90deg' : direction === 'left' ? '90deg' : direction === 'up' ? '-180deg' : '0deg',
        transition: 'rotate 0.1s ease-in-out',
      }}
      width={`${size}em`}
      height={`${size}em`}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 8.93332L2 4.93332L2.93333 3.99998L6 7.06665L9.06667 3.99998L10 4.93332L6 8.93332Z" fill="currentColor" />
    </svg>
  );
};
