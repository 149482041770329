import React, { FunctionComponent, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../../../../Context/auth-context/auth-context';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import Button from '../../../atoms/Button/Button';
import PlusIcon from '../../../../Icons/Plus.icon';
import TablePage from '../../../organisms/TablePage/TablePage';
import { incomeTableProperties } from './IncomeList.properties';
import incomeTableFilters from './IncomeList.filters';
import { IncomeType } from '../../../../@types/Finances/Income/IncomeType';

const IncomeList: FunctionComponent = () => {
  let { pathname } = useLocation();
  let navigate = useNavigate();
  const { can } = React.useContext(AuthContext);

  const handleCreate = useCallback(() => {
    navigate(`${pathname}/create`);
  }, [pathname, navigate]);

  const CreateAction = () => {
    return (
      <Grid spacing={2} $align={'center'}>
        <GridItem $desktop={'auto'}>
          <Button $text={'New income'} kind={'primary'} onClick={() => handleCreate()} $icon={<PlusIcon />} />
        </GridItem>
      </Grid>
    );
  };
  return (
    <TablePage<IncomeType>
      context={'/api/incomes'}
      heading={'Incomes'}
      headingRight={can('FINANCES.INCOMES.CAN_MANAGE') ? <CreateAction /> : undefined}
      itemLink={(item) => `${pathname}/${item.id}/show`}
      url={'/incomes'}
      defaultFilters={{
        postedAt: new Date().getFullYear(),
      }}
      properties={incomeTableProperties}
      filters={incomeTableFilters}
      exportUrl={'/api/incomes/export'}
      globalFilters={{
        _archived: 0,
      }}
    />
  );
};
export default IncomeList;
