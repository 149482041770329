import { TableProperties } from '../../../../@types/Table/TableProperty';
import { PurchaseOrderType } from '../../../../@types/Finances/PurchaseOrder/PurchaseOrderType';
import MoneyTemplate from '../../../molecules/CellTemplates/common/MoneyTemplate/MoneyTemplate';
import DateTemplate from '../../../molecules/CellTemplates/common/DateTemplate/DateTemplate';
import PersonTemplate from '../../../molecules/CellTemplates/common/PersonTemplate/PersonTemplate';
import FinanceItemInfoTemplate from '../../../molecules/CellTemplates/Finances/FinanceItemInfoTemplate/FinanceItemInfoTemplate';
import FinanceIdTemplate from '../../../molecules/CellTemplates/Finances/FinanceIdTemplate/FinanceIdTemplate';
import ContractorValueTaxTemplate from '../../../molecules/CellTemplates/Finances/ContractorValueTaxTemplate/ContractorValueTaxTemplate';
import { shorten } from '../../../../helpers/shorten';
import ProjectTemplate from '../../../molecules/CellTemplates/common/ProjectTemplate/ProjectTemplate';

export const properties: TableProperties<PurchaseOrderType> = [
  {
    name: 'number',
    visibleName: 'Number',
    template: FinanceIdTemplate,
    sortable: true,
  },
  {
    name: 'title',
    visibleName: 'Title',
    template: FinanceItemInfoTemplate,
    sortable: true,
    templateOptions: {
      chips: [
        {
          display: (item: PurchaseOrderType) => item.billingType,
          condition: () => true,
        },
        {
          display: () => 'Closed',
          condition: (item: PurchaseOrderType) => item.closed,
        },
        {
          display: (item: PurchaseOrderType) => shorten(item.costType?.label, 20, 20) ?? 'N/A',
          condition: () => true,
          color: (item: PurchaseOrderType) => (item.kind === 'management' ? 'warning' : 'secondary'),
        },
      ],
      textProperties: {
        size: 'l',
        bold: true,
      },
    },
  },
  {
    name: 'contractor',
    visibleName: 'Vendor',
    template: ContractorValueTaxTemplate,
    sortable: true,
    templateOptions: {
      textProperties: {
        maxWidth: '15rem',
      },
    },
  },
  {
    name: 'createdBy',
    visibleName: 'Requester',
    template: PersonTemplate,
    sortable: true,
    templateOptions: {
      areInitials: true,
    },
  },
  {
    name: 'project',
    visibleName: 'Project',
    template: ProjectTemplate,
    sortable: 'project.id',
  },
  {
    name: 'createdAt',
    visibleName: 'Creation date',
    template: DateTemplate,
    sortable: true,
  },
  {
    name: 'amountNet',
    visibleName: 'Amount',
    template: MoneyTemplate,
    sumBy: 'amountNet',
    sumAsMoney: true,
    sortable: 'amountNet.value',
    justifyContent: 'flex-end',
  },
  {
    name: 'remainNet',
    visibleName: 'Remain value',
    template: MoneyTemplate,
    justifyContent: 'flex-end',
    sumBy: 'remainNet',
    sortable: 'remainNet.value',
  },
  {
    name: 'invoicedNet',
    visibleName: 'Invoiced value',
    template: MoneyTemplate,
    sumBy: 'invoicedNet',
    sortable: 'invoicedNet.value',
    justifyContent: 'flex-end',
    templateOptions: {
      textProperties: {
        bold: true,
        color: 'primary',
      },
    },
  },
];
