import { useCallback, useState } from 'react';

type UseTableReloadResponse = {
  key: number | undefined;
  reload: () => void;
};

function useTableReload(): UseTableReloadResponse {
  const [key, setKey] = useState<number | undefined>(undefined);
  const reload = useCallback(() => {
    setKey(Math.random());
  }, []);

  return { key, reload };
}

export default useTableReload;
