import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import useEntity from '../../../../hooks/useEntity';
import { Loader } from '../../../../legacy/CapeMorris/components';
import CostForm from './CostForm';
import { CostType } from '../../../../@types/Finances/Cost/CostType';
import Container from '../../../atoms/Container/Container';

type Props = {};

const CostEdit: FunctionComponent<Props> = (props) => {
  let { id } = useParams<{ id: string }>();
  const { entity, loading } = useEntity<CostType>(`/api/costs/${id}`);

  return (
    <Container>
      {loading && <Loader />}
      {entity && <CostForm cost={entity} isEdit={true} />}
    </Container>
  );
};

export default CostEdit;
