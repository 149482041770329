import React from 'react';
import { AuthContext } from '../../../../Context/auth-context/auth-context';
import { Avatar, Container, Welcome } from './user-indicator.styled';
import { createFullApiUrl } from '../../../../services/api';
import AvatarImage from '../../../../assets/images/sample-avatar.png';
import Chip from '../../../../components/atoms/Chips/Chip';

export const UserIndicator: React.FC = () => {
  const { state, isImpersonating } = React.useContext(AuthContext);
  const { user, context } = state;
  return (
    <Container>
      {context !== 'employee' && <Chip>{context}</Chip>}
      {state.user && (
        <Welcome $isImpersonating={isImpersonating}>
          Hi, <span>{state.user.firstName}!</span>
        </Welcome>
      )}
      <Avatar title={`${user?.firstName} ${user?.lastName}`}>
        {user?.profilePhotoUrl && <img src={createFullApiUrl(user.profilePhotoUrl)} alt={`${user?.firstName} ${user?.lastName}`} />}
        {!user?.profilePhotoUrl && <img src={AvatarImage} alt={`${user?.firstName} ${user?.lastName}`} />}
      </Avatar>
    </Container>
  );
};
