import styled, { css, keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { color, fontSize, fontWeight } from 'styles/Variables';
import { BodyText } from '../../../styles/Typography';

type TreeLinkProps = {
  $haschildren: string;
  $ismaincategory?: string;
  $isclicked: string;
};

type NavigationNodeWrapperProps = {
  $isExpanded: boolean;
};

export const Wrapper = styled.div``;

const textColorKeyframes = keyframes`
  0% {
    color: ${color.neutral[80]};
  }

  100% {
    color: ${color.primary[80]}
  }
`;

export const TreeHeading = styled.p`
  ${BodyText};
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.body.l};
  margin-bottom: 0.8rem;
`;

export const TreeLink = styled(Link)<TreeLinkProps>`
  ${BodyText};
  font-weight: ${(props: TreeLinkProps) => (props.$haschildren ? fontWeight.bold : fontWeight.regular)};
  font-size: ${(props: TreeLinkProps) => (props.$ismaincategory ? 1.3 : 1.2)}rem;
  display: flex;
  align-items: center;

  ${(props: TreeLinkProps) =>
    props.$isclicked &&
    css`
      color: ${color.primary[60]};
      animation: ${textColorKeyframes} 0.3s ease-in;
    `}
`;

export const ArrowReplacement = styled.div`
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem;
`;

const slideDown = keyframes`
  0% {
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
  30% {
    height: auto;
    visibility: visible;
    opacity: 0;
  }
  50% {
    height: auto;
    opacity: 0.5;
  }

  100% {
    height: auto;
    opacity: 1;
    visibility: visible;
  }
`;

const slideUp = keyframes`
  0% {
    opacity: 1;
    height: auto;
    visibility: visible;
  }
  30% {
    opacity: 0.7;
    height: auto;
  }
  80% {
    opacity: 0;
    height: auto;
  }
  100% {
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
`;

export const NavigationNodeWrapper = styled.div<NavigationNodeWrapperProps>`
  padding-left: 1.5rem;
  transition: 0.3s ease-in-out;
  transition-property: opacity, visibility, max-height;

  ${(props: NavigationNodeWrapperProps) =>
    props.$isExpanded
      ? css`
          animation: ${slideDown} 0.15s forwards;
        `
      : css`
          animation: ${slideUp} 0.15s forwards;
        `}
`;

export const TreeLinkWrapper = styled.div`
  margin-bottom: 1.6rem;
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 0;
`;

export const ArrowWrapper = styled.div`
  display: flex;
  margin-top: -3px;
  align-items: center;
`;
