import { CellTemplate } from '../../CellTemplate';
import React, { useMemo } from 'react';
import Text from '../../../../atoms/Text/Text';
import { DictValue } from '../../../../../@types/Dictionary/DictValue';
import { Icon } from '../../../../atoms/Icon/Icon';
import AttachmentIcon from '../../../../../Icons/Attachment.icon';
import { shorten } from '../../../../../helpers/shorten';
import { TextSizeType } from '../../../../../@types/Text/TextSizeType';
import { ColorType } from '../../../../../@types/Color/ColorType';
import * as Styled from './StringTemplate.styled';

const StringTemplate: CellTemplate<
  string,
  {
    dictionary?: DictValue[];
    wrap?: boolean;
    textProperties?: {
      shorten?: number;
      bold?: boolean;
      size?: TextSizeType;
      color?: ColorType;
    };
  },
  any
> = ({ value, options, item }) => {
  const translated = useMemo(() => {
    if (!value) {
      return 'N/A';
    }
    if (!options?.dictionary) {
      return value;
    }
    return options.dictionary.find((item) => item.value === value)?.label || value;
  }, [options, value]);

  return (
    <Styled.Container $wrap={!!options?.wrap}>
      <Text
        style={
          options?.wrap
            ? { whiteSpace: 'normal' }
            : {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }
        }
        color={translated === 'N/A' ? 'lightGrey' : (options?.textProperties?.color ?? 'darkGrey')}
        size={options?.textProperties?.size ?? 'l'}
        bold
      >
        {options?.textProperties?.shorten ? shorten(translated, options.textProperties?.shorten, options.textProperties?.shorten) : translated}
      </Text>
      <div>
        {item.hasResources && (
          <Icon size={1}>
            <AttachmentIcon />
          </Icon>
        )}
      </div>
    </Styled.Container>
  );
};
export default StringTemplate;
