import React, { FunctionComponent } from 'react';
import TablePage from '../../../components/organisms/TablePage/TablePage';
import Container from '../../../components/atoms/Container/Container';
import properties from './ContactsList.properties';
import filters from './ContactsList.filters';
import { Helmet } from 'react-helmet';

type Props = {};

const ContactsScreen: FunctionComponent<Props> = (props) => {
  return (
    <Container>
      <Helmet>
        <title>Contacts | F.CAPE</title>
      </Helmet>
      <TablePage
        url={'/employees'}
        context={'/api/employees'}
        properties={properties}
        globalFilters={{
          status: 'active',
        }}
        heading={'Contacts'}
        filters={filters}
      />
    </Container>
  );
};

export default ContactsScreen;
