import React, { FunctionComponent, useCallback } from 'react';
import Form from '../../molecules/Form/Form';
import useModal from '../../../hooks/useModal';
import SnackContainer from '../../../legacy/CapeMorris/components/snack-bar/use-snack-container';
import AddWorkIcon from '../../../Icons/AddWork.icon';
import { Grid, GridItem } from '../../atoms/Grid/Grid';
import TimesheetDayOption from '../TaskSidebar/TaskSidebarTopBar/elements/TaskTopBarAddToTimesheetButton/TimesheetDayOption';
import TimeInput from '../../molecules/Form/TimeInput/TimeInput';
import { TaskType } from '../../../@types/Task/TaskType';
import { MeetingType } from '../../../@types/Meeting/MeetingType';
import timesheetDaySingleValue from '../TaskSidebar/TaskSidebarTopBar/elements/TaskTopBarAddToTimesheetButton/TimesheetDaySingleValue';
import useApi from '../../../hooks/useApi';
import { TextAreaInput } from '../../molecules/Form/TextAreaInput/TextAreaInput';
import { ModalFooter } from '../../molecules/Modal/Modal';
import Select from '../../molecules/Form/SelectInput/SelectInput';
import Button from '../../atoms/Button/Button';
import DynamicSelectInput from '../../molecules/Form/SelectInput/DynamicSelectInput';

type Props = {
  task?: TaskType;
  meeting?: MeetingType;
  onSave?: () => void;
};

const AddToTimesheetModal: FunctionComponent<Props> = ({ task, meeting, onSave }) => {
  const { post } = useApi();
  const { hideModal } = useModal();
  const { showSnack } = SnackContainer.useContainer();

  const handleAddToTimesheet = useCallback(
    async (data: { description: string; duration: number; type?: string }) => {
      post('/api/timesheet_entries', {
        ...data,
        project: task && task.project ? task.project['@id'] : meeting && meeting.project ? meeting.project['@id'] : null,
        task: task ? task['@id'] : null,
        type: data.type ?? 'project',
      })
        .then(() => {
          onSave && onSave();
          hideModal();
          showSnack({
            type: 'success',
            title: 'Success',
            message: 'Element was added to the timesheet.',
          });
        })
        .catch(() => {
          hideModal();
          showSnack({
            type: 'error',
            title: 'Error',
            message: 'Element was not added to the timesheet.',
          });
        });
    },
    [task, meeting],
  );

  const currentDate = new Date();
  const twoWeeksAgo = new Date(currentDate.getTime() - 14 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10);

  return (
    <Form onSubmit={handleAddToTimesheet}>
      <Grid spacing={2}>
        <GridItem $desktop={12}>
          <TextAreaInput
            name={'description'}
            label={'Description'}
            defaultValue={meeting ? meeting.subject : ''}
            placeholder={'e.g. Project meeting'}
            required
          />
        </GridItem>
        <GridItem $desktop={12}>
          <TimeInput name={'duration'} required={true} />
        </GridItem>
        <GridItem $desktop={12}>
          <DynamicSelectInput
            name={'timesheetDay'}
            baseUrl={'/timesheet_days'}
            label={'Day'}
            isOptionDisabled={(option) => option.meta.closed}
            optionComponent={TimesheetDayOption}
            singleValueComponent={timesheetDaySingleValue}
            optionLabel={'day'}
            baseUrlParams={{ 'day[strictly_after]': twoWeeksAgo }}
          />
        </GridItem>
        {((task && !task.project) || (meeting && !meeting.project)) && (
          <GridItem $desktop={12}>
            <Select
              name={'type'}
              options={[
                { value: 'admin', label: 'Admin' },
                { value: 'learn', label: 'Learn' },
                { value: 'notask', label: 'No task' },
              ]}
              label={'Type'}
              placeholder={'Select task type'}
            />
          </GridItem>
        )}
      </Grid>
      <ModalFooter>
        <Button $text={'Add to timesheet'} type="submit" $icon={<AddWorkIcon />} />
      </ModalFooter>
    </Form>
  );
};

export default AddToTimesheetModal;
