import React, { FunctionComponent, useCallback, useContext } from 'react';
import { TaskType } from '../../../../../@types/Task/TaskType';
import * as Styled from './Styles.styled';
import Text from '../../../../atoms/Text/Text';
import { Link } from 'react-router-dom';
import { SidebarContext } from '../../../../../Context/Sidebar/SidebarContext';
import AttachmentIcon from '../../../../../Icons/Attachment.icon';
import { Icon } from '../../../../atoms/Icon/Icon';
import useUser from '../../../../../hooks/useUser';

type Props = {
  task: TaskType;
};

const TaskSidebarTitle: FunctionComponent<Props> = ({ task }) => {
  const { closeSidebar } = useContext(SidebarContext);
  const user = useUser();

  const sendDataLayerInfo = useCallback(() => {
    if (!window || !document) return;
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      event: 'go_to_project',
      element: 'task_sidebar',
      pageTitle: document.title,
      userDepartment: user?.department?.name,
    });
  }, [window, document]);

  const goToTheProject = () => {
    closeSidebar();
    sendDataLayerInfo();
  };

  return (
    <Styled.TitleContainer>
      {task.project && (
        <Link
          to={`/projects/projects/${task.project.id}`}
          onClick={() => {
            goToTheProject();
          }}
          style={{ cursor: 'pointer' }}
        >
          <Styled.ProjectInfo>
            <Text color={'semiGrey'} size={'l'}>
              {task.project.client.name} /
            </Text>
            <Text color={'primary'} bold style={{ textWrap: 'nowrap', marginRight: '0.35rem' }}>
              #{task.project.id}
            </Text>
            <Text color={'semiGrey'} size={'l'}>
              {task.project.name}
            </Text>
          </Styled.ProjectInfo>
        </Link>
      )}
      <Styled.TitleWrapper>
        <Styled.Title size={'l'} level={2}>
          {task.title}
        </Styled.Title>
        {task.hasResources && (
          <Icon size={1.5}>
            <AttachmentIcon />
          </Icon>
        )}
      </Styled.TitleWrapper>
    </Styled.TitleContainer>
  );
};

export default TaskSidebarTitle;
