import React from 'react';
import { CellTemplate } from '../../../molecules/CellTemplates/CellTemplate';
import { TaskType } from '../../../../@types/Task/TaskType';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import IdText from '../../../molecules/IdText/IdText';
import TaskStatus from '../../../molecules/Statuses/TaskStatus/TaskStatus';

type Props = {};

const TaskIdTemplate: CellTemplate<string, {}, TaskType> = ({ value, item }) => {
  return (
    <Grid spacing={0.5}>
      <GridItem $desktop={12}>
        <IdText withHash={!!value} value={value ?? ''} />
      </GridItem>
      <GridItem $desktop={12}>
        <TaskStatus status={item.status} />
      </GridItem>
    </Grid>
  );
};

export default TaskIdTemplate;
