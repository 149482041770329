import styled from 'styled-components';
import { color } from '../../../styles/Variables';

export const Button = styled.button<{ $secondary?: boolean }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  background-color: ${({ $secondary }) => ($secondary ? color.primary['40'] : color.primary['60'])};
  color: ${color.light};
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    background-color: ${color.primary['70']};
    box-shadow: 0 0 16px ${color.primary['40']};
  }

  &:active {
    outline: none;
  }
`;
