import styled from 'styled-components';
import { color } from '../../../../styles/Variables';

export const Icon = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: ${color.neutral[30]};
  border-radius: 0.6rem;
  width: 4rem;
  height: 4rem;
`;

export const Image = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 0.6rem;
  overflow: hidden;
  border: 1px solid ${color.primary[60]};

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
`;
