import React, { FunctionComponent, useEffect, useState } from 'react';
import IncomeForm from './IncomeForm';
import { useLocation } from 'react-router-dom';
import Container from '../../../atoms/Container/Container';

type Props = {};

const IncomeCreate: FunctionComponent<Props> = (props) => {
  const [entity, setEntity] = useState<undefined | any>();
  const location = useLocation();

  useEffect(() => {
    if (!location.hasOwnProperty('state')) {
      return;
    }
    const state: any = location.state;
    if (state?.hasOwnProperty('cloned')) {
      setEntity(state.cloned);
    }
  }, [location]);
  return (
    <Container>
      {!entity && <IncomeForm />}
      {entity && <IncomeForm income={{ ...entity, number: '' }} />}
    </Container>
  );
};

export default IncomeCreate;
