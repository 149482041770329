import styled, { css } from 'styled-components';

export const Cell = styled.td<{ $clickable?: boolean }>`
  padding: 1.1rem 1rem;
  vertical-align: middle;
  min-height: 3rem;

  & > div {
    min-height: 3rem;
    display: flex;
    align-items: center;
  }

  ${({ $clickable }) =>
    $clickable &&
    css`
      cursor: pointer;
    `}

  &:first-child {
    padding-left: 1.5rem;
  }

  &:last-child {
    padding-right: 1.5rem;
  }
`;

type Props = {
  $justifyContent?: 'flex-start' | 'flex-end' | 'center' | undefined;
};

export const CellContent = styled.div<Props>`
  justify-content: flex-start;
  ${(p: Props) =>
    p.$justifyContent === 'flex-end' &&
    css`
      justify-content: flex-end;
      * {
        justify-content: flex-end;
        text-align: right;
      }
    `};
  ${(p: Props) =>
    p.$justifyContent === 'center' &&
    css`
      justify-content: center;
      * {
        justify-content: center;
        text-align: center;
      }
    `};
`;
