import { BaseAction, FinancialCompanyStateType } from './FinancialCompanyContext';

export const financialCompanyReducer = (previousState: FinancialCompanyStateType, action: BaseAction): FinancialCompanyStateType => {
  switch (action.type) {
    case 'SAVE_COMPANY':
      return {
        ...previousState,
        financialCompany: action.financialCompany,
      };
    default:
      return previousState;
  }
};
