import styled from 'styled-components';
import { color } from 'styles/Variables';

export const Arrows = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;
export const ArrowUp = styled.span`
  display: flex;
  width: 4rem;
  height: 100%;
  color: ${color.primary[60]};
  background: ${color.primary[20]};
  justify-content: center;
  align-items: center;
  pointer-events: all;
  cursor: pointer;
`;

export const ArrowDown = styled(ArrowUp)``;
