import React, { FunctionComponent, useContext } from 'react';
import { EntityProvider } from '../../../../Context/entity-context/entity-provider';
import { Route, Routes, useLocation } from 'react-router-dom';

import EmployeeForm from './Screens/EmployeeForm';
import EmployeeShowView from './Screens/EmployeeShowView';
import EmployeesList from '../../../../components/templates/Employees/EmployeesList/EmployeesList';
import Container from '../../../../components/atoms/Container/Container';
import { AuthContext } from '../../../../Context/auth-context/auth-context';
import { Helmet } from 'react-helmet';

const EmployeesSubmodule: FunctionComponent = () => {
  const { can } = useContext(AuthContext);

  return (
    <Container>
      <Helmet>
        <title>Employees | F.CAPE</title>
      </Helmet>
      <Routes>
        <Route path="" element={<EmployeesList />}></Route>
        <Route path=":id/show" element={<EmployeeShowView />}></Route>
        {can('EMPLOYEE.CAN_MANAGE') && (
          <>
            <Route path=":id/edit" element={<EmployeeForm />}></Route>
            <Route path="create" element={<EmployeeForm />}></Route>
          </>
        )}
      </Routes>
    </Container>
  );
};

export default EmployeesSubmodule;
