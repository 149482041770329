import React, { FunctionComponent } from 'react';

interface OwnProps {}

type Props = OwnProps;

const ExclamationMarkIcon: FunctionComponent<Props> = (props) => {
  return (
    <svg viewBox="0 0 24 24" data-name="Layer 1">
      <path
        fill={'currentColor'}
        stroke={'currentColor'}
        d="M12,14a1,1,0,0,0,1-1V7a1,1,0,0,0-2,0v6A1,1,0,0,0,12,14Zm0,4a1.25,1.25,0,1,0-1.25-1.25A1.25,1.25,0,0,0,12,18Z"
      />
    </svg>
  );
};

export default ExclamationMarkIcon;
