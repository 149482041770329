import styled, { css } from 'styled-components';
import { color } from '../../../styles/Variables';

type IconWrapperType = {
  $size: number;
  $color: string;
  onClick?: () => void;
  $disabled?: boolean;
  $reversedHover?: boolean;
};

export const IconWrapper = styled.div<IconWrapperType>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.$size}em;
  height: ${(props) => props.$size}em;
  color: ${(props) => props.$color};

  ${(props) => [
    !!props.onClick &&
      css`
        cursor: pointer;

        svg {
          path,
          rect {
            transition: all 0.2s ease-in-out;
          }
        }

        &:hover {
          svg {
            path {
              fill: ${props.$reversedHover ? color.neutral['90'] : color.primary['80']};
            }

            rect {
              stroke: ${props.$reversedHover ? color.neutral['90'] : color.primary['80']};
            }
          }
        }
      `,
  ]}
  ${(props) =>
    props.$disabled &&
    css`
      pointer-events: none;
    `}
  svg {
    width: 100%;
    height: 100%;
  }
`;
