import React from 'react';
import { CellTemplate } from '../../CellTemplate';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import Text from '../../../../atoms/Text';
import { TextSizeType } from '../../../../../@types/Text/TextSizeType';
import { ColorType } from '../../../../../@types/Color/ColorType';
import BetterChip, { ChipColor } from '../../../../atoms/Chips/BetterChip';
import { IconType } from 'react-icons';

export type ValueWithChipProps = {
  chips?: {
    display: (item: any) => string;
    condition: (item: any) => boolean;
    color?: (item: any) => ChipColor;
    icon?: (item: any) => IconType;
    title?: string;
  }[];
  textProperties?: { bold?: boolean; size?: TextSizeType; color?: ColorType };
  itemsSpacing?: number;
  minWidth?: string;
  maxWidth?: string;
};

const FinanceItemInfoTemplate: CellTemplate<'string', ValueWithChipProps> = ({ item, value, options }) => {
  return (
    <Grid spacing={options?.itemsSpacing ?? 1} style={{ minWidth: options?.minWidth ?? undefined, maxWidth: options?.maxWidth ?? undefined }}>
      <GridItem $desktop={12}>
        <Text color={options?.textProperties?.color ?? 'darkGrey'} size={options?.textProperties?.size ?? 'l'} bold={options?.textProperties?.bold ?? false}>
          {value}
        </Text>
      </GridItem>
      <div style={{ display: 'flex', flexFlow: 'column', gap: '.5rem' }}>
        {options?.chips?.map((chip, index) => {
          if (chip.condition(item)) {
            return (
              <GridItem key={index}>
                <BetterChip color={chip.color ? chip.color(item) : undefined} title={chip.title} icon={chip.icon ? chip.icon(item) : undefined}>
                  {chip.display(item)}
                </BetterChip>
              </GridItem>
            );
          }
        })}
      </div>
    </Grid>
  );
};

export default FinanceItemInfoTemplate;
