import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { ArrayResponseType } from '../../../@types/hydra/hydra';
import api, { createFullApiUrl } from '../../../services/api';
import qs from 'qs';
import { Grid, GridItem } from '../../atoms/Grid/Grid';
import TableFilters from './Table/TableFilters';
import TablePagination from './Table/TablePagination';
import * as Styled from './Table.styled';
import { TableProperties } from '../../../@types/Table/TableProperty';
import { EntityType } from '../../../@types/Entity/EntityType';
import TableHead from './Table/TableHead';
import TableBody from './Table/TableBody';
import serverEvents from '../../../services/serverEvents';
import { TableFiltersType } from '../../../@types/Table/TableFilterType';
import ExtendedRowComponent from '../../atoms/Table/ExtendedRowComponent';
import { TableRowActionsType } from '../../../@types/Table/TableRowActionType';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

export type TableProps<T extends EntityType> = {
  url: string;
  context: string;
  onItemClick?: (item: T) => void;
  properties: TableProperties<T>;
  onSelect?: (items: T[]) => void;
  filters?: TableFiltersType;
  globalFilters?: any;
  defaultFilters?: any;
  extendedRow?: ExtendedRowComponent<T>;
  rowActions?: TableRowActionsType<T>;
  reloadKey?: string | number;
  exportUrl?: string;
  itemLink?: (item: T) => string;
};

export type OrderType =
  | {
      [key: string]: 'ASC' | 'DESC';
    }
  | undefined;

type RequestState = {
  page: number;
  count: number;
  order?: OrderType;
};

const initialState: RequestState = {
  order: undefined,
  page: 1,
  count: 25,
};

export const createUrl = (url: string, filters: any, globalFilters: any, state?: RequestState) => {
  for (const key in filters) {
    if (typeof filters[key] === 'boolean' || filters[key] === 0) continue;
    if (!filters[key]) {
      delete filters[key];
    }
  }
  let _url = `${url}?${qs.stringify(filters)}&${qs.stringify(globalFilters)}`;
  if (state) {
    _url += `&${qs.stringify(state)}`;
  }
  return _url;
};

const fetchData = async (url: string, signal: any, state: RequestState, filters: any, globalFilters: any) => {
  const _url = createUrl(url, filters, globalFilters, state);
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();

  const promise = api
    .get<ArrayResponseType>(_url, {
      cancelToken: source.token,
    })
    .then((response) => {
      return response.data;
    });

  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled by React Query');
  });
  return promise;
};

/**
 * @param url
 * @param context is used to identify the query in the query cache. It is used to invalidate the query when needed. It must be the same, as '@context' returned by the backend.
 * @constructor
 */
const Table = <T extends EntityType>({ url, properties, reloadKey, ...props }: TableProps<T>): ReactElement => {
  const [state, setState] = useState(initialState);
  const [init, setInit] = useState(false);
  const [selectedItems, setSelectedItems] = useState<T[]>([]);
  const [filters, setFilters] = useState<any>({});
  const { data, isLoading, isFetching } = useQuery<ArrayResponseType<T>>(
    [props.context, state, filters, props.globalFilters],
    ({ signal }) => fetchData(url, signal, state, filters, props.globalFilters),
    {
      keepPreviousData: true,
      enabled: init,
    },
  );
  const client = useQueryClient();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const invalidate = useCallback(() => {
    client.invalidateQueries({
      queryKey: [props.context, state],
    });
  }, [client, props.context, state]);

  useEffect(() => {
    const listener = serverEvents.listen(props.context.split('|')[0]).subscribe((event) => {
      invalidate();
    });
    return () => {
      listener.unsubscribe();
    };
  }, [props.context, invalidate]);

  const handleSelectItem = useCallback((item: T) => {
    if (!props.onSelect) {
      return;
    }
    setSelectedItems((old) => [...old, item]);
  }, []);

  const handleDeselectItem = useCallback(
    (item: T) => {
      if (!props.onSelect) {
        return;
      }
      setSelectedItems((old) => old.filter((i) => i.id !== item.id));
    },
    [props.onSelect],
  );

  const handleSelectAll = useCallback(() => {
    if (!data) {
      return;
    }
    setSelectedItems(data['hydra:member']);
  }, [data]);

  const handleDeselectAll = useCallback(() => {
    setSelectedItems([]);
  }, []);

  useEffect(() => {
    if (!props.onSelect) {
      return;
    }
    props.onSelect(selectedItems.filter((item, index, self) => self.findIndex((i) => i.id === item.id) === index));
  }, [selectedItems]);

  useEffect(() => {
    if (!reloadKey) {
      return;
    }
    invalidate();
    setSelectedItems([]);
  }, [invalidate, reloadKey]);

  const handleExport = React.useCallback(() => {
    if (!props.exportUrl) {
      return;
    }
    const _url = createFullApiUrl(createUrl(props.exportUrl, filters, props.globalFilters));
    api
      .get(_url, {
        headers: {
          Accept: 'text/csv',
        },
      })
      .then((response) => {
        const content = response.data;
        const element = document.createElement('a');
        const file = new Blob([content], { type: 'text/csv' });
        element.href = URL.createObjectURL(file);
        element.download = 'exported.csv';
        document.body.appendChild(element);
        element.click();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.exportUrl, filters, props.globalFilters]);

  useEffect(() => {
    invalidate();
  }, [props.defaultFilters]);

  const setPage = useCallback(
    (page: number) => {
      const newSearch = new URLSearchParams(search);
      newSearch.set('page', page.toString());
      navigate(`${pathname}?${newSearch.toString()}`, { replace: true });
      setState((s) => ({ ...s, page: page }));
    },
    [search],
  );
  const setCount = useCallback(
    (count: number) => {
      setState((s) => ({ ...s, count: count, page: 1 }));
      localStorage.setItem('count', count.toString());
    },
    [search],
  );

  const handleFiltersChange = useCallback(
    (filters: any) => {
      setFilters(filters);
      if (init) {
        setState((s) => ({ ...s, page: 1 }));
      }
    },
    [setPage, init],
  );

  useEffect(() => {
    const newSearch = new URLSearchParams(search);
    const page = newSearch.get('page');
    const count = localStorage.getItem('count');
    if (page) {
      setState((s) => ({ ...s, page: parseInt(page) }));
    } else {
      setState((s) => ({ ...s, page: 1 }));
    }
    setState((s) => ({ ...s, count: count ? parseInt(count) : s.count }));
    setTimeout(() => {
      setInit(true);
    }, 500);
  }, []);

  return (
    <Grid $justifyContent={'space-between'}>
      {props.filters && (
        <GridItem $desktop={12} style={{ zIndex: 2 }}>
          <TableFilters
            filters={props.filters}
            onUpdate={handleFiltersChange}
            defaultFilters={props.defaultFilters}
            isFetching={isFetching}
            context={props.context}
          />
        </GridItem>
      )}
      <GridItem $desktop={12} style={{ zIndex: 1 }}>
        <Styled.Table>
          <TableHead
            properties={properties}
            order={state.order}
            onOrder={(newOrder) => setState((s) => ({ ...s, order: newOrder }))}
            selectable={!!props.onSelect}
            onSelectAll={handleSelectAll}
            onDeselectAll={handleDeselectAll}
            hasActions={!!props.rowActions}
            sums={data && data['hydra:sums']}
            extendable={!!props.extendedRow}
          />
          <TableBody<T>
            isLoading={isLoading}
            items={data && data['hydra:member']}
            properties={properties}
            onItemClick={props.onItemClick}
            onSelect={handleSelectItem}
            onDeselect={handleDeselectItem}
            selectedItems={selectedItems}
            extendedRow={props.extendedRow}
            selectable={!!props.onSelect}
            rowActions={props.rowActions}
            itemLink={props.itemLink}
          />
        </Styled.Table>
      </GridItem>
      <GridItem $desktop={12}>
        {data && data['hydra:totalItems'] > 0 && (
          <TablePagination
            page={state.page}
            count={state.count}
            setPage={(p) => setPage(p)}
            setCount={(pp) => {
              setCount(pp);
            }}
            totalCount={data && data['hydra:totalItems']}
            onExport={handleExport}
            exportable={!!props.exportUrl}
          />
        )}
      </GridItem>
    </Grid>
  );
};

export default Table;
