import React, { FunctionComponent } from 'react';
import { EmployeeType } from '../../../../../@types/Employee/EmployeeType';
import useModal from '../../../../../hooks/useModal';
import SnackContainer from '../../../../../legacy/CapeMorris/components/snack-bar/use-snack-container';
import api from '../../../../../services/api';
import Form from '../../../../molecules/Form/Form';
import { ModalFooter } from '../../../../molecules/Modal/Modal';
import Button from '../../../../atoms/Button/Button';

type Props = {
  employee: EmployeeType;
  onSuccess: () => void;
};

const EmployeeStatusModal: FunctionComponent<Props> = ({ employee, ...props }) => {
  const { hideModal } = useModal();
  const [waiting, setWaiting] = React.useState(false);
  const { showSnack } = SnackContainer.useContainer();

  const activate: string = `${employee['@id'].substr(4)}}/activate`;
  const deactivate: string = `${employee['@id'].substr(4)}}/deactivate`;

  const changeStatus = (path: string) => {
    api
      .put<EmployeeType>(path, {})
      .then(() => {
        setWaiting(false);
        props.onSuccess();
        showSnack({
          title: 'Success',
          message: 'Employee status changed successfully!',
          type: 'success',
        });
        hideModal();
      })
      .catch((error) => {
        setWaiting(false);
        const response = error.response;
        showSnack({
          title: 'Error',
          message: response?.data['hydra:description'] ?? 'Unexpected error occured',
          type: 'error',
        });
        hideModal();
      });
  };

  const handleSubmit = React.useCallback(
    (data: EmployeeType) => {
      setWaiting(true);
      if (employee.active) {
        changeStatus(deactivate);
      } else {
        changeStatus(activate);
      }
    },

    [employee],
  );

  return (
    <Form onSubmit={handleSubmit} defaultValues={employee}>
      Do you want to change status of this user?
      <ModalFooter>
        <Button $text="Cancel" kind={'link'} onClick={() => hideModal()} />
        <Button $text="Yes" kind={'secondary'} waiting={waiting} type={'submit'} />
      </ModalFooter>
    </Form>
  );
};

export default EmployeeStatusModal;
