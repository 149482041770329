import React, { FunctionComponent, useCallback, useRef } from 'react';
import { color } from '../../../../../../../../../styles/Variables';
import { Icon } from '../../../../../../../../atoms/Icon/Icon';
import EditIcon from '../../../../../../../../../Icons/edit.icon';
import TrashIcon from '../../../../../../../../../Icons/Trash.icon';
import useApi from '../../../../../../../../../hooks/useApi';
import { SectionType } from '../../../../../../../../../@types/Section/SectionType';
import * as Styled from './SectionName.styled';
import { useEntityContext } from '../../../../../../../../../Context/EntityContext/EntityContext';
import useModal from '../../../../../../../../../hooks/useModal';
import Form from '../../../../../../../../molecules/Form/Form';
import { TextInput } from '../../../../../../../../molecules/Form/TextInput/TextInput';
import { ModalFooter } from '../../../../../../../../molecules/Modal/Modal';
import Button from '../../../../../../../../atoms/Button/Button';

type Props = {
  section: Partial<SectionType>;
};

const SectionName: FunctionComponent<Props> = ({ section }) => {
  const { reload } = useEntityContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const { del, put } = useApi();
  const isWithoutSection = !section['id'];
  const { showModal, hideModal } = useModal();

  const onDelete = useCallback(() => {
    del(`/api/sections/${section.id}`).then(() => {
      void reload();
    });
  }, []);

  const updateSection = useCallback(
    ({ name }: { name: string }) => {
      put(`/api/sections/${section.id}`, { name }).then(() => {
        hideModal();
        void reload();
      });
    },
    [section],
  );

  const openModal = useCallback(() => {
    showModal({
      title: 'Edit section',
      body: (
        <Form onSubmit={updateSection} defaultValues={section}>
          <TextInput name="name" label="Section name" defaultValue={section.name} required />
          <ModalFooter>
            <Button $text="Save" type="submit" />
          </ModalFooter>
        </Form>
      ),
    });
  }, [updateSection]);

  return (
    <Styled.NameWrapper ref={containerRef}>
      <Styled.Name level={2} size={'s'} color={color.primary[60]}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '1.6rem',
            width: '100%',
          }}
        >
          {section.name}{' '}
          <span>
            {section.activeTaskCount} task{section.activeTaskCount !== 1 ? 's' : ''}
          </span>
          {!isWithoutSection && (
            <Styled.ActionWrapper>
              <Icon
                style={{ cursor: 'pointer' }}
                color={color.neutral[60]}
                onClick={(e) => {
                  e.stopPropagation();
                  openModal();
                }}
              >
                <EditIcon />
              </Icon>
            </Styled.ActionWrapper>
          )}
        </div>
        {!isWithoutSection && (
          <Styled.ActionWrapper>
            <Icon
              style={{ cursor: 'pointer' }}
              onClick={() => {
                onDelete();
              }}
              color={color.neutral[60]}
            >
              <TrashIcon />
            </Icon>
          </Styled.ActionWrapper>
        )}
      </Styled.Name>
    </Styled.NameWrapper>
  );
};

export default SectionName;
