import IdTemplate from '../../../components/molecules/CellTemplates/common/IdTemplate/IdTemplate';
import StringTemplate from '../../../components/molecules/CellTemplates/common/StringTemplate/StringTemplate';
import { TableProperties } from '../../../@types/Table/TableProperty';
import { EmployeeType } from '../../../@types/Employee/EmployeeType';
import PersonTemplate from '../../../components/molecules/CellTemplates/common/PersonTemplate/PersonTemplate';

const properties: TableProperties<EmployeeType> = [
  {
    name: '@id',
    visibleName: 'ID',
    template: IdTemplate,
  },
  {
    name: 'name',
    visibleName: 'Name',
    template: PersonTemplate,
    templateOptions: {
      textProperties: {
        size: 'l',
        bold: true,
      },
    },
  },
  {
    name: 'email',
    visibleName: 'E-mail',
    template: StringTemplate,
    templateOptions: {
      textProperties: {
        size: 'm',
        bold: true,
      },
    },
  },
  {
    name: 'phoneNumber',
    visibleName: 'Phone',
    template: StringTemplate,
    templateOptions: {
      textProperties: {
        size: 'm',
        bold: true,
      },
    },
  },
  {
    name: 'department.name',
    visibleName: 'Department',
    template: StringTemplate,
    templateOptions: {
      textProperties: {
        size: 'm',
        bold: true,
      },
    },
  },
  {
    name: 'position.name',
    visibleName: 'Position',
    template: StringTemplate,
    templateOptions: {
      textProperties: {
        size: 'm',
        bold: true,
      },
    },
  },
  {
    name: 'teamLeader',
    visibleName: 'Leader',
    template: PersonTemplate,
    templateOptions: {
      textProperties: {
        size: 'm',
        bold: true,
      },
    },
  },
];
export default properties;
