import React from 'react';
import { CellTemplate } from '../../CellTemplate';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import Text from '../../../../atoms/Text';
import { TaskType } from '../../../../../@types/Task/TaskType';
import TaskDeadlineDropdown from '../../../Task/TaskForm/TaskDeadlineDropdown/TaskDeadlineDropdown';
import EditableDetail from '../../../EditableDetail/EditableDetail';
import BetterChip from '../../../../atoms/Chips/BetterChip';
import { parseDate } from '../../../../../helpers/date';

const TaskDateTemplate: CellTemplate<string, TaskType> = ({ item }) => {
  return (
    <Grid>
      <GridItem>
        <EditableDetail<TaskType>
          name={'deadline'}
          subject={item}
          show={
            <>
              {item.deadline ? (
                <BetterChip>{parseDate(item.deadline)}</BetterChip>
              ) : (
                <Text color={'grey'} size={'s'} style={{ textWrap: 'nowrap' }} bold>
                  No due date
                </Text>
              )}
            </>
          }
          component={TaskDeadlineDropdown}
        />
      </GridItem>
    </Grid>
  );
};
export default TaskDateTemplate;
