import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../legacy/CapeMorris/components';
import CostShow from './CostShow';
import { CostType } from '../../../../@types/Finances/Cost/CostType';
import EntityProvider from '../../../../Context/EntityContext/EntityProvider';
import Container from '../../../atoms/Container/Container';

type Props = {};

const CostShowView: FunctionComponent<Props> = (props) => {
  let { id } = useParams<{ id: string }>();
  return (
    <EntityProvider<CostType> url={`/api/costs/${id}`}>
      {({ loading, entity }) => (
        <Container>
          {loading && <Loader />}
          {entity && <CostShow cost={entity} />}
        </Container>
      )}
    </EntityProvider>
  );
};

export default CostShowView;
