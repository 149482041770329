import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import * as Styled from './Checkbox.styled';
import { useFormContext } from 'react-hook-form';

/**
 * todo: disabled and required
 */
type Props = {
  selected?: boolean;
  onChange?: (value: boolean) => void;
  name: string;
  label?: string | ReactElement;
  value?: string;
};

const Checkbox: FunctionComponent<Props> = ({ name, selected = false, ...props }) => {
  const { register } = useFormContext();
  const [internalValue, setInternalValue] = useState(selected);

  useEffect(() => {
    if (selected !== internalValue) {
      setInternalValue(selected);
    }
  }, [selected]);

  useEffect(() => {
    props.onChange && props.onChange(internalValue);
  }, [internalValue]);

  return (
    <Styled.CheckboxContainer $checked={internalValue}>
      <Styled.Checkbox $checked={internalValue}>
        <Tick />
      </Styled.Checkbox>
      <Styled.HiddenInput
        value={props.value}
        type="checkbox"
        checked={internalValue}
        {...register(name, { onChange: (event) => setInternalValue(event.target.checked) })}
      />
      {props.label}
    </Styled.CheckboxContainer>
  );
};

const Tick: FunctionComponent = () => (
  <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 2.84803L2.55145 4.67562C2.91852 5.10813 3.51347 5.10813 3.88054 4.67562L7 1"
      stroke="white"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Checkbox;
