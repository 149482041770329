import React from 'react';
import { TableFilterInterface } from '../TableFilterInterface';
import { SwitchInput } from '../../Form/SwitchInput/SwitchInput';

/**
 * Used to filter elements that have a value for a given property.
 *
 * @param props
 * @constructor
 */
const ExistsFilter: TableFilterInterface = ({ filter, onChange }) => {
  return <SwitchInput name={`filters.exists<${filter.name}>`} label={filter.label} onChange={onChange} />;
};

export default ExistsFilter;
