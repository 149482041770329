import React, { ComponentType, useEffect } from 'react';
import Select from '../../Form/SelectInput/SelectInput';
import { TableFilterInterface } from '../TableFilterInterface';
import { DictValue } from '../../../../@types/Dictionary/DictValue';
import { useFormContext } from 'react-hook-form';

export type SelectFilterOptions = {
  baseUrl?: string;
  options?: DictValue[];
  optionLabel?: string;
  optionComponent?: ComponentType<any>;
  singleValueComponent?: ComponentType<any>;
  baseUrlParams?: { [p: string]: string | number | string[] } | undefined;
  isMulti?: boolean;
};
const SelectFilter: TableFilterInterface<SelectFilterOptions> = ({ name, filter, onChange, defaultValue, options }) => {
  return (
    <Select
      name={name}
      label={filter.label ?? name}
      onChange={onChange}
      optionComponent={options && options.optionComponent}
      singleValueComponent={options && options.singleValueComponent}
      defaultValue={defaultValue}
      baseUrl={options && options.baseUrl}
      options={options && options.options}
      optionLabel={options && options.optionLabel}
      baseUrlParams={options && options.baseUrlParams}
      isMulti={filter.type === 'multiselect' || (options && options.isMulti)}
      isFilter={true}
    />
  );
};

export default SelectFilter;
