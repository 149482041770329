import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import DropdownMenu from '../../../../../../DropdownMenu/DropdownMenu';
import { ArrowIcon } from '../../../../../../../../Icons/arrow.icon';
import { color } from '../../../../../../../../styles/Variables';
import Text from '../../../../../../../atoms/Text/Text';
import DeleteIcon from '../../../../../../../../Icons/delete.icon';
import { Icon } from '../../../../../../../atoms/Icon/Icon';
import { ProjectRoleType } from '../../../../../../../../@types/Project/ProjectRoleType';
import useApi from '../../../../../../../../hooks/useApi';
import { DropdownMenuItemType } from '../../../../../../DropdownMenu/DropdownMenuItem/DropdownMenuItem';
import EditIcon from '../../../../../../../../Icons/edit.icon';
import RoleModal from '../../RoleModal/RoleModal';
import { ModalContext } from '../../../../../../../../Context/ModalContext/ModalContext';

type Props = {
  role: ProjectRoleType;
  projectId: number;
  onChange: () => void;
};

const RoleDropdownMenu: FunctionComponent<Props> = ({ role, projectId, onChange }) => {
  const { del } = useApi();
  const { showModal } = useContext(ModalContext);
  const [menuItems] = useState<DropdownMenuItemType[]>([
    {
      icon: (
        <Icon>
          <EditIcon />
        </Icon>
      ),
      text: 'Change role',
      onClick: () => {
        showModal({
          title: 'Edit employee role',
          body: <RoleModal projectId={`/api/projects/${projectId}`} role={role} onChange={onChange} />,
        });
      },
    },
    {
      icon: (
        <Icon color={color.semantic.error['100']}>
          <DeleteIcon />
        </Icon>
      ),
      text: (
        <Text as="div" bold={true} color={'error'}>
          Remove from project
        </Text>
      ),
      onClick: () => {
        del(`/api/project_roles/${role.id}`).then(() => onChange());
      },
    },
  ]);

  return (
    <DropdownMenu menuItems={menuItems}>
      <ArrowIcon />
    </DropdownMenu>
  );
};

export default RoleDropdownMenu;
