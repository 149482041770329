import React, { FunctionComponent } from 'react';

type PropType = {
  size?: number;
};

const AddWorkIcon: FunctionComponent<PropType> = ({ size = 1 }) => {
  return (
    <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.66732 14C2.30065 14 1.98676 13.8694 1.72565 13.6083C1.46454 13.3472 1.33398 13.0333 1.33398 12.6666V5.33331C1.33398 4.96665 1.46454 4.65276 1.72565 4.39165C1.98676 4.13054 2.30065 3.99998 2.66732 3.99998H5.33398V2.66665C5.33398 2.29998 5.46454 1.98609 5.72565 1.72498C5.98676 1.46387 6.30065 1.33331 6.66732 1.33331H9.33398C9.70065 1.33331 10.0145 1.46387 10.2757 1.72498C10.5368 1.98609 10.6673 2.29998 10.6673 2.66665V3.99998H13.334C13.7007 3.99998 14.0145 4.13054 14.2757 4.39165C14.5368 4.65276 14.6673 4.96665 14.6673 5.33331V8.18331C14.4673 8.03887 14.2562 7.91387 14.034 7.80831C13.8118 7.70276 13.5784 7.61109 13.334 7.53331V5.33331H2.66732V12.6666H7.38398C7.41732 12.9 7.46732 13.1278 7.53398 13.35C7.60065 13.5722 7.68398 13.7889 7.78398 14H2.66732ZM6.66732 3.99998H9.33398V2.66665H6.66732V3.99998ZM12.0007 15.3333C11.0784 15.3333 10.2923 15.0083 9.64232 14.3583C8.99232 13.7083 8.66732 12.9222 8.66732 12C8.66732 11.0778 8.99232 10.2916 9.64232 9.64165C10.2923 8.99165 11.0784 8.66665 12.0007 8.66665C12.9229 8.66665 13.709 8.99165 14.359 9.64165C15.009 10.2916 15.334 11.0778 15.334 12C15.334 12.9222 15.009 13.7083 14.359 14.3583C13.709 15.0083 12.9229 15.3333 12.0007 15.3333ZM13.1007 13.5666L13.5673 13.1L12.334 11.8666V9.99998H11.6673V12.1333L13.1007 13.5666Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default AddWorkIcon;
