import React, { FunctionComponent } from 'react';
import { Route, Routes } from 'react-router-dom';
import Iframe from '../../legacy/CapeMorris/components/Iframe/Iframe';
import MyInventorySubmodule from './submodules/MyInventorySubmodule';
import { Helmet } from 'react-helmet';

const ProfileModule: FunctionComponent = () => {
  return (
    <Routes>
      <Route path="inventory" element={<MyInventorySubmodule />}></Route>
      <Route
        path=""
        element={
          <>
            <Helmet>
              <title>Profile | F.CAPE</title>
            </Helmet>
            <Iframe src={`${process.env.REACT_APP_FCAPE_LEGACY}/my-profile`} title="Profile screen" />
          </>
        }
      ></Route>
    </Routes>
  );
};

export default ProfileModule;
