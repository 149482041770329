import styled, { css } from 'styled-components';
import { color } from '../../../styles/Variables';

type DropdownMenuProps = {
  $isVisible?: boolean;
  $overflows?: boolean;
  $overflowsVertically?: boolean;
  $relative?: boolean;
  $left?: boolean;
};

export const DropdownMenu = styled.div<DropdownMenuProps>`
  flex-direction: column;
  position: absolute;
  background: ${color.light};
  border-radius: 4px;
  border: 1px solid ${color.neutral['50']};
  z-index: 19;

  ${(props: DropdownMenuProps) =>
    !props.$isVisible &&
    css`
      display: none;
    `}

  ${(props: DropdownMenuProps) =>
    props.$isVisible &&
    css`
      display: flex;
    `}

  ${(props) =>
    props.$overflows &&
    !props.$relative &&
    css`
      right: 0;
    `}

  ${(props) =>
    props.$overflowsVertically &&
    !props.$relative &&
    css`
      bottom: 0;
      right: 100%;
    `}

  ${(props) =>
    props.$left &&
    css`
      right: 101%;
      bottom: 0;
    `}
  ${(props) =>
    props.$left &&
    props.$relative &&
    css`
      bottom: -20px;
    `}
`;
