import React from 'react';
import { CellTemplate } from '../../CellTemplate';
import { ContractorType } from '../../../../../@types/Finances/Contractor/ContractorType';
import ContractorTypeTag from '../../../Finances/ContractorTypeTag/ContractorTypeTag';

const ContractorTypeTemplate: CellTemplate<string | number, {}, ContractorType> = ({ value, item }) => {
  return <ContractorTypeTag contractorType={item.type} />;
};

export default ContractorTypeTemplate;
