import React, { FunctionComponent } from 'react';
import { TaskType } from '../../../../@types/Task/TaskType';
import TaskSidebarTags from './elements/TaskSidebarTags';
import TaskSidebarTitle from './elements/TaskSidebarTitle';
import TaskSidebarDetails from './elements/TaskSidebarDetails';
import TaskSidebarDescription from './elements/TaskSidebarDescription';
import TaskSidebarSourceFiles from './elements/TaskSidebarSourceFiles';
import Comments from '../../Comments/Comments';

type Props = {
  task: TaskType;
};

const TaskSidebarBody: FunctionComponent<Props> = ({ task }) => {
  return (
    <>
      <TaskSidebarTags task={task} />
      <TaskSidebarTitle task={task} />
      <TaskSidebarDetails task={task} />
      <TaskSidebarDescription description={task.description} task={task} />
      <TaskSidebarSourceFiles taskId={task.id} disabled={!!task.deletedAt} />
      <div style={{ margin: '0 3.2rem' }}>
        <Comments subject={'Task'} subjectId={task.id} hideInput localStorageKey={task['@id']} disabled={!!task.deletedAt} />
      </div>
    </>
  );
};

export default TaskSidebarBody;
