import React, { FunctionComponent, useMemo } from 'react';
import Chip from '../../../atoms/Chips/Chip';
import { Icon } from '../../../atoms/Icon/Icon';
import PeopleIcon from '../../../../Icons/TimelineIcons/People.icon';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import Text from '../../../atoms/Text';
import PersonIcon from '../../../../Icons/Project/Person.icon';
import { ContractorType } from '../../../../@types/Finances/Contractor/ContractorType';
import { color } from '../../../../styles/Variables';
import { HomeIcon } from '../../../../Icons/home.icon';
import { ColorType } from '../../../../@types/Color/ColorType';
import ExclamationMarkIcon from '../../../../Icons/ExclamationMarkIcon';
import BetterChip from '../../../atoms/Chips/BetterChip';
import { PiBuilding, PiPerson } from 'react-icons/pi';
type Props = {
  contractorType: ContractorType['type'];
};

const ContractorTypeTag: FunctionComponent<Props> = ({ contractorType }) => {
  return (
    <BetterChip icon={contractorType === 'person' ? PiPerson : PiBuilding} color={contractorType === 'person' ? 'neutral' : 'primary'}>
      {contractorType === 'person' ? 'Person' : 'Company'}
    </BetterChip>
  );
};

export default ContractorTypeTag;
