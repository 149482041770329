import React, { FunctionComponent } from 'react';
import * as Styled from './ExportModal.styled';
import { CostType } from '../../../../../@types/Finances/Cost/CostType';
import moneyAsString from '../../../../../helpers/moneyAsString';
import SingleDetail from '../../../../molecules/SingleDetail/SingleDetail';
import { PiXCircleBold } from 'react-icons/pi';
import { color } from '../../../../../styles/Variables';

type Props = {
  cost: CostType;
  disabled?: boolean;
};

const ExportModalItem: FunctionComponent<Props> = ({ cost, disabled }) => {
  let paymentDue = new Date(cost.paymentDue);
  if (paymentDue < new Date()) {
    paymentDue = new Date();
  }
  const textColor = disabled ? 'grey' : 'neutral80';
  return (
    <Styled.Item>
      <td>
        <SingleDetail labelColor={textColor} color={textColor} title={'No.'}>
          {disabled ? <PiXCircleBold color={color.semantic.error[100]} /> : undefined} {cost.number}
        </SingleDetail>
      </td>
      <td>
        <SingleDetail labelColor={textColor} color={textColor} title={'Vendor'}>
          {cost.contractor?.name}
        </SingleDetail>
      </td>
      <td>
        <SingleDetail labelColor={textColor} color={textColor} title={'Value'}>
          {moneyAsString(cost.originalAmountGross)}
        </SingleDetail>
      </td>
      <td>
        <SingleDetail labelColor={textColor} color={textColor} title={'Payment date'}>
          {paymentDue.toISOString().split('T')[0]}
        </SingleDetail>
      </td>
    </Styled.Item>
  );
};

export default ExportModalItem;
