import React, { FunctionComponent } from 'react';
import TablePage from '../../../organisms/TablePage/TablePage';
import { properties } from './SubincomesList.properties';
import { filters } from './SubincomesList.filters';
import { SubincomeType } from '../../../../@types/Finances/Income/SubincomeType';

type Props = {};

const SubincomesList: FunctionComponent<Props> = (props) => {
  return (
    <TablePage<SubincomeType>
      heading={'Assignments'}
      url={'subincomes'}
      defaultFilters={{
        income__postedAt: new Date().getFullYear(),
      }}
      context={'/api/subincomes'}
      properties={properties}
      filters={filters}
      itemLink={(item) => `/finances/incomes/incomes/${item.income.id}/show`}
      exportUrl={'/subincomes/export'}
    />
  );
};

export default SubincomesList;
