import styled from 'styled-components';
import { color } from '../../../../styles/Variables';

export const RoleWrapper = styled.div`
  display: flex;
  transition: background-color 0.15s ease-in-out;
  border-radius: 6px;
  padding: 0.6rem 1rem;
  gap: 1.2rem;
  align-items: center;
  position: relative;

  .arrow-icon {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &:hover {
    background-color: ${color.neutral['30']};
    .arrow-icon {
      opacity: 1;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
