import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import DropdownMenuItem, { DropdownMenuItemType } from './DropdownMenuItem/DropdownMenuItem';
import * as Styled from './DropdownMenu.styled';

type InnerDropdownMenuProps = {
  menuItems: DropdownMenuItemType[];
  isVisible?: boolean;
  childrenLeft?: boolean;
  relative?: boolean;
  ref?: any;
};

const InnerDropdownMenu: FunctionComponent<InnerDropdownMenuProps> = ({ menuItems, isVisible = true, childrenLeft, relative }) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [overflows, setOverflows] = useState(false);
  const [overflowsVertically, setOverflowsVertically] = useState(false);

  const checkOverflow = () => {
    if (!divRef.current) {
      return;
    }
    const { left, width, height, top } = divRef.current.getBoundingClientRect();
    const isOverflowingHorizontally = left + width > window.innerWidth;
    const isOverflowingVertically = height + top > window.innerHeight;

    if (isOverflowingHorizontally) {
      setOverflows(true);
    } else {
      setOverflows(false);
    }
    if (isOverflowingVertically) {
      setOverflowsVertically(true);
    } else {
      setOverflowsVertically(false);
    }
  };

  useEffect(() => {
    checkOverflow();
  }, [isVisible]);

  return (
    <Styled.DropdownMenu
      $left={childrenLeft}
      $relative={relative}
      ref={divRef}
      $isVisible={isVisible}
      $overflows={overflows}
      $overflowsVertically={overflowsVertically}
    >
      {menuItems.map((item, idx) => (
        <DropdownMenuItem key={idx} {...item} childrenLeft={childrenLeft} />
      ))}
    </Styled.DropdownMenu>
  );
};

export default InnerDropdownMenu;
