import { ResourceType } from '../../@types/Resource/ResourceType';
import React, { useContext, useMemo, useRef } from 'react';
import { SidebarContext } from '../../Context/Sidebar/SidebarContext';
import ShowPdfSidebar from './ShowPdfSidebar';
import useOutsideClick from '../useOutsideClick';

const useShowPdf = (resource: ResourceType) => {
  const { openSidebar } = useContext(SidebarContext);

  const isPdf = useMemo(() => {
    return resource.file?.originalName?.endsWith('.pdf');
  }, [resource]);

  const pdfFile = useMemo(() => {
    if (resource.downloadUrl && isPdf) {
      return resource.downloadUrl.replace('download', 'preview');
    } else {
      return null;
    }
  }, [resource]);

  const showPdf = () => {
    if (pdfFile) {
      openSidebar(<ShowPdfSidebar pdfFile={pdfFile} />);
    }
  };

  return { showPdf, isPdf };
};

export default useShowPdf;
