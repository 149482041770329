import { DictValue } from '../../@types/Dictionary/DictValue';
import { SubcostType } from '../../@types/Finances/Cost/SubcostType';

export const CostKindDict: DictValue<SubcostType['kind']>[] = [
  {
    value: 'project',
    label: 'Project',
  },
  {
    value: 'management',
    label: 'Management',
  },
];
