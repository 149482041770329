import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const Attachments = styled.div`
  margin-left: 4rem;
  margin-top: 1.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;
