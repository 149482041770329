import React, { FunctionComponent, useMemo } from 'react';
import { TaskType } from '../../../../../@types/Task/TaskType';
import WorkloadTask from './Items/WorkloadTask/WorkloadTask';
import { MeetingType } from '../../../../../@types/Meeting/MeetingType';
import WorkloadMeeting from './Items/WorkloadMeeting/WorkloadMeeting';

type Props = {
  workloadTasks: TaskType[];
  workloadMeetings: MeetingType[];
};

const EmployeeWorkloadCollapseBody: FunctionComponent<Props> = ({ workloadTasks, workloadMeetings }) => {
  const sortedMeetings = useMemo(() => {
    return workloadMeetings.sort((a, b) => Number(new Date(a.start)) - Number(new Date(b.start)));
  }, [workloadMeetings]);

  return (
    <>
      {sortedMeetings.map((meeting) => (
        <WorkloadMeeting key={meeting.id} meeting={meeting}></WorkloadMeeting>
      ))}
      {workloadTasks.map((task) => (
        <WorkloadTask key={task.id} task={task}></WorkloadTask>
      ))}
    </>
  );
};

export default EmployeeWorkloadCollapseBody;
