import React from 'react';

type PropType = {
  size?: number;
};

export const MattermostIcon: React.FC<PropType> = ({ size = 2.25 }) => {
  return (
    <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.6895 11.8325C5.56274 6.29903 10.7982 2.85453 16.3475 3.00472L14.5689 5.10634C11.2768 5.70158 8.43417 7.94959 7.31696 11.2499C5.65469 16.1603 8.44081 21.5401 13.54 23.2664C18.6392 24.9925 24.1203 22.4112 25.7825 17.5011C26.8961 14.2117 26.0131 10.7118 23.776 8.24049L23.6393 5.48111C28.1238 8.73602 30.1806 14.6427 28.3104 20.1673C26.0087 26.9661 18.6314 30.6117 11.8326 28.3102C5.03381 26.0086 1.38799 18.6313 3.6895 11.8325ZM19.7766 4.21103C19.8431 4.18222 19.9197 4.17605 19.9936 4.20106C20.0661 4.22559 20.122 4.27544 20.1576 4.33653L20.1649 4.3484L20.1714 4.36201C20.1884 4.39462 20.203 4.43197 20.2098 4.47786C20.2232 4.56833 20.2292 4.78101 20.2317 4.99887L20.2323 5.05832C20.2328 5.12763 20.2331 5.19638 20.2332 5.26081V5.31489C20.233 5.49103 20.2315 5.62308 20.2315 5.62308L20.2826 7.54601L20.3581 9.7657L20.4523 13.6231C20.4523 13.6231 20.4523 13.6238 20.4523 13.6252L20.4524 13.6315C20.4524 13.6329 20.4524 13.6344 20.4524 13.6361L20.4524 13.6484C20.4517 13.8687 20.4065 15.4815 19.2624 16.6023C18.0322 17.8075 16.5211 17.6972 15.538 17.3645C14.5548 17.0315 13.2876 16.2013 13.0424 14.4966C12.834 13.0477 13.6043 11.8299 13.8401 11.4971L13.8559 11.4751C13.888 11.4306 13.9067 11.4072 13.9067 11.4072L16.3247 8.40034L17.7331 6.68299L18.9418 5.18642C18.9418 5.18642 19.1195 4.94835 19.3025 4.71387L19.3391 4.6671C19.4 4.58946 19.4603 4.51401 19.5134 4.4497L19.5444 4.41252C19.5895 4.35898 19.6278 4.31591 19.6546 4.28984C19.686 4.25946 19.7181 4.23967 19.7496 4.22432L19.7727 4.21261L19.7766 4.21103Z"
        fill="currentColor"
      />
    </svg>
  );
};
