import React, { FunctionComponent, useMemo } from 'react';
import * as Styled from './HistoryItem.styled';
import { InventoryAllocationType } from '../../../../../../@types/Inventory/InventoryAllocationType';
import Person from '../../../../Person/Person';
import Chip from '../../../../../atoms/Chips/Chip';
import { color } from '../../../../../../styles/Variables';
import Text from '../../../../../atoms/Text';
import { parseDate } from '../../../../../../helpers/date';
import Tooltip from '../../../../Tooltip/Tooltip';
import { DotIcon } from '../../../../../../Icons/Dot.icon';
import { ChipProperties, determineChipProperties } from '../../../../../atoms/Chips/determineChipProperties';

type Props = {
  allocation: InventoryAllocationType;
};

const HistoryItem: FunctionComponent<Props> = ({ allocation }) => {
  const historyItemType: ChipProperties = determineChipProperties(allocation.type);
  const historyItemStatus: { status: string; color: string } = useMemo(() => {
    switch (allocation.status) {
      case 'accepted':
        return { status: 'Accepted', color: color.semantic.success['100'] };
      case 'rejected':
        return { status: 'Rejected', color: color.semantic.error['80'] };
      case 'pending':
        return { status: 'Pending', color: color.semantic.warning['80'] };
      default:
        return { status: 'ERROR', color: color.semantic.error['80'] };
    }
  }, [allocation.type]);

  return (
    <Styled.HistoryItem>
      <Styled.ActionWrapper>
        <Person person={allocation.createdBy} textSize={'s'} />
        <Chip $size={'s'} $bgColor={historyItemType.bgColor} onClick={() => {}}>
          {historyItemType.icon}
          {historyItemType.type}
        </Chip>
        <>{allocation.type === 'release' && <Person person={allocation.person} textSize={'s'} />}</>
        <>{allocation.type === 'return' && <Person person={allocation.person} textSize={'s'} />}</>
        {allocation.type === 'release' && (
          <Tooltip
            content={
              <div style={{ display: 'flex' }}>
                <Text size={'s'} color={'grey'}>
                  Acceptation: &nbsp;
                </Text>
                <Text size={'s'} color={'light'}>
                  {historyItemStatus.status}
                </Text>
              </div>
            }
            trigger={<DotIcon dotColor={historyItemStatus.color} />}
            arrowPosition={'topCenter'}
          />
        )}
      </Styled.ActionWrapper>
      <Styled.DateWrapper>
        <Text color={'grey'} size={'s'} bold>
          {parseDate(allocation.createdAt)}
        </Text>
      </Styled.DateWrapper>
    </Styled.HistoryItem>
  );
};

export default HistoryItem;
