import styled, { css } from 'styled-components';
import { color } from '../../../../../styles/Variables';

type NotificationProps = {
  $isVisible: boolean;
};

export const Notification = styled.div<NotificationProps>`
  width: 100%;
  display: flex;
  background-color: ${color.primary['60']};
  align-items: center;
  height: 3.2rem;
  border-radius: 52px;
  padding: 0.8rem 1.2rem 0.8rem 0.8rem;
  justify-content: space-between;

  ${(props: NotificationProps) =>
    !props.$isVisible &&
    css`
      display: none;
    `}
`;

export const NotificationTitleWrapper = styled.div`
  display: flex;
  gap: 0.8rem;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const Label = styled.div`
  background-color: ${color.light};
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
  width: 4.6rem;
  border-radius: 52px;
`;
