import styled, { css } from 'styled-components';
import { UiText } from '../../../../styles/Typography';
import { color, fontSize, fontWeight } from '../../../../styles/Variables';

type WithStatus = {
  status?: string;
  isSuffix?: string;
};

export const FormInputContainer = styled.label<WithStatus>`
  display: block;
  width: 100%;
  border-bottom: 1px solid ${color.neutral[60]};
  transition: border-bottom-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);

  .css-1dwxodu-menu {
    z-index: 2;
  }

  &:hover {
    border-bottom-color: ${color.primary[80]};
  }

  ${(props) =>
    props.status === 'error' &&
    css`
      border-bottom-color: ${color.semantic.error[60]};
    `}
`;

type FormLabelProps = WithStatus & {
  required?: boolean;
  disabled?: boolean;
};

export const FormLabel = styled.span<FormLabelProps>`
  ${UiText};
  font-size: ${fontSize.body.s};
  color: ${(props) => (props.disabled ? color.neutral[60] : color.neutral[80])};
  transition: color 0.2s ease;
  display: flex;
  gap: 0.3rem;
  font-weight: ${fontWeight.bold};
  letter-spacing: -0.22px;
  line-height: normal;

  ${(props) =>
    props.required &&
    !props.disabled &&
    css`
      &:after {
        content: '*';
        color: ${color.semantic.error[100]};
      }
    `}
  ${(props) =>
    props.status === 'error' &&
    css`
      color: ${color.semantic.error[100]};
    `}
`;

export const FormPlaceholder = css<WithStatus>`
  ::placeholder {
    ${UiText};
    line-height: normal;
    opacity: 1;
    font-weight: ${fontWeight.bold};
    font-size: ${fontSize.body.m};
    letter-spacing: -0.26px;
    color: ${(props) => (props.status === 'error' ? color.semantic.error[100] : color.neutral[50])};
  }

  :focus::placeholder {
    ${(props) =>
      props.isSuffix &&
      css`
        width: calc(100% - 48px);
      `}
  }
`;

export const FormHtmlInput = styled.input<WithStatus>`
  ${FormPlaceholder};
  ${UiText};
  width: 100%;
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.body.m};
  outline: none;
  margin: 0;
  border: none;
  background: none;
  margin-top: 0.6rem;
  padding-bottom: 1rem;
  letter-spacing: -0.26px;
  line-height: normal;

  :focus {
  }
`;
export const FormInputSuffix = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  color: ${color.primary['60']};
  height: 100%;
  min-width: 48px;
  text-align: center;
  background-color: ${color.primary['20']};
  font-weight: ${fontWeight.bold};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SuffixWrapper = styled.div<{ $arrows?: boolean }>`
  position: relative;
  ${(props) =>
    props.$arrows &&
    css`
      input {
        text-align: center;
      }
    `}
`;

export const FormTextarea = styled.textarea<WithStatus>`
  ${FormPlaceholder};
  ${UiText};
  border: none;
  outline: none;
  font-weight: ${fontWeight.bold};
  resize: vertical;
  width: 100%;
  padding: 0.8rem 0 0;

  :focus {
    outline: none;
  }
`;
