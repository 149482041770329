import styled from 'styled-components';
import { color } from '../../../../../../../styles/Variables';

export const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  align-items: flex-start;
  width: 100%;
  background-color: ${color.neutral[10]};

  .collapse-header {
    padding-left: 2.6rem;
  }

  &:hover {
    .section-drag-handler {
      svg {
        opacity: 1;
        visibility: visible;
      }
    }
  }
`;

export const DragHandler = styled.div<{ $isDragging: boolean }>`
  position: absolute;
  top: 0.4rem;
  padding: 1.6rem 0;
  cursor: grab;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    cursor: grab;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    transition-property: opacity, visibility;
  }
`;
