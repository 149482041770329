import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import PageTop from '../../../molecules/Page/PageTop';
import { Card } from '../../../atoms/Card/Card';
import CardHeading from '../../../molecules/CardHeading/CardHeading';
import { TextInput } from '../../../molecules/Form/TextInput/TextInput';
import DatePicker from '../../../molecules/Form/DatePicker/DatePicker';
import SelectInput from '../../../molecules/Form/SelectInput/SelectInput';
import { DocumentTypeDict } from '../../../../@dicts/Cost/DocumentTypeDict';
import RichTextInput from '../../../molecules/Form/RichTextInput/RichTextInput';
import { SwitchInput } from '../../../molecules/Form/SwitchInput/SwitchInput';
import { useFormContext } from 'react-hook-form';
import { PurchaseOrderType } from '../../../../@types/Finances/PurchaseOrder/PurchaseOrderType';
import api from '../../../../services/api';
import SingleDetail from '../../../molecules/SingleDetail/SingleDetail';
import Button from '../../../atoms/Button/Button';
import EditIcon from '../../../../Icons/edit.icon';
import AcceptIcon from '../../../../Icons/Accept.icon';
import { useLocation, useNavigate } from 'react-router-dom';
import CurrencyInput from '../../../molecules/Form/CurrencyInput/CurrencyInput';
import PaymentsInput from '../../../organisms/PaymentsInput/PaymentsInput';
import SubcostsInput from './components/SubcostsInput/SubcostsInput';
import { CostSubject, CostType } from '../../../../@types/Finances/Cost/CostType';
import ResourceDropzone from '../../../organisms/Resources/ResourceDropzone';
import { ResourceType } from '../../../../@types/Resource/ResourceType';
import { AuthContext } from '../../../../Context/auth-context/auth-context';
import PurchaseOrderOption from '../../../molecules/Finances/PurchaseOrderOption/PurchaseOrderOption';
import { SidebarContext } from '../../../../Context/Sidebar/SidebarContext';
import StaticSelectInput from '../../../molecules/Form/SelectInput/StaticSelectInput';
import MoneyInput from '../../../molecules/Form/MoneyInput/MoneyInput';
import { VatDict } from '../../../../@dicts/Cost/Vat';
import CalculateGrossButton from '../../../atoms/CalculateGross/CalculateGrossButton';
import HiddenInput from '../../../atoms/Form/HiddenInput/HiddenInput';
import PurchaseOrderSingleDetail from '../../../molecules/SingleDetail/PurchaseOrderSingleDetail/PurchaseOrderSingleDetail';
import PurchaseOrderLabel from '../../../molecules/Finances/PurchaseOrderLabel';
import { color } from '../../../../styles/Variables';
import ContractorOption from '../../../molecules/Finances/ContractorOption';
import FinanceColumn from '../../../molecules/Finances/FinanceColumn';
import { Helmet } from 'react-helmet';

type Props = {
  cost?: CostType;
  onUpload: (attachments: ResourceType[]) => void;
  working: boolean;
};

const CostFormInner: FunctionComponent<Props> = ({ cost, onUpload, working }) => {
  const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrderType | undefined | null>(cost?.purchaseOrder);
  const isEdit = !!cost?.id;
  const heading = isEdit ? `Edit cost #${cost?.number} ${cost?.contractor?.name}` : 'Add new cost';
  const { watch, setValue, unregister } = useFormContext();
  const isPurchaseOrder = watch('isPurchaseOrder', !!cost?.purchaseOrder);
  const purchaseOrderIri = watch('purchaseOrder');
  const projectIri = watch('project');
  const currency = watch('currency');
  const vat = watch('vat');
  const netAmount = watch('originalAmountNet');
  const { can } = useContext(AuthContext);
  const navigate = useNavigate();
  const { isOpen, closeSidebar } = useContext(SidebarContext);
  const location = useLocation();
  const [financialCompany, setFinancialCompany] = useState(null);
  const [financialCompanyKey, setFinancialCompanyKey] = useState(0);

  useEffect(() => {
    if (typeof purchaseOrderIri === 'string') {
      api.get(purchaseOrderIri).then((response) => {
        setPurchaseOrder(response.data);
        setValue('costType', response.data.costType);
        setValue('currency', response.data.currency);
      });
    }
  }, [purchaseOrderIri]);

  useEffect(() => {
    getFinancialCompany();
  }, []);

  useEffect(() => {
    if (financialCompany && !isEdit) {
      setValue('financialCompany', financialCompany);
      setTimeout(() => {
        setFinancialCompanyKey(Math.random());
      }, 200);
    }
  }, [financialCompany]);

  const getFinancialCompany = useCallback(() => {
    api.get('/api/financial_companies/1').then((response) => {
      setFinancialCompany(response.data);
    });
  }, []);

  useEffect(() => {
    if (cost?.purchaseOrder) {
      setValue('isPurchaseOrder', true);
    }
  }, []);

  useEffect(() => {
    if (!isPurchaseOrder) {
      setPurchaseOrder(undefined);
      setValue('purchaseOrder', null);
      unregister('purchaseOrder');
    } else {
      unregister('financialCompany');
      unregister('branch');
      unregister('project');
      unregister('contractor');
    }
  }, [isPurchaseOrder]);

  const handleResourcesChange = useCallback((attachments: ResourceType[], newAttachments: ResourceType[]) => {
    onUpload(newAttachments);
  }, []);

  const PageActions = () => {
    return (
      <Grid spacing={2} $align={'center'}>
        <Helmet>
          <title>{`${cost ? 'Edit cost' : 'Create cost'} | F.CAPE`}</title>
        </Helmet>
        <GridItem $desktop={'auto'}>
          <Button $text={'Save cost'} waiting={working} kind={'primary'} type={'submit'} $icon={cost ? <EditIcon /> : <AcceptIcon />} />
        </GridItem>
        <GridItem $desktop={'auto'}>
          <Button
            $text={'Cancel'}
            kind={'secondary'}
            onClick={() => {
              closeSidebar();
              if (location.pathname.includes('create')) {
                navigate(-1);
              } else {
                navigate(-1);
              }
            }}
          />
        </GridItem>
      </Grid>
    );
  };

  const calculateGross = () => {
    if (vat > 0) {
      const grossValue = Math.round(netAmount.value * (1 + vat));
      setValue('originalAmountGross.value', grossValue);
    } else {
      setValue('originalAmountGross.value', netAmount.value);
    }
  };

  return (
    <Grid spacing={4} style={{ marginBottom: '2rem' }}>
      <GridItem $desktop={12}>
        <PageTop heading={heading} paddingBottom={'0'} rightColumn={<PageActions />} isPdfOpen={isOpen} />
      </GridItem>

      <FinanceColumn>
        <GridItem $desktop={12}>
          <Card>
            <CardHeading heading={'Purchase order'} />
            <Grid spacing={4}>
              <GridItem $desktop={6}>
                <SwitchInput name={'isPurchaseOrder'} label={'I have purchase order'} />
              </GridItem>
              <GridItem $desktop={6}>
                {isPurchaseOrder && (
                  <SelectInput
                    optionLabel={'title'}
                    name={'purchaseOrder'}
                    label={'Purchase order'}
                    baseUrl={'purchase_orders'}
                    required
                    optionComponent={PurchaseOrderOption}
                    singleValueComponent={PurchaseOrderLabel}
                    baseUrlParams={{
                      status: 'accepted',
                      closed: 0,
                    }}
                  />
                )}
              </GridItem>
              {purchaseOrder && (
                <>
                  <SingleDetail $desktop={6} title={'Company'}>
                    {purchaseOrder.company.name}
                  </SingleDetail>
                  <PurchaseOrderSingleDetail purchaseOrder={purchaseOrder} $desktop={4} />
                  <GridItem $desktop={12} style={{ borderBottom: `1px solid ${color.neutral[40]}` }}></GridItem>

                  <HiddenInput name={'currency'} value={purchaseOrder.currency} />
                  <SingleDetail $desktop={6} title={'Vendor'}>
                    {purchaseOrder.contractor.name}
                  </SingleDetail>
                  <SingleDetail $desktop={6} title={'Cost type'}>
                    {purchaseOrder.costType.label}
                  </SingleDetail>
                  <SingleDetail $desktop={6} title={'Amount net (PO)'} type={'money'}>
                    {purchaseOrder.originalAmountNet.asString}
                  </SingleDetail>
                  <SingleDetail $desktop={4} title={'Remain to invoice (PO)'} type={'money'}>
                    {purchaseOrder.originalRemainNet ? purchaseOrder.originalRemainNet.asString : '-'}
                  </SingleDetail>

                  <SingleDetail $desktop={6} title={'Payment terms (PO)'}>
                    {purchaseOrder.paymentTerms}
                  </SingleDetail>
                  <SingleDetail $desktop={4} title={'Currency'}>
                    {purchaseOrder.currency}
                  </SingleDetail>
                  {purchaseOrder.specialPaymentTermsDetails && (
                    <SingleDetail $desktop={6} title={'Special Payment Terms Details (PO)'}>
                      {purchaseOrder.specialPaymentTermsDetails}
                    </SingleDetail>
                  )}
                  {purchaseOrder.specialPaymentTermsReason && (
                    <SingleDetail $desktop={6} title={'Special Payment Terms Reason (PO)'}>
                      {purchaseOrder.specialPaymentTermsReason}
                    </SingleDetail>
                  )}
                </>
              )}
            </Grid>
          </Card>
        </GridItem>
        <GridItem $desktop={12}>
          <Card>
            <CardHeading heading={'Cost information'} />
            <Grid spacing={4}>
              <GridItem $desktop={6}>
                <TextInput name={'number'} label={'Number'} required />
              </GridItem>
              <GridItem $desktop={6}></GridItem>
              {!purchaseOrder && (
                <>
                  <GridItem $desktop={6}>
                    <SelectInput name={'financialCompany'} label={'Company'} baseUrl={'/api/financial_companies/'} required key={financialCompanyKey} />
                  </GridItem>
                  <GridItem $desktop={6}>
                    <SelectInput
                      key={projectIri}
                      name={'contractor'}
                      label={'Vendor'}
                      baseUrl={'/api/contractors/'}
                      required
                      optionComponent={ContractorOption}
                    />
                  </GridItem>
                  <GridItem $desktop={6}>
                    <CurrencyInput name={'currency'} required />
                  </GridItem>
                  <GridItem $desktop={6}></GridItem>
                </>
              )}

              <GridItem $desktop={4}>
                <DatePicker name={'issuedAt'} label={'Issue date'} required />
              </GridItem>
              <GridItem $desktop={4}>
                <DatePicker name={'postedAt'} label={'Post date'} required />
              </GridItem>
              <GridItem $desktop={4}>
                <DatePicker name={'paymentDue'} label={'Payment due'} required />
              </GridItem>
              <GridItem $desktop={12}>
                <StaticSelectInput name={'documentType'} label={'Document type'} options={DocumentTypeDict} required />
              </GridItem>
              <GridItem $desktop={12}>
                <RichTextInput name={'note'} label={'Comment'} placeholder={'Comment'} />
              </GridItem>
              {can('FINANCES.COSTS.CAN_MANAGE_CONFIDENTIAL') && (
                <GridItem $desktop={4}>
                  <SwitchInput name={'confidential'} label={'Confidential'} />
                </GridItem>
              )}
              <GridItem $desktop={4}>
                <SwitchInput name={'internal'} label={'Internal (unreported)'} />
              </GridItem>
              <GridItem $desktop={4}>
                <SwitchInput name={'splitPayment'} label={'Split payment'} />
              </GridItem>
              <GridItem $desktop={12}>
                <SwitchInput name={'excludeFromBankExport'} label={'Excl. from bank export'} />
              </GridItem>
            </Grid>
          </Card>
        </GridItem>
        <GridItem $desktop={12}>
          <Card>
            <CardHeading heading={'Payments'} />
            <PaymentsInput currency={currency} hideHeader />
          </Card>
        </GridItem>
      </FinanceColumn>
      {isOpen && <GridItem $desktop={12} />}
      <FinanceColumn>
        <GridItem $desktop={12} style={{ order: isOpen ? 2 : 0 }}>
          <Card>
            <CardHeading heading={'Upload attachment'} />
            <ResourceDropzone subject={CostSubject} subjectId={cost?.id} context={'finances'} onChange={handleResourcesChange} />
          </Card>
        </GridItem>
        <GridItem $desktop={12} style={{ zIndex: 11 }}>
          <Card>
            <CardHeading $noMargin heading={'Cost values'} />
            <GridItem style={{ marginBottom: '1.6rem' }}></GridItem>

            <Grid spacing={2} $align={'center'}>
              <GridItem $desktop={4}>
                <MoneyInput name={'originalAmountNet'} currency={currency} label={'Net amount'} required />
              </GridItem>
              <GridItem $desktop={3}>
                <StaticSelectInput name={'vat'} options={VatDict} label={'VAT'} />
              </GridItem>
              <GridItem $desktop={1} style={{ display: 'flex', justifyContent: 'center' }}>
                <CalculateGrossButton onClick={calculateGross} />
              </GridItem>
              <GridItem $desktop={4}>
                <MoneyInput name={'originalAmountGross'} currency={currency} label={'Gross amount'} required />
              </GridItem>
            </Grid>
          </Card>
        </GridItem>
        <GridItem $desktop={12}>
          <Grid spacing={2}>
            <SubcostsInput purchaseOrder={purchaseOrder} />
          </Grid>
        </GridItem>
      </FinanceColumn>
    </Grid>
  );
};

export default CostFormInner;
