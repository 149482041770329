import React, { FunctionComponent, useMemo } from 'react';
import { NotificationType } from '../../../../@types/Notification/NotificationType';
import * as Styled from '../Notification.styled';
import Text from '../../../atoms/Text';
import RichText from '../../../atoms/RichText/RichText';
import { color } from '../../../../styles/Variables';
import Chip from '../../../atoms/Chips/Chip';
import { Icon } from '../../../atoms/Icon/Icon';
import CommentIcon from '../../../../Icons/Comment.icon';
import { getNotificationAction } from '../Notification';

type Props = {
  notification: NotificationType;
  timeComponent: JSX.Element;
  indicatorComponent: JSX.Element;
};

const CommentNotification: FunctionComponent<Props> = ({ notification, timeComponent, indicatorComponent }) => {
  const projectId = useMemo(() => {
    const action = getNotificationAction(notification);
    if (!action) {
      return;
    }
    if (action.hasOwnProperty('PROJECT')) {
      return (
        <Text size={'xs'} color={'semiGrey'} style={{ display: 'flex', gap: '0.3rem' }}>
          Project id:{' '}
          <Text size={'xs'} bold color={'primary'}>
            #{action['PROJECT']}
          </Text>
        </Text>
      );
    }
  }, []);

  return (
    <>
      <Styled.TopWrapper>
        {indicatorComponent}
        {timeComponent}
        <Chip $size={'s'} $bgColor={notification.notificationType === 'mention' ? color.primary[40] : color.neutral[40]}>
          <Icon color={notification.notificationType === 'mention' ? color.primary[60] : color.neutral[60]}>
            <CommentIcon />
          </Icon>
          <Text size={'xs'} color={notification.notificationType === 'mention' ? 'primary' : 'semiGrey'}>
            {notification.notificationType === 'mention' ? 'Mention' : 'Comment'}
          </Text>
        </Chip>
      </Styled.TopWrapper>
      <Styled.ContentTitle size={'l'} bold={true}>
        {notification.subject}
      </Styled.ContentTitle>
      {projectId}
      <Text size={'xs'} color={'semiGrey'}>
        <RichText
          content={notification.content}
          paragraphStyles={{
            fontSize: '1.3rem',
            color: color.neutral[60],
          }}
          wordsLimit={10}
        />
      </Text>
    </>
  );
};

export default CommentNotification;
