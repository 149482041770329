import React from 'react';
import { color } from '../styles/Variables';

type PropType = {
  size?: number;
  dotColor?: string;
};

export const DotIcon: React.FC<PropType> = ({ size = 1, dotColor = color.neutral['60'] }) => {
  return (
    <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="4" cy="4.5" r="4" fill={dotColor} />
    </svg>
  );
};
