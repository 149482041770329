import React, { FunctionComponent, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  name: string;
  value: string | number | undefined;
};

const HiddenInput: FunctionComponent<Props> = ({ name, value }) => {
  const { register, setValue } = useFormContext();

  useEffect(() => {
    setValue(name, value);
  }, [value]);
  return <input type="hidden" id={name} defaultValue={value} {...register(name, { valueAsNumber: typeof value === 'number' })} />;
};

export default HiddenInput;
