import React, { useEffect, useState } from 'react';
import { TableFilterInterface } from '../TableFilterInterface';
import Select from '../../Form/SelectInput/SelectInput';
import { DictValue } from '../../../../@types/Dictionary/DictValue';

const YearFilter: TableFilterInterface = ({ name, filter, onChange, defaultValue }) => {
  const [options, setOptions] = useState<DictValue[]>([]);

  useEffect(() => {
    let options = [];
    for (let i = new Date().getFullYear() + 1; i >= 2019; i--) {
      options.push({ value: i, label: `${i}` });
    }
    setOptions(options);
  }, []);

  return <Select name={name} label={filter.label ?? name} onChange={onChange} defaultValue={defaultValue} options={options} />;
};

export default YearFilter;
