import React, { FunctionComponent } from 'react';
import { TaskType } from '../../../../../@types/Task/TaskType';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import { Icon } from '../../../../atoms/Icon/Icon';
import { color } from '../../../../../styles/Variables';
import StopIcon from '../../../../../Icons/Stop.icon';
import Text from '../../../../atoms/Text/Text';
import SandglassInCircle from '../../../../../Icons/SandglassInCircle.icon';
import Popover from '../../../../atoms/Popover/Popover';
import Link from '../../../../atoms/Link/Link';

type Props = {
  title: TaskType['title'];
  status?: 'previous' | 'next';
  taskId: number;
};

const TaskDependency: FunctionComponent<Props> = ({ title, status, taskId }) => {
  return (
    <Popover popover={title} onHover>
      <Link href={`?task=${taskId}`}>
        <Grid spacing={0.8} $align={'center'} style={{ cursor: 'pointer', flexWrap: 'nowrap' }}>
          <GridItem>
            <Icon size={2} color={status === 'previous' ? color.semantic.error[80] : color.semantic.warning[80]}>
              {status === 'previous' ? <StopIcon size={2} /> : <SandglassInCircle />}
            </Icon>
          </GridItem>
          <GridItem>
            <Text
              color={status === 'previous' ? 'error' : 'warning'}
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                maxWidth: '20rem',
                textWrap: 'nowrap',
              }}
              bold
            >
              {title}
            </Text>
          </GridItem>
        </Grid>
      </Link>
    </Popover>
  );
};

export default TaskDependency;
