import React, { FunctionComponent } from 'react';
import Iframe from '../../../../legacy/CapeMorris/components/Iframe/Iframe';
import { Helmet } from 'react-helmet';

const ProjectsTimesheets: FunctionComponent = () => {
  return (
    <>
      <Helmet>
        <title>Timesheet | F.CAPE</title>
      </Helmet>
      <Iframe src={`${process.env.REACT_APP_FCAPE_LEGACY ?? '/'}/timesheet`} title="Timesheet" />
    </>
  );
};

export default ProjectsTimesheets;
