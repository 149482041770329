import React, { FunctionComponent } from 'react';
import { Grid } from '../../../../../../atoms/Grid/Grid';
import SingleChecklistItem from '../SingleChecklistItem/SingleChecklistItem';
import { ChecklistItemType } from '../../../../../../../@types/Checklist/ChecklistType';
import { color } from '../../../../../../../styles/Variables';

type Props = {
  items: ChecklistItemType[];
  handleComplete: (item: ChecklistItemType) => void;
};

const SingleChecklistBody: FunctionComponent<Props> = ({ items, handleComplete }) => {
  return (
    <>
      <Grid style={{ padding: '2.4rem 0', borderTop: `1px solid ${color.neutral[40]}`, gap: '1.6rem' }}>
        {items.map((item) => (
          <SingleChecklistItem item={item} key={item['@id']} onClick={handleComplete} />
        ))}
      </Grid>
    </>
  );
};

export default SingleChecklistBody;
