import React, { FunctionComponent } from 'react';

type PropType = {
  size?: number;
  color?: string;
};

const FigmaIcon: FunctionComponent<PropType> = ({ size = 1, color }) => {
  return (
    <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 18 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 13C9 11.9391 9.42143 10.9217 10.1716 10.1716C10.9217 9.42143 11.9391 9 13 9C14.0609 9 15.0783 9.42143 15.8284 10.1716C16.5786 10.9217 17 11.9391 17 13C17 14.0609 16.5786 15.0783 15.8284 15.8284C15.0783 16.5786 14.0609 17 13 17C11.9391 17 10.9217 16.5786 10.1716 15.8284C9.42143 15.0783 9 14.0609 9 13V13Z"
        stroke={color ?? 'currentColor'}
        strokeWidth="2"
      />
      <path
        d="M1 21C1 19.9391 1.42143 18.9217 2.17157 18.1716C2.92172 17.4214 3.93913 17 5 17H9V21C9 22.0609 8.57857 23.0783 7.82843 23.8284C7.07828 24.5786 6.06087 25 5 25C3.93913 25 2.92172 24.5786 2.17157 23.8284C1.42143 23.0783 1 22.0609 1 21Z"
        stroke={color ?? 'currentColor'}
        strokeWidth="2"
      />
      <path
        d="M9 1V9H13C14.0609 9 15.0783 8.57857 15.8284 7.82843C16.5786 7.07828 17 6.06087 17 5C17 3.93913 16.5786 2.92172 15.8284 2.17157C15.0783 1.42143 14.0609 1 13 1L9 1Z"
        stroke={color ?? 'currentColor'}
        strokeWidth="2"
      />
      <path
        d="M1 5C1 6.06087 1.42143 7.07828 2.17157 7.82843C2.92172 8.57857 3.93913 9 5 9H9V1H5C3.93913 1 2.92172 1.42143 2.17157 2.17157C1.42143 2.92172 1 3.93913 1 5Z"
        stroke={color ?? 'currentColor'}
        strokeWidth="2"
      />
      <path
        d="M1 13C1 14.0609 1.42143 15.0783 2.17157 15.8284C2.92172 16.5786 3.93913 17 5 17H9V9H5C3.93913 9 2.92172 9.42143 2.17157 10.1716C1.42143 10.9217 1 11.9391 1 13Z"
        stroke={color ?? 'currentColor'}
        strokeWidth="2"
      />
    </svg>
  );
};

export default FigmaIcon;
