import React, { FunctionComponent } from 'react';
import { getDayName, WeekDaysType } from '../../../../helpers/getDaysForWeekMethods';
import { parseDate } from '../../../../helpers/date';
import Text from '../../../../components/atoms/Text';
import { Wrapper } from './EmployeeWorkloadCollapse/EmployeeWorkloadCollapseHeader.styled';

type Props = {
  days: WeekDaysType;
};

const Days: FunctionComponent<Props> = ({ days }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'end' }}>
      <Wrapper>
        {days.map((day, index) => (
          <div key={index} style={{ width: '100px' }}>
            <Text bold color={parseDate(day) === parseDate(new Date()) ? 'primary' : 'dark'}>
              {getDayName(day)}
            </Text>
            <Text bold color={'grey'}>
              {parseDate(day, false, false, true)}
            </Text>
          </div>
        ))}
        <Text bold>Total</Text>
      </Wrapper>
    </div>
  );
};

export default Days;
