import React, { FunctionComponent, useEffect, useState } from 'react';
import ShareIcon from '../../../../Icons/Share.icon';
import * as Common from '../Common.styled';
import { FloatingButton } from '../../../atoms/FloatingButton/FloatingButton';
import PersonIcon from '../../../../Icons/Project/Person.icon';
import HeartIcon from '../../../../Icons/Heart.icon';
import DropdownMenu from '../../../organisms/DropdownMenu/DropdownMenu';
import { useNavigate } from 'react-router-dom';
import api from '../../../../services/api';
import { ArrayResponseType } from '../../../../@types/hydra/hydra';
import { ArticleType } from '../../../../@types/Article/ArticleType';

type Props = {};

const HelpButton: FunctionComponent<Props> = (props) => {
  const navigate = useNavigate();
  const [favorites, setFavorites] = useState<
    {
      link: string;
      label: string;
    }[]
  >([]);

  useEffect(() => {
    api.get<ArrayResponseType<ArticleType>>('/wiki/favorite-articles').then((res) => {
      setFavorites(
        res.data['hydra:member'].map((article) => ({
          link: article.fullPath,
          label: article.name,
        })),
      );
    });
  }, []);

  return (
    <FloatingButton id={'floating-button-help-main'}>
      <DropdownMenu
        childrenLeft
        menuItems={[
          {
            text: 'Contacts',
            id: 'floating-button-help-contacts',
            onClick: () => {
              navigate('/contacts');
            },
            icon: <PersonIcon size={1.1} />,
          },
          {
            text: 'Feedback',
            id: 'floating-button-help-feedback',
            onClick: () => {
              window.open(
                'https://teams.microsoft.com/l/channel/19%3A58783b1805354be2a638e1714e642253%40thread.tacv2/FCAPE%20Bugs%20and%20requests?groupId=d6c29023-b3f4-4c2f-86ef-0517016d4039&tenantId=991eb2ef-05b1-43ac-91e4-629ffba8abb1',
              );
            },
            icon: <ShareIcon size={1.1} />,
          },
          {
            text: 'Favorites',
            id: 'floating-button-help-favourites',
            icon: <HeartIcon size={1.1} />,
            children: favorites.map((favorite) => ({
              text: favorite.label,
              onClick: () => {
                navigate(favorite.link);
              },
            })),
          },
        ]}
      >
        <Common.Button $secondary>
          <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2.53125 8.125C2.53125 7.28125 2.60677 6.67448 2.75781 6.30469C2.90885 5.9349 3.22917 5.53125 3.71875 5.09375C4.14583 4.71875 4.47135 4.39323 4.69531 4.11719C4.91927 3.84115 5.03125 3.52604 5.03125 3.17188C5.03125 2.74479 4.88802 2.39062 4.60156 2.10938C4.3151 1.82812 3.91667 1.6875 3.40625 1.6875C2.875 1.6875 2.47135 1.84896 2.19531 2.17188C1.91927 2.49479 1.72396 2.82292 1.60938 3.15625L0 2.46875C0.21875 1.80208 0.619792 1.22396 1.20313 0.734375C1.78646 0.244792 2.52083 0 3.40625 0C4.5 0 5.34115 0.304687 5.92969 0.914062C6.51823 1.52344 6.8125 2.25521 6.8125 3.10938C6.8125 3.63021 6.70052 4.07552 6.47656 4.44531C6.2526 4.8151 5.90104 5.23438 5.42188 5.70312C4.91146 6.19271 4.60156 6.5651 4.49219 6.82031C4.38281 7.07552 4.32813 7.51042 4.32813 8.125H2.53125ZM3.40625 11.875C3.0625 11.875 2.76823 11.7526 2.52344 11.5078C2.27865 11.263 2.15625 10.9688 2.15625 10.625C2.15625 10.2812 2.27865 9.98698 2.52344 9.74219C2.76823 9.4974 3.0625 9.375 3.40625 9.375C3.75 9.375 4.04427 9.4974 4.28906 9.74219C4.53385 9.98698 4.65625 10.2812 4.65625 10.625C4.65625 10.9688 4.53385 11.263 4.28906 11.5078C4.04427 11.7526 3.75 11.875 3.40625 11.875Z"
              fill="currentColor"
            />
          </svg>
        </Common.Button>
      </DropdownMenu>
    </FloatingButton>
  );
};

export default HelpButton;
