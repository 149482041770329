import React from 'react';

type PropType = {
  size?: number;
  color?: string;
};

export const SlidersIcon: React.FC<PropType> = ({ size = 1, color = 'white' }) => {
  return (
    <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7528 6.56842H11.7596V0.462273C11.7596 0.206963 11.5597 -0.000244141 11.3136 -0.000244141C11.0674 -0.000244141 10.8676 0.206963 10.8676 0.462273V6.56842H9.65535C9.40916 6.56842 9.20935 6.77563 9.20935 7.03094C9.20935 7.28625 9.40916 7.49345 9.65535 7.49345H12.7528C12.999 7.49345 13.1988 7.28625 13.1988 7.03094C13.1988 6.77563 12.999 6.56842 12.7528 6.56842ZM3.21475 8.2998H4.3446C4.5908 8.2998 4.7906 8.50701 4.7906 8.76232C4.7906 9.01763 4.5908 9.22484 4.3446 9.22484H1.24715C1.00095 9.22484 0.801147 9.01763 0.801147 8.76232C0.801147 8.50701 1.00095 8.2998 1.24715 8.2998H2.32275V0.462395C2.32275 0.206623 2.52256 -0.00012207 2.76875 -0.00012207C3.01494 -0.00012207 3.21475 0.206623 3.21475 0.462395V8.2998ZM8.48132 2.86407H7.48611V0.462578C7.48611 0.206806 7.28631 6.10352e-05 7.04011 6.10352e-05C6.79392 6.10352e-05 6.59412 0.206806 6.59412 0.462578V2.86407H5.38386C5.13767 2.86407 4.93787 3.07128 4.93787 3.32659C4.93787 3.5819 5.13767 3.78911 5.38386 3.78911H8.48132C8.72751 3.78911 8.92732 3.5819 8.92732 3.32659C8.92732 3.07128 8.72751 2.86407 8.48132 2.86407ZM7.04011 11.9999C6.79392 11.9999 6.59412 11.7927 6.59412 11.5374V4.58666C6.59412 4.33135 6.79392 4.12415 7.04011 4.12415C7.28631 4.12415 7.48611 4.33135 7.48611 4.58666V11.5374C7.48611 11.7927 7.28631 11.9999 7.04011 11.9999ZM2.76863 12.0003C2.52244 12.0003 2.32263 11.7931 2.32263 11.5378V10.0864C2.32263 9.83111 2.52244 9.6239 2.76863 9.6239C3.01482 9.6239 3.21463 9.83111 3.21463 10.0864V11.5378C3.21463 11.7931 3.01482 12.0003 2.76863 12.0003Z"
        fill={color}
      />
    </svg>
  );
};
