import React, { FunctionComponent } from 'react';
import { TextInput } from '../../../../../../molecules/Form/TextInput/TextInput';
import HiddenInput from '../../../../../../atoms/Form/HiddenInput/HiddenInput';
import { ProjectEventType } from '../../../../../../../@types/Project/ProjectEventType';
import DatePicker from '../../../../../../molecules/Form/DatePicker/DatePicker';
import ModalForm from '../../../../../../organisms/Form/ModalForm/ModalForm';
import { Grid, GridItem } from '../../../../../../atoms/Grid/Grid';
type Props = {
  projectId: string;
  event?: ProjectEventType;
  onChange: () => void;
};

const TimelineModal: FunctionComponent<Props> = ({ projectId, event, onChange }) => {
  const isEdit = !!event;

  return (
    <ModalForm
      basePath={isEdit ? `/api/milestones/${event?.subjectId}` : '/api/milestones'}
      buttonText={isEdit ? 'Save milestone' : 'Add milestone'}
      defaults={event}
      onChange={onChange}
    >
      <Grid spacing={2.4}>
        <GridItem $desktop={12}>
          <TextInput name={'title'} label={'Title'} required />
        </GridItem>
        <GridItem $desktop={12}>
          <DatePicker name={'date'} label={'Date'} required />
        </GridItem>
        <GridItem $desktop={12}>
          <HiddenInput name={'type'} value={'milestone'} />
        </GridItem>
        <GridItem $desktop={12}>
          <HiddenInput name={'project'} value={projectId} />
        </GridItem>
      </Grid>
    </ModalForm>
  );
};

export default TimelineModal;
