import React, { FunctionComponent } from 'react';
import { CostType } from '../../../../../@types/Finances/Cost/CostType';
import * as Styled from './ExportModal.styled';
import Heading from '../../../../atoms/Heading';
import ExportModalItem from './ExportModalItem';
import moneyAsString from '../../../../../helpers/moneyAsString';
import Collapse from '../../../../atoms/Collapse/Collapse';

type Props = {
  costs: CostType[];
};

const ExportModal: FunctionComponent<Props> = ({ costs }) => {
  const exportableCosts = costs.filter((cost) => cost.bankExportEnabled);
  const total = exportableCosts.reduce((acc, cost) => acc + cost.amountGross.value, 0);
  return (
    <Styled.Container>
      <Heading level={3} size={'s'}>
        You will create CSV file including {exportableCosts.length} cost{exportableCosts.length > 1 ? 's' : ''}. Total:{' '}
        {moneyAsString({ value: total, currency: 'PLN' })}
      </Heading>
      <Styled.List>
        {exportableCosts.map((c) => (
          <ExportModalItem cost={c} key={c['@id']} />
        ))}
      </Styled.List>

      {costs.filter((c) => !c.bankExportEnabled).length > 0 && (
        <Collapse
          header={
            <Heading level={3} size={'s'} color={'darkGrey'}>
              Costs that cannot be exported.
            </Heading>
          }
          hidden={
            <>
              <Styled.InfoBox>
                Bank export is only available for accepted costs with PLN currency and without split payment. Vendor has to be accepted and have a bank account
                assigned.
              </Styled.InfoBox>
              <Styled.List>
                {costs
                  .filter((c) => !c.bankExportEnabled)
                  .map((c) => (
                    <ExportModalItem disabled cost={c} key={c['@id']} />
                  ))}
              </Styled.List>
            </>
          }
        />
      )}
    </Styled.Container>
  );
};

export default ExportModal;
