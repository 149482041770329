import styled, { css } from 'styled-components';
import { color } from '../../../styles/Variables';
import { UiText } from '../../../styles/Typography';

type ButtonProps = {
  $isActive: boolean;
};

export const SwitchWrapper = styled.div`
  display: flex;
`;

export const Button = styled.button<ButtonProps>`
  padding: 1rem 1.6rem;
  background-color: ${color.neutral['10']};
  display: flex;
  align-items: center;
  gap: 0.4rem;
  border: 1px solid ${color.neutral['40']};
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  ${(props: ButtonProps) =>
    props.$isActive &&
    css`
      background-color: ${color.light};
    `}
`;

export const LeftButton = styled(Button)`
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
  border-right: none;
`;

export const RightButton = styled(Button)`
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
`;

export const FeedIndicator = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${color.primary['60']};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FeedIndicatorText = styled.small`
  ${UiText};
  line-height: normal;
  font-size: 8px;
  color: ${color.light};
`;
