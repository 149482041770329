import React, { FunctionComponent } from 'react';
import TransformIcon from '../../../Icons/Transform.icon';
import styled from 'styled-components';
import { color } from '../../../styles/Variables';

type Props = {
  onClick: () => void;
};

const Button = styled.button`
  border: none;
  background-color: ${color.primary[30]};
  color: ${color.light};
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  border-radius: 0.3rem;
  transition: background-color 0.2s ease-in-out;
  :hover {
    background-color: ${color.primary[50]};
  }
  svg {
    width: 100%;
    height: 100%;
  }
`;

const CalculateGrossButton: FunctionComponent<Props> = (props) => {
  return (
    <Button type={'button'} onClick={() => props.onClick()}>
      <TransformIcon />
    </Button>
  );
};

export default CalculateGrossButton;
