import styled from 'styled-components';
import { color } from '../../../../styles/Variables';

export const TableActionContainer = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: ${color.neutral[60]};
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: ${color.primary[30]};
    color: ${color.primary[60]};
  }

  svg {
    width: 1.6rem;
    height: 1.6rem;
  }
`;
