import ExtendedRowComponent from '../../../../../../../components/atoms/Table/ExtendedRowComponent';
import { InventoryAllocationType } from '../../../../../../../@types/Inventory/InventoryAllocationType';
import * as Styled from './InventoryProtocolItemExtendedRow.styled';
import React, { useCallback } from 'react';
import Heading from '../../../../../../../components/atoms/Heading';
import Text from '../../../../../../../components/atoms/Text';
import Button from '../../../../../../../components/atoms/Button/Button';
import api from '../../../../../../../services/api';
import SnackContainer from '../../../../../../../legacy/CapeMorris/components/snack-bar/use-snack-container';
import UnionIcon from '../../../../../../../Icons/Union.icon';
import { Colored } from '../../../../../../../legacy/CapeMorris/components/layout/layout.styled';
import iriHelper from '../../../../../../../helpers/iri-helper';

const InventoryProtocolItemExtendedRow: ExtendedRowComponent<InventoryAllocationType> = ({ entity }) => {
  const { showSnack } = SnackContainer.useContainer();
  const handleReturn = useCallback(() => {
    const formData = {
      person: entity.person['@id'],
      createdBy: null,
      type: 'return',
      items: entity.items.map((i) => i['@id']),
    };
    return api
      .post('/api/inventory_allocations', formData)
      .then((r) => {
        showSnack({
          title: 'Success',
          message: 'Item restored',
          type: 'success',
        });
      })
      .catch(() => {
        showSnack({
          title: 'Error',
          message: 'Error while restoring items',
          type: 'error',
        });
      });
  }, []);

  return (
    <Styled.InventoryProtocolItemExtendedRowWrapper>
      <td></td>
      <td colSpan={3} style={{ padding: '2.4rem 0' }}>
        <Heading size={'s'}>Items ({entity.items?.length})</Heading>
        {entity.items?.map((item) => (
          <Styled.ItemWrapper>
            <Text>{item.name}</Text>
            <Text color={'grey'}>
              <Colored>#{iriHelper.iriToId(item['@id'])}</Colored> {item.manufacturer}/{item.model}
            </Text>
          </Styled.ItemWrapper>
        ))}
      </td>
      <td>{entity.type !== 'return' && <Button $text={'Return inventory'} $icon={<UnionIcon />} onClick={handleReturn} />}</td>
    </Styled.InventoryProtocolItemExtendedRowWrapper>
  );
};

export default InventoryProtocolItemExtendedRow;
