import styled from 'styled-components';
import { UiText } from '../../../styles/Typography';
import { fontSize } from '../../../styles/Variables';

export const Table = styled.table`
  position: relative;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  ${UiText};
  font-size: ${fontSize.body.m};
  margin-top: 3.6rem;
`;
