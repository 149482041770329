import React, { FunctionComponent } from 'react';

type PropType = {
  size?: number;
};

const LinkIcon: FunctionComponent<PropType> = ({ size = 1 }) => {
  return (
    <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.96294 15.1939C3.99562 14.2266 4.0393 12.6103 5.06036 11.5892L7.44881 9.20079L6.37401 8.12599L3.98556 10.5144C2.33753 12.1625 2.26714 14.7671 3.82843 16.3284C5.38972 17.8897 7.99439 17.8193 9.64242 16.1713L12.0309 13.7828L10.9561 12.708L8.56761 15.0965C7.54655 16.1176 5.93026 16.1612 4.96294 15.1939ZM7.97679 13.3114L12.7537 8.53454L11.6223 7.40317L6.84542 12.1801L7.97679 13.3114ZM9.95669 4.54331L7.56824 6.93176L8.64304 8.00657L11.0315 5.61812C12.0526 4.59705 13.6688 4.55337 14.6362 5.52069C15.6035 6.48801 15.5598 8.1043 14.5387 9.12537L12.1503 11.5138L13.2251 12.5886L15.6135 10.2002C17.2616 8.55214 17.332 5.94747 15.7707 4.38618C14.2094 2.82489 11.6047 2.89528 9.95669 4.54331Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LinkIcon;
