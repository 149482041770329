import styled from 'styled-components';
import { color } from '../../../../../../styles/Variables';

export const Timeline = styled.div`
  background-color: ${color.neutral['20']};
`;
export const HeadingWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
`;

export const TimelineEvents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;
