import React, { FunctionComponent, useState } from 'react';
import Button from '../../atoms/Button/Button';
import PlusIcon from '../../../Icons/Plus.icon';
import FeedEntryForm from './FeedEntryForm/FeedEntryForm';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { FeedEntryType } from '../../../@types/FeedEntry/FeedEntryType';

type Props = {
  onNewEntry: (entry: FeedEntryType) => void;
};

const NewFeedEntry: FunctionComponent<Props> = ({ onNewEntry }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const localStorageKey = 'new-feed-entry';

  const hide = () => {
    setIsVisible(false);
  };

  const handleChange = (entry: FeedEntryType) => {
    setIsVisible(false);
    onNewEntry(entry);
  };

  return (
    <div>
      <div style={isVisible ? { border: 'none' } : {}}>
        {!isVisible && (
          <Button
            $text={'Add new story'}
            onClick={() => {
              setIsVisible(true);
            }}
            full
            kind="ghost"
            $icon={<PlusIcon />}
          />
        )}

        {isVisible && <FeedEntryForm onChange={handleChange} basePath={'/api/feed_entries'} onCancel={hide} localStorageKey={localStorageKey} />}
      </div>
    </div>
  );
};

export default NewFeedEntry;
