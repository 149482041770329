import React, { FunctionComponent } from 'react';

type Props = {
  src: string | undefined;
  title?: string;
};

const Iframe: FunctionComponent<Props> = ({ src }) => {
  return (
    <iframe
      src={src}
      style={{
        transform: 'translateY(-60px)',
        height: '100vh',
        position: 'absolute',
      }}
    />
  );
};

export default Iframe;
