import { Grid, GridItem } from 'components/atoms/Grid/Grid';
import { Icon } from 'components/atoms/Icon/Icon';
import Text from 'components/atoms/Text';
import DeleteIcon from 'Icons/delete.icon';
import EditIcon from 'Icons/edit.icon';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { color } from 'styles/Variables';
import * as Styled from './Comment.styled';
import { CommentType } from '../../../@types/Comment/CommentType';
import DateString from '../../atoms/DateString/DateString';
import { AuthContext } from '../../../Context/auth-context/auth-context';
import Person from '../Person/Person';
import Attachments from '../Attachments/Attachments';
import CommentEditor from './elements/CommentEditor';
import { AttachmentType } from '../../../@types/Attachment/AttachmentType';
import useApi from '../../../hooks/useApi';

type PropType = {
  comment: CommentType;
  localStorageKey: string;
  disabled?: boolean;
};

const Comment: FunctionComponent<PropType> = ({ comment, localStorageKey, disabled }) => {
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState<string | null>(comment.content);
  const [innerAttachments, setInnerAttachments] = useState<AttachmentType[]>(comment.attachments);
  const { put, del } = useApi();
  const {
    state: { user },
  } = React.useContext(AuthContext);
  const deleteComment = (id: number) => {
    void del(`new_comments/${id}`);
  };

  const editComment = () => {
    setEditMode(true);
  };

  const onSubmit = async () => {
    let formData = {
      content: value,
    };

    await put(`/api/new_comments/${comment.id}`, { ...formData }).then(() => {
      setValue('');
      setEditMode(false);
    });
  };

  useEffect(() => {
    if (!editMode) {
      setValue(comment.content);
    }
  }, [editMode]);

  useEffect(() => {
    setInnerAttachments(comment.attachments);
  }, [comment.attachments]);

  useEffect(() => {
    const id = window.location.search.split('?comment=')[1];
    if (id) {
      const element = document.getElementById(`comment=${id}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, []);

  return (
    <Styled.Comment id={`comment=${comment.id}`} $highlighted={window.location.href.includes(`comment=${comment.id}`)}>
      <Grid $align={'center'} $justifyContent={'space-between'} spacing={0.8}>
        <GridItem $desktop={8}>
          <Grid $align={'center'} spacing={0.4} $simple>
            <Person person={comment.createdBy} size={3.2} bold />
            <Text as={'small'} color={'grey'} size={'s'}>
              <DateString input={comment.createdAt} fromNow />
            </Text>
          </Grid>
        </GridItem>
        <GridItem $desktop={4}>
          {comment.createdBy.id === user?.employeeId && !disabled && (
            <Grid $align={'center'} $justifyContent={'end'} spacing={2}>
              <GridItem>
                <Icon size={1.2} color={color.neutral[60]} onClick={() => editComment()}>
                  <EditIcon />
                </Icon>
              </GridItem>
              <GridItem>
                <Icon size={1} onClick={() => deleteComment(comment.id)} color={color.neutral[60]}>
                  <DeleteIcon />
                </Icon>
              </GridItem>
            </Grid>
          )}
        </GridItem>
      </Grid>
      <Grid>
        <GridItem $desktop={12}>
          {editMode && <CommentEditor value={value} setValue={setValue} onSubmit={onSubmit} setEditMode={setEditMode} localStorageKey={localStorageKey} />}
          {!editMode && <Styled.Message dangerouslySetInnerHTML={{ __html: comment.content }} />}
          {innerAttachments && innerAttachments.length !== 0 && <Attachments attachments={innerAttachments} onDelete={setInnerAttachments} />}
        </GridItem>
      </Grid>
    </Styled.Comment>
  );
};
export default Comment;
