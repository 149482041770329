import React, { FunctionComponent } from 'react';

type PropType = {
  size?: number;
};

const SendIcon: FunctionComponent<PropType> = ({ size = 1 }) => {
  return (
    <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 13.3334V2.66669L14.6667 8.00002L2 13.3334ZM3.33333 11.3334L11.2333 8.00002L3.33333 4.66669V7.00002L7.33333 8.00002L3.33333 9.00002V11.3334Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SendIcon;
