import styled, { css } from 'styled-components';
import { color, lineHeight } from '../../../../styles/Variables';
import Text from '../../../atoms/Text';

type TaskProps = {
  $color: string | undefined;
  $isDone: boolean;
  $isTaskOverdue: boolean;
  $topPosition: number;
  $duration: number;
  $isShort: boolean;
  $isOverlapped?: boolean;
  $position?: 'left' | 'right';
  $isMeeting?: boolean;
};

export const Task = styled.div<TaskProps>`
  position: relative;
  // top: ${(p: TaskProps) => p.$topPosition / 10}rem;
  //left: 0;

  margin: 0.4rem;
  width: calc(100% - 0.8rem);
  background: ${(p: TaskProps) => (p.$isDone ? color.neutral[40] : p.$isTaskOverdue ? color.semantic.error[100] : p.$color ? p.$color : color.primary[60])};
  padding: 1.6rem;
  border-radius: 0.6rem;
  min-height: 6.2rem;
  cursor: pointer;
  overflow: hidden;
  ${(p: TaskProps) =>
    p.$isOverlapped &&
    css`
      width: calc(50% - 0.8rem);
    `};
  ${(p: TaskProps) =>
    p.$isOverlapped &&
    p.$isMeeting &&
    css`
      left: unset;
      right: 0;
    `};
  ${(p: TaskProps) =>
    p.$isShort &&
    css`
      padding: 0.8rem 1.6rem;
    `};
  ${(p: TaskProps) =>
    p.$isTaskOverdue &&
    css`
      p {
        color: ${color.neutral[40]};
      }

      .task-title {
        color: ${color.light};
      }
    `};
  ${(p: TaskProps) =>
    p.$isDone &&
    css`
      p {
        color: ${color.neutral[60]};
      }

      .task-title {
        color: ${color.neutral[60]};
      }
    `};
  ${(p: TaskProps) =>
    p.$isMeeting &&
    p.$isShort &&
    p.$isOverlapped &&
    css`
      padding-right: 0.6rem;
    `};
  ${(p: TaskProps) =>
    p.$position === 'right' &&
    css`
      right: 0;
      left: unset;
    `};
  ${(p: TaskProps) =>
    p.$position === 'left' &&
    css`
      left: 0;
      right: unset;
    `};
`;

export const Head = styled.div`
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
`;

export const Info = styled.div`
  display: flex;
  flex-flow: wrap;
  align-items: center;
  gap: 0.8rem;

  div {
    margin-left: 1rem;
  }
`;

type BodyProps = {
  $isShort: boolean;
};

export const Body = styled.div<BodyProps>`
  display: flex;
  flex-flow: column;
  margin-top: 1rem;
  ${(p: BodyProps) =>
    p.$isShort &&
    css`
      margin-top: 0.2rem;

      .task-title {
        height: 2.4rem;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .client-name {
        display: none;
      }
    `}
`;

export const BodyText = styled(Text)`
  line-height: ${lineHeight.body.s};
  margin-bottom: 0.5rem;
`;

export const AbsenceWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;
