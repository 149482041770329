import styled, { css } from 'styled-components';
import { color, fontSize, fontWeight } from '../../styles/Variables';

const Border = css`
  border-radius: 0.6rem;
  border: 1px solid ${color.primary[60]};
`;

export const AttachmentIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  background-color: ${color.neutral[30]};
  border-radius: 0.6rem;
  color: ${color.primary[60]};
  text-transform: uppercase;
  font-size: ${fontSize.body.xs};
  font-weight: ${fontWeight.extraBold};
  cursor: pointer;
`;

export const AttachmentImage = styled.img`
  ${Border};
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  object-fit: cover;
  transition: border 0.2s ease-in-out;
`;
