import React, { FunctionComponent, useCallback, useContext, useMemo, useState } from 'react';
import { PurchaseOrderSubject, PurchaseOrderType } from '../../../../@types/Finances/PurchaseOrder/PurchaseOrderType';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import { Card } from '../../../atoms/Card/Card';
import SingleDetail from '../../../molecules/SingleDetail/SingleDetail';
import RichText from '../../../atoms/RichText/RichText';
import PageTop from '../../../molecules/Page/PageTop';
import CardHeading from '../../../molecules/CardHeading/CardHeading';
import DateString from '../../../atoms/DateString/DateString';
import PaymentTermsDict from '../../../../@dicts/PurchaseOrder/PaymentTermsDict';
import { BillingTypeDict } from '../../../../@dicts/PurchaseOrder/BillingTypeDict';
import Heading from '../../../atoms/Heading';
import FinanceStatus from '../../../molecules/Statuses/FinanceStatus/FinanceStatus';
import HistoryCard from '../../../molecules/HistoryCard/HistoryCard';
import Button from '../../../atoms/Button/Button';
import EditIcon from '../../../../Icons/edit.icon';
import SubcostsListTable from '../../../organisms/Tables/SubcostsListTable/SubcostsListTable';
import Resources from '../../../organisms/Resources/Resources';
import Text from '../../../atoms/Text/Text';
import moneyAsString from '../../../../helpers/moneyAsString';
import ApprovalCard from '../../../organisms/RequestCard/ApprovalCard';
import useApi from '../../../../hooks/useApi';
import { CostKindDict } from '../../../../@dicts/Cost/CostKind';
import { useEntityContext } from '../../../../Context/EntityContext/EntityContext';
import Chip from '../../../atoms/Chips/Chip';
import { SidebarContext } from '../../../../Context/Sidebar/SidebarContext';
import ProjectSingleDetail from '../../../molecules/SingleDetail/ProjectSingleDetail/ProjectSingleDetail';
import useModal from '../../../../hooks/useModal';
import DeleteIcon from '../../../../Icons/delete.icon';
import { AuthContext } from '../../../../Context/auth-context/auth-context';
import { color } from '../../../../styles/Variables';
import ContractorSingleDetail from '../../../molecules/SingleDetail/ContractorSingleDetail/ContractorSingleDetail';
import CloseIcon from '../../../../Icons/CloseIcon';
import ReopenIcon from '../../../../Icons/Reopen.icon';
import { Colored } from '../../../../legacy/CapeMorris/components/layout/layout.styled';
import FinanceColumn from '../../../molecules/Finances/FinanceColumn';
import { Helmet } from 'react-helmet';

type Props = {
  purchaseOrder: PurchaseOrderType;
};

const PurchaseOrderShow: FunctionComponent<Props> = ({ purchaseOrder }) => {
  const ctx = useEntityContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { post, del } = useApi();
  const { can } = React.useContext(AuthContext);
  const { showModal, hideModal } = useModal();
  const [waiting, setWaiting] = useState(false);
  const { isOpen, closeSidebar } = useContext(SidebarContext);
  const [resourcesReloadKey, setResourcesReloadKey] = useState(Math.random());

  const generatePdf = () => {
    setWaiting(true);
    post('/api/resources/generate_document', {
      subject: 'Finances::PurchaseOrder',
      subjectId: purchaseOrder.id.toString(),
    }).then(() => {
      ctx.reload();
      setWaiting(false);
      setResourcesReloadKey(Math.random());
    });
  };

  const pageHeading = useMemo(() => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
        <Heading>
          {purchaseOrder.number ? <Colored>#{purchaseOrder.number}</Colored> : null} Purchase order "{purchaseOrder.title}"
        </Heading>
        <FinanceStatus status={purchaseOrder.status} />
        {purchaseOrder.closed && <Chip>Closed</Chip>}
      </div>
    );
  }, [purchaseOrder]);

  const deletePurchaseOrder = () => {
    closeSidebar();
    del(`${purchaseOrder['@id']}`).then(() => navigate('/finances/purchase-orders/'));
    hideModal();
  };

  const closePurchaseOrder = () => {
    return post<PurchaseOrderType>(`${purchaseOrder['@id']}/close`, {}).then((response) => {
      ctx.replace(response.data);
    });
  };
  const reopenPurchaseOrder = () => {
    return post<PurchaseOrderType>(`${purchaseOrder['@id']}/reopen`, {}).then((response) => {
      ctx.replace(response.data);
    });
  };

  const handleDelete = useCallback(() => {
    showModal({
      title: `Delete Purchase Order no. ${purchaseOrder.number ?? purchaseOrder.id}?`,
      body: <span>Are you sure you want to delete this Purchase Order?</span>,
      footer: (
        <>
          <Button $text={'Cancel'} type={'button'} kind={'ghost'} onClick={hideModal} />
          <Button $text={'Delete'} type={'button'} kind={'negative'} $icon={<DeleteIcon />} onClick={deletePurchaseOrder} />
        </>
      ),
    });
  }, []);

  const PageActions = () => {
    return (
      <Grid spacing={2} $align={'center'}>
        <GridItem $desktop={'auto'}>
          <HistoryCard
            subject={PurchaseOrderSubject}
            subjectId={purchaseOrder.id}
            itemCreatedBy={purchaseOrder.createdBy}
            itemCreatedAt={purchaseOrder.createdAt}
          />
        </GridItem>
        {purchaseOrder.closed ? (
          <GridItem $desktop={'auto'}>
            <Button $text={'Reopen'} kind={'ghost'} type={'submit'} $icon={<ReopenIcon />} onClick={() => reopenPurchaseOrder()} />
          </GridItem>
        ) : (
          <GridItem $desktop={'auto'}>
            <Button $text={'Close'} kind={'ghost'} type={'button'} $icon={<CloseIcon />} onClick={closePurchaseOrder} />
          </GridItem>
        )}

        {purchaseOrder.status !== 'accepted' && (
          <GridItem $desktop={'auto'}>
            <Button $text={'Edit'} kind={'primary'} type={'submit'} $icon={<EditIcon />} onClick={() => navigate(pathname.replace('show', 'edit'))} />
          </GridItem>
        )}
        {purchaseOrder.status === 'accepted' && (
          <GridItem $desktop={'auto'}>
            <Button $text={'Generate pdf'} kind={'primary'} waiting={waiting} onClick={() => generatePdf()} />
          </GridItem>
        )}
      </Grid>
    );
  };

  const handleApprovalRequest = useCallback(() => {
    return post('/purchase_order_requests', {
      purchaseOrder: purchaseOrder['@id'],
    }).then(() => {
      return ctx.reload();
    });
  }, []);

  return (
    <>
      <Grid spacing={4} style={{ marginBottom: '2rem' }}>
        <Helmet>
          <title>{`Purchase #${purchaseOrder.number ?? ''} | F.CAPE`}</title>
        </Helmet>
        <GridItem $desktop={12}>
          <PageTop heading={pageHeading} rightColumn={<PageActions />} paddingBottom={'0'} isPdfOpen={isOpen} showBackButton />
        </GridItem>

        <FinanceColumn>
          <GridItem $desktop={12}>
            <Card>
              <CardHeading heading={'Purchase order information'} />
              <Grid spacing={4}>
                <GridItem $desktop={12}></GridItem>
                <SingleDetail title={'Requester'} $desktop={6}>
                  {purchaseOrder.createdBy.name}
                </SingleDetail>
                <SingleDetail title={'Request date'} $desktop={6} type={'date'}>
                  <DateString input={purchaseOrder.createdAt} />
                </SingleDetail>
                <SingleDetail title={'Company name'} $desktop={6}>
                  {purchaseOrder.company.name}
                </SingleDetail>
                <SingleDetail title={'Location'} $desktop={6}>
                  {purchaseOrder.location.shortName}
                </SingleDetail>
                {purchaseOrder.project ? (
                  <ProjectSingleDetail name={purchaseOrder.project.name} id={purchaseOrder.project.id} $desktop={6} />
                ) : (
                  <Text>No Project</Text>
                )}
                <ContractorSingleDetail contractor={purchaseOrder.contractor} $desktop={6} />
                <SingleDetail title={'Vendor comment'} $desktop={12}>
                  {purchaseOrder.contractor.note}
                </SingleDetail>
                <GridItem $desktop={12} style={{ borderBottom: `1px solid ${color.neutral[40]}` }}></GridItem>
                <SingleDetail title={'Title'} $desktop={6}>
                  {purchaseOrder.title}
                </SingleDetail>
                <SingleDetail title={'Description'} $desktop={12}>
                  <RichText content={purchaseOrder.description} />
                </SingleDetail>
                <SingleDetail title={'Acceptance terms'} $desktop={12}>
                  <RichText content={purchaseOrder.acceptanceTerms} />
                </SingleDetail>
                <GridItem $desktop={12} style={{ borderBottom: `1px solid ${color.neutral[40]}` }}></GridItem>

                <SingleDetail title={'Amount'} $desktop={6} type={'money'}>
                  {moneyAsString(purchaseOrder.amountNet)}
                </SingleDetail>
                <SingleDetail title={'Original amount'} $desktop={6} type={'money'}>
                  {moneyAsString(purchaseOrder.originalAmountNet)}
                </SingleDetail>
                <SingleDetail title={'Billing type'} $desktop={6} dictionary={BillingTypeDict}>
                  {purchaseOrder.billingType}
                </SingleDetail>
                <SingleDetail title={'Delivery date'} $desktop={6} type={'date'}>
                  <DateString input={purchaseOrder.deliveryDate} />
                </SingleDetail>
                <SingleDetail title={'Cost kind'} $desktop={6} dictionary={CostKindDict}>
                  {purchaseOrder.kind}
                </SingleDetail>
                <SingleDetail title={'Cost type'} $desktop={6}>
                  {purchaseOrder.costType.label}
                </SingleDetail>
                <SingleDetail title={'Payment terms'} $desktop={6} dictionary={PaymentTermsDict}>
                  {purchaseOrder.paymentTerms}
                </SingleDetail>
                <SingleDetail title={'Budget symbol'} $desktop={6}>
                  {purchaseOrder.budgetSymbol}
                </SingleDetail>
                {purchaseOrder.specialPaymentTermsDetails && (
                  <SingleDetail title={'Special payment terms details'} $desktop={12}>
                    {purchaseOrder.specialPaymentTermsDetails}
                  </SingleDetail>
                )}
                {purchaseOrder.specialPaymentTermsReason && (
                  <SingleDetail title={'Special payment terms reason'} $desktop={12}>
                    {purchaseOrder.specialPaymentTermsReason}
                  </SingleDetail>
                )}
                <SingleDetail title={'Internal comment'} $desktop={12}>
                  <RichText content={purchaseOrder.internalComment ?? 'N/A'} />
                </SingleDetail>
              </Grid>
            </Card>
          </GridItem>
        </FinanceColumn>

        <FinanceColumn>
          <GridItem $desktop={12}>
            <Card>
              <CardHeading heading={'Uploaded documents'} />
              <Grid spacing={4}>
                <Resources
                  subject={PurchaseOrderSubject}
                  subjectId={purchaseOrder.id}
                  disableCreation
                  maxWidth
                  noResourcesText={'No documents'}
                  reloadToken={resourcesReloadKey}
                />
              </Grid>
            </Card>
          </GridItem>
          <GridItem $desktop={12}>
            <ApprovalCard requestable={purchaseOrder} onApprovalRequest={handleApprovalRequest} requests={purchaseOrder.requests ?? []} />
          </GridItem>
          <GridItem $desktop={12}>
            <Card>
              <CardHeading heading={'Costs summary'} />
              <Grid spacing={4}>
                <SingleDetail $desktop={4} title={'Ordered value'} type={'money'}>
                  <Text size={'xl'} bold>
                    {moneyAsString(purchaseOrder.originalAmountNet)}
                  </Text>
                </SingleDetail>
                <SingleDetail $desktop={4} title={'Invoiced value'} type={'money'}>
                  <Text size={'xl'} bold>
                    {moneyAsString(purchaseOrder.originalInvoicedNet)}
                  </Text>
                </SingleDetail>
                <SingleDetail $desktop={4} title={'Remain to invoice'} type={'money'}>
                  <Text size={'xl'} bold>
                    {moneyAsString(purchaseOrder.originalRemainNet)}
                  </Text>
                </SingleDetail>
              </Grid>
            </Card>
          </GridItem>
        </FinanceColumn>

        <GridItem $desktop={12}>
          <Card>
            <CardHeading heading={'Costs'} />
            <SubcostsListTable
              globalFilters={{
                'purchaseOrder.id': purchaseOrder.id,
              }}
            />
          </Card>
        </GridItem>
      </Grid>
      {can('FINANCES.CAN_DELETE') && (
        <Grid style={{ margin: '2rem 0 6rem' }}>
          <GridItem $desktop={'auto'}>
            <Button $text={'Delete'} kind={'link'} type={'button'} onClick={handleDelete} style={{ padding: 0, color: color.semantic.error[100] }} />
          </GridItem>
        </Grid>
      )}
    </>
  );
};

export default PurchaseOrderShow;
