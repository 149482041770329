import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import EntityContext, { EntityContextType } from './EntityContext';
import useApi from '../../hooks/useApi';
import api from '../../services/api';
import serverEvents from '../../services/serverEvents';
import { EntityType } from '../../@types/Entity/EntityType';

type Props<E> = {
  url?: string;
  children: (ReactNode | ReactNode[]) | ((ctx: EntityContextType<E>) => ReactNode | ReactNode[]);
};

const EntityProvider = <E extends EntityType = any>({ children, url }: Props<E>) => {
  const { put } = useApi();
  const [entity, setEntity] = useState<E>();
  const [loading, setLoading] = useState(true);

  const load = useCallback(() => {
    if (!url) return Promise.reject();
    return api.get<E>(url).then((r) => {
      setEntity(r.data);
      setLoading(false);
    });
  }, [url]);

  const save = useCallback(
    (data: E) => {
      if (!url) return;
      put(url, data).then((r) => {
        setEntity(r.data);
      });
    },
    [put],
  );

  const reload = useCallback(() => {
    return load();
  }, [load]);

  const replace = useCallback(
    (newEntity: E) => {
      if (entity?.['@id'] !== newEntity['@id']) return;
      setEntity(newEntity);
    },
    [entity],
  );

  const clone = useCallback((entity: E) => {
    // @ts-ignore
    delete entity.id;
    // @ts-ignore
    delete entity['@id'];
    setEntity(entity);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!url) return;
    const subscription = serverEvents.listen<E & EntityType>().subscribe((event) => {
      if (event['@id'] === url && !!event.id) {
        load();
      }
    });
    load();
    return () => {
      subscription.unsubscribe();
    };
  }, [url]);

  useEffect(() => {
    setLoading(true);
  }, [url]);

  return (
    <EntityContext.Provider
      value={{
        entity,
        save,
        clone,
        reload,
        loading,
        replace,
      }}
    >
      {typeof children === 'function'
        ? children({
            entity: entity as E,
            save,
            clone,
            reload,
            loading,
            replace,
          })
        : children}
    </EntityContext.Provider>
  );
};

export default EntityProvider;
