import { TableProperty } from '../../../../@types/Table/TableProperty';
import StringTemplate from '../../../molecules/CellTemplates/common/StringTemplate/StringTemplate';
import ContractorValueTaxTemplate from '../../../molecules/CellTemplates/Finances/ContractorValueTaxTemplate/ContractorValueTaxTemplate';
import DateTemplate from '../../../molecules/CellTemplates/common/DateTemplate/DateTemplate';
import MoneyTemplate from '../../../molecules/CellTemplates/common/MoneyTemplate/MoneyTemplate';
import PersonTemplate from '../../../molecules/CellTemplates/common/PersonTemplate/PersonTemplate';
import ProjectTemplate from '../../../molecules/CellTemplates/common/ProjectTemplate/ProjectTemplate';

export const properties: TableProperty[] = [
  {
    name: 'income.number',
    visibleName: 'Number',
    template: StringTemplate,
    sortable: 'income.sortableNumber',
  },
  {
    name: 'income.contractor',
    visibleName: 'Vendor',
    template: ContractorValueTaxTemplate,
    sortable: 'income.contractor.name',
  },
  {
    name: 'income.postedAt',
    visibleName: 'Service end',
    template: DateTemplate,
    sortable: 'income.postedAt',
  },
  {
    name: 'income.owner',
    visibleName: 'Owner',
    template: PersonTemplate,
    sortable: 'income.owner.name',
    templateOptions: {
      areInitials: true,
    },
  },
  {
    name: 'project',
    visibleName: 'Project',
    template: ProjectTemplate,
    sortable: 'project.id',
  },
  {
    name: 'amountNet',
    visibleName: 'Net amount',
    template: MoneyTemplate,
    sumAsMoney: true,
    sumBy: 'amountNet',
    sortable: 'amountNet.value',
  },
];
