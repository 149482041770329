import styled from 'styled-components';
import { color } from '../../../../styles/Variables';

export const DataPickerContainer = styled.div`
  .react-datepicker-wrapper {
    display: block;
  }

  .react-datepicker__close-icon {
    top: unset;
    bottom: 0;
    height: 3.8rem;
    display: flex;
    justify-content: end;
    align-items: center;

    &:hover {
      &:after {
        color: ${color.neutral[60]};
      }
    }

    &:after {
      color: ${color.neutral[50]};
      background-color: transparent;
      font-size: 2.2rem;
      display: flex;
      align-items: center;
    }
  }
`;
