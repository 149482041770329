import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { PaymentType } from '../../../@types/Finances/PaymentType';
import SinglePaymentCard from './SinglePaymentCard';
import { Card } from '../../atoms/Card/Card';
import CardHeading from '../../molecules/CardHeading/CardHeading';
import { Grid, GridItem } from '../../atoms/Grid/Grid';
import { MoneyType } from '../../../@types/Money/MoneyType';
import Text from '../../atoms/Text';
import PaymentsInput from '../PaymentsInput/PaymentsInput';
import Button from '../../atoms/Button/Button';
import moneyAsString from '../../../helpers/moneyAsString';
import CreatePayment from './SinglePaymentCard/CreatePayment';
import { CostType } from '../../../@types/Finances/Cost/CostType';
import { IncomeType } from '../../../@types/Finances/Income/IncomeType';

type Props = {
  payments: PaymentType[];
  amountGross: MoneyType;
  currency: string;
  subject: CostType | IncomeType;
};

const PaymentsCard: FunctionComponent<Props> = ({ payments, amountGross, currency, subject }) => {
  const [values, setValues] = useState<number[]>(payments.map((payment) => payment.value.value));

  const remain: MoneyType = useMemo(() => {
    let result: number = amountGross.value;

    for (let value of values) {
      result -= value;
    }
    return {
      value: result,
      currency: currency,
    };
  }, [amountGross, values, currency]);

  useEffect(() => {
    setValues(payments.map((payment) => payment.value.value));
  }, [payments]);

  const updateValue = useCallback((value: number, index: number) => {
    const newValues = [...values];
    newValues[index] = value;
    setValues(newValues);
  }, []);

  return (
    <Card>
      <CardHeading
        heading={'Payments'}
        rightElement={
          <Text size={'xl'} bold color={'darkGrey'}>
            Remain to pay: {moneyAsString(remain)}
          </Text>
        }
      />
      <Grid spacing={2}>
        {payments.map((payment, idx) => (
          <SinglePaymentCard onValueChange={(v) => updateValue(v, idx)} payment={payment} remain={remain} key={idx} idx={idx + 1} />
        ))}
      </Grid>
      <CreatePayment subject={subject} onValueChange={(v) => updateValue(v, payments.length)} currency={currency} remain={remain} />
    </Card>
  );
};

export default PaymentsCard;
