import React, { FunctionComponent } from 'react';

type Props = {
  size?: number;
};

const CommentIcon: FunctionComponent<Props> = ({ size = 1 }) => {
  return (
    <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M5 14H12V12.5H5V14ZM5 11.25H15V9.75H5V11.25ZM5 8.5H15V7H5V8.5ZM2 20V5.5C2 5.0875 2.14688 4.73438 2.44063 4.44063C2.73438 4.14688 3.0875 4 3.5 4H16.5C16.9125 4 17.2656 4.14688 17.5594 4.44063C17.8531 4.73438 18 5.0875 18 5.5V15.5C18 15.9125 17.8531 16.2656 17.5594 16.5594C17.2656 16.8531 16.9125 17 16.5 17H5L2 20ZM4.375 15.5H16.5V5.5H3.5V16.375L4.375 15.5Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default CommentIcon;
