import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import Form from '../../../../Form/Form';
import api from '../../../../../../services/api';
import useApi from '../../../../../../hooks/useApi';
import PrivateNoteInput from './PrivateNoteInput';
import InfoIcon from '../../../../../../Icons/info.icon';
import { Icon } from '../../../../../atoms/Icon/Icon';
import Text from '../../../../../atoms/Text';

type Props = {};

const PrivateNoteCardContentCard: FunctionComponent<Props> = () => {
  const [myPrivateNote, setMyPrivateNote] = useState<string | undefined | null>(undefined);
  const { post } = useApi();

  const getMyPrivateNote = useCallback(() => {
    api.get('/my-note').then((r) => {
      setMyPrivateNote(r.data.content ?? null);
    });
  }, []);

  const handleChange = useCallback((content: string) => {
    post('/my-note', { content }, true);
  }, []);

  useEffect(() => {
    getMyPrivateNote();
  }, [myPrivateNote]);

  return (
    <>
      {myPrivateNote !== undefined && (
        <Form defaultValues={{ content: myPrivateNote ?? undefined }} onSubmit={() => {}}>
          <div
            style={{
              display: 'flex',
              gap: '0.3rem',
              alignItems: 'center',
              marginBottom: '0.6rem',
            }}
          >
            <Icon>
              <InfoIcon />
            </Icon>
            <Text size={'s'} color={'grey'}>
              This note is encrypted and can be seen only by You. It saves automatically after typing.
            </Text>
          </div>
          <PrivateNoteInput onChange={handleChange} />
        </Form>
      )}
    </>
  );
};

export default PrivateNoteCardContentCard;
