import React, { ComponentType, FunctionComponent, useEffect, useRef } from 'react';
import { selectStyles } from './select.styles';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import AsyncCreatable from 'react-select/async-creatable';
import api from '../../../../services/api';
import { DictValue } from '../../../../@types/Dictionary/DictValue';
import { ArrayResponseType } from '../../../../@types/hydra/hydra';
import { color } from '../../../../styles/Variables';

interface OwnProps {
  isMulti: boolean;
  placeholder?: string;
  defaultValue: any;
  onChange: (value: any) => void;
  name: string;
  error?: string;
  optionComponent?: ComponentType<any>;
  singleValueComponent?: ComponentType<any>;
  baseUrl?: string;
  baseUrlParams?: { [key: string]: string | number | string[] };
  required?: boolean;
  optionLabel: string;
  disabled?: boolean;
  ref?: any;
  key?: string;
}

type Props = OwnProps;

export const SelectWithOptions: FunctionComponent<Props & { options: DictValue[] }> = (props) => {
  const [components, setComponents] = React.useState<any>({
    IndicatorSeparator: () => null,
  });

  React.useEffect(() => {
    if (props.optionComponent) {
      setComponents((old: {}) => ({ ...old, Option: props.optionComponent }));
    }
  }, [props.optionComponent]);

  const ref = useRef(null);

  useEffect(() => {
    if (props.error) {
      // @ts-ignore
      ref.current.focus();
    }
  }, [props.error]);

  return (
    <Select
      ref={ref}
      isDisabled={props.disabled}
      options={props.options}
      isMulti={props.isMulti}
      name={props.name}
      placeholder={props.placeholder}
      noOptionsMessage={() => 'Start typing to search...'}
      loadingMessage={() => 'Searching...'}
      defaultValue={props.defaultValue}
      onChange={props.onChange}
      components={components}
      isClearable={!props.required}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: color.primary[60],
          primary75: color.primary[50],
          primary50: color.primary[20],
          primary25: color.primary[20],
        },
      })}
      styles={selectStyles(!!props.error, props.required, props.disabled)}
    />
  );
};

export const SelectWithBaseUrl: FunctionComponent<Props> = (props) => {
  const [components, setComponents] = React.useState<{}>({
    IndicatorSeparator: () => null,
  });

  React.useEffect(() => {
    if (props.optionComponent) {
      setComponents((old: {}) => ({ ...old, Option: props.optionComponent }));
    }
    if (props.singleValueComponent) {
      setComponents((old: {}) => ({ ...old, SingleValue: props.singleValueComponent }));
    }
  }, [props.optionComponent, props.singleValueComponent]);

  const loadOptions = (inputValue: string, callback: Function) => {
    if (!props.baseUrl) {
      callback([]);
      return;
    }

    const params: { [key: string]: string | number | string[] } = props.baseUrlParams ? props.baseUrlParams : {};
    params['_search'] = inputValue;

    api.get<ArrayResponseType>(props.baseUrl, { params }).then((response) => {
      callback(
        response.data['hydra:member'].map((i) => {
          return { value: i['@id'], label: i[props.optionLabel], meta: i };
        }),
      );
    });
  };

  const ref = useRef(null);

  useEffect(() => {
    if (props.error) {
      // @ts-ignore
      ref.current.focus();
    }
  }, [props.error]);

  return (
    <AsyncSelect
      isDisabled={props.disabled}
      id={props.name}
      isMulti={props.isMulti}
      menuPortalTarget={document.getElementById('data-picker-portal')}
      ref={ref}
      name={props.name}
      placeholder={props.placeholder}
      noOptionsMessage={() => 'Start typing to search...'}
      loadingMessage={() => 'Searching...'}
      loadOptions={loadOptions}
      defaultValue={props.defaultValue}
      onChange={props.onChange}
      components={components}
      defaultOptions
      isClearable={!props.required}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: color.primary[60],
          primary75: color.primary[50],
          primary50: color.primary[20],
          primary25: color.primary[20],
        },
      })}
      styles={selectStyles(!!props.error, props.required, props.disabled)}
    />
  );
};

export const CreatableSelect: FunctionComponent<Props> = (props) => {
  const [components, setComponents] = React.useState<{}>({
    IndicatorSeparator: () => null,
  });

  React.useEffect(() => {
    if (props.optionComponent) {
      setComponents((old: {}) => ({ ...old, Option: props.optionComponent }));
    }
  }, [props.optionComponent]);

  const loadOptions = (inputValue: string, callback: Function) => {
    if (!props.baseUrl) {
      callback([]);
      return;
    }
    api.get<ArrayResponseType>(props.baseUrl, { params: { _search: inputValue } }).then((response) => {
      callback(
        response.data['hydra:member'].map((i) => {
          return { value: i['@id'], label: i[props.optionLabel], meta: i };
        }),
      );
    });
  };

  const ref = useRef(null);

  useEffect(() => {
    if (props.error) {
      // @ts-ignore
      ref.current.focus();
    }
  }, [props.error]);

  return (
    <AsyncCreatable
      ref={ref}
      isMulti={props.isMulti}
      name={props.name}
      placeholder={props.placeholder}
      noOptionsMessage={() => 'Start typing to search...'}
      loadingMessage={() => 'Searching...'}
      loadOptions={loadOptions}
      defaultValue={props.defaultValue}
      onChange={props.onChange}
      components={components}
      isDisabled={props.disabled}
      defaultOptions
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: color.primary[60],
          primary75: color.primary[50],
          primary50: color.primary[20],
          primary25: color.primary[20],
        },
      })}
      styles={selectStyles(!!props.error, props.required, props.disabled)}
    />
  );
};
