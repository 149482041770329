import styled, { css } from 'styled-components';
import { color } from 'styles/Variables';

type Props = {
  $photoUrl?: string;
  $size?: number;
  $border?: boolean;
};

export const TooltipWrapper = styled.div`
  display: flex;
  width: max-content;
`;

export const Avatar = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  font-size: ${(p) => (p.$size ?? 1) * 0.4}rem;
  color: ${color.light};
  background-color: ${color.neutral[40]};

  ${(props: Props) =>
    props.$size &&
    css`
      width: ${props.$size}rem;
      height: ${props.$size}rem;
    `}

  ${(props: Props) =>
    props.$border &&
    css`
      border: 2px solid ${color.light};
    `}

  ${(props: Props) =>
    !props.$photoUrl &&
    css`
      background-color: ${color.primary[60]};
    `}

  ${(props: Props) =>
    props.$photoUrl &&
    css`
      background: url(${() => props.$photoUrl});
      background-size: cover;
      background-position: center;
    `}
`;
