import FrequentFilters from '../../../../@types/Table/FrequentFilters';
import { DictValue } from '../../../../@types/Dictionary/DictValue';
import { TableFiltersType } from '../../../../@types/Table/TableFilterType';
import { FinanceStatusType } from '../../../../@types/Finances/FinanceStatusType';
import { DocumentTypeDict } from '../../../../@dicts/Cost/DocumentTypeDict';
import PurchaseOrderOption from '../../../molecules/Finances/PurchaseOrderOption/PurchaseOrderOption';
import PurchaseOrderLabel from '../../../molecules/Finances/PurchaseOrderLabel';
import ContractorOption from '../../../molecules/Finances/ContractorOption';
import { CostKindDict } from '../../../../@dicts/Cost/CostKind';
import { CurrencyTypeDict } from '../../../../@dicts/CurrencyTypeDict';
import DateSelectFilter from '../../../molecules/TableFilters/DateSelectFilter/DateSelectFilter';

const statusOptions: DictValue<null, FinanceStatusType>[] = [
  { value: 'automated_draft', label: 'Automated draft' },
  { value: 'draft', label: 'Draft' },
  { value: 'pending', label: 'Pending' },
  { value: 'accepted', label: 'Accepted' },
  { value: 'rejected', label: 'Rejected' },
];
export const filters: TableFiltersType = [
  {
    ...FrequentFilters._search,
    placeholder: 'Search by id, document number, vendor',
    gridProps: {
      $desktop: 3,
    },
  },
  {
    type: 'select',
    name: 'contractor.id',
    label: 'Vendor',
    options: {
      baseUrl: '/api/contractors',
      optionComponent: ContractorOption,
    },
    gridProps: {
      $desktop: 3,
    },
  },
  {
    type: 'select',
    name: 'documentType',
    label: 'Type',
    options: {
      options: DocumentTypeDict,
    },
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'text',
    name: 'number',
    label: 'Number',
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'select',
    name: 'status',
    label: 'Status',
    options: {
      options: statusOptions,
    },
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'select',
    name: 'financialCompany.id',
    label: 'Company',
    placeholder: 'Select Company',
    options: {
      baseUrl: '/api/financial_companies',
    },
    gridProps: {
      $desktop: 3,
    },
  },
  {
    ...FrequentFilters.project,
    name: 'subcosts.project.id',
    gridProps: {
      $desktop: 3,
    },
  },

  {
    type: 'select',
    name: 'subcosts.purchaseOrder.id',
    label: 'Subcost purchase order',
    placeholder: 'Select PO',
    options: {
      baseUrl: '/api/purchase_orders',
      optionComponent: PurchaseOrderOption,
      singleValueComponent: PurchaseOrderLabel,
    },
    gridProps: {
      $desktop: 3,
    },
  },
  {
    type: 'select',
    name: 'subcosts.employee.id',
    label: 'Subcost employee',
    placeholder: 'Select employee',
    options: {
      baseUrl: '/api/employees',
      optionLabel: 'name',
    },
    gridProps: {
      $desktop: 3,
    },
  },
  {
    type: 'text',
    name: 'subcosts.budgetSymbol',
    label: 'Subcost budget symbol',
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'select',
    name: 'subcosts.kind',
    label: 'Cost kind',
    gridProps: {
      $desktop: 2,
    },
    options: {
      options: CostKindDict,
    },
  },
  {
    type: 'select',
    name: 'subcosts.costType.id',
    label: 'Cost type',
    gridProps: {
      $desktop: 2,
    },
    options: {
      baseUrl: '/api/cost_types',
      optionLabel: 'label',
    },
  },
  {
    type: 'month',
    name: 'issuedAt',
    label: 'Issued at',
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'custom',
    name: 'postedAt',
    label: 'Posting date',
    options: {
      component: DateSelectFilter,
    },
    gridProps: {
      $desktop: 4,
    },
  },
  {
    type: 'date',
    name: 'paymentDue[before]',
    label: 'Payment date (before)',
    placeholder: 'Payment date',
    options: {
      before: true,
    },
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'money',
    name: 'liabilitiesPln.value[gte]',
    label: 'Liabilities from',
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'money',
    name: 'liabilitiesPln.value[lte]',
    label: 'Liabilities to',
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'money',
    name: 'amountGross.value[gte]',
    label: 'Amount gross from',
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'money',
    name: 'amountGross.value[lte]',
    label: 'Amount gross to',
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'select',
    name: 'originalAmountGross.currency',
    label: 'Original currency',
    options: {
      options: CurrencyTypeDict,
    },
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'select',
    name: 'confidential',
    label: 'Confidential',
    options: {
      options: [
        { value: 0, label: 'No' },
        { value: 1, label: 'Yes' },
      ],
    },
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'switch',
    name: 'exportEnabled',
    label: 'Bank Export enabled',
  },
];
