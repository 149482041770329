import { useFormContext } from 'react-hook-form';

function useFormError(name: string) {
  const {
    formState: { errors },
  } = useFormContext();
  const parts = name.split('.');

  parts.forEach((part, index) => {
    if (part.endsWith(']')) {
      part = part.substr(0, part.length - 1);
      const subparts = part.split('[');
      parts.splice(index, 1, ...subparts);
    }
  });
  const error = parts.reduce((object: any, currentName) => {
    if (typeof object === 'string' || object === undefined) {
      return object;
    }
    if (object.hasOwnProperty(currentName)) {
      return object[currentName];
    }
    return undefined;
  }, errors);

  if (error && error.hasOwnProperty('message')) {
    return error['message'];
  }
  return undefined;
}

export default useFormError;
