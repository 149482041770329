import React, { createElement, FunctionComponent, useCallback, useMemo } from 'react';
import { TableFilterType } from '../../../../@types/Table/TableFilterType';
import { GridItem } from '../../../atoms/Grid/Grid';
import TextFilter from '../../../molecules/TableFilters/TextFilter/TextFilter';
import MonthFilter from '../../../molecules/TableFilters/MonthFilter/MonthFilter';
import EmployeeFilter from '../../../molecules/TableFilters/EmployeeFilter/EmployeeFilter';
import DepartmentFilter from '../../../molecules/TableFilters/DepartmentFilter/DepartmentFilter';
import ExistsFilter from '../../../molecules/TableFilters/ExistsFilter/ExistsFilter';
import SwitchFilter from '../../../molecules/TableFilters/SwitchFilter/SwitchFilter';
import SelectFilter from '../../../molecules/TableFilters/SelectFilter/SelectFilter';
import DateFilter from '../../../molecules/TableFilters/DateFilter/DateFilter';
import YearFilter from '../../../molecules/TableFilters/YearFilter/YearFilter';
import TagsFilter from '../../../molecules/TableFilters/TagsFilter';
import useUser from '../../../../hooks/useUser';
import { useLocation } from 'react-router-dom';
import SwitchIncludeFilter from '../../../molecules/TableFilters/SwitchIncludeFilter/SwitchIncludeFilter';
import DateRangeFilter from '../../../molecules/TableFilters/DateRangeFilter/DateRangeFilter';
import MoneyFilter from '../../../molecules/TableFilters/MoneyFilter/MoneyFilter';

type Props = {
  filter: TableFilterType;
  onChange: () => void;
};

const TableFilter: FunctionComponent<Props> = ({ filter, onChange }) => {
  const Component = useMemo(() => {
    switch (filter.type) {
      case 'text':
        return TextFilter;
      case 'month':
        return MonthFilter;
      case 'employee':
        return EmployeeFilter;
      case 'department':
        return DepartmentFilter;
      case 'exists':
        return ExistsFilter;
      case 'select':
        return SelectFilter;
      case 'multiselect':
        return SelectFilter;
      case 'switch':
        return SwitchFilter;
      case 'switch_include':
        return SwitchIncludeFilter;
      case 'custom':
        return filter.options.component;
      case 'date':
        return DateFilter;
      case 'daterange':
        return DateRangeFilter;
      case 'year':
        return YearFilter;
      case 'tags':
        return TagsFilter;
      case 'money':
        return MoneyFilter;
      default:
        return 'test';
    }
  }, [filter]);

  const user = useUser();
  const location = useLocation();

  const sendDataLayerInfo = useCallback(() => {
    if (!location || !window || !document) return;
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      event: 'use_filter',
      filterName: filter.name,
      filterType: filter.type,
      pagePathname: location.pathname,
      pageTitle: document.title,
      userDepartment: user?.department?.name,
    });
  }, [window, document, location, filter]);

  const handleOnChange = () => {
    onChange();
    if (filter.type !== 'text') sendDataLayerInfo();
  };

  return (
    <GridItem {...filter.gridProps}>
      {createElement(Component, {
        filter,
        name: `filters.${filter.name.split('.').join('__').split('[').join('<').split(']').join('>')}`,
        onChange: handleOnChange,
        defaultValue: '',
        options: filter.options,
      })}
    </GridItem>
  );
};

export default TableFilter;
