import React, { FunctionComponent } from 'react';
import { SubincomeType } from '../../../../../@types/Finances/Income/SubincomeType';
import { Card } from '../../../../atoms/Card/Card';
import CardHeading from '../../../../molecules/CardHeading/CardHeading';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import DynamicSelectInput from '../../../../molecules/Form/SelectInput/DynamicSelectInput';
import MoneyInput from '../../../../molecules/Form/MoneyInput/MoneyInput';
import TrashIcon from '../../../../../Icons/Trash.icon';
import Button from '../../../../atoms/Button/Button';
import ProjectOption from '../../../../molecules/Projects/ProjectOption/ProjectOption';
import ProjectLabel from '../../../../molecules/Projects/ProjectLabel/ProjectLabel';
import IriHelper from '../../../../../helpers/iri-helper';
import { ClientType } from '../../../../../@types/Project/Client/ClientType';

type Props = {
  name: string;
  subincome: SubincomeType;
  index: number;
  onRemove: () => void;
  currency: string;
  client: ClientType | undefined;
};

const SubincomeInput: FunctionComponent<Props> = ({ name, subincome, ...props }) => {
  return (
    <Card>
      <CardHeading
        heading={`Assignment ${props.index + 1}`}
        action={<Button $text={'Delete'} kind={'ghost'} $icon={<TrashIcon />} onClick={props.onRemove} />}
      />
      <Grid spacing={2} $align={'center'}>
        <GridItem $desktop={6}>
          <DynamicSelectInput
            defaultValue={subincome.project}
            optionComponent={ProjectOption}
            singleValueComponent={ProjectLabel}
            name={`${name}.project`}
            baseUrl={'/api/projects'}
            label={'Project'}
            key={props.client?.id}
            baseUrlParams={{
              status: ['active', 'completed'],
              archived: 0,
              'client.id': props.client?.id,
            }}
          />
        </GridItem>
        <GridItem $desktop={6}>
          <MoneyInput defaultMoney={subincome.originalAmountNet} name={`${name}.originalAmountNet`} currency={props.currency} label={'Amount'} />
        </GridItem>
      </Grid>
    </Card>
  );
};

export default SubincomeInput;
