import React, { FunctionComponent, useEffect } from 'react';
import useLocationQuery from '../../../hooks/useLocationQuery';
import useTaskSidebar from '../../../hooks/useTaskSidebar';

type Props = {};

const TaskSidebarQueryHandler: FunctionComponent<Props> = (props) => {
  const taskId = useLocationQuery('task');
  const { openTask } = useTaskSidebar();

  useEffect(() => {
    if (taskId) openTask(`/api/tasks/${taskId}`);
  }, [taskId]);

  return <></>;
};

export default TaskSidebarQueryHandler;
