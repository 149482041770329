import styled from 'styled-components';

export const Container = styled.div`
  min-width: 300px;

  @media (max-width: 1200px) {
    min-width: 200px;
  }

  @media (max-width: 1000px) {
    min-width: 100px;
  }
`;
