import React, { FunctionComponent, useContext, useMemo } from 'react';
import EmployeeBasicDataForm from './EmployeeForm/EmployeeBasicDataForm';
import EmployeePositionForm from './EmployeeForm/EmployeePositionForm';
import EmployeeDetailsForm from './EmployeeForm/EmployeeDetailsForm';
import FormPage from '../../../../../components/organisms/Form/FormPage/FormPage';
import { Grid } from '../../../../../components/atoms/Grid/Grid';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../../../../Context/auth-context/auth-context';
import EmployeeRolesForm from './EmployeeForm/EmployeeRolesForm';

type Props = {};

const defaultValues = {
  fullTimeEquivalent: 1,
};

const EmployeeForm: FunctionComponent<Props> = ({ ...props }) => {
  let { id } = useParams<{ id: string | undefined }>();
  const { can } = useContext(AuthContext);
  const basePath = useMemo(() => {
    let base = '/api/employees';
    if (id) {
      return `${base}/${id}`;
    }
    return base;
  }, [id]);

  return (
    <FormPage basePath={basePath} editTitle={'Edit employee'} createTitle={'Create new employee'} defaults={defaultValues}>
      {() => {
        return (
          <Grid spacing={4}>
            <EmployeeBasicDataForm />
            <EmployeePositionForm />
            <EmployeeDetailsForm />
            {can('EMPLOYEE.CAN_MANAGE.EXTENDED') && <EmployeeRolesForm />}
          </Grid>
        );
      }}
    </FormPage>
  );
};

export default EmployeeForm;
