import React, { FunctionComponent, ReactNode } from 'react';
import { FinancialCompanyContext } from './FinancialCompanyContext';
import { financialCompanyReducer } from './FinancialCompanyReducer';
import { FinancialCompanyType } from '../../@types/FinancialCompany/FinancialCompanyType';

type Props = {
  children: ReactNode;
};

const FinancialCompanyProvider: FunctionComponent<Props> = (props) => {
  const [state, dispatch] = React.useReducer(financialCompanyReducer, {
    financialCompany: null,
  });
  const setFinancialCompany = React.useCallback((financialCompany: FinancialCompanyType) => {
    dispatch({ type: 'SAVE_COMPANY', financialCompany: financialCompany });
  }, []);

  return <FinancialCompanyContext.Provider value={{ setFinancialCompany: setFinancialCompany, state }}>{props.children}</FinancialCompanyContext.Provider>;
};

export default FinancialCompanyProvider;
