import React, { FunctionComponent } from 'react';
import { ProjectType } from '../../../../../@types/Project/Project';
import MyProjectsCardItem from '../DashboardCardItems/MyProjectsCardItem';
import * as Styled from './ContentCard.styled';
import AddNewProjectButton from '../DashboardCardItems/AddNewProjectButton/AddNewProjectButton';
import { Loader } from '../../../../../legacy/CapeMorris/components';

type Props = {
  projects: ProjectType[] | undefined;
};

const MyProjectsCardContentCard: FunctionComponent<Props> = ({ projects }) => {
  return (
    <Styled.ItemsColumnWrapper $isProjectsList={true}>
      <AddNewProjectButton />
      {!projects && <Loader />}
      {projects &&
        projects.map((project, index) => (
          <React.Fragment key={index}>
            <MyProjectsCardItem project={project} key={index} />
          </React.Fragment>
        ))}
    </Styled.ItemsColumnWrapper>
  );
};

export default MyProjectsCardContentCard;
