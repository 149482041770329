import React, { FunctionComponent } from 'react';
import { AbsenceType } from '../../../../../@types/Absences/AbsenceType';
import Button from '../../../../atoms/Button/Button';
import AbsenceRequestOverlayBody from '../../../../templates/Absences/components/RequestOverlayBody/AbsenceRequestOverlayBody';
import useOverlay from '../../../../../hooks/useOverlay';

type Props = {
  absence: AbsenceType;
};

const EditButton: FunctionComponent<Props> = ({ absence }) => {
  const { showOverlay } = useOverlay();

  const editAbsence = () => {
    showOverlay({ body: <AbsenceRequestOverlayBody absence={absence} /> });
  };

  return <Button $text={'Edit'} kind={'secondary'} onClick={editAbsence} />;
};

export default EditButton;
