import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { EmployeeType } from '../../../../../@types/Employee/EmployeeType';
import useEntity from '../../../../../hooks/useEntity';
import EmployeeShow from '../../../../../components/templates/Employees/EmployeeShow/EmployeeShow';
import { Loader } from '../../../../../legacy/CapeMorris/components';

type Props = {};

const EmployeeShowView: FunctionComponent<Props> = () => {
  let { id } = useParams<{ id: string }>();
  const { entity, loading, reload } = useEntity<EmployeeType>(`/api/employees/${id}`);

  return (
    <>
      {loading && <Loader />}
      {entity && <EmployeeShow employee={entity} onChange={reload} />}
    </>
  );
};

export default EmployeeShowView;
