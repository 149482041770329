import styled, { css } from 'styled-components';

export type RoleSize = {
  $small?: boolean;
};
export const AddRole = styled.div<RoleSize>`
  padding: 1rem;

  ${(props: RoleSize) =>
    props.$small &&
    css`
      padding: 0;
      flex-basis: 10%;
    `}
`;
