import styled, { css } from 'styled-components';

export type GridSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'auto' | 'max';

export type GridProps = {
  spacing?: number;
  $align?: 'baseline' | 'start' | 'end' | 'center';
  $justifyContent?: 'space-between' | 'start' | 'end' | 'center' | 'flex-end' | 'flex-start';
  $simple?: boolean;
};

const calculateSpacing = ({ spacing = 0 }: GridProps, multiplayer = 1): string => {
  return `${spacing * 8 * multiplayer}px`;
};

export const Grid = styled.div<GridProps>`
  display: flex;
  margin-top: -${(p) => calculateSpacing(p)};
  margin-left: -${(p) => calculateSpacing(p)};
  flex-wrap: wrap;
  ${(props) =>
    !props.$simple &&
    css`
      & > * {
        padding-left: ${(p) => calculateSpacing(p as GridProps)};
        padding-top: ${(p) => calculateSpacing(p as GridProps)};
      }
    `}
  ${(props) =>
    props.$simple &&
    css`
      & > * {
        margin-left: ${(p) => calculateSpacing(p as GridProps)};
        margin-top: ${(p) => calculateSpacing(p as GridProps)};
      }
    `}
  width: calc(100% + ${(p) => calculateSpacing(p)});
  justify-content: ${(props) => props.$justifyContent ?? 'initial'};
  align-items: ${(props) => props.$align ?? 'initial'};
`;

export type GridItemProps = {
  mobile?: GridSize;
  tablet?: GridSize;
  laptop?: GridSize;
  $desktop?: GridSize;
  relative?: boolean;
};

export const GridItem = styled.div<GridItemProps>`
  max-width: ${(props: GridItemProps) =>
    !props.$desktop || props.$desktop === 'auto' || props.$desktop === 'max' ? 'none' : `${(props.$desktop / 12) * 100}%`};
  flex-basis: ${(props: GridItemProps) =>
    !props.$desktop || props.$desktop === 'auto' || props.$desktop === 'max' ? 'auto' : `${(props.$desktop / 12) * 100}%`};
  flex-grow: ${(props: GridItemProps) => (props.$desktop === 'max' ? 12 : 0)};
  box-sizing: border-box;
  //todo: RWD
  ${(props) =>
    props.relative &&
    css`
      position: relative;
    `}
`;
