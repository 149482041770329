import styled from 'styled-components';
import { color } from '../../../../../styles/Variables';

export const Column = styled.div`
  max-height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &:nth-child(2n) {
    background: ${color.neutral[30]};
  }
`;

export type CellProps = {
  $background?: string;
};

export const Cell = styled.div<CellProps>`
  margin: 0.4rem;
  width: calc(100% - 0.8rem);
  padding: 1rem 1.6rem;
  border-radius: 0.6rem;
  min-height: 6.2rem;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  background: ${(p: CellProps) => p.$background};
`;
