import React, { FunctionComponent, ReactNode, useCallback, useEffect } from 'react';
import useEntity from '../../../../hooks/useEntity';
import Form from '../../../molecules/Form/Form';
import { UseFormReturn } from 'react-hook-form';
import PageTop from '../../../molecules/Page/PageTop';
import { PageSection } from '../../../molecules/Page/PageSection';
import { useNavigate } from 'react-router-dom';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import { EntityType } from '../../../../@types/Entity/EntityType';
import Button from '../../../atoms/Button/Button';
import { Loader } from '../../../../legacy/CapeMorris/components';

type Props = {
  basePath: string;
  children: ((methods: UseFormReturn<any>, isEdit: boolean, entity?: EntityType | null) => ReactNode) | ReactNode;
  createTitle?: string;
  editTitle?: string;
  onCreate?: (entity: any) => void;
  onEdit?: (entity: any) => void;
  onEntityLoad?: (entity: any) => void;
  defaults?: any;
};

const FormPage: FunctionComponent<Props> = ({ basePath, children, createTitle, editTitle, onCreate, onEdit, defaults, ...props }) => {
  const { entity, loading, create, save, waiting } = useEntity(basePath);
  const navigate = useNavigate();
  const handleSubmit = useCallback(
    (data: any) => {
      if (!entity) {
        void create(data).then((response) => {
          if (onCreate) {
            onCreate(response);
          } else {
            navigate(-1);
          }
        });
      } else {
        void save(data).then((response) => {
          if (onEdit) {
            onEdit(response);
          } else {
            navigate(-1);
          }
        });
      }
    },
    [entity, create, save],
  );

  useEffect(() => {
    if (entity && props.onEntityLoad) {
      props.onEntityLoad(entity);
    }
  }, [entity, props.onEntityLoad]);

  return (
    <>
      {!loading && (
        <>
          <Form defaultValues={entity ?? defaults} onSubmit={handleSubmit}>
            {(m) => (
              <>
                <PageTop
                  heading={entity ? (editTitle ?? 'Create ITEM TBD') : (createTitle ?? 'Create ITEM TBD')}
                  rightColumn={
                    <Grid spacing={2}>
                      <GridItem>
                        <Button
                          $text={'Cancel'}
                          onClick={() => {
                            navigate(-1);
                          }}
                          kind={'link'}
                        />
                      </GridItem>
                      <GridItem>
                        <Button $text={'Save'} type={'submit'} waiting={loading} />
                      </GridItem>
                    </Grid>
                  }
                />
                <PageSection spacing={4}>{typeof children === 'function' ? children(m, !!entity, entity) : children}</PageSection>
              </>
            )}
          </Form>
          {waiting && <Loader />}
        </>
      )}
    </>
  );
};

export default FormPage;
