import React, { FunctionComponent } from 'react';

type PropType = {
  size?: number;
};

const ShiftIcon: FunctionComponent<PropType> = ({ size = 1 }) => {
  return (
    <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0096 17.6442L10.9404 16.6L14.7904 12.75H2.25V11.25H14.7808L10.9654 7.4L12.0096 6.3558L17.6538 12L12.0096 17.6442ZM20.25 17.75V6.25002H21.75V17.75H20.25Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ShiftIcon;
