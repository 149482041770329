import React, { FunctionComponent, useContext, useEffect } from 'react';
import { SidebarContext } from '../../Context/Sidebar/SidebarContext';
import { Icon } from '../../components/atoms/Icon/Icon';
import CloseIcon from '../../Icons/CloseIcon';
import { color } from '../../styles/Variables';

type Props = {
  pdfFile: string;
};

const ShowPdfSidebar: FunctionComponent<Props> = ({ pdfFile }) => {
  const { closeSidebar, setPdfContext } = useContext(SidebarContext);

  useEffect(() => {
    if (setPdfContext) {
      setPdfContext(true);
    }
  }, []);

  return (
    <div
      style={{
        width: '100%',
        alignItems: 'end',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: color.neutral['40'],
      }}
    >
      <Icon onClick={closeSidebar} size={2.4}>
        <CloseIcon />
      </Icon>
      <iframe src={pdfFile} style={{ width: '100%', height: '100%' }} frameBorder="0" />
    </div>
  );
};

export default ShowPdfSidebar;
