import styled, { css, keyframes } from 'styled-components';
import { color } from 'styles/Variables';

const animation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

type WrapperProps = {
  $width?: number;
};
export const ModalBackground = css`
  animation: 1 0.2s ${animation};
  z-index: 1001;
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${color.neutral[80]}bb;
  backdrop-filter: blur(2px);
  cursor: pointer;
`;

export const Background = styled.div`
  ${ModalBackground};
`;

export const TitleWrapper = styled.div``;

export const Wrapper = styled.div<WrapperProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 90vh;
  width: ${(props) => (props.$width ? `${props.$width}rem` : '60vw')};
  display: flex;
  flex-direction: column;
  background-color: ${color.light};
  border-radius: 1rem;
  cursor: default;
  padding: 3rem;
  row-gap: 2rem;
  overflow-y: auto;
`;

export const OverflowWrapper = styled.div`
  max-height: 80vh;
  //min-height: 20vh;
  overflow-y: auto;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
`;
