import React, { FunctionComponent } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import EmployeesSubmodule from './submodules/Employees/EmployeesSubmodule';
import AdminInventory from './submodules/Inventory/AdminInventory';
import { AuthContext } from '../../Context/auth-context/auth-context';

const AdminModule: FunctionComponent = () => {
  const { can } = React.useContext(AuthContext);

  return (
    <Routes>
      <Route path="employees/*" element={<>{can('EMPLOYEE') ? <EmployeesSubmodule /> : <Navigate to={`inventory`} />}</>}></Route>
      <Route path="inventory/*" element={<AdminInventory />}></Route>
    </Routes>
  );
};

export default AdminModule;
