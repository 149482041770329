import React, { FunctionComponent } from 'react';
import { Grid } from '../../../../../atoms/Grid/Grid';
import SingleDetail from '../../../../../molecules/SingleDetail/SingleDetail';
import moneyAsString from '../../../../../../helpers/moneyAsString';
import { ProjectType } from '../../../../../../@types/Project/Project';

type Props = {
  project: ProjectType;
};

const ProjectFinancialData: FunctionComponent<Props> = ({ project }) => {
  return (
    <>
      <Grid spacing={2}>
        <SingleDetail $desktop={2} title={'Incomes'}>
          {moneyAsString(project.incomesTotal)}
        </SingleDetail>
        <SingleDetail $desktop={2} title={'Costs'}>
          {moneyAsString(project.costsTotal)}
        </SingleDetail>
        <SingleDetail $desktop={2} title={'Revenue'}>
          {moneyAsString(project.revenue)}
        </SingleDetail>
        <SingleDetail $desktop={2} title={'Team costs'}>
          {moneyAsString(project.workCostsTotal)}
        </SingleDetail>
        <SingleDetail $desktop={2} title={'Profit'}>
          {moneyAsString(project.profit)}
        </SingleDetail>
        <SingleDetail $desktop={2} title={'%'}>
          {((project.margin ?? 0) * 100).toFixed(0)}%
        </SingleDetail>
      </Grid>
    </>
  );
};

export default ProjectFinancialData;
