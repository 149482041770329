import { useEffect, RefObject, useCallback } from 'react';
import useModal from './useModal';

const useOutsideClick = (ref: RefObject<any>, callback: () => void) => {
  const { modal } = useModal();

  const handleOutsideClick = useCallback(
    (event: any) => {
      try {
        if (!event.target || !event.target.className || typeof event.target.className.includes !== 'function') return;
        if (event.target?.className?.includes('ck-button')) return;
        if (ref.current && !ref.current.contains(event.target) && !modal) {
          callback();
        }
      } catch (error) {
        console.log(error);
      }
    },
    [modal, callback],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [callback, ref, modal]);
};

export default useOutsideClick;
