import React from 'react';
import { AuthContext } from './Context/auth-context/auth-context';
import { Splash } from './screens/Splash/Splash';
import AnonymousRouter from './routers/AnonymousRouter';
import AuthenticatedRouter from './routers/AuthenticatedRouter';
import AllProviders from './Context/AllProviders';
import { Helmet } from 'react-helmet';

function App(): any {
  return (
    <AllProviders>
      <Helmet>
        <title>F.CAPE</title>
        <meta name="description" content="F.CAPE means Flexible Cape Morris" />
        <link rel="icon" href={process.env.NODE_ENV === 'development' ? '/favicon-dev.png' : '/favicon.png'} />
      </Helmet>
      <AuthContext.Consumer>
        {({ state }) => (state.state === 'LOGGED_IN' ? <AuthenticatedRouter /> : state.state === 'LOADING' ? <Splash /> : <AnonymousRouter />)}
      </AuthContext.Consumer>
    </AllProviders>
  );
}

export default App;
