import React, { FunctionComponent, useCallback, useContext } from 'react';
import Form from '../../../../../components/molecules/Form/Form';
import EmployeeInput from '../../../../../components/molecules/Form/EmployeeInput/EmployeeInput';
import { AuthContext } from '../../../../../Context/auth-context/auth-context';
import api from '../../../../../services/api';
import useModal from '../../../../../hooks/useModal';

type Props = {};

const ImpersonateModal: FunctionComponent<Props> = (props) => {
  const c = useContext(AuthContext);
  const { hideModal } = useModal();
  const impersonate = useCallback((option: any) => {
    sessionStorage.setItem('impersonate', option.meta.email);
    c.loadUser(undefined, true);
    hideModal();
  }, []);
  return (
    <Form onSubmit={() => {}}>
      <EmployeeInput name={'employee'} group={'organisation'} onChange={impersonate} />
    </Form>
  );
};

export default ImpersonateModal;
