import React from 'react';
import { SubcostType } from '../../../../@types/Finances/Cost/SubcostType';
import { TableProperties } from '../../../../@types/Table/TableProperty';
import MoneyTemplate from '../../../molecules/CellTemplates/common/MoneyTemplate/MoneyTemplate';
import DateTemplate from '../../../molecules/CellTemplates/common/DateTemplate/DateTemplate';
import FinanceItemInfoTemplate from '../../../molecules/CellTemplates/Finances/FinanceItemInfoTemplate/FinanceItemInfoTemplate';
import { CostType } from '../../../../@types/Finances/Cost/CostType';
import FinanceIdTemplate from '../../../molecules/CellTemplates/Finances/FinanceIdTemplate/FinanceIdTemplate';
import ContractorValueTaxTemplate from '../../../molecules/CellTemplates/Finances/ContractorValueTaxTemplate/ContractorValueTaxTemplate';
import TagsTemplate from '../../../molecules/CellTemplates/common/TagsTemplate/TagsTemplate';
import { MoneyType } from '../../../../@types/Money/MoneyType';

const subcostsListTableProperties: TableProperties<SubcostType> = [
  {
    name: 'cost.id',
    visibleName: 'ID',
    template: FinanceIdTemplate,
    sortable: true,
  },
  {
    name: 'cost.number',
    visibleName: 'Number',
    template: FinanceItemInfoTemplate,
    sortable: true,
    templateOptions: {
      chips: [
        {
          display: () => 'Confidential',
          condition: (item: CostType) => item.confidential,
        },
        {
          display: () => 'Internal',
          condition: (item: CostType) => item.internal,
        },
      ],
      textProperties: {
        size: 'l',
        color: 'darkGrey',
        bold: true,
      },
    },
  },
  {
    name: 'cost.contractor',
    visibleName: 'Contractor',
    template: ContractorValueTaxTemplate,
    sortable: true,
  },
  {
    name: 'cost.postedAt',
    visibleName: 'Posting date',
    template: DateTemplate,
    sortable: true,
    templateOptions: {
      textProperties: {
        size: 'm',
        color: 'grey',
        bold: true,
      },
    },
  },
  {
    name: 'amountNet',
    sumAsMoney: true,
    sumBy: 'amountNet',
    visibleName: 'Net',
    template: MoneyTemplate,
    sortable: 'amountNet.value',
    templateOptions: {
      textProperties: {
        size: 'm',
        bold: true,
      },
    },
  },
  {
    name: 'cost.liabilitiesPln',
    visibleName: 'Paid',
    transformValue: (value: MoneyType) => (value.value > 0 ? 'No' : 'Yes'),
    template: TagsTemplate,
  },
];

export default subcostsListTableProperties;
