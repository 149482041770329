import styled from 'styled-components';
import { color } from '../../../../../styles/Variables';
import { UiText } from '../../../../../styles/Typography';

export const EstimationInput = styled.input`
  ${UiText};
  border: none;
  border-bottom: 1px solid ${color.neutral[60]};
  width: 100%;
  padding: 1rem 0 0.3rem;
`;
