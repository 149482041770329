import { TableFilterType } from '../../../../legacy/CapeMorris/@types';
import { DictValue } from '../../../../@types/Dictionary/DictValue';
import CustomDateSelectFilter from './Filters/CustomDateSelectFilter';

const StatusOptions: DictValue[] = [
  { value: 'active', label: 'Active' },
  { value: 'completed', label: 'Completed' },
  { value: 'settled', label: 'Settled' },
];
export const BillableTypeOptions: DictValue[] = [
  { value: 'billable', label: 'Billable' },
  { value: 'new_business', label: 'New business' },
  { value: 'marketing', label: 'Marketing' },
  { value: 'internal', label: 'Internal' },
  { value: 'technical', label: 'Technical' },
];

const filters: TableFilterType[] = [
  {
    type: 'text',
    name: '_search',
    label: 'Search',
    placeholder: 'Search by partner, project, leader',
    gridProps: {
      $desktop: 4,
    },
  },
  {
    name: 'leader.id',
    label: 'Leader',
    type: 'employee',
    options: {
      group: 'team',
      all: true,
      isMulti: true,
    },
    gridProps: {
      $desktop: 3,
    },
  },
  {
    type: 'select',
    name: 'status',
    label: 'Status',
    options: { options: StatusOptions },
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'custom',
    options: {
      component: CustomDateSelectFilter,
    },
    name: 'settlementDate',
    label: 'Settled at',
    gridProps: {
      $desktop: 3,
    },
  },
  {
    type: 'month',
    name: 'createdAt',
    label: 'Start date',
  },
  {
    type: 'month',
    name: 'plannedCompletionDate',
    label: 'Planned end date',
  },
  {
    type: 'select',
    name: 'billableType',
    label: 'Type',
    gridProps: {
      $desktop: 3,
    },
    options: {
      options: BillableTypeOptions,
      isMulti: true,
    },
  },

  {
    type: 'select',
    name: 'client.id',
    label: 'Partner',
    options: {
      baseUrl: '/api/clients',
    },
    gridProps: {
      $desktop: 3,
    },
  },
  {
    type: 'switch_include',
    name: 'archived',
    label: 'Show archived also',
    gridProps: {
      $desktop: 2,
    },
  },
];

export default filters;
