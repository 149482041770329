import React, { FunctionComponent } from 'react';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import { Card } from '../../../../atoms/Card/Card';
import CardHeading from '../../../../molecules/CardHeading/CardHeading';
import useQuery from '../../../../../hooks/useQuery';
import { Loader } from '../../../../../legacy/CapeMorris/components';
import { ArrayResponseType } from '../../../../../@types/hydra/hydra';
import { VacationDaysType } from '../../../../../@types/VacationDays/VacationDaysType';
import Text from '../../../../atoms/Text';

type Props = {
  employeeIri: string;
  availableDays: number;
};

const VacationDays: FunctionComponent<Props> = ({ employeeIri, availableDays }) => {
  const { data, isLoading } = useQuery<ArrayResponseType<VacationDaysType>>('/api/vacation_days', `${employeeIri}/vacation_days`);
  return (
    <GridItem $desktop={6}>
      <Card style={{ position: 'relative' }}>
        <CardHeading heading={`Available vacation days: ${availableDays}`} />
        {isLoading && <Loader />}
        {data && (
          <Grid spacing={1}>
            <GridItem $desktop={12}>
              <Text size={'xl'} bold>
                History
              </Text>
            </GridItem>
            {data['hydra:member'].map((day) => (
              <GridItem $desktop={12}>
                <Text size={'s'}>
                  <Text color={'primary'}>{day.count}</Text>&nbsp;
                  {day.description}
                </Text>
              </GridItem>
            ))}
          </Grid>
        )}
      </Card>
    </GridItem>
  );
};

export default VacationDays;
