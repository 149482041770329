import React, { FunctionComponent, useContext } from 'react';
import * as Styled from './AddRoleButton.styled';
import Button from '../../../../../../atoms/Button/Button';
import PlusIcon from '../../../../../../../Icons/Plus.icon';
import { color, fontSize, fontWeight } from '../../../../../../../styles/Variables';
import { ModalContext } from '../../../../../../../Context/ModalContext/ModalContext';
import RoleModal from '../RoleModal/RoleModal';
import { ProjectRoleType } from '../../../../../../../@types/Project/ProjectRoleType';

type Props = {
  projectId: number;
  small?: boolean;
  role?: ProjectRoleType;
  onChange: () => void;
};

const AddRoleButton: FunctionComponent<Props> = ({ projectId, small = false, role, onChange }) => {
  const { showModal } = useContext(ModalContext);

  const addRole = () => {
    showModal({
      title: role?.name ? 'Edit employee role' : 'Add role',
      body: <RoleModal projectId={`/api/projects/${projectId}`} role={role} onChange={onChange} />,
      width: 42,
    });
  };

  return (
    <Styled.AddRole $small={small}>
      <Button
        $text={'Add role'}
        $icon={small ? <PlusIcon /> : <PlusIcon inCircle={true} />}
        kind={'link'}
        iconSize={small ? 1 : 2.8}
        style={{
          padding: '0',
          fontWeight: small ? fontWeight.regular : fontWeight.bold,
          fontSize: small ? fontSize.body.s : fontSize.body.m,
        }}
        iconColor={color.neutral['70']}
        onClick={() => addRole()}
      />
    </Styled.AddRole>
  );
};

export default AddRoleButton;
