import React, { FunctionComponent } from 'react';
import { DayEventType } from './DayEventType';
import * as Styled from './DayEvent.styled';
import { Icon } from '../../../../atoms/Icon/Icon';
import RecreationIcon from '../../../../../Icons/Recreation.icon';
import { color } from '../../../../../styles/Variables';
import Text from '../../../../atoms/Text';
import HolidayIcon from '../../../../../Icons/Holiday.icon';
import SickLeaveIcon from '../../../../../Icons/SickLeave.icon';

type Props = {
  dayEvent: DayEventType;
  areConflicts?: boolean;
};

const DayEvent: FunctionComponent<Props> = ({ dayEvent: { name, type }, areConflicts }) => {
  return (
    <Styled.DayEvent $areConflicts={areConflicts}>
      <Icon size={1.5}>
        {type === 'vacation' && <RecreationIcon size={1.5} iconColor={color.neutral[60]} />}
        {type === 'holiday' && <HolidayIcon color={color.neutral[60]} />}
        {type === 'sick_leave' && <SickLeaveIcon size={1.5} />}
      </Icon>
      <Text color={'grey'} size={'xl'} className={'task-title'}>
        {name}
      </Text>
    </Styled.DayEvent>
  );
};

export default DayEvent;
