import React from 'react';
import { CellTemplate } from '../../CellTemplate';
import { ProjectListItemType } from '../../../../../@types/Project/Project';
import { OverflowSentimentType } from '../../../../../@types/OverflowSentiment/OverflowSentimentType';
import Text from '../../../../atoms/Text';

const MarginTemplate: CellTemplate<number, {}, ProjectListItemType> = ({ item }) => {
  const displayValue = React.useMemo(() => {
    if (!item.margin) {
      return 0;
    }
    return (item.margin * 100).toFixed(0);
  }, [item.margin]);

  const sentiment: OverflowSentimentType = React.useMemo(() => {
    if (!item.margin) {
      return 'neutral';
    }
    if (item.status !== 'settled') {
      return 'neutral';
    }
    return item.margin > 0 ? 'good' : 'bad';
  }, [item.status, item.margin]);

  return (
    <Text color={sentiment === 'good' ? 'success' : sentiment === 'bad' ? 'error' : 'darkGrey'} bold>
      {displayValue}%
    </Text>
  );
};

export default MarginTemplate;
