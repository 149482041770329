import { DictValue } from '../../@types/Dictionary/DictValue';

export const ContractTypeDict: DictValue[] = [
  {
    value: 'framework_agreement',
    label: 'Framework Agreement',
  },
  {
    value: 'annex_to_the_contract',
    label: 'Annex to the contract',
  },
  {
    value: 'nda',
    label: 'NDA',
  },
  {
    value: 'administration',
    label: 'Administration',
  },
  {
    value: 'orders',
    label: 'Orders',
  },
  {
    value: 'others',
    label: 'Others',
  },
];
