import styled from 'styled-components';
import { color } from '../../../../../../../styles/Variables';

export const TimelineEvent = styled.div`
  display: grid;
  grid-template-columns: 4rem auto;
  gap: 1.2rem;
  align-items: flex-start;
  position: relative;

  &:hover {
    button {
      opacity: 1;
    }
  }
`;

export const LeftColumn = styled.div<{ $isLastEvent?: boolean }>`
  display: flex;
  height: 0;
  justify-content: center;
  align-items: self-start;
`;

export const Line = styled.div`
  min-height: 2rem;
  height: calc(100% - 2rem);
  border-left: 1px dashed ${color.neutral['50']};
  position: absolute;
  top: 4.5rem;
  transform: translate(-50%, 0);
`;

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const TitleWrapper = styled.div`
  display: flex;
  line-height: 1.2;
  padding-right: 1rem;
  justify-content: space-between;

  p:first-of-type {
    margin-bottom: 0.5rem;
  }

  div {
    display: flex;
    gap: 0.5rem;
  }
`;

export const Participants = styled.div`
  display: flex;
  align-items: center;
`;
