import React, { FunctionComponent, useCallback } from 'react';
import { InputProps } from '../../../../@types/Form/InputProps';
import { useFormContext } from 'react-hook-form';
import useFormError from '../../../../hooks/Form/useFormError';
import { FormHtmlInput, FormInputContainer, FormInputSuffix, FormLabel, SuffixWrapper } from '../../../atoms/Form/InputParts/InputParts';
import { ErrorMessages } from '../../../atoms/Form/FormMessages/ErrorMessages';
import FormError from '../../../atoms/Form/FormError/FormError';
import * as Styled from './NumberInput.styled';

type NumberInputProps = InputProps & {
  style?: React.CSSProperties;
  suffix?: boolean | string;
  float?: boolean | number;
  min?: number;
  max?: number;
  arrows?: boolean;
  step?: number;
};

const NumberInput: FunctionComponent<NumberInputProps> = ({
  name,
  label,
  placeholder,
  disabled = false,
  style,
  defaultValue,
  required = false,
  onChange,
  float = false,
  suffix,
  min = 0,
  max,
  arrows,
  step,
}) => {
  const { register, setValue, getValues } = useFormContext();
  const error = useFormError(name);

  const stepUp = useCallback((value: number) => {
    if (max && value < max) {
      setValue(name, value + (step ? step : 1));
    } else if (!max) {
      setValue(name, value + (step ? step : 1));
    }
  }, []);

  const stepDown = useCallback((value: number) => {
    if (value > min) {
      setValue(name, value - (step ? step : 1));
    }
  }, []);

  return (
    <>
      <FormInputContainer style={style} status={error && 'error'}>
        {label && (
          <FormLabel status={error && 'error'} required={required}>
            {label}
          </FormLabel>
        )}
        <SuffixWrapper $arrows={arrows}>
          <FormHtmlInput
            id={name}
            type={'number'}
            defaultValue={typeof defaultValue !== 'number' || isNaN(defaultValue) ? undefined : defaultValue}
            step={float === false && step ? step : float === false ? 1 : float === true ? '0.0000000000001' : 1 / Math.pow(10, float)}
            status={error && 'error'}
            {...register(name, {
              required: required && ErrorMessages.required,
              valueAsNumber: true,
              min: min,
              max: max,
            })}
            onInput={() => (onChange ? onChange(getValues(name)) : null)}
            placeholder={placeholder ?? label ?? name}
            disabled={disabled}
          />
          {arrows && (
            <Styled.Arrows>
              <Styled.ArrowDown onClick={() => stepDown(getValues(name))}>-</Styled.ArrowDown>
              <Styled.ArrowUp onClick={() => stepUp(getValues(name))}>+</Styled.ArrowUp>
            </Styled.Arrows>
          )}
          {suffix && <FormInputSuffix>{suffix}</FormInputSuffix>}
        </SuffixWrapper>
      </FormInputContainer>
      <FormError name={name} />
    </>
  );
};

export default NumberInput;
