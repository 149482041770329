import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import * as Styled from './Sections.styled';
import { useEntityContext } from '../../../../../../Context/EntityContext/EntityContext';
import { ProjectType } from '../../../../../../@types/Project/Project';
import { SectionType } from '../../../../../../@types/Section/SectionType';
import api from '../../../../../../services/api';
import { EntityType } from '../../../../../../@types/Entity/EntityType';
import { TaskListType, TaskType } from '../../../../../../@types/Task/TaskType';
import Section from './Section/Section';
import serverEvents from '../../../../../../services/serverEvents';
import SectionInlineForm from './Section/SectionBody/SectionInlineForm/SectionInlineForm';
import Form from '../../../../../molecules/Form/Form';
import { DragDropContext, Droppable, DropResult } from '@hello-pangea/dnd';

type ProjectWithSectionsType = EntityType & {
  sections: SectionType[];
  tasksWithoutSection: TaskListType[];
  completedTaskWithoutSectionCount: number;
};

const Sections: FunctionComponent = () => {
  const { entity: project } = useEntityContext<ProjectType>();
  const [sections, setSections] = useState<Partial<SectionType>[]>([]);

  const handleSectionsOrder = useCallback(
    (sectionIri: string, sourcePosition: number, targetPosition: number) => {
      const section = sections.find((section) => section['@id'] === sectionIri);
      if (!section) return;

      sections.splice(sourcePosition, 1);
      sections.splice(targetPosition, 0, section);
      setSections([...sections]);
      void api.post(`${project['@id']}/reorder-sections`, { orderSections: sections.filter((s) => !!s['@id']).map((s, idx) => ({ id: s.id, position: idx })) });
    },
    [sections],
  );

  const handleTasksOrder = useCallback(
    (taskIri: string, sourceSectionIri: string, targetSectionIri: string, sourcePosition: number, targetPosition: number) => {
      const sourceSection = sourceSectionIri === 'without-section' ? sections[0] : sections.find((section) => section['@id'] === sourceSectionIri);
      const targetSection = sections.find((section) => section['@id'] === targetSectionIri);
      if (!sourceSection || !targetSection) return;

      const task = sourceSection?.tasks?.find((task) => task['@id'] === taskIri);
      if (!task) return;

      sourceSection.tasks?.splice(sourcePosition - 1, 1);
      targetSection.tasks?.splice(targetPosition - 1, 0, task);

      void api.post(`${targetSectionIri}/reorder-tasks`, { orderTasks: targetSection.tasks?.map((t, idx) => ({ id: t.id, position: idx })) });
    },
    [sections],
  );

  const handleTasksWithoutSectionOrder = useCallback(
    (taskIri: string, sourceSectionIri: string, sourcePosition: number, targetPosition: number) => {
      const sourceSection = sourceSectionIri === 'without-section' ? sections[0] : sections.find((section) => section['@id'] === sourceSectionIri);
      if (!sourceSection) return;
      const targetSection = sections[0];

      const task = sourceSection?.tasks?.find((task) => task['@id'] === taskIri);
      if (!task) return;

      sourceSection.tasks?.splice(sourcePosition - 1, 1);
      targetSection.tasks?.splice(targetPosition - 1, 0, task);

      void api.post(`${project['@id']}/reorder-tasks`, { orderTasks: targetSection.tasks?.map((t, idx) => ({ id: t.id, position: idx })) });
    },
    [project, sections],
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }
      const sourceDroppable = result.source.droppableId;
      const draggable = result.draggableId;
      const droppable = result.destination.droppableId;

      if (draggable.startsWith('/api/tasks')) {
        if (droppable === 'without-section') {
          handleTasksWithoutSectionOrder(draggable, sourceDroppable, result.source.index, result.destination.index);
        } else {
          handleTasksOrder(draggable, sourceDroppable, droppable, result.source.index, result.destination.index);
        }
      } else {
        handleSectionsOrder(draggable, result.source.index, result.destination.index);
      }
    },
    [handleSectionsOrder, handleTasksOrder, handleTasksWithoutSectionOrder],
  );

  const loadTasks = useCallback(() => {
    api.get<ProjectWithSectionsType>(`${project['@id']}/tasks`).then((response) => {
      setSections([
        {
          name: 'Without section',
          tasks: response.data.tasksWithoutSection,
          completedTaskCount: response.data.completedTaskWithoutSectionCount,
          activeTaskCount: response.data.tasksWithoutSection.length,
        },
        ...response.data.sections,
      ]);
    });
  }, []);

  useEffect(() => {
    loadTasks();
    const subscriber = serverEvents.listen<TaskType>('/api/tasks').subscribe((event) => {
      if (!event.project || event.project['@id'] !== project['@id']) return;
      loadTasks();
    });
    const subscriber2 = serverEvents.listen<SectionType>('/api/sections').subscribe((event) => {
      if (!event.project || event.project['@id'] !== project['@id']) return;
      loadTasks();
    });
    return () => {
      subscriber.unsubscribe();
      subscriber2.unsubscribe();
    };
  }, [project]);

  useEffect(() => {
    loadTasks();
  }, []);

  return (
    <Styled.SectionsContainer>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={project['@id']} type="SECTION" isCombineEnabled={false} ignoreContainerClipping={false}>
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {sections.map((section, index) => (
                <Section key={section['@id'] ?? 'without-section'} section={section} projectId={project.id} index={index} />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <SectionInlineForm project={project} sectionsCount={sections.length} />
    </Styled.SectionsContainer>
  );
};

export default Sections;
