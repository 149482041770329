import { ModuleStateType, ModuleType } from '../../@types/Module/Module';

export type BaseAction = {
  type: 'SET_MODULE';
  module: ModuleType | undefined;
};

const moduleReducer = (prev: ModuleStateType, action: BaseAction) => {
  switch (action.type) {
    case 'SET_MODULE':
      return {
        ...prev,
        currentModule: action.module,
      };
  }
  throw new Error('ModuleReducer does not have action ' + action.type);
};

export default moduleReducer;
