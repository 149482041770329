import styled, { css } from 'styled-components';
import { color, fontSize, FontType, fontWeight } from 'styles/Variables';

/** @internal */
export const Heading = styled.h1<{
  $size: FontType;
  $color?: string;
  $noPadding?: boolean;
}>`
  color: ${color.neutral[80]};
  font-weight: ${fontWeight.bold};
  line-height: normal;
  ${(p) =>
    p.$color &&
    css`
      color: ${p.$color};
    `}
  ${(p) =>
    p.$size === 's' &&
    css`
      font-size: ${fontSize.heading.s};
    `}
  ${(p) =>
    p.$size === 'm' &&
    css`
      font-size: ${fontSize.heading.m};
    `}
  ${(p) =>
    p.$size === 'l' &&
    css`
      font-size: ${fontSize.heading.l};
    `}
`;
