import styled from 'styled-components';
import Container from '../../../atoms/Container/Container';

export const FeedStyled = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  padding: 1rem;
  margin-top: 4.4rem;
  align-items: center;
`;
