import styled, { css } from 'styled-components';
import { color } from '../../../styles/Variables';

export const sidebarPadding = css`
  padding: 1.6rem 3.2rem;
`;

export const sidebarBg = css`
  background-color: ${color.neutral[10]};
`;

export const ExternalContainer = styled.div`
  height: 100%;
  position: relative;
`;

export const TaskSidebarContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  padding-bottom: 130px;
  background-color: ${color.neutral[20]};
`;

export const CommentInputContainer = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  background-color: ${color.neutral[30]};
  padding: 1.2rem 3.2rem 3.2rem 3.2rem;
  overflow: scroll;
  max-height: 60vh;
  border-top: 2rem solid ${color.neutral[30]};
`;
