import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { SectionType } from '../../../../../../../@types/Section/SectionType';
import Collapse from '../../../../../../atoms/Collapse/Collapse';
import SectionHeader from './SectionHeader/SectionHeader';
import SectionBody from './SectionBody/SectionBody';
import { Draggable } from '@hello-pangea/dnd';
import * as Styled from './Section.styled';
import { Icon } from '../../../../../../atoms/Icon/Icon';
import DragIcon from '../../../../../../../Icons/Drag.icon';
import { color } from '../../../../../../../styles/Variables';
import api from '../../../../../../../services/api';
import { TaskType } from '../../../../../../../@types/Task/TaskType';

type Props = {
  section: Partial<SectionType>;
  projectId: number;
  index: number;
};

type CompletedSectionTasks = {
  ['hydra:member']: TaskType[];
};

const Section: FunctionComponent<Props> = ({ section, projectId, index }) => {
  const [showCompleted, setShowCompleted] = useState(false);
  const [completedTasks, setCompletedTasks] = useState<TaskType[]>([]);
  const allTasks = useMemo(() => {
    return [...(section.tasks ?? []), ...completedTasks];
  }, [section.tasks, completedTasks, showCompleted]);

  const loadCompletedTasks = useCallback(() => {
    const params: any = {
      completed: true,
      'project.id': projectId,
      'section.id': section.id ?? null,
      withoutSection: section.id ? null : 'true',
    };

    api.get<CompletedSectionTasks>('/tasks', { params }).then((response) => {
      setCompletedTasks(response.data['hydra:member']);
    });
  }, [showCompleted]);

  useEffect(() => {
    if (showCompleted) {
      loadCompletedTasks();
    } else {
      setCompletedTasks([]);
    }
  }, [showCompleted]);

  return (
    <Draggable draggableId={section['@id'] ?? 'without-section'} isDragDisabled={!section['@id']} index={index} disableInteractiveElementBlocking={true}>
      {(provided, snapshot) => (
        <Styled.Wrapper ref={provided.innerRef} {...provided.draggableProps}>
          {section['@id'] && (
            <Styled.DragHandler $isDragging={snapshot.isDragging} {...provided.dragHandleProps} className={'section-drag-handler'}>
              <Icon size={1.05} onClick={(e) => e.stopPropagation()}>
                <DragIcon color={color.neutral[60]} />
              </Icon>
            </Styled.DragHandler>
          )}
          <div>
            <Collapse
              header={<SectionHeader section={section} />}
              displayType={'section'}
              hidden={<SectionBody section={section} allTasks={allTasks} showCompleted={showCompleted} setShowCompleted={setShowCompleted} />}
              initialExpanded={true}
            />
          </div>
        </Styled.Wrapper>
      )}
    </Draggable>
  );
};

export default Section;
