import React, { FunctionComponent, useCallback } from 'react';
import * as Styled from '../CommonStyles.styled';
import { Icon } from '../../../../../../atoms/Icon/Icon';
import { color } from '../../../../../../../styles/Variables';
import CalendarIcon from '../../../../../../../Icons/Calendar.icon';
import MeetingsBody, { MeetingsSubtitle } from '../../../../../../organisms/MeetingDetailsModal/MeetingDetailsModal';
import useModal from '../../../../../../../hooks/useModal';
import { MeetingType } from '../../../../../../../@types/Meeting/MeetingType';
import Text from '../../../../../../atoms/Text';
import { useNavigate } from 'react-router-dom';
import { parseDate } from '../../../../../../../helpers/date';

type Props = {
  meeting: MeetingType;
};

const WorkloadMeeting: FunctionComponent<Props> = ({ meeting }) => {
  const { showModal, hideModal } = useModal();
  const navigate = useNavigate();

  const onMeetingSubtitleClick = () => {
    navigate(`projects/${meeting.project.id}`);
    hideModal();
  };
  const onMeetingClick = useCallback(() => {
    showModal({
      title: meeting.subject,
      subtitle: meeting.project && MeetingsSubtitle(meeting, () => onMeetingSubtitleClick()),
      body: React.createElement(MeetingsBody, { meeting }),
      width: 64,
    });
  }, [meeting]);
  return (
    <Styled.MainWrapper
      onClick={() => {
        onMeetingClick();
      }}
    >
      <Styled.MeetingWrapper>
        <Icon size={1.5} color={color.neutral[80]}>
          <CalendarIcon />
        </Icon>
        <Text size={'l'}> {meeting.subject}</Text>
      </Styled.MeetingWrapper>
      <Styled.MeetingWrapper>
        <Styled.MeetingInnerWrapper>
          <Text color={'darkGrey'} bold>
            {parseDate(new Date(meeting.start), false, false, true, false)}
          </Text>
        </Styled.MeetingInnerWrapper>
        <Styled.MeetingInnerWrapper>
          <Text color={'grey'} bold>
            {parseDate(new Date(meeting.start), false, false, false, true)}
          </Text>
          <Text color={'grey'} bold>
            -
          </Text>
          <Text color={'grey'} bold>
            {parseDate(new Date(meeting.end), false, false, false, true)}
          </Text>
        </Styled.MeetingInnerWrapper>
      </Styled.MeetingWrapper>
    </Styled.MainWrapper>
  );
};

export default WorkloadMeeting;
