import styled from 'styled-components';

export const Container = styled.div<{ $open: boolean }>`
  display: ${(props) => (props.$open ? 'flex' : 'none')};
  position: fixed;
  z-index: 1000;
  //background: #000000aa;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
`;

export const InnerContainer = styled.div`
  width: 640px;
`;
