import React, { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { BodyText } from '../../../../../styles/Typography';
import { color, fontSize, fontWeight } from '../../../../../styles/Variables';
import useFormError from '../../../../../hooks/Form/useFormError';

type Props = {};

const Input = styled.input<{ error: boolean }>`
  ${BodyText};
  border: none;
  border-bottom: 1px solid transparent;
  font-size: ${fontSize.heading.s};
  width: calc(100% - 4rem) !important;
  font-weight: ${fontWeight.bold};
  color: ${color.neutral[80]};
  line-height: normal;
  margin-top: 0;

  ::placeholder {
    color: ${color.neutral[60]};
  }

  ${(p) =>
    p.error &&
    css`
      color: ${color.semantic.error[80]};
      ::placeholder {
        color: ${color.semantic.error[80]};
      }
    `}
`;

const TaskNameInput: FunctionComponent<Props> = () => {
  const { register } = useFormContext();
  const error = useFormError('name');
  return (
    <Input
      error={!!error}
      {...register('title', {
        required: 'Name is required',
      })}
      placeholder={'Type task name here'}
    />
  );
};

export default TaskNameInput;
