import React, { FunctionComponent } from 'react';
import MyWorkCalendar from '../MyWork/MyWorkCalendar/MyWorkCalendar';
import useUser from '../../../hooks/useUser';
import TeamSelector from '../../molecules/TeamSelector/TeamSelector';
import { Grid, GridItem } from '../../atoms/Grid/Grid';

type Props = {};

const WorkloadSchedule: FunctionComponent<Props> = (props) => {
  const user = useUser();
  const [employee, setEmployee] = React.useState<number>(user.employeeId);
  return (
    <div>
      <Grid spacing={4}>
        <GridItem $desktop={'max'} />
        <GridItem>
          <TeamSelector companyTeamRole={'WORKLOAD.CAN_MANAGE_ALL'} onSelect={(id) => setEmployee(!id ? user.employeeId : Array.isArray(id) ? id[0] : id)} />
        </GridItem>
        <GridItem $desktop={12}>
          <MyWorkCalendar employeeId={employee} extraPadding key={employee} />
        </GridItem>
      </Grid>
    </div>
  );
};

export default WorkloadSchedule;
