import { TableFiltersType } from '../../../../../../legacy/CapeMorris/@types';

const protocolsTableFilters: TableFiltersType = [
  {
    type: 'select',
    name: 'type',
    options: {
      options: [
        { value: 'release', label: 'Release' },
        { value: 'return', label: 'Return' },
        { value: 'restore', label: 'In stock' },
        { value: 'common_use', label: 'Office use' },
        { value: 'destruction', label: 'Damaged' },
      ],
    },
    label: 'Type',
    gridProps: {
      $desktop: 6,
    },
    placeholder: 'Select type',
  },
  {
    type: 'employee',
    name: 'person.id',
    label: 'Assignee',
    options: {
      group: 'organisation',
      all: true,
    },
    gridProps: {
      $desktop: 6,
    },
    placeholder: 'Select person',
  },
];

export default protocolsTableFilters;
