import React, { FunctionComponent, useRef } from 'react';
import * as Styled from './Tab.styled';

type Props = {
  name: string;
  idName?: string;
  onClick: () => void;
  isActive: boolean;
  count?: number;
  action?: JSX.Element;
};

const NavigatorRouterTab: FunctionComponent<Props> = ({ name, onClick, isActive, count, action, idName }) => {
  const ref = useRef(null);

  return (
    <Styled.Wrapper
      onClick={() => {
        onClick();
      }}
      $isActive={isActive}
    >
      <Styled.TabContainer id={idName} ref={ref}>
        {name}
        {action}
        {count ? <Styled.Count>{count}</Styled.Count> : null}
      </Styled.TabContainer>
      <Styled.Underline />
    </Styled.Wrapper>
  );
};

export default NavigatorRouterTab;
