import styled from 'styled-components';
import { UiText } from '../../../../styles/Typography';
import { color, fontSize } from '../../../../styles/Variables';

export const Body = styled.tbody`
  min-height: 20rem;
  position: relative;
  ${UiText};
  font-size: ${fontSize.body.m};
  tr:nth-child(even) {
    background-color: ${color.light};
    &:hover {
      background-color: ${color.neutral[20]};
    }
  }
  td {
    padding: 2rem 1rem;
  }
`;

export const LoaderContainer = styled.div``;
