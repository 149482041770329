import styled, { css } from 'styled-components';
import { color } from '../../../../styles/Variables';

export const Container = css`
  z-index: 1000;
  padding: 3.2rem;
  width: 64rem;
  border: 1px solid ${color.neutral['30']};
  background-color: ${color.light};
  border-radius: 6px;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-content: stretch;
  gap: 3.2rem;
`;

export const Row = styled.div`
  display: flex;
  column-gap: 1.6rem;
`;

export const Column = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.6rem;
`;

export const Line = styled.span`
  display: block;
  gap: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  max-width: 26rem;
  margin-right: 0.6rem;
`;
export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 3.2rem;
  right: 3.2rem;
`;
