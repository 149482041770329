import React, { FunctionComponent, useCallback } from 'react';
import { useEntityContext } from '../../../../../Context/EntityContext/EntityContext';
import { ProjectType } from '../../../../../@types/Project/Project';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import { Card } from '../../../../atoms/Card/Card';
import CardHeading from '../../../../molecules/CardHeading/CardHeading';
import { IncomeType } from '../../../../../@types/Finances/Income/IncomeType';
import Table from '../../../../organisms/Table/Table';
import { useNavigate } from 'react-router-dom';
import { contractsListProperties } from '../../../Contracts/ContractsList/ContractsList.properties';
import { contractListFilters } from '../../../Contracts/ContractsList/ContractsList.filters';
import { EntityType } from '../../../../../@types/Entity/EntityType';

type Props = {};

const ContractsView: FunctionComponent<Props> = (props) => {
  const { entity: project } = useEntityContext<ProjectType>();
  let navigate = useNavigate();

  const handleIncomeClick = useCallback((item: EntityType) => {
    navigate(`/finances/contracts/${item.id}/show`);
  }, []);

  return (
    <Grid spacing={2}>
      <GridItem $desktop={12}>
        <Card>
          <CardHeading heading={'Contracts'} />
          <Table<IncomeType>
            context={'/api/project-contracts'}
            url={`/api/project-contracts?project.id=${project.id}`}
            properties={contractsListProperties}
            filters={contractListFilters}
            onItemClick={handleIncomeClick}
          />
        </Card>
      </GridItem>
    </Grid>
  );
};

export default ContractsView;
