import styled, { css } from 'styled-components';

type AnimatedWrapperProps = {
  $isInViewport?: boolean;
};
export const AnimatedWrapper = styled.div<AnimatedWrapperProps>`
  ${(props: AnimatedWrapperProps) =>
    props.$isInViewport &&
    css`
      transition: 0.2s ease-in-out;
      transition-property: opacity, visibility;
      visibility: visible;
      opacity: 1;
    `}

  ${(props: AnimatedWrapperProps) =>
    !props.$isInViewport &&
    css`
      transition: 0.2s ease-in-out;
      transition-property: opacity, visibility;
      opacity: 0;
      visibility: hidden;
    `}
`;
