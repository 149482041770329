import React, { FunctionComponent } from 'react';
import { Grid, GridItem } from '../../atoms/Grid/Grid';
import Text from '../../atoms/Text';
import Button from '../../atoms/Button/Button';
import { StatusType } from '../../../@types/Request/RequestType';

type Props = {
  status: StatusType;
  onApprovalRequest: () => Promise<void>;
};

const DraftStatus: FunctionComponent<Props> = ({ onApprovalRequest, status }) => {
  return (
    <>
      {status === 'draft' && (
        <Grid spacing={2}>
          <GridItem>
            <Text>This document is in draft status. You may click the "Send for approval" button to submit it for approval.</Text>
          </GridItem>
          <GridItem $desktop={12}>
            <Button $text={'Send for approval'} onClick={onApprovalRequest} />
          </GridItem>
        </Grid>
      )}
    </>
  );
};

export default DraftStatus;
