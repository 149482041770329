import styled, { css } from 'styled-components';
import { UiText } from '../../../../../../../../styles/Typography';
import { color, fontSize } from '../../../../../../../../styles/Variables';
import { ProjectTasksGrid } from '../../../TasksView.styled';

export const DragHandler = styled.div`
  svg {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    transition-property: opacity, visibility;
  }
`;

export const Task = styled.div<{ $isDragging: boolean }>`
  ${UiText};
  padding: 1.6rem 0 1.6rem 0;
  border-bottom: 1px solid ${color.neutral[40]};
  border-collapse: collapse;
  background-color: ${color.neutral[10]};
  font-size: ${fontSize.body.l};
  cursor: pointer;
  display: grid;
  ${ProjectTasksGrid};
  transition: background-color 0.15s ease-in-out;

  &:hover {
    background-color: ${color.neutral[20]};

    ${DragHandler} {
      svg {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  ${({ $isDragging }) =>
    $isDragging &&
    css`
      border-top: 1px solid ${color.neutral[40]};
    `}
`;

export const Icons = styled.div`
  display: flex;
  gap: 0.2rem;
  align-items: center;

  svg:first-of-type {
    cursor: grab;
  }
`;

export const DroppableArea = styled.div<{ $isDraggingOver: boolean }>`
  transition: all 0.15s ease-in-out;
  transition-property: background-color, border;
  ${({ $isDraggingOver }) =>
    $isDraggingOver &&
    css`
      border-bottom: 1px solid ${color.primary[80]};
      background-color: ${color.primary[10]};
    `};
`;

export const Empty = styled.div`
  height: 5rem;
  padding: 1rem 2.7rem 2rem;
  font-size: ${fontSize.body.m};
  color: ${color.neutral[60]};
`;

export const NameWrapper = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
`;

export const ShowCompletedButton = styled.button`
  border: none;
  background: none;
  padding: 0.5rem 0 0.5rem 3.8rem;
  font-size: ${fontSize.body.xs};
  color: ${color.primary[60]};
  transition: color 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    color: ${color.primary[80]};
  }
`;
