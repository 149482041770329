import React from 'react';

export const InfoIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 11.5C20 15.9182 16.4182 19.5 12 19.5C7.58183 19.5 4 15.9182 4 11.5C4 7.08183 7.58183 3.5 12 3.5C16.4182 3.5 20 7.08183 20 11.5Z"
        stroke="#2C2D33"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 10V16" stroke="#2C2D33" strokeWidth="0.9" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 7.7V6.5" stroke="#2C2D33" strokeLinecap="round" />
    </svg>
  );
};

export default InfoIcon;
