import styled, { css } from 'styled-components';
import { color, fontSize, fontWeight } from 'styles/Variables';
import { Text, TextType } from '../Text/Text.styled';

type ChipType = TextType & {
  $bgColor?: string;
  onClick?: () => void;
  $rounded?: boolean;
  $hoverColor?: string;
  $equalPaddings?: boolean;
};

export const Chip = styled.span<ChipType>`
  ${Text};
  display: inline-flex;
  gap: 0.6em;
  align-items: center;
  font-weight: ${fontWeight.medium};
  color: ${(props) => (props.color ? props.color : color.neutral[60])};
  border-radius: ${(props) => (props.$rounded ? '10em' : '0.25em')};
  font-size: ${fontSize.body.s};
  padding: 0.2rem 0.6rem;
  ${(props) =>
    props.$size === 'xs' &&
    css`
      padding: 0.1rem 0.5rem;
      font-size: ${fontSize.body.xs};
    `};
  ${(props) =>
    props.$equalPaddings &&
    css`
      padding: 0.3rem;
    `};
  ${(props) =>
    props.$size === 'xs' &&
    props.$equalPaddings &&
    css`
      padding: 0.1rem;
      font-size: ${fontSize.body.xs};
    `};
  background-color: ${(props) => (props.$bgColor ? props.$bgColor : color.neutral[40])};
  cursor: ${(props) => (props.onClick ? 'pointer' : 'inherit')};
  transition: background-color 0.15s ease-out;

  &:hover {
    background-color: ${(props) => (props.$hoverColor ? props.$hoverColor : props.$bgColor ? props.$bgColor : color.neutral[40])};
  }
`;
