import styled, { css } from 'styled-components';
import { color, fontWeight } from '../../../../../styles/Variables';

export const Container = styled.div`
  margin-left: 50px;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  position: relative;
  height: 100%;

  @media (max-width: 1440px) {
    margin-left: 30px;
  }
  @media (max-width: 1024px) {
    margin-left: 15px;
  }
`;

export const SubmenuLink = styled.div<{ $isActive: boolean }>`
  color: ${color.primary[100]};
  font-weight: ${fontWeight.bold};
  text-transform: uppercase;
  font-size: 12px;
  margin-right: 2em;
  display: flex;
  align-items: center;
  height: 100%;
  transition: border-bottom-color 0.2s ease-out;
  border-bottom: 2px solid white;
  ${({ $isActive }) =>
    $isActive &&
    css`
      border-bottom-color: ${color.primary[60]};
    `}

  @media (max-width: 1290px) {
    font-size: 10px;
  }

  &:hover {
    color: ${color.primary[80]};
  }

  &:active {
    transform: scale(0.95);
  }
`;

export const Underline = styled.div`
  border-bottom: 2px solid ${color.primary[60]};
  height: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 0.2s ease-out;
`;
