import { EntityType } from '../../Entity/EntityType';
import { PurchaseOrderType } from '../PurchaseOrder/PurchaseOrderType';
import { MoneyType } from '../../Money/MoneyType';
import { SubcostType } from './SubcostType';
import { ProjectType } from '../../Project/Project';
import { DepartmentType } from '../../Department/DepartmentType';
import { EmployeeType } from '../../Employee/EmployeeType';
import { PaymentType } from '../PaymentType';
import { FinanceStatusType } from '../FinanceStatusType';
import { ContractorType } from '../Contractor/ContractorType';

export const CostSubject = 'Finances::Cost';
export type CostType = EntityType & {
  number: string;
  createdBy: EmployeeType;
  createdAt: string;
  postedAt: string;
  issuedAt: string;
  paymentDue: string;
  documentType: 'invoice' | 'receipt' | 'taxes' | 'salary';
  purchaseOrder?: PurchaseOrderType | null;
  originalAmountNet: MoneyType;
  amountNet: MoneyType;
  originalAmountGross: MoneyType;
  amountGross: MoneyType;
  vat: number;
  currency: string;
  subcosts: SubcostType[];
  payments: PaymentType[];
  status: FinanceStatusType;
  project?: ProjectType;
  type: string;
  internal?: boolean;
  confidential: boolean;
  splitPayment: boolean;
  excludeFromBankExport: boolean;
  contractor?: ContractorType;

  financialCompany?: EntityType & {
    name: string;
    vatNumber: string;
  };
  branch?: EntityType & {
    shortName: string;
    departments: DepartmentType[];
    employees: EmployeeType[];
  };
  note?: string;
  liabilities?: MoneyType;
  exchangeRate?: number;
  bankExportEnabled: boolean;
};
export type CreateCostTypeDto = Partial<CostType> & {
  uploadedResources: number[];
  type: string;
  subcosts: Partial<SubcostType>[];
};
