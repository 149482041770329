import React, { FunctionComponent } from 'react';
import Text from '../../atoms/Text';
import { TaskListType } from '../../../@types/Task/TaskType';

type Props = {
  task: TaskListType;
};

const TaskDeadlineDate: FunctionComponent<Props> = ({ task }) => {
  const getDate = () => {
    if (!task.deadline) {
      return;
    }

    var yesterday = new Date(Date.now() - 86400000);
    var tomorrow = new Date(Date.now() + 86400000);

    if (new Date(task.deadline).toDateString() === yesterday.toDateString()) {
      return (
        <Text size={'s'} color={task.status === 'completed' ? 'grey' : 'error'}>
          Yesterday
        </Text>
      );
    }

    if (new Date(task.deadline).toDateString() === tomorrow.toDateString()) {
      return (
        <Text size={'s'} color={task.status === 'completed' ? 'grey' : 'success'}>
          Tomorrow
        </Text>
      );
    }

    if (new Date(task.deadline).toDateString() === new Date().toDateString()) {
      return (
        <Text size={'s'} color={task.status === 'completed' ? 'grey' : 'success'}>
          Today
        </Text>
      );
    }
    return (
      <Text size={'s'} color={new Date(task.deadline) < new Date() && task.status !== 'completed' ? 'error' : 'grey'}>
        {new Date(task.deadline).toLocaleDateString('en-us', {
          month: 'long',
          day: 'numeric',
        })}
      </Text>
    );
  };
  return <>{getDate()}</>;
};

export default TaskDeadlineDate;
