import styled from 'styled-components';
import { BodyText } from '../../../../../styles/Typography';
import { color, fontSize } from '../../../../../styles/Variables';

export const Container = styled.div``;

export const Item = styled.tr`
  td {
    padding-bottom: 1rem;
  }
`;

export const InfoBox = styled.div`
  ${BodyText};
  font-size: ${fontSize.body.s};
  color: ${color.neutral[60]};
  background-color: ${color.neutral[20]};
  border-radius: 8px;
  padding: 1.2rem;
  margin: 0.8rem 0;
`;
export const List = styled.table`
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: 100%;
  row-gap: 1rem;
`;
