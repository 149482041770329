import React, { FunctionComponent, useMemo } from 'react';
import { TextSizeType } from '../../../@types/Text/TextSizeType';
import PriorityType from '../../../@types/Priority/PriorityType';
import { color } from '../../../styles/Variables';
import BetterChip, { ChipColor } from '../../atoms/Chips/BetterChip';
import Text from '../../atoms/Text';

type Props = {
  priority: PriorityType;
  size?: TextSizeType;
};

const PriorityTag: FunctionComponent<Props> = ({ priority, size = 's' }) => {
  const state = useMemo<{
    displayedText: string;
    color: string;
    bgColor: ChipColor;
  }>(() => {
    switch (priority) {
      case 'Low':
        return {
          displayedText: 'Low',
          color: color.neutral[70],
          bgColor: 'neutral',
        };
      case 'Normal':
        return {
          displayedText: 'Normal',
          color: color.secondary[70],
          bgColor: 'success',
        };
      case 'High':
        return {
          displayedText: 'High',
          color: color.semantic.warning[100],
          bgColor: 'warning',
        };
      case 'ASAP':
        return {
          displayedText: 'ASAP',
          color: color.semantic.error[80],
          bgColor: 'error',
        };
    }
  }, [priority]);
  return (
    <BetterChip color={state?.bgColor}>
      <Text size={size}>{priority}</Text>
    </BetterChip>
  );
};

export default PriorityTag;
