import React, { FunctionComponent } from 'react';
import { NotificationType } from '../../../../@types/Notification/NotificationType';
import * as Styled from '../Notification.styled';
import Text from '../../../atoms/Text';
import RichText from '../../../atoms/RichText/RichText';
import { color } from '../../../../styles/Variables';

type Props = {
  notification: NotificationType;
  timeComponent: JSX.Element;
  indicatorComponent: JSX.Element;
};

const DefaultNotification: FunctionComponent<Props> = ({ notification, timeComponent, indicatorComponent }) => {
  return (
    <>
      <Styled.TopWrapper>
        {indicatorComponent}
        {timeComponent}
      </Styled.TopWrapper>
      <Styled.ContentTitle size={'l'} bold={true}>
        {notification.subject}
      </Styled.ContentTitle>
      <Text size={'xs'} color={'semiGrey'}>
        <RichText content={notification.content} paragraphStyles={{ fontSize: '1.3rem', color: color.neutral[60] }} wordsLimit={10} />
      </Text>
    </>
  );
};

export default DefaultNotification;
