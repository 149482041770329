import styled from 'styled-components';
import { color } from '../../../../../styles/Variables';

export const InfoContainer = styled.div`
  margin: 0 0 4rem;
  padding: 0 0 2.5rem 0;
  width: 100%;
  display: flex;
  justify-content: end;
  border-bottom: 1px solid ${color.neutral[40]};
`;
