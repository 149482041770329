import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import Button from '../Button/Button';
import * as Styled from './RichText.styled';

type Props = {
  content: string;
  wordsLimit?: number;
  context?: string;
  paragraphStyles?: {
    fontSize?: string;
    color?: string;
  };
};

const RichText: FunctionComponent<Props> = ({ content, wordsLimit, context, paragraphStyles }) => {
  const [isTruncated, setIsTruncated] = useState<boolean>(false);

  const isLong = useMemo(() => {
    if (wordsLimit) {
      return content.split(' ').length > wordsLimit;
    }
    return false;
  }, [content]);

  useEffect(() => {
    if (isLong) {
      setIsTruncated(true);
    }
  }, [isLong, content]);

  const truncatedContent = content?.split(' ').slice(0, wordsLimit).join(' ') + '...';

  return (
    <div>
      <Styled.Content $isTruncated={isTruncated}>
        <Styled.RichTextContainer
          $paragraphStyles={paragraphStyles}
          dangerouslySetInnerHTML={{ __html: isTruncated ? truncatedContent : content }}
          $context={context}
        />
      </Styled.Content>
      {isLong && (
        <div onClick={(e) => e.preventDefault()}>
          <Button
            $text={isTruncated ? 'show more' : 'show less'}
            kind={'link'}
            onClick={(e) => {
              setIsTruncated((prevState) => !prevState);
            }}
            style={{ display: 'flex', padding: 0 }}
          />
        </div>
      )}
    </div>
  );
};
export default RichText;
