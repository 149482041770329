import React, { CSSProperties, FunctionComponent } from 'react';
import { color } from '../styles/Variables';

interface OwnProps {
  direction?: 'asc' | 'desc' | undefined;
  style?: CSSProperties;
}

type Props = OwnProps;

const SortIcon: FunctionComponent<Props> = ({ style, direction }) => {
  return (
    <svg style={style} width={6} viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.33528 2.01397C4.48083 2.16328 4.48083 2.40536 4.33528 2.55467C4.18973 2.70398 3.95374 2.70398 3.80819 2.55467L1.96336 0.662223C1.81781 0.512912 1.81781 0.270832 1.96336 0.121522C2.10892 -0.0277882 2.3449 -0.0277882 2.49046 0.121522L4.33528 2.01397Z"
        fill={direction === 'asc' ? color.primary[70] : color.neutral[60]}
      />
      <path
        d="M0.636256 2.54512C0.490703 2.69443 0.254715 2.69443 0.109163 2.54512C-0.0363894 2.39581 -0.0363894 2.15373 0.109163 2.00442L1.95399 0.111972C2.09954 -0.0373378 2.33553 -0.0373378 2.48108 0.111972C2.62663 0.261283 2.62663 0.503363 2.48108 0.652673L0.636256 2.54512Z"
        fill={direction === 'asc' ? color.primary[70] : color.neutral[60]}
      />
      <path
        d="M0.109164 5.98603C-0.0363882 5.83672 -0.0363882 5.59464 0.109164 5.44533C0.254717 5.29602 0.490704 5.29602 0.636257 5.44533L2.48108 7.33778C2.62663 7.48709 2.62663 7.72917 2.48108 7.87848C2.33553 8.02779 2.09954 8.02779 1.95399 7.87848L0.109164 5.98603Z"
        fill={direction === 'desc' ? color.primary[70] : color.neutral[60]}
      />
      <path
        d="M3.80819 5.45488C3.95374 5.30557 4.18973 5.30557 4.33528 5.45488C4.48083 5.60419 4.48083 5.84627 4.33528 5.99558L2.49046 7.88803C2.3449 8.03734 2.10892 8.03734 1.96336 7.88803C1.81781 7.73872 1.81781 7.49664 1.96336 7.34733L3.80819 5.45488Z"
        fill={direction === 'desc' ? color.primary[70] : color.neutral[60]}
      />
    </svg>
  );
};

export default SortIcon;
