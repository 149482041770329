import React, { FunctionComponent } from 'react';
import { color } from '../styles/Variables';

type PropType = {
  size?: number;
};

const ChildbirthIcon: FunctionComponent<PropType> = ({ size = 1 }) => {
  return (
    <svg width={`${size}rem`} height={`${size}rem`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 14H18V11H10V6H8C7.45 6 6.97917 6.19583 6.5875 6.5875C6.19583 6.97917 6 7.45 6 8V14ZM12 20C12.3333 20 12.6667 19.9792 13 19.9375C13.3333 19.8958 13.6667 19.8333 14 19.75V16H10V19.75C10.3333 19.8333 10.6667 19.8958 11 19.9375C11.3333 19.9792 11.6667 20 12 20ZM12 22C10.6667 22 9.39167 21.7458 8.175 21.2375C6.95833 20.7292 5.875 20.0083 4.925 19.075L6.35 17.65C6.6 17.9 6.8625 18.1292 7.1375 18.3375C7.4125 18.5458 7.7 18.7417 8 18.925V16H6C5.45 16 4.97917 15.8042 4.5875 15.4125C4.19583 15.0208 4 14.55 4 14V8C4 6.9 4.39167 5.95833 5.175 5.175C5.95833 4.39167 6.9 4 8 4H12V9H18C18.55 9 19.0208 9.19583 19.4125 9.5875C19.8042 9.97917 20 10.45 20 11V14C20 14.55 19.8042 15.0208 19.4125 15.4125C19.0208 15.8042 18.55 16 18 16H16V18.925C16.3 18.7417 16.5875 18.5458 16.8625 18.3375C17.1375 18.1292 17.4 17.9 17.65 17.65L19.075 19.075C18.125 20.0083 17.0417 20.7292 15.825 21.2375C14.6083 21.7458 13.3333 22 12 22Z"
        fill={'currentColor'}
      />
    </svg>
  );
};

export default ChildbirthIcon;
