import React, { FunctionComponent } from 'react';
import { CurrencyTypeDict } from '../../../../@dicts/CurrencyTypeDict';
import StaticSelectInput from '../SelectInput/StaticSelectInput';

type Props = {
  name: string;
  required: boolean;
};

const CurrencyInput: FunctionComponent<Props> = (props) => {
  return <StaticSelectInput {...props} options={CurrencyTypeDict} label={'Currency'} />;
};

export default CurrencyInput;
