import React, { FunctionComponent } from 'react';
import { AbsenceType } from '../../../../../@types/Absences/AbsenceType';
import Button from '../../../../atoms/Button/Button';
import useApi from '../../../../../hooks/useApi';
import SnackContainer from '../../../../../legacy/CapeMorris/components/snack-bar/use-snack-container';
import { AxiosError } from 'axios';

type Props = {
  absence: AbsenceType;
};

const CancelButton: FunctionComponent<Props> = ({ absence }) => {
  const { post } = useApi();
  const { showSnack } = SnackContainer.useContainer();

  const cancelAbsence = () => {
    return post(absence['@id'].replace('absence_requests', 'requests') + '/cancel')
      .then(() => {
        showSnack({
          title: 'Success',
          message: 'Absence canceled successfully.',
          type: 'success',
        });
      })
      .catch((error: any) => {
        showSnack({
          title: 'Error',
          message: error.response?.data['hydra:description'] || error.message,
          type: 'error',
        });
      });
  };
  return <Button $text={'Cancel'} kind={'primary'} onClick={cancelAbsence} />;
};

export default CancelButton;
