import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { getCurrentWeekDays, getPrevOrNextWeekDays, getSelectedWeekDaysArray, WeekDaysType } from '../../../helpers/getDaysForWeekMethods';
import PageTop from '../../../components/molecules/Page/PageTop';
import Text from '../../../components/atoms/Text';
import Form from '../../../components/molecules/Form/Form';
import { DepartmentType } from '../../../@types/Department/DepartmentType';
import useUser from '../../../hooks/useUser';
import iriHelper from '../../../helpers/iri-helper';
import WorkloadActions from './components/WorkloadActions';
import api from '../../../services/api';
import EmployeeWorkloadCollapse from './components/EmployeeWorkloadCollapse/EmployeeWorkloadCollapse';
import { WorkloadType } from '../../../@types/Workload/WorkloadType';
import Days from './components/Days';
import * as Styled from './Workload.styled';
import { DictValue } from '../../../@types/Dictionary/DictValue';
import serverEvents from '../../../services/serverEvents';
import { MyWorkEntryType } from '../../../@types/MyWorkEntry/MyWorkEntryType';
import { Loader } from '../../../legacy/CapeMorris/components';

type Props = {};

const Workload: FunctionComponent<Props> = (props) => {
  const user = useUser();
  const currentWeekDays: WeekDaysType = useMemo(() => {
    return getCurrentWeekDays();
  }, []);

  const [selectedWeekDays, setSelectedWeekDays] = useState<WeekDaysType>(currentWeekDays);
  const [selectedWeekDaysArray, setSelectedWeekDaysArray] = useState<WeekDaysType>(getSelectedWeekDaysArray(currentWeekDays));
  const [departmentId, setDepartmentId] = useState<string | undefined>(user.department.id);
  const [workload, setWorkload] = useState<WorkloadType[]>([]);
  const [working, setWorking] = useState(false);

  const changeWeek = useCallback(
    (directionNumber: number) => {
      const weekDays = directionNumber === 0 ? getCurrentWeekDays() : getPrevOrNextWeekDays(selectedWeekDays, directionNumber);
      setSelectedWeekDays(weekDays);
      setSelectedWeekDaysArray(getSelectedWeekDaysArray(weekDays));
    },
    [selectedWeekDays, departmentId],
  );

  const onDepartmentSelect = useCallback((data: DictValue<DepartmentType> | undefined) => {
    if (!data || !data.meta) return;
    setDepartmentId(iriHelper.iriToId(data.meta['@id']));
  }, []);

  const loadWorkload = useCallback(() => {
    setWorking(true);
    api
      .get('/workload', {
        params: {
          department: departmentId ?? user.department.id,
          after: selectedWeekDaysArray[0].toISOString().slice(0, 10),
          before: selectedWeekDaysArray[selectedWeekDaysArray.length - 1].toISOString().slice(0, 10),
        },
      })
      .then((response) => {
        setWorkload(response.data);
      })
      .finally(() => {
        setWorking(false);
      });
  }, [departmentId, selectedWeekDaysArray]);

  useEffect(() => {
    loadWorkload();
  }, [departmentId, selectedWeekDaysArray]);

  useEffect(() => {
    const subscriber = serverEvents.listen<MyWorkEntryType>('/api/tasks').subscribe(() => {
      loadWorkload();
    });
    return () => {
      subscriber.unsubscribe();
    };
  }, [selectedWeekDays, departmentId]);

  return (
    <>
      <Form onSubmit={() => {}}>
        <PageTop
          heading={'Workload'}
          rightColumn={<WorkloadActions onDepartmentSelect={onDepartmentSelect} onChangeWeek={changeWeek} placeholder={user.department.name} />}
        />
      </Form>
      <Styled.Wrapper>
        <Text color={'grey'}>Person</Text>
        <Days days={selectedWeekDaysArray} />
      </Styled.Wrapper>
      <Styled.CollapseWrapper style={{ position: 'relative' }}>
        {working && <Loader />}
        {workload.length > 0 && workload.map((workloadItem: WorkloadType, idx: number) => <EmployeeWorkloadCollapse workloadItem={workloadItem} key={idx} />)}
      </Styled.CollapseWrapper>
    </>
  );
};

export default Workload;
