import { CellTemplate } from '../../CellTemplate';
import React from 'react';
import { TaskPriorityType, TaskType } from '../../../../../@types/Task/TaskType';
import PriorityTag from '../../../PriorityTag/PriorityTag';
import EditableDetail from '../../../EditableDetail/EditableDetail';
import TaskPriorityDropdown from '../../../Task/TaskForm/TaskPriorityDropdown/TaskPriorityDropdown';
import { color } from '../../../../../styles/Variables';
import Chip from '../../../../atoms/Chips/Chip';

const TaskPriorityTemplate: CellTemplate<TaskPriorityType, any, TaskType> = ({ value, item }) => {
  return <EditableDetail name={'priority'} subject={item} show={<PriorityTag priority={item.priority} />} component={TaskPriorityDropdown} />;
};
export default TaskPriorityTemplate;
