import React, { FunctionComponent } from 'react';
import SelectInput from '../../../../components/molecules/Form/SelectInput/SelectInput';
import { DepartmentType } from '../../../../@types/Department/DepartmentType';
import { DictValue } from '../../../../@types/Dictionary/DictValue';

type Props = {
  onDepartmentSelect: (data: DictValue<DepartmentType>) => void;
  placeholder: string;
};

const DepartmentSelect: FunctionComponent<Props> = ({ onDepartmentSelect, placeholder }) => {
  return <SelectInput name={'department'} label={'Department'} baseUrl={'/departments'} onChange={onDepartmentSelect} placeholder={placeholder} required />;
};

export default DepartmentSelect;
