import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PurchaseOrderSubject, PurchaseOrderType } from '../../../../@types/Finances/PurchaseOrder/PurchaseOrderType';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import PageTop from '../../../molecules/Page/PageTop';
import Form from '../../../molecules/Form/Form';
import useApi from '../../../../hooks/useApi';
import Button from '../../../atoms/Button/Button';
import EditIcon from '../../../../Icons/edit.icon';
import AcceptIcon from '../../../../Icons/Accept.icon';
import CardHeading from '../../../molecules/CardHeading/CardHeading';
import { Card } from '../../../atoms/Card/Card';
import Select from '../../../molecules/Form/SelectInput/SelectInput';
import { TextInput } from '../../../molecules/Form/TextInput/TextInput';
import RichTextInput from '../../../molecules/Form/RichTextInput/RichTextInput';
import MoneyInput from '../../../molecules/Form/MoneyInput/MoneyInput';
import { BillingTypeDict } from '../../../../@dicts/PurchaseOrder/BillingTypeDict';
import DatePicker from '../../../molecules/Form/DatePicker/DatePicker';
import PaymentTermsDict from '../../../../@dicts/PurchaseOrder/PaymentTermsDict';
import Text from '../../../atoms/Text';
import { TextAreaInput } from '../../../molecules/Form/TextAreaInput/TextAreaInput';
import ResourceDropzone from '../../../organisms/Resources/ResourceDropzone';
import { ResourceType } from '../../../../@types/Resource/ResourceType';
import ProjectOption from '../../../molecules/Projects/ProjectOption/ProjectOption';
import CurrencyInput from '../../../molecules/Form/CurrencyInput/CurrencyInput';
import costTypeOption from '../../../molecules/Finances/CostTypeOption';
import StaticSelectInput from '../../../molecules/Form/SelectInput/StaticSelectInput';
import { CostKindDict } from '../../../../@dicts/Cost/CostKind';
import { SidebarContext } from '../../../../Context/Sidebar/SidebarContext';
import ProjectLabel from '../../../molecules/Projects/ProjectLabel/ProjectLabel';
import api from '../../../../services/api';
import SingleDetail from '../../../molecules/SingleDetail/SingleDetail';
import ContractorOption from '../../../molecules/Finances/ContractorOption';
import FinanceColumn from '../../../molecules/Finances/FinanceColumn';
import { Helmet } from 'react-helmet';
import translateDictionary from '../../../../helpers/translateDictionary';
import { ContractTypeDict } from '../../../../@dicts/Contract/ContractTypeDict';
import { ContractorType } from '../../../../@types/Finances/Contractor/ContractorType';
import BetterChip from '../../../atoms/Chips/BetterChip';
import { color } from '../../../../styles/Variables';

type Props = {
  purchaseOrder?: PurchaseOrderType;
  onChange?: () => void;
};

const defaults = {
  currency: 'PLN',
};

const PurchaseOrderForm: FunctionComponent<Props> = ({ purchaseOrder, onChange }) => {
  const [loading, setLoading] = useState(false);
  const [uploadedResources, setUploadedResources] = useState<ResourceType[]>([]);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { post, put } = useApi();
  const { isOpen, closeSidebar } = useContext(SidebarContext);

  const pageHeading = useMemo(() => {
    if (!purchaseOrder) {
      return 'Add new purchase order';
    } else {
      return `Edit purchase order #${purchaseOrder.id}`;
    }
  }, [purchaseOrder]);

  const handleSubmit = (data: PurchaseOrderType) => {
    closeSidebar();
    setLoading(true);
    data.uploadedResources = uploadedResources.map((resource) => resource.id);

    if (!purchaseOrder) {
      post('/api/purchase_orders', data)
        .then((response) => {
          navigate(pathname.replace('create', `${response.data.id}/show`));
          setLoading(false);
        })
        .catch(() => setLoading(false));
    } else {
      put(`${purchaseOrder['@id']}`, data)
        .then(() => {
          navigate(pathname.replace('edit', 'show'));
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  };

  const PageActions = () => {
    return (
      <Grid spacing={2} $align={'center'}>
        <GridItem $desktop={'auto'}>
          <Button
            $text={loading ? 'Saving...' : 'Save purchase order'}
            waiting={loading}
            kind={'primary'}
            type={'submit'}
            $icon={purchaseOrder ? <EditIcon /> : <AcceptIcon />}
          />
        </GridItem>
        <GridItem $desktop={'auto'}>
          <Button
            $text={'Cancel'}
            kind={'secondary'}
            onClick={() => {
              navigate(-1);
              closeSidebar();
            }}
          />
        </GridItem>
      </Grid>
    );
  };

  return (
    <Form onSubmit={(data) => handleSubmit(data)} defaultValues={purchaseOrder ?? defaults}>
      {({ watch, setValue }) => {
        const currencyValue = watch('currency');
        const paymentTermsValue = watch('paymentTerms');
        const costKind = watch('kind');
        const contractor = watch('contractor');
        const [contractorDetails, setContractorDetails] = useState<ContractorType | undefined>();
        const [financialCompany, setFinancialCompany] = useState(null);
        const [branch, setBranch] = useState(null);
        const [financialCompanyKey, setFinancialCompanyKey] = useState(0);
        const [branchKey, setBranchKey] = useState(0);
        const [contractorComment, setContractorComment] = useState<string | null>(null);
        const getFinancialCompany = useCallback(() => {
          api.get('/api/financial_companies/1').then((response) => {
            setFinancialCompany(response.data);
          });
        }, []);

        const getBranch = useCallback(() => {
          api.get('/api/branches/1').then((response) => {
            setBranch(response.data);
          });
        }, []);
        useEffect(() => {
          getFinancialCompany();
          getBranch();
        }, []);
        useEffect(() => {
          if (branch && !purchaseOrder) {
            setValue('location', branch);
            setTimeout(() => {
              setBranchKey(Math.random());
            }, 200);
          }
        }, [branch, purchaseOrder]);

        useEffect(() => {
          if (financialCompany && !purchaseOrder) {
            setValue('company', financialCompany);
            setTimeout(() => {
              setFinancialCompanyKey(Math.random());
            }, 200);
          }
        }, [financialCompany, purchaseOrder]);

        useEffect(() => {
          if (!contractor) {
            return;
          }
          let url = typeof contractor === 'string' ? contractor : contractor['@id'];
          api.get<ContractorType>(url).then((response) => {
            setContractorDetails(response.data);
          });
        }, [contractor]);

        return (
          <Grid spacing={4} style={{ marginBottom: '2rem' }}>
            <Helmet>
              <title>{`${purchaseOrder ? 'Edit purchase order' : 'Create purchase order'} | F.CAPE`}</title>
            </Helmet>
            <GridItem $desktop={12}>
              <PageTop heading={pageHeading} rightColumn={<PageActions />} paddingBottom={'0'} isPdfOpen={isOpen} />
            </GridItem>

            <FinanceColumn>
              <GridItem $desktop={12}>
                <Card>
                  <CardHeading heading={'Basic information'} />
                  <Grid spacing={4}>
                    <GridItem $desktop={6}>
                      <Select name={'company'} label={'Company'} baseUrl={'/api/financial_companies/'} required key={financialCompanyKey} />
                    </GridItem>
                    <GridItem $desktop={6}>
                      <Select name={'location'} label={'Location'} optionLabel={'shortName'} baseUrl={'/api/branches/'} required key={branchKey} />
                    </GridItem>
                    <GridItem $desktop={6}>
                      <Select
                        name={'project'}
                        label={'Project'}
                        baseUrl={'/api/projects/'}
                        baseUrlParams={{
                          status: ['active'],
                          archived: 0,
                        }}
                        optionComponent={ProjectOption}
                        singleValueComponent={ProjectLabel}
                      />
                    </GridItem>
                    <GridItem $desktop={6}>
                      <Select
                        name={'contractor'}
                        label={'Vendor'}
                        baseUrl={'/api/contractors/'}
                        required
                        onChange={(option) => {
                          setContractorComment(option.meta.note);
                        }}
                        optionComponent={ContractorOption}
                      />
                    </GridItem>
                    <GridItem $desktop={12}>
                      <Grid spacing={2} style={{ background: color.neutral['20'], borderRadius: 8, paddingBottom: '2rem' }}>
                        <SingleDetail title={'Vendor comment'} $desktop={6}>
                          {contractor ? contractorComment : 'Contractor not yet selected'}
                        </SingleDetail>
                        <SingleDetail $desktop={6} title={'Contracts (click to view)'}>
                          <div style={{ display: 'flex', marginTop: '0.7rem' }}>
                            {contractorDetails?.contractTags?.map((contractType) => (
                              <Link
                                style={{ marginRight: '0.6rem' }}
                                key={contractType}
                                to={`/finances/contracts?contractor.id=${contractorDetails.id}&type=${contractType}`}
                              >
                                <BetterChip>{translateDictionary(ContractTypeDict, contractType)}</BetterChip>
                              </Link>
                            ))}
                          </div>
                        </SingleDetail>
                      </Grid>
                    </GridItem>
                    <GridItem $desktop={12}>
                      <TextInput name={'title'} label={'Title'} required />
                    </GridItem>
                    <GridItem $desktop={12}>
                      <TextAreaInput
                        name={'description'}
                        label={'Description'}
                        placeholder={
                          'What is the order about, what are the specifications, what are the timings, etc. All the most important information that we agree with the supplier.'
                        }
                        required
                        rows={3}
                      />
                    </GridItem>
                    <GridItem $desktop={12}>
                      <TextAreaInput
                        name={'acceptanceTerms'}
                        label={'Acceptance terms'}
                        placeholder={'What must happen so that we can consider the task completed and accept the invoice.'}
                        required
                        rows={3}
                      />
                    </GridItem>
                    <GridItem $desktop={6}>
                      <MoneyInput name={'originalAmountNet'} label={'Amount'} currency={currencyValue} required />
                    </GridItem>
                    <GridItem $desktop={6}>
                      <CurrencyInput name={'currency'} required />
                    </GridItem>
                    <GridItem $desktop={6}>
                      <StaticSelectInput name={'kind'} options={CostKindDict} label={'Cost kind'} required />
                    </GridItem>
                    <GridItem $desktop={6}>
                      {costKind && (
                        <Select
                          key={costKind}
                          name={'costType'}
                          label={'Cost type'}
                          baseUrl={'/api/cost_types/'}
                          baseUrlParams={{ kind: costKind }}
                          optionLabel={'label'}
                          required
                          optionComponent={costTypeOption}
                        />
                      )}
                    </GridItem>
                    <GridItem $desktop={6}>
                      <StaticSelectInput name={'billingType'} label={'Billing type'} options={BillingTypeDict} required />
                    </GridItem>
                    <GridItem $desktop={6}>
                      <TextInput name={'budgetSymbol'} label={'Budget symbol'} />
                    </GridItem>
                    <GridItem $desktop={6}>
                      <DatePicker name={'deliveryDate'} label={'Delivery date'} required />
                    </GridItem>
                    <GridItem $desktop={12}>
                      <StaticSelectInput name={'paymentTerms'} options={PaymentTermsDict} label={'Payment terms'} required />
                      <Text size={'xs'} style={{ marginTop: '.8rem' }}>
                        Remember to secure appropriate payment terms from our Partner to cover payment terms for this PO - required for
                        &gt;&nbsp;10'000&nbsp;PLN&nbsp;gross.{' '}
                        <a
                          style={{ textDecoration: 'underline' }}
                          target="_blank"
                          href="https://capemorriscom.sharepoint.com/:b:/s/cape-morris/EWPiNBL1v4xPl1m9glyGl2MBpHVexpYyld5fzUmbmuJ4HA?e=uXVGMP"
                        >
                          More in our handbook
                        </a>
                        .
                      </Text>
                    </GridItem>
                    {paymentTermsValue === 'special' && (
                      <>
                        <GridItem $desktop={12}>
                          <TextAreaInput name={'specialPaymentTermsDetails'} label={'Requested payment terms'} rows={3} required />
                        </GridItem>
                        <GridItem $desktop={12}>
                          <TextAreaInput name={'specialPaymentTermsReason'} label={'Request reason'} rows={3} required />
                        </GridItem>
                      </>
                    )}
                  </Grid>
                </Card>
              </GridItem>
            </FinanceColumn>
            {isOpen && <GridItem $desktop={12} />}
            <FinanceColumn>
              <GridItem $desktop={12}>
                <Card>
                  <CardHeading heading={'Documents'} />
                  <Grid spacing={4}>
                    <ResourceDropzone
                      subject={PurchaseOrderSubject}
                      subjectId={purchaseOrder ? purchaseOrder.id : undefined}
                      context={'finances'}
                      onChange={(data) => setUploadedResources(data)}
                    />
                  </Grid>
                </Card>
              </GridItem>
              <GridItem $desktop={12}>
                <Card>
                  <CardHeading heading={'Internal comment'} />
                  <RichTextInput name="internalComment" label={''} placeholder={'Internal comment'} defaultValue={''} />
                </Card>
              </GridItem>
            </FinanceColumn>
          </Grid>
        );
      }}
    </Form>
  );
};

export default PurchaseOrderForm;
