import React from 'react';
import { TableFilterInterface } from '../TableFilterInterface';
import DateRangePicker from '../../Form/DatePicker/DateRangePicker';

const DateRangeFilter: TableFilterInterface = ({ name, filter, onChange, defaultValue }) => {
  return (
    <DateRangePicker
      name={name}
      onChange={onChange}
      defaultValue={defaultValue}
      label={filter.label ?? name}
      placeholder={filter.placeholder}
      nameStart={`${name}[after]`}
      nameEnd={`${name}[before]`}
    />
  );
};

export default DateRangeFilter;
