import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { BasicProjectType } from '../../../../../../../../../@types/Project/Project';
import useApi from '../../../../../../../../../hooks/useApi';
import useKeyboard from '../../../../../../../../../hooks/useKeyboard';
import useOutsideClick from '../../../../../../../../../hooks/useOutsideClick';
import { SectionType } from '../../../../../../../../../@types/Section/SectionType';
import Form from '../../../../../../../../molecules/Form/Form';
import SectionInlineInput from './SectionInlineInput';

type Props = {
  project?: BasicProjectType;
  section?: Partial<SectionType>;
  handleEnabled?: (enabled: boolean) => void;
  sectionsCount?: number;
};

const SectionInlineForm: FunctionComponent<Props> = ({ project, section, handleEnabled, sectionsCount }) => {
  const { post, put } = useApi();
  const [enabled, setEnabled] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useOutsideClick(containerRef, () => setEnabled(false));

  const onEscPressed = useCallback(() => {
    setEnabled(false);
  }, [false]);

  useKeyboard('Escape', onEscPressed);

  useEffect(() => {
    if (handleEnabled) {
      handleEnabled(enabled);
    }
  }, [enabled]);

  const handleSubmit = useCallback(
    ({ name }: { name: string }) => {
      if (!enabled) return;
      const isEdit = !!section;

      if (isEdit) {
        put(`/api/sections/${section?.id}`, { name }).then(() => {
          setEnabled(false);
        });
      } else {
        post('/api/sections', { name, project: project?.['@id'], position: sectionsCount ? sectionsCount + 1 : 0 }).then(() => {
          setEnabled(false);
        });
      }
    },
    [project, section, sectionsCount, enabled],
  );

  return (
    <div ref={containerRef} style={{ width: '100%', marginBottom: '5rem' }}>
      <Form onSubmit={handleSubmit}>
        <SectionInlineInput onEnabledChange={setEnabled} defaultValue={section?.name} enabled={enabled} largeFont={!!section} />
      </Form>
    </div>
  );
};

export default SectionInlineForm;
