import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
`;

export const List = styled.div<{ $maxWidth: boolean }>`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  ${(props) =>
    props.$maxWidth &&
    css`
      display: grid;
      grid-template-columns: 1fr 1fr;

      .resource-card {
        width: 27.8rem;
      }
    `}
`;
