import React, { FunctionComponent } from 'react';

type PropType = {
  size?: number;
  color?: string;
};

const MilestoneIcon: FunctionComponent<PropType> = ({ size = 1, color }) => {
  return (
    <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_3429_22252" maskUnits="userSpaceOnUse" x="6" y="6" width="20" height="21">
        <rect x="6" y="6.5" width="20" height="20" fill={color} />
      </mask>
      <path
        d="M16.0001 23.9792C15.8046 23.9792 15.6329 23.9485 15.4849 23.8871C15.3369 23.8257 15.1946 23.7239 15.0578 23.5818L8.91843 17.4424C8.77634 17.3057 8.67457 17.1633 8.61314 17.0153C8.55171 16.8674 8.521 16.6956 8.521 16.5001C8.521 16.3046 8.55171 16.1342 8.61314 15.9889C8.67457 15.8436 8.77634 15.6999 8.91843 15.5578L15.0578 9.41843C15.1946 9.28168 15.3369 9.18125 15.4849 9.11714C15.6329 9.05304 15.8046 9.021 16.0001 9.021C16.1956 9.021 16.366 9.05304 16.5113 9.11714C16.6566 9.18125 16.8003 9.28168 16.9424 9.41843L23.0818 15.5578C23.2186 15.6999 23.319 15.8436 23.3831 15.9889C23.4472 16.1342 23.4792 16.3046 23.4792 16.5001C23.4792 16.6956 23.4472 16.8674 23.3831 17.0153C23.319 17.1633 23.2186 17.3057 23.0818 17.4424L16.9424 23.5818C16.8003 23.7239 16.6566 23.8257 16.5113 23.8871C16.366 23.9485 16.1956 23.9792 16.0001 23.9792ZM16.1844 22.8158L22.3158 16.6844C22.3585 16.6417 22.3799 16.5803 22.3799 16.5001C22.3799 16.42 22.3585 16.3585 22.3158 16.3158L16.1844 10.1844C16.1417 10.1417 16.0803 10.1203 16.0001 10.1203C15.92 10.1203 15.8585 10.1417 15.8158 10.1844L9.68443 16.3158C9.6417 16.3585 9.62033 16.42 9.62033 16.5001C9.62033 16.5803 9.6417 16.6417 9.68443 16.6844L15.8158 22.8158C15.8585 22.8585 15.92 22.8799 16.0001 22.8799C16.0803 22.8799 16.1417 22.8585 16.1844 22.8158Z"
        fill={color}
      />
      <rect x="0.5" y="1" width="31" height="31" rx="15.5" stroke="#24A340" />
    </svg>
  );
};

export default MilestoneIcon;
