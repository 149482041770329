import styled, { css } from 'styled-components';

type PeopleDimensions = {
  $width: number;
  $height: number;
};

export const Attendees = styled.div<PeopleDimensions>`
  display: flex;
  position: relative;
  flex-wrap: wrap;

  ${(props: PeopleDimensions) =>
    props.$width &&
    props.$height &&
    css`
      width: 20rem;
      height: ${props.$height}rem;
    `}
`;
