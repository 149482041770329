import { DictValue } from '../@types/Dictionary/DictValue';

export const CurrencyTypeDict: DictValue[] = [
  {
    value: 'PLN',
    label: 'PLN',
  },
  {
    value: 'EUR',
    label: 'EUR',
  },
  {
    value: 'USD',
    label: 'USD',
  },
  {
    value: 'GBP',
    label: 'GBP',
  },
];
