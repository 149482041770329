import styled from 'styled-components';
import { color } from 'styles/Variables';
import { sidebarBg, sidebarPadding } from '../TaskSidebar.styled';

export const Bar = styled.div`
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;

  right: 0;
  z-index: 1;
  padding: 1rem;
  border-bottom: 1px solid ${color.neutral['40']};
  ${sidebarPadding};
  ${sidebarBg};
`;
