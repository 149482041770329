import React, { FunctionComponent } from 'react';
import { DictValue } from '../../../../@types/Dictionary/DictValue';
import { InputProps } from '../../../../@types/Form/InputProps';
import { Controller, useFormContext } from 'react-hook-form';
import Select, { SingleValue } from 'react-select';
import { selectStyles } from './select.styles';
import useFormError from '../../../../hooks/Form/useFormError';
import { FormInputContainer, FormLabel } from '../../../atoms/Form/InputParts/InputParts';
import FormError from '../../../atoms/Form/FormError/FormError';

type Props = InputProps & {
  options: DictValue[];
};

const StaticSelectInput: FunctionComponent<Props> = (props) => {
  const { control } = useFormContext();
  const error = useFormError(props.name);
  return (
    <>
      <FormInputContainer>
        <FormLabel status={error && 'error'} required={props.required} disabled={props.disabled}>
          {props.label}
        </FormLabel>
        <Controller
          control={control}
          defaultValue={props.defaultValue}
          name={props.name}
          rules={{
            required: props.required ? 'this field is required' : undefined,
          }}
          render={({ field: { onChange, value } }) => (
            <Select
              value={props.options.find((o) => o.value === value)}
              options={props.options}
              onChange={(option: SingleValue<DictValue>) => {
                if (option) {
                  onChange(option.value);
                } else {
                  onChange(null);
                }
              }}
              required={props.required}
              isDisabled={props.disabled}
              isMulti={false}
              placeholder={props.placeholder}
              components={{ IndicatorSeparator: () => null }}
              noOptionsMessage={() => 'Start typing to search...'}
              isClearable={!props.required}
              styles={selectStyles(!!error, props.required, props.disabled)}
            />
          )}
        ></Controller>
      </FormInputContainer>
      <FormError name={props.name} />
    </>
  );
};

export default StaticSelectInput;
