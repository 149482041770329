import React, { FunctionComponent, useCallback, useContext } from 'react';
import { AuthContext } from '../../../../Context/auth-context/auth-context';
import { properties } from './MyProjects.properties';
import SnackContainer from '../../../../legacy/CapeMorris/components/snack-bar/use-snack-container';
import api from '../../../../services/api';
import filters from './MyProjects.filters';
import { useNavigate } from 'react-router-dom';
import { ProjectListItemType } from '../../../../@types/Project/Project';
import { Grid, GridItem } from '../../../../components/atoms/Grid/Grid';
import Button from '../../../../components/atoms/Button/Button';
import TablePage from '../../../../components/organisms/TablePage/TablePage';
import useTableReload from '../../../../hooks/Table/useTableReload';
import useProjectForm from '../../../../hooks/useProjectForm';
import { Icon } from '../../../../components/atoms/Icon/Icon';
import PlusIcon from '../../../../Icons/Plus.icon';
import useUser from '../../../../hooks/useUser';

type Props = {};

const MyProjects: FunctionComponent<Props> = (props) => {
  const user = useUser();
  const { can } = useContext(AuthContext);
  const [waitingForReport, setWaitingForReport] = React.useState(false);
  const { reload, key } = useTableReload();
  const navigate = useNavigate();
  const { showSnack } = SnackContainer.useContainer();

  const { show } = useProjectForm();

  const handleReportClick = React.useCallback(() => {
    setWaitingForReport(true);
    api
      .post('/run-report/project-finances')
      .then(() => {
        setWaitingForReport(false);
        reload();
        showSnack({
          title: 'Success',
          message: 'Finances report updated successfully.',
          type: 'success',
        });
      })
      .catch(() => {
        setWaitingForReport(false);
        showSnack({
          title: 'Error',
          message: 'Unexpected error occured',
          type: 'error',
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSnack, reload]);

  const isAdmin = React.useMemo(() => {
    return can('ROLE_SUPER_ADMIN');
  }, [can]);

  const sendDataLayerInfo = useCallback(() => {
    if (!window || !document) return;
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      event: 'go_to_project',
      element: 'my_projects_view',
      pageTitle: document.title,
      userDepartment: user?.department?.name,
    });
  }, [window, document]);

  const handleItemClick = React.useCallback(() => {
    sendDataLayerInfo();
  }, [navigate]);

  return (
    <TablePage<ProjectListItemType>
      onItemClick={handleItemClick}
      itemLink={(i) => `/projects/projects/${i.id}`}
      url={'/my-projects'}
      context={'/api/projects'}
      filters={filters}
      properties={properties}
      defaultFilters={{
        status: 'active',
        archived: 0,
        leader__id: ['/api/employees/' + user.employeeId],
      }}
      reloadKey={key}
      heading="My projects"
      exportUrl={'/my-projects/export'}
      headingRight={
        <Grid spacing={2} $align={'center'}>
          {isAdmin && (
            <GridItem $desktop={'auto'}>
              <Grid spacing={1.6} $align={'end'} $justifyContent={'end'}>
                <GridItem>
                  <Button $text={waitingForReport ? 'Please wait...' : 'Run report'} onClick={handleReportClick} kind={'secondary'} />
                </GridItem>
                <GridItem>
                  <Button
                    id={'open-project-form'}
                    $text={'Add new project'}
                    onClick={() => show()}
                    $icon={
                      <Icon>
                        <PlusIcon />
                      </Icon>
                    }
                  />
                </GridItem>
              </Grid>
            </GridItem>
          )}
        </Grid>
      }
    />
  );
};

export default MyProjects;
