import React, { ReactElement, useCallback } from 'react';
import { TableRowActionType } from '../../../../@types/Table/TableRowActionType';
import * as Styled from './TableAction.styled';
import { EntityType } from '../../../../@types/Entity/EntityType';

type Props<T extends EntityType> = TableRowActionType & {
  item: T;
};

const TableAction = <T extends EntityType>({ item, ...props }: Props<T>): ReactElement => {
  const handleClick = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    props.onClick(props.name, item);
  }, []);
  return (
    <Styled.TableActionContainer title={typeof props.label === 'string' ? props.label : props.label(item)} onClick={handleClick}>
      {props.icon}
    </Styled.TableActionContainer>
  );
};

export default TableAction;
