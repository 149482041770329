import { DictValue } from '../../@types/Dictionary/DictValue';

export const VatDict: DictValue[] = [
  {
    value: -1,
    label: 'ZW',
  },
  {
    value: 0,
    label: '0%',
  },
  {
    value: 0.08,
    label: '8%',
  },
  {
    value: 0.19,
    label: '19%',
  },
  {
    value: 0.23,
    label: '23%',
  },
];
