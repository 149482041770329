import React, { FunctionComponent } from 'react';
import DashboardCard from '../DashboardCard';
import { color } from '../../../../../../styles/Variables';
import FireIcon from '../../../../../../Icons/Fire.icon';
import { FeedEntryType } from '../../../../../../@types/FeedEntry/FeedEntryType';
import HotNewsCardItem from '../../../../../molecules/Projects/Dashboard/DashboardCardItems/HotNewsCardItem';
import Button from '../../../../../atoms/Button/Button';
import { useNavigate } from 'react-router-dom';
import { Icon } from '../../../../../atoms/Icon/Icon';

type Props = {
  feedEntry?: FeedEntryType;
};

const HotNews: FunctionComponent<Props> = ({ feedEntry }) => {
  const navigate = useNavigate();

  return (
    <DashboardCard
      title={'Hot news'}
      icon={
        <Icon color={color.primary['60']} size={2.3}>
          <FireIcon />
        </Icon>
      }
      action={<Button $text={'Show all news'} kind={'link'} onClick={() => navigate('/projects/feed')} />}
    >
      <HotNewsCardItem feedEntry={feedEntry} />
    </DashboardCard>
  );
};

export default HotNews;
