import { components, OptionProps } from 'react-select';
import { Grid, GridItem } from '../../../../../atoms/Grid/Grid';
import React, { FunctionComponent } from 'react';
import { fontWeight } from '../../../../../../styles/Variables';
import Text from '../../../../../atoms/Text';
import { TimesheetDayType } from '../../../../../../@types/TimesheetDay/TimesheetDay';
import { parseDate } from '../../../../../../helpers/date';
import Chip from '../../../../../atoms/Chips/Chip';

type Props = OptionProps<{ value: string; label: string; meta: TimesheetDayType }, false>;
const TimesheetDayOption: FunctionComponent<Props> = (props) => {
  return (
    <components.Option {...props}>
      <Grid style={{ fontWeight: fontWeight.regular, pointerEvents: 'none' }} spacing={3}>
        <GridItem>
          <Text bold color={!props.data.meta.closed ? 'primary' : 'grey'}>
            {parseDate(props.data.meta.day)}
          </Text>
        </GridItem>
        <GridItem>{props.data.meta.closed && <Chip>Closed</Chip>}</GridItem>
      </Grid>
    </components.Option>
  );
};
export default TimesheetDayOption;
