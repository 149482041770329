import React, { FunctionComponent, useMemo } from 'react';
import * as Styled from './TaskCell.styled';
import { TaskListType } from '../../../../../@types/Task/TaskType';
import { useTextShade } from '../../../../../helpers/useTextShade';
import Text from '../../../../atoms/Text';
import useTaskSidebar from '../../../../../hooks/useTaskSidebar';
import { convertMinutesToHours } from '../../../../../helpers/convertMinutesToHours';
import PriorityTag from '../../../../molecules/PriorityTag/PriorityTag';
import { parseDate } from '../../../../../helpers/date';
import { adjustColorBrightness, color } from '../../../../../styles/Variables';
import { Draggable } from '@hello-pangea/dnd';
import TaskStatus from '../../../../molecules/Statuses/TaskStatus/TaskStatus';
import Tooltip from '../../../../molecules/Tooltip/Tooltip';
import TaskEstimationDropdown from '../../../../molecules/Task/TaskForm/TaskEstimationDropdown/TaskEstimationDropdown';
import EditableDetail from '../../../../molecules/EditableDetail/EditableDetail';
import { TaskEntryType } from '../../../../../@types/Task/TaskEntryType';
import BetterChip from '../../../../atoms/Chips/BetterChip';
import { PiFire, PiLeaf } from 'react-icons/pi';

type Props = {
  index: number;
  draggableId: string;
  task: TaskListType;
  entry?: TaskEntryType;
  onChange?: () => void;
  duration?: number;
  context: 'task' | 'entry';
};

const TaskCell: FunctionComponent<Props> = ({ task, index, onChange, duration, draggableId, entry, context }) => {
  const textShade = useTextShade(task.client?.color);
  const { openTask } = useTaskSidebar();

  const isTaskFinished = useMemo(() => {
    return task.status !== 'in_progress' && task.status !== 'todo';
  }, [task, onChange]);

  const isOverdue = () => {
    if (!task.deadline) {
      return false;
    }
    if (isTaskFinished) {
      return false;
    }
    const deadline = new Date(task.deadline);
    const today = new Date();
    return deadline < today;
  };

  return (
    <Draggable draggableId={draggableId} index={index}>
      {(provided, snapshot) => (
        <Styled.TaskCell
          $background={
            isTaskFinished
              ? adjustColorBrightness(color.neutral[50], 80)
              : task.client?.color
                ? adjustColorBrightness(task.client.color, 80)
                : adjustColorBrightness(color.primary[40], 80)
          }
          onClick={() => openTask(task['@id'], onChange)}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.3rem',
              justifyContent: 'space-between',
            }}
            title={task.title}
          >
            {isTaskFinished && (
              <div>
                <TaskStatus status={task.status} size={'xs'} />
              </div>
            )}
            <div style={{ display: 'flex', gap: '0.3rem' }}>
              {task.priority !== 'Normal' && <PriorityTag priority={task.priority} size={'xs'} />}
              {(duration || task.estimate) && (
                <div onClick={(e) => e.stopPropagation()}>
                  {context === 'entry' && entry ? (
                    <EditableDetail<TaskEntryType>
                      name={'duration'}
                      subject={entry}
                      show={
                        <BetterChip>
                          <Text size={'xs'}>{convertMinutesToHours(duration ? duration : task.estimatePerDay)}</Text>
                        </BetterChip>
                      }
                      component={TaskEstimationDropdown}
                    />
                  ) : (
                    <BetterChip>
                      <Text size={'xs'}>
                        {task.estimate === task.remainingDuration
                          ? convertMinutesToHours(task.estimate)
                          : `${convertMinutesToHours(task.remainingDuration)} / ${convertMinutesToHours(+task.estimate)}`}
                      </Text>
                    </BetterChip>
                  )}
                </div>
              )}
            </div>
          </div>
          <Styled.Title bold color={textShade === 'light' ? 'light' : 'dark'} size={'s'}>
            {task.title}
          </Styled.Title>
          {task.project && (
            <div style={{ flexGrow: 3 }}>
              <Text
                color={textShade === 'light' ? 'light' : 'dark'}
                size={'xs'}
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                <strong>#{task.project.id}</strong> {task.project.name}
              </Text>
            </div>
          )}
          <div style={{ display: 'flex', gap: '0.3rem', width: '100%' }}>
            {task.expectedStart && parseDate(task.expectedStart) !== parseDate(task.createdAt) && (
              <Tooltip
                content={'This date indicates when task can be started'}
                trigger={
                  <BetterChip icon={PiLeaf}>
                    <Text size={'xs'}>{parseDate(task.expectedStart)}</Text>
                  </BetterChip>
                }
                arrowPosition={'topLeft'}
              />
            )}
            {task.deadline && (
              <Tooltip
                content={'This date indicates deadline'}
                trigger={
                  <BetterChip color={isOverdue() ? 'error' : undefined} icon={PiFire} $rounded>
                    <Text size={'xs'}>{parseDate(task.deadline)}</Text>
                  </BetterChip>
                }
                arrowPosition={'topLeft'}
              />
            )}
          </div>
        </Styled.TaskCell>
      )}
    </Draggable>
  );
};

export default TaskCell;
