import React, { FunctionComponent } from 'react';
import { TextInput } from '../../../../molecules/Form/TextInput/TextInput';
import { GridItem } from '../../../../atoms/Grid/Grid';
import StaticSelectInput from '../../../../molecules/Form/SelectInput/StaticSelectInput';

const BankAccountDataForm: FunctionComponent = () => {
  return (
    <>
      <GridItem $desktop={6}>
        <TextInput name={'bank.name'} label={'Name'} placeholder={'Central Bank'} />
      </GridItem>
      <GridItem $desktop={6}>
        <StaticSelectInput
          name={'bank.currency'}
          label={'Currency'}
          options={[
            { value: 'PLN', label: 'PLN' },
            { value: 'EUR', label: 'EUR' },
            { value: 'GBP', label: 'GBP' },
            { value: 'USD ', label: 'USD' },
          ]}
        />
      </GridItem>
      <GridItem $desktop={6}>
        <TextInput name={'bank.number'} label={'Number'} placeholder={'PL17958411065867915407528914'} />
      </GridItem>
      <GridItem $desktop={6}>
        <TextInput name={'bank.swift'} label={'SWIFT'} placeholder={'PL000000000'} />
      </GridItem>
    </>
  );
};

export default BankAccountDataForm;
