import styled, { css } from 'styled-components';
import { Cell } from './Common';
import { color } from '../../../../../styles/Variables';

export const DayEvent = styled(Cell)<{ $areConflicts?: boolean }>`
  height: 100%;
  z-index: 3;
  background-color: ${color.neutral[40]};

  ${({ $areConflicts }) =>
    $areConflicts &&
    css`
      position: absolute;
    `}
`;
