import React, { FunctionComponent, useCallback } from 'react';
import TablePage from '../../../organisms/TablePage/TablePage';
import properties from './EmployeesList.properties';
import { useNavigate, useLocation } from 'react-router-dom';
import { TableRowActionsType } from '../../../../@types/Table/TableRowActionType';
import EditIcon from '../../../../Icons/edit.icon';
import { EmployeeType } from '../../../../@types/Employee/EmployeeType';
import filters from './EmployeesList.filters';
import { AuthContext } from '../../../../Context/auth-context/auth-context';

type Props = {};

const EmployeesList: FunctionComponent<Props> = (props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { can } = React.useContext(AuthContext);

  const handleCreate = useCallback(() => {
    navigate(`${pathname}/create`);
  }, [pathname, navigate]);

  const actions: TableRowActionsType<EmployeeType> = can('EMPLOYEE.CAN_MANAGE')
    ? [
        {
          name: 'edit',
          label: 'Edit',
          icon: <EditIcon />,
          onClick: (name, item) => {
            navigate(`${pathname}/${item.id}/edit`);
          },
        },
      ]
    : [];

  return (
    <TablePage
      url={'/employees'}
      context={'/api/employees'}
      properties={properties}
      defaultFilters={{
        status: 'active',
      }}
      heading={'Employees'}
      onCreate={can('EMPLOYEE.CAN_MANAGE') ? handleCreate : undefined}
      itemLink={(item) => `${pathname}/${item.id}/show`}
      rowActions={actions}
      filters={filters}
    />
  );
};

export default EmployeesList;
