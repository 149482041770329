import React, { FunctionComponent, useCallback, useState } from 'react';
import * as Styled from './DropdownMenuItem.styled';
import Text from '../../../atoms/Text';
import { ArrowIcon } from '../../../../Icons/arrow.icon';
import InnerDropdownMenu from '../InnerDropdownMenu';
import { Icon } from '../../../atoms/Icon/Icon';
import { color } from '../../../../styles/Variables';

export type DropdownMenuItemType = {
  id?: string;
  text: string | JSX.Element;
  onClick?: () => void;
  icon?: JSX.Element;
  children?: DropdownMenuItemType[];
  isBorderTop?: boolean;
  childrenLeft?: boolean;
};

const DropdownMenuItem: FunctionComponent<DropdownMenuItemType> = ({ id, childrenLeft, icon, text, onClick, isBorderTop, children }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const onMouseEnter = useCallback(() => {
    if (!children) {
      return;
    }
    setExpanded(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    if (!children) {
      return;
    }
    setExpanded(false);
  }, [children]);
  return (
    <Styled.DropdownMenuItem
      id={id}
      $hasSubmenu={!!children}
      onClick={onClick}
      $isBorderTop={isBorderTop}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {icon ?? <span></span>}
      <Text as="div" bold={true} color={'semiGrey'}>
        {text}
      </Text>
      {children && (
        <Styled.SubmenuWrapper $left={childrenLeft} $active={expanded}>
          <InnerDropdownMenu relative menuItems={children} childrenLeft={childrenLeft} />
        </Styled.SubmenuWrapper>
      )}
      {children && (
        <Icon color={color.neutral[70]}>
          <ArrowIcon direction={'right'} />
        </Icon>
      )}
    </Styled.DropdownMenuItem>
  );
};

export default DropdownMenuItem;
