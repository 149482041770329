import styled, { css } from 'styled-components';
import { color, fontSize } from '../../../../styles/Variables';
import { BodyText } from '../../../../styles/Typography';

export const CheckboxContainer = styled.label<{ $checked: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  ${BodyText};
  font-size: ${fontSize.body.m};
  padding: 0.8rem;
`;

export const Checkbox = styled.div<{ $checked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 5px;
  border: 2px solid ${color.neutral[50]};
  cursor: pointer;
  margin-right: 0.7rem;

  transition: all 100ms cubic-bezier(0.77, 0.28, 0.43, 0.97);
  ${(props) =>
    props.$checked &&
    css`
      background-color: ${color.primary[60]};
      border-color: ${color.primary[60]};
    `};
`;

export const HiddenInput = styled.input`
  appearance: none;
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  margin: 0;
`;
