import React, { FunctionComponent } from 'react';
import { components, OptionProps } from 'react-select';
import { CustomOptionType } from '../../../../../@types/Select/CustomOptionsType';
import { WikiSearchOptionType } from './WikiSearch';
import Text from '../../../../atoms/Text';
import { Colored } from '../../../../../legacy/CapeMorris/components/layout/layout.styled';
import { color } from '../../../../../styles/Variables';
import Chip from '../../../../atoms/Chips/Chip';

type Props = OptionProps<CustomOptionType<WikiSearchOptionType, string>, true>;

const WikiSearchOption: FunctionComponent<Props> = (props) => {
  return (
    <>
      {props.data.object['@type'] === 'ProjectTag' && (
        <components.Option {...props} className={'tag-option'}>
          <div className={'tag'}>
            <Chip {...props}>{props.label}</Chip>
          </div>
        </components.Option>
      )}
      {props.data.object['@type'] === 'Article' && (
        <components.Option {...props}>
          <Text size={'s'}>
            {props.data.object.name} <br />
            <Colored color={color.neutral[60]}>{props.data.object.pathString}</Colored>
          </Text>
        </components.Option>
      )}
    </>
  );
};

export default WikiSearchOption;
