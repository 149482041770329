import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../legacy/CapeMorris/components';
import { CostType } from '../../../../@types/Finances/Cost/CostType';
import EntityProvider from '../../../../Context/EntityContext/EntityProvider';
import CostShow from '../../Costs/CostShow/CostShow';

type Props = {};

const CostInboxShowView: FunctionComponent<Props> = (props) => {
  let { id } = useParams<{ id: string }>();
  return (
    <EntityProvider<CostType> url={`/api/costs/${id}`}>
      {({ loading, entity }) => (
        <>
          {loading && <Loader />}
          {entity && <CostShow inbox cost={entity} />}
        </>
      )}
    </EntityProvider>
  );
};

export default CostInboxShowView;
