import React, { FunctionComponent } from 'react';
import Avatar from '../../../atoms/Avatar/Avatar';

type Props = {
  avatarSize: number;
  move: number;
  idx: number;
  name: string;
  profilePhotoUrl?: string;
};

const AttendeeEmployee: FunctionComponent<Props> = ({ avatarSize, move, idx, name, profilePhotoUrl }) => {
  return (
    <div style={{ marginLeft: `${(avatarSize / move) * idx}rem`, position: 'absolute', padding: 0 }}>
      <Avatar size={avatarSize} name={name} border={true} tooltipPosition={'bottomLeft'} style={{ padding: 0 }} photoUrl={profilePhotoUrl} />
    </div>
  );
};

export default AttendeeEmployee;
