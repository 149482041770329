import React from 'react';
import { CellTemplate } from '../../../../molecules/CellTemplates/CellTemplate';
import { SubcostType } from '../../../../../@types/Finances/Cost/SubcostType';
import Text from '../../../../atoms/Text';
import Chip from '../../../../atoms/Chips/Chip';
import { CostTypeType } from '../../../../../@types/Finances/Cost/CostTypeType';

const CostTypeTemplate: CellTemplate<CostTypeType, SubcostType> = ({ value, options, item }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.3rem' }}>
      <Text size={'m'} bold={true}>
        {value.label}
      </Text>
      <div style={{ gap: '0.3rem', display: 'flex' }}>
        <Chip $size={'xs'}>{item.kind.toUpperCase()}</Chip>
      </div>
    </div>
  );
};
export default CostTypeTemplate;
