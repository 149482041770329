import React, { FunctionComponent } from 'react';

interface OwnProps {}

type Props = OwnProps;

const DeleteIcon: FunctionComponent<Props> = (props) => {
  return (
    <svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.8112 7.8748L12.2457 7.81738L11.9062 17.0863L13.4718 17.1435L13.8112 7.8748Z" strokeWidth={0.1} fill="currentColor" />
      <path d="M10.7834 7.84619H9.2168V17.1151H10.7834V7.84619Z" strokeWidth={0.5} fill="currentColor" />
      <path d="M8.09295 17.0861L7.75353 7.81714L6.18799 7.8746L6.52744 17.1435L8.09295 17.0861Z" strokeWidth={0.1} fill="currentColor" />
      <path
        d="M0.678711 3.98193V5.5485H2.31109L3.60609 20.2647C3.6416 20.6691 3.98027 20.9793 4.38625 20.9793H15.587C15.993 20.9793 16.332 20.6689 16.3672 20.2644L17.6622 5.5485H19.321V3.98193H0.678711ZM14.8696 19.4128H5.10352L3.88367 5.5485H16.0897L14.8696 19.4128Z"
        strokeWidth={0.1}
        fill="currentColor"
      />
      <path
        d="M12.5587 0.97937H7.44121C6.72137 0.97937 6.13574 1.565 6.13574 2.28484V4.76527H7.7023V2.54593H12.2976V4.76527H13.8642V2.28484C13.8642 1.565 13.2785 0.97937 12.5587 0.97937Z"
        strokeWidth={0.1}
        fill="currentColor"
      />
    </svg>
  );
};

export default DeleteIcon;
