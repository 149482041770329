import React, { FunctionComponent } from 'react';
import * as Styled from './Styles.styled';
import Resources from '../../../Resources/Resources';
import useUser from '../../../../../hooks/useUser';

type Props = {
  taskId: number;
  disabled?: boolean;
};

const TaskSidebarSourceFiles: FunctionComponent<Props> = ({ taskId, disabled }) => {
  const user = useUser();
  return (
    <Styled.SourceFiles>
      <Styled.Label>Resources:</Styled.Label>
      <Styled.SourceFilesWrapper>
        <Resources subject={'Task'} subjectId={taskId} editable={(resource) => resource.createdBy.id === user.employeeId} />
      </Styled.SourceFilesWrapper>
    </Styled.SourceFiles>
  );
};

export default TaskSidebarSourceFiles;
