import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import ContractShow from './ContractShow';
import { Loader } from '../../../../legacy/CapeMorris/components';
import useEntity from '../../../../hooks/useEntity';
import { ContractType } from '../../../../@types/Finances/Contracts/ContractType';

const ContractShowView: FunctionComponent = () => {
  let { id } = useParams<{ id: string }>();
  const { entity, loading, reload } = useEntity<ContractType>(`/api/contracts/${id}`);

  return (
    <>
      {loading && <Loader />}
      {entity && <ContractShow contract={entity} onChange={reload} />}
    </>
  );
};

export default ContractShowView;
