import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { Grid, GridItem } from '../../atoms/Grid/Grid';
import CardHeading from '../../molecules/CardHeading/CardHeading';
import { useFieldArray, useFormContext } from 'react-hook-form';
import Button from '../../atoms/Button/Button';
import Payment from './Payment';
import { PaymentType } from '../../../@types/Finances/PaymentType';

type Props = {
  name?: string;
  hideHeader?: boolean;
  getPaymentsLength?: (length: number) => void;
  saveButton?: React.ReactNode;
  editablePayments?: PaymentType[];
  currency: string;
};

const PaymentsInput: FunctionComponent<Props> = ({ name = 'payments', hideHeader, getPaymentsLength, saveButton, editablePayments, currency }) => {
  const { control, formState, reset } = useFormContext();
  const {
    fields: payments,
    append,
    remove,
  } = useFieldArray({
    control,
    name,
    keyName: '_id',
  });

  const handleCreate = useCallback(() => {
    append({
      paidAt: new Date().toISOString(),
      type: null,
      value: { value: null, currency: null },
      isNew: true,
    });
  }, [append]);

  useEffect(() => {
    if (getPaymentsLength) {
      getPaymentsLength(payments.length);
    }
  }, [payments]);

  useEffect(() => {
    if (saveButton && formState.isSubmitSuccessful) {
      reset();
    }
  }, [formState]);

  useEffect(() => {
    if (editablePayments) {
      editablePayments.forEach((payment) => {
        append(payment);
      });
    }
  }, []);

  return (
    <GridItem $desktop={12}>
      {!hideHeader && <CardHeading heading={'Payments'} />}
      <Grid spacing={4}>
        {/* todo: upgraded react-hook-form is more typing strict */}
        {payments.map((payment: any, idx) => (
          <Payment index={idx} name={`${name}.${idx}`} key={payment._id} payment={payment as PaymentType} onDelete={() => remove(idx)} currency={currency} />
        ))}
      </Grid>
      <Grid $justifyContent={'end'} spacing={2} style={{ marginTop: '3.6rem' }}>
        <GridItem>{saveButton && saveButton}</GridItem>
        <GridItem>
          <Button full $text={'Add payment'} onClick={handleCreate} />
        </GridItem>
      </Grid>
    </GridItem>
  );
};

export default PaymentsInput;
