import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import useEntity from '../../../../hooks/useEntity';
import { Loader } from '../../../../legacy/CapeMorris/components';
import { IncomeType } from '../../../../@types/Finances/Income/IncomeType';
import IncomeShow from './IncomeShow';
import EntityProvider from '../../../../Context/EntityContext/EntityProvider';
import { CostType } from '../../../../@types/Finances/Cost/CostType';
import CostShow from '../../Costs/CostShow/CostShow';
import Container from '../../../atoms/Container/Container';

const IncomeShowView: FunctionComponent = () => {
  let { id } = useParams<{ id: string }>();
  return (
    <EntityProvider<IncomeType> url={`/api/incomes/${id}`}>
      {({ loading, entity }) => (
        <Container>
          {loading && <Loader />}
          {entity && <IncomeShow income={entity} />}
        </Container>
      )}
    </EntityProvider>
  );
};

export default IncomeShowView;
