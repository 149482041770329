import styled, { css } from 'styled-components';
import { color, fontSize } from 'styles/Variables';

type Props = {
  $active: boolean;
  $simple?: boolean;
};

export const Container = styled.div<Props>`
  background-color: ${color.neutral[30]};
  border: 2px dashed ${color.neutral[50]};
  width: 100%;
  min-height: 10rem;
  border-radius: 0.5rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: ${color.neutral[60]};

  & > * {
    text-align: center;
  }
  ${(props) => [
    props.$active &&
      css`
        background-color: ${color.primary[10]};
        border-color: ${color.primary[30]};
      `,
    props.$simple &&
      css`
        min-height: 2rem;
      `,
  ]}
`;

export const DragDropText = styled.div`
  color: ${color.neutral[60]};
  font-size: ${fontSize.body.s};
  margin: 0.5rem auto;
`;
