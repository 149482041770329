import styled, { css } from 'styled-components';
import { color } from '../../../../styles/Variables';
import { OverflowSentimentType } from '../../../../@types/OverflowSentiment/OverflowSentimentType';

type ProgressBarProps = {
  $isFilled: boolean;
};

type ProgressProps = {
  $overflowSentiment: OverflowSentimentType;
  $width?: number;
  $maxValue?: number;
};

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const ProgressBar = styled.div<ProgressBarProps>`
  width: 100%;
  height: 0.8rem;
  border-radius: 6px;
  background-color: ${color.neutral['40']};
`;

export const Progress = styled.div<ProgressProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: 0.8rem;
  border-radius: 6px;
  width: 0;
  background-color: ${color.primary['80']};

  ${(props: ProgressProps) =>
    props.$width &&
    props.$maxValue &&
    css`
      width: ${props.$width}%;
    `}

  ${(props: ProgressProps) =>
    props.$overflowSentiment === 'good' &&
    css`
      background-color: ${color.semantic.success['80']};
    `}
  ${(props: ProgressProps) =>
    props.$overflowSentiment === 'bad' &&
    css`
      background-color: ${color.semantic.error['80']};
    `}
  ${(props: ProgressProps) =>
    props.$overflowSentiment === 'warning' &&
    css`
      background-color: ${color.semantic.warning['80']};
    `}
  ${(props: ProgressProps) =>
    props.$overflowSentiment === 'neutral' &&
    css`
      background-color: ${color.primary['80']};
    `}
`;
