import React, { FunctionComponent, useEffect, useState } from 'react';
import HomeOfficesCardItem from '../DashboardCardItems/HomeOfficesCardItem';
import * as Styled from './ContentCard.styled';
import Text from '../../../../atoms/Text/Text';
import { Loader } from '../../../../../legacy/CapeMorris/components';
import { DashboardDataAbsenceType } from '../../../../../@types/Dashboard/DashboardDataAbsenceType';
import AbsencesPerDaySelect from '../../../AbsencesPerDaySelect/AbsencesPerDaySelect';
import AbsencesCounter from '../../../AbsencesCounter/AbsencesCounter';

type Props = {
  homeOffices: DashboardDataAbsenceType[] | undefined;
  showDateFilter?: boolean;
  showCounter?: boolean;
};

const HomeOfficesCardContentCard: FunctionComponent<Props> = ({ homeOffices, showDateFilter, showCounter }) => {
  const [filteredHomeOffices, setFilteredHomeOffices] = useState<DashboardDataAbsenceType[] | undefined>(undefined);

  useEffect(() => {
    if (!homeOffices) {
      return;
    }
    setFilteredHomeOffices(homeOffices);
  }, [homeOffices]);

  return (
    <Styled.ItemsColumnWrapper $scrollable={true} $heightLocked>
      {showDateFilter && <AbsencesPerDaySelect absences={homeOffices} onSelect={setFilteredHomeOffices} />}
      {showCounter && <AbsencesCounter count={filteredHomeOffices?.length || 0} />}
      {filteredHomeOffices &&
        filteredHomeOffices
          .sort((a, b) => (a.employee.name > b.employee.name ? 1 : -1))
          .map((homeOffice, index) => (
            <>
              <HomeOfficesCardItem homeOffice={homeOffice} key={index} />
            </>
          ))}
      {filteredHomeOffices?.length === 0 && <Text color={'grey'}>No home offices to display</Text>}
      {!filteredHomeOffices && <Loader />}
    </Styled.ItemsColumnWrapper>
  );
};

export default HomeOfficesCardContentCard;
