import React, { FunctionComponent } from 'react';
import { TagType } from '../../@types/Tag/Tag';
import { Grid } from '../atoms/Grid/Grid';
import BetterChip, { ChipColor } from '../atoms/Chips/BetterChip';

type Props = {
  tags: TagType[];
  color?: ChipColor;
};

const Tags: FunctionComponent<Props> = ({ tags, color }) => {
  return (
    <Grid spacing={0.5} $simple style={{ width: 'fit-content' }}>
      {tags.map((tag) => (
        <BetterChip color={color} key={tag.id}>
          {tag.value}
        </BetterChip>
      ))}
    </Grid>
  );
};

export default Tags;
