import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import useEntity from '../../../../hooks/useEntity';
import { Loader } from '../../../../legacy/CapeMorris/components';
import { IncomeType } from '../../../../@types/Finances/Income/IncomeType';
import IncomeForm from './IncomeForm';
import Container from '../../../atoms/Container/Container';

const IncomeFormEditView: FunctionComponent = () => {
  let { id } = useParams<{ id: string }>();
  const { entity, loading, reload } = useEntity<IncomeType>(`/api/incomes/${id}`);

  return (
    <Container>
      {loading && <Loader />}
      {entity && <IncomeForm income={entity} onChange={reload} isEdit={true} />}
    </Container>
  );
};

export default IncomeFormEditView;
