import React, { FunctionComponent } from 'react';
import ModalForm from '../../../../../../../components/organisms/Form/ModalForm/ModalForm';
import { Grid, GridItem } from '../../../../../../../components/atoms/Grid/Grid';
import NumberInput from '../../../../../../../components/molecules/Form/NumberInput/NumberInput';
import DatePicker from '../../../../../../../components/molecules/Form/DatePicker/DatePicker';
import { TextInput } from '../../../../../../../components/molecules/Form/TextInput/TextInput';
import SelectInput from '../../../../../../../components/molecules/Form/SelectInput/SelectInput';
import InventoryItemType from '../../../../../../../@types/Inventory/InventoryItemType';

type Props = {
  basePath: string;
  defaults?: InventoryItemType;
};

const InventoryItemModal: FunctionComponent<Props> = ({ basePath, defaults }) => {
  return (
    <ModalForm basePath={basePath} defaults={defaults}>
      <Grid spacing={2}>
        <GridItem $desktop={6}>
          <TextInput name={'name'} label={'Name'} placeholder={'Type item name'} />
        </GridItem>
        <GridItem $desktop={6}>
          <TextInput name={'model'} label={'Model'} placeholder={'Type item model'} />
        </GridItem>
        <GridItem $desktop={12}>
          <SelectInput name={'type'} label={'Type'} placeholder={'Select item type'} creatable={true} baseUrl={'/api/inventory_types/'} />
        </GridItem>
        <GridItem $desktop={12}>
          <TextInput name={'manufacturer'} label={'Manufacturer'} placeholder={'Type manufacturer'} />
        </GridItem>
        <GridItem $desktop={12}>
          <TextInput name={'serialNumber'} placeholder={'Type serial number'} label={'Serial number'} />
        </GridItem>
        <GridItem $desktop={12}>
          <DatePicker name={'productionDate'} label={'Production date'} placeholder={'Select date'} />
        </GridItem>
        <GridItem $desktop={12}>
          <DatePicker name={'warrantyDate'} label={'Warranty date'} placeholder={'Select date'} />
        </GridItem>
        <GridItem $desktop={12}>
          <NumberInput name={'costId'} placeholder={'Value'} label={'Cost ID'} />
        </GridItem>
        <GridItem $desktop={12}>
          <TextInput name={'comment'} label={'Comment'} />
        </GridItem>
      </Grid>
    </ModalForm>
  );
};

export default InventoryItemModal;
