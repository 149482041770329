import { TableFiltersType } from '../../../@types/Table/TableFilterType';

const filters: TableFiltersType = [
  {
    type: 'select',
    name: 'absenceType',
    label: 'Type',
    options: {
      options: [
        { value: 'vacation', label: 'Vacation' },
        { value: 'home_office', label: 'Home office' },
        {
          value: 'sick_leave',
          label: 'Sick leave',
        },
      ],
    },
    gridProps: {
      $desktop: 3,
    },
  },
  {
    type: 'select',
    name: 'person.id',
    label: 'Person',
    options: {
      baseUrl: '/api/employees',
    },
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'select',
    name: 'status',
    label: 'Status',
    options: {
      options: [
        { value: 'pending', label: 'Pending' },
        { value: 'cancelled', label: 'Canceled' },
        {
          value: 'rejected',
          label: 'Rejected',
        },
        { value: 'accepted', label: 'Approved' },
      ],
    },
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'year',
    name: 'year',
    label: 'Year',

    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'select',
    name: 'person.teamLeader.id',
    label: 'Team leader',
    options: {
      baseUrl: '/api/employees',
    },
    gridProps: {
      $desktop: 3,
    },
  },
];
export default filters;
