import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import OverlayForm from '../../../../molecules/OverlayForm/OverlayForm';
import useOverlay from '../../../../../hooks/useOverlay';
import useEntity from '../../../../../hooks/useEntity';
import { AbsenceType } from '../../../../../@types/Absences/AbsenceType';
import SelectInput from '../../../../molecules/Form/SelectInput/SelectInput';
import { DictValue } from '../../../../../@types/Dictionary/DictValue';
import { TextInput } from '../../../../molecules/Form/TextInput/TextInput';
import DateRangePicker from '../../../../molecules/Form/DatePicker/DateRangePicker';
import { AuthContext } from '../../../../../Context/auth-context/auth-context';
import OverlayFormSection from '../../../../molecules/OverlayForm/OverlayFormSection';
import HiddenInput from '../../../../atoms/Form/HiddenInput/HiddenInput';
import api from '../../../../../services/api';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import Text from '../../../../atoms/Text/Text';
import SnackContainer from '../../../../../legacy/CapeMorris/components/snack-bar/use-snack-container';
import StaticSelectInput from '../../../../molecules/Form/SelectInput/StaticSelectInput';

type Props = {
  absence?: AbsenceType;
};
const absenceTypeDict: DictValue[] = [
  { value: 'vacation', label: 'Vacation' },
  { value: 'home_office', label: 'Home office' },
  { value: 'sick_leave', label: 'Sick leave' },
];

const absenceKindDict: DictValue[] = [
  { value: 'recreation', label: 'Recreation' },
  { value: 'on_demand', label: 'On demand' },
  { value: 'wedding', label: 'Occasional: wedding' },
  { value: 'funeral', label: 'Occasional: funeral' },
  { value: 'childbirth', label: 'Occasional: Child birth' },
];

const AbsenceRequestOverlayBody: FunctionComponent<Props> = ({ absence }) => {
  const { hideOverlay } = useOverlay();
  const { create, save } = useEntity<AbsenceType>(absence ? `${absence['@id']}` : '/api/absence_requests');
  const { can } = useContext(AuthContext);
  const isEdit = !!absence;
  const { showSnack } = SnackContainer.useContainer();
  const [state, setState] = useState<null | 'Working...' | 'Done' | 'Error'>(null);

  const types = useMemo(() => {
    const result = [...absenceTypeDict];
    if (!can('ABSENCE.CAN_ADD_SICKLEAVE')) {
      result.pop();
    }
    return result;
  }, [can]);

  const handleSubmit = useCallback((data: AbsenceType) => {
    setState('Working...');
    if (isEdit) {
      save(data)
        .then(() => {
          hideOverlay();
        })
        .then((r) => {
          showSnack({
            title: 'Success',
            message: 'Absence request updated',
            type: 'success',
          });
          setState('Done');
        })
        .catch((e) => {
          setState('Error');
        });
    } else {
      create(data)
        .then(() => {
          hideOverlay();
        })
        .then((r) => {
          showSnack({
            title: 'Success',
            message: 'Absence request created',
            type: 'success',
          });
          setState('Done');
        })
        .catch((e) => {
          setState('Error');
        });
    }
  }, []);

  return (
    <OverlayForm
      onSubmit={handleSubmit}
      defaultValues={
        absence
          ? { ...absence, dateFrom: new Date(absence.dateFrom), dateTo: new Date(absence.dateTo) }
          : { absenceType: 'vacation', absenceKind: 'recreation' }
      }
      state={state}
      buttonProps={{
        waiting: state === 'Working...',
      }}
    >
      {({ watch, unregister }) => {
        const type = watch('absenceType');
        const dateFrom = watch('dateFrom');
        const dateTo = watch('dateTo');
        useEffect(() => {
          if (type === 'home_office') {
            unregister(['person', 'personReplacing', 'absenceKind']);
          }
          if (type === 'sick_leave') {
            unregister(['personReplacing', 'absenceKind']);
          }
        }, [type]);

        const [days, setDays] = useState<number | undefined>(undefined);
        const calculateDays = () => {
          if (dateFrom && dateTo) {
            api
              .get<{ count: number }>('/api/working_days/count', {
                params: {
                  start: dateFrom,
                  end: dateTo,
                },
              })
              .then((r) => {
                setDays(r.data.count);
              });
          }
        };

        useEffect(() => {
          calculateDays();
        }, [dateFrom, dateTo]);
        return (
          <OverlayFormSection heading={isEdit ? 'Edit absence request' : 'Create absence request'}>
            <Grid spacing={4}>
              <GridItem $desktop={6}>
                <StaticSelectInput name={'absenceType'} label={'Type'} options={types} required />
              </GridItem>
              <GridItem $desktop={6}>
                {type === 'vacation' && <StaticSelectInput name={'absenceKind'} label={'Vacation kind'} options={absenceKindDict} required />}
              </GridItem>
            </Grid>
            <GridItem $desktop={12}>
              {type === 'vacation' && (
                <SelectInput name={'personReplacing'} label={'Person replacing'} baseUrl={'/api/employees/'} required baseUrlParams={{ status: ['active'] }} />
              )}
            </GridItem>
            {type === 'sick_leave' && (
              <GridItem $desktop={12}>
                <SelectInput name={'person'} label={'Person'} baseUrl={'/api/employees/'} required baseUrlParams={{ status: ['active'] }} />
              </GridItem>
            )}
            <Grid>
              <GridItem $desktop={6}>{<DateRangePicker label={'Dates'} nameStart={'dateFrom'} nameEnd={'dateTo'} name={'days'} required />}</GridItem>
              <GridItem style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '1rem' }} $desktop={6}>
                <Text bold>Selected days: </Text>
                <Text bold color={'primary'}>
                  {days ?? 0}
                </Text>
              </GridItem>
            </Grid>
            <HiddenInput name={'days'} value={days} />
            <GridItem $desktop={12}>
              <TextInput name={'comment'} label={'Comment'} />
            </GridItem>
          </OverlayFormSection>
        );
      }}
    </OverlayForm>
  );
};

export default AbsenceRequestOverlayBody;
