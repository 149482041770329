import React, { FunctionComponent, ReactNode, useCallback, useState } from 'react';
import OverlayType from '../../@types/Overlay/OverlayType';
import { OverlayContext } from './OverlayContext';

type Props = {
  children: ReactNode;
};

const OverlayContextProvider: FunctionComponent<Props> = (props) => {
  const [overlay, setOverlay] = useState<OverlayType | undefined>();

  const showOverlay = useCallback((overlay: OverlayType) => {
    setOverlay(overlay);
  }, []);

  const hideOverlay = useCallback(() => {
    setOverlay(undefined);
  }, []);

  return <OverlayContext.Provider value={{ overlay, showOverlay, hideOverlay }}>{props.children}</OverlayContext.Provider>;
};

export default OverlayContextProvider;
