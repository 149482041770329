import { EntityType } from '../Entity/EntityType';
import { DictValue } from '../Dictionary/DictValue';

export const FinancialCompanyProductionNamesDict: DictValue[] = [
  {
    label: '361',
    value: '361.SH Sp. z o.o.',
  },
  {
    label: 'CM-PL',
    value: 'CAPE MORRIS PL Sp. z o.o.',
  },
  {
    label: 'RFRM',
    value: 'Reframed Sp. z o.o.',
  },
  {
    label: 'CM-DE',
    value: 'Cape Morris Germany GmbH',
  },
];

export const getFinancialCompanyProductionNameLabel = (value: string): string => {
  const dictValue = FinancialCompanyProductionNamesDict.find((dictValue) => dictValue.value === value);
  return dictValue ? dictValue.label : 'unknown';
};

export const getFinancialCompanyProductionFullName = (value: string): string => {
  const dictValue = FinancialCompanyProductionNamesDict.find((dictValue) => dictValue.value === value);
  return dictValue ? dictValue.value : 'unknown';
};

export type FinancialCompanyType = EntityType & {
  name: string;
};
