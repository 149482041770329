import React, { FunctionComponent } from 'react';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import Narwhal from '../../../../Icons/Narwhal';
import Heading from '../../../atoms/Heading';
import Text from '../../../atoms/Text';
import Button from '../../../atoms/Button/Button';
import * as Styled from './EmptyBody.styled';

type Props = {
  isButton: boolean;
  buttonText?: string;
  buttonAction?: () => void;
};

const EmptyBody: FunctionComponent<Props> = ({ isButton, buttonText, buttonAction }) => {
  return (
    <tr>
      <td colSpan={100}>
        <Styled.EmptyBodyWrapper>
          <Grid>
            <GridItem $desktop={12} style={{ justifyContent: 'center', display: 'flex' }}>
              <Narwhal width={'10rem'} />
            </GridItem>
            <GridItem $desktop={12} style={{ justifyContent: 'center', display: 'flex' }}>
              <Heading size={'l'}>No records to display</Heading>
            </GridItem>
            <GridItem $desktop={12} style={{ justifyContent: 'center', display: 'flex' }}>
              <Text size={'xl'} color={'grey'}>
                Looks like there is no data we can show you at the moment
              </Text>
            </GridItem>
            <GridItem $desktop={12} style={{ justifyContent: 'center', display: 'flex' }}>
              {isButton && <Button $text={buttonText ?? ''} onClick={buttonAction} />}
            </GridItem>
          </Grid>
        </Styled.EmptyBodyWrapper>
      </td>
    </tr>
  );
};

export default EmptyBody;
