import { SidebarContext } from '../Context/Sidebar/SidebarContext';
import { useCallback, useContext, useRef } from 'react';
import TaskSidebar from '../components/organisms/TaskSidebar/TaskSidebar';
import useUser from './useUser';
import { useLocation, useNavigate } from 'react-router-dom';
import IriHelper from '../helpers/iri-helper';

function useTaskSidebar() {
  const { openSidebar } = useContext(SidebarContext);
  const user = useUser();
  const location = useLocation();
  const navigate = useNavigate();

  const containerRef = useRef<HTMLDivElement>(null);

  const sendDataLayerInfo = useCallback(() => {
    if (!location || !window || !document) return;
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      event: 'open_task_sidebar',
      pagePathname: location.pathname,
      pageTitle: document.title,
      userDepartment: user?.department?.name,
    });
  }, [window, document, location]);

  const openTask = useCallback(
    (iri: string, onChange?: () => void) => {
      // if loacation.search contains task=ID, rewrite it with new ID
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('task', IriHelper.iriToId(iri)!);
      navigate({ search: searchParams.toString() }, { replace: true });
      openSidebar(TaskSidebar({ iri, ref: containerRef, onChange: onChange }));
      sendDataLayerInfo();
    },
    [location],
  );

  return { openTask };
}

export default useTaskSidebar;
