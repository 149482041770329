import React from 'react';
import { CellTemplate } from '../../CellTemplate';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import { ContractorType } from '../../../../../@types/Finances/Contractor/ContractorType';
import Text from '../../../../atoms/Text';
import Chip from '../../../../atoms/Chips/Chip';
import { TextSizeType } from '../../../../../@types/Text/TextSizeType';
import { ColorType } from '../../../../../@types/Color/ColorType';
import { shorten } from '../../../../../helpers/shorten';
import EmptyCell from '../../common/EmptyCell';
import * as Styled from './ContractorValueTaxTemplate.styled';

const ContractorValueTaxTemplate: CellTemplate<
  ContractorType,
  { textProperties?: { bold?: boolean; size?: TextSizeType; color?: ColorType; shorten?: number; maxWidth?: string } },
  any
> = ({ value, options, item }) => {
  return (
    <Styled.Container $align={'center'} spacing={0.3} style={{ maxWidth: options?.textProperties?.maxWidth }}>
      {value && (
        <>
          <GridItem $desktop={12}>
            <Text bold style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {options?.textProperties?.shorten ? shorten(value.name ?? 'N/A', options.textProperties?.shorten, options.textProperties?.shorten) : value.name}
            </Text>
          </GridItem>
          {value.taxNumber && (
            <GridItem>
              <Chip $size={'xs'}>{value.taxNumber}</Chip>
            </GridItem>
          )}
        </>
      )}
      {!value && <EmptyCell />}
    </Styled.Container>
  );
};
export default ContractorValueTaxTemplate;
