import React, { FunctionComponent, useCallback } from 'react';
import useOverlay from '../../../../../hooks/useOverlay';
import OverlayForm from '../../../../molecules/OverlayForm/OverlayForm';
import useEntity from '../../../../../hooks/useEntity';
import { ClientType } from '../../../../../@types/Project/Client/ClientType';
import { TextInput } from '../../../../molecules/Form/TextInput/TextInput';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import Heading from '../../../../atoms/Heading';
import { ListItemType } from '../../../../molecules/Projects/ListItem/ListItem';
import RadioColorsInput from '../../../../molecules/Form/RadioColorsInput/RadioColorsInput';
import useApi from '../../../../../hooks/useApi';
import Button from '../../../../atoms/Button/Button';
import PlusIcon from '../../../../../Icons/Plus.icon';
import AcceptIcon from '../../../../../Icons/Accept.icon';

type Props = {
  client?: ListItemType;
};

const PartnerForm: FunctionComponent<Props> = ({ client }) => {
  const { hideOverlay } = useOverlay();
  const { create, save } = useEntity<ClientType>(client ? `/api/clients/${client.id}` : '/api/clients');
  const isEdit = !!client;
  const { post } = useApi();

  const handleSubmit = useCallback((data: ClientType) => {
    if (isEdit) {
      save(data).then(() => {
        hideOverlay();
      });
    } else {
      create(data).then(() => {
        hideOverlay();
      });
    }
  }, []);

  const onArchive = useCallback(() => {
    if (isEdit && client) {
      post(`/clients/${client.id}/archive`).then((r) => {
        hideOverlay();
      });
    }
  }, []);

  const onRestore = useCallback(() => {
    if (isEdit && client) {
      post(`/clients/${client.id}/restore`).then((r) => {
        hideOverlay();
      });
    }
  }, []);

  return (
    <OverlayForm
      onSubmit={handleSubmit}
      defaultValues={client ?? {}}
      buttonProps={{
        text: isEdit ? 'Save changes' : 'Add new Partner',
        icon: isEdit ? <AcceptIcon /> : <PlusIcon />,
        iconSize: 1.2,
      }}
    >
      <Grid spacing={2}>
        <GridItem $desktop={12}>
          <Heading size={'l'}>{isEdit ? 'Edit Partner' : 'Add new Partner'}</Heading>
        </GridItem>
        <GridItem $desktop={12}>
          <TextInput name={'name'} label={"Partner's name"} placeholder={"Type Partner's name"} required />
        </GridItem>
        <GridItem $desktop={12}>
          <RadioColorsInput name={'color'} required />
        </GridItem>
        <GridItem $desktop={12}>
          <Grid $justifyContent={'start'}>
            <GridItem>
              {client && (
                <Button
                  $text={!client?.archived ? 'Archive Partner' : 'Restore Partner'}
                  onClick={() => {
                    !client.archived ? onArchive() : onRestore();
                  }}
                  kind={'link'}
                  style={{ paddingLeft: 0 }}
                />
              )}
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </OverlayForm>
  );
};

export default PartnerForm;
