import React, { FunctionComponent, ReactNode, useCallback } from 'react';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';

interface OwnProps {
  onSubmit: (data: any) => any;
  defaultValues?: any;
  children: ReactNode | ((methods: UseFormReturn) => ReactNode);
}

type Props = OwnProps;
const Form: FunctionComponent<Props> = ({ onSubmit, defaultValues, children }) => {
  const methods = useForm({
    defaultValues: defaultValues,
    shouldUnregister: true,
  });

  const handleError = useCallback((data: any) => {
    console.group('FORM ERROR');
    console.log('FORM ERROR', methods.getValues());
    console.log(methods.formState.errors);
    console.groupEnd();
  }, []);

  return (
    <FormProvider {...methods}>
      <form
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
        onSubmit={methods.handleSubmit(onSubmit, handleError)}
      >
        {typeof children === 'function' ? children(methods) : children}
      </form>
    </FormProvider>
  );
};
export default Form;
