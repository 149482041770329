import React, { FunctionComponent } from 'react';

type Props = {
  size?: number;
  color?: string;
};

const EditIcon: FunctionComponent<Props> = ({ size = 1, color }) => {
  return (
    <svg width={`${size}em`} height={`${size}em`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.15388 19H6.39041L15.6501 9.74036L14.4136 8.50381L5.15388 17.7635V19ZM18.8577 8.65576L15.4828 5.31158L16.7866 4.00776C17.0802 3.71417 17.4372 3.56738 17.8577 3.56738C18.2782 3.56738 18.6353 3.71417 18.9289 4.00776L20.1462 5.22503C20.4397 5.51862 20.5917 5.87053 20.6019 6.28078C20.6122 6.69103 20.4705 7.04295 20.1769 7.33653L18.8577 8.65576ZM17.7731 9.75573L7.02886 20.5H3.65393V17.125L14.3982 6.38078L17.7731 9.75573Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  );
};

export default EditIcon;
