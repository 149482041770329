import React, { FunctionComponent } from 'react';
import { SubmenuLinkType } from '../../../../../@types/Module/Module';
import * as Components from './HeaderSubmenu.styled';
import { NavLink, useLocation } from 'react-router-dom';
import Badge from '../../../../../components/atoms/Badge/Badge';

interface OwnProps {
  link: SubmenuLinkType;
  base: string;
  innerRef: (ref: HTMLDivElement | null) => void;
}

type Props = OwnProps;

const HeaderSubmenuLink: FunctionComponent<Props> = ({ link, base, innerRef }) => {
  const [to, setTo] = React.useState(`${base}${link.url}`);
  const location = useLocation();

  React.useEffect(() => {
    setTo(`${base}${link.url}`);
  }, [base, link]);
  const isIndex = link.url === '';

  return (
    <NavLink to={to} style={{ height: '100%' }}>
      {({ isActive }) => (
        <>
          <Components.SubmenuLink ref={innerRef} $isActive={(isIndex && location.pathname === to) || (!isIndex && isActive)}>
            {link.name} {link.badge ? <Badge>{link.badge}</Badge> : null}
          </Components.SubmenuLink>
        </>
      )}
    </NavLink>
  );
};

export default HeaderSubmenuLink;
