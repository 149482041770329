import React, { FunctionComponent, useCallback, useState } from 'react';
import RichText from '../../../../atoms/RichText/RichText';
import Text from '../../../../atoms/Text';
import { TaskType } from '../../../../../@types/Task/TaskType';
import * as Styled from './Styles.styled';
import Form from '../../../../molecules/Form/Form';
import RichTextInput from '../../../../molecules/Form/RichTextInput/RichTextInput';
import useApi from '../../../../../hooks/useApi';
import Button from '../../../../atoms/Button/Button';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import { Icon } from '../../../../atoms/Icon/Icon';
import EditIcon from '../../../../../Icons/edit.icon';
import { useEntityContext } from '../../../../../Context/EntityContext/EntityContext';
import useLocalStorage from '../../../../../hooks/useLocalStorage';
import Tooltip from '../../../../molecules/Tooltip/Tooltip';
import InfoIcon from '../../../../../Icons/info.icon';
import { fontWeight } from '../../../../../styles/Variables';
import InsightsIcon from '../../../../../Icons/Insights.icon';
import { Link } from 'react-router-dom';
import useUser from '../../../../../hooks/useUser';

type Props = {
  description: TaskType['description'];
  task: TaskType;
};

const TaskSidebarDescription: FunctionComponent<Props> = ({ description, task }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { replace } = useEntityContext();
  const { put } = useApi();
  const [isLocalStorageValueVisible, setIsLocalStorageValueVisible] = useState(true);
  const localStorageKey = `task-${task['@id']}-description`;
  const user = useUser();

  const { storageValue, deleteStorageValue } = useLocalStorage(localStorageKey, null);

  const handleSubmit = useCallback((data: Partial<TaskType>) => {
    void put(task['@id'], data).then((response) => {
      setIsEditing(false);
      replace(response.data);
      setIsLocalStorageValueVisible(false);
    });
  }, []);

  const clickOnGoToProject = useCallback(() => {
    if (!window || !document) return;
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      event: 'go_to_project',
      element: 'task_sidebar_details_button',
      pageTitle: document.title,
      userDepartment: user?.department?.name,
    });
  }, [window, document]);

  return (
    <Styled.Description>
      <Grid $align={'center'} $justifyContent={'space-between'}>
        <GridItem>
          <Styled.Label
            style={{
              marginBottom: '1.6rem',
              marginTop: '0.6rem',
            }}
          >
            Description
          </Styled.Label>
        </GridItem>
        <GridItem>
          <Icon onClick={!!task.deletedAt ? undefined : () => setIsEditing(true)} style={{ marginBottom: '1.6rem', marginTop: '0.6rem' }}>
            <EditIcon />
          </Icon>
        </GridItem>
      </Grid>
      {!isEditing && (
        <>
          {isLocalStorageValueVisible && storageValue && (
            <Styled.InnerSavedDescription
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              {storageValue && <Text className={'info-warning'}>You have unsaved changes to this description:</Text>}
              {storageValue && <RichText content={storageValue} wordsLimit={30} />}
            </Styled.InnerSavedDescription>
          )}
          <Styled.InnerDescription
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <RichText content={description ? description : 'No description provided'} />
            {task.project && (
              <Styled.ProjectButtonWrapper>
                <Text color={'grey'}>
                  <span style={{ fontWeight: fontWeight.bold }}>
                    Before starting your work on the task, check the vital project details,
                    <br />
                  </span>
                  such as remaining time, key agreements, and core brief insights.
                </Text>
                <Link to={`/projects/projects/${task.project?.id}`} onClick={() => clickOnGoToProject()}>
                  <Button $text={'Check the project essentials'} kind={'ghost'} $icon={<InsightsIcon />} iconSize={1.4} />
                </Link>
              </Styled.ProjectButtonWrapper>
            )}
          </Styled.InnerDescription>
        </>
      )}
      {isEditing && (
        <Form onSubmit={handleSubmit} defaultValues={{ description }}>
          <Grid spacing={2} $align={'center'} style={{ width: '100%' }}>
            <GridItem $desktop={12}>
              <RichTextInput name={'description'} localStorageKey={localStorageKey} />
            </GridItem>
            <GridItem>
              <Button $text={'Save'} type={'submit'} />
            </GridItem>
            <GridItem>
              <Button
                $text={'Cancel'}
                type={'button'}
                onClick={() => {
                  deleteStorageValue();
                  setIsEditing(false);
                  setIsLocalStorageValueVisible(false);
                }}
                kind={'ghost'}
              />
            </GridItem>
            <GridItem style={{ position: 'relative', width: '25rem' }}>
              <Tooltip
                content={
                  <Text color={'lightGrey'} size={'s'}>
                    By clicking on "Cancel" button you will delete all unsaved changes
                  </Text>
                }
                trigger={
                  <Icon>
                    <InfoIcon />
                  </Icon>
                }
                arrowPosition={'topCenter'}
              />
            </GridItem>
          </Grid>
        </Form>
      )}
    </Styled.Description>
  );
};

export default TaskSidebarDescription;
