import { TableFiltersType } from '../../../../@types/Table/TableFilterType';
import { ContractTypeDict } from '../../../../@dicts/Contract/ContractTypeDict';
import { ContractAppliesDict } from '../../../../@dicts/Contract/ContractAppliesDict';
import ContractorOption from '../../../molecules/Finances/ContractorOption';
import ContractAppliesFilter from './component/ContractAppliesFilter';

export const contractListFilters: TableFiltersType = [
  {
    type: 'select',
    name: 'contractor.id',
    label: 'Vendor',
    placeholder: 'Select vendor',
    options: {
      baseUrl: '/api/contractors',
      optionComponent: ContractorOption,
    },
    gridProps: {
      $desktop: 3,
    },
  },
  {
    type: 'select',
    name: 'company.id',
    label: 'Company',
    options: {
      baseUrl: '/api/financial_companies',
    },
    gridProps: {
      $desktop: 3,
    },
  },
  {
    type: 'select',
    name: 'branch.id',
    label: 'Location',
    options: {
      baseUrl: '/api/branches',
      optionLabel: 'shortName',
    },
    gridProps: {
      $desktop: 3,
    },
  },
  {
    type: 'select',
    name: 'owner.id',
    label: 'Owner',
    options: {
      baseUrl: '/api/employees',
    },
    gridProps: {
      $desktop: 3,
    },
  },
  {
    type: 'select',
    name: 'type',
    label: 'Type',
    options: {
      options: ContractTypeDict,
    },
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'select',
    name: 'applies',
    label: 'Contract applies',
    options: {
      options: ContractAppliesDict,
    },
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'select',
    name: 'confidential',
    label: 'Confidential',
    options: {
      options: [
        { value: 'true', label: 'Yes' },
        { value: 'false', label: 'No' },
      ],
    },
    gridProps: {
      $desktop: 2,
    },
  },
  {
    type: 'custom',
    name: 'applies',
    label: 'Contract applies',
    options: {
      component: ContractAppliesFilter,
    },
    gridProps: {
      $desktop: 6,
    },
  },
];
