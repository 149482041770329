import React, { FunctionComponent } from 'react';
import * as Styled from './Details.styled';
import Heading from '../../../../../atoms/Heading';
import { Grid } from '../../../../../atoms/Grid/Grid';
import { parseDate } from '../../../../../../helpers/date';
import IdText from '../../../../IdText/IdText';
import InventoryItemType from '../../../../../../@types/Inventory/InventoryItemType';
import SingleDetail from '../../../../SingleDetail/SingleDetail';

type Props = {
  entity: InventoryItemType;
};

const Details: FunctionComponent<Props> = ({ entity }) => {
  return (
    <Styled.Details>
      <Heading size={'s'}>Details</Heading>
      <Styled.DetailsWrapper>
        <SingleDetail title={'Purchase date'} bold={true}>
          {parseDate(entity.productionDate)}
        </SingleDetail>
        <SingleDetail title={'Guarantee date'} bold={true}>
          {parseDate(entity.warrantyDate)}
        </SingleDetail>
        <SingleDetail title={'CostID'}>
          <IdText withHash={true} value={entity.costId ?? 'N/A'} />
        </SingleDetail>
      </Styled.DetailsWrapper>
      <Grid style={{ marginTop: '2.4rem' }}>
        {entity.comment && (
          <SingleDetail bold={true} title={'Comment'}>
            {entity.comment}
          </SingleDetail>
        )}
      </Grid>
    </Styled.Details>
  );
};

export default Details;
