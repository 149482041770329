import React, { FunctionComponent } from 'react';
import * as Styled from './SwitchButton.styled';
import Text from '../../atoms/Text';
import SpeakerIcon from '../../../Icons/Speaker.icon';
import GridIcon from '../../../Icons/Grid.icon';
import { color } from '../../../styles/Variables';
import { Icon } from '../../atoms/Icon/Icon';

type SwitchButton = {
  onClick: () => void;
  name: string;
  isActive: boolean;
  label?: number;
};

type Props = {
  leftButton: SwitchButton;
  rightButton: SwitchButton;
};

const SwitchButton: FunctionComponent<Props> = ({ leftButton, rightButton }) => {
  return (
    <Styled.SwitchWrapper>
      <Styled.LeftButton onClick={leftButton.onClick} $isActive={leftButton.isActive}>
        <Icon size={1.5} color={leftButton.isActive ? color.neutral['80'] : color.neutral[60]}>
          <SpeakerIcon />
        </Icon>
        <Text color={leftButton.isActive ? 'darkGrey' : 'neutral60'} size={'l'} style={{ fontWeight: '500' }}>
          {leftButton.name}
        </Text>
        {leftButton.label && leftButton.label > 0 ? (
          <Styled.FeedIndicator>
            <Styled.FeedIndicatorText>{String(leftButton.label).length > 2 ? String(leftButton.label)[0] + '+' : leftButton.label}</Styled.FeedIndicatorText>
          </Styled.FeedIndicator>
        ) : null}
      </Styled.LeftButton>
      <Styled.RightButton onClick={rightButton.onClick} $isActive={rightButton.isActive}>
        <Icon size={1.5} color={rightButton.isActive ? color.neutral['80'] : color.neutral[60]}>
          <GridIcon />
        </Icon>
        <Text color={rightButton.isActive ? 'darkGrey' : 'neutral60'} size={'l'} style={{ fontWeight: '500' }}>
          {rightButton.name}
        </Text>
      </Styled.RightButton>
      {rightButton.label && rightButton.label > 0 && (
        <Styled.FeedIndicator>
          <Styled.FeedIndicatorText>{rightButton.label}</Styled.FeedIndicatorText>
        </Styled.FeedIndicator>
      )}
    </Styled.SwitchWrapper>
  );
};

export default SwitchButton;
