import React, { FunctionComponent } from 'react';
import { TextInput } from '../../../../../../components/molecules/Form/TextInput/TextInput';
import { Card } from '../../../../../../components/atoms/Card/Card';
import { Grid, GridItem } from '../../../../../../components/atoms/Grid/Grid';
import CardHeading from '../../../../../../components/molecules/CardHeading/CardHeading';

type Props = {};

const PersonBasicDataForm: FunctionComponent<Props> = (props) => {
  return (
    <GridItem $desktop={6}>
      <Card>
        <CardHeading heading={'Basic information'} />
        <Grid spacing={2}>
          <GridItem $desktop={6}>
            <TextInput name={'firstName'} label={'First name'} required placeholder={'John'} />
          </GridItem>
          <GridItem $desktop={6}>
            <TextInput name={'lastName'} label={'Last name'} required placeholder={'Smith'} />
          </GridItem>
          <GridItem $desktop={6}>
            <TextInput name={'login'} label={'Login'} required placeholder={'j.smith'} />
          </GridItem>
          <GridItem $desktop={6}>
            <TextInput name={'email'} label={'e-mail'} required placeholder={'j.smith@capemorris.com'} />
          </GridItem>
          <GridItem $desktop={6}>
            <TextInput name={'phoneNumber'} label={'Phone'} placeholder={'500500500'} />
          </GridItem>
        </Grid>
      </Card>
    </GridItem>
  );
};

export default PersonBasicDataForm;
