import React, { FunctionComponent, useCallback, useRef, useState } from 'react';
import TaskNameInput from 'components/molecules/Task/TaskInlineForm/TaskNameInput/TaskNameInput';
import Form from 'components/molecules/Form/Form';
import Button from 'components/atoms/Button/Button';
import * as Styled from './TaskInlineForm.styled';
import { DictValue } from '../../../../@types/Dictionary/DictValue';
import { ProjectType } from '../../../../@types/Project/Project';
import useApi from 'hooks/useApi';
import TaskDeadlineDropdown from '../TaskForm/TaskDeadlineDropdown/TaskDeadlineDropdown';
import PlusIcon from 'Icons/Plus.icon';
import HiddenInput from 'components/atoms/Form/HiddenInput/HiddenInput';
import { SectionType } from '../../../../@types/Section/SectionType';
import { useEntityContext } from 'Context/EntityContext/EntityContext';
import { color, fontSize } from '../../../../styles/Variables';
import InlineInput from '../TaskForm/InlineInput/InlineInput';
import CalendarIcon from '../../../../Icons/Calendar.icon';
import PersonIcon from '../../../../Icons/Project/Person.icon';
import TaskEmployeeDropdown from '../TaskForm/TaskEmployeeDropdown/TaskEmployeeDropdown';
import Chip from '../../../atoms/Chips/Chip';
import { parseDate } from '../../../../helpers/date';
import Person from '../../Person/Person';
import { Icon } from '../../../atoms/Icon/Icon';
import TaskProjectDropdown from '../TaskForm/TaskProjectDropdown/TaskProjectDropdown';
import ChecklistIcon from '../../../../Icons/Checklist.icon';
import { TaskType } from '../../../../@types/Task/TaskType';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import BetterChip from '../../../atoms/Chips/BetterChip';

type PropType = {
  section?: Partial<SectionType>;
  context?: 'dashboard' | 'projectDetails';
  onCreate?: (task: TaskType) => void;
};

const TaskInlineForm: FunctionComponent<PropType> = ({ section, context = 'dashboard', onCreate }) => {
  const { post } = useApi();
  const [key, setKey] = useState(0);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const { entity: project } = useEntityContext<ProjectType>();
  const inputWrapperRef = useRef<HTMLDivElement | null>(null);
  const titleWrapperRef = useRef<HTMLDivElement | null>(null);

  const onFocusLost = useCallback(() => {
    // @ts-ignore
    titleWrapperRef.current?.children[0].focus();
  }, [titleWrapperRef, visible]);

  useOutsideClick(inputWrapperRef, () => setVisible(false));

  const onSubmit = useCallback(async (data: any) => {
    if (!data.title) return;
    await post('/tasks', data).then((r) => {
      setKey(Math.random());
      onCreate && onCreate(r.data);
    });
  }, []);

  return (
    <Form onSubmit={onSubmit} key={key}>
      {() => {
        return (
          <Styled.InputWrapper ref={inputWrapperRef}>
            <Button
              kind={'link'}
              $text={''}
              $icon={
                context === 'dashboard' ? (
                  <Icon size={2} onClick={() => {}}>
                    <PlusIcon isNoDashedCircle />
                  </Icon>
                ) : (
                  <></>
                )
              }
              iconSize={context === 'dashboard' ? 1.85 : 1}
              onClick={() => {
                setVisible(true);
              }}
              style={{ padding: 0, paddingLeft: '0.62rem', cursor: 'unset' }}
              iconColor={color.neutral[70]}
            />
            <div style={{ flexGrow: 1 }} ref={titleWrapperRef}>
              <TaskNameInput
                name={'title'}
                placeholder={'Click to add task'}
                fontSize={context === 'dashboard' ? fontSize.body.xl : context === 'projectDetails' ? fontSize.body.l : fontSize.body.xl}
                onClick={() => {
                  setVisible(true);
                }}
              />
            </div>
            <Styled.InputWrapper>
              {visible && (
                <div style={{ display: 'flex', gap: '1rem' }}>
                  {!section && (
                    <InlineInput
                      name={'project'}
                      icon={<ChecklistIcon />}
                      dropdown={TaskProjectDropdown}
                      label={false}
                      customHandler={(v) => (
                        <BetterChip customBackgroundColor={v.meta.client.color}>
                          #{v.meta.id}&nbsp;
                          {v.meta.name}
                        </BetterChip>
                      )}
                      handlerSize={'sm'}
                      translateXValue={'-20rem'}
                      onFocusLost={onFocusLost}
                    />
                  )}
                  <InlineInput
                    name={'deadline'}
                    icon={<CalendarIcon />}
                    dropdown={TaskDeadlineDropdown}
                    parseValue={(v: DictValue) => (
                      <Chip $bgColor={'transparent'} $hoverColor={color.neutral['40']}>
                        {parseDate(v.value)}
                      </Chip>
                    )}
                    customHandler={() => <></>}
                    handlerSize={'sm'}
                    translateXValue={'-13rem'}
                    onFocusLost={onFocusLost}
                  />
                  <InlineInput
                    name={'assignee'}
                    icon={<PersonIcon />}
                    dropdown={TaskEmployeeDropdown}
                    label={false}
                    customHandler={(v) => <Person person={v.meta} hideName size={2.4} />}
                    handlerSize={'sm'}
                    translateXValue={'-19rem'}
                    onFocusLost={onFocusLost}
                  />
                </div>
              )}
            </Styled.InputWrapper>
            {section && <HiddenInput name={'section'} value={section['@id']} />}
            {project && <HiddenInput name={'project'} value={project['@id']} />}
            <Button
              id={'inline-form-submit-button'}
              buttonRef={buttonRef}
              $text={'Submit'}
              type={'submit'}
              style={{
                visibility: 'hidden',
                opacity: '0',
                height: '0',
                width: '0',
                padding: '0',
              }}
            />
          </Styled.InputWrapper>
        );
      }}
    </Form>
  );
};

export default TaskInlineForm;
