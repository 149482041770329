import styled, { css } from 'styled-components';
import { BodyText } from '../../../styles/Typography';
import { color, fontSize, fontWeight, lineHeight } from '../../../styles/Variables';

export type ContentProps = {
  $isTruncated: boolean;
};

export const Content = styled.div<ContentProps>`
  ${(props: ContentProps) =>
    !props.$isTruncated &&
    css`
      height: unset;
    `}
`;

export const RichTextStyles = css`
  ${BodyText};

  * {
    line-height: ${lineHeight.body.l};
    font-size: ${fontSize.body.l};
    font-weight: ${fontWeight.regular};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol,
  blockquote {
    margin-bottom: 1.1rem;
  }

  b,
  strong {
    font-weight: ${fontWeight.bold};
  }

  i {
    font-style: italic;
  }

  h1,
  h2,
  h3,
  h4 {
    color: ${color.primary[60]};
    font-weight: ${fontWeight.bold};
  }

  h1,
  h1 strong {
    font-size: ${fontSize.heading.l};
  }

  h2,
  h2 strong {
    font-size: ${fontSize.heading.m};
  }

  h3,
  h3 strong {
    font-size: ${fontSize.heading.s};
  }

  h4,
  h4 strong {
    font-size: ${fontSize.heading.xs};
  }

  a {
    color: ${color.primary[60]};
    transition: color 250ms ease-in-out;

    &:hover {
      color: ${color.primary[50]};
    }
  }

  a[target='_blank']::after {
    content: ' ↗';
    color: ${color.neutral[60]};
  }

  ul li,
  ol li {
    margin-bottom: 0;
    margin-left: 2rem;
  }

  ol,
  ul {
    padding-left: 1.8rem;
  }

  pre {
    background: ${color.neutral[30]};
    border: 1px solid ${color.neutral[40]};
    border-left: 3px solid ${color.primary[60]};
    color: ${color.neutral[70]};
    page-break-inside: avoid;
    font-family: monospace;
    font-size: ${fontSize.body.m};
    line-height: ${lineHeight.ui.l};
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 1em 1.5em;
    display: block;
    word-wrap: break-word;
  }

  td,
  th {
    border: 1px solid ${color.neutral[50]};
    padding: 1rem;
    text-align: center;
  }

  blockquote {
    width: 80%;
    font-style: italic;
    color: ${color.neutral[80]};
    padding: 0.8rem 0.4rem 0.8em 1rem;
    border-left: 4px solid ${color.primary[60]};
    background: ${color.neutral[40]};

    p {
      margin-bottom: unset;
    }
  }

  mark {
    background-color: ${color.primary[30]};
  }

  img {
    max-width: 100%;
    margin-top: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    height: auto;
  }

  figure {
    &.image-style-side {
      justify-content: flex-end;
      display: unset;
      flex-direction: unset;
      align-items: unset;
    }
  }

  .image {
    align-self: center;
    justify-content: center;
  }

  figure {
    display: unset;
  }

  figcaption {
    color: ${color.neutral['60']};
    font-size: ${fontSize.body.s};
  }

  .mention {
    color: ${color.primary[80]};
  }

  img,
  .raw-html-embed {
    max-width: 100%;
    border-radius: 8px;
    display: block;
    margin-bottom: 1.6rem;
  }

  code {
    font-size: ${fontSize.body.m};
    background-color: ${color.neutral[50]};
    border-radius: 4px;
    padding: 3px;
  }

  p:last-child {
    margin-bottom: unset;
  }

  ul.todo-list {
    list-style-type: none;
    padding-left: 0;
  }

  .todo-list__label {
    display: flex;
    align-items: center;
  }

  input[type='checkbox'] {
    width: 1.6rem;
    height: 1.6rem;
    padding-bottom: 0;
    margin-bottom: 0;
    appearance: auto;
  }
`;

type RichTextContainerStyles = {
  $context?: string;
  $paragraphStyles?: {
    fontSize?: string;
    color?: string;
  };
};
export const RichTextContainer = styled.div<RichTextContainerStyles>`
  ${RichTextStyles};
  display: flex;
  flex-flow: column;
  ${(props: RichTextContainerStyles) =>
    props.$context === 'dashboard' &&
    css`
      margin-top: 1rem;

      figure {
        margin-bottom: 0.7rem;

        img {
          height: 20rem;
          object-fit: cover;
          width: auto;
          margin: 0 auto;
        }
      }
    `}

  ${(props: RichTextContainerStyles) => {
    if (props.$paragraphStyles) {
      return css`
        p,
        span {
          font-size: ${props.$paragraphStyles.fontSize};
        }

        p {
          color: ${props.$paragraphStyles.color};
        }
      `;
    }
  }};
`;
