import styled from 'styled-components';
import { color } from '../../../../../../../styles/Variables';

export const InventoryProtocolItemExtendedRowWrapper = styled.tr`
  background-color: ${color.neutral['20']};
  border-bottom: 1px solid ${color.neutral['40']};
  margin-top: 2rem;
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;
