import React, { FunctionComponent, useCallback } from 'react';
import { Item } from '../Commander';
import { Command } from 'cmdk';
import { Colored } from '../../../../legacy/CapeMorris/components/layout/layout.styled';
import { useNavigate } from 'react-router-dom';
import BetterChip from '../../../atoms/Chips/BetterChip';

type Props = {
  item: Item<'projectId' | 'archived'>;
  onSelect: (value: string) => void;
};

const ProjectCommanderItem: FunctionComponent<Props> = ({ item, onSelect }) => {
  const navigate = useNavigate();
  const handleSelect = useCallback(() => {
    navigate(`/projects/projects/${item.metadata?.projectId}`);
    onSelect?.(item.value);
  }, []);
  return (
    <Command.Item onSelect={handleSelect} value={`${item.metadata?.projectId}-${item.value}`}>
      {item.icon && React.createElement(item.icon)}
      <Colored>#{item.metadata?.projectId}</Colored> {item.value}
      {item.metadata?.archived && <BetterChip>archived</BetterChip>}
      <span cmdk-raycast-meta="">Project</span>
    </Command.Item>
  );
};
export default ProjectCommanderItem;
