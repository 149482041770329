import { CellTemplate } from '../../CellTemplate';
import React from 'react';
import { BasicEmployeeType } from '../../../../../@types/Employee/EmployeeType';
import { TaskListType } from '../../../../../@types/Task/TaskType';
import { Grid } from '../../../../atoms/Grid/Grid';
import EditableDetail from '../../../EditableDetail/EditableDetail';
import Person from '../../../Person/Person';
import TaskEmployeeDropdown from '../../../Task/TaskForm/TaskEmployeeDropdown/TaskEmployeeDropdown';
import { color } from '../../../../../styles/Variables';
import Chip from '../../../../atoms/Chips/Chip';
import { TextSizeType } from '../../../../../@types/Text/TextSizeType';
import { ColorType } from '../../../../../@types/Color/ColorType';

const TaskAssigneeTemplate: CellTemplate<
  BasicEmployeeType,
  { colored?: boolean; areInitials?: boolean; textProperties?: { shorten?: number; bold?: boolean; size?: TextSizeType; color?: ColorType } },
  TaskListType
> = ({ value, options = {}, item }) => {
  return (
    <Grid spacing={0.6} $align={'center'} style={{ flexWrap: 'nowrap' }}>
      {value && (
        <>
          <EditableDetail<TaskListType>
            name={'assignee'}
            subject={item}
            show={
              <Person
                person={item.assignee}
                size={2.2}
                textSize={options?.textProperties?.size ?? 's'}
                textColor={options?.textProperties?.color ?? 'darkGrey'}
                bold={options?.textProperties?.bold ?? false}
              />
            }
            component={TaskEmployeeDropdown}
          />
        </>
      )}
    </Grid>
  );
};
export default TaskAssigneeTemplate;
