import React, { FunctionComponent } from 'react';
import { RouterPageType } from '../../../../@types/RouterPage/RouterPageType';
import TabsNavigatorRouter from '../../../../components/molecules/TabsNavigatorRouter/TabsNavigatorRouter';
import CostsList from '../../../../components/templates/Costs/CostsList/CostsList';
import SubcostsList from '../../../../components/templates/Costs/SubcostsList/SubcostsList';

type Props = {};

const CostsListScreen: FunctionComponent<Props> = (props) => {
  const pages: RouterPageType[] = [
    {
      name: 'Costs',
      to: '/costs',
      screen: <CostsList />,
    },
    {
      name: 'Assignments',
      to: '/assignments',
      screen: <SubcostsList />,
    },
  ];
  return <TabsNavigatorRouter pages={pages} />;
};

export default CostsListScreen;
