import { CellTemplate } from '../../CellTemplate';
import { ProjectListItemType } from '../../../../../@types/Project/Project';
import React from 'react';
import { Grid, GridItem } from '../../../../atoms/Grid/Grid';
import { Colored } from '../../../../../legacy/CapeMorris/components/layout/layout.styled';
import { shorten } from '../../../../../helpers/shorten';
import EmptyCell from '../EmptyCell';
import Text from '../../../../atoms/Text';

const ProjectTemplate: CellTemplate<ProjectListItemType, {}, ProjectListItemType> = ({ item, value, ...props }) => {
  const object = React.useMemo<ProjectListItemType>(() => {
    if (item['@type'] === 'Project') {
      return item;
    }
    return value;
  }, [item]);

  return (
    <>
      {object && (
        <Grid style={{ maxWidth: 200, minWidth: 150 }}>
          <GridItem $desktop={12} style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
            <Colored>#{object.id}</Colored> {shorten(object.name, 25, 25)}
          </GridItem>
          <GridItem $desktop={12}>
            <Text>
              {object.client.name} <Colored>/</Colored> {object.category.name}
            </Text>
          </GridItem>
        </Grid>
      )}
      {!object && (
        <Grid style={{ maxWidth: 300 }}>
          <GridItem $desktop={12}>
            <EmptyCell />
          </GridItem>
        </Grid>
      )}
    </>
  );
};
export default ProjectTemplate;
