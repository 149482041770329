import React, { FunctionComponent } from 'react';
import SelectInput from '../SelectInput/SelectInput';
import { InputProps } from '../../../../@types/Form/InputProps';

type Props = InputProps & {
  baseUrl: string;
  name?: string;
};

const TagsInput: FunctionComponent<Props> = ({ baseUrl, name = 'tags', ...props }) => {
  return <SelectInput label={'Tags'} name={name} baseUrl={baseUrl} optionLabel={'value'} {...props} isMulti creatable />;
};

export default TagsInput;
