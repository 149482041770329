import React, { FunctionComponent } from 'react';

type PropType = {
  size?: number;
  color?: string;
};

const ReopenIcon: FunctionComponent<PropType> = ({ size = 1, color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g>
        <path
          d="M11 21.95C9.73333 21.8167 8.55417 21.4708 7.4625 20.9125C6.37083 20.3542 5.42083 19.6292 4.6125 18.7375C3.80417 17.8458 3.16667 16.825 2.7 15.675C2.23333 14.525 2 13.3 2 12C2 10.4833 2.30417 9.08333 2.9125 7.8C3.52083 6.51667 4.35 5.41667 5.4 4.5H3V2.5H9V8.5H7V5.775C6.08333 6.50833 5.35417 7.4125 4.8125 8.4875C4.27083 9.5625 4 10.7333 4 12C4 14.05 4.67083 15.8208 6.0125 17.3125C7.35417 18.8042 9.01667 19.675 11 19.925V21.95ZM10.575 16.6L6.35 12.35L7.75 10.95L10.575 13.775L16.25 8.1L17.65 9.525L10.575 16.6ZM15 21.5V15.5H17V18.225C17.9167 17.475 18.6458 16.5667 19.1875 15.5C19.7292 14.4333 20 13.2667 20 12C20 9.95 19.3292 8.17917 17.9875 6.6875C16.6458 5.19583 14.9833 4.325 13 4.075V2.05C15.5333 2.3 17.6667 3.36667 19.4 5.25C21.1333 7.13333 22 9.38333 22 12C22 13.5167 21.6958 14.9167 21.0875 16.2C20.4792 17.4833 19.65 18.5833 18.6 19.5H21V21.5H15Z"
          fill={color ? color : 'currentColor'}
        />
      </g>
    </svg>
  );
};

export default ReopenIcon;
