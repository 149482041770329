import React from 'react';
import TagsInput from '../Form/TagsInput/TagsInput';
import { TableFilterInterface } from './TableFilterInterface';

export type TagsFilterOptions = {
  baseUrl: string;
};

const TagsFilter: TableFilterInterface<TagsFilterOptions> = ({ name, filter, options, onChange, defaultValue }) => {
  return <TagsInput name={`${name}__id`} baseUrl={options?.baseUrl ?? ''} label={filter.label ?? name} onChange={onChange} defaultValue={defaultValue} />;
};

export default TagsFilter;
