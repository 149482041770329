import React, { FunctionComponent } from 'react';
import useTaskSidebar from '../../../../../../../../hooks/useTaskSidebar';
import TaskInlineForm from '../../../../../../../molecules/Task/TaskInlineForm/TaskInlineForm';
import { SectionType } from '../../../../../../../../@types/Section/SectionType';
import SectionTaskDetails from './SectionTaskDetails';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import * as Styled from './SectionBody.styled';
import { Icon } from '../../../../../../../atoms/Icon/Icon';
import DragIcon from '../../../../../../../../Icons/Drag.icon';
import { color } from '../../../../../../../../styles/Variables';
import TickIcon from '../../../../../../../../Icons/TickIcon';
import Text from '../../../../../../../atoms/Text';
import { TaskListType } from '../../../../../../../../@types/Task/TaskType';
import AttachmentIcon from '../../../../../../../../Icons/Attachment.icon';

type Props = {
  section: Partial<SectionType>;
  allTasks: TaskListType[];
  showCompleted: boolean;
  setShowCompleted: (showCompleted: boolean) => void;
};
const SectionBody: FunctionComponent<Props> = ({ section, allTasks, showCompleted, setShowCompleted }) => {
  const { openTask } = useTaskSidebar();

  return (
    <>
      <Droppable droppableId={section['@id'] ?? 'without-section'} type="TASK" isCombineEnabled={false} ignoreContainerClipping={false}>
        {(droppableProvided, snapshot) => (
          <Styled.DroppableArea {...droppableProvided.droppableProps} ref={droppableProvided.innerRef} $isDraggingOver={snapshot.isDraggingOver}>
            {allTasks?.length === 0 && <Styled.Empty>no tasks in this section</Styled.Empty>}
            {allTasks?.map((task, index) => (
              <Draggable key={task['@id']} draggableId={task['@id']} index={index + 1}>
                {(provided, snapshot) => (
                  <Styled.Task $isDragging={snapshot.isDragging} ref={provided.innerRef} {...provided.draggableProps} onClick={() => openTask(task['@id'])}>
                    <Styled.NameWrapper>
                      <Styled.Icons>
                        <Styled.DragHandler {...provided.dragHandleProps}>
                          <Icon size={1.2} onClick={(e) => e.stopPropagation()}>
                            <DragIcon color={color.neutral[60]} />
                          </Icon>
                        </Styled.DragHandler>
                        <Icon disabled={true} size={1.5} color={color.neutral[60]}>
                          {task.status === 'completed' ? <TickIcon checked={true} /> : <TickIcon />}
                        </Icon>
                      </Styled.Icons>
                      <Text color={'darkGrey'}>{task.title}</Text>
                      {task.hasResources && (
                        <Icon>
                          <AttachmentIcon />
                        </Icon>
                      )}
                    </Styled.NameWrapper>
                    <SectionTaskDetails task={task} />
                  </Styled.Task>
                )}
              </Draggable>
            ))}
            {droppableProvided.placeholder}
          </Styled.DroppableArea>
        )}
      </Droppable>
      {!showCompleted && section.completedTaskCount !== 0 && (
        <Styled.ShowCompletedButton onClick={() => setShowCompleted(true)}>Show completed (+{section.completedTaskCount})</Styled.ShowCompletedButton>
      )}
      {showCompleted && section.completedTaskCount !== 0 && (
        <Styled.ShowCompletedButton onClick={() => setShowCompleted(false)}>Hide completed</Styled.ShowCompletedButton>
      )}

      <div style={{ marginLeft: '1.6rem' }}>
        <TaskInlineForm section={section} context={'projectDetails'} />
      </div>
    </>
  );
};
export default SectionBody;
