import styled from 'styled-components';
import { color } from 'styles/Variables';

export const Container = styled.div`
  height: 100%;
  width: 100%;
`;

export const Button = styled.button`
  border: 1px dashed ${color.neutral[60]};
  height: 6.4rem;
  width: 6.4rem;
  border-radius: 0.5rem;
  background: transparent;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  color: ${color.neutral[60]};
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: ${color.neutral[30]};
  }
`;
