import styled from 'styled-components';

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;

  > div {
    padding-bottom: 0.3rem;
  }
`;

export const Line = styled.span`
  display: block;
  gap: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  max-width: 26rem;
`;
