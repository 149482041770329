import React, { FunctionComponent } from 'react';
import { AbsenceType } from '../../../../../@types/Absences/AbsenceType';
import Button from '../../../../atoms/Button/Button';
import useApi from '../../../../../hooks/useApi';
import SnackContainer from '../../../../../legacy/CapeMorris/components/snack-bar/use-snack-container';
import { AxiosError } from 'axios';

type Props = {
  absence: AbsenceType;
};

const AcceptButton: FunctionComponent<Props> = ({ absence }) => {
  const { post } = useApi();
  const { showSnack } = SnackContainer.useContainer();

  const acceptAbsence = () => {
    return post(absence['@id'].replace('absence_requests', 'requests') + '/sign')
      .then(() => {
        showSnack({
          title: 'Success',
          message: 'Absence accepted',
          type: 'success',
        });
      })
      .catch((error: any) => {
        showSnack({
          title: 'Error',
          message: error.response?.data['hydra:description'] || error.message || 'Absence can not be accepted',
          type: 'error',
        });
      });
  };

  return <Button $text={'Accept'} kind={'secondary'} onClick={acceptAbsence} />;
};

export default AcceptButton;
