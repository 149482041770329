import React, { FunctionComponent, useContext } from 'react';
import * as Styled from './FeedEntriesStyled';
import { AuthContext } from '../../../../../Context/auth-context/auth-context';
import NewFeedEntry from 'components/molecules/NewWallEntry/NewFeedEntry';
import FeedEntryItem from './WallEntryItem/FeedEntryItem';
import { FeedEntryType } from '../../../../../@types/FeedEntry/FeedEntryType';

type Props = {
  wallEntries: FeedEntryType[] | undefined;
  onNewEntry: (entry: FeedEntryType) => void;
};

const FeedEntries: FunctionComponent<Props> = ({ wallEntries, onNewEntry }) => {
  const { can } = useContext(AuthContext);

  return (
    <Styled.FeedEntriesStyled>
      {can('WALL.CAN_MANAGE') && <NewFeedEntry onNewEntry={onNewEntry} />}
      {wallEntries && wallEntries.map((feedEntry, idx) => <FeedEntryItem feedEntry={feedEntry} key={feedEntry['@id']} feedEntryIndex={idx} />)}
    </Styled.FeedEntriesStyled>
  );
};

export default FeedEntries;
