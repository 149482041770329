import React, { FunctionComponent } from 'react';
import IriHelper from '../../../../../helpers/iri-helper';
import { FinancialCompanyContext } from '../../../../../Context/BranchContext/FinancialCompanyContext';
import TablePage from '../../../../../components/organisms/TablePage/TablePage';
import HeadingRight from './HeadingRight';
import tableFilters from './Filters/Table.filters';
import properties from './IncoicesTable.properties';
import useTableReload from '../../../../../hooks/Table/useTableReload';
import DownloadIcon from '../../../../../Icons/DownloadIcon';
import { createFullApiUrl } from '../../../../../services/api';
import Container from '../../../../../components/atoms/Container/Container';

const CostsTable: FunctionComponent = () => {
  const {
    state: { financialCompany },
  } = React.useContext(FinancialCompanyContext);
  const [selectedItems, setSelectedItems] = React.useState<any[]>([]);

  const { key, reload } = useTableReload();

  const handleDownload = React.useCallback(
    (name: string, element: any) => {
      const link = document.createElement('a');
      link.href = createFullApiUrl(`/exchange/costs/${IriHelper.iriToId(element['@id'])}/download`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setTimeout(() => {
        reload();
      }, 1000);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [reload],
  );

  return (
    <Container>
      <TablePage
        url={'/exchange/costs'}
        context={'/api/costs'}
        onSelect={setSelectedItems}
        filters={tableFilters}
        properties={properties}
        heading={'Costs'}
        reloadKey={key}
        exportUrl={'/exchange/costs/export'}
        globalFilters={{
          'financialCompany.id': financialCompany ? IriHelper.iriToId(financialCompany['@id']) : null,
        }}
        rowActions={[
          {
            hidden: false,
            name: 'download',
            label: 'Download',
            onClick: handleDownload,
            icon: <DownloadIcon />,
          },
        ]}
        headingRight={<HeadingRight invalidateCallback={reload} context={'costs'} selectedItems={selectedItems} />}
      />
    </Container>
  );
};

export default CostsTable;
