import React, { FunctionComponent } from 'react';
import SnackContainer from '../../../../../legacy/CapeMorris/components/snack-bar/use-snack-container';
import { AbsenceType } from '../../../../../@types/Absences/AbsenceType';
import Button from '../../../../atoms/Button/Button';
import useApi from '../../../../../hooks/useApi';
import { AxiosError } from 'axios';

type Props = {
  absence: AbsenceType;
};

const RejectButton: FunctionComponent<Props> = ({ absence }) => {
  const { post } = useApi();
  const { showSnack } = SnackContainer.useContainer();

  const rejectAbsence = () => {
    return post(absence['@id'].replace('absence_requests', 'requests') + '/reject').catch((error: any) => {
      showSnack({
        title: 'Error',
        message: error.response?.data['hydra:description'] || error.message,
        type: 'error',
      });
    });
  };

  return <Button $text={'Reject'} kind={'link'} onClick={rejectAbsence} />;
};

export default RejectButton;
