import { TeamMemberType } from '../../../@types/TeamMember/TeamMemberType';
import { AxiosResponse } from 'axios';
import React, { FunctionComponent, useEffect } from 'react';
import { CustomOptionsType, CustomOptionType } from '../../../@types/Select/CustomOptionsType';
import { AuthContext } from '../../../Context/auth-context/auth-context';
import api from '../../../services/api';
import Select, { OnChangeValue } from 'react-select';
import { TeamSelectorStyles } from './TeamSelectorStyles';
import TeamSelectorOption from './TeamSelectorOption';

type Props = {
  onSelect: (id?: number | number[], object?: TeamMemberType | TeamMemberType[]) => void;
  context?: 'project-leaders' | undefined;
  companyTeamRole?: string;
  isMulti?: boolean;
};
type ResponseType = AxiosResponse<{
  'hydra:member': TeamMemberType[];
}>;
const TeamSelector: FunctionComponent<Props> = (props) => {
  const [team, setTeam] = React.useState<CustomOptionsType<TeamMemberType, number>>([]);
  const { can } = React.useContext(AuthContext);

  const url = React.useMemo(() => {
    return props.companyTeamRole && can(props.companyTeamRole) ? '/my-company-team' : '/my-team';
  }, []);

  React.useEffect(() => {
    let params = {};
    if (props.context) {
      params = {
        context: props.context,
      };
    }
    api.get(url, { params }).then((response: ResponseType) => {
      const team = response.data['hydra:member'];
      const mapped: CustomOptionsType<TeamMemberType, number> = team.map((member) => ({
        value: member.id,
        label: member.name,
        object: member,
      }));
      setTeam(mapped);
    });
  }, [props.context, url]);

  const handleChange = React.useCallback(
    (value: OnChangeValue<CustomOptionType<TeamMemberType, number>, true | false>) => {
      if (Array.isArray(value)) {
        props.onSelect(
          value.map((v) => v.value),
          value.map((v) => v.object),
        );
        return;
      } else {
        //@ts-ignore
        props.onSelect(value?.value, value?.object);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [team, props.onSelect],
  );

  useEffect(() => {
    if (team.length > 0) {
      props.onSelect(team[0].value, team[0].object);
    }
  }, [team]);

  return (
    <>
      {team.length > 0 && (
        <Select
          options={team}
          defaultValue={team[0]}
          isMulti={props.isMulti}
          onChange={handleChange}
          isClearable={true}
          styles={TeamSelectorStyles}
          components={{ Option: TeamSelectorOption }}
        />
      )}
    </>
  );
};
export default TeamSelector;
