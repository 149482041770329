import React, { ReactNode } from 'react';
import { LocaleContext } from './locale-context';

type Props = {
  children: ReactNode;
};

export const LocaleProvider: React.FC<Props> = ({ children }) => {
  const { locale, availableLocales } = React.useContext(LocaleContext);

  return <LocaleContext.Provider value={{ locale, availableLocales }}>{children}</LocaleContext.Provider>;
};
