import styled, { css } from 'styled-components';
import { color, fontSize, fontWeight } from '../../../../styles/Variables';

export const Head = styled.thead`
  color: ${color.primary[60]};
  border-collapse: separate;
  position: sticky;
  top: 0;
  z-index: 10;
  background: ${color.light};
`;

export const ExtendColumn = styled.th`
  border-bottom: 1px solid ${color.neutral[40]};
  padding: 1rem 1.5rem;
  width: 2rem;
  vertical-align: middle;
`;
export const SelectColumn = styled.th`
  border-bottom: 1px solid ${color.neutral[40]};
  padding: 1rem 1rem;
  width: 1rem;
  vertical-align: middle;
`;
export const Column = styled.th<{ $clickable?: boolean; $minWidth?: number }>`
  min-width: 9rem;
  text-align: left;
  border-bottom: 1px solid ${color.neutral[40]};
  padding: 1rem 1rem;
  vertical-align: middle;
  font-size: ${fontSize.body.l};

  ${(props) =>
    props.$minWidth &&
    css`
      min-width: ${props.$minWidth}rem;
    `}
  ${(props) =>
    props.$clickable &&
    css`
      cursor: pointer;
    `}
  &:first-child {
    padding-left: 1.5rem;
  }

  &:last-child {
    padding-right: 1.5rem;
  }
`;

export const Label = styled.p`
  color: ${color.primary[80]};
  font-size: ${fontSize.body.m};
  font-weight: ${fontWeight.bold};
`;

export const ColumnSum = styled.div`
  color: ${color.dark};
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.body.l};
`;
