import React, { FunctionComponent } from 'react';
import { Grid, GridItem } from '../../../atoms/Grid/Grid';
import { ChecklistItemTemplateType } from '../../../../@types/ChecklistTemplate/ChecklistTemplateType';
import HiddenInput from '../../../atoms/Form/HiddenInput/HiddenInput';
import Button from '../../../atoms/Button/Button';
import { TextInput } from '../../../molecules/Form/TextInput/TextInput';

type Props = {
  namePrefix: string;
  onDelete: () => void;
  defaultValue: ChecklistItemTemplateType;
};

const ChecklistItemTemplateForm: FunctionComponent<Props> = ({ namePrefix, onDelete, defaultValue }) => {
  return (
    <GridItem $desktop={12}>
      <Grid spacing={2} $align={'center'}>
        <GridItem $desktop={6}>
          <TextInput
            name={`${namePrefix}.content`}
            label={'Item content'}
            required
            defaultValue={defaultValue.content}
            placeholder={'eg. Analyze income and outcome'}
          />
          <HiddenInput name={`${namePrefix}.id`} value={defaultValue.id} />
        </GridItem>
        <GridItem>
          <Button $text={'Delete'} kind={'link'} onClick={onDelete} />
        </GridItem>
      </Grid>
    </GridItem>
  );
};

export default ChecklistItemTemplateForm;
