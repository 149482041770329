import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import * as Styled from './CommentEditor.styled';
import Form from '../../Form/Form';
import SendIcon from '../../../../Icons/Send';
import { color } from '../../../../styles/Variables';
import { Icon } from 'components/atoms/Icon/Icon';
import CloseIcon from '../../../../Icons/close.icon';
import CKEditorAtom from '../../../atoms/CKEditor/CKEditorAtom';
import AttachmentIcon from '../../../../Icons/Attachment.icon';
import api from '../../../../services/api';
import { AxiosResponse } from 'axios';
import { AttachmentType } from '../../../../@types/Attachment/AttachmentType';
import useApi from '../../../../hooks/useApi';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import useOutsideClick from '../../../../hooks/useOutsideClick';

type Props = {
  value: string | null;
  setValue: (value: string | null) => void;
  loading?: boolean;
  onAttachmentUpload?: (value: AttachmentType) => void;
  onCancel?: () => void;
  onSubmit?: () => void;
  setEditMode?: (value: boolean) => void;
  localStorageKey: string;
};

const CommentEditor: FunctionComponent<Props> = ({ value, setValue, onSubmit, setEditMode, onAttachmentUpload, onCancel, loading, localStorageKey }) => {
  const [active, setActive] = useState<boolean>(false);
  const { post } = useApi();
  const { setStorageValue, storageValue, deleteStorageValue } = useLocalStorage(localStorageKey, value);

  const containerRef = useRef<HTMLDivElement>(null);

  const onFormSubmit = () => {
    if (onSubmit) {
      onSubmit();
      setActive(false);
      deleteStorageValue();
    }
  };

  const handleCancel = () => {
    setActive(false);
    deleteStorageValue();
    setValue('');

    if (setEditMode) {
      setEditMode(false);
    } else {
      setValue('');
      onCancel && onCancel();
    }
  };

  const handleUploadedFile = useCallback((uuid: string) => {
    void post<AttachmentType>('/api/attachments', {
      fileUuid: uuid,
    }).then(({ data }) => {
      onAttachmentUpload && onAttachmentUpload(data);
    });
  }, []);

  const uploadFile = useCallback((files: FileList | null) => {
    if (!files) {
      return;
    }
    for (let i = 0; i < files.length; ++i) {
      const file = files[i];
      let formData = new FormData();
      formData.append('file', file);
      formData.append('context', 'project');
      api
        .post('/files', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response: AxiosResponse<{ uuid: string }>) => {
          handleUploadedFile(response.data.uuid);
        });
    }
  }, []);

  useEffect(() => {
    if ((!value && !storageValue) || value === '') {
      deleteStorageValue();
    } else if (value) {
      setStorageValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (storageValue) {
      setValue(storageValue);
    }
  }, []);
  useOutsideClick(containerRef, () => setActive(false));

  return (
    <Styled.Comment>
      <Styled.Container onClick={() => !active && setActive(true)} ref={containerRef}>
        <Form onSubmit={() => onFormSubmit()} defaultValues={{}}>
          <Styled.CKEWrapper $isActive={active}>
            <CKEditorAtom value={value} onChange={setValue} hideToolbar={true} placeholder={'Add comment'} mentions={true} active={active} />
          </Styled.CKEWrapper>
          {(value || active) && (
            <Styled.ButtonWrapper>
              <Styled.CancelButton type={'button'} onClick={() => handleCancel()}>
                <Icon size={1} color={color.neutral[60]}>
                  <CloseIcon />
                </Icon>
              </Styled.CancelButton>
              {!setEditMode && (
                <Styled.AddFileLabel htmlFor="att-file">
                  <Icon size={1.4} color={color.neutral[60]} disabled={false}>
                    <AttachmentIcon />
                  </Icon>
                  <input id={'att-file'} type={'file'} multiple onChange={(e) => uploadFile(e.currentTarget.files)} hidden />
                </Styled.AddFileLabel>
              )}
              <Styled.SendButton type={'submit'} disabled={!value || loading}>
                <Icon size={1.5} disabled={!value}>
                  <SendIcon />
                </Icon>
                {loading && <p>saving...</p>}
              </Styled.SendButton>
            </Styled.ButtonWrapper>
          )}
        </Form>
      </Styled.Container>
    </Styled.Comment>
  );
};

export default CommentEditor;
