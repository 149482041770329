import React from 'react';

export const SuccessIcon: React.FC = () => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 0C5.3828 0 0 5.3828 0 12C0 18.6172 5.3828 24 12 24C18.6172 24 24 18.6163 24 12C24 5.38373 18.6172 0 12 0ZM12 22.141C6.40898 22.141 1.85902 17.592 1.85902 12C1.85902 6.40805 6.40898 1.85902 12 1.85902C17.592 1.85902 22.141 6.40805 22.141 12C22.141 17.592 17.591 22.141 12 22.141Z"
        fill="#54C92B"
        strokeWidth={1}
      />
      <path
        d="M17.5055 7.8265C17.1272 7.48258 16.5388 7.50953 16.1931 7.88968L10.523 14.1332L7.78375 11.3484C7.42216 10.9822 6.83472 10.9766 6.46942 11.3373C6.10319 11.697 6.09761 12.2854 6.45826 12.6516L9.88722 16.1373C10.0629 16.3157 10.3008 16.4152 10.5499 16.4152C10.5555 16.4152 10.562 16.4152 10.5676 16.4162C10.8242 16.4106 11.0658 16.3009 11.2378 16.1113L17.5687 9.13993C17.9136 8.75879 17.8857 8.17136 17.5055 7.8265Z"
        fill="#54C92B"
        strokeWidth={1}
      />
    </svg>
  );
};

export default SuccessIcon;
