import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 3rem;
`;

export const HoursWrapper = styled.div`
  display: flex;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
`;
